<form [formGroup]="form">
  <ul class="d-flex align-items-center project-question-directory__apartment-type justify-content-between">
    <li
      [ngClass]="{ selected: propertyType === PropertyType.SINGLE_FAMILY }"
      (click)="setPropertyType(PropertyType.SINGLE_FAMILY)"
      class="d-flex flex-column align-items-center"
    >
      <span class="icon">
        <svg-icon src="/assets/img/apartment-{{ PropertyType.SINGLE_FAMILY }}.svg"></svg-icon>
      </span>
      <span>{{ 'Projects.apartment-' + PropertyType.SINGLE_FAMILY | translate }}</span>
    </li>
    <li
      [ngClass]="{ selected: propertyType === PropertyType.TOWNHOUSE }"
      (click)="setPropertyType(PropertyType.TOWNHOUSE)"
      class="d-flex flex-column align-items-center"
    >
      <span class="icon">
        <svg-icon src="/assets/img/apartment-{{ PropertyType.TOWNHOUSE }}.svg"></svg-icon>
      </span>
      <span>{{ 'Projects.apartment-' + PropertyType.TOWNHOUSE | translate }}</span>
    </li>
    <li
      [ngClass]="{ selected: propertyType === PropertyType.FLAT }"
      (click)="setPropertyType(PropertyType.FLAT)"
      class="d-flex flex-column align-items-center"
    >
      <span class="icon">
        <svg-icon src="/assets/img/apartment-{{ PropertyType.FLAT }}.svg"></svg-icon>
      </span>
      <span>{{ 'Projects.apartment-' + PropertyType.FLAT | translate }}</span>
    </li>
    <li
      [ngClass]="{ selected: propertyType === PropertyType.COMMERCIAL }"
      (click)="setPropertyType(PropertyType.COMMERCIAL)"
      class="d-flex flex-column align-items-center"
    >
      <span class="icon">
        <svg-icon src="/assets/img/apartment-{{ PropertyType.COMMERCIAL }}.svg"></svg-icon>
      </span>
      <span>{{ 'Projects.apartment-' + PropertyType.COMMERCIAL | translate }}</span>
    </li>
    <li
      [ngClass]="{ selected: propertyType === PropertyType.OTHER }"
      (click)="setPropertyType(PropertyType.OTHER)"
      class="d-flex flex-column align-items-center"
    >
      <span class="icon">
        <div class="project-question-directory__apartment-circle">
          <svg-icon src="/assets/img/ask.svg"></svg-icon>
        </div>
      </span>
      <span>{{ 'Projects.apartment-' + PropertyType.OTHER | translate }}</span>
    </li>
  </ul>

  <div *ngIf="propertyType === PropertyType.OTHER" class="project-question-directory__apartment-input">
    <div class="mt-3">
      <div class="bold mb-2">{{ 'Projects.anotherApartment' | translate }}</div>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.propertyTypeName"
        [config]="{ name: 'Projects.writeType' | translate }"
      ></gbxsoft-input>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="bold mb-2">{{ 'Projects.adresBuilding' | translate }}</div>
      <ng-select
        [searchable]="false"
        [formControlName]="PROJECT_DATA_FORM_BOX.addressType"
        [bindLabel]="'label'"
        [bindValue]="'id'"
        [items]="AddressTypeList"
        [tabIndex]="-1"
        [placeholder]="'Projects.sameAddress' | translate"
        class="select select-default select-with-title mb-3 w-100"
        (change)="onAddressTypeChange($event)"
      ></ng-select>
      <gbxsoft-input
        *ngIf="isNotBuildYet"
        [formControlName]="PROJECT_DATA_FORM_BOX.addressNote"
        [config]="{
          name: 'Projects.addressNote' | translate
        }"
      ></gbxsoft-input>
      <address-with-autocomplete
        *ngIf="!!addressConfig"
        [form]="form"
        [config]="addressConfig"
      ></address-with-autocomplete>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="bold mb-2">{{ 'Projects.apartmentInfo' | translate }}</div>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.propertyAge"
        [config]="{ name: 'Projects.yearApartment' | translate }"
      >
      </gbxsoft-input>

      <ng-select
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=heating-type'"
        [formControlName]="PROJECT_DATA_FORM_BOX.heatingType"
        [initialValue]="initialHeatingType"
        [tabIndex]="-1"
        [bindValue]="'name'"
        [bindLabel]="'name'"
        class="select select-default select-with-title mb-3 w-100"
        [placeholder]="'Projects.typeHeating' | translate"
        [addTagText]="'Projects.addTagText' | translate"
        [loadingText]="'Projects.loading' | translate"
        [notFoundText]="'Projects.notFoundText' | translate"
        [translateLabelPrefix]="'Projects.Heating.'"
        [enableSort]="false"
      ></ng-select>

      <ng-select
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=electricity-type'"
        [formControlName]="PROJECT_DATA_FORM_BOX.electricityType"
        [initialValue]="initialElectricityType"
        [tabIndex]="-1"
        [bindValue]="'name'"
        [bindLabel]="'name'"
        class="select select-default select-with-title mb-3 w-100"
        [placeholder]="'Projects.typeElectricity' | translate"
        [addTagText]="'Projects.addTagText' | translate"
        [loadingText]="'Projects.loading' | translate"
        [notFoundText]="'Projects.notFoundText' | translate"
        [translateLabelPrefix]="'Projects.Electricity.'"
        [enableSort]="false"
      ></ng-select>

      <ng-select
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=ownership-type'"
        [formControlName]="PROJECT_DATA_FORM_BOX.ownershipType"
        [initialValue]="initialOwnershipType"
        [tabIndex]="-1"
        [bindValue]="'name'"
        [bindLabel]="'name'"
        class="select select-default select-with-title mb-3 w-100"
        [placeholder]="'Projects.typeOwned' | translate"
        [addTagText]="'Projects.addTagText' | translate"
        [loadingText]="'Projects.loading' | translate"
        [notFoundText]="'Projects.notFoundText' | translate"
        [translateLabelPrefix]="'Projects.Ownership.'"
        [enableSort]="false"
      ></ng-select>
    </div>
    <div class="bold mb-2 col-12">{{ 'Projects.anotherInfo' | translate }}</div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <gbxsoft-input
        *ngIf="!isWholeBuildingUsage"
        [formControlName]="PROJECT_DATA_FORM_BOX.floor"
        [config]="{
          type: GbxsoftInputTypes.TEXT,
          name: 'Projects.whichFloor' | translate
        }"
      ></gbxsoft-input>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.floorsCount"
        [config]="{
          type: GbxsoftInputTypes.TEXT,
          name: 'Projects.addFloorCount' | translate
        }"
      ></gbxsoft-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.nameOnDoorbell"
        [config]="{
          name: 'Projects.lastnameOnRing' | translate
        }"
      ></gbxsoft-input>

      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.area"
        [config]="{ name: 'Projects.meters' | translate }"
      >
      </gbxsoft-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="bold mb-2">{{ 'Projects.parking' | translate }}</div>
      <div>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasParking"
          [name]="'parking'"
          [text]="'Projects.yes' | translate"
          [valueName]="true"
        ></radio-button>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasParking"
          class="ml-4"
          [name]="'parking'"
          [text]="'Projects.no' | translate"
          [valueName]="false"
        ></radio-button>
      </div>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.parkingDescription"
        [config]="{
          name: 'Projects.additionalMarks' | translate
        }"
      ></gbxsoft-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="bold mb-2">{{ 'Projects.elevator' | translate }}</div>
      <div>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasElevator"
          [name]="'elevator'"
          [text]="'Projects.yes' | translate"
          [valueName]="true"
        ></radio-button>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasElevator"
          class="ml-4"
          [name]="'elevator'"
          [text]="'Projects.no' | translate"
          [valueName]="false"
        ></radio-button>
      </div>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.elevatorDescription"
        [config]="{
          name: 'Projects.additionalMarks' | translate
        }"
      ></gbxsoft-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="bold mb-2">{{ 'Projects.underground' | translate }}</div>

      <div>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasBasement"
          [name]="'underground'"
          [text]="'Projects.yes' | translate"
          [valueName]="true"
        ></radio-button>
        <radio-button
          [formControlName]="PROJECT_DATA_FORM_BOX.hasBasement"
          class="ml-4"
          [name]="'underground'"
          [text]="'Projects.no' | translate"
          [valueName]="false"
        ></radio-button>
      </div>
      <gbxsoft-input
        [formControlName]="PROJECT_DATA_FORM_BOX.basementDescription"
        [config]="{
          name: 'Projects.additionalMarks' | translate
        }"
      ></gbxsoft-input>
    </div>
  </div>
  <hr />
</form>
