<div
  class="notification-item d-flex align-items-center"
  [ngClass]="{ 'notification-item--shown': notification.shown }">

  <div class="notification-item__avatar">
    <avatar [imageUrl]="avatarUrl"
            *ngIf="!avatarConfig?.hideAvatar && avatarConfig?.type === 'person'"
            class="mr-3"></avatar>
    <svg-icon *ngIf="!avatarConfig?.hideAvatar && avatarConfig?.type === 'svg'"
              [src]="avatarConfig?.src"
              [svgClass]="avatarConfig?.class"
              [svgStyle]="{width: '44px', height: '44px'}"></svg-icon>
  </div>
  <div class="notification-item-content d-flex align-items-start justify-content-between">
    <div class="notification-item-content-data">
      <ng-container #notificationContent></ng-container>
      <app-button
        *ngIf="notificationClickFunction"
        [disabled]="loading"
        (onClick)="show()"
        [config]="{
        type: ButtonTypes.SECONDARY_GREY,
        text: 'Notifications.show' | translate,
        size: ButtonSize.SMALL
      }">
      </app-button>
    </div>
    <div class="d-flex align-items-center">
      <div class="notification-item-content-date">{{ notification.created | dateFromNow }}</div>
      <div class="notification-item-content-close" (click)="markAsShown()">
        <svg-icon [src]="'/assets/img/ic-close.svg'" [svgStyle]="{width: '16px', height: '16px'}"></svg-icon>
      </div>
    </div>

  </div>
</div>
