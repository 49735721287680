import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EParticipantStatus } from '../enums/participant-status.enum';

export class Participant {
  contact: Contact;
  created: string;
  deleted: string;
  email: string;
  id: number;
  modified: string;
  status: EParticipantStatus;
  isCreator?: boolean;
  calendar?: any;

  constructor(p?: Participant) {
    this.deserialize(p);
  }

  deserialize(p?: Participant) {
    p ? Object.assign(this, p) : null;
    p.contact ? (this.contact = new Contact(p.contact)) : null;
  }
}
