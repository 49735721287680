import localePL from '@fullcalendar/core/locales/pl';
import localeDE from '@fullcalendar/core/locales/de';
import localeEN from '@fullcalendar/core/locales/en-gb';
import { Languages, LanguageService } from '@core/services/language.service';
import { CalendarOptions } from '@fullcalendar/common';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { IFullCalendarView } from '@modules/calendar/shared/interfaces/fullcalendar-view.interface';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export const calendarConfig = (
  languageService: LanguageService,
  singleClick: (e) => void,
  doubleClick: (e) => void,
  changeEvent: (e) => void,
): CalendarOptions => {
  const getLocale = () => {
    switch (languageService.getCurrentLanguage()) {
      case Languages.PL:
        return localePL;
      case Languages.DE:
        return localeDE;
      default:
        return localeEN;
    }
  };

  const addClickEventListener = (calendar: IFullCalendarView) => {
    const delay = 200;
    let timer = null;
    let prevent = false;
    calendar.el.addEventListener('click', () => {
      timer = setTimeout(() => {
        !prevent ? singleClick(calendar) : null;
        prevent = false;
      }, delay);
    });

    calendar.el.addEventListener('dblclick', () => {
      clearTimeout(timer);
      prevent = true;
      doubleClick(calendar);
    });
  };

  return {
    eventTimeFormat: { hour12: false, hour: '2-digit', minute: '2-digit' },
    locale: getLocale(),
    initialView: 'timeGridWeek',
    headerToolbar: false,
    height: '100%',
    selectable: true,
    weekNumbers: true,
    weekNumberFormat: { week: 'numeric' },
    eventChange: changeEvent.bind(this),
    eventDidMount: (calendar: IFullCalendarView) => addClickEventListener(calendar),
    dateClick: (calendar) => {
      const ctrl = new EventSidenavController();
      let config = {};

      const permissionCtrl = new CheckPermission({ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' });
      if (!permissionCtrl.check()) return;

      if (!!calendar.allDay) {
        config = {
          isFullDay: !!calendar.allDay,
          termStart: moment(calendar.date).startOf('day').format(Config.DATE_SERVER),
          termEnd: moment(calendar.date).endOf('day').format(Config.DATE_SERVER),
        };
      } else {
        config = {
          isFullDay: !!calendar.allDay,
          termStart: moment(calendar.date).format(Config.DATE_SERVER),
          termEnd: moment(calendar.date).add(30, 'minutes').format(Config.DATE_SERVER),
        };
      }
      ctrl.createEvent(config);
    },
    selectAllow: (e) => {
      if (e.allDay) {
        if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400) {
          return true;
        }
      } else {
        if (e.end.getTime() / 1000 - e.start.getTime() / 1000 < 3600) {
          return true;
        }
      }
    },
    slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
    select: (selectionInfo) => {
      // console.log(selectionInfo);
    },

    views: {
      dayGridMonth: {
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
      },
      timeGridWeek: {
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
      },
      timeGridDay: {
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
      },
      listWeek: {
        eventDidMount: (calendar: IFullCalendarView) => {
          const container = calendar.el;
          const applyOnElementCreated = () => {
            const icon = container.querySelector('.fc-list-event-graphic');
            container.insertBefore(icon, container.firstChild);
            addClickEventListener(calendar);
          };

          setTimeout(() => applyOnElementCreated(), 0);
        },
      },
    },
  };
};
