<form [formGroup]="form" class="entity-sidenav">
  <sidenav-loading *ngIf="downloadingEntry" [cover]="true"></sidenav-loading>
  <div class="entity-sidenav__details">
    <sidenav-title
      [closeFunc]="discardSaving.bind(this)"
      [title]="
        (!!isCreate
          ? 'ProjectCreator.SecondStep.sidenav.addTitle'
          : 'ProjectCreator.SecondStep.sidenav.editTitle'
        ) | translate
      "
    ></sidenav-title>
    <div class="entity-sidenav__details__wrapper">
      <div class="entity-sidenav__card mb-3">
        <div class="mb-3">
          <ng-select
            class="select select-default select-with-title w-100"
            [items]="items"
            [bindLabel]="'name'"
            [bindValue]="'value'"
            [clearAllText]="'clearAll' | translate"
            [searchable]="false"
            [clearable]="false"
            [placeholder]="'ProjectCreator.SecondStep.sidenav.folderName' | translate"
            [notFoundText]="'Protocols.notFoundText' | translate"
            [tabIndex]="-1"
            [readonly]="loading"
            [formControlName]="EProjectEntitySidenavForm.TYPE"
          >
          </ng-select>
        </div>
        <div class="mb-3">
          <gbxsoft-input
            [errMessages]="errorMessages(EProjectEntitySidenavForm.NAME)"
            [readonly]="loading"
            [formControlName]="EProjectEntitySidenavForm.NAME"
            [config]="{
              name:
                (!form.get(EProjectEntitySidenavForm.NAME)?.value?.length
                  ? 'ProjectCreator.SecondStep.sidenav.placeholder'
                  : 'ProjectCreator.SecondStep.sidenav.name'
                ) | translate
            }"
          ></gbxsoft-input>
        </div>
        <div class="relative">
          <quill-editor
            #editor
            [bounds]="'.quill-custom-img-size'"
            class="quill-custom-img-size"
            (onEditorCreated)="editorInit($event)"
            [readOnly]="loading"
            [formControlName]="EProjectEntitySidenavForm.DESCRIPTION"
            [formats]="formats"
            [modules]="modules"
            [placeholder]="'ProjectCreator.SecondStep.sidenav.commentary' | translate"
          ></quill-editor>
        </div>
      </div>

      <div>
        <attachment-manager-list
          #attachmentsList
          [apiConfig]="
            !!details?.projectPublicFormToken ? { projectFormToken: details?.projectPublicFormToken } : {}
          "
          [uploadConfig]="!!this.entry?.id ? { id: this.entry.id, objectType: 'protocol-entry' } : null"
          [fileAttachments]="entry?.attachments || []"
          (filesUploaded)="filesUploaded($event)"
          [attachmentsConfig]="attachmentsConfig"
        ></attachment-manager-list>
      </div>
    </div>
  </div>

  <div class="sticky-footer d-flex justify-content-between">
    <app-button
      [type]="'button'"
      (onClick)="cancel()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_SECONDARY_BLUE,
        text: 'ProjectCreator.SecondStep.sidenav.discard' | translate
      }"
    ></app-button>

    <app-button
      [type]="'button'"
      [disabled]="loading"
      [loading]="loading"
      (onClick)="submit()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text:
          (!!isCreate
            ? 'ProjectCreator.SecondStep.sidenav.submitAdd'
            : 'ProjectCreator.SecondStep.sidenav.submitEdit'
          ) | translate
      }"
    ></app-button>
  </div>
</form>
