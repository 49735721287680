import { EventEmitter, Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class ProjectChangesService extends BaseHttpService {
  updateEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    super();
  }

  getChangesSummary(id: number) {
    const url = `${Config.API}/project/${id}/changes-summary`;
    return this.get(url, false);
  }
}
