import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { EntryEmitter } from '@modules/protocols/shared/interfaces/entry-emitter.interface';
import { WindowHelper } from '@shared/helpers/window.helper';
import { MatMenuTrigger } from '@angular/material/menu';
import { EntryType } from '../../enums/entry-type.enum';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'entry-item',
  styleUrls: ['./entry-item.component.scss'],
  templateUrl: './entry-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryItemComponent implements OnInit {
  $item: Entry;
  title: string = '';

  @Input() showAllImages: boolean = false;
  @Input() index: number = 1;
  @Input() limitEditAction: boolean = false;
  @Input() preview: boolean = false;
  @Input() edit: boolean = true;
  @Input()
  set item($item: Entry) {
    this.$item = $item;
    this.setTitle();
    this.changes.detectChanges();
  }

  get item(): Entry {
    return this.$item;
  }

  @Output() entryAction: EventEmitter<EntryEmitter> = new EventEmitter();
  @ViewChild('trigger') trigger: MatMenuTrigger;
  WindowHelper = WindowHelper;

  date: number = Date.now();

  get EntryClass() {
    return { 'entry-upload-item-opened': this.item.opened, 'entry-upload-item-deleted': !!this.item.deleted };
  }

  constructor(private t: TranslateService, private changes: ChangeDetectorRef) {}

  ngOnInit() {}

  deleteEntry() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.item.deleted = Date.now().toString();
        this.entryAction.emit({ type: EntryAction.DELETE, data: this.item });
      })
      .subscribe(() => {});
  }

  restoreEntry() {
    this.entryAction.emit({ type: EntryAction.RESTORE, data: this.item });
  }

  editEntry() {
    this.entryAction.emit({ type: EntryAction.EDIT, data: this.item });
  }

  toggleEntry() {
    this.item.opened = !this.item.opened;
    this.changes.detectChanges();
  }

  setTitle() {
    switch (this.item.type) {
      case EntryType.TYPE_WORKER_ENTRY:
        const employee =
          this.item?.assignedTo[0]?.userPerson?.previewName || this.item?.externalEmployees[0]?.name || '';
        this.title = !!employee ? employee : this.t.instant('Protocols.employee');
        break;
      default:
        this.title = !!this.item?.description ? this.item?.description : this.t.instant('Protocols.registry');
        break;
    }
  }

  @HostListener('window:resize', ['$event']) windowResize() {
    this.changes.detectChanges();
  }
}
