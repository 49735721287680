<ng-template #defaultTemplate let-options="options">
  <div
    [ngClass]="[
      'popover',
      options.placement,
      'popover-' + options.placement,
      'bs-popover-' + options.placement,
      options.popoverClass
    ]"
  >
    <div class="popover-arrow arrow"></div>
    <h3
      class="popover-title popover-header"
      [innerHTML]="options.popoverTitle"
    ></h3>
    <div class="popover-content popover-body">
      <ng-template
        *ngIf="options.customDescriptionTemplate"
        [ngTemplateOutlet]="options.customDescriptionTemplate"
        [ngTemplateOutletContext]="{ options: options }"
      ></ng-template>
      <p [innerHTML]="options.popoverMessage" *ngIf="!options.customDescriptionTemplate"></p>
      <div
        class="confirm-btns"
        [class.confirm-btns-reversed]="options.reverseButtonOrder"
      >
        <div class="confirm-btn-container" *ngIf="!options.hideCancelButton">
          <button
            type="button"
            [mwlFocus]="options.focusButton === 'cancel'"
            [class]="'btn btn-block btn-' + options.cancelButtonType"
            (click)="options.onCancel({ clickEvent: $event })"
            [innerHtml]="options.cancelText"
          ></button>
        </div>
        <div class="confirm-btn-container" *ngIf="!options.hideConfirmButton">
          <button
            type="button"
            [mwlFocus]="options.focusButton === 'confirm'"
            [class]="'btn btn-block btn-' + options.confirmButtonType"
            (click)="options.onConfirm({ clickEvent: $event })"
            [innerHtml]="options.confirmText"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  [ngTemplateOutlet]="options.customTemplate || defaultTemplate"
  [ngTemplateOutletContext]="{ options: options }"
>
</ng-template>
