import { AttachmentsModule } from '@shared/modules/attachments/attachments.module';
import { NgSelectExtendedModule } from '@shared/modules/ng-select-extended/ng-select-extended.module';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { MatDialogModule } from '@angular/material/dialog';
import { MailingService } from './shared/services/mailing.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MailingSelectionModalComponent } from './shared/components/mailing-selection-modal/mailing-selection-modal.component';

@NgModule({
  declarations: [MailingSelectionModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    QuillModule,
    TranslateModule,
    SharedModule,
    NgSelectExtendedModule,
    AttachmentsModule,
  ],
  exports: [],
  providers: [MailingService],
})
export class MailingsModule {}
