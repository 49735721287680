<div #container
     class="projects-timeline-favourites"
     [ngClass]="{'projects-timeline-favourites--checked':favourited}"
     (click)="setFavourite()"
     (touchstart)="setFavourite()">
  <svg-icon [src]="'/assets/img/ic-star-filled.svg'"
            [svgStyle]="{width: '16px', height: '16px'}"
            (click)="setFavourite()"
  ></svg-icon>
  <span>{{ 'ProjectsTimeline.Header.favourited' | translate }}</span>
</div>
