<div class="contact-option-template">
  <span>
    <div [title]="contact?.name" class="contact-option-template__name">
      <span>{{ contact?.fullName }}</span>
      <span *ngIf="contact?.type && showType"><ng-container *ngIf="contact?.fullName">, </ng-container>{{ 'Contacts.Type.' + contact?.type | translate }} </span>
    </div>

    <div *ngIf="contact?.phone || contact?.email" class="contact-option-template__contact">
      <span *ngIf="contact?.phone" [title]="contact?.fullPhone">
        {{ contact.fullPhone }}
        <ng-container *ngIf="contact?.phone && contact?.email">, </ng-container>
      </span>
      <span *ngIf="contact?.email" [title]="contact?.email">{{ contact?.email }}</span>
    </div>

    <div
      *ngIf="contact?.fullAddress && !isLabel"
      class="contact-option-template__address"
      [title]="contact.fullAddress"
    >
      {{ contact?.fullAddress }}
    </div>
  </span>
</div>
