import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserPerson } from '@shared/interfaces/user.interface';
import { HttpError } from '@shared/interfaces/error.interface';
import { BaseComponent } from '@shared/components/base.component';
import { JWT } from '@shared/interfaces/jwt.interface';
import { Regex } from '@shared/configs/regex';
import { StorageEventType, StorageService } from '@core/services/storage.service';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { LanguageService } from '@core/services/language.service';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { CurrencyFormat } from '@shared/enums/curreny-format.enum';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  subSaving: Subscription;

  constructor(
    private service: SettingsService,
    private changes: ChangeDetectorRef,
    private langService: LanguageService,
    private footerService: StickyFooterService,
    public storage: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.initUserData();
    this.setLanguage();
    this.subscribeSaving();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submit();
          break;
        default:
          break;
      }
    });
  }

  createForm() {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      phone: new FormControl(''),
      phoneCountry: new FormControl(''),
      language: new FormControl(''),
      numberFormat: new FormControl(Config.DEFAULT_NUMBER_FORMAT),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(Regex.email),
      ]),
      photo: new FormControl(''),
      clearPhoto: new FormControl(false),
    });
  }

  setLanguage() {
    const lang = this.form.get('language').value;
    this.form.get('language').setValue(lang);
  }

  initUserData() {
    this.service.getUserData().subscribe({
      next: this.onSuccessUserData.bind(this),
      error: this.onErrorUserData.bind(this),
    });
    this.form.patchValue(this.store.UserPerson);
    this.setProgressBar();
  }

  setProgressBar() {
    let filledCount = 0;
    let fieldsCount = 0;
    // tslint:disable-next-line: forin
    for (const field in this.form.value) {
      const fieldValue = this.form.value[field];
      if (field !== 'phoneCountry' && field !== 'clearPhoto') {
        if (field === 'photo' && !fieldValue && this.store.UserPerson.photo) {
          filledCount++;
        }
        fieldsCount++;
        if (fieldValue && fieldValue !== '') {
          filledCount++;
        }
      }
    }
    let percent = (filledCount / fieldsCount) * 100;
    if (percent > 100) {
      percent = 100;
    }
    this.service.progressbar = percent;
  }

  onSuccessUserData(user: UserPerson) {
    this.store.UserPerson = user;
    this.userPerson = user;
  }

  onErrorUserData(e: HttpError) {
    switch (e.messageCode) {
      default:
        this.s.error(this.t.instant('Settings.User.Errors.userDataError'));
        break;
    }
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.loading = true;
    this.service
      .putUserData(this.form.value)
      .subscribe({
        next: this.onSuccessPutUser.bind(this),
        error: this.onErrorPutUser.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.changes.detectChanges();
      });
  }

  onSuccessPutUser(jwt: JWT) {
    const reloadForLanguage = this.checkIfReloadForLanguage();

    this.s.success(this.t.instant('Settings.User.successPutUserData'));
    this.store.UserPerson = jwt.userPerson;
    this.userPerson = jwt.userPerson;
    jwt.token ? (this.store.jwt = jwt.token) : null;
    reloadForLanguage ? this.langService.saveLanguage(this.form.value['language']) : null;

    this.store.emmiter.emit({ type: StorageEventType.UPDATE_USER_PERSON, userPerson: jwt.userPerson });
    this.updateIamOwnerDataInCompany();
    this.setProgressBar();
  }

  checkIfReloadForLanguage() {
    if (this.form.value['language'] !== this.userPerson.language) {
      return true;
    }
    return false;
  }

  onErrorPutUser(e: HttpError) {
    switch (e.messageCode) {
      case IntilioCodes.EMAIL_IS_TAKEN:
        this.s.error(this.t.instant('Settings.User.Errors.emailIsTaken'));
        break;
      case IntilioCodes.FILE_IS_BIG:
        this.s.error(this.t.instant('Settings.User.Errors.errorFileTooBig').replace('{{size}}', 10));
        break;
      case IntilioCodes.WRONG_EXTENSION:
        this.s.error(this.t.instant('Settings.User.Errors.wrongFileExtension'));
        break;
      default:
        this.s.error(this.t.instant('Settings.User.Errors.userPutDataError'));
        break;
    }
  }

  updateIamOwnerDataInCompany() {
    if (this.store.Company.iAmOwner && this.employee.isEmployeeOwner) {
      const company = this.store.Company;
      company.ownerFirstName = this.userPerson.firstName;
      company.ownerLastName = this.userPerson.lastName;
      company.ownerEmail = this.userPerson.email;
      company.ownerPhone = this.userPerson.phone;
      company.ownerPhoneCountry = this.userPerson.phoneCountry;
      this.store.Company = company;
    }
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
