import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GbxsoftValueAccessor, GbxsoftValidatorAccessor } from '../gbxsoft-accessors';
import { GbxsoftBaseFormComponent } from '../gbxsoft-base.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { GbxsoftSelectConfig, DefaultSelectConfig } from './gbxsoft-select.interface';

@Component({
  selector: 'gbxsoft-select',
  templateUrl: './gbxsoft-select.component.html',
  styleUrls: ['./gbxsoft-select.component.scss'],
  providers: [GbxsoftValueAccessor(GbxsoftSelectComponent), GbxsoftValidatorAccessor(GbxsoftSelectComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GbxsoftSelectComponent
  extends GbxsoftBaseFormComponent
  implements OnInit, OnChanges, AfterViewInit {
  _c: GbxsoftSelectConfig;
  defaultConfig: GbxsoftSelectConfig = new DefaultSelectConfig();
  tempValue;

  @Input()
  set config(config: GbxsoftSelectConfig) {
    this._c = Object.assign(this.defaultConfig, config);
  }
  get config(): GbxsoftSelectConfig {
    return this._c;
  }

  @Input() options = [];
  originalOptions = [];

  @Output('searchEmit') searchEmit: EventEmitter<string> = new EventEmitter();

  searchControl: FormControl = new FormControl();
  isOpened: boolean = false;
  unselecting: boolean = false;

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  ngOnInit() {
    this.changes.markForCheck();
    this.initSearch();
  }

  ngOnChanges(simple: SimpleChanges) {
    simple.options ? (this.originalOptions = [...simple.options.currentValue]) : null;
  }

  ngAfterViewInit() {
    // this.updateValue(this.value);
  }

  initSearch(): void {
    const debounce = this.config?.debounceTime || 25;
    this.searchControl.valueChanges.pipe(debounceTime(debounce)).subscribe((term) => this.search(term));
    this.changes.detectChanges();
  }

  search(term: string): void {
    if (!this.config?.searching) return;
    this.options = this.originalOptions.filter((option) => {
      return option[this.config?.label].toLowerCase().includes(term.toLowerCase());
    });
    this.searchEmit.emit(this.searchControl.value);
    this.changes.detectChanges();
  }

  selectEvent(o): void {
    if (!this.config?.enableUnselect) {
      this.select(o);
      return;
    }
    this.isOptionSelected(o) ? this.unselect(o) : this.select(o);
  }

  getValueName(value) {
    if (this.config?.valueName) {
      return value && value[this.config?.valueName] ? value[this.config?.valueName] : value;
    }
    return value;
  }

  select(value): void {
    this.config?.multiple ? (value = this.tempValue ? [value, ...this.tempValue] : [value]) : null;
    this.updateValue(value);
    this.clearSearch();
    this.config?.closeOnSelect ? this.clickOutside() : null;
    this.changes.detectChanges();
  }

  unselect(value = null): void {
    this.unselecting = true;
    value =
      this.config.multiple && value
        ? this.tempValue.filter((opt) => opt[this.config.id] !== value[this.config.id])
        : null;
    this.updateValue(value);
    this.unselecting = false;
    this.changes.detectChanges();
  }

  isSelected(id: string): boolean {
    return this.tempValue && this.tempValue[this.config.id] === id;
  }

  optionClass(option): { [key: string]: boolean } {
    return { gbxsoft__select__option__selected: this.isOptionSelected(option) };
  }

  isOptionSelected(option): boolean {
    if (this.config.multiple) {
      return this.tempValue
        ? this.tempValue.filter((opt) => option[this.config.id] === opt[this.config.id]).length
        : false;
    } else {
      return this.isSelected(option[this.config.id]);
    }
  }

  updateValue(value): void {
    this.writeValue(value);
  }

  writeValue(value: any) {
    if (this.config?.valueName) {
      if (this.value === value) return;
      const obj = value && value[this.config?.valueName] ? value[this.config?.valueName] : null;
      //returns only selected bind value to formControl
      const _value = obj || value;
      const item = this.options.filter((i) => i[this.config?.valueName] === _value)[0];
      this.setValueFields(_value, item);
    } else {
      //returns whole object value when no bind Value added
      this.setValueFields(value, value);
    }
    this.changes.detectChanges();
  }

  setValueFields(original, templateValue) {
    this.fnOnChange ? this.fnOnChange(this.getValueName(original)) : null;
    this.value = original;
    this.tempValue = templateValue;
  }

  openDropdown(): void {
    this.isOpened = true;
    this.changes.detectChanges();
  }

  closeDropdown(): void {
    this.isOpened = false;
    this.changes.detectChanges();
  }

  clearSearch() {
    this.searchControl?.setValue('');
  }

  clearAll(): void {
    this.clearSearch();
    this.unselect(null);
    this.config?.closeOnClear ? this.closeDropdown() : null;
  }

  clickOutside(): void {
    if (this.unselecting) return;
    this.clearSearch();
    this.closeDropdown();
  }

  addNewTag() {
    const newOption = {
      [this.config.label]: this.searchControl.value,
      [this.config.id]: Date.now(),
    };

    this.originalOptions.push(newOption);
    this.options.push(newOption);
    this.select(newOption);
    this.changes.detectChanges();
  }

  trackByFn(index, item) {
    return index;
  }

  get selectedOption(): string {
    return !!this.tempValue && !!this.tempValue[this.config.label] ? this.tempValue[this.config.label] : '';
  }

  get selectedOptions(): Array<any> {
    return this.tempValue;
  }

  get isClearAllowed(): boolean {
    return (
      ((this.config?.multiple ? this.selectedOptions && this.selectedOptions.length : this.selectedOption) ||
        this.searchControl.value) &&
      this.config?.allowClear
    );
  }

  get selectClasses() {
    return {
      'selected-value': this.searchControl?.value || this.selectedOption || this.selectedOptions?.length,
      'select-error': this.errorCtrl.isError && this.errorCtrl.errorMessage ? true : false,
      'select-opened': this.isOpened,
    };
  }
}
