import { ChangeStatusList } from '@modules/protocols/shared/consts/changes-status.list';
import { TaskStatusList } from '@modules/protocols/shared/consts/task-status.list';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ITaskStatus } from '@modules/protocols/shared/interfaces/task-status-list.interface';
import { TaskType } from '@shared/enums/task-type.enum';

@Component({
  selector: 'task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
})
export class TaskStatusComponent implements OnInit {
  TaskStatuses = TaskStatus;
  statusList: ITaskStatus[] = TaskStatusList;

  tempType: TaskType;

  @Input()
  set type(type: TaskType) {
    this.tempType = type;
    this.statusList =
      type === TaskType.TYPE_CHANGE ? ChangeStatusList.filter((i) => !i.disabled) : TaskStatusList;
  }

  get type() {
    return this.tempType;
  }

  @Output() statusChanged: EventEmitter<TaskStatus> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  setNewStatus(status: TaskStatus) {
    this.statusChanged.emit(status);
  }
}
