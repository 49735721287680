<list-single-filter [template]="filter"
                    [name]="name"
                    [iconUrl]="iconUrl"></list-single-filter>

<ng-template #filter>
  <div class="list-filter-single__value" [ngClass]="{ hasValue: isValue }">

    <input type="text"
           #input
           ngxDaterangepickerMd
           [(ngModel)]="selected"
           [ranges]="ranges"
           readonly="readonly"
           [alwaysShowCalendars]="true"
           [locale]="dateRangeLocale"
           class="list-filter-single__date-input"
           placeholder="{{ 'List.Filters.notChosen' | translate }}"
           (datesUpdated)="datesUpdated($event)"
    />
    <div class="list-filter-single__actions">
      <span class="list-filter-single__clear" (click)="clear()" *ngIf="isValue">×</span>
      <span class="list-filter-single__arrow" (click)="onArrowClick()"></span>
    </div>
  </div>
</ng-template>
