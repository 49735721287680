import { TaskStatus } from '@shared/enums/task-status.enum';
import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { TaskType } from '@shared/enums/task-type.enum';
import { Task } from '@shared/models/task.model';
import { Observable } from 'rxjs';
import { TaskAction } from '@modules/protocols/shared/enums/task-action.enum';

@Injectable({ providedIn: 'root' })
export class TaskService extends BaseHttpService {
  taskStatusEvent: EventEmitter<TaskAction> = new EventEmitter();
  updateTaskList: EventEmitter<boolean> = new EventEmitter();
  dragEnd: EventEmitter<boolean> = new EventEmitter();
  // taskEvent: EventEmitter<{ type: TaskAction; data: any }> = new EventEmitter();

  constructor() {
    super();
  }

  acceptChange(taskId: number, body: any) {
    const url = `${Config.API}/task/${taskId}/accept`;
    return this.post(url, body, false);
  }

  shareChange(body: any) {
    const url = `${Config.API}/task/share-changes`;
    return this.post(url, body, false);
  }

  getTask(id: number) {
    if (!id) {
      console.warn('[Task ID] is not provided!');
      return;
    }

    const url = `${Config.API}/task/${id}`;
    return this.get(url).pipe(map((i) => new Task(i)));
  }

  createTask(data): Observable<Task> {
    const url = `${Config.API}/task`;
    return this.postWithSaving(url, data, false, true);
  }

  editPatchTask(data, id: number): Observable<Task> {
    const url = `${Config.API}/task/${id}`;
    return this.patch(url, data);
  }

  editTask(data, id: number): Observable<Task> {
    const url = `${Config.API}/task/${id}`;
    return this.postWithSaving(url, data, false, true);
  }

  editStatus(data, id: number) {
    const url = `${Config.API}/task/${id}`;
    return this.patch(url, data);
  }

  reorderTask(task: { newPosition: number; newType: TaskType }, id: number): Observable<Task> {
    const url = `${Config.API}/task/${id}/reorder`;
    return this.postWithSaving(url, task);
  }

  groupEditStatus(tasksIds: Array<number>, taskStatus: TaskStatus, type: TaskType) {
    let params = {
      tasksIds,
    };

    params[type === TaskType.TYPE_CHANGE ? 'changeStatus' : 'status'] = taskStatus;
    const urlEnding = type === TaskType.TYPE_CHANGE ? 'change-status' : 'status';
    const url = `${Config.API}/task/bulk/${urlEnding}`;

    return this.postWithSaving(url, params);
  }

  finishTask(taskId: number, recursive: number = 0) {
    const url = `${Config.API}/task/${taskId}/finish`;
    return this.postWithSaving(url, { recursive });
  }

  groupAssign(
    tasksIds: Array<number>,
    assignedToIds: Array<number>,
    assignedEmails?: Array<string>,
  ): Observable<Array<Task>> {
    const params = {
      tasksIds,
      assignedToIds,
      assignedEmails,
    };
    const url = `${Config.API}/task/bulk/assigned-to`;
    return this.postWithSaving(url, params);
  }

  groupDiscount(tasksIds: Array<number>, discount: number) {
    const params = {
      tasksIds,
      discount,
    };
    const url = `${Config.API}/task/bulk/discount`;
    return this.postWithSaving(url, params);
  }

  groupTerm(tasksIds: Array<number>, termStart: string, termEnd: string) {
    const params = {
      tasksIds,
      termStart,
      termEnd,
    };
    const url = `${Config.API}/task/bulk/term`;
    return this.postWithSaving(url, params);
  }

  deleteTask(id: number): Observable<{}> {
    const url = `${Config.API}/task/${id}`;
    return this.deleteWithSaving(url, false);
  }

  createTaskGroup(name: string, projectId?: number) {
    const url = `${Config.API}/task-group`;
    return this.post(url, { name, projectId }, false);
  }

  editTaskGroup(name: string, groupId: number) {
    const url = `${Config.API}/task-group/${groupId}`;
    return this.post(url, { name }, false);
  }

  postWithSaving(
    url: string,
    body: any,
    skipAuth: boolean = false,
    multipart: boolean = false,
    options?: any,
  ) {
    this.taskStatusEvent.emit(TaskAction.STARTED_SAVING);
    return this.post(url, body, skipAuth, multipart, options).pipe(
      map((res) => {
        this.taskStatusEvent.emit(TaskAction.COMPLETED_SAVING);
        return res;
      }),
    );
  }

  deleteWithSaving(url: string, skipAuth: boolean = false, options?: any) {
    this.taskStatusEvent.emit(TaskAction.STARTED_SAVING);
    return this.delete(url, skipAuth, options).pipe(
      map((res) => {
        this.taskStatusEvent.emit(TaskAction.COMPLETED_SAVING);
        return res;
      }),
    );
  }
}
