import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'textarea-v2',
  templateUrl: './textarea-v2.component.html',
  styleUrls: ['./textarea-v2.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaV2Component), multi: true },
  ],
})
export class TextareaV2Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string;

  form = new FormGroup({
    control: new FormControl(),
  });

  destroy$ = new Subject();

  constructor() {}

  onChange = (value: string) => {};

  onTouched = () => {};

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((values) => {
          this.onChange(values.control);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  writeValue(value: string): void {
    this.form.patchValue({ control: value });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
