<modal-title [title]="data?.title | translate"></modal-title>
<mat-dialog-content>
  <form [formGroup]="form">
    <gbxsoft-input
      [formControlName]="data?.apiConfig.fieldName"
      [errMessages]="errorMessages(data?.apiConfig.fieldName)"
      [config]="{
        name: data?.title | translate,
        appendContent: data?.apiConfig?.currency,
        showPlaceholderOnFocus: true,
        type: 'number'
      }"
    ></gbxsoft-input>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-button
    (onClick)="dialogRef.close()"
    [class]="'mr-2 bold'"
    [config]="{
      text: 'ProjectChanges.Summary.cancel' | translate,
      type: ButtonTypes.DISCARD_BUTTON,
      size: ButtonSize.LARGE
    }"
  ></app-button>
  <app-button
    [disabled]="loading"
    [loading]="loading"
    (onClick)="submitAmount()"
    [config]="{
      text: 'ProjectChanges.Summary.save' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-save.svg'
    }"
  ></app-button>
</mat-dialog-actions>
