import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { GbxsoftSelectConfig } from '@form/src/lib/gbxsoft-select/gbxsoft-select.interface';
import { GbxsoftInputComponent } from '@form/src/public-api';
import { Config } from '@shared/configs/config';
import { countries } from '@shared/datas/countries';
import {
  GoogleAddressComponent,
  GoogleAdressComponentType,
} from '@shared/interfaces/google/google-address-component.interface';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'address-with-autocomplete',
  templateUrl: './address-with-autocomplete.component.html',
  styleUrls: ['./address-with-autocomplete.component.scss'],
})
export class AddressWithAutocompleteComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() form: FormGroup;
  @Input() config: AddressAutcompleteConfig;
  @ViewChild('autocomplete') autocomplete: GbxsoftInputComponent;
  autocompleteInstance: google.maps.places.Autocomplete;
  autocompleteListener: any;

  countrySelectConfig: GbxsoftSelectConfig = {
    id: 'id',
    label: 'text',
    notFoundText: this.t.instant('Global.notFoundResults'),
    searching: true,
    multiple: false,
    allowClear: false,
    closeOnClear: false,
    closeOnSelect: true,
    addTag: false,
    enableUnselect: false,
    placeholder: this.t.instant('Address.country'),
    labelName: this.t.instant('Address.country'),
    valueName: 'id',
  };

  countrySelectOptions = countries;

  constructor(public changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    this.autocompleteInstance = new google.maps.places.Autocomplete(
      this.autocomplete.gbxsoftInput.nativeElement,
      {
        types: ['geocode'],
      },
    );
    this.autocompleteInstance.setFields(['address_component', 'formatted_address']);
    this.autocompleteListener = google.maps.event.addListener(
      this.autocompleteInstance,
      'place_changed',
      () => {
        this.setAddressComponents(this.autocompleteInstance.getPlace());
      },
    );
    this.changes.detectChanges();
  }

  setAddressComponents(place: any) {
    let street = '';
    let streetNumber = '';
    place.address_components.map((component: GoogleAddressComponent) => {
      switch (component.types[0]) {
        case GoogleAdressComponentType.POSTAL_CODE:
          this.form.get(this.config.postalCodeControlName).setValue(component.long_name);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.TOWN:
          this.form.get(this.config.townControlName).setValue(component.long_name);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.COUNTRY:
          const country = countries.filter((c) => c.id === component.short_name)[0];
          this.form.get(this.config.countryControlName).setValue(country);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.ROUTE:
          street = component.long_name;
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.STREET_NUMBER:
          streetNumber = component.long_name;
          this.changes.detectChanges();
          break;
      }
    });

    this.form.get(this.config.adrressControlName).setValue(!!street ? `${street} ${streetNumber}` : null);
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return messages;
  }

  ngOnDestroy() {
    google.maps.event.removeListener(this.autocompleteListener);
    google.maps.event.clearInstanceListeners(this.autocompleteInstance);
    $('.pac-container').remove();
  }
}

export interface AddressAutcompleteConfig {
  adrressControlName?: string;
  postalCodeControlName?: string;
  townControlName?: string;
  countryControlName?: string;
}
