import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AttachmentsModule } from '../attachments/attachments.module';
import { EntryModule } from '../entry/entry.module';
import { CommentaryItemComponent } from './components/commentary-item/commentary-item.component';
import { CommentaryManageComponent } from './components/commentary-manage/commentary-manage.component';
import { CommentaryService } from './services/commentary.service';
import { UIModule } from '@shared/modules/ui/ui.module';
import { CommentaryManageV2Component } from './components/commentary-manage-v2/commentary-manage-v2.component';

export const COMMENTARY_IMPORTS = [
  CoreModule,
  TranslateModule,
  AttachmentsModule,
  AngularSvgIconModule,
  UIModule,
  EntryModule,
  AttachmentsModule,
];

export const COMMENTARY_EXPORT = [CommentaryManageComponent, CommentaryManageV2Component];
export const COMMENTARY_COMPONENTS = [
  CommentaryItemComponent,
  CommentaryManageComponent,
  CommentaryManageV2Component,
];

export const COMMENTARY_PROVIDERS = [CommentaryService];
