import { PermissionsConfig } from '@core/permissions/interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProjectPerson } from '@modules/project-creator/shared/enums/project-person.enum';

export class ExistingProjectConfigurationPermissionConfig implements PermissionsConfig {
  static readonly PREVIEW_STEP_1: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly EDIT_STEP_1: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly PREVIEW_STEP_2: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly EDIT_STEP_2: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly PREVIEW_STEP_2_DOCUMENTS: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly EDIT_STEP_2_DOCUMENTS: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly PREVIEW_STEP_3: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly EDIT_STEP_3: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly PREVIEW_STEP_4: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly EDIT_STEP_4: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.EXISTING_PROJECT_CONFIGURATOR;
}
