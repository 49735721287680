export class ListEvent<T> {
  type: ListEventType;
  data?: T | any;
}

export enum ListEventType {
  QUERY_PARAMS,
  CONFIG_COLUMNS,
  START_GET_ROWS,
  END_GET_ROWS,
  ROW_REMOVED,
  ROW_ADD,
  ALL_CHECKBOXES_SELECT,
  ALL_CHECKBOXES_UNSELECT,
  SINGLE_CHECKBOX_SELECT,
  SINGLE_CHECKBOX_UNSELECT,
  CLEAR_CHECKBOX_SELECTION,
}
