<div class="no-title">
  <modal-title></modal-title>
  <mat-dialog-content>
    <div class="text-center modal-protocols">
      <h2 class="blue-500 modal-protocols-title" [innerHTML]="'Protocols.selectProtocol' | translate"></h2>
      <div class="container">
        <ul class="row">
          <li class="col-sm-4">
            <div
              (click)="createNewDraft(ProtocolTypes.TYPE_MEETING)"
              [title]="'Protocols.meetingProtocol' | translate"
            >
              <div>
                <img draggable="false"
                  src="./assets/img/meeting-protocol.svg"
                  [alt]="'Protocols.meetingProtocol' | translate"
                />
              </div>
              <span [innerHTML]="'Protocols.meetingProtocol' | translate"></span>
            </div>
          </li>
          <li class="col-xs-12 col-sm-4">
            <div
              (click)="createNewDraft(ProtocolTypes.TYPE_PROJECT)"
              [title]="'Protocols.projectProtocol' | translate"
            >
              <div>
                <img draggable="false"
                  src="./assets/img/project-protocol.svg"
                  [alt]="'Protocols.projectProtocol' | translate"
                />
              </div>
              <span [innerHTML]="'Protocols.projectProtocol' | translate"></span>
            </div>
          </li>
          <li class="col-xs-12 col-sm-4">
            <div
              (click)="createNewDraft(ProtocolTypes.TYPE_ACCEPTANCE)"
              [title]="'Protocols.acceptanceProtocol' | translate"
            >
              <div>
                <img draggable="false"
                  src="./assets/img/acceptance-protocol.svg"
                  [alt]="'Protocols.acceptanceProtocol' | translate"
                />
              </div>
              <span [innerHTML]="'Protocols.acceptanceProtocol' | translate"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      (click)="dialogRef.close()"
      class="btn btn-cancel"
      [innerHTML]="'Protocols.discard' | translate"
    ></button>
  </mat-dialog-actions>
</div>
