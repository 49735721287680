<list-single-filter-v2
  [template]="filter"
  [name]="name"
  [form]="form"
  [control]="control"
  [controlName]="controlName"
  class="w-100 w-sm-auto"
></list-single-filter-v2>

<ng-template #filter>
  <div class="new-list-filter-single__value" [ngClass]="{ 'new-list-filter-single__value--chosen': isValue }">
    <ng-select
      ngSelectExtension
      [clearAllText]="'clearAll' | translate"
      class="new-select strict-width"
      #select="ngSelectExtensionDir"
      [url]="url ? url : null"
      (change)="onChange()"
      (open)="open($event)"
      [searchGetParam]="searchGetParams"
      [formControl]="control ? control : form.get(controlName)"
      [bindValue]="bindValue"
      [loadingSpinnerDisabled]="false"
      [closeOnSelect]="!multiple"
      [translateLabelPrefix]="translateLabelPrefix"
      [items]="items"
      [responseTransform]="responseTransform ? responseTransform : null"
      [bindLabel]="bindLabel"
      [multiple]="multiple"
      [hideSelected]="!multiple"
      [loadingText]="'loading' | translate"
      [notFoundText]="'Protocols.notFoundText' | translate"
      [placeholder]="'List.Filters.notChosen' | translate"
      [tabIndex]="-1"
      [sortByField]="sortByField"
      (response)="handleItemsResponse($event)"
    >
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-search="searchTerm" let-index="index">
        <div *ngIf="multiple">
          <svg-icon *ngIf="item$.selected" [src]="'/assets/img/check-checked-icon.svg'"></svg-icon>
          <div *ngIf="!item$.selected" class="not-selected"></div>
        </div>
        <span class="ng-arrow-option">{{
          valueTransform ? valueTransform(item) : select.getTranslatedLabel(item[bindLabel])
        }}</span>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="isValue">
        <span class="ng-value-label">{{
          valueTransform ? valueTransform(item) : select.getTranslatedLabel(item[bindLabel])
        }}</span>
      </ng-template>

      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!translateLabelPrefix">
        <span *ngIf="select.select.selectedItems.length === 1" class="ng-value-label"
          >{{ valueTransform ? valueTransform(items[0]) : select.getTranslatedLabel(items[0][bindLabel]) }}
        </span>

        <span *ngIf="select.select.selectedItems.length > 1" class="ng-value-label"
          >{{ 'Global.selected' | translate : { count: select.select.selectedItems.length } }}
        </span>
      </ng-template>
    </ng-select>
  </div>
</ng-template>
