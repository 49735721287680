import { NavigateService } from '@shared/services/navigate.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { Injector } from '@angular/core';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class CalendarController {
  n: NavigateService;
  injector: Injector;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.n = this.injector.get(NavigateService);
  }

  @Permission({
    group: PermissionsGroups.CALENDAR,
    action: 'PREVIEW'
  })
  openEmployeeCalendar(employeeIds: number[]) {
    this.n.go('calendar', {}, {
      queryParams: {
        employees: '[' + employeeIds.toString() + ']'
      }
    });
  }
}
