import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { IAddTeamMember } from '@modules/projects/shared/interfaces/add-team-member.interface';
import { Config } from '@shared/configs/config';
import { Observable } from 'rxjs';
import { Project } from '@modules/projects/shared/models/project.model';

@Injectable({providedIn: 'root'})
export class ProjectTeamService extends BaseHttpService {
  constructor() {
    super();
  }

  addTeamMember(data: IAddTeamMember, projectId: number): Observable<Project> {
    const url = `${Config.API}/project/${projectId}/employee`;
    return this.post(url, data);
  }

  removeTeamMember(employeeId: number, projectId: number) {
    const url = `${Config.API}/project/${projectId}/employee/${employeeId}`;
    return this.delete(url, false);
  }
}
