import { ProjectManageService } from './project-manage.service';
import { map } from 'rxjs/operators';
import { Config } from '@shared/configs/config';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Project } from '../models/project.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Observable } from 'rxjs';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectDataBox } from '../models/project-data-box.model';
import { Employee } from '@shared/models/employee.model';

@Injectable({ providedIn: 'root' })
export class ProjectAPIService extends BaseHttpService {
  constructor(public manager: ProjectManageService) {
    super();
  }

  getProjectShareLink(id: number) {
    const url = `${Config.API}/project-data-box/simple-2/${id}/share-token`;
    return this.post(url, {});
  }

  projectWithoutShare(id: number) {
    const url = `${Config.API}/project/${id}/without-sharing`;
    return this.post(url, {});
  }

  createProjectDraft() {
    const url = `${Config.API}/project/draft`;
    return this.post(url, {});
  }

  findProjectByID(id: number) {
    const url = `${Config.API}/project/${id}`;
    return this.get(url).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  editProjectDataBox(id: number, data: any) {
    const url = `${Config.API}/project-data-box/basic/${id}`;
    return this.post(url, data).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  assignMainContactToProject(projectid: number, contactID: number): Observable<Project> {
    const url = `${Config.API}/project-data-box/basic/${projectid}/main-client/${contactID}`;
    return this.post(url, {}).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  assignResponsibleContactToProject(projectid: number, responsibleId: number): Observable<Project> {
    const url = `${Config.API}/project/${projectid}/responsible`;
    return this.post(url, { responsibleId }).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  assignMainAndResponsibleContact(projectid: number, data: any): Observable<Project> {
    const url = `${Config.API}/project-data-box/basic/${projectid}/client-in-project`;
    return this.post(url, data).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  assignBasicContactToProject(
    projectid: number,
    contactID: number,
    isResponsible: boolean,
  ): Observable<Project> {
    const url = `${Config.API}/project-data-box/basic/${projectid}/client/${contactID}`;
    return this.post(url, { isResponsible }).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  activateProject(projectid: number) {
    const url = `${Config.API}/project/${projectid}/active`;
    return this.post(url, {});
  }

  addContactToProject(contactId: number, projectId: number, isResponsible?: boolean): Observable<Project> {
    let body = {};
    if (isNotNullOrUndefined(isResponsible)) {
      body['isResponsible'] = isResponsible;
    }
    const url = `${Config.API}/project-data-box/basic/${projectId}/client/${contactId}`;
    return this.post(url, body);
  }

  removeContactFromProject(accessContactId: number) {
    const url = `${Config.API}/contact-project/${accessContactId}`;
    return this.delete(url);
  }

  removeContactFromProjectWithoutAccessContact(contactId: number, projectId: number) {
    const url = `${Config.API}/project/${projectId}/contact/${contactId}`;
    return this.delete(url);
  }

  inviteNewContactToProject(
    contactId: number,
    email: string,
    role: UserRoles,
    hasAccess: boolean = false,
    projectId: number,
  ) {
    const url = `${Config.API}/contact/${contactId}/invite-new`;
    const body = {
      email,
      role,
      hasAccess,
      projectId,
    };
    return this.post(url, body);
  }

  inviteExistContactToProject(
    contactId: number,
    employeeId: number,
    hasAccess: boolean = false,
    projectId: number,
    isMain: boolean = false,
  ) {
    const url = `${Config.API}/contact/${contactId}/invite-existing`;
    const body = {
      employeeId,
      isMain,
      hasAccess,
      projectId,
    };
    return this.post(url, body);
  }

  getProject(id: number) {
    const url = `${Config.API}/project/${id}`;
    return this.get(url).pipe(map((p) => new Project(p)));
  }

  getProjectsForStage(stage: ProjectStage, onlyFavourite: boolean = false) {
    const url = `${Config.API}/project/stage/${stage}`;
    return this.get(url, false, { onlyFavourite: onlyFavourite ? 1 : 0 }).pipe(
      map((projects: Project[]) => {
        return projects.map((p) => new Project(p));
      }),
    );
  }

  editSharedSecondBoxWithToken(token: string, data: any) {
    const url = `${Config.API}/project-data-box/simple-2/${token}`;
    return this.post(url, data);
  }

  getSharedSecondBox(token: string): Observable<ProjectDataBox> {
    const url = `${Config.API}/project-data-box/simple-2/${token}`;
    return this.get(url, true);
  }

  shareSecondBox(projectId: number, email: string) {
    const url = `${Config.API}/project-data-box/simple-2/${projectId}/share`;
    return this.post(url, { email }, false, false);
  }

  shareSecondBoxWithToken(projectId: number, email: string) {
    const url = `${Config.API}/project-data-box/simple-2/${projectId}/share-with-token`;
    return this.post(url, { email }, false, false);
  }

  setAlerts(projectId: number, costsLimit: number[]) {
    const url = `${Config.API}/alert/project-costs-limit`;
    const body = {
      projectId,
      costsLimit,
    };
    return this.post(url, body, false, false);
  }

  setFavourite(projectId: number) {
    const url = `${Config.API}/favourite/project/${projectId}`;
    return this.post(url, {}, false, false);
  }

  removeFavourite(projectId: number) {
    const url = `${Config.API}/favourite/project/${projectId}`;
    return this.delete(url);
  }

  setSpecification(projectId: number, specification: File) {
    const url = `${Config.API}/project/${projectId}/specification`;
    let body = {
      specification,
    };
    return this.post(url, body, false, true);
  }

  setResponsibleEmployee(projectId: number, employeeId: number) {
    const url = `${Config.API}/project-data-box/basic/${projectId}/responsible-employee`;
    let body = {
      employeeId,
    };
    return this.post(url, body, false, false);
  }

  removeProject(projectId: number) {
    const url = `${Config.API}/project/${projectId}`;
    return this.delete(url);
  }

  getKonceptPDF(projectId: number) {
    const url = `${Config.API}/project/${projectId}/pdf?timestamp=${Date.now()}`;
    return this.getFileBlob(url, false);
  }

  updateBox2(id: number, data: any) {
    const url = `${Config.API}/project-data-box/simple-2/${id}`;
    return this.post(url, data).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  updateBox3(id: number, data: any) {
    const url = `${Config.API}/project-data-box/simple-3/${id}`;
    return this.post(url, data).pipe(map((p) => this.manager.successFindProjectByID(p)));
  }

  getAssignedEmployees(projectId: number) {
    const url = `${Config.API}/project/${projectId}/employees`;
    return this.get(url);
  }

  getAssignedEmployeesAndEmail(projectId: number): Observable<{ emails: string[]; employees: Employee[] }> {
    const url = `${Config.API}/project/${projectId}/tasks-employees`;
    return this.get(url);
  }
}
