import {PipeTransform, Pipe, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    if(!value) return '';
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.domSanitizer.bypassSecurityTrustHtml(value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, " ")));
  }

}
