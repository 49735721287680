import { CanDeactivateProjectCreation } from '@modules/projects/shared/guards/canDeactivateProjectCreation.guard';
import { ProjectsConfiguratorComponent } from '@modules/projects/modules/projects-configurator/projects-configurator.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectConfiguratoRoutes: CustomRoutes = [
  {
    path: '',
    component: ProjectsConfiguratorComponent,
    data: {
      edit: true,
      useExistingProjectPermissions: true,
    },
    canDeactivate: [CanDeactivateProjectCreation],
  },
];
