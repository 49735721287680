import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'project-remove-modal',
  templateUrl: './project-remove-modal.component.html',
})
export class ProjectRemoveModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  project: Project;
  loading: boolean = false;
  eventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ProjectRemoveModalComponent>,
    private service: ProjectAPIService,
    private t: TranslateService,
    private s: SnackBarService,
    private n: NavigateService,
  ) {}

  ngOnInit(): void {}

  submit() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.service
      .removeProject(this.project.id)
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Projects.RemoveModal.successRemoveProject'));
          this.n.go('projects-list');
          this.eventEmitter.emit(true);
          this.dialogRef.close();
        },
        error: () => {
          this.s.error(this.t.instant('Projects.RemoveModal.errorRemoveProject'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
