<div class="signup-navigation d-flex flex-column justify-content-between">
  <div>
    <div (click)="n.go(Config.MAIN_VIEW)" class="header-logo d-flex align-items-center mb-4">
      <img draggable="false" src="/assets/img/logo.svg" [alt]="'projectName' | translate" />
    </div>

    <ul>
      <li>
        <a draggable="false" [title]="'Auth.Menu.about' | translate">{{ 'Auth.Menu.about' | translate }}</a>
      </li>
    </ul>
  </div>

  <div class="d-flex align-items-center">
    <app-button
      [class]="'font-400'"
      (click)="n.go('login')"
      [config]="{
        text: 'Auth.Login.signIn' | translate,
        type: ButtonTypes.PRIMARY_WHITE
      }"
    ></app-button>
    <app-button
      (click)="n.go('register')"
      class="ml-2"
      [class]="'font-400'"
      [config]="{
        text: 'Auth.Login.createAccountFree' | translate,
        type: ButtonTypes.PRIMARY_BLUE
      }"
    ></app-button>
  </div>
</div>
