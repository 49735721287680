<list-single-filter [template]="filter"
                    [name]="name"
                    [iconUrl]="iconUrl"
                    [form]="form"
                    [control]="control"
                    [controlName]="controlName"
                    class="w-100 w-sm-auto"></list-single-filter>

<ng-template #filter>
  <div class="list-filter-single__value" [ngClass]="{ 'list-filter-single__value--chosen': isValue }">
    <ng-select
      ngSelectExtension
      [clearAllText]="'clearAll' | translate"
      class="mini-select"
      #select="ngSelectExtensionDir"
      [url]="url ? url : null"
      (change)="change.emit()"
      (open)="open($event)"
      [searchGetParam]="searchGetParams"
      [formControl]="control ? control : form.get(controlName)"
      [bindValue]="bindValue"
      [loadingSpinnerDisabled]="true"
      [closeOnSelect]="!multiple"
      [translateLabelPrefix]="translateLabelPrefix"
      [items]="items"
      [responseTransform]="responseTransform ? responseTransform : null"
      [bindLabel]="bindLabel"
      [multiple]="multiple"
      [hideSelected]="true"
      [loadingText]="'loading' | translate"
      [notFoundText]="'Protocols.notFoundText' | translate"
      [placeholder]="'List.Filters.notChosen' | translate"
      [tabIndex]="-1">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span class="ng-arrow-option">{{ valueTransform ? valueTransform(item) : select.getTranslatedLabel(item[bindLabel]) }}</span>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="isValue">
        <span class="ng-value-label">{{ valueTransform ? valueTransform(item) : select.getTranslatedLabel(item[bindLabel]) }}</span>
      </ng-template>

      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!translateLabelPrefix">
            <span *ngIf="select.select.selectedItems.length === 1"
                  class="ng-value-label">{{ valueTransform ? valueTransform(items[0]) : select.getTranslatedLabel(items[0][bindLabel]) }}
            </span>

        <span
          *ngIf="select.select.selectedItems.length > 1"
          class="ng-value-label">{{ 'Global.selected' | translate: {count: select.select.selectedItems.length} }}
            </span>

      </ng-template>
    </ng-select>

    <div class="list-filter-single__actions">
      <span class="list-filter-single__clear" (click)="clear()" *ngIf="isValue">×</span>
      <span class="list-filter-single__arrow" (click)="onArrowClick()"></span>
    </div>

  </div>
</ng-template>
