import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectProtocolsComponent } from '@modules/projects/modules/project-protocols/pages/project-protocols/project-protocols.component';
import { ProtocolType } from '@shared/enums/protocol-type.enum';

export const ProjectProtocolsRoutes: CustomRoutes = [
  {
    path: '',
    component: ProjectProtocolsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'all',
      },
      {
        path: 'all',
        name: 'project-protocols-list-all',
        data: {
          edit: true,
          metaTitle: 'Protocols.list',
        },
      },
      {
        path: 'acceptance',
        name: 'project-protocols-list-acceptance',
        data: {
          edit: true,
          type: ProtocolType.TYPE_ACCEPTANCE,
          metaTitle: 'Protocols.listAcceptance',
          notRestorationPosition: true,
        },
      },
      {
        path: 'project',
        name: 'project-protocols-list-project',
        data: {
          edit: true,
          type: ProtocolType.TYPE_PROJECT,
          metaTitle: 'Protocols.listProject',
          notRestorationPosition: true,
        },
      },
      {
        path: 'meeting',
        name: 'project-protocols-list-meeting',
        data: {
          edit: true,
          type: ProtocolType.TYPE_MEETING,
          metaTitle: 'Protocols.listMeeting',
          notRestorationPosition: true,
        },
      },
      {
        path: 'drafts',
        name: 'project-protocols-list-drafts',
        data: {
          edit: true,
          type: ProtocolType.TYPE_DRAFT,
          metaTitle: 'Protocols.listDrafts',
          notRestorationPosition: true,
        },
      },
    ],
  },
];
