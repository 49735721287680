export enum MailingForm {
  PROJECT = 'project',
  TEMPLATE = 'template',
  RECIEVER = 'email',
  TITLE = 'title',
  NAME = 'name',
  DESCRIPTION = 'content',
  DESCRIPTION_END = 'content_end',
  ATTACHMENTS = 'attachments',
}
