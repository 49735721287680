import { Component, Injector, Input, ReflectiveInjector } from '@angular/core';
import { CHIPS_VIEW, ListService } from '../../services/list.service';

@Component({
  selector: 'app-list-chips-active-filters',
  templateUrl: './list-chips-active-filters.component.html',
  styleUrls: ['./list-chips-active-filters.component.scss'],
})
export class ListChipsActiveFiltersComponent  {
  @Input() component;

  myInjector: Injector;

  constructor(public service: ListService, private injector: Injector) {
    this.myInjector = ReflectiveInjector.resolveAndCreate(
      [{ provide: CHIPS_VIEW, useValue: true }],
      this.injector,
    );
  }
}
