import { SubtasksModule } from './../subtasks/subtasks.module';
import { CommentaryModule } from '@shared/modules/commentary/commentary.module';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { TasksModule } from './../tasks/tasks.module';
import { SharedModule } from '@shared/shared.module';
import { GbxsoftFormModule } from '@form/src/public-api';
import { TASK_SIDENAV_COMPONENTS_EXPORT, TASK_SIDENAV_COMPONENTS } from '.';
import { NgModule } from '@angular/core';
import { AttachmentsManageModule } from '../attachment-manage/attachments-manage.module';
import { CoreModule } from '@core/core.module';
@NgModule({
  declarations: [TASK_SIDENAV_COMPONENTS, TASK_SIDENAV_COMPONENTS_EXPORT],
  imports: [
    CoreModule,
    SharedModule,
    GbxsoftFormModule,
    AttachmentsManageModule,
    CommentaryModule,
    TasksModule,
    NgxPrettyCheckboxModule,
    SubtasksModule,
  ],
  exports: [TASK_SIDENAV_COMPONENTS_EXPORT],
  providers: [],
})
export class TaskSidenavModule {}
