import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'list-cell-creator',
  templateUrl: './list-cell-creator.component.html',
  styleUrls: ['./list-cell-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCellCreatorComponent implements OnInit {

  @Input() created: string;
  @Input() name: string;
  @Output() onCreatorClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
