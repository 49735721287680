import { TaskViewState } from '@shared/enums/task-view-state.enum';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskType } from '@shared/enums/task-type.enum';

@Component({
  templateUrl: './task-discard.component.html',
})
export class TaskDiscardComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  get isCreation() {
    return this.data.viewState === TaskViewState.CREATE;
  }

  get isConfigChange() {
    return this.data.type === TaskType.TYPE_CHANGE;
  }

  constructor(
    public dialogRef: MatDialogRef<TaskDiscardComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { type: TaskType; viewState: number; confirm: () => void },
  ) {}

  confirm() {
    this.dialogRef.close();
    this.data?.confirm();
  }
}
