import { Config } from '@shared/configs/config';
import { BaseHttpService } from '@core/http/base-http.service';
import { Observable } from 'rxjs';
import { RecievedAcceptance } from '../interfaces/recieved-acceptance.interface';
import { Injectable } from '@angular/core';
import { ITaskAccept } from '../interfaces/task-accept.inerface';

@Injectable({ providedIn: 'root' })
export class ClientAcceptanceService extends BaseHttpService {
  constructor() {
    super();
  }

  getProtocolAcceptance(token: string): Observable<RecievedAcceptance> {
    const url = `${Config.API}/protocol-acceptance/${token}`;
    return this.get(url);
  }

  getProtocolAcceptancePDF(token: string, skipAuth = true): Observable<Blob> | Observable<File> {
    const url = `${Config.API}/protocol-acceptance/${token}/pdf`;
    return this.getFileBlob(url, skipAuth);
  }

  sentAcceptance(token: string, data: { accepted: boolean; note: string }, skipAuth = true) {
    const url = `${Config.API}/protocol-acceptance/${token}/accept`;
    return this.post(url, data, skipAuth);
  }

  getTaskChangesAcceptance(token: string) {
    const url = `${Config.API}/task-share/change-acceptance/${token}`;
    return this.get(url, true);
  }

  acceptTask(model: ITaskAccept) {
    const url = `${Config.API}/task-share/change-acceptance/${model.token}`;
    return this.post(url, model, true);
  }

  getEventByToken(token: string) {
    const url = `${Config.API}/calendar-token/${token}`;
    return this.get(url);
  }

  acceptEventByToken(token: string) {
    const url = `${Config.API}/calendar-token/${token}/confirm`;
    return this.post(url, {});
  }

  rejectEventByToken(token: string) {
    const url = `${Config.API}/calendar-token/${token}/reject`;
    return this.post(url, {});
  }

  getChangesSharedSummary(token: string) {
    const url = `${Config.API}/task-share/change-acceptance/${token}/summary`;
    return this.get(url);
  }
}
