<label class="form-control-radio" [ngClass]="{ 'form-control-radio--checked': checked }">
  <input type="radio" [checked]="checked" />
  <span></span>
  <div class="radio-box">
    <div class="mb-2 radio-box-name threeDots">
      {{ title }}
    </div>
    <div
      class="radio-box-position"
      [ngClass]="{ 'radio-box-position--small-text': description?.length > 40 || !!smallText }"
    >
      {{ description }}
    </div>
  </div>
</label>
