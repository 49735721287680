<modal-title [title]="title"></modal-title>
<!-- #region Content -->
<mat-dialog-content>
  <form [formGroup]="form">
    <ng-container *ngIf="type === ProtocolAcceptanceType.ACCEPT">
      <h4 [innerHTML]="'Protocols.acceptProtocol' | translate"></h4>
      <p class="mb-3" [innerHTML]="'Protocols.acceptProtocolDescr' | translate"></p>
    </ng-container>
    <ng-container *ngIf="type === ProtocolAcceptanceType.DECLINE">
      <h4 [innerHTML]="'Protocols.declineProtocol' | translate"></h4>
      <p class="mb-3" [innerHTML]="'Protocols.declineProtocolDescr' | translate"></p>
    </ng-container>
    <gbxsoft-textarea
      class="textarea"
      formControlName="note"
      [config]="{ name: 'Protocols.addCommentary' | translate, type: 'text', placeholder: '' }">
    </gbxsoft-textarea>
  </form>
  <!-- #endregion -->

  <!-- #region Actions -->

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-cancel mr-md-2 mb-2 mb-md-0 btn-sm-block" (click)="closeModal()">
    {{ 'Protocols.cancel' | translate }}
  </button>

  <button
    class="btn btn-primary ml-md-1 btn-sm-block"
    (click)="submit()"
    [disabled]="loading"
    *ngIf="type === ProtocolAcceptanceType.ACCEPT"
  >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
    <img draggable="false"
      *ngIf="!loading"
      src="./assets/img/ic-save.svg"
      [alt]="'Protocols.acceptProtocolBtn' | translate"
    />
    {{ 'Protocols.acceptProtocolBtn' | translate }}
  </button>

  <button
    class="btn btn-danger ml-md-1 btn-sm-block"
    (click)="submit()"
    [disabled]="loading"
    *ngIf="type === ProtocolAcceptanceType.DECLINE"
  >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
    <img draggable="false"
      *ngIf="!loading"
      src="./assets/img/ic-close.svg"
      [alt]="'Protocols.declineProtocolBtn' | translate"
    />
    {{ 'Protocols.declineProtocolBtn' | translate }}
  </button>
</mat-dialog-actions>
<!-- #endregion -->
