import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { NgOption } from '@ng-select/ng-select';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { Config } from '@shared/configs/config';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_DATA_FORM_BOX } from '../../consts/project-data-form-box';
import { BaseDirectoryComponent } from '../base-directory.component';
import * as moment from 'moment';
import { Project } from '@modules/projects/shared/models/project.model';

@Component({
  selector: 'directory-first-step-two',
  templateUrl: './directory-first-step-two.component.html',
  styleUrls: ['./directory-first-step-two.component.scss'],
})
export class DirectoryFirstStepTwo extends BaseDirectoryComponent implements OnInit, AfterViewInit {
  CONFIG = Config;
  PROJECT_DATA_FORM_BOX = PROJECT_DATA_FORM_BOX;
  initialWorkToDo: NgOption;
  initialBudget: NgOption;

  @Input() form: FormGroup;
  @ViewChild('datepicker') datepicker: DateRangePickerComponent;

  get project(): Project {
    return this.projectStore.projectCtrl?.project;
  }

  constructor(private t: TranslateService, public projectStore: ProjectStoreService) {
    super(projectStore);
  }

  ngOnInit() {
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.workToDo, 'initialWorkToDo');
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.budget, 'initialBudget');
  }

  ngAfterViewInit() {
    this.setInitialRange();
  }

  setInitialRange() {
    const start = this.projectStore.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.termStart).value;
    const end = this.projectStore.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.termEnd).value;

    if (start && end) {
      this.datepicker.setRange(start, end);
      return;
    }

    if (this.project?.basicDataBox?.termStart && this.project?.basicDataBox?.termEnd) {
      this.datepicker.setRange(this.project.basicDataBox.termStart, this.project.basicDataBox.termEnd);
    }
  }

  rangeChanged($event) {
    this.projectStore.mainDirectoryFirstForm
      .get(PROJECT_DATA_FORM_BOX.termStart)
      .setValue(!!$event.start ? moment($event.start, Config.DATE_SERVER).format(Config.DATE_SERVER) : null);

    this.projectStore.mainDirectoryFirstForm
      .get(PROJECT_DATA_FORM_BOX.termEnd)
      .setValue(!!$event.end ? moment($event.end, Config.DATE_SERVER).format(Config.DATE_SERVER) : null);
  }
}
