import { Directive, OnInit, ElementRef, Input, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[trim]',
  exportAs: 'TrimDirective',
})
export class GbxsoftTrimDirective implements OnInit {
  @Input('ctrl') ctrl: FormControl;
  element: HTMLInputElement | HTMLTextAreaElement;
  constructor(public el: ElementRef) {}

  ngOnInit() {
    this.element = this.el.nativeElement;
  }

  trim() {
    let value = this.element.value;
    const trimmed = value ? value.trim() : '';

    if (value !== trimmed) {
      this.element.value = trimmed;
      this.ctrl ? this.ctrl.setValue(trimmed) : null;
    }
  }

  @HostListener('blur', ['$event'])
  blur(e) {
    this.trim();
  }
}
