import { debounce } from '@shared/decorators/debounce.decorator';
import { Component, OnInit, HostListener, Renderer2, AfterViewInit, OnDestroy, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { WindowHelper } from '@shared/helpers/window.helper';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ButtonGroupConfig } from '@shared/modules/ui/components/button-group/button-group.component';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarStorageService } from '@modules/calendar/shared/services/calendar-storage.service';
import { CalendarMainSectionComponent } from './components/calendar-main-section/calendar-main-section.component';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  PermissionsGroups = PermissionsGroups;
  WindowHelper = WindowHelper;

  viewTypesButtonGroupConfig: ButtonGroupConfig = {
    buttons: [
      {
        name: this.t.instant('ProjectsTimeline.Header.month'),
        prop: 'dayGridMonth',
        active: false,
      },
      {
        name: this.t.instant('ProjectsTimeline.Header.week'),
        prop: 'timeGridWeek',
        active: true,
      },
      {
        name: this.t.instant('ProjectsTimeline.Header.day'),
        prop: 'timeGridDay',
        active: false,
      },
      {
        name: this.t.instant('ProjectsTimeline.Header.list'),
        prop: 'listWeek',
        active: false,
      },
    ],
  };

  get calendar(): Calendar {
    return this.calendarMainSectionComponent.fullCalendarComponent
      ? this.calendarMainSectionComponent.fullCalendarComponent?.getApi()
      : null;
  }

  @ViewChild(CalendarMainSectionComponent) calendarMainSectionComponent!: CalendarMainSectionComponent;
  @ViewChild('viewTypesButtonComponent') viewTypesButtonComponent;

  openedMenu = false;

  constructor(
    public service: CalendarService,
    private renderer: Renderer2,
    private changes: ChangeDetectorRef,
    private storage: CalendarStorageService,
    private t: TranslateService,
  ) {}

  ngOnInit(): void {
    this.service.initialize();
  }

  ngAfterViewInit() {
    this.setCalendarHeaderPosition();

    setTimeout(() => {
      if (!!this.storage.selectedCalendarView) {
        this.calendar.changeView(this.storage.selectedCalendarView);
        this.viewTypesButtonGroupConfig.buttons.map((b) => {
          b.active = this.storage.selectedCalendarView === b.prop;
        });
        this.viewTypesButtonComponent.changes.detectChanges();
        this.changes.detectChanges();
      }
    }, 0);
  }

  private setCalendarHeaderPosition() {
    const calendar = document.querySelector('.calendar__wrapper');
    const header = document.querySelector('calendar-header');
    const calendarContent = document.querySelector('.calendar-main-section__content');
    if (WindowHelper.isLessMD) {
      this.renderer.insertBefore(calendar.parentElement, header, calendar);
    } else {
      this.renderer.insertBefore(calendarContent.parentElement, header, calendarContent);
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  onDesktopResize() {
    this.setCalendarHeaderPosition();
  }

  ngOnDestroy() {
    this.service.resetViewState();
  }

  handleOpen(e) {
    this.openedMenu = e;
  }

  addEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent();
    this.changes.detectChanges();
  }

  addTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(null, null, null, null, null, null, true);
    this.changes.detectChanges();
  }

  onChangeViewType(viewType: string) {
    this.calendar.changeView(viewType);
    this.storage.selectedCalendarView = viewType;
    this.viewTypesButtonGroupConfig.buttons.map((button) => {
      button.active = button.prop === viewType;
    });
    this.viewTypesButtonComponent.changes.detectChanges();
    this.service.updateCalendarView();
    this.changes.detectChanges();
  }
}
