import { Injectable } from '@angular/core';

import { INgxmStepOption } from './step-option.interface';
import { TourService } from 'ngx-tour-core';

@Injectable()
export class NgxmTourService extends TourService<INgxmStepOption> {
  blockChangeStep: boolean = false;

  public start() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      super.start();
    }, 300);
  }

  public next(): void {
    if (!this.blockChangeStep) {
      this.blockChangeStep = true;
      super.next();
    }
  }

  public prev(): void {
    if (!this.blockChangeStep) {
      this.blockChangeStep = true;
      super.prev();
    }
  }
}
