import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectsTimelineComponent } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/projects-timeline.component';

export const ProjectsTimelineRoutes: CustomRoutes = [
  {
    path: '',
    component: ProjectsTimelineComponent,
    data: {
      metaTitle: 'Projects.timeline',
    },
  },
];
