import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ContactsListComponent } from '@modules/contacts/pages/contacts-list/contacts-list.component';
import { ContactsPanelComponent } from '@modules/contacts/pages/contacts-panel/contacts-panel.component';
import { ContactPreviewComponent } from '@modules/contacts/pages/contact-preview/contact-preview.component';
import { ContactManageComponent } from '@modules/contacts/pages/contact-manage/contact-manage.component';
import { ContactPreviewDetailsComponent } from '@modules/contacts/pages/contact-preview/components/contact-preview-details/contact-preview-details.component';
import { ContactPreviewSubcontactsComponent } from '@modules/contacts/pages/contact-preview/components/contact-preview-subcontacts/contact-preview-subcontacts.component';
import { ContactPreviewProjectsComponent } from '@modules/contacts/pages/contact-preview/components/contact-preview-projects/contact-preview-projects.component';
import { ContactPreviewResolver } from '@modules/contacts/pages/contact-preview/components/contact-preview.resolver';
import { ContactManageDeactivateGuard } from '@core/guards/contacts/contact-manage-deactivate.guard';
import { ContactsListGuard } from '@core/guards/contacts/contacts-list.guard';
import { ContactManageActivateGuard } from '@core/guards/contacts/contact-manage-activate.guard';

export const ContactsRoutes: CustomRoutes = [
  {
    path: '',
    component: ContactsPanelComponent,
    data: {
      hideProgressBar: true,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/panel/contacts/list',
      },
      {
        path: 'list',
        name: 'contacts-list',
        canActivate: [ContactsListGuard],
        data: {
          metaTitle: 'Contacts.list',
        },
        component: ContactsListComponent,
      },
      {
        path: 'preview/:id',
        name: 'contact-preview',
        // canActivate: [ContactPreviewGuard],
        data: {
          metaTitle: 'Contacts.preview',
        },
        component: ContactPreviewComponent,
        resolve: {
          contact: ContactPreviewResolver,
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'details',
          },
          {
            path: 'details',
            name: 'contact-preview-details',
            component: ContactPreviewDetailsComponent,
            data: {
              metaTitle: 'Contacts.details',
            },
          },
          {
            path: 'subcontacts',
            name: 'contact-preview-subcontacts',
            component: ContactPreviewSubcontactsComponent,
            data: {
              metaTitle: 'Contacts.subcontacts',
            },
          },
          {
            path: 'projects',
            name: 'contact-preview-projects',
            component: ContactPreviewProjectsComponent,
            data: {
              metaTitle: 'Contacts.projects',
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        name: 'contact-edit',
        canDeactivate: [ContactManageDeactivateGuard],
        canActivate: [ContactManageActivateGuard],
        data: {
          metaTitle: 'Contacts.edit',
          edit: true,
          bottomBar: true,
        },
        component: ContactManageComponent,
      },
      {
        path: 'create/:type',
        name: 'contact-create',
        canDeactivate: [ContactManageDeactivateGuard],
        canActivate: [ContactManageActivateGuard],
        data: {
          metaTitle: 'Contacts.create',
          bottomBar: true,
        },
        component: ContactManageComponent,
      },
    ],
  },
];
