<div class="contact" [ngClass]="{ 'contact--no-borders': withoutBorders }">
  <div
    *ngIf="(contact?.isContactPerson && showContactPerson) || isMainClient || isResponsibleClient"
    class="contact__header d-flex justify-content-between align-items-center mb-3"
  >
    <!-- #region default Contact Title -->
    <ng-container *ngIf="contact?.isContactPerson && showContactPerson">
      <span class="contact__header__person">{{ 'Contacts.Fields.contactPerson' | translate }}</span>

      <span class="contact__header__source cursor-pointer" [matMenuTriggerFor]="contactPersonMenu">
        <b class="d-none d-md-inline-block">{{ 'Contacts.Fields.changeContactPerson' | translate }}</b>
        <b class="d-inline-block d-md-none">{{ 'Contacts.Fields.changeContactPersonShort' | translate }}</b>
      </span>
    </ng-container>
    <!-- #endregion -->

    <!-- #region Client || Responsible Client -->
    <ng-container *ngIf="isMainClient || isResponsibleClient">
      <span *ngIf="isMainClient" class="contact__header__person mr-2">
        {{ 'Contacts.Fields.client' | translate }}
      </span>
      <span *ngIf="isResponsibleClient" class="contact__header__person mr-2">
        {{ 'Contacts.Fields.contactPersonClient' | translate }}
      </span>

      <div class="contact__header-change-type d-flex justify-content-end">
        <span (click)="modalProjectPermission()">
          {{
            (isMainClient ? 'Contacts.Manage.changeMainContact' : 'Contacts.Manage.changeClientResponsible')
              | translate
          }}
        </span>
      </div>
    </ng-container>
    <!-- #endregion -->
  </div>

  <mat-menu #contactPersonMenu="matMenu" xPosition="after">
    <button mat-menu-item (click)="editContactPerson()">
      <span>{{ 'Contacts.Preview.editContactPerson' | translate }}</span>
    </button>
    <button mat-menu-item class="hover_red red-500" (click)="turnOffContactPerson()">
      <span>{{ 'Contacts.Preview.turnOffContactPerson' | translate }}</span>
    </button>
  </mat-menu>
  <div
    class="contact__main d-flex justify-content-between align-items-start align-items-md-center"
    [ngClass]="{ 'no-availability': !showAvailability }"
  >
    <div class="contact__person-info contact__person-column pr-1">
      <div
        class="contact__person-info__name {{
          !isPreviewVisible ? 'contact__person-info__name--hide-link' : ''
        }}"
        (click)="preview()"
      >
        {{ contact?.fullName }}
      </div>
      <tags-record
        [tags]="getTranslatedFunctions(contact?.function)"
        [maxVisibleTags]="1"
        *ngIf="contact?.function?.length"
        class="d-none d-md-block"
      ></tags-record>
    </div>

    <!--    <div *ngIf="showAvailability" class="contact__person-role pr-1 d-none d-md-block">-->
    <!--      <label-info-->
    <!--        [title]="'Contacts.Fields.availability' | translate"-->
    <!--        [value]="contact?.availability ? contact?.availability : ('Contacts.Fields.noData' | translate)"-->
    <!--      ></label-info>-->
    <!--    </div>-->

    <div class="contact__person-digital pr-1">
      <phone-info
        [class]="'mt-1 mt-md-0'"
        [phoneCountry]="contact?.phoneCountry"
        [phone]="contact?.fullPhone"
        *ngIf="contact?.phone"
      ></phone-info>
      <label-info
        [class]="'mt-2 mt-md-0'"
        [noTitle]="true"
        *ngIf="contact?.email"
        [value]="'<a draggable=\'false\' href=\'mailto:' + contact?.email + '\'>' + contact?.email + '</a>'"
      ></label-info>
      <tags-record
        [tags]="getTranslatedFunctions(contact?.function)"
        [maxVisibleTags]="2"
        *ngIf="contact?.function?.length && showMobileTags"
        class="d-block d-md-none mt-2"
      ></tags-record>
    </div>

    <div class="contact__person-actions d-flex align-items-start justify-content-end" *ngIf="!withoutActions">
      <div class="d-inline-flex align-items-center">
        <app-button
          [permission]="permissionEdit"
          [class]="'d-none d-md-block'"
          (onClick)="edit()"
          [config]="{
            type: ButtonTypes.SECONDARY_BLUE,
            size: ButtonSize.SMALL,
            text: 'Contacts.Preview.edit' | translate,
            prependSrc: './assets/img/ic-edit-3.svg'
          }"
        ></app-button>
        <button class="btn-icon" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button *ngIf="isPreviewVisible" mat-menu-item (click)="preview()">
            <span>{{ 'Contacts.Preview.preview' | translate }}</span>
          </button>

          <button *ngIf="extraConfig?.addSubContact" (click)="applySubContact()" mat-menu-item>
            <span>{{ 'Contacts.Preview.addSubContact' | translate }}</span>
          </button>

          <button *ngIf="extraConfig?.assignAsMainContact" (click)="setContactMainContact()" mat-menu-item>
            <span>{{ 'Contacts.Preview.applyAsMainContact' | translate }}</span>
          </button>

          <button
            *ngIf="extraConfig?.assignAsResponsibleContact"
            (click)="setResponsibleContact()"
            mat-menu-item
          >
            <span>{{ 'Contacts.Preview.applyAsResponsibleContact' | translate }}</span>
          </button>

          <button mat-menu-item [permission]="permissionEdit" (click)="edit()">
            <span>{{ 'Contacts.Preview.edit' | translate }}</span>
          </button>

          <button
            mat-menu-item
            *ngIf="extraConfig?.fullEdit"
            [permission]="permissionEdit"
            (click)="fullEdit()"
          >
            <span>{{ 'Contacts.Preview.fullEdit' | translate }}</span>
          </button>

          <button *ngIf="isRemoveAction" mat-menu-item class="hover_red red-500" (click)="remove()">
            <span>{{ 'Contacts.Preview.remove' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
