export const CONTACT_FORM = {
    type: 'type',
    firstName: 'firstName',
    email: 'email',
    function: 'function',
    availability: 'availability',
    discount: 'discount',
    lastName: 'lastName',
    birth: 'birth',
    address: 'address',
    postalCode: 'postalCode',
    town: 'town',
    country: 'country',
    phone: 'phone',
    phoneCountry: 'phoneCountry',
    comment: 'comment',
    taxNumber: 'taxNumber',
    taxNumberEU: 'taxNumberEU',
    companyName: 'companyName',
    website: 'website',
    acquisitionSource: 'acquisitionSource',
    links: 'links',
    isNetPrices: 'isNetPrices',
    id: 'id',
    parentContactId: 'parentContactId',
    isContactPerson: 'isContactPerson',
    parentContact: 'parentContact',
    isResponsible: 'isResponsible',
    isMainContact: 'isMainContact',
    contactType: 'contactType',
    status: 'status',
    parentsIds: 'parentsIds',
    parentContacts: 'parentContacts',
};
