import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  SettingEventType,
  SettingsEvent,
  SettingsService,
} from '@modules/settings/shared/services/settings.service';
import { BaseComponent } from '@shared/components/base.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { Company } from '@shared/models/company.model';
import { Regex } from '@shared/configs/regex';
import { addHttp } from '@shared/helpers/add-http.helper';
import { Currency } from '@shared/models/currency.interface';
import { BusinessProfile } from '@shared/interfaces/business-profile.interface';
import { ActivatedRoute } from '@angular/router';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { countries } from '@shared/datas/countries';
import { StorageEventType } from '@core/services/storage.service';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  subSaving: Subscription;

  private readonly FIELDS_WITHOUT_PERCENT = [
    'contactFirstName',
    'contactLastName',
    'contactEmail',
    'contactFunction',
    'contactPhone',
    'contactPosition',
    'contactPhoneCountry',
    'phoneCountry',
    'ownerFirstName',
    'ownerLastName',
    'ownerEmail',
    'ownerPhone',
    'ownerPhoneCountry',
    'iAmOwner',
  ];

  form: FormGroup;

  constructor(
    private service: SettingsService,
    private changes: ChangeDetectorRef,
    private route: ActivatedRoute,
    private footerService: StickyFooterService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.initCompanyData();
    this.subscribeSaving();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submit();
          break;
        default:
          break;
      }
    });
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      taxNumber: new FormControl('', [Validators.maxLength(100)]),
      email: new FormControl('', [Validators.pattern(Regex.email)]),
      website: new FormControl('', [Validators.maxLength(100)]),
      employeesCount: new FormControl(''),
      businessProfile: new FormControl('', [Validators.maxLength(100)]),
      phone: new FormControl('', [Validators.maxLength(100)]),
      phoneCountry: new FormControl(''),
      currency: new FormControl(''),
      postalCode: new FormControl('', [Validators.maxLength(10)]),
      town: new FormControl('', [Validators.maxLength(100)]),
      address: new FormControl('', [Validators.maxLength(100)]),
      country: new FormControl(''),
      ownerFirstName: new FormControl('', [Validators.maxLength(100)]),
      ownerLastName: new FormControl('', [Validators.maxLength(100)]),
      ownerEmail: new FormControl('', [Validators.pattern(Regex.email)]),
      ownerPhone: new FormControl('', [Validators.maxLength(100)]),
      ownerPhoneCountry: new FormControl(''),
      iAmOwner: new FormControl(''),
      logo: new FormControl(''),
      contactFirstName: new FormControl('', [Validators.maxLength(100)]),
      contactLastName: new FormControl('', [Validators.maxLength(100)]),
      contactEmail: new FormControl('', [Validators.pattern(Regex.email)]),
      contactFunction: new FormControl('', [Validators.maxLength(100)]),
      contactPhone: new FormControl('', [Validators.maxLength(100)]),
      contactPhoneCountry: new FormControl(''),
      contactPosition: new FormControl('', [Validators.maxLength(100)]),
      iban: new FormControl('', [Validators.maxLength(100)]),
      euTaxNumber: new FormControl('', [Validators.maxLength(100)]),
      bic: new FormControl('', [Validators.maxLength(100)]),
      bankName: new FormControl('', [Validators.maxLength(100)]),
    });
  }

  initCompanyData() {
    if (!this.store.Company.id) {
      this.setProgressBar(true);
      return;
    }

    this.employee.active
      ? this.service.getCompanyData().subscribe({
          next: this.onSuccessCompanyData.bind(this),
          error: this.onErrorCompanyData.bind(this),
        })
      : null;
    this.form.patchValue(this.store.Company);
    this.store.Company.employeesCount
      ? this.form.get('employeesCount').setValue(this.store.Company.employeesCount)
      : null;
    this.setBusinessProfile();
    this.setCurrency();
    this.setCountry();
    this.setProgressBar();
  }

  setProgressBar(setZero?: boolean) {
    if (setZero) {
      this.service.progressbar = 0;
      return;
    }
    let filledCount = 0;
    let fieldsCount = 0;
    // tslint:disable-next-line: forin
    for (const field in this.form.value) {
      const fieldValue = this.form.value[field];
      if (this.FIELDS_WITHOUT_PERCENT.indexOf(field) === -1) {
        fieldsCount++;
        if (field === 'logo' && !fieldValue && this.store.Company.logo) {
          filledCount++;
        }
        if (field === 'employeesCount' || field === 'currency' || field === 'businessProfile') {
          if (fieldValue || (fieldValue && fieldValue.id)) {
            filledCount++;
          }
        } else if (fieldValue && fieldValue !== '') {
          filledCount++;
        }
      }
    }

    let percent = (filledCount / fieldsCount) * 100;
    if (percent > 100) {
      percent = 100;
    }
    this.service.progressbar = percent;
  }

  setCurrency() {
    const currency: Currency = new Currency(this.store.Company.currency);
    this.store.Company.currency ? this.form.get('currency').setValue(currency.code) : null;
  }

  setCountry() {
    const country = countries.filter((c) => c.id === this.store.Company.country)[0];
    country && country !== undefined ? this.form.get('country').setValue(country.id) : null;
    this.changes.detectChanges();
  }

  setBusinessProfile() {
    let businessProfile: BusinessProfile = null;
    const businessProfiles: BusinessProfile[] = this.store.GlobalData.businessProfiles;
    Object.keys(businessProfiles).forEach((key) => {
      const bP: BusinessProfile = businessProfiles[key];
      if (bP.name === this.store.Company.businessProfile) {
        businessProfile = bP;
      }
    });
    businessProfile ? this.form.get('businessProfile').setValue(businessProfile.name) : null;
  }

  onSuccessCompanyData(company: Company) {
    this.store.Company = company;
  }

  onErrorCompanyData(e: HttpError) {
    this.s.error(this.t.instant('Settings.Company.Errors.companyDataError'));
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.loading = true;
    this.form.get('website').setValue(addHttp(this.form.get('website').value));
    this.service
      .putCompany(this.form.value)
      .subscribe({
        next: this.onSuccessPutCompany.bind(this),
        error: this.onErrorPutCompany.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.changes.detectChanges();
      });
  }

  onSuccessPutCompany(company: Company) {
    this.setProgressBar();
    this.s.success(this.t.instant('Settings.Company.companyDataSaved'));
    this.store.emmiter.emit({ type: StorageEventType.UPDATE_COMPANY, company });
    this.store.Company = company;
  }

  onErrorPutCompany(e: HttpError) {
    switch (e.messageCode) {
      case IntilioCodes.WRONG_EXTENSION:
        this.s.error(this.t.instant('Settings.Company.Errors.wrongFileExtension'));
        break;
      case IntilioCodes.FILE_IS_BIG:
        this.s.error(this.t.instant('Settings.Company.Errors.errorFileTooBig').replace('{{size}}', 10));
        break;
      default:
        this.s.error(this.t.instant('Settings.Company.Errors.companyDataSaveError'));
        break;
    }
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
