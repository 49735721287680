import { Injectable } from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';

@Injectable()
export class MailingDataService {
  private _project: Project;

  set project(_project: Project) {
    this._project = _project;
  }

  get project(): Project {
    return this._project;
  }
}
