import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { SHARED_COMPONENTS } from './consts/shared-components';
import { SHARED_PIPES } from './consts/shared-pipes';
import { SHARED_MODULES, SHARED_MODULES_FOR_ROOT } from './consts/shared-modules';
import { SHARED_DIRECTIVES } from './consts/shared-directives';
import { SidenavService } from './services/sidenav/sidenav.service';
import { SIDENAV_DATA } from './services/sidenav/sidenav.data';
import { GlobalDataResolve } from './resolvers/global-data.resolve';
import { GlobalDataService } from './services/global-data.service';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Config } from './configs/config';
import { FileSignatureController } from './controllers/file-signature.controller';
import { AttachmentService } from './services/attachment.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { SEOService } from './services/seo.service';
import { ConfirmationPopoverOptions } from './libs/angular-confirmation-directive/confirmation-popover-options.provider';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NgxmTourService } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/ngx-md-menu-tour.service';
import { ProtocolOptionTemplateComponent } from './components/protocol-option-template/protocol-option-template.component';
import { EmployeeService } from '@shared/services/employee.service';
import { ContactOptionMultipleTemplateComponent } from './components/contact-option-multiple-template/contact-option-multiple-template.component';

@NgModule({
  declarations: [
    SHARED_COMPONENTS,
    SHARED_PIPES,
    SHARED_DIRECTIVES,
    ProtocolOptionTemplateComponent,
    ContactOptionMultipleTemplateComponent,
  ],
  imports: [SHARED_MODULES, SHARED_MODULES_FOR_ROOT],
  exports: [SHARED_MODULES, SHARED_COMPONENTS, SHARED_PIPES, SHARED_DIRECTIVES],
  providers: [SHARED_PIPES],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CurrencyPipe,
        DecimalPipe,
        SidenavService,
        FileSignatureController,
        SIDENAV_DATA,
        GlobalDataResolve,
        GlobalDataService,
        AuthorizationService,
        SettingsService,
        AttachmentService,
        SEOService,
        ConfirmationPopoverOptions,
        GoogleAnalyticsService,
        EmployeeService,
        NgxmTourService,
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: Config.DATEPICKER_ANGULAR_MATERIAL_FORMATS },
      ],
    };
  }
}
