<div class="search-v2" (click)="searchInput.focus()">
  <svg-icon class="search-icon" [src]="'/assets/img/search-icon.svg'"></svg-icon>
  <input
    #searchInput
    type="text"
    [placeholder]="searchName ? searchName : ('List.Filters.search' | translate)"
    [formControl]="form.get('search')"
    (keydown)="onChange()"
  />
  <svg-icon
    *ngIf="form.get('search')?.value"
    class="clear-icon"
    [src]="'/assets/img/close-icon.svg'"
    (click)="form.get('search').setValue(''); onChange()"
  ></svg-icon>
</div>
