import { NgModule } from '@angular/core';
import { EntryListComponent } from './components/entry-list/entry-list.component';
import { EntryItemComponent } from './components/entry-item/entry-item.component';
import { EntryModalComponent } from './components/entry-modal/entry-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CoreModule } from '@core/core.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { EntryService } from './services/entry.service';

const ENTRY_COMPONENTS_EXPORT = [EntryListComponent];
const ENTRY_COMPONENTS = [...ENTRY_COMPONENTS_EXPORT, EntryItemComponent, EntryModalComponent];
const ENTRY_IMPORTS = [CoreModule, TranslateModule, AttachmentsModule, AngularSvgIconModule];
const ENTRY_PROVIDERS = [EntryService];

@NgModule({
  declarations: [ENTRY_COMPONENTS],
  imports: [ENTRY_IMPORTS],
  exports: [ENTRY_COMPONENTS_EXPORT],
  providers: [ENTRY_PROVIDERS],
})
export class EntryModule {}
