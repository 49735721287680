import { NgModule } from '@angular/core';
import { TASKS_COMPONENTS, TASKS_EXPORT, TASKS_PROVIDERS, TASK_MODULES } from '.';

@NgModule({
  declarations: [TASKS_COMPONENTS],
  imports: [TASK_MODULES],
  exports: [TASKS_EXPORT],
  providers: [TASKS_PROVIDERS],
})
export class TasksModule {}
