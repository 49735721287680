import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigateService } from '@shared/services/navigate.service';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent implements OnInit {
  @ViewChild('el') el: ElementRef;

  Config = Config;

  constructor(public n: NavigateService) {}

  ngOnInit(): void {}
}
