import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Component({
  selector: 'contact-option-template',
  templateUrl: './contact-option-template.component.html',
  styleUrls: ['./contact-option-template.component.scss'],
})
export class ContactOptionTemplateComponent implements OnInit {
  private _contact: Contact;
  @Input() showType: boolean = true;
  @Input() isLabel: boolean = false;
  @Input()
  set contact(contact) {
    this._contact = new Contact(contact);
  }

  get contact() {
    return this._contact;
  }

  constructor() {}

  ngOnInit(): void {}
}
