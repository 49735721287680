<modal-title
  [title]="'Contacts.Preview.Projects.addToProjectTitle' | translate"></modal-title>

<form [formGroup]="form" class="contact-add-to-project">
  <mat-dialog-content>
    <radio-box
      [checked]="form?.get('asContact')?.value"
      [title]="'Contacts.Preview.Projects.addAsContact' | translate"
      [description]="'Contacts.Preview.Projects.addAsContactDescription' | translate"
      (change)="onAddToProjectTypeChange(true)"
    ></radio-box>

    <radio-box
      [checked]="!form?.get('asContact')?.value"
      [title]="'Contacts.Preview.Projects.addAsContactAndCreateAccount' | translate"
      [description]="'Contacts.Preview.Projects.addAsContactAndCreateAccountDescription' | translate"
      (change)="onAddToProjectTypeChange(false)"
    ></radio-box>

    <div class="mt-3">
      <label class="grey-1" *ngIf="!form.get('asContact')?.value && !contact.email"><b>{{'Contacts.Fields.email' | translate}}</b></label>
      <gbxsoft-input *ngIf="!form.get('asContact')?.value && !contact.email"
                     class="email-control special-control required"
                     [errMessages]="errorMessages('email')"
                     formControlName="email"
                     [config]="{ name: 'Contacts.Fields.email' | translate, type: 'email', placeholder: '' }"></gbxsoft-input>
    </div>

    <div class="mt-3" *ngIf="!form.get('asContact')?.value">
      <label class="grey-1"><b>{{'Contacts.Preview.Projects.roleInSystem' | translate}}</b></label>
      <ng-select
        #roleSelect="ngSelectExtensionDir"
        ngSelectExtension
        class="select select-default w-100 ng-select--required"
        [dropdownPosition]="'top'"
        [items]="roles"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [placeholder]="'Contacts.Preview.Projects.chooseRole' | translate"
        [tabIndex]="-1"
        formControlName="role">
      </ng-select>
      <span
        *ngIf="form?.get('role')?.touched && !form?.get('role')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'Contacts.Preview.Projects.notChosenRole' | translate"
      ></span>
    </div>

    <div class="mt-3" >
      <label class="grey-1"><b>{{'Contacts.Preview.Projects.chooseProject' | translate}}</b></label>
      <ng-select
        ngSelectExtension
        class="select select-default w-100 ng-select--required"
        [dropdownPosition]="'top'"
        [url]="CONFIG.API + '/project/search'"
        [clearAllText]="'clearAll' | translate"
        [bindLabel]="'name'"
        [responseTransform]="successOnLoadProjects.bind(this)"
        [loadingText]="'Contacts.Preview.Projects.projectsLoading' | translate"
        [placeholder]="'Contacts.Preview.Projects.project' | translate"
        [notFoundText]="'Contacts.Preview.Projects.notFoundProject' | translate"
        [tabIndex]="-1"
        formControlName="project">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <span class="ng-arrow-option" [innerHTML]="item.display" [title]="item.address"></span>
        </ng-template>
      </ng-select>
      <span
        *ngIf="form?.get('project')?.touched && !form?.get('project')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'Contacts.Preview.Projects.notChosenProject' | translate"
      ></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">

      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Contacts.Preview.Projects.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Contacts.Preview.Projects.add' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
