import { Component, Input } from '@angular/core';

@Component({
  selector: 'attachment-image-more',
  templateUrl: './attachment-img-more.component.html',
  styleUrls: ['./attachment-img-more.component.scss'],
})
export class AttachmentImageMoreComponent {
  @Input() fancyboxUrl: string = '';
  constructor() {}

  triggerFancybox() {
    if (!this.fancyboxUrl) return;
    const gallery: any = $(`[data-fancybox="${this.fancyboxUrl}"]`);
    gallery.eq(0).click();
  }
}
