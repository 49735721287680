import { INotificationManager } from '@modules/notification/shared/interfaces/notification-manager.interface';
import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { INotificationAvatarConfig } from '@modules/notification/shared/interfaces/notification-avatar-config.interface';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { LanguageService } from '@core/services/language.service';
import { Notification } from '@modules/notification/shared/models/notification.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { NotificationBaseComponent } from '@modules/notification/shared/components/notification/types/notification-base/notification-base.component';
import { NotificationType } from '@modules/notification/shared/models/notification-type';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { RangeFormatPipe } from '@shared/pipes/rangeFormat.pipe';
import { NotificationProtocolAcceptanceComponent } from '@modules/notification/shared/components/notification/types/protocol/notification-protocol-acceptance/notification-protocol-acceptance.component';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { NotificationEventComponent } from '@modules/notification/shared/components/notification/types/event/notification-event/notification-event.component';

export class NotificationCalendarManager implements INotificationManager {
  resolver: ComponentFactoryResolver;
  t: TranslateService;
  sidenav: SidenavService;
  avatarConfig: INotificationAvatarConfig = {
    src: '/assets/img/ic-calendar.svg',
    type: 'svg',
    class: 'blue-300',
    hideAvatar: false,
  };
  pService: ProtocolApiService;
  dialog: MatDialog;
  s: SnackBarService;
  langService: LanguageService;
  rangeFormatPipe: RangeFormatPipe = new RangeFormatPipe();
  eventSidenavController: EventSidenavController = new EventSidenavController();

  constructor(private notification: Notification<any>, private markAsShown?: Function) {
    this.resolver = AppInjector.getInjector().get(ComponentFactoryResolver);
    this.t = AppInjector.getInjector().get(TranslateService);
    this.sidenav = AppInjector.getInjector().get(SidenavService);
    this.pService = AppInjector.getInjector().get(ProtocolApiService);
    this.dialog = AppInjector.getInjector().get(MatDialog);
    this.s = AppInjector.getInjector().get(SnackBarService);
    this.langService = AppInjector.getInjector().get(LanguageService);
  }

  getFactory(): ComponentFactory<any> {
    return this.resolver.resolveComponentFactory(NotificationBaseComponent);
  }

  createComponent(content: ViewContainerRef): Function | null {
    let factory = this.getFactory();
    let component = content.createComponent<NotificationBaseComponent>(factory);

    switch (this.notification.type) {

      // (N.4.1)
      case NotificationType.CALENDAR.EVENT_CREATED:
        factory = this.resolver.resolveComponentFactory(NotificationEventComponent);
        component = content.createComponent<NotificationEventComponent>(factory);
        (component.instance as NotificationEventComponent).event = new CalendarEvent(this.notification.data.calendarEvent);
        if (this.notification?.eventParticipant) {
          (component.instance as NotificationEventComponent).status = this.notification.eventParticipant.status;
        }
        this.avatarConfig.class = 'blue-300';
        const sub = (component.instance as NotificationEventComponent).markAsReadEmitter.subscribe(
          () => {
            this.markAsShown ? this.markAsShown() : '';
            sub.unsubscribe();
          },
        );
        component.instance.text = this.t.instant(
          `Notifications.Texts.Calendar.${this.notification.type}`,
          this.getNotificationTextData(),
        );
        return null;

      // (N.4.2)  // (N.4.3)
      case NotificationType.CALENDAR.EVENT_REMINDER:
      case NotificationType.CALENDAR.EVENT_REMOVED:

        component.instance.text = this.t.instant(
          `Notifications.Texts.Calendar.${this.notification.type}`,
          this.getNotificationTextData(),
        );
        if (this.notification.type === NotificationType.CALENDAR.EVENT_REMOVED) {
          this.avatarConfig.class = 'red-500';
          return null;
        } else {
          this.avatarConfig.class = 'blue-300';
          return () => {
            this.eventSidenavController.openPreview(this.notification.data.calendarEvent.id);
          };
        }
    }
  }

  getNotificationTextData() {
    const event: CalendarEvent = new CalendarEvent(this.notification.data.calendarEvent);
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    return {
      eventName: capitalizeFirstLetter(event.name),
      eventDate: capitalizeFirstLetter(this.rangeFormatPipe.transform(event.termStart, event.termEnd, event.isFullDay))
    };
  }
}
