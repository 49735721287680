import { MailingSidenavController } from './../../../../mailing-templates/shared/modules/mailing-sidenav/controllers/mailing-sidenav.controller';
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Config } from '@shared/configs/config';
import { Project } from '@modules/projects/shared/models/project.model';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { Employee } from '@shared/models/employee.model';

@Component({
  selector: 'project-responsible-company-modal',
  templateUrl: './project-responsible-company-modal.component.html',
  styleUrls: ['./project-responsible-company-modal.component.scss'],
})
export class ProjectResponsibleCompanyModalComponent implements OnInit, AfterViewInit {
  readonly responsibleMailingIdNumber = 19;

  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  Config = Config;
  searchRoles: string;

  loading: boolean = false;
  eventEmitter: EventEmitter<Project> = new EventEmitter<Project>();
  project: Project;
  form: FormGroup = new FormGroup({
    responsible: new FormControl(null, [Validators.required]),
  });
  responsibleUrl = Config.API + '/employee/search';
  @ViewChild('responsibleSelect') responsibleSelect: NgSelectExtensionDirective;

  constructor(
    public dialogRef: MatDialogRef<ProjectResponsibleCompanyModalComponent>,
    private service: ProjectAPIService,
    private t: TranslateService,
    private s: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    public data: { project: Project },
  ) {}

  ngOnInit() {
    this.setSearchRoles();
  }

  ngAfterViewInit() {
    this.setResponsible();
  }

  setSearchRoles() {
    const params = new URLSearchParams();
    const roles = [
      UserRoles.ROLE_EMPLOYEE_ADMIN.toString(),
      UserRoles.ROLE_EMPLOYEE_MANAGER.toString(),
      UserRoles.ROLE_EMPLOYEE_WORKER.toString(),
    ];
    roles.forEach((role) => params.append('filters[a.role][in][]', role));
    this.responsibleUrl += '?' + params.toString();

    if (this.project?.basicDataBox?.responsibleEmployee) {
      this.responsibleUrl += '&requiredId=' + this.project?.basicDataBox?.responsibleEmployee.id;
    }
  }

  transformEmployees(list: EmployeeSearchItem[]) {
    return list.map((i) => new EmployeeSearchItem(i).userPerson);
  }

  setResponsible() {
    this.project?.basicDataBox?.responsibleEmployee
      ? this.form.get('responsible').setValue(this.project?.basicDataBox?.responsibleEmployee.id)
      : null;
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.loading) {
      return;
    }
    this.loading = true;

    this.service
      .setResponsibleEmployee(this.project.id, this.form.get('responsible').value)
      .subscribe({
        next: (res: Project) => {
          this.s.success(this.t.instant('Projects.ResponsibleCompany.successSettingResponsible'));
          this.project = new Project(res);
          this.eventEmitter.emit(this.project);
          this.dialogRef.close();

          const _project = this.data.project;
          const project = res;

          // if (!!_project) {
          //   const isResponsibleChanged =
          //     project.basicDataBox.responsibleEmployee.id !== _project.basicDataBox.responsibleEmployee.id;
          //   if (!isResponsibleChanged) return;
          //   const ctrl = new MailingSidenavController();
          //   ctrl.createMailing(this.responsibleMailingIdNumber, {
          //     projectId: project.id,
          //     recieverIds: [
          //       this.project.MainContact.id,
          //       this.project.basicDataBox?.responsibleEmployee?.contacts[0]?.id,
          //     ].filter(Boolean),
          //   });
          // }
        },
        error: () => {
          this.s.error(this.t.instant('Projects.ResponsibleCompany.errorSettingResponsible'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  getEmployee(employee: Employee) {
    return new Employee(employee);
  }
}
