import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { map } from 'rxjs/operators';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { EVENT_SIDENAV_FORM } from '@shared/modules/event-sidenav/enums/event-sidenav-form.enum';
import { PersonalCalendars } from '../models/personal-calendars.model';
import { CalendarEntity } from '../models/calendar-entity.model';
import { CalendarView } from '../models/calendar-view.model';
import { CalendarEventsList } from '@modules/calendar/modules/calendar-events-sidenav/shared/models/calendar-events-list.model';

@Injectable({ providedIn: 'root' })
export class CalendarApiService extends BaseHttpService {
  constructor() {
    super();
  }

  acceptEvent(id: number): Observable<any> {
    const url = `${Config.API}/calendar/event/${id}/confirm`;
    return this.post(url, {});
  }

  declineEvent(id: number): Observable<any> {
    const url = `${Config.API}/calendar/event/${id}/reject`;
    return this.post(url, {});
  }

  getGoogleEvent(googleEventId: string): Observable<CalendarEvent> {
    const url = `${Config.API}/calendar/event/google/${googleEventId}`;
    return this.get(url, false).pipe(map((e) => new CalendarEvent(e)));
  }

  getEvent(id: number): Observable<CalendarEvent> {
    const url = `${Config.API}/calendar/event/${id}`;
    return this.get(url).pipe(map((e) => new CalendarEvent(e)));
  }

  createEvent(data: Record<EVENT_SIDENAV_FORM, string>): Observable<CalendarEvent> {
    const url = `${Config.API}/calendar/event`;
    return this.post(url, data).pipe(map((e) => new CalendarEvent(e)));
  }

  editEvent(data: Record<EVENT_SIDENAV_FORM, string>, id: number): Observable<CalendarEvent> {
    const url = `${Config.API}/calendar/event/${id}`;
    return this.post(url, data).pipe(map((e) => new CalendarEvent(e)));
  }

  editGoogleEvent(data: Record<EVENT_SIDENAV_FORM, string>): Observable<CalendarEvent> {
    const url = `${Config.API}/calendar/event/google`;
    return this.post(url, data).pipe(map((e) => new CalendarEvent(e)));
  }

  deleteEvent(id: number) {
    const url = `${Config.API}/calendar/event/${id}`;
    return this.delete(url);
  }

  getCalendars(query?: string): Observable<PersonalCalendars> {
    const url = `${Config.API}/calendar`;
    return this.get<any>(url, false, { query }).pipe(map((i) => new PersonalCalendars(i)));
  }

  getMyWeekEvents(range: { startDate: string; endDate: string }): Observable<CalendarEventsList> {
    const url = `${Config.API}/calendar/my-events`;
    return this.get(url, false, range).pipe(map((i) => new CalendarEventsList(i, range)));
  }

  getCalendarView(fields?: {
    'calendarsIds[]'?: number[];
    startDate?: string;
    endDate?: string;
  }): Observable<CalendarView> {
    const url = `${Config.API}/calendar/full`;
    return this.get<any>(url, false, fields).pipe(map((i) => new CalendarView(i)));
  }

  setColor(id: number, color: string): Observable<CalendarEntity> {
    const url = `${Config.API}/calendar/${id}/color`;
    return this.post(url, { color });
  }

  addCalendar({ url, name, type }: { url: string; name: string; type?: string }): Observable<any> {
    const _url = `${Config.API}/calendar`;
    return this.post(_url, { url, name, type });
  }

  editCalendar(id: number, name: string): Observable<any> {
    const _url = `${Config.API}/calendar/${id}`;
    return this.post(_url, { name });
  }

  deleteCalendar(id: number) {
    const _url = `${Config.API}/calendar/${id}`;
    return this.delete(_url);
  }
}
