<div class="page-title" [ngClass]="'page-title-' + pathName">
  <content-title [title]="'Mailings.Templates.' + pathName | translate"></content-title>
</div>

<ng-container *ngIf="rows?.length; else emptyList">
  <app-mailing-template-item
    *ngFor="let row of rows"
    [project]="mailingService.project"
    [id]="row.id"
    [title]="row.name"
    [subject]="row.title"
    [content]="row.content"
    [email]="email"
  ></app-mailing-template-item>
</ng-container>

<ng-template #emptyList>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <h3 class="py-5">{{ 'Protocols.List.emptyList' | translate }}</h3>
  </div>
</ng-template>
