<div class="datatable-footer__container">
  <div class="datatable-footer-showing mb-3 mb-md-0">
    {{
      'List.showingEntriesCount'
        | translate
          : {
              start: ((service?.Page - 1) * service?.PerPage) + 1,
              end: end,
              all: service?.total
            }
    }}
  </div>
  <app-list-pager></app-list-pager>
  <div class="datatable-footer-per-page d-flex align-items-center cursor-pointer mt-4 mt-md-0 justify-content-center" [matMenuTriggerFor]="menu">
    <div
      class="datatable-footer-per-page-text"
      [innerHTML]="'List.showPerPage' | translate: { perPage: service?.config?.limit }"
    ></div>
    <div class="datatable-footer-per-page-arrow ml-1">
      <img draggable="false" src="/assets/img/ic-arrow-down.svg" alt="Arrow Down" />
    </div>
  </div>
</div>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item (click)="service.setPerPage(p)" *ngFor="let p of service?.config?.availablePerPage">
    <span>{{ p }}</span>
  </button>
</mat-menu>
