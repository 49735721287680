import { HttpError } from '@shared/interfaces/error.interface';
import { AttachmentController } from './../../shared/controllers/attachment.controller';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Attachment } from '@shared/interfaces/attachment.interface';

@Component({
  selector: 'attachment-image',
  templateUrl: './attachment-img.component.html',
  styleUrls: ['./attachment-img.component.scss'],
})
export class AttachmentImageComponent {
  @Input() attachment: Attachment = null;
  @Input() fancyboxUrl: string = '';
  @Input() edit: boolean = true;
  @Input() previewWidth: number = 100;
  @Input() privateFile: boolean = true;

  restoring: boolean = false;

  constructor(private changes: ChangeDetectorRef) {}

  openImage($event) {
    if (!this.fancyboxUrl) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  restore() {
    if (!!this.attachment.deleted && this.edit) {
      this.restoreAttachment();
    }
  }

  restoreAttachment() {
    if (this.restoring) return;
    this.restoring = true;
    const ctrl = new AttachmentController();
    ctrl.restoreAttachment(this.attachment).subscribe({
      next: (attachment: Attachment) => this.successRestore(attachment),
      error: (err: HttpError) => this.errorRestore(),
    });
  }

  successRestore(attachment: Attachment) {
    this.restoring = false;
    this.attachment.deleted = null;
    this.attachment = new Attachment(this.attachment);
    this.changes.detectChanges();
  }

  errorRestore() {
    this.restoring = false;
    this.changes.detectChanges();
  }
}
