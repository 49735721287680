import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListSingleFilter } from '@shared/modules/list/interfaces/list-single-filter.interface';
import { ListService } from '@shared/modules/list/services/list.service';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';

declare var $: any;

@Component({
  selector: 'list-single-filter-select-v2',
  templateUrl: './list-single-filter-select-v2.component.html',
})
export class ListSingleFilterSelectV2Component implements OnInit, ListSingleFilter, AfterViewInit {
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() name: string;
  @Input() iconUrl: string;
  @Input() controlName: string;
  @Input() url: string;
  @Input() localItems: any[] = [];
  @Input() bindValue?: string = 'id';
  @Input() bindLabel?: string = 'text';
  @Input() searchGetParams?: string = 'query';
  @Input() valueTransform;
  @Input() translateLabelPrefix?: string;
  @Input() responseTransform?: Function;
  @Input() requiredIdUrl?: string;
  @Input() multiple: boolean = false;
  @Input() sortByField?: string = 'id';

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() selectedValues: EventEmitter<Array<{ id: any; name: string }>> = new EventEmitter();

  @ViewChild('select') select: NgSelectExtensionDirective;

  items: any[] = [];

  constructor(public service: ListService, private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.url && this.localItems.length) {
      this.items = this.localItems;
      this.emitSelectedFormattedValues();
    }

    if (this.requiredIdUrl) {
      setTimeout(() => {
        if (this.getControl().value) {
          this.select.url = this.requiredIdUrl + '?requiredId=' + this.getControl().value;
        } else {
          this.select.url = this.requiredIdUrl;
        }
        this.select.getItems();
      });
    }
  }

  clear() {
    this.getControl().setValue(null);
    this.change.emit();
  }

  onChange() {
    this.change.emit();
    this.changes.detectChanges();
    this.emitSelectedFormattedValues();
  }

  open(e) {
    setTimeout(() => {
      const $dropdown = $('.ng-dropdown-panel');

      const bounds = $dropdown.get(0).getBoundingClientRect();
      const position = $dropdown.position();

      const diff = document.body.offsetWidth - (bounds.width + bounds.left);

      const left = diff < 0 ? diff - 30 : position.left;
      $dropdown.css({
        left: left + 'px',
      });
    });
  }

  getControl() {
    return this.control ? this.control : this.form.get(this.controlName);
  }

  handleItemsResponse(_items: any[]) {
    this.emitSelectedFormattedValues();
  }

  get isValue() {
    if (this.control) {
      return this.control.value && this.control.value.toString().length;
    }
    return this.form?.value[this.controlName] && this.form?.value[this.controlName].toString().length;
  }

  private emitSelectedFormattedValues() {
    setTimeout(() => {
      const values = this.select.select.selectedValues.map((item) => {
        return {
          id: (item as any).id,
          name: this.valueTransform
            ? this.valueTransform(item)
            : this.select.getTranslatedLabel(item[this.bindLabel]),
        };
      });
      this.selectedValues.emit(values);
    });
  }
}
