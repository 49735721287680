import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Project } from '@modules/projects/shared/models/project.model';
import { Subscription } from 'rxjs';

export abstract class BaseProjectCreator {
  form: FormGroup;
  additionalForm: FormGroup;

  project: Project;
  loading: boolean = false;
  dataChanged: boolean = false;
  sub: Subscription = new Subscription();
  disallowWatchChanges: boolean = false;

  subscribeChange(changes: ChangeDetectorRef) {
    this.sub?.unsubscribe();
    this.sub = new Subscription();

    let initial = false;
    const sub = this.form.valueChanges.subscribe(() => {
      this.dataChanged = true;
      changes?.detectChanges();
    });
    this.sub.add(sub);
  }

  unsubscribe() {
    this.dataChanged = false;
    this.sub?.unsubscribe();
  }

  setProject(project: Project) {
    this.project = new Project(project);
  }

  setFormValues(onlySelf: boolean = false) {
    this.form?.setValue(this.defaultFormValues, onlySelf ? { onlySelf: true, emitEvent: false } : {});
  }

  getFormValues() {
    return this.form.value;
  }

  createForm(fb: FormBuilder) {
    this.form = fb.group(this.defaultFormValues);
  }

  abstract get defaultFormValues();
  abstract submit(token: string, currentStep?: number);

  abstract submitInternal(id: number, currentStep?: number, isNext?: boolean);
}
