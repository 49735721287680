import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { NavigateService } from '@shared/services/navigate.service';

@Injectable({ providedIn: 'root' })
export class MailingActivateRouteGuard implements CanActivate {
  constructor(private n: NavigateService) {}

  canActivate() {
    const ctrl = new CheckPermission({ group: PermissionsGroups.PROJECTS, action: 'EMAIL_TAB' });
    if (ctrl.check()) {
      return true;
    } else {
      this.n.go('projects-list');
      return false;
    }
  }
}
