<div class="mr-1 cursor-pointer">
  <div
    tourAnchor="protocolsList.s2.notifications"
    matBadge="{{ notificationsService.unreadNotifications }}"
    matBadgeColor="warn"
    matBadgeHidden="{{ notificationsService.unreadNotifications === 0 }}"
    [ngClass]="{
          'mat-badge-warn-bigger':
            notificationsService.unreadNotifications > '90' ||
            notificationsService.unreadNotifications === '+99'
        }">
    <img draggable="false"
      src="/assets/img/ic-notifications-outline.svg"
      [alt]="'notifications' | translate"
      [title]="'notifications' | translate"
    />
  </div>
</div>
