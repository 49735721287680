import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Config } from '@shared/configs/config';
import { ProjectListItem } from '@shared/interfaces/project-list-item.interface';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Regex } from '@shared/configs/regex';

@Component({
  selector: 'contact-add-to-project-or-system',
  templateUrl: './contact-add-to-project.component.html',
  styleUrls: ['./contact-add-to-project.component.scss']
})
export class ContactAddToProjectComponent implements OnInit {

  CONFIG = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  contact: Contact;
  form: FormGroup;
  roles: { id: string, text: string }[] = [];

  @Output('onAddAsContactToProject') onAddAsContactToProject: EventEmitter<{ project: Project, accessContactId: number, hasAccess: boolean }> = new EventEmitter<{ project: Project, accessContactId: number, hasAccess: boolean }>();
  @ViewChild('roleSelect') roleSelect: NgSelectExtensionDirective;

  constructor(
    public dialogRef: MatDialogRef<ContactAddToProjectComponent>,
    private projectApiService: ProjectAPIService,
    private s: SnackBarService,
    private t: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.setRoles();
  }

  createForm() {
    this.form = new FormGroup({
      asContact: new FormControl(true),
      project: new FormControl(null, [Validators.required]),
      role: new FormControl(null),
      email: new FormControl(this.contact.email, [
        Validators.required,
        Validators.pattern(Regex.email),
        Validators.maxLength(100),
      ])
    });
  }

  setRoles() {
    Object.keys(UserRoles).forEach((key) => {
      this.roles.push({
        id: UserRoles[key],
        text: this.t.instant('ROLES.' + key),
      });
    });
  }

  onAddToProjectTypeChange(type: any) {
    this.form.get('asContact').setValue(type);
    if (type) {
      this.form.get('role').setValidators([Validators.required]);
    } else {
      this.form.get('role').setValue(null);
      this.form.get('role').setValidators([]);
    }
  }

  successOnLoadProjects(projectList: Array<ProjectListItem>) {
    const currentProjectsIds = this.contact.mainContactProjects.concat(this.contact.contactProjects.map((a: ContactAccessProject) => a.project)).map((project: Project) => project.id);
    return projectList.filter((i: ProjectListItem) => {
      i ? (i.display = this.getProjectOption(i)) : null;
      return currentProjectsIds.indexOf(i.id) === -1;
    });
  }

  getProjectOption(project: ProjectListItem): string {
    const projectName = `<div class="ng-name">${project?.name}</div>`;
    const projectAddress = project?.address ? `<div class="ng-address">${project?.address}</div>` : '';
    return projectName + projectAddress;
  }

  submit() {
    this.form.markAllAsTouched();
    if ((!this.form.get('asContact').value && !this.form.valid) || (this.form.get('asContact').value && !this.form.get('project').value) || this.loading) {
      return;
    }
    this.loading = true;

    if (this.form.get('asContact').value) {
      return this.addAsContactToProject();
    } else {
      return this.addAsContactAndInviteToProject();
    }
  }

  addAsContactAndInviteToProject() {
    if (this.contact.employee) {
      this.inviteExistContactToProject();
    } else {
      this.inviteNewContactToProject();
    }
  }

  inviteExistContactToProject() {
    let project: Project = this.form.get('project').value;
    this.projectApiService.inviteExistContactToProject(this.contact.id, this.contact.employee.id, true, project.id, false).subscribe({
      next: (c: Contact) => {
        let accessContactId = 0;
        c.contactProjects.map((c: ContactAccessProject) => {
          if (c.project.id === project.id) {
            accessContactId = c.id;
            project = new Project(c.project);
          }
        });
        this.s.success(this.t.instant('Contacts.Preview.Projects.successContactAddedToProject'));
        this.onAddAsContactToProject.emit({project, accessContactId, hasAccess: true});
        this.form.reset();
        this.dialogRef.close();
      },
      error: () => {
        this.s.error(this.t.instant('Contacts.Preview.Projects.errorContactAddedToProject'));
      }
    }).add(() => {
      this.loading = false;
    });
  }

  inviteNewContactToProject() {
    let project: Project = this.form.get('project').value;
    this.projectApiService.inviteNewContactToProject(
      this.contact.id,
      this.form.get('email').value,
      this.form.get('role').value,
      true,
      project.id).subscribe({
      next: (c: Contact) => {
        let accessContactId = 0;
        c.contactProjects.map((c: ContactAccessProject) => {
          if (c.project.id === project.id) {
            accessContactId = c.id;
            project = new Project(c.project);
          }
        });
        this.s.success(this.t.instant('Contacts.Preview.Projects.successContactAddedToProject'));
        this.onAddAsContactToProject.emit({project, accessContactId, hasAccess: true});
        this.form.reset();
        this.dialogRef.close();
      },
      error: () => {
        this.s.error(this.t.instant('Contacts.Preview.Projects.errorContactAddedToProject'));
      }
    }).add(() => {
      this.loading = false;
    });
  }

  addAsContactToProject() {
    const project: Project = this.form.get('project').value;
    this.projectApiService.addContactToProject(this.contact.id, project.id).subscribe({
      next: (p: Project) => {
        let accessContactId = 0;
        p.basicDataBox.contacts.map((c: ContactAccessProject) => {
          if (c.contact.id === this.contact.id) {
            accessContactId = c.id;
          }
        });
        this.s.success(this.t.instant('Contacts.Preview.Projects.successContactAddedToProject'));
        this.onAddAsContactToProject.emit({project, accessContactId, hasAccess: false});
        this.form.reset();
        this.dialogRef.close();
      },
      error: () => {
        this.s.error(this.t.instant('Contacts.Preview.Projects.errorContactAddedToProject'));
      }
    }).add(() => {
      this.loading = false;
    });
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return messages;
  }
}
