<div class="settings settings-content">
  <div *ngIf="employee?.isEmployeeOwner">
    <content-title
      [title]="'Settings.Company.title' | translate"
      [subtitle]="'Settings.Company.letsStart' | translate"
    >
    </content-title>
    <h2 class="blue-500 settings-content-subtitle" [innerHTML]="'Settings.Company.company' | translate"></h2>
    <div
      class="grey-4 settings-content-description mb-4"
      [innerHTML]="'Settings.Company.mainDescription' | translate"
    ></div>

    <div class="settings-content-section-forms">
      <company-base-data [form]="form"></company-base-data>
      <hr />
      <company-extend-data [form]="form"></company-extend-data>
      <hr />
      <company-payments [form]="form"></company-payments>
      <hr />
      <company-contact-person [form]="form"></company-contact-person>

      <hr />
      <company-project-creator></company-project-creator>
    </div>
  </div>
  <company-no-account *ngIf="userPerson.canCreateCompany"></company-no-account>
</div>
