<div class="action-title-header"
     [ngClass]="{
     'action-title-header--sticky': sticky,
     'action-title-header--sticky--side-navigation': stickyWithSideNavigation,
     'action-title-header--double-height-mobile': doubleHeightOnMobile
     }"
     #header>
  <div class="action-title-header__left-side">
    <h1 class="action-title-header-title" [innerHTML]="title"></h1>
    <app-button
      [permission]="permissionDecorator"
      [class]="'ml-3'"
      (onClick)="onAction.emit(true)"
      [loading]="loading"
      [config]="{
        type: ButtonTypes.PRIMARY_GREEN,
        text: buttonText,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL,
        prependSrc: prependSrc
      }">
    </app-button>
  </div>
  <ng-content></ng-content>
</div>
