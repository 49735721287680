import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss']
})
export class ExpanderComponent implements OnInit {


  @Input() opened: boolean;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() tourAnchor: string;

  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
