import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';
import { EventAction } from '@shared/modules/event-sidenav/enums/event-action.enum';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { Subscription } from 'rxjs';
import { IProjectPreviewEvent } from '@modules/projects/modules/project-preview/interfaces/project-preview-event.interface';
import { ProjectPreviewEventType } from '@modules/projects/modules/project-preview/enums/projcet-preview-event-type.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { MatDialog } from '@angular/material/dialog';
import { ProjectSendStageNotificationComponent } from '@modules/projects/shared/components/project-send-stage-notification/project-send-stage-notification.component';
import { Config } from '@shared/configs/config';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectSummaryAPIService } from '@modules/projects/shared/services/project-summary-api.service';
import { ProjectStatistics } from '@modules/projects/shared/models/project-statistics.interface';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckPermission } from '@core/permissions/check-permission';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import * as moment from 'moment';
import { IEventAction } from '@shared/modules/event-sidenav/interfaces/event-action.interface';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ContactProjectPemissionDialogData } from '@shared/components/contact-project-permission-modal/contact-project-permission-modal.component';
import { NavigateService } from '@shared/services/navigate.service';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  ProjectStage = ProjectStage;
  showRightSectionOnMobile: boolean = false;
  subs: Subscription = new Subscription();
  showStageSendNotification: boolean = false;
  loading: boolean = false;
  projectStatistics: ProjectStatistics;

  get currency() {
    return this.storage?.Company?.currency;
  }

  constructor(
    private storage: StorageService,
    public projectPreviewService: ProjectPreviewService,
    private changes: ChangeDetectorRef,
    private dialogRef: MatDialog,
    private basePanelService: BasePanelService,
    private projectStageApiService: ProjectStageAPIService,
    private projectSummaryApiService: ProjectSummaryAPIService,
    private eventService: EventSidenavService,
    private s: SnackBarService,
    private t: TranslateService,
    public n: NavigateService,
  ) {}

  ngOnInit(): void {
    this.getStatistics();
    this.listenPreviewEvents();
    this.listenEventsManage();
    this.setShowSendStageNotification();
  }

  sendMail(contact) {
    const ctrl = new MailingController();
    ctrl.goToProjectTemplates(contact.email, this.projectPreviewService.project.id);
  }

  editProject() {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    ctrl.edit(this.projectPreviewService.project);
  }

  editMainContact() {
    const ctrl = new ContactController();
    const data: ContactProjectPemissionDialogData = {
      projectId: this.projectPreviewService.project?.id,
      mainContactId: this.projectPreviewService.project?.MainContact?.id,
      responsibleContactId: this.projectPreviewService.project?.basicDataBox?.responsibleContact?.id,
      contactList: [],
      getContactsList: true,
      project: this.projectPreviewService.project,
    };
    const sub = ctrl
      .editMainContactOrResponsible(data)
      .componentInstance.onProjectChange.subscribe((project) => {
        this.projectPreviewService.project = project;
        this.changes.detectChanges();
        sub.unsubscribe();
      });
    this.subs.add(sub);
  }

  listenEventsManage() {
    const sub = this.eventService.CalendarEventEmitter.subscribe((e: IEventAction) => {
      switch (e.action) {
        case EventAction.ACTION_EDIT:
        case EventAction.ACTION_ADD:
          this.getStatistics();
          break;
      }
    });
    this.subs.add(sub);
  }

  listenPreviewEvents() {
    const sub = this.projectPreviewService.projectServiceEventEmitter.subscribe((e: IProjectPreviewEvent) => {
      switch (e.type) {
        case ProjectPreviewEventType.TOGGLE_RIGHT_SECTION:
          this.showRightSectionOnMobile = !this.showRightSectionOnMobile;
          this.changes.detectChanges();
          break;
        case ProjectPreviewEventType.UPDATE_PROJECT:
          this.setShowSendStageNotification();
          this.changes.detectChanges();
          break;
      }
    });
    this.subs.add(sub);
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.projectPreviewService.projectEditIDs,
  })
  openSendDialog() {
    const ctrl = new MailingController();
    ctrl.openMailingSelection('', this.projectPreviewService.project.id);
    // this.dialogRef.open(ProjectSendStageNotificationComponent, {
    //   width: Config.DEFAULT_MODAL_WIDTH,
    // });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.projectPreviewService.projectEditIDs,
  })
  clearStageNotification() {
    this.projectPreviewService.project.pendingStageNotification = false;
    this.setShowSendStageNotification();
    this.changes.detectChanges();
    this.projectStageApiService.clearStageNotification(this.projectPreviewService.project.id).toPromise();
  }

  ngOnDestroy() {
    this.basePanelService.unBlockScroll();
    this.subs.unsubscribe();
  }

  setShowSendStageNotification() {
    const project = this.projectPreviewService.project;
    if (
      project?.pendingStageNotification &&
      project?.stage !== project?.prevStage &&
      project?.MainContact?.email
    ) {
      this.showStageSendNotification = true;
    } else {
      this.showStageSendNotification = false;
    }
  }

  getStatistics() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'SUMMARY_STATISTICS',
      objectCreatorId: this.projectPreviewService.project.basicDataBox?.responsibleEmployee?.id,
    });

    if (this.loading || !ctrl.check()) {
      return;
    }
    this.loading = true;
    this.projectSummaryApiService
      .getSummary(this.projectPreviewService.project.id)
      .subscribe({
        next: (res: ProjectStatistics) => {
          res.upcomingEvents.sort((a: CalendarEvent, b: CalendarEvent) => {
            return moment(a.termStart, Config.DATE_SERVER).diff(moment(b.termStart, Config.DATE_SERVER));
          });
          this.projectStatistics = res;
          this.changes.detectChanges();
        },
        error: () => {
          this.s.error(this.t.instant('ProjectSummary.errorGettingStatistics'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }
}
