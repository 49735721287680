import { Calendar } from '@fullcalendar/angular';
import { CalendarEntity } from '@modules/calendar/shared/models/calendar-entity.model';
import { Company } from './company.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { TranslateService } from '@ngx-translate/core';
import { BaseUserPerson } from './base-user-person.model';
import { SearchHistory } from '@shared/models/search-history.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { BaseContactModel } from '@modules/contacts/shared/models/base-contact.model';

export class Employee {
  deleted?: any;
  created: string;
  modified: string;
  id: number;
  company: Company;
  hasResponsibleProjects: boolean;
  role: UserRoles;
  active: boolean;
  hourlyRate: number;
  removed: boolean;
  userPerson: BaseUserPerson;
  imageSecret?: string;
  searchHistory: SearchHistory[];
  contacts?: Contact[];
  calendars?: CalendarEntity[] = [];
  disabled?: boolean = false;
  defaultCalendar?: CalendarEntity;
  contact?: BaseContactModel;

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
    this.company = new Company(this.company);
    this.userPerson = new BaseUserPerson(this.userPerson);
    this.calendars = this.calendars?.map((i) => new CalendarEntity(i)) || [];
    this.contact = new BaseContactModel(data?.contact);
    data?.defaultCalendar ? (this.defaultCalendar = new CalendarEntity(data.defaultCalendar)) : null;
  }

  /**
   * deprecated - just stay for back support
   * @returns {boolean}
   */
  get isEmployeeOwner(): boolean {
    return this.role === UserRoles.ROLE_EMPLOYEE_ADMIN;
  }

  get isAdmin(): boolean {
    return this.role === UserRoles.ROLE_EMPLOYEE_ADMIN;
  }

  get isClient(): boolean {
    return this.role === UserRoles.ROLE_EMPLOYEE_CLIENT;
  }

  get isExternalUser(): boolean {
    return this.role === UserRoles.ROLE_EMPLOYEE_EXTERNAL;
  }

  get isWorker(): boolean {
    return this.role === UserRoles.ROLE_EMPLOYEE_WORKER;
  }

  get _company(): Company {
    return this.company;
  }

  isOwnerNotFilledFullData(translate: TranslateService) {
    if (this.isEmployeeOwner) {
      let msg = '';
      const values = [
        'logo',
        'address',
        'postalCode',
        'town',
        'country',
        'taxNumber',
        'euTaxNumber',
        'bankName',
        'iban',
        'bic',
        'website',
        'email',
      ];

      values.forEach((key: string) => {
        if (!this.company[key]) {
          const t = translate.instant(`Protocols.${key}Check`);
          msg = !msg ? t : `${msg}, ${t}`;
        }
      });
      return msg;
    }
    return null;
  }
}
