import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'new-toggle-checkbox-v2',
  templateUrl: './new-toggle-checkbox-v2.component.html',
  styleUrls: ['./new-toggle-checkbox-v2.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewToggleCheckboxV2Component), multi: true },
  ],
})
export class NewToggleCheckboxV2Component implements ControlValueAccessor {
  @Input() label: string;
  @Input() set disabled(value: boolean) {
    this._disabled = value;

  }

  get disabled() : boolean { return this._disabled}

  _disabled = false;
  checked: boolean = false;

  constructor() {}

  onChange = (state: boolean) => {};

  onTouched = () => {};

  onModelChange(e: boolean) {
    this.checked = e;
    this.onChange(e);
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: (state: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
