import {ButtonTypes} from '@shared/modules/ui/components/button/button.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Host,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Attachment} from '@shared/interfaces/attachment.interface';
import {AuthFilePipe} from '@shared/pipes/authFile.pipe';
import {debounce} from "@shared/decorators/debounce.decorator";

@Component({
  selector: 'signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit, AfterViewInit {
  ButtonTypes = ButtonTypes;

  @Input() title: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Output('onSave') onSave: EventEmitter<any> = new EventEmitter();
  @Output('removeSiganture') removeSiganture: EventEmitter<RemoveSignature> = new EventEmitter();

  @ViewChild('signaturePad') signaturePad: ElementRef;
  @ViewChild('signaturePadWrapper') signaturePadWrapper: ElementRef;
  @ViewChild('sketch') sketch: ElementRef;

  editMode: boolean = false;
  isDrawing: boolean = false;
  context;
  tmpContext;
  tmpCanvas;

  points: Point[] = [];
  mouse: Point = {x: 0, y: 0};

  constructor(private authFilePipe: AuthFilePipe) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setSignatureSketchHeight();
    this.prepareCanvas();
    this.setFileToCanvas();
  }

  prepareCanvas() {
    this.context = this.signaturePad.nativeElement.getContext('2d');
    const sketchStyle = getComputedStyle(this.signaturePadWrapper.nativeElement);
    this.signaturePad.nativeElement.width = parseInt(sketchStyle.getPropertyValue('width'));
    this.signaturePad.nativeElement.height = parseInt(sketchStyle.getPropertyValue('height'));
    this.createTempCanvas();
    this.setConfigSignature();
  }

  createTempCanvas() {
    this.tmpCanvas = document.createElement('canvas');
    this.tmpContext = this.tmpCanvas.getContext('2d');
    this.tmpCanvas.id = 'tmpCanvas';
    this.tmpCanvas.width = this.signaturePad.nativeElement.width;
    this.tmpCanvas.height = this.signaturePad.nativeElement.height;
    this.sketch.nativeElement.appendChild(this.tmpCanvas);
  }

  setConfigSignature() {
    this.tmpContext.strokeStyle = this.tmpContext.fillStyle = 'black';
    this.tmpContext.lineCap = this.tmpContext.lineJoin = 'round';
    this.tmpContext.lineWidth = 2;
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onMouseUp() {
    this.isDrawing = false;
    this.context.drawImage(this.tmpCanvas, 0, 0);
    this.tmpContext.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.clearCanvas();
    this.ngAfterViewInit();
  }

  setSignatureSketchHeight() {
    const ratio = 1.4;
    const sketchStyle = getComputedStyle(this.sketch.nativeElement);
    const width = parseInt(sketchStyle.getPropertyValue('width'));
    this.signaturePadWrapper.nativeElement.style.height = (width / ratio) + 'px';
  }

  paint() {
    this.points.push(this.mouse as Point);

    if (this.points.length < 3) {
      const b = this.points[0];
      this.tmpContext.beginPath();
      this.tmpContext.arc(b.x, b.y, this.tmpContext.lineWidth / 2, 0, Math.PI * 2, !0);
      this.tmpContext.closePath();
      this.tmpContext.fill();
      return;
    }

    this.tmpContext.clearRect(
      0,
      0,
      this.signaturePad.nativeElement.width,
      this.signaturePad.nativeElement.height,
    );
    this.tmpContext.beginPath();
    this.tmpContext.moveTo(this.points[0].x, this.points[0].y);

    for (var i = 1; i < this.points.length - 2; i++) {
      const c = (this.points[i].x + this.points[i + 1].x) / 2;
      const d = (this.points[i].y + this.points[i + 1].y) / 2;
      this.tmpContext.quadraticCurveTo(this.points[i].x, this.points[i].y, c, d);
    }

    // For the last 2 points
    this.tmpContext.quadraticCurveTo(
      this.points[i].x,
      this.points[i].y,
      this.points[i + 1].x,
      this.points[i + 1].y,
    );
    this.tmpContext.stroke();
  }

  onTouchStart(e) {
  }

  onTouchMove(e) {
    const touch = e.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.signaturePad.nativeElement.dispatchEvent(mouseEvent);
  }

  onMouseDown(e) {
    if (!this.editMode) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.isDrawing = true;
    this.mouse = this.relativeCoords(e);
    this.points = [];
    this.points.push(this.mouse as Point);
    this.paint();
  }

  onMouseMove(e) {
    this.mouse = this.relativeCoords(e);
    if (this.isDrawing) {
      this.paint();
    }
  }

  clearCanvas() {
    this.tmpContext.clearRect(
      0,
      0,
      this.signaturePad.nativeElement.width,
      this.signaturePad.nativeElement.height,
    );
    this.context.clearRect(
      0,
      0,
      this.signaturePad.nativeElement.width,
      this.signaturePad.nativeElement.height,
    );
  }

  saveSignature() {
    this.editMode = false;
    this.isDrawing = false;
    if (this.isCanvasBlank()) {
      this.isAddedAttachment ? this.removeSiganture.emit({controlName: this.controlName}) : null;
    } else {
      this.setValue({file: this.getFileFromCanvas()});
      this.onSave.emit();
    }
  }

  get isAddedAttachment() {
    return this.form.get(this.controlName).value?.attachment;
  }

  setValue(value: Signature) {
    const currentValue = this.form.get(this.controlName).value ? this.form.get(this.controlName).value : {};
    this.form.get(this.controlName).setValue(Object.assign(currentValue, value));
  }

  setFileToCanvas() {
    if (!this.form.get(this.controlName).value) {
      return;
    }
    const img = new Image();
    img.crossOrigin = '*';
    img.onload = (e) => {

      if (img.width > this.signaturePad.nativeElement.width || img.height > this.signaturePad.nativeElement.height) {
        this.tmpContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, this.signaturePad.nativeElement.width, this.signaturePad.nativeElement.height);
      } else {
        this.tmpContext.drawImage(img, 0, 0);
      }

      this.context.drawImage(this.tmpCanvas, 0, 0);
      this.setValue({file: this.getFileFromCanvas()});
    };
    const src = this.authFilePipe.transform(this.form.get(this.controlName).value?.attachment?.file);
    src ? (img.src = src) : null;
  }

  getFileFromCanvas() {
    const data = this.signaturePad.nativeElement.toDataURL();
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], this.controlName, {type: mime});
  }

  setEditMode() {
    this.editMode = true;
  }

  isCanvasBlank() {
    return !this.signaturePad.nativeElement
      .getContext('2d')
      .getImageData(0, 0, this.signaturePad.nativeElement.width, this.signaturePad.nativeElement.height)
      .data.some((channel) => channel !== 0);
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return {x: x, y: y} as Point;
  }
}

export interface Point {
  x;
  y;
}

export interface RemoveSignature {
  controlName: string;
}

export interface Signature {
  file?: File;
  attachment?: Attachment;
}
