<div>
  <div class="d-flex align-items-center">
    <div
      *ngIf="!isImage"
      class="attachment-single-card"
      docPreview
      [file]="!!attachment && attachment?.isUploaded ? attachment : null"
      (onLoading)="downloading = $event"
      [title]="attachment.name"
    >
      <ng-container *ngIf="!!attachment?.isUploaded">
        <img draggable="false" height="24px" src="./assets/img/{{ icon }}" alt="Icon" />
      </ng-container>
      <span [title]="attachment?.name">{{ attachment?.name }}</span>
    </div>

    <a
      draggable="false"
      *ngIf="isImage"
      class="attachment-single-card"
      [href]="attachment?.file | authFile : null : null : !privateFile"
      [attr.data-fancybox]="fancyboxUrl"
      (click)="openImage($event)"
    >
      <ng-container *ngIf="!!attachment?.isUploaded">
        <img *ngIf="isImage" draggable="false" height="24px" src="./assets/img/image-icon.svg" alt="Icon" />
      </ng-container>
      <span [title]="attachment?.name" class="att-name">{{ attachment?.name }}</span>
    </a>
  </div>
</div>
