import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResponse } from '@shared/modules/list/model/list-response.model';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseHttpService {
  constructor() {
    super();
  }

  setContact(data) {
    const url = `${Config.API}/contact/extended`;
    return this.post(url, data);
  }

  updateContact(contact: Contact) {
    const url = `${Config.API}/contact/${contact.id}`;
    return this.post(url, contact);
  }

  getContact(id: number): Observable<Contact> {
    const url = `${Config.API}/contact/${id}`;
    return this.get(url).pipe(map((c) => new Contact(c)));
  }

  setAsSubcontact(subcontactId: number, mainContactId: number): Observable<Contact> {
    const url = `${Config.API}/contact/${subcontactId}/parent`;
    let body = {
      parentId: mainContactId,
      isContactPerson: false,
    };
    return this.post(url, body).pipe(map((c) => new Contact(c)));
  }

  setSubcontactAsContactPerson(subcontactId: number, mainContactId: number): Observable<Contact> {
    const url = `${Config.API}/contact/${subcontactId}/parent`;
    let body = {
      parentId: mainContactId,
      isContactPerson: true,
    };
    mainContactId ? (body['parentContactId'] = mainContactId) : null;
    return this.post(url, body).pipe(map((c) => new Contact(c)));
  }

  removeContactPerson(id: number, mainContactId: number) {
    const url = `${Config.API}/contact/${id}/parent`;
    let body = {
      parentId: mainContactId,
      isContactPerson: false,
    };
    return this.post(url, body);
  }

  search(params: any): Observable<ListResponse<Contact>> {
    const url = `${Config.API}/contact/search-advenced`;
    return this.get(url, false, params, true);
  }

  remove(id: number) {
    const url = `${Config.API}/contact/${id}`;
    return this.delete(url);
  }

  removeContactFromProject(id: number) {
    const url = `${Config.API}/contact-project/${id}`;
    return this.delete(url);
  }
}
