import {isDevMode} from "@angular/core";
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
import CryptoJS from 'crypto-js'

export class BaseStorageService {
  static readonly IN_PREFIX: string = 'intilio';

  constructor() {
  }

  get(key: string) {
    const val = localStorage.getItem(`${BaseStorageService.IN_PREFIX}-${key}`);
    if (val && !isDevMode()) {
      return Base64.parse(val).toString(CryptoJS.enc.Utf8);
    }
    return val;
  }

  put(key: string, val: any) {
    if (val && val.toString().length && !isDevMode()) {
      val = Base64.stringify(Utf8.parse(val));
    }
    localStorage.setItem(`${BaseStorageService.IN_PREFIX}-${key}`, val);
  }

  delete(key: string) {
    localStorage.removeItem(`${BaseStorageService.IN_PREFIX}-${key}`);
  }
}
