import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { Calendar } from './calendar.model';

export class CalendarView {
  calendars: Calendar[] = [];
  myEventsCount: number = 0;

  constructor(view?: CalendarView) {
    view ? this.deserialize(view) : null;
  }

  deserialize(view: CalendarView) {
    Object.assign(this, view);
    this.initCalendars();
  }

  initCalendars() {
    this.calendars = this.calendars?.map((i) => new Calendar(i)) || [];
  }
}
