import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';

@Pipe({ name: 'dateTimeFormat', pure: true })

export class DateTimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    return value ? moment(value, Config.DATE_SERVER).format(Config.DATETIME_FORMAT_DOTS) : '';
  }
}
