<div>
  <span class="d-flex align-items-center header-action-menu-user-icon-wrapper">
    <avatar [width]="24" [imageUrl]="imageUrl" [title]="userPerson?.previewName"></avatar>
    <header-action-menu-company
      [hidden]="!isExpanded"
      [ngClass]="{ expanded: isExpanded }"
    ></header-action-menu-company>
    <svg-icon
      class="pointer ml-auto"
      #menuTrigger="matMenuTrigger"
      mat-icon-button
      [hidden]="!isExpanded"
      [matMenuTriggerFor]="userMenu"
      [ngClass]="{ expanded: isExpanded }"
      src="/assets/img/three-dots-icon.svg"
    ></svg-icon>
  </span>
  <mat-menu #userMenu="matMenu" xPosition="after">
    <button mat-menu-item (click)="n.go('user-settings')">
      <span>{{ 'settings' | translate }}</span>
    </button>
    <button mat-menu-item (click)="n.go('faq')">
      <span>{{ 'Header.faq' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()" class="hover_red red-500">
      <span>{{ 'logout' | translate }}</span>
    </button>
  </mat-menu>
</div>
