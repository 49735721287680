<list-single-filter-v2 [template]="filter" [name]="name" [iconUrl]="iconUrl"></list-single-filter-v2>

<ng-template #filter>
  <div class="input-v2">
    <input
      type="text"
      #input
      ngxDaterangepickerMd
      [(ngModel)]="selected"
      [ranges]="ranges"
      readonly="readonly"
      [alwaysShowCalendars]="true"
      [locale]="dateRangeLocale"
      class="list-filter-single__date-input"
      placeholder="{{ 'List.Filters.notChosen' | translate }}"
      (datesUpdated)="datesUpdated($event)"
    />
    <span *ngIf="isValue" class="clear-icon list-filter-single_clear" (click)="clear()">×</span>
    <svg-icon
      class="arrow-icon"
      [src]="'/assets/img/select-arrow-icon.svg'"
      (click)="onArrowClick()"
    ></svg-icon>
  </div>
</ng-template>
