import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ContactToggleMenuCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-toggle-menu-cell/contact-toggle-menu-cell.component';
import { ColumnMode } from '@swimlane/ngx-datatable';

export const ContactsListConfig: ListConfig = {
  columns: [
    {
      prop: 'id',
      name: 'Contacts.List.contactName',
      componentRef: 'contactNameCell',
      sortField: 'a.sortName',
      resizeable: false,
      hideOnMobile: false,
      cellClass: 'tree-cell--flex-start',
      width: 450,
      mobileWidth: 300,
      isTreeColumn: true,
      treeLevelIndent: 15,
      canNotShow: false,
      exportPath: {
        contact_name: '$[0].name',
      },
      order: 1,
    },
    {
      prop: 'type',
      name: 'Contacts.List.contactType',
      componentRef: 'contactTypeCcell',
      sortField: 'a.type',
      resizeable: false,
      hideOnMobile: true,
      width: 130,
      exportPath: {
        contact_type: '#translate;["$[0].type"]',
      },
      order: 2,
    },
    {
      prop: 'phone',
      name: 'Contacts.List.phone',
      componentRef: 'contactPhoneCell',
      sortField: 'a.phone',
      resizeable: false,
      hideOnMobile: true,
      width: 200,
      exportPath: {
        phoneNumber: '$[0].phone',
      },
      order: 3,
    },
    {
      prop: 'email',
      name: 'Contacts.List.email',
      componentRef: 'contactEmailCell',
      sortField: 'a.email',
      resizeable: false,
      hideOnMobile: true,
      width: 300,
      exportPath: {
        email: '$[0].email',
      },
      order: 4,
    },
    {
      prop: 'availability',
      name: 'Contacts.List.availability',
      componentRef: 'contactAvailabilityCell',
      sortField: 'a.availability',
      resizeable: false,
      hideOnMobile: true,
      width: 140,
      exportPath: {
        contact_availability: '$[0].availability',
      },
      order: 5,
    },
    {
      prop: 'childContacts',
      name: 'Contacts.List.subontactsCount',
      componentRef: 'contactSubcontactsCountCell',
      sortField: 'childCount',
      resizeable: false,
      hideOnMobile: true,
      width: 100,
      exportPath: {
        subContacts: '$[0].childCount',
      },
      order: 7,
    },
    {
      prop: 'creator',
      name: 'Contacts.List.creator',
      componentRef: 'contactCreatorCell',
      sortField: 'a.created',
      resizeable: false,
      hideOnMobile: true,
      width: 150,
      exportPath: {
        creator_fullName: '$[0].creator.userPerson.notificationName',
      },
      order: 8,
    },
    {
      prop: 'function',
      name: 'Contacts.List.function',
      componentRef: 'contactFunctionCell',
      sortField: 'function.name',
      resizeable: false,
      hideOnMobile: true,
      width: 250,
      mobileWidth: 130,
      exportPath: {
        function: '#list;["$[0].function","name"]',
      },
      order: 9,
    },
    {
      toggleMenu: true,
      component: ContactToggleMenuCellComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 0,
      sortable: false,
      order: 10,
    },
  ],
  url: `${Config.API}/contact/search-advenced?filters[parent][is null]=1`,
  urlTransform: (url: string, params: any) => {
    const baseUrl = `${Config.API}/contact/search-advenced`;
    return params?.search ? baseUrl : baseUrl + '?filters[parent][is null]=1';
  },
  urlExport: `${Config.API}/contact/export`,
  scrollbarH: true,
  listName: 'contacts-list',
  rowClass: 'cursor-pointer',
  columnMode: ColumnMode.standard,
  trackByProp: 'id',
  responseKey: 'records',
  listTitle: 'Contacts.List.listTitle',
  treeAction: {
    treeToRelation: 'id',
    treeFromRelation: 'mainContactId',
  },
};
