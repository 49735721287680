import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'white-board',
  templateUrl: './white-board.component.html',
  styleUrls: ['./white-board.component.scss'],
})
export class WhiteBoardComponent implements OnInit {
  @Input() template;

  @Input() isLogo: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
