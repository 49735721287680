<div class="calendar-events-item d-flex" (click)="openPreview()">
  <div class="calendar-events-item__type d-flex align-items-center justify-content-center">
    <img draggable="false" *ngIf="item.isEvent" src="./assets/img/ic-calendar.svg" alt="event" />
    <img draggable="false" *ngIf="item.isTask" src="./assets/img/ic-check-square.svg" alt="task" />
  </div>
  <div class="d-flex justify-content-between calendar-events-item__box">
    <div class="calendar-events-item__box-data">
      <div class="calendar-events-item__box-data__title">{{ item?.name }}</div>
      <div class="calendar-events-item__box-data__info">
        {{ item?.description }}
      </div>
    </div>
    <div class="calendar-events-item__box-time">
      <div>{{ item.termStart | rangeFormat: item.termEnd:false:true }}</div>
    </div>
  </div>
</div>
