<div class="d-flex justify-content-between">
  <app-button
    (onClick)="service.discardSaving()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_SECONDARY_BLUE,
      text: 'Projects.discard' | translate
    }"
  ></app-button>

  <app-button
    [disabled]="service.loading"
    [loading]="service.loading"
    (onClick)="submitForm()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_PRIMARY_DARK_BLUE,
      text: 'Projects.saveAndEnd' | translate
    }"
  ></app-button>
</div>
