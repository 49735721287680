<div class="entry-upload-item" [ngClass]="EntryClass">
  <!-- #region Header -->
  <div class="entry-upload-item-header d-flex justify-content-between" (click)="toggleEntry()">
    <!-- #region Header -->
    <div
      class="w-50 d-flex justify-content-start entry-upload-item-header-title no-select"
      [title]="title"
      [attr.aria-expanded]="!item.opened"
    >
      <img
        draggable="false"
        height="16px"
        width="16px"
        src="./assets/img/ic-circle-toggle.svg"
        alt="Toggle"
        class="mr-2 expand-arrow cursor-pointer"
      />
      <span [innerHTML]="title"></span>
    </div>
    <!-- #endregion -->

    <!-- #region Actions -->
    <div
      *ngIf="edit"
      class="w-50 d-flex justify-content-end entry-upload-item-actions relative"
      (click)="$event.stopPropagation()"
    >
      <div
        class="task-item_task-actions"
        *ngIf="WindowHelper.isMobileWidth && !preview"
        [matMenuTriggerFor]="previewActions"
        #trigger="matMenuTrigger"
      >
        <svg-icon class="more-icon" src="./assets/img/ic-plus-add-sharp.svg"></svg-icon>
      </div>

      <mat-menu #previewActions="matMenu">
        <ul class="m-0" (click)="$event.stopPropagation()">
          <ng-container *ngIf="!item?.deleted && edit">
            <li
              mat-menu-item
              (click)="editEntry(); trigger.closeMenu()"
              class="d-flex align-items-center"
              [innerHTML]="'Protocols.QuickPreview.edit' | translate"
            ></li>
            <li
              *ngIf="!item?.deleted && !limitEditAction"
              mat-menu-item
              (click)="deleteEntry(); trigger.closeMenu()"
              class="d-flex align-items-center red-500 hover_red"
              [innerHTML]="'Protocols.QuickPreview.remove' | translate"
            ></li>
          </ng-container>

          <ng-container *ngIf="!!item?.deleted && edit">
            <li
              mat-menu-item
              class="d-flex align-items-center"
              confirmationPopover
              [cancelText]="'Entries.cancel' | translate"
              [confirmText]="'Entries.yes' | translate"
              [popoverTitle]="'Entries.restoreRecord' | translate"
              [popoverMessage]="'Entries.restoreRecordSubtitle' | translate"
              [placement]="'bottom'"
              [innerHTML]="'Entries.restore' | translate"
              (confirm)="restoreEntry(); trigger.closeMenu()"
            ></li>
          </ng-container>
        </ul>
      </mat-menu>

      <ng-container *ngIf="WindowHelper.isLargeWidth && !preview">
        <!-- #region Interaction Buttons -->
        <ng-container *ngIf="!item?.deleted">
          <img
            draggable="false"
            *ngIf="edit"
            class="entry-upload-item-actions-edit"
            (click)="editEntry()"
            src="./assets/img/ic-edit-task.svg"
            alt="Task"
          />

          <img
            draggable="false"
            *ngIf="!limitEditAction && edit"
            class="entry-upload-item-actions-delete ml-3 hover_red red-500"
            (click)="deleteEntry()"
            src="./assets/img/ic-trash-outline-red.svg"
            alt="Trash"
          />
        </ng-container>
        <!-- #endregion -->

        <ng-container *ngIf="!!item?.deleted && !limitEditAction && !preview && edit">
          <span
            class="entry-upload-item-actions-restore align-items-center"
            confirmationPopover
            [cancelText]="'Entries.cancel' | translate"
            [confirmText]="'Entries.yes' | translate"
            [popoverTitle]="'Entries.restoreRecord' | translate"
            [popoverMessage]="'Entries.restoreRecordSubtitle' | translate"
            [placement]="'left'"
            (confirm)="restoreEntry()"
          >
            <svg-icon [src]="'./assets/img/ic-restore.svg'"></svg-icon>
            <span class="ml-2 d-inline-flex">{{ 'Entries.restore' | translate }}</span>
          </span>
        </ng-container>
      </ng-container>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <!-- #region Body -->

  <ul class="entry-upload-item-body" [hidden]="!item.opened">
    <!-- #region Employee -->
    <li
      *ngIf="item?.assignedTo?.length || (item?.externalEmployees)[0]"
      class="entry-upload-item-body_list-element"
    >
      <div [innerHTML]="'Entry.employee' | translate" class="entry-upload-item-body_list-element-title"></div>
      <div class="entry-upload-item-body_list-element-preview bold">
        <b
          class="grey-1"
          [title]="(item?.assignedTo)[0]?.userPerson?.previewName || (item?.externalEmployees)[0]?.name"
          [innerHTML]="(item?.assignedTo)[0]?.userPerson?.previewName || (item?.externalEmployees)[0]?.name"
        ></b>
      </div>
    </li>
    <!-- #endregion -->

    <!-- #region Description -->
    <li *ngIf="item?.description" class="entry-upload-item-body_list-element">
      <div
        [innerHTML]="'Entry.description' | translate"
        class="entry-upload-item-body_list-element-title"
      ></div>
      <div [innerText]="item?.description" class="entry-upload-item-body_list-element-preview"></div>
    </li>
    <!-- #endregion -->

    <!-- #region Images -->
    <li *ngIf="item?.imgAttachments?.length" class="entry-upload-item-body_list-element images">
      <div [innerHTML]="'Entry.images' | translate" class="entry-upload-item-body_list-element-title"></div>
      <div class="entry-upload-item-body_list-element-preview d-flex" [ngClass]="{ 'flex-wrap': allImages }">
        <ng-container [ngTemplateOutlet]="showAllImages ? allImages : defaultTemplate"></ng-container>
      </div>
    </li>
    <!-- #endregion -->

    <ng-template #allImages>
      <ng-container *ngFor="let image of item?.imgAttachments; let i = index">
        <div class="mr-2 mb-2">
          <attachment-image
            [edit]="edit"
            [attachment]="image"
            [fancyboxUrl]="'acceptance-fancybox-' + date + index"
          >
          </attachment-image>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #defaultTemplate>
      <ng-container *ngFor="let image of item?.imgAttachments; let i = index">
        <div class="mr-2" [hidden]="!(i < 3)">
          <attachment-image
            [edit]="edit"
            [attachment]="image"
            [fancyboxUrl]="'acceptance-fancybox-' + date + index"
          >
          </attachment-image>
        </div>
      </ng-container>
      <ng-container *ngIf="item?.imgAttachments?.length > 3">
        <div>
          <attachment-image-more [fancyboxUrl]="'acceptance-fancybox-' + date + index">
          </attachment-image-more>
        </div>
      </ng-container>
    </ng-template>

    <!-- #region Files -->
    <li *ngIf="item?.fileAttachments?.length" class="entry-upload-item-body_list-element">
      <div
        [innerHTML]="'Entry.attachments' | translate"
        class="entry-upload-item-body_list-element-title"
      ></div>
      <div class="entry-upload-item-body_list-element-preview">
        <attachment-file
          [edit]="edit"
          [file]="file"
          *ngFor="let file of item?.fileAttachments"
        ></attachment-file>
      </div>
    </li>
    <!-- #endregion -->
  </ul>
  <!-- #endregion -->
</div>
