import {Component, Input, OnInit} from '@angular/core';
import {ContactType} from '@modules/contacts/shared/enums/contact-type.enum';

@Component({
  selector: 'contact-preview-top-menu',
  templateUrl: './contact-preview-top-menu.component.html'
})
export class ContactPreviewTopMenuComponent implements OnInit {

  @Input() contactType: ContactType = ContactType.PERSONAL;

  constructor() { }

  ngOnInit(): void {
  }

}
