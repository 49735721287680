import { WindowHelper } from '@shared/helpers/window.helper';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { catchError, tap } from 'rxjs/operators';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentType } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { EventSidenavComponent } from '../components/event-sidenav/event-sidenav.component';
import { EventViewState } from '../enums/event-view-state.enum';
import { IEventExtraCreation } from '../interfaces/event-extra-creation.interface';
import { throwError } from 'rxjs';
import { CalendarEvent } from '../models/calendar-event.model';

export class EventSidenavController {
  injector: Injector;
  sidenav: SidenavService;
  apiService: CalendarApiService;
  t: TranslateService;
  s: SnackBarService;
  eventSidenav: EventSidenavService;

  component: ComponentType<EventSidenavComponent> = EventSidenavComponent;
  config: any = {
    maxWidth: '611px',
    class: 'no-padding',
    hideBackdrop: WindowHelper.isMoreLessMD,
    hardReload: true,
    closeOnBackdrop: true,
  };

  constructor() {
    this.injector = AppInjector.getInjector();
    this.sidenav = this.injector.get(SidenavService);
    this.apiService = this.injector.get(CalendarApiService);
    this.t = this.injector.get(TranslateService);
    this.s = this.injector.get(SnackBarService);
    this.eventSidenav = this.injector.get(EventSidenavService);
  }

  createEvent(extra?: IEventExtraCreation) {
    this.sidenav.open(this.component, this.config, {
      viewState: EventViewState.CREATE,
      extra,
    });
  }

  openPreview(event: CalendarEvent) {
    if (!event.id && !event.googleEventId) return;

    const params = {
      viewState: EventViewState.PREVIEW,
    };
    event.id ? (params['eventId'] = event.id) : null;
    event.googleEventId ? (params['googleEventId'] = event.googleEventId) : null;
    event.isGoogleEvent ? (params['isGoogleEvent'] = event.isGoogleEvent) : null;

    event ? (params['event'] = event) : null;
    this.sidenav.open(this.component, this.config, params);
  }

  openEdit(event: CalendarEvent) {
    if (!event.id && !event.googleEventId) return;

    this.sidenav.open(this.component, this.config, {
      viewState: EventViewState.EDIT,
      eventId: event?.id,
      googleEventId: event?.googleEventId,
      isGoogleEvent: !!event?.isGoogleEvent,
    });
  }

  deleteEvent(eventId: number) {
    if (!eventId) return;
    return this.apiService.deleteEvent(eventId).pipe(
      tap(() => {
        this.s.success(this.t.instant('CalendarEvents.eventRemovedSuccess'));
      }),
      catchError((err) => {
        this.s.error(this.t.instant('CalendarEvents.eventRemovedError'));
        return throwError(err);
      }),
    );
  }
}
