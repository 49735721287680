export enum ProtocolAction {
  START_SAVING = 1,
  END_SAVED_SUCCESS,
  END_SAVED_ERROR,
  OPEN_TASK_ADD,
  NEW_TASK,
  NEW_INVOICE,
  EDIT_INVOICE,
  PROJECT_CHANGED,
  UPDATE_ACCEPTANCE_DATA_TYPE,
  RELOAD_LIST,
  UPDATE_CHECKBOX_LIST,
  EDIT_TASK,
  EDIT_TASK_CUSTOM,
  GROUP_ASSIGNMENT
}
