import { CheckPermission } from '../check-permission';
import { PermissionDecorator } from '../interfaces/permission-decorator.interface';

/**
 * @description Permission decoratotor for methods
 * @author Robert Juszczyk
 * @export
 * @param {PermissionDecorator} data
 * @returns {MethodDecorator}
 */
export function Permission(data: PermissionDecorator): MethodDecorator {
  return (target: Function, key: string, descriptor: any) => {
    const method = descriptor.value; // references the method being decorated

    descriptor.value = function (...args) {
      data.objectCreatorIdMethod ? (data.objectCreatorId = data.objectCreatorIdMethod(this)) : null;
      const ctrl = new CheckPermission(data);
      if (!ctrl.check()) {
        return; // block the method
      }
      return method.apply(this, args); // run method
    };
  };
}
