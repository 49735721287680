<notification-base [text]="text" (click)="preview()"></notification-base>

<div class="d-flex mt-1" [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ACCEPTANCE' }">
  <a
    draggable="false"
    [permission]="{
      group: PermissionsGroups.PROTOCOLS,
      action: 'ACCEPTANCE'
    }"
    class="mr-2 green-500 acceptance"
    (click)="accept()"
  >
    <svg-icon
      src="/assets/img/ic-check-green.svg"
      [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
    ></svg-icon>
    {{ 'Notifications.Texts.Protocol.accept' | translate }}</a
  >
  <a
    draggable="false"
    [permission]="{
      group: PermissionsGroups.PROTOCOLS,
      action: 'ACCEPTANCE'
    }"
    class="red-500 acceptance"
    (click)="decline()"
  >
    <svg-icon src="/assets/img/ic-close-red.svg" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"></svg-icon>
    {{ 'Notifications.Texts.Protocol.decline' | translate }}</a
  >
</div>
