<tour-step-template>
  <ng-template let-step="step">
    <mat-card (click)="$event.stopPropagation()">
      <mat-card-title>
        {{step?.title}}
      </mat-card-title>
      <mat-card-content [innerHTML]="step?.content">
      </mat-card-content>
      <mat-card-actions>
        <button mat-icon-button [disabled]="!tourService.hasPrev(step) || tourService.blockChangeStep" (click)="tourService.prev()"
                class="mat-focus-indicator mat-icon-button mat-button-base">
          <span class="mat-button-wrapper">
            <mat-icon>chevron_left</mat-icon>
          </span>
        </button>
        <button mat-icon-button [disabled]="!tourService.hasNext(step) || tourService.blockChangeStep" (click)="tourService.next()"
                class="mat-focus-indicator mat-icon-button mat-button-base">
          <span class="mat-button-wrapper">
          <mat-icon>chevron_right</mat-icon>
          </span>
        </button>

        <button mat-button *ngIf="!tourService.hasNext(step)" (click)="tourService.end()"
                class="mat-focus-indicator mat-button mat-button-base">
          <span class="mat-button-wrapper"> {{step?.endBtnTitle}} </span>
          <div class="mat-ripple mat-button-ripple"></div>
          <div class="mat-button-focus-overlay"></div>
        </button>

        <button mat-button *ngIf="tourService.hasNext(step)" (click)="tourService.next()"
                class="mat-focus-indicator mat-button mat-button-base">
          <span class="mat-button-wrapper"> {{step?.nextBtnTitle}} </span>
          <div class="mat-ripple mat-button-ripple"></div>
          <div class="mat-button-focus-overlay"></div>
        </button>

      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
