import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-reason',
  templateUrl: './status-reason.component.html',
  styleUrls: ['./status-reason.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusReasonComponent implements OnInit {

  public statusClass: string;
  public textClass: string;
  private _color: string;

  @Input() label: string;
  @Input() showTooltipWithComment: boolean;
  @Input() comment: string;
  @Input() colorForText: boolean;

  @Input()
  set color(color: string) {
    this._color = color;
    this.statusClass = 'status--' + color + (this.showOnlySmallDot ? ' status-reason--small' : '');
    this.setColorText();
    this.changes.detectChanges();
  }

  get color() {
    return this._color;
  }

  @Input() showOnlySmallDot: boolean;

  constructor(
    private changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.setColorText();
  }

  setColorText() {
    this.colorForText ? this.textClass = 'status-reason-text--' + this.color : '';
  }


}
