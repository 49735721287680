import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

export enum EStatusColor {
  COLOR_DANGER,
  COLOR_SUCCESS,
  COLOR_DEFAULT,
}

@Component({
  selector: 'status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCellComponent implements OnInit {
  EStatusColor = EStatusColor;

  @Input() status: string;
  @Input() color: EStatusColor = EStatusColor.COLOR_DEFAULT;

  constructor() {}

  ngOnInit(): void {}
}
