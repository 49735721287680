<div class="datatable-pager">
  <ul class="pager">
    <li *ngIf="service?.Page > 1" (click)="setPage(1)">
      <a draggable="false" role="button" aria-label="go to first page"><i class="datatable-icon-prev"></i></a>
    </li>
    <li *ngIf="service?.Page > 1" (click)="setPage(service?.Page - 1)">
      <a draggable="false" role="button" aria-label="go to previous page"
        ><i class="datatable-icon-left"></i
      ></a>
    </li>

    <li
      role="button"
      class="pages ng-star-inserted"
      [ngClass]="{ active: service?.Page == page }"
      *ngFor="let page of pagination"
      (click)="setPage(page)"
    >
      <a draggable="false">{{ page }}</a>
    </li>

    <li
      *ngIf="
        service?.Page < Math.ceil(service?.total / service?.PerPage) &&
        Math.ceil(service?.total / service?.PerPage) > 1
      "
      (click)="setPage(service?.Page + 1)"
    >
      <a draggable="false" role="button" aria-label="go to next page"><i class="datatable-icon-right"></i></a>
    </li>
    <li
      *ngIf="
        service?.Page < Math.ceil(service?.total / service?.PerPage) &&
        Math.ceil(service?.total / service?.PerPage) > 1
      "
      (click)="setPage(Math.ceil(service?.total / service?.PerPage))"
    >
      <a draggable="false" role="button" aria-label="go to last page"><i class="datatable-icon-skip"></i></a>
    </li>
  </ul>
</div>
