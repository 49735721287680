import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tags-record',
  templateUrl: './tags-record.component.html',
  styleUrls: ['./tags-record.component.scss']
})
export class TagsRecordComponent implements AfterViewInit {

  _tagsVisible: string[] = [];
  _tagsHidden: string[] = [];
  private inited: boolean = false;
  private _maxVisibleTags: number = 4;
  private _tags: string[] = [];

  @Input() set maxVisibleTags(count: number) {
    this._maxVisibleTags = count;
    this.changes.detectChanges();
  }

  get maxVisibleTags() {
    return this._maxVisibleTags;
  }

  @Input()
  set tags(tags: string[]) {
    if (tags) {
      this._tags = tags;
      if (this.inited) {
        this._tagsVisible = this.tags.slice(0, this.maxVisibleTags);
        this._tagsHidden = this.tags.slice(this.maxVisibleTags, this.tags.length);
        this.changes.detectChanges();
      }
    }
  }

  get tags() {
    return this._tags;
  }

  @Input() clickable: boolean = false;
  @Output('onTagClick') onTagClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public changes: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    this.inited = true;
    this._tagsVisible = this.tags.slice(0, this.maxVisibleTags);
    this._tagsHidden = this.tags.slice(this.maxVisibleTags, this.tags.length);
    this.changes.detectChanges();
  }

}
