import { Injectable, EventEmitter } from '@angular/core';
import { UserPerson } from '@shared/interfaces/user.interface';
import { JWT } from '@shared/interfaces/jwt.interface';
import { Company } from '@shared/models/company.model';
import { GlobalData } from '@shared/interfaces/global-data.interface';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { BaseStorageService } from './base-storage.service';
import { Employee } from '@shared/models/employee.model';
import { NavigateService } from '@shared/services/navigate.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { ListNotShowColumnConfig } from '@shared/modules/list/interfaces/list-not-show-column-config.interface';
import { CalendarStorageService } from '@modules/calendar/shared/services/calendar-storage.service';
import { CurrencyFormatSymbol } from '@shared/enums/curreny-format.enum';

export enum EStorageAuth {
  STORAGE_USER_PERSON = 'userPerson',
  STORAGE_EMPLOYEE = 'employee',
  STORAGE_JWT = 'jwt',
  STORAGE_SHARED_JWT = 'sharedJWT',
  STORAGE_GLOBAL_DATA = 'globalData',
}

export enum EStorageTour {
  TOUR_SHOW_BUTTON = 'SecondBoxTourButton',
  TOUR_SHOW_MODAL = 'SecondBoxTourModal',
}

export enum EStorageExtras {
  EXTRA_DOWNLOAD_MESSAGE = 'downloadAppMessage',
  EXTRA_SHOW_COLUMNS = 'notShowColumns',
  EXTRA_HIDE_SIDE_NAV = 'hiddenSideNavigation',
  EXTRA_CHANGES_SUMMARY_EXTEND = 'changesSummaryExtend',
  EXTRA_NOTE_TYPE = 'NoteType',
}
export class StorageEvent {
  type: StorageEventType;
  userPerson?: UserPerson;
  company?: Company;
}

export enum StorageEventType {
  UPDATE_USER_PERSON,
  UPDATE_COMPANY,
}

@Injectable({ providedIn: 'root' })
export class StorageService extends BaseStorageService {
  emmiter: EventEmitter<StorageEvent> = new EventEmitter();
  readonly twoWeeks: number = 1000 * 60 * 60 * 24 * 14;

  constructor(
    private n: NavigateService,
    private sidenav: SidenavService,
    private dialog: MatDialog,
    private calendarStorage: CalendarStorageService,
  ) {
    super();
    this.listenStorageEvent();
  }

  get Company(): Company {
    const employee = this.get(EStorageAuth.STORAGE_EMPLOYEE);
    return employee ? new Employee(JSON.parse(employee)).company : null;
  }

  set Company(company: Company) {
    const employee = this.Employee;
    employee.company = company;
    this.Employee = employee;
  }

  get Employee(): Employee {
    const employee = this.get(EStorageAuth.STORAGE_EMPLOYEE);
    return employee ? new Employee(JSON.parse(employee)) : null;
  }

  set Employee(employee: Employee) {
    this.put(EStorageAuth.STORAGE_EMPLOYEE, JSON.stringify(employee));
  }

  get SecondBoxTourButton(): boolean {
    const token = this.get(EStorageTour.TOUR_SHOW_BUTTON);
    return !!token ? token : null;
  }

  set SecondBoxTourButton(value: boolean) {
    this.put(EStorageTour.TOUR_SHOW_BUTTON, value);
  }

  deleteSecondBoxTourButton() {
    this.delete(EStorageTour.TOUR_SHOW_BUTTON);
  }

  get SecondBoxTourModal(): boolean {
    const token = this.get(EStorageTour.TOUR_SHOW_MODAL);
    return !!token ? token : null;
  }

  set SecondBoxTourModal(value: boolean) {
    this.put(EStorageTour.TOUR_SHOW_MODAL, value);
  }

  deleteSecondBoxTourModal() {
    this.delete(EStorageTour.TOUR_SHOW_MODAL);
  }

  get sharedToken(): string {
    const token = this.get(EStorageAuth.STORAGE_SHARED_JWT);
    return !!token ? token : null;
  }

  set sharedToken(sharedToken: string) {
    this.put(EStorageAuth.STORAGE_SHARED_JWT, sharedToken);
  }

  deleteSharedToken() {
    this.delete(EStorageAuth.STORAGE_SHARED_JWT);
  }

  get jwt(): string {
    const jwtStr = this.get(EStorageAuth.STORAGE_JWT);
    if (!jwtStr) {
      return null;
    }

    const jwt = JSON.parse(jwtStr);
    const now = Date.now();
    if (now > jwt.expire) {
      this.delete(EStorageAuth.STORAGE_JWT);
      return null;
    }
    return jwt.token;
  }

  set jwt(jwt: string) {
    const jwtModel = {
      token: jwt,
      expire: Date.now() + this.twoWeeks,
    };
    this.put(EStorageAuth.STORAGE_JWT, JSON.stringify(jwtModel));
  }

  get decimalSymbol() {
    const type = this.UserPerson?.numberFormat || Config.DEFAULT_NUMBER_FORMAT;
    return CurrencyFormatSymbol[type];
  }

  get UserPerson(): UserPerson {
    const userPerson = this.get(EStorageAuth.STORAGE_USER_PERSON);
    return userPerson ? new UserPerson(JSON.parse(userPerson)) : null;
  }

  set UserPerson(user: UserPerson) {
    this.put(EStorageAuth.STORAGE_USER_PERSON, JSON.stringify(user));
  }

  get GlobalData(): GlobalData {
    let globalData;
    try {
      globalData = JSON.parse(this.get(EStorageAuth.STORAGE_GLOBAL_DATA));
      return globalData;
    } catch (e) {
      return null;
    }
  }

  set GlobalData(globalData: GlobalData) {
    globalData.saveTime = moment().format(Config.DATE_SERVER);
    this.put(EStorageAuth.STORAGE_GLOBAL_DATA, JSON.stringify(globalData));
  }

  get DownloadAppMessage(): string {
    return JSON.parse(this.get(EStorageExtras.EXTRA_DOWNLOAD_MESSAGE));
  }

  set DownloadAppMessage(value: string) {
    this.put(EStorageExtras.EXTRA_DOWNLOAD_MESSAGE, JSON.stringify(value));
  }

  get NotShowColumns(): ListNotShowColumnConfig {
    return JSON.parse(this.get(EStorageExtras.EXTRA_SHOW_COLUMNS));
  }

  set NotShowColumns(value: ListNotShowColumnConfig) {
    this.put(EStorageExtras.EXTRA_SHOW_COLUMNS, JSON.stringify(value));
  }

  get HiddenSideNavigation(): { [key: string]: boolean } {
    return JSON.parse(this.get(EStorageExtras.EXTRA_HIDE_SIDE_NAV));
  }

  set HiddenSideNavigation(value: { [key: string]: boolean }) {
    this.put(EStorageExtras.EXTRA_HIDE_SIDE_NAV, JSON.stringify(value));
  }

  get ChangesSummaryExtend(): boolean {
    return JSON.parse(this.get(EStorageExtras.EXTRA_CHANGES_SUMMARY_EXTEND));
  }

  set ChangesSummaryExtend(value: boolean) {
    this.put(EStorageExtras.EXTRA_CHANGES_SUMMARY_EXTEND, JSON.stringify(value));
  }

  get isLogged(): boolean {
    return this.jwt?.toString().length && this.UserPerson?.toString().length ? true : false;
  }

  setSignedIn(login: JWT) {
    this.jwt = login.token;
    this.Employee = login.employee;
    this.UserPerson = login.userPerson;
    this.deleteSharedToken();
  }

  deleteAuth() {
    this.delete(EStorageAuth.STORAGE_JWT);
    this.delete(EStorageAuth.STORAGE_USER_PERSON);
    this.delete(EStorageAuth.STORAGE_EMPLOYEE);
    this.deleteSharedToken();
  }

  resetCalendars() {
    this.calendarStorage.resetSelectedCalendars();
  }

  logout() {
    this.deleteAuth();
    this.sidenav.close();
    this.dialog.closeAll();
    this.resetCalendars();
  }

  removeExtraStorageValues() {
    this.delete(EStorageExtras.EXTRA_SHOW_COLUMNS);
    this.delete(EStorageExtras.EXTRA_DOWNLOAD_MESSAGE);
    this.delete(EStorageExtras.EXTRA_NOTE_TYPE);
  }

  clearAllStorage() {
    this.logout();
    this.removeExtraStorageValues();
    this.delete(EStorageAuth.STORAGE_GLOBAL_DATA);
  }

  listenStorageEvent() {
    window.addEventListener('storage', (e) => {
      switch (e.key) {
        case 'intilio-jwt':
          if (!e.newValue) {
            this.logout();
            this.n.go('login');
          } else if (this.isLogged) {
            this.n.navigate('protocols-list', {}, {}, true);
          }
          break;
        default:
          break;
      }
    });
  }
}
