<top-bar-progress-bar></top-bar-progress-bar>
<sidenav></sidenav>
<!-- <intilio-header *ngIf="!hideHeader && !isSignUpHeader"></intilio-header> -->
<sign-up-header *ngIf="isSignUpHeader"></sign-up-header>
<div class="header-base-panel-wrapper">
  <intilio-header-v2
    *ngIf="!hideHeader && !isSignUpHeader"
    [isExpanded]="isExpanded"
    (mouseenter)="handleHeaderMouseEnter()"
  ></intilio-header-v2>
  <div
    class="base-panel w-100"
    (scroll)="onScroll()"
    [ngClass]="{
      'base-panel--hidden-header': hideHeader,
      'base-panel--mobile': DeviceHelper.isMobileDevice,
      'base-panel--sticky-footer': isFooterSticky,
      'base-panel--ipad': DeviceHelper.isIPad
    }"
    #basePanel
    (mouseenter)="handleBasePanelMouseEnter()"
  >
    <custom-tour-step></custom-tour-step>

    <!-- <download-app></download-app> -->
    <router-outlet></router-outlet>
    <intilio-footer *ngIf="!hideFooter"></intilio-footer>
  </div>
</div>
