<form [formGroup]="form">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <daterangepicker
        #datepicker
        [interactive]="true"
        [isCalendarTime]="true"
        (rangeChanged)="rangeChanged($event)"
      ></daterangepicker>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <ng-select
        class="select select-default select-with-title mb-3 w-100"
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=budget'"
        [initialValue]="initialBudget"
        [formControlName]="PROJECT_DATA_FORM_BOX.budget"
        [addTag]="true"
        [tabIndex]="-1"
        [bindValue]="'name'"
        [bindLabel]="'name'"
        [placeholder]="'Projects.provideBudget' | translate"
        [translateLabelPrefix]="'Projects.Budget.'"
        [addTagText]="'Projects.addTagText' | translate"
        [loadingText]="'Projects.loading' | translate"
        [notFoundText]="'Projects.notFoundText' | translate"
      ></ng-select>
    </div>
    <div class="col-xl-12 client-comments">
      <div class="bold mb-2">{{ 'Projects.clientComments' | translate }}</div>
      <gbxsoft-textarea
        [formControlName]="PROJECT_DATA_FORM_BOX.clientComment"
        [config]="{ name: 'Projects.additionalComments' | translate }"
      ></gbxsoft-textarea>
    </div>
  </div>
</form>
