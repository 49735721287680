<div class="task-basic-preview-data">
  <div class="row mb-4">
    <div class="col-6">
      <div class="new-select-label">{{ 'Projects.projectName' | translate }}</div>
      <div class="value">{{ service.task?.project?.name }}</div>
    </div>
    <div class="col-6">
      <div class="new-select-label">{{ 'Tasks.TaskType.' + service.task.type | translate }}</div>
      <div class="value">{{ service.task.description }}</div>
    </div>
  </div>
  <div *ngIf="!!service.safeContent" class="mb-4" [innerHTML]="service.safeContent"></div>

  <ng-container *ngIf="service.isConfigChange && service.isPreview">
    <task-changes-summary></task-changes-summary>
  </ng-container>

  <div class="mb-4">
    <task-sidenav-blue></task-sidenav-blue>
  </div>

  <div
    *ngIf="!!service.isConfigChange && !storage?.Employee?.isClient && service.safeContentInternalNote"
    class="mb-4"
  >
    <div class="new-select-label">{{ 'Projects.internalNote' | translate }}</div>
    <div *ngIf="!!service.safeContentInternalNote" [innerHTML]="service.safeContentInternalNote"></div>
  </div>
</div>
