<div class="d-flex align-items-center justify-content-between event-details__section">
  <span class="event-details__section-title">{{ 'CalendarEvents.eventData' | translate }}</span>
</div>

<div class="event-data__single">
  <div class="event-data__single-title">{{ 'CalendarEvents.contact' | translate }}</div>
  <div class="event-data__single-info elipsis" [title]="service.creatorContact?.fullName">
    {{ service.creatorContact?.fullName }}
  </div>
</div>
<div class="event-data__single">
  <div class="event-data__single-title">{{ 'CalendarEvents.phone' | translate }}</div>
  <div
    (click)="call(service.creatorContact?.fullPhone)"
    class="event-data__single-info elipsis"
    [ngClass]="{ pointer: !!service.creatorContact?.fullPhone }"
    [title]="
      service.creatorContact?.fullPhone
        ? service.creatorContact?.fullPhone
        : ('CalendarEvents.noInfo' | translate)
    "
  >
    {{
      service.creatorContact?.fullPhone
        ? service.creatorContact?.fullPhone
        : ('CalendarEvents.noInfo' | translate)
    }}
  </div>
</div>
<div class="event-data__single">
  <div class="event-data__single-title">{{ 'CalendarEvents.email' | translate }}</div>
  <div
    (click)="sendMail(service.creatorContact?.email)"
    class="event-data__single-info elipsis"
    [ngClass]="{ pointer: !!service.creatorContact?.email }"
    [title]="
      service.creatorContact?.email ? service.creatorContact?.email : ('CalendarEvents.noInfo' | translate)
    "
  >
    {{
      service.creatorContact?.email ? service.creatorContact?.email : ('CalendarEvents.noInfo' | translate)
    }}
  </div>
</div>
<div class="event-data__single">
  <div class="event-data__single-title">{{ 'CalendarEvents.meetingAddress' | translate }}</div>
  <div
    class="event-data__single-info event-data__single-info--address d-flex align-items-center justify-content-between"
    [ngClass]="{ pointer: !!service.calendarEvent?.address }"
    (click)="openGoogleMaps(service.calendarEvent?.address)"
  >
    <span>
      {{
        service.calendarEvent?.address
          ? service.calendarEvent?.address
          : ('CalendarEvents.noInfo' | translate)
      }}
    </span>
    <img draggable="false" class="ml-2" *ngIf="!!service.calendarEvent?.address" src="./assets/img/ic-map.svg" alt="map" />
  </div>
</div>
