import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GbxsoftFormModule } from '@form/src/public-api';
import { EVENT_SIDENAV_COMPONENTS, EVENT_SIDENAV_COMPONENTS_EXPORT, EVENT_SIDENAV_SERVICES } from '.';
import { SharedModule } from '@shared/shared.module';
@NgModule({
  declarations: [EVENT_SIDENAV_COMPONENTS],
  imports: [CommonModule, GbxsoftFormModule, SharedModule],
  exports: [EVENT_SIDENAV_COMPONENTS_EXPORT],
  providers: [EVENT_SIDENAV_SERVICES],
})
export class EventSidenavModule {}
