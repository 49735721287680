import { Employee } from './employee.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';

export class ContactAccessProject {
  hasAccess?: boolean;
  project?: Project;
  mainProject?: Project;
  id?: number;
  contact?: Contact;
  mainContact?: Contact;
  isResponsible?: boolean;
  employee?: Employee;
  created: string;
  deleted?: string;
  modified?: string;

  constructor(c: ContactAccessProject) {
    this.deserialize(c);
  }

  deserialize(c: ContactAccessProject) {
    c ? Object.assign(this, c) : null;
    this.contact = this.contact ? new Contact(this.contact) : null;
    this.mainContact = this.mainContact ? new Contact(this.mainContact) : null;
  }
}
