import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TaskEmitter } from '@modules/protocols/shared/interfaces/task-emitter.interface';
import { TaskType } from '@shared/enums/task-type.enum';

@Component({ template: '' })
export class BaseTaskComponent {
  TaskTypes = TaskType;
  @Input() type: TaskType;
  @Input() preview: boolean = false;
  @Input() isStatus: boolean = false;
  @Input() isRealization: boolean = false;
  @Input() isAssignment: boolean = false;
  @Input() isDiscount: boolean = false;
  @Input() isTypeSelection: boolean = true;
  @Input() hiddenForClientShow: boolean = true; // Show option to select hidden for client
  @Input() hideAddAction: boolean = false;
  // @Input() hideHiddenFromClient: boolean = false; // That option filters only visible tasks
  @Input() editPermission: PermissionDecorator;
  @Input() deletePermission: PermissionDecorator;
  @Input() snackBottom: boolean = true;

  @Output() taskEmitter: EventEmitter<TaskEmitter> = new EventEmitter();

  get isNote(): boolean {
    return this.type === this.TaskTypes.TYPE_NOTE;
  }

  get isCorrection(): boolean {
    return this.type === this.TaskTypes.TYPE_CORRECTION;
  }

  get isChange(): boolean {
    return this.type === this.TaskTypes.TYPE_CHANGE;
  }
}
