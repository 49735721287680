<modal-title [title]="'Protocols.sendProtocol' | translate"></modal-title>

<form [formGroup]="form" class="protocol-send">
  <mat-dialog-content>
    <radio-box
      [checked]="form?.get('client').value"
      [title]="'Protocols.protocolToAccept' | translate"
      [description]="'Protocols.protocolToAcceptDescr' | translate"
      (change)="onSendTypeChange(true)"
    ></radio-box>

    <radio-box
      [checked]="!form?.get('client').value"
      [title]="'Protocols.fullProtocol' | translate"
      [description]="'Protocols.fullProtocolDescr' | translate"
      (change)="onSendTypeChange(false)"
    ></radio-box>

    <div class="mb-4 mt-3">
      <ng-select
        ngSelectExtension
        #contactSearchSelect="ngSelectExtensionDir"
        [url]="Config.API + '/contact/emails'"
        class="w-100 select select-default"
        [placeholder]="'ProjectContacts.Add.searchPlaceholder' | translate"
        [loadingText]="'Contacts.Manage.loadingContacts' | translate"
        [notFoundText]="'Contacts.Manage.notFoundContacts' | translate"
        [addTagText]="'Contacts.Manage.addContact' | translate"
        [formControlName]="'contact'"
        [dropdownPosition]="'top'"
        [bindLabel]="'email'"
        [hideSelected]="true"
        [searchGetParam]="'search'"
        [multiple]="false"
        [closeOnSelect]="true"
        [addTag]="true"
        (change)="onChangeContact()"
        [responseTransform]="transformContactResponse.bind(this)"
        [tabIndex]="-1">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <contact-option-template [contact]="item"></contact-option-template>
        </ng-template>
      </ng-select>

      <span
        *ngIf="form?.get('email')?.touched"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="emailErrorMessages | translate"
      ></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center"> </mat-dialog-actions>
  <div class="d-flex justify-content-end">
    <button
      (click)="closeProtocolSend()"
      class="btn btn-cancel"
      [innerHTML]="'Protocols.discard' | translate"
    ></button>

    <span
      *ngIf="isOwnerNotFilledFullData && form.valid"
      mat-menu-item
      confirmationPopover
      [popoverClass]="'task-popover'"
      [confirmButtonType]="'default'"
      [placement]="'top'"
      [cancelText]="'Protocols.discard' | translate"
      [confirmText]="'Protocols.yes' | translate"
      (confirm)="sendProtocol()"
      [popoverMessage]="'Protocols.sendOwnerPDF' | translate: { data: isOwnerNotFilledFullData }"
      class="p-0"
    >
      <button class="btn btn-primary ml-2">
        <span
          *ngIf="sending"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>

        <img draggable="false"
          *ngIf="!sending"
          class="mr-2"
          src="./assets/img/ic-save.svg"
          [alt]="'Auth.Login.signIn' | translate"
        />

        <span [innerHTML]="'Protocols.send' | translate"></span>
      </button>
    </span>

    <button
      *ngIf="!isOwnerNotFilledFullData || !form.valid"
      (click)="sendProtocol()"
      [disabled]="sending"
      class="btn btn-primary ml-2"
    >
      <span
        *ngIf="sending"
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>

      <img draggable="false"
        *ngIf="!sending"
        class="mr-2"
        src="./assets/img/ic-save.svg"
      />

      <span [innerHTML]="'Protocols.send' | translate"></span>
    </button>
  </div>
</form>
