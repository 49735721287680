import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SettingsService } from '../services/settings.service';
import { NavigateService } from '@shared/services/navigate.service';
import { Injectable } from '@angular/core';
import { concatMap, finalize, map, switchMap } from 'rxjs/operators';
import { UserMailMethod } from '@shared/models/base-user-person.model';
import { StorageService } from '@core/services/storage.service';
import { UserPerson } from '@shared/interfaces/user.interface';

@Injectable()
export class EmailGoogleAuthResolver implements Resolve<any> {
  constructor(private settings: SettingsService, private n: NavigateService, private store: StorageService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { code } = route?.queryParams;

    if (!code) {
      return;
    }

    return this.settings.grantAccessGoogleAccount(code).pipe(
      switchMap(() =>
        this.settings.setDefaultMethod(UserMailMethod.google).pipe(
          switchMap(() =>
            this.settings.getUserData().pipe(
              map((user: UserPerson) => this.onSuccessUserData(user)),
              finalize(() => {
                this.n.go('email-settings', {}, {});
              }),
            ),
          ),
        ),
      ),
    );
  }

  onSuccessUserData(user: UserPerson) {
    this.store.UserPerson = user;
  }
}
