import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { forwardRef, Type } from '@angular/core';

export function GbxsoftValueAccessor(component: Type<any>) {
  return { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => component), multi: true };
}

export function GbxsoftValidatorAccessor(component: Type<any>) {
  return { provide: NG_VALIDATORS, useExisting: forwardRef(() => component), multi: true };
}
