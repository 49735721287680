import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import * as jws from 'jws';

@Injectable()
export class FileSignatureController {
  lastSign: string = '';
  signature: string = '';
  employeeIdForSignature: number = 0;

  get isSharedJWT() {
    return this.store.sharedToken;
  }

  constructor(private store: StorageService) {}

  getSignature() {
    if (this.signature && this.isValidSignature() && this.isCorrectEmployee()) {
      return this.signature;
    }
    return this.generateSignature();
  }

  isCorrectEmployee() {
    if (this.employeeIdForSignature === this.store?.Employee?.id) {
      return true;
    }
    return false;
  }

  isValidSignature() {
    if (moment().diff(moment(this.lastSign, Config.DATE_SERVER), 's') < Config.FILE_SIGNATURE_VALID_TIME) {
      return true;
    }
    return false;
  }

  generateSignature() {
    if (!this.store.Employee) {
      return;
    }
    this.lastSign = moment().format(Config.DATE_SERVER);
    this.employeeIdForSignature = this.store.Employee.id;
    this.signature = jws.sign({
      header: { alg: 'HS256' },
      payload: {
        empId: this.store.Employee.id,
        iat: moment().unix(),
        exp: moment().unix() + Config.FILE_SIGNATURE_VALID_TIME,
      },
      secret: this.store.Employee.imageSecret,
    });
    return this.signature;
  }
}
