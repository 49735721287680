import { EventReminderFullDay, EventReminderNonFullDay } from '../enums/event-reminder.enum';

export const EventReminderFullDayList = [
  {
    id: EventReminderFullDay.REMIND_ONE_DAY,
    label: 'CalendarEvents.Reminder.FullDay.oneDay',
  },
  {
    id: EventReminderFullDay.REMIND_THREE_DAYS,
    label: 'CalendarEvents.Reminder.FullDay.theeDays',
  },
  {
    id: EventReminderFullDay.REMIND_SEVEN_DAYS,
    label: 'CalendarEvents.Reminder.FullDay.sevenDays',
  },
];

export const EventReminderNonFullDayList = [
  {
    id: EventReminderNonFullDay.REMIND_TEN_MINUTES,
    label: 'CalendarEvents.Reminder.NonFullDay.tenMinutes',
  },
  {
    id: EventReminderNonFullDay.REMIND_THIRTY_MINUTES,
    label: 'CalendarEvents.Reminder.NonFullDay.thirtyMinutes',
  },
  {
    id: EventReminderNonFullDay.REMIND_ONE_HOUR,
    label: 'CalendarEvents.Reminder.NonFullDay.oneHour',
  },
  {
    id: EventReminderNonFullDay.REMIND_THREE_HOURS,
    label: 'CalendarEvents.Reminder.NonFullDay.threeHours',
  },
  {
    id: EventReminderNonFullDay.REMIND_TWENTY_FOUR_HOURS,
    label: 'CalendarEvents.Reminder.NonFullDay.twentyFourHours',
  },
];
