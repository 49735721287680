import { StorageService } from '@core/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ChangesToggleCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-toggle-cell/changes-toggle-cell.component';
import { ComponentRef } from '@angular/core';

export const ProjectChangesListConfig = (active: ActivatedRoute, storage: StorageService): ListConfig => {
  const checkModalDataAndActiveTask = (row) => {
    if (active.snapshot?.queryParams?.modalData) {
      const modalData = JSON.parse(active.snapshot.queryParams.modalData);
      return modalData.taskId === row?.taskId || modalData.taskId == row?.id;
    } else {
      return false;
    }
  };

  let columns = [
    {
      prop: 'checkbox',
      checkbox: true,
      width: 40,
      sortable: false,
      order: 0,
    },
    {
      prop: 'name',
      name: 'ProjectChanges.List.changesName',
      componentRef: 'changesNameCellComponent',
      sortField: 'a.description',
      resizeable: false,
      hideOnMobile: false,
      cellClass: 'tree-cell--flex-start',
      width: 450,
      mobileWidth: 200,
      isTreeColumn: true,
      treeLevelIndent: 15,
      canNotShow: false,
      order: 1,
    },
    {
      prop: 'source',
      name: 'ProjectChanges.List.source',
      componentRef: 'changesSourceCellComponent',
      sortField: 'a.source',
      resizeable: false,
      hideOnMobile: true,
      width: 230,
      order: 2,
    },
    {
      prop: 'days',
      name: 'ProjectChanges.List.addDays',
      componentRef: 'addDaysCellComponent',
      sortField: 'a.additionalTime',
      resizeable: false,
      hideOnMobile: true,
      width: 200,
      order: 3,
    },
    {
      prop: 'price',
      name: 'ProjectChanges.List.price',
      componentRef: 'changesPriceCellComponent',
      sortField: 'a.price',
      additionalSortFields: ['orderBy[a.price]', 'orderBy[a.isPriceEstimated]'],
      resizeable: false,
      hideOnMobile: false,
      width: 200,
      order: 4,
    },
    {
      prop: 'deadline',
      name: 'ProjectChanges.List.deadline',
      componentRef: 'changesDeadlineCellComponent',
      sortField: 'a.termEnd',
      resizeable: false,
      hideOnMobile: true,
      width: 100,
      order: 5,
    },
    {
      prop: 'status',
      name: 'ProjectChanges.List.status',
      componentRef: 'changesStatusCellComponent',
      sortField: 'a.changeStatus',
      resizeable: false,
      hideOnMobile: true,
      width: 190,
      order: 6,
    },
    {
      prop: 'settlementStatus',
      name: 'ProjectChanges.List.settlementStatus',
      componentRef: 'changesSettlementCellComponent',
      sortField: 'a.settlementStatus',
      resizeable: false,
      hideOnMobile: true,
      width: 190,
      order: 7,
    },
    {
      prop: 'created',
      name: 'ProjectChanges.List.created',
      componentRef: 'changesCreatedCellComponent',
      sortField: 'a.created',
      resizeable: false,
      hideOnMobile: true,
      width: 150,
      order: 8,
    },
    {
      toggleMenu: true,
      component: ChangesToggleCellComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 0,
      sortable: false,
      order: 10,
    },
  ];

  const isSettlemntVisible = !(
    storage.Employee?.isWorker ||
    storage.Employee?.isClient ||
    storage.Employee?.isExternalUser
  );

  if (!isSettlemntVisible) {
    columns = columns?.filter((i) => i.prop !== 'settlementStatus');
  }

  return {
    columns: columns,
    url: `${Config.API}/project/[{projectId}]/changes`,
    scrollbarH: true,
    listName: 'project-changes-list',
    responseKey: 'records',
    desktopRowHeight: 70,
    listTitle: 'ProjectChanges.List.title',
    getRowClass: (row) => {
      return { 'row-active': checkModalDataAndActiveTask(row) };
    },
    treeAction: {
      treeToRelation: 'id',
      treeFromRelation: 'mainTaskId',
    },
  };
};
