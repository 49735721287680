import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Contact} from '@modules/contacts/shared/models/contact.model';
import {ContactController} from '@modules/contacts/shared/controllers/contact.controller';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ContactPreviewProjectsListComponent} from '@modules/contacts/pages/contact-preview/components/contact-preview-projects/components/contact-preview-projects-list/contact-preview-projects-list.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';

@Component({
  selector: 'contact-preview-projects',
  templateUrl: './contact-preview-projects.component.html',
  styleUrls: ['./contact-preview-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPreviewProjectsComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  contact: Contact;
  contactController: ContactController;
  sub: Subscription;
  loading: boolean = false;

  @ViewChild('projectList') projectList: ContactPreviewProjectsListComponent;

  constructor(
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.parent.data.subscribe((data: { contact: Contact }) => {
      this.contact = new Contact(data.contact);
      this.contactController = new ContactController(this.contact);
    });
  }

  onUpdateContact(contact: Contact) {
    this.contact = new Contact(contact);
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
    this.projectList ? this.projectList.changes.detectChanges() : null;
  }

  createNewProject() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const ctrl = new ProjectController();
    ctrl.add(this.contact.id).add(() => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

}
