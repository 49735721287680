<main>
  <form [formGroup]="form" class="auth-form">
    <h1 class="mb-2 h1 grey-1" [innerHTML]="'Auth.Reset.notRemember' | translate"></h1>
    <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.Reset.resetViaMail' | translate"></div>
    <div class="grey-4 grey-text" [innerHTML]="'Auth.Reset.secure' | translate"></div>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="email-control special-control required"
      formControlName="email"
      [config]="{
        fieldName: 'reset-email',
        name: 'Auth.Login.email' | translate,
        type: 'email',
        placeholder: ''
      }"
    >
    </gbxsoft-input>

    <div class="d-flex align-items-center justify-content-end mt-4">
      <button type="button" (click)="n.navigate('login')" class="btn btn-cancel"
        [innerHTML]="'Auth.Reset.discard'|translate">
      </button>
      <button type="submit" (click)="submitReseting()" [disabled]="loading" class="btn btn-primary ml-2">
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <img draggable="false" *ngIf="!loading" src="./assets/img/ic-arrow-right.svg" [alt]="'Auth.Login.signIn' | translate" />
        {{ 'Auth.Reset.send' | translate }}
      </button>
    </div>
  </form>
</main>
