import { Component, OnInit } from '@angular/core';
import { CalendarEventsSidenavService } from '../../shared/services/calendar-events-sidenav.service';

@Component({
  selector: 'calendar-events-list',
  templateUrl: './calendar-events-list.component.html',
  styleUrls: ['./calendar-events-list.component.scss'],
})
export class CalendarEventsListComponent implements OnInit {
  constructor(public service: CalendarEventsSidenavService) {}

  ngOnInit(): void {}

  trackByDate(index: number, date: string) {
    return date;
  }

  trackByIndex(index: number) {
    return index;
  }
}
