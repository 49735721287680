import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { StorageService } from '@core/services/storage.service';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectQuestionDirectoryBase } from '@modules/projects/shared/components/project-question-directory-base/project-question-directory-base.component';
import { ProjectSecondBoxEntries } from '@modules/projects/shared/consts/project-second-box-entries';
import { IEntryTemplateConfig } from '@modules/projects/shared/interfaces/entry-template-config.interface';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import {
  EntryListComponent,
  EntryManageType,
} from '@shared/modules/entry/components/entry-list/entry-list.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';

@Component({
  templateUrl: './client-question-directory-second.component.html',
  styleUrls: ['./client-question-directory-second.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientQuestionDirectorySecondComponent
  extends ProjectQuestionDirectoryBase
  implements OnInit, OnDestroy, AfterViewInit
{
  ProjectSecondBoxEntries = ProjectSecondBoxEntries;
  entryListsConfig: IEntryTemplateConfig[] = this.filterList(
    ProjectSecondBoxEntries,
    'dataBox2',
    EntryManageType.BUTTON,
  );

  loading: boolean = false;
  @ViewChildren('entryList') entryList: QueryList<EntryListComponent>;

  constructor(
    public active: ActivatedRoute,
    public changes: ChangeDetectorRef,
    public projectStore: ProjectStoreService,
    public store: StorageService,
    private projectAPI: ProjectAPIService,
    private t: TranslateService,
    private s: SnackBarService,
    private appTourService: AppTourService,
  ) {
    super(projectStore, changes);
  }

  ngOnInit() {
    this.store.sharedToken = this.active.snapshot.params.id;
    this.additionalComment.setValue(this.project?.dataBox2?.additionalComment || null);
  }

  ngAfterViewInit() {
    if (!this.store.SecondBoxTourButton) {
      this.appTourService.initTour(AppTourTypes.QUESTION_DIRECTORY_SECOND, 0, true, null, () => {
        const first = this.entryList.toArray()[0];
        first.openEntryModal();
        this.store.SecondBoxTourButton = true;
      });
    }
  }

  submitDirectoryModel() {
    if (this.loading) return;
    this.loading = true;
    this.changes.detectChanges();

    this.projectAPI
      .editSharedSecondBoxWithToken(this.store.sharedToken, this.directoryModel)
      .subscribe(
        () => this.successEditDirectory(),
        () => this.errorEditDirectory(),
      )
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successEditDirectory() {
    this.s.success(this.t.instant('Projects.projectBox2Updated'));
  }

  errorEditDirectory() {
    this.s.error(this.t.instant('Projects.projectBoxUpdateFail'));
  }

  ngOnDestroy() {
    this.store.deleteSharedToken();
  }
}
