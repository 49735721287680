import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { BaseComponent } from '@shared/components/base.component';
import { JWT } from '@shared/interfaces/jwt.interface';

@Component({
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterConfirmationComponent extends BaseComponent implements OnInit {
  confirmed: boolean = false;

  constructor(
    private active: ActivatedRoute,
    private authService: AuthorizationService,
    private changes: ChangeDetectorRef,
  ) {
    super();
    this.loading = true;
  }

  ngOnInit() {
    this.confirmAccount();
  }

  confirmAccount() {
    const token = this.active.snapshot.params['confirmation-token'];
    if (!token) return;
    this.authService
      .signInConfirmation(token)
      .subscribe({
        next: (jwt: JWT) => {
          this.store.setSignedIn(jwt);
          this.confirmed = true;
          this.changes.detectChanges();
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }
}
