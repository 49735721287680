import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent implements OnInit {
  @Input() link: string;
  @Input() target: string;
  @Input() offsetTop: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
