import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { BaseComponent } from '@shared/components/base.component';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';

@Component({
  templateUrl: './contact.component.html',
})
export class ContactComponent extends BaseComponent implements OnInit {
  CONFIG = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  form: FormGroup;
  constructor(
    public location: Location,
    private service: AuthorizationService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
      topic: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }
    this.loading = true;
    this.service
      .sendContact(this.form.value)
      .subscribe({
        next: this.onSuccessContact.bind(this),
        error: this.onErrorContact.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  onSuccessContact() {
    this.s.success(this.t.instant('Auth.Contact.successSendContact'));
    this.form.reset();
  }

  onErrorContact() {
    this.s.error(this.t.instant('Auth.Contact.errorSendContact'));
  }

  get errorMessages() {
    return Config.validationMessages;
  }
}
