import { UIModule } from '@shared/modules/ui/ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SummaryItemComponent } from './components/summary-item/summary-item.component';
import { SummaryListComponent } from './components/summary-list/summary-list.component';
import { SummaryChangesManage } from './components/summary-changes-manage/summary-changes-manage.component';
import { SummaryToggleComponent } from './components/summary-toggle/summary-toggle.component';

const CHANGE_SUMMARY_SHARED_COMPONENTS = [
  SummaryItemComponent,
  SummaryListComponent,
  SummaryChangesManage,
  SummaryToggleComponent,
];

@NgModule({
  imports: [CommonModule, TranslateModule, UIModule],
  declarations: [CHANGE_SUMMARY_SHARED_COMPONENTS],
  exports: [CHANGE_SUMMARY_SHARED_COMPONENTS],
})
export class ChangeSummarySharedModule {}
