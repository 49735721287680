import { Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { ListNotShowColumnConfig } from '@shared/modules/list/interfaces/list-not-show-column-config.interface';
import { ListEventType } from '@shared/modules/list/model/list-event.model';
import { StorageService } from '@core/services/storage.service';
import { ListService } from '@shared/modules/list/services/list.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'column-config-button',
  templateUrl: './column-config-button.component.html',
  styleUrls: ['./column-config-button.component.scss'],
})
export class ColumnConfigButtonComponent {
  WindowHelper = WindowHelper;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  columnMenu: ElementRef<HTMLElement>;

  constructor(
    public service: ListService,
    private storage: StorageService,
    private changes: ChangeDetectorRef,
  ) {}

  get columnMenuData() {
    return {
      menuWidth: this.columnMenu.nativeElement.clientWidth - 22,
    };
  }

  identify(_index, item: CustomTableColumn) {
    return item.prop;
  }

  columnsSort(a: CustomTableColumn, b: CustomTableColumn) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  onColumnShowChange(column: CustomTableColumn) {
    if (column.canNotShow === false) {
      return;
    }
    column.notShow = !column.notShow;
    this.changes.detectChanges();
    this.updateNotShowColumnsInStorage(column);
  }

  updateNotShowColumnsInStorage(column: CustomTableColumn) {
    const notShowColumnConfig: ListNotShowColumnConfig = this.storage.NotShowColumns || {};

    if (
      notShowColumnConfig[this.service.config.listName] &&
      notShowColumnConfig[this.service.config.listName].userId === this.storage.UserPerson.id
    ) {
      const index = notShowColumnConfig[this.service.config.listName].columns.indexOf(column.prop as string);
      if (column.notShow) {
        // add column prop to storage
        if (index === -1) {
          notShowColumnConfig[this.service.config.listName].columns.push(column.prop as string);
        }
      } else {
        if (index !== -1) {
          // remove column prop from storage
          notShowColumnConfig[this.service.config.listName].columns.splice(index, 1);
        }
      }
    } else {
      notShowColumnConfig[this.service.config.listName] = {
        userId: this.storage.UserPerson.id,
        columns: [column.prop as string],
      };
    }
    this.storage.NotShowColumns = notShowColumnConfig;
    this.service.eventEmitter.emit({ type: ListEventType.CONFIG_COLUMNS });
  }
}
