<div class="project-property-avatar-type">
  <svg-icon [src]="'/assets/img/apartment-'+propertyType+'.svg'"
            [svgStyle]="{ width: '23px', height: '23px' }"
            *ngIf="propertyType && propertyType !== PropertyType.OTHER">
  </svg-icon>
  <svg-icon [src]="'/assets/img/ask.svg'"
            *ngIf="propertyType === PropertyType.OTHER || !propertyType"
            [svgStyle]="{ width: '18px', height: '18px' }">
  </svg-icon>
</div>
