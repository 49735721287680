import { ProtocolPermissionsConfig } from './protocol-permissions.config';
import { TaskPermissionsConfig } from './task-permission.config';
import { ContactPermissionsConfig } from '@core/permissions/configs/contact-permission.config';
import { ProjectPermissionsConfig } from '@core/permissions/configs/project-permission.config';
import { CommentariesPermissionsConfig } from '@core/permissions/configs/commentaries-persmissions.config';
import { ProjectTeamPermissionsConfig } from '@core/permissions/configs/project-team-permission.config';
import { MyWorkPermissionConfig } from '@core/permissions/configs/my-work-permission.config';
import { CalendarPermissionsConfig } from '@core/permissions/configs/calendar-permissions.config';
import { ProjectContactsPermissionConfig } from '@core/permissions/configs/project-contacts-permission.config';
import { MailingPermissionsConfig } from './mailings-permissions.config';
import { ExistingProjectConfigurationPermissionConfig } from './existing-project-configuration-permission.config';

export const permissionsConfigs: any[] = [
  ProtocolPermissionsConfig,
  TaskPermissionsConfig,
  ContactPermissionsConfig,
  ProjectPermissionsConfig,
  CommentariesPermissionsConfig,
  ProjectTeamPermissionsConfig,
  ProjectContactsPermissionConfig,
  MyWorkPermissionConfig,
  CalendarPermissionsConfig,
  MailingPermissionsConfig,
  ExistingProjectConfigurationPermissionConfig,
];
