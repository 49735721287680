<main>
  <content-with-tabs
    [tabLinks]="tabLinks"
    [tabsDescription]="'Contacts.Manage.Menu.description'+(isEdit ? 'Edit' : '') | translate : {contactType: 'Contacts.Type.'+contactType | translate}"
    [tabsTemplate]="contactManageTopMenu"
    [contentTemplate]="contactContent"
  ></content-with-tabs>
</main>

<ng-template #contactManageTopMenu>
  <contact-manage-top-menu [contactType]="contactType" [isEdit]="isEdit"></contact-manage-top-menu>
</ng-template>

<ng-template #contactContent>
  <contact-manage-form
    (onChangeType)="setType($event)"
    [contactType]="contactType"
    [isInited]="isInited"
    [mainContactId]="mainContactId"
    #contactManageFormComponent
  ></contact-manage-form>
</ng-template>
