import { BaseStorageService } from '@core/services/base-storage.service';
import { Injectable } from '@angular/core';
import { TaskType } from '@shared/enums/task-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ProtocolsStorageService extends BaseStorageService {
  constructor() {
    super();
  }

  get NoteType(): TaskType {
    const note = this.get('NoteType') as TaskType;
    return !!note ? note : TaskType.TYPE_NOTE;
  }

  set NoteType(note: TaskType) {
    this.put('NoteType', note);
  }
}
