import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { StorageService } from '@core/services/storage.service';
import { SummaryAmountService } from '@project-modules/project-changes/shared/services/summary-amount.service';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ISummaryConfig } from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'summary-changes-manage',
  templateUrl: './summary-changes-manage.component.html',
  styleUrls: ['./summary-changes-manage.component.scss'],
  animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryChangesManage implements OnInit, OnDestroy {
  opened: boolean = false;
  subListUpdate: Subscription;

  _summaryConfig: ISummaryConfig;

  @Input() set summaryConfig(summaryConfig: ISummaryConfig) {
    this._summaryConfig = summaryConfig;
  }

  get summaryConfig(): ISummaryConfig {
    return this._summaryConfig;
  }

  @Output() updateList: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public changes: ChangeDetectorRef,
    private service: SummaryAmountService,
    private store: StorageService,
  ) {
    this.opened = this.store.ChangesSummaryExtend;
  }

  ngOnInit() {
    this.subscribeListUpdate();
  }

  subscribeListUpdate() {
    this.subListUpdate = this.service.updateList.subscribe(() => {
      this.updateList.emit(true);
    });
  }

  saveSummaryStateToStorage() {
    this.store.ChangesSummaryExtend = this.opened;
    this.changes.detectChanges();
  }

  ngOnDestroy() {
    this.subListUpdate?.unsubscribe();
  }
}
