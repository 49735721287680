<div
  class="avatar overflow-hidden align-items-center d-flex justify-content-center"
  [ngStyle]="{
    width: avatarWidth + 'px',
    height: avatarWidth + 'px',
    borderColor: color ? color : 'transparent',
    borderWidth: color ? '2px' : '0px'
  }"
>
  <img draggable="false" [src]="imageUrl" *ngIf="imageUrl; else placeholder" [width]="avatarWidth" draggable="false" />
  <ng-template #placeholder>
    <img draggable="false"
      src="/assets/img/avatar-placeholder.svg"
      alt="Avatar Placeholder"
      [width]="avatarWidth"
      draggable="false"
    />
  </ng-template>
</div>
