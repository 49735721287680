<button
  class="hamburger hamburger--spin"
  type="button"
  [ngClass]="{ 'is-active': headerService.isMenuOpen }"
  (click)="headerService.isMenuOpen ? headerService.closeMenu() : headerService.openMenu()"
>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
