import { DeviceHelper } from '@shared/helpers/device.helper';
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { SnackBarService } from '@core/services/snackbar.service';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { downloadPdf } from '@shared/helpers/download-pdf.helper';
import { Employee } from '@shared/models/employee.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { NavigateService } from '@shared/services/navigate.service';
import {
  ProtocolAcceptanceComponent,
  ProtocolAcceptanceType,
} from '../components/protocol-acceptance/protocol-acceptance.component';
import { ProtocolSelectionComponent } from '../components/protocol-selection/protocol-selection.component';
import { ProtocolSendComponent } from '../components/protocol-send/protocol-send.component';
import { ProtocolAction } from '../enums/protocol-action.enum';
import { ProtocolsStatuses } from '../enums/protocols-statuses.enum';
import { Protocol } from '../models/protocol';
import { ProtocolApiService } from '../services/protocol-api.service';
import { ProtocolAcceptance } from '@modules/protocols/shared/interfaces/protocol-acceptance.interface';

export class ProtocolController {
  loading: boolean = false;

  injector: Injector;
  t: TranslateService;
  s: SnackBarService;
  n: NavigateService;
  clientService: ClientAcceptanceService;
  dialog: MatDialog;

  constructor(private pService: ProtocolApiService, private protocol?: Protocol) {
    this.injector = AppInjector.getInjector();
    this.s = this.injector.get(SnackBarService);
    this.t = this.injector.get(TranslateService);
    this.n = this.injector.get(NavigateService);
    this.clientService = this.injector.get(ClientAcceptanceService);
    this.dialog = this.injector.get(MatDialog);
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'SEND',
    objectCreatorIdMethod: (self) => [
      self.protocol?.creator.id,
      self.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
    ],
  })
  send(snackBottom: boolean = true) {
    if (this.protocol?.status === ProtocolsStatuses.STATUS_DRAFT) {
      return false;
    }
    this.dialog.open(ProtocolSendComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: { id: this.protocol.id, snackBottom },
    });
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'ADD',
  })
  add(projectId?: number, data?: any) {
    const dialog = this.dialog.open(ProtocolSelectionComponent, {
      width: '1170px',
      autoFocus: false,
      data,
    });
    dialog.componentInstance.projectId = projectId;
  }

  // @Permission({
  //   group: PermissionsGroups.PROTOCOLS,
  //   action: 'PREVIEW',
  //   objectCreatorIdMethod: (self) => [
  //     self.protocol?.creator.id,
  //     self.protocol?.project?.basicDataBox?.mainContact?.id,
  //     self.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
  //   ],
  // })
  preview(employee: Employee, protocolAcceptanceId?: number, openPdf?: boolean) {
    if (protocolAcceptanceId || openPdf) {
      const id =
        protocolAcceptanceId || employee.isClient ? this.protocol?.lastAcceptance?.id : this.protocol?.id;

      if (DeviceHelper.isPWA) {
        this.n.go(`protocol-preview`, { id });
      } else {
        const url = this.n.getPath('protocol-preview', {}, { id });
        window.open(url, '_blank');
      }
    } else {
      return this.n.go(`${this.protocol.type}-protocol-preview`, { id: this.protocol.id });
    }
  }

  signProtocol() {
    this.n.go(
      `${this.protocol.type}-protocol-preview`,
      { id: this.protocol.id },
      { queryParams: { sign: true } },
    );
  }

  previewAcceptance(protocol: Protocol | ProtocolAcceptance, id?: number) {
    if (DeviceHelper.isPWA) {
      this.n.go('protocol-acceptance-preview', { id: id ? id : protocol.id });
    } else {
      const url = this.n.getPath('protocol-acceptance-preview', {}, { id: id ? id : protocol.id });
      window.open(url, '_blank');
    }
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'ACCEPTANCE',
  })
  acceptance(type: ProtocolAcceptanceType, id?: number) {
    this.dialog.open(ProtocolAcceptanceComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: true,
      data: { protocol: this.protocol, type, id },
    });
  }

  download(clientPreview: boolean = false, employee: Employee) {
    if (employee.isClient) {
      return this.downloadAsClient();
    }
    this.pService.getProtocolPDF(this.protocol.id, clientPreview).subscribe({
      next: (res) => {
        downloadPdf(res, this.getFileProtocolName());
      },
      error: (e) => {
        this.s.error(this.t.instant('Protocols.errorDownloadPDF'));
      },
    });
  }

  downloadAsClient(id?) {
    this.clientService
      .getProtocolAcceptancePDF(id ? id : this.protocol.lastAcceptance.id.toString(), false)
      .subscribe({
        next: (res) => {
          downloadPdf(res, this.getFileProtocolName());
        },
        error: (e) => {
          this.s.error(this.t.instant('Protocols.errorDownloadPDF'));
        },
      });
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => [
      self.protocol?.creator.id,
      self.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
    ],
  })
  edit() {
    if (this.protocol.status === ProtocolsStatuses.STATUS_DRAFT) {
      return this.n.go(this.protocol.type + '-protocol-new', { id: this.protocol.id });
    } else {
      return this.n.go(this.protocol.type + '-protocol-edit', { id: this.protocol.id });
    }
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self) => [
      self.protocol?.creator.id,
      self.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
    ],
  })
  remove() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    return this.pService
      .deleteProtocol(this.protocol.id)
      .subscribe({
        next: (e) => {
          this.pService.manager.protocolAction.emit({ type: ProtocolAction.RELOAD_LIST });
          this.s.success(this.t.instant('Protocols.successDeleteProtocol'));
        },
        error: (e) => {
          this.s.error(this.t.instant('Protocols.errorDuringDeleteProtocol'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  @Permission({
    group: PermissionsGroups.PROTOCOLS,
    action: 'ACTIVATE',
    objectCreatorIdMethod: (self) => [
      self.protocol?.creator.id,
      self.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
    ],
  })
  activate(toaster?: any) {
    if (this.loading || !this.protocol?.id) {
      return;
    }
    this.loading = true;
    return this.pService
      .activateProtocol(this.protocol.id)
      .subscribe({
        next: (p) => this.successActivation(p, toaster),
        error: () => {
          this.s.error(this.t.instant('Protocols.activationError'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  successActivation(protocol, toaster?: any) {
    this.protocol = protocol;
    this.n.go(
      this.pService.manager.protocolType + '-protocol-edit',
      { id: this.pService.manager.protocol.id },
      { replaceUrl: true },
    );
    this.s.success(this.t.instant('Protocols.activationSuccess'), toaster);
  }

  private getFileProtocolName() {
    const name = this.protocol.idNumber
      ? this.protocol.idNumber.toString()
      : this.t.instant('Protocols.newName');
    return `[${Config.SYSTEM_NAME.charAt(0).toUpperCase() + Config.SYSTEM_NAME.slice(1)}]-${this.t.instant(
      'Protocols.Types.' + this.protocol.type,
    )}-${name}`;
  }
}
