import { publicFile } from '@shared/helpers/public-file.helper';
import { FormGroup, FormControl } from '@angular/forms';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { CalendarEntityPreview } from './calendar-entity-preview.model';
import { CalendarEntity } from './calendar-entity.model';
import { EmployeeCalendar } from './employee-calendar.model';
import { getCalendarIcon } from '@shared/helpers/calendar-icon.helper';

export class PersonalCalendars {
  injector: Injector;
  t: TranslateService;

  employees: CalendarEntityPreview[] = [];
  myCalendars: CalendarEntityPreview[] = [];
  sharedCalendars: CalendarEntityPreview[] = [];

  constructor(calendar?: PersonalCalendars) {
    calendar ? this.deserialize(calendar) : null;
  }

  deserialize(calendar: PersonalCalendars) {
    Object.assign(this, calendar);
    this.initializeInjectors();
    this.setEmployees();
    this.setMyCalendars();
    this.setSharedCalendars();
  }

  initializeInjectors() {
    this.injector = AppInjector.getInjector();
    this.t = this.injector.get(TranslateService);
  }

  setCalendar(item: any, name: string, addEvent: boolean) {
    const c = new CalendarEntityPreview(item);
    c.setPreviewName(name);
    c.addEvent(addEvent);
    return c;
  }

  //#region  Employee Calendars
  setEmployees() {
    this.employees = this.employees?.map((i) => this.setSingleEmployeeCalendar(i)) || [];
  }

  setSingleEmployeeCalendar(i) {
    const j = new EmployeeCalendar(i as any);
    const c = this.setCalendar(j.defaultCalendar as CalendarEntityPreview, j?.userPerson?.previewName, true);
    c.setEmployeeId(j?.contact?.id);
    c.setPhotoURL(publicFile(j.userPerson.photo));
    c.addEvent(true);
    return c;
  }

  setMyCalendars() {
    this.myCalendars = this.myCalendars?.map((i) => this.setSingleCalendar(i)) || [];
  }

  setSharedCalendars() {
    this.sharedCalendars = this.sharedCalendars?.map((i) => this.setSingleCalendar(i)) || [];
  }

  getCalendarIcon(i: CalendarEntityPreview) {
    return getCalendarIcon(i);
  }

  getCalendarTitle(i) {
    return i.type === 'default'
      ? this.t.instant('Calendar.myCalendarTitle')
      : i.name && i.name != i.url
      ? i.name
      : this.capitalizeFirstLetter('google');
  }

  setSingleCalendar(i) {
    const c = this.setCalendar(i, this.getCalendarTitle(i), true);
    c.addEvent(false);
    c.setPhotoURL(this.getCalendarIcon(c));

    return c;
  }
  //#endregion

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
