import { CheckPermission } from '@core/permissions/check-permission';
import { HeaderConfig } from '@shared/configs/header.config';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { StorageService } from '@core/services/storage.service';
import { BasePanelChangeEvent, BasePanelChangeEventType, BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { AppInjector } from '@shared/services/app-injector.service';

export class BaseNavigationComponent {
  tabLinks: TabLink[] = [];
  basePanelService: BasePanelService;

  constructor(public store: StorageService) {
    this.basePanelService = AppInjector.getInjector().get(BasePanelService);
    this.listenHeaderChanges();
    this.configureNavigation();
  }

  listenHeaderChanges() {
    this.basePanelService.panelChangeEmitter.subscribe((e: BasePanelChangeEvent) => {
      switch (e.type) {
        case BasePanelChangeEventType.RECONFIGURE_NAVIGATION:
          this.tabLinks = [];
          this.configureNavigation();
          break;
      }
    });
  }

  configureNavigation() {
    const ctrl = new CheckPermission(null);
    Object.values(HeaderConfig).map((tabLink: TabLink) => {

      if (!tabLink.permissionAction || !isNotNullOrUndefined(tabLink.permissionGroup)) {
        this.tabLinks = [];
        return console.error('ERROR Error: There is no permissions declared for header navigation!');
      }

      if ((tabLink.permissionGroup === PermissionsGroups.CONTACTS || tabLink.permissionGroup === PermissionsGroups.PROJECTS) && tabLink.permissionAction === 'LIST') {
        const employee = this.store.Employee;
        ctrl.setPermissionDecorator({
          group: tabLink.permissionGroup,
          action: tabLink.permissionAction,
          objectCreatorId: employee?.hasResponsibleProjects ? employee.id : null
        });
      } else {
        ctrl.setPermissionDecorator({group: tabLink.permissionGroup, action: tabLink.permissionAction});
      }


      if (ctrl.check()) {
        this.tabLinks.push(tabLink);
      }
    });
  }
}
