import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionType } from '../enums/permission-type.enum';
import { PermissionsAction } from '../interfaces/permissions-action.interface';
import { PermissionsConfig } from '../interfaces/permissions-config';
import { PermissionsGroups } from '../permissions.group';

export class ProjectPermissionsConfig implements PermissionsConfig {
  static readonly EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PREVIEW_EXISTING_PROJECT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly EDIT_SECOND_BOX_FROM_LIST: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_CLIENT,
  ];

  static readonly ADD: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_DOCUMENTATION_READ: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly LIST: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CLIENT_VISIBILITY: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly BUDGET_VISIBILITY: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGES_VISIBILITY: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly ALERTS: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly SUMMARY_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly EMAIL_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_CLIENT_DATA_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly SUMMARY_STATISTICS: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly TASKS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly DOCUMENTS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
  ];

  static readonly CHANGES_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly REPORTS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
  ];

  static readonly PROTOCOLS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly DRAG_PROJECT_TASKS: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CONTACTS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly TEAM_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
  ];

  static readonly PHOTOS_TAB: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
  ];

  static readonly PROJECT_BOX_FIRST_EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_BOX_SECOND_EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_BOX_THIRD_EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly PROJECT_BOX_FIRST_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_BOX_SECOND_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PROJECT_BOX_THIRD_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly PROJECT_CONFIGURATOR_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.PROJECTS;
}
