export enum PermissionsGroups {
  PROTOCOLS,
  TASKS,
  CONTACTS,
  PROJECTS,
  COMMENTARIES,
  PROJECT_TEAM,
  PROJECT_CONTACTS,
  MY_WORK,
  CALENDAR,
  MAILINGS,
  EXISTING_PROJECT_CONFIGURATOR,
}
