<div class="selection-change-title d-flex align-items-center justify-content-between">
  <span class="new-select-label" [innerHTML]="title"></span>
</div>
<form [formGroup]="form" class="row">
  <ng-select
    formControlName="select"
    class="new-select col-6"
    [items]="items"
    [placeholder]="selectConfig?.placeholder"
    [searchable]="false"
    [clearable]="false"
    [tabIndex]="-1"
    (change)="changeValue($event)"
  >
  </ng-select>
  <input-v2
    class="col-6"
    [errMessages]="errorMessages('input')"
    #input
    [disabled]="disabledInput"
    formControlName="input"
    [placeholder]="config.name"
    [type]="config.type"
  ></input-v2>
</form>
