<modal-title [title]="('Projects.Menu.questions' | translate) + ' 2'"></modal-title>
<!-- #region Content -->
<mat-dialog-content>
  <div copyDir [textCopy]="token" [callback]="showTooltip.bind(this)" *ngIf="!!token" class="invitation-link">
    <div
      class="hidden"
      mat-raised-button
      #tooltip="matTooltip"
      matTooltip="{{ 'Projects.copied' | translate }}"
      matTooltipPosition="above"
    ></div>
    <div class="d-flex align-items-center justify-content-between">
      <span>{{ token }}</span>
      <app-button
        [class]="'ml-2'"
        [config]="{
          type: ButtonTypes.PRIMARY_WHITE,
          text: 'Projects.copy' | translate,
          size: ButtonSize.SMALL,
          prependSrc: './assets/img/ic-copy.svg'
        }"
      ></app-button>
    </div>
  </div>
  <div class="pt-3 box-form">
    <ng-container *ngIf="data?.contact">
      <div class="bold mb-1">
        {{ 'Projects.contactData' | translate }}
      </div>

      <contact-info-single
        [isPreviewVisible]="false"
        [showAvailability]="false"
        [withoutActions]="true"
        [contact]="data?.contact"
      ></contact-info-single>
    </ng-container>

    <div class="mb-3">
      {{ 'Projects.contactInviteDescription' | translate }}
    </div>

    <form [formGroup]="form">
      <div class="mb-2" *ngIf="!data?.contact?.email || !data?.contact">
        <gbxsoft-input
          class="email-control special-control required"
          formControlName="email"
          [errMessages]="errorMessages('email')"
          [config]="{ name: 'Settings.User.email' | translate, type: 'text', placeholder: '' }"
        >
        </gbxsoft-input>
      </div>
      <div class="mb-2">
        <radio-box
          [smallText]="true"
          [checked]="!form?.get('share')?.value"
          [title]="'Projects.shareWithoutInvite' | translate"
          [description]="'Projects.shareWithoutInviteDescr' | translate"
          (change)="selectShareType(false)"
        ></radio-box>

        <radio-box
          [smallText]="true"
          [checked]="form?.get('share')?.value"
          [title]="'Projects.shareWithInvite' | translate"
          [description]="'Projects.shareWithInviteDescr' | translate"
          (change)="selectShareType(true)"
        ></radio-box>
      </div>
    </form>
  </div>
  <!-- Kontakt opis select checkbox -->
</mat-dialog-content>

<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    class="bold"
    (onClick)="discard()"
    [disabled]="loading"
    [config]="{
      type: ButtonTypes.DISCARD_BUTTON,
      text: 'Projects.dontShare' | translate,
      size: ButtonSize.LARGE
    }"
  ></app-button>

  <app-button
    class="bold ml-2"
    [loading]="loading"
    [disabled]="loading"
    (onClick)="submit()"
    [config]="{
      type: ButtonTypes.PRIMARY_BLUE,
      text: 'Projects.share' | translate,
      size: ButtonSize.LARGE,
      prependSrc: '/assets/img/ic-share.svg'
    }"
  ></app-button>
</mat-dialog-actions>
<!-- #endregion -->
