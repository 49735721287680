<div class="contact-preview-details">
  <contact-preview-details-title
    (onAddProjectToContact)="onUpdateContact($event)"
    [contact]="contact"
  ></contact-preview-details-title>

  <contact-preview-details-basic-data
    [contact]="contact"
    (onUpdateContact)="onUpdateContact($event)"
  ></contact-preview-details-basic-data>

  <contact-info-single
    [mainContact]="contact"
    [contact]="getContactPersonWithParent()"
    *ngIf="contact?.contactPerson"
    (onChangeContactPerson)="onChangeContactPerson($event)"
    (onRemove)="onRemoveSubcontactContactPerson(contact?.contactPerson)"
    (onRemoveContactPerson)="onRemoveContactPersonFromMain(contact?.contactPerson)"
  >
  </contact-info-single>

  <contact-preview-details-no-contact-person
    *ngIf="!contact?.contactPerson"
    [contact]="contact"
    (onChangeContactPerson)="onChangeContactPerson($event)"
  >
  </contact-preview-details-no-contact-person>

  <contact-preview-details-statistics
    [contact]="contact"
    (onAddAccount)="onAddAccount($event)"
  ></contact-preview-details-statistics>

  <commentary-manage
    #comments
    [id]="contact.id"
    [type]="CommentaryType.CONTACT"
    [commentaries]="contact.comments"
    (commentaryEvent)="updateCommentaries($event)"
  ></commentary-manage>

  <div class="d-flex justify-content-between align-items-center mt-4 flex-column-reverse flex-md-row">
    <app-button
      [permission]="{
        group: PermissionsGroups.CONTACTS,
        action: 'REMOVE'
      }"
      [config]="{
        type: ButtonTypes.SECONDARY_RED,
        text: 'Contacts.Preview.remove' | translate,
        size: ButtonSize.SMALL,
        prependSrc: '/assets/img/ic-trash-outline.svg'
      }"
      (click)="remove()"
    >
    </app-button>
    <div class="grey-4 mb-3 mb-md-0">
      {{ 'Contacts.Preview.createdBy' | translate }}
      <b class="grey-1" *ngIf="contact?.creator">{{ contact?.creator?.userPerson?.previewName }}</b>
      {{ contact?.created | dateFormat }}
    </div>
  </div>
</div>
