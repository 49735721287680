<div class="mt-4 d-flex justify-content-center align-content-center flex-column text-center">
  <h3 class="blue-300 mb-4 mt-4" [innerHTML]="'Notifications.errorGettingNotifications' | translate"></h3>
  <app-button
    (click)="reload.emit()"
    [config]="{
      text: buttonText,
      type: ButtonTypes.SECONDARY_BLUE
    }"
  ></app-button>
</div>
