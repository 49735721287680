import {Component, OnInit} from '@angular/core';
import {TabLink} from '@shared/interfaces/menu/tab-link.interface';
import {Contact} from '@modules/contacts/shared/models/contact.model';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigateService} from '@shared/services/navigate.service';

@Component({
  selector: 'contact-preview',
  templateUrl: './contact-preview.component.html'
})
export class ContactPreviewComponent implements OnInit {

  tabLinks: TabLink[] = [];
  contact: Contact;

  constructor(
    private route: ActivatedRoute,
    private t: TranslateService,
    private n: NavigateService
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: { contact: Contact }) => {
      this.contact = data.contact;
      this.initTabLinks();
    });
  }

  initTabLinks() {

    this.tabLinks = [];

    this.tabLinks.push({
      name: this.t.instant('Contacts.Preview.Menu.details'),
      link: this.n.getPath('contact-preview-details',{},{id: this.contact.id})
    });

    this.tabLinks.push({
      name: this.t.instant('Contacts.Preview.Menu.subcontacts'),
      link: this.n.getPath('contact-preview-subcontacts',{},{id: this.contact.id})
    });

    this.tabLinks.push({
      name: this.t.instant('Contacts.Preview.Menu.projects'),
      link: this.n.getPath('contact-preview-projects',{},{id: this.contact.id})
    });
  }


}
