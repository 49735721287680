<header class="signup-header d-flex align-items-center">
  <nav class="container">
    <div class="col-12 d-flex align-items-center justify-content-between p-0">
      <div class="d-flex align-items-center">
        <div (click)="n.go(Config.MAIN_VIEW)" class="header-logo pointer d-flex align-items-center">
          <img draggable="false" src="/assets/img/logo.svg" [alt]="'projectName' | translate" />
        </div>

        <!-- <ul class="d-none d-md-block mb-0">
          <li>
            <a draggable="false" [title]="'Auth.Menu.about' | translate">{{ 'Auth.Menu.about' | translate }}</a>
          </li>
        </ul> -->
      </div>

      <div class="d-flex">
        <div class="d-none d-md-block">
          <app-button
            [class]="'font-400'"
            (click)="n.go('login')"
            [config]="{
              text: 'Auth.Login.signIn' | translate,
              type: ButtonTypes.PRIMARY_WHITE
            }"
          ></app-button>
          <app-button
            (click)="n.go('register')"
            class="ml-2"
            [class]="'font-400'"
            [config]="{
              text: 'Auth.Login.createAccountFree' | translate,
              type: ButtonTypes.PRIMARY_BLUE
            }"
          ></app-button>
        </div>
        <div class="d-block d-md-none">
          <signup-humburger></signup-humburger>
        </div>
      </div>
    </div>
  </nav>
</header>
