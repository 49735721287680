<div class="button-group">
  <ng-container *ngIf="!config.mobileDropdown || window.innerWidth > config.mobileDropdown">
    <a
      draggable="false"
      class="button-group__button"
      (click)="button.prop || button.prop === 0 ? onClick.emit(button.prop) : null"
      matRipple
      [routerLink]="button.link"
      [routerLinkActive]="button.link ? 'button-group__button--active' : ''"
      [ngClass]="{ 'button-group__button--active': button.active }"
      *ngFor="let button of config?.buttons"
    >
      {{ button.name }}
    </a>
  </ng-container>

  <ng-container *ngIf="config.mobileDropdown && window.innerWidth < config.mobileDropdown">
    <ng-container *ngFor="let btn of config?.buttons">
      <app-button
        [matMenuTriggerFor]="menu"
        *ngIf="btn.active || getRouterLinkActive(btn.link)"
        [config]="{
          type: ButtonTypes.LIGHT_BLUE,
          text: btn.name,
          size: ButtonSize.SMALL,
          prependSrc: btn.icon,
          appendSrc: './assets/img/ic-arrow-down-blue.svg'
        }"
      >
      </app-button>
    </ng-container>

    <mat-menu #menu="matMenu" xPosition="after">
      <button
        mat-menu-item
        class="button-group__button__dropdown"
        *ngFor="let button of config?.buttons"
        (click)="onDropdownButtonClick(button)"
      >
        <span>
          <svg-icon [src]="button.icon" *ngIf="button.icon"></svg-icon>
          {{ button.name }}
        </span>
      </button>
    </mat-menu>
  </ng-container>
</div>
