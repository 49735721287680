import { PermissionsConfig } from '@core/permissions/interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class MyWorkPermissionConfig implements PermissionsConfig {
  static readonly LIST: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    UserRoles.ROLE_EMPLOYEE_WORKER
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.MY_WORK;
}
