<div
  class="action-title-header"
  [ngClass]="{
    'action-title-header--sticky': sticky,
    'action-title-header--sticky--side-navigation': stickyWithSideNavigation,
    'action-title-header--double-height-mobile': doubleHeightOnMobile
  }"
  #header
>
  <div class="action-title-header__left-side">
    <h1 class="action-title-header-title" [innerHTML]="title"></h1>
    <app-button
      *ngIf="!actionTemplate"
      class="show-tablet"
      [permission]="permissionDecorator"
      (onClick)="onAction.emit(true)"
      [loading]="loading"
      [config]="{
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text: WindowHelper.isLessXXL ? '' : buttonText,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL,
        prependSrc: prependSrc
      }"
    >
    </app-button>
    <div *ngIf="actionTemplate" class="show-tablet">
      <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
    </div>
  </div>
  <ng-content></ng-content>
  <app-button
    *ngIf="!actionTemplate"
    class="hide-tablet"
    [permission]="permissionDecorator"
    (onClick)="onAction.emit(true)"
    [loading]="loading"
    [config]="{
      type: ButtonTypes.NEW_PRIMARY_BLUE,
      text: WindowHelper.isLessXXL ? '' : buttonText,
      size: ButtonSize.LARGE,
      smSize: ButtonSize.SMALL,
      prependSrc: prependSrc
    }"
  >
  </app-button>
  <div *ngIf="actionTemplate" class="hide-tablet">
    <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
  </div>
</div>
