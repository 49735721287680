import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TopProgressBarService } from '@modules/top-progress-bar/shared/services/top-progress-bar.service';
import { tap, catchError } from 'rxjs/operators';

export const TopProgressBarSkipHeader = 'T-Skip-Interceptor';
@Injectable()
export class TopProgressBarInterceptor implements HttpInterceptor {
  constructor(private topBarService: TopProgressBarService) {}
  /**
   *
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TopProgressBarInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.method === 'GET' && !request.headers.has(TopProgressBarSkipHeader)) {
      this.topBarService.showTopProgressBar();
    }

    const headers = request.headers.delete(TopProgressBarSkipHeader);

    return next.handle(request.clone({headers})).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.topBarService.hideTopProgressBar();
        }
      }),
      catchError((error) => {
        this.topBarService.hideTopProgressBar();
        return throwError(error);
      }) as any,
    );
  }
}
