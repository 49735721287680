import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Config } from '@shared/configs/config';
import { Component } from '@angular/core';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { EventViewState } from '@shared/modules/event-sidenav/enums/event-view-state.enum';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';

@Component({
  selector: 'event-sticky-action',
  templateUrl: './event-sticky-action.component.html',
  styleUrls: ['./event-sticky-action.component.scss'],
})
export class EventStickyActionComponent {
  Config = Config;

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  eventCtrl: EventSidenavController = new EventSidenavController();

  constructor(public service: EventSidenavService) {}

  submitForm() {
    Object.keys(this.service.EVENT_SIDENAV_FORM).forEach((key: string) => {
      const control = this.service.form.get(key);
      control?.markAsTouched();
      control?.updateValueAndValidity({ emitEvent: false });
    });
    switch (this.service.viewState) {
      case EventViewState.CREATE:
        this.service.createEvent()?.subscribe({ next: this.successUpdatingTask.bind(this) });
        break;
      case EventViewState.EDIT:
        this.service.updateEvent()?.subscribe({ next: this.successUpdatingTask.bind(this) });
        break;
      default:
        break;
    }
  }

  successUpdatingTask(event: CalendarEvent) {
    this.eventCtrl.openPreview(event || this.service?.calendarEvent);
  }
}
