import { EventEmitter, Injectable } from '@angular/core';
import { BasePanelComponent } from '@shared/modules/base-panel/components/base-panel/base-panel.component';
import {
  RouterNavigationController,
  RouterNavigationEvent,
  RouterNavigationEventType
} from '@shared/controllers/router-navigation.controller';

@Injectable()
export class BasePanelService {

  panelChangeEmitter: EventEmitter<BasePanelChangeEvent> = new EventEmitter<BasePanelChangeEvent>();
  basePanelComponent: BasePanelComponent;
  routerNavigationController: RouterNavigationController;

  constructor() {
  }

  get scrollTop() {
    return document.querySelector('.base-panel').scrollTop;
  }

  get scrollbarWidth() {
    return this.basePanelComponent.basePanel.nativeElement.offsetWidth - this.basePanelComponent.basePanel.nativeElement.clientWidth;
  }

  blockScroll() {
    this.panelChangeEmitter.emit({type: BasePanelChangeEventType.BLOCK_SCROLL});
  }

  unBlockScroll() {
    this.panelChangeEmitter.emit({type: BasePanelChangeEventType.UNBLOCK_SCROLL});
  }

  noRestoreScrollPosition() {
    this.routerNavigationController.routerNavigationEventEmitter.emit({type: RouterNavigationEventType.NO_RESTORE});
  }

  restoreScrollPosition() {
    this.routerNavigationController.routerNavigationEventEmitter.emit({type: RouterNavigationEventType.RESTORE});
  }
}

export interface BasePanelChangeEvent {
  type: BasePanelChangeEventType;
  data?: any;
}

export enum BasePanelChangeEventType {
  HIDE_TOP_MENU,
  SHOW_TOP_MENU,
  SCROLL_TO,
  BLOCK_SCROLL,
  UNBLOCK_SCROLL,
  RECONFIGURE_NAVIGATION
}
