import { countries } from '@shared/datas/countries';
import {
  GoogleAdressComponentType,
  GoogleAddressComponent,
} from './../../interfaces/google/google-address-component.interface';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
declare var google;

@Component({
  selector: 'autocomplete',
  template: `
    <label
      [formGroup]="form"
      class="google-autocomplete"
      [ngClass]="{ 'google-autocomplete-disabled': disabled }"
    >
      <input
        type="text"
        [formControlName]="controlName"
        #addresstext
        [disabled]="disabled ? '' : null"
        [placeholder]="(placeholder ? placeholder : 'Protocols.addAddress') | translate"
      />
      <span></span>
    </label>
  `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() adressType: string;
  @Input() disabled: boolean = false;
  @Input() placeholder: string;
  @Input() form: FormGroup;
  @Input() controlName: string = 'address';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    if (!!this.form) return;

    this.form = this.fb.group({
      [this.controlName]: null,
    });
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement);

    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      const data = Object.assign(place, {
        custom_address: this.setAddressComponents(place),
      });
      this.invokeEvent(data);
    });
  }

  invokeEvent(place: any) {
    this.setAddress.emit(place);
    this.form?.get(this.controlName)?.setValue(place?.custom_address);
  }

  setAddressComponents(place: any) {
    let address = {
      street: '',
      streetNumber: '',
      postalCode: '',
      town: '',
      country: null,
      address: '',
    };

    place.address_components.map((component: GoogleAddressComponent) => {
      switch (component.types[0]) {
        case GoogleAdressComponentType.POSTAL_CODE:
          address.postalCode = component.long_name;
          break;
        case GoogleAdressComponentType.TOWN:
          address.town = component.long_name;
          break;
        case GoogleAdressComponentType.COUNTRY:
          address.country = countries.filter((c) => c.id === component.short_name)[0];
          break;
        case GoogleAdressComponentType.ROUTE:
          address.street = component.long_name;
          break;
        case GoogleAdressComponentType.STREET_NUMBER:
          address.streetNumber = component.long_name;
          break;
        default:
          break;
      }
    });

    address.address = !!address.street ? `${address.street} ${address.streetNumber}` : '';

    return this.setFullAddress(address);
  }

  setFullAddress(address) {
    const adressAndCode = [address?.address, address?.postalCode].filter(Boolean).join(', ');
    const townAndCountry = [address?.town, address?.country?.text].filter(Boolean).join(', ');
    return `${adressAndCode} ${townAndCountry}`;
  }
}
