import { EMailingGroup } from '@modules/mailing-templates/shared/enums/mailing-group.enum';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { TranslateService } from '@ngx-translate/core';

export const MailingPanelLinks = (
  t: TranslateService,
  queryParams: any = {},
  start_uri: string = '',
): TabLink[] => {
  const prefix_url = start_uri ?? `/panel/mailings/`;

  return [
    {
      name: `Mailings.Templates.new-contact`,
      link: `${prefix_url}${EMailingGroup.GROUP_NEW_CONTACT}`,
      iconUrl: '/assets/img/ic-user-plus.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.terms`,
      link: `${prefix_url}${EMailingGroup.GROUP_MEETING}`,
      iconUrl: '/assets/img/business-calendar-check-line.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.estimation`,
      link: `${prefix_url}${EMailingGroup.GROUP_ESTIMATION}`,
      iconUrl: '/assets/img/business-calculator-line.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.documents`,
      link: `${prefix_url}${EMailingGroup.GROUP_DOCUMENTS}`,
      iconUrl: '/assets/img/documents-folder-open-line.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.negotiations`,
      link: `${prefix_url}${EMailingGroup.GROUP_NEGOTIATIONS}`,
      iconUrl: '/assets/img/communication-feedback-line.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.realization`,
      link: `${prefix_url}${EMailingGroup.GROUP_REALIZATION}`,
      iconUrl: '/assets/img/documents-todo-line.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.realized`,
      link: `${prefix_url}${EMailingGroup.GROUP_FINISHED}`,
      iconUrl: '/assets/img/ic-check-square.svg',
      queryParams,
    },
    {
      name: `Mailings.Templates.complaint`,
      link: `${prefix_url}${EMailingGroup.GROUP_COMPLAINT}`,
      iconUrl: '/assets/img/finance-hand-coin-line.svg',
      queryParams,
    },
    // {
    //   name: `Mailings.Templates.execution-mails`,
    //   link: `${prefix_url}execution-mails`,
    //   iconUrl: '/assets/img/business-mail-send-line.svg',
    //   queryParams,
    // },
    {
      name: `Mailings.Templates.recommendation`,
      link: `${prefix_url}${EMailingGroup.GROUP_RECOMMENDATION}`,
      iconUrl: '/assets/img/business-mail-star-line.svg',
      queryParams,
    },
  ].map((i) => {
    i.name = t.instant(i.name);
    return i;
  });
};
