import { Injector, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Config } from '@shared/configs/config';
import { Currency } from '@shared/models/currency.interface';
import { Languages, LanguageService } from '@core/services/language.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { StorageService } from '@core/services/storage.service';
import { CurrencyFormat, CurrencyFormatSymbol } from '@shared/enums/curreny-format.enum';

@Pipe({ name: 'priceFormat', pure: true })
export class PriceFormatPipe implements PipeTransform {
  injector: Injector;
  storage: StorageService;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.storage = this.injector.get(StorageService);
  }

  transform(value: number, withoutSymbol: boolean = false, currency?: Currency): any {
    const val = value !== 0 ? (value * 10) / 1000 : value;
    const formatted = numberWithSpaces(
      val?.toString(),
      CurrencyFormatSymbol[this.storage?.UserPerson?.numberFormat || Config.DEFAULT_NUMBER_FORMAT],
    );
    if (withoutSymbol) {
      return `${formatted}`;
    } else {
      !currency ? (currency = Config?.currency) : '';
      const num = val;
      const symbol = currency?.symbol ? currency?.symbol : '';
      return currency?.leftSide ? `${symbol} ${formatted}` : `${formatted} ${symbol}`;
    }
  }
}

export function numberWithSpaces(
  x,
  currencyFormat: string = CurrencyFormatSymbol[Config.DEFAULT_NUMBER_FORMAT],
) {
  var parts = parseFloat(Number(x?.replace(/\s/g, '')).toString())
    .toFixed(2)
    .toString()
    .split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(currencyFormat);
}
