import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  SidenavEvent,
  SidenavEventType,
  SidenavService,
  SidenavSide,
} from '@shared/services/sidenav/sidenav.service';
import { MobileNavigationComponent } from '@shared/modules/base-panel/components/mobile-navigation/mobile-navigation.component';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'header-hamburger',
  templateUrl: './header-hamburger.component.html',
  styleUrls: ['./header-hamburger.component.scss'],
})
export class HeaderHamburgerComponent implements OnInit {
  constructor(
    // private sidenavService: SidenavService,
    private changes: ChangeDetectorRef,
    public headerService: HeaderService,
  ) {}

  ngOnInit(): void {}

  // openMenu() {
  // this.menuOpened = true;
  // this.sidenavService.open(MobileNavigationComponent, {side: SidenavSide.LEFT, closeOnBackdrop: true, maxWidth: '85%'});
  // this.changes.detectChanges();
  // this.sidenavService.emitter.subscribe((e: SidenavEvent) => {
  //   if (e.type === SidenavEventType.CLOSE) {
  //     this.menuOpened = false;
  //     this.changes.detectChanges();
  //   }
  // });
  // }
}
