<div class="task-title-holder">
  <div class="d-flex align-items-start justify-content-between">
    <span class="task-title grey-1" [title]="task?.description" [innerText]="task?.description"></span>
    <span
      *ngIf="task && !preview"
      class="back"
      [permission]="{
        group: PermissionsGroups.TASKS,
        action: 'EDIT',
        objectCreatorId: objectCreatorIds
      }"
      (click)="openEditModal()"
    >
      <svg-icon
        src="./assets/img/ic-edit-task.svg"
        [svgStyle]="{ 'width.px': 19, 'height.px': 19 }"
      ></svg-icon>
    </span>
  </div>
  <div
    class="task-attachments-holder"
    *ngIf="!!task?.imgAttachments?.length || task?.fileAttachments?.length"
  >
    <div class="mb-2" *ngIf="!!task?.imgAttachments?.length">
      <span class="subtitle bold mb-2" [innerHTML]="'Protocols.attachments' | translate"></span>
      <div class="d-flex flex-wrap">
        <ng-container *ngFor="let image of task?.imgAttachments; let i = index; trackBy: trackAttachment">
          <div class="mr-2 mb-2">
            <attachment-image [attachment]="image" [fancyboxUrl]="'acceptance-fancybox'"></attachment-image>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="task?.fileAttachments?.length">
      <attachment-file
        [file]="file"
        *ngFor="let file of task?.fileAttachments; trackBy: trackAttachment"
      ></attachment-file>
    </ng-container>
  </div>
</div>
<div class="task-content-holder">
  <div>
    <span class="subtitle bold" [innerHTML]="'Protocols.description' | translate"></span>
    <ul>
      <li class="list-item">
        <span class="bold" [innerHTML]="'Protocols.created' | translate"></span>
        <span class="text-right"> {{ task?.created | dateFormat }}</span>
      </li>
      <li class="list-item">
        <span class="bold" [innerHTML]="'Protocols.status' | translate"></span>
        <div
          class="mini-green"
          [ngClass]="{ 'mini-grey': status?.value === taskStatusList[1]?.id }"
          *ngIf="status?.value"
        >
          <ng-select
            [items]="taskStatusList"
            class="select select-default w-100"
            [readonly]="preview"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            [bindLabel]="'label'"
            [bindValue]="'id'"
            [placeholder]="'Protocols.status' | translate"
            [notFoundText]="'Protocols.notFoundText' | translate"
            [formControl]="status"
            (change)="changeStatus($event)"
            [tabIndex]="-1"
            [disabled]="!onPatchTaskPermissionCheck()"
          >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span [innerHTML]="item.label" [title]="item.label"></span>
            </ng-template>
          </ng-select>
        </div>
      </li>
      <li class="list-item" *ngIf="!!assignedUsers?.length && !task.isNote">
        <span class="bold" [innerHTML]="'Protocols.assignedTo' | translate"></span>
        <span class="text-right assigned" [innerHTML]="assignedUsers"></span>
      </li>
      <li class="list-item" *ngIf="realizationTerm && !task.isNote">
        <span class="bold" [innerHTML]="'Protocols.realizationTerm' | translate"></span>
        <span class="text-right"> {{ realizationTerm }}</span>
      </li>

      <li class="list-item align-items-center" *ngIf="task?.isCorrection">
        <span class="bold" [innerHTML]="'Protocols.discountTitle' | translate"></span>
        <span class="text-right">{{
          task?.discount ? (task?.discount | priceFormat) : ('Protocols.withoutDiscount' | translate)
        }}</span>
      </li>

      <li class="list-item align-items-center" *ngIf="task?.isChange">
        <span class="bold" [innerHTML]="'Protocols.amountOfChanges' | translate"></span>
        <price-cell [price]="task?.price" [isLocalCurrency]="true"></price-cell>
      </li>

      <li class="list-item align-items-center" *ngIf="task?.isChange">
        <span class="bold" [innerHTML]="'Protocols.additionalDays' | translate"></span>
        <day-cell [day]="task?.additionalTime"></day-cell>
      </li>

      <li class="list-item" *ngIf="!!task?.completedDate">
        <span class="bold" [innerHTML]="'Protocols.done' | translate"></span>
        <span class="text-right"> {{ task?.completedDate | dateFormat }}</span>
      </li>
    </ul>
  </div>
  <div
    class="description ql-editor"
    [ngClass]="{
      'grey-1': !!task?.content?.length,
      'grey-4': !task?.content?.length,
      'description-empty': !task?.content?.length
    }"
    [innerHTML]="task?.content ? task?.content : ('Protocols.noDescription' | translate)"
  ></div>
  <div class="form-control-checkbox" *ngIf="task && hiddenForClientShow && !preview">
    <label
      class="m-0 d-flex align-items-center"
      [ngClass]="{ 'cursor-default': !onPatchTaskPermissionCheck() }"
    >
      <input type="checkbox" [formControl]="hiddenFromClients" (click)="onPatchTaskPermissionCheck($event)" />
      <span></span>
      <span [innerHTML]="'Protocols.hiddenFromClient' | translate"></span>
    </label>
  </div>
</div>
