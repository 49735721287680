<main>
  <form [formGroup]="form" class="auth-form">
    <h1 class="blue-500 thin-title" [innerHTML]="'Auth.welcome' | translate"></h1>
    <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.data' | translate"></div>
    <div class="grey-4 grey-text" [innerHTML]="'Auth.privacy' | translate"></div>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="email-control special-control required"
      formControlName="username"
      [config]="{
        fieldName: 'username-confirm',
        name: 'Auth.Login.email' | translate,
        type: 'email',
        placeholder: '',
        spellcheck: false
      }"
    >
    </gbxsoft-input>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="password-control special-control"
      formControlName="password"
      [config]="{
        fieldName: 'password-confirm',
        name: 'Auth.Login.password' | translate,
        type: 'password',
        placeholder: ''
      }"
    >
    </gbxsoft-input>

    <div class="form-control-checkbox">
      <label class="m-0 d-flex align-items-center">
        <input type="checkbox" formControlName="remember" />
        <span></span>
        <span [innerHTML]="'Auth.Login.remember' | translate"></span>
      </label>
    </div>

    <div class="mt-4 d-flex align-items-center justify-content-between mb-5 auth-submit">
      <div class="d-none d-sm-block">
        <span class="mr-2 grey-4" [innerHTML]="'Auth.Login.noAccount' | translate"></span>
        <a
          draggable="false"
          (click)="n.navigate('register')"
          class="blue-300"
          [innerHTML]="'Auth.Login.createAccont' | translate"
        ></a>
      </div>
      <button type="submit" [disabled]="loading" (click)="submitLogin()" class="btn btn-primary ml-1">
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <img
          draggable="false"
          *ngIf="!loading"
          src="./assets/img/ic-arrow-right.svg"
          [alt]="'Auth.Login.signIn' | translate"
        />
        {{ 'Auth.Login.signIn' | translate }}
      </button>
    </div>

    <div>
      <span class="mr-2 grey-4" [innerHTML]="'Auth.Login.forget' | translate"></span>
      <a
        draggable="false"
        (click)="n.navigate('reset')"
        class="blue-300"
        [innerHTML]="'Auth.Login.reset' | translate"
      ></a>
    </div>
    <div class="d-block d-sm-none mt-2">
      <span class="mr-2 grey-4" [innerHTML]="'Auth.Login.noAccount' | translate"></span>
      <a
        draggable="false"
        (click)="n.navigate('register')"
        class="blue-300"
        [innerHTML]="'Auth.Login.createAccont' | translate"
      ></a>
    </div>
  </form>
  <div class="auth-form">
    <div class="py-4 text-center text-lg-left h4 mb-0 blue-500">{{ 'loginWith' | translate }}</div>
    <div class="d-flex justify-content-center justify-content-lg-start">
      <div #googleButton></div>
    </div>
    <div class="mt-4 grey-4">
      <b class="grey-1">Intilio's</b> use and transfer to any other app of information received from
      Google APIs will adhere to
      <a
        href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        target="_blank"
      >
        Google API Services User Data Policy,
      </a>
      including the Limited Use requirements.
    </div>
  </div>
</main>
