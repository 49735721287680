import { Injectable, TemplateRef, EventEmitter, Type } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class SidenavService {
  emitter: EventEmitter<SidenavEvent> = new EventEmitter();
  backdropFuncion: () => {};

  constructor() {}

  // you can send data through service to put it to sidenav popup component
  open<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T> | Type<T>,
    config?: SidenavConfig,
    data?: any,
  ) {
    const c: SidenavConfig = new SidenavConfig(config);
    this.emitter.emit({ component: componentOrTemplateRef, type: SidenavEventType.OPEN, config: c, data });
  }

  close() {
    this.emitter.emit({ type: SidenavEventType.CLOSE });
    this.backdropFuncion = null;
  }
}

export interface SidenavEvent {
  type: SidenavEventType;
  component?;
  config?: SidenavConfig;
  data?: any;
}

export enum SidenavEventType {
  OPEN,
  CLOSE,
}

export class SidenavConfig {
  closeOnBackdrop?: boolean = true;
  hideBackdrop?: boolean = false;
  side?: SidenavSide = SidenavSide.RIGHT;
  maxWidth?: string;
  class?: string;
  hardReload?: boolean;
  extra?: any;

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
  }
}

export enum SidenavSide {
  RIGHT = 'right',
  LEFT = 'left',
}
