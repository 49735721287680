<div class="calendar-main-section">
  <calendar-header [fullCalendar]="fullCalendarComponent"></calendar-header>
  <div class="calendar-main-section__content">
    <full-calendar [options]="calendarOptions" #fullCalendarComponent></full-calendar>
    <calendar-settings [@fadeInRight] *ngIf="service.settingsCtrl.settingsOpened"></calendar-settings>
  </div>

  <calendar-event-popover
    #popoverComponent
    [event]="openedPopover?.event"
    [parentElement]="openedPopover?.parentElement"
    (close)="openedPopover = null"
    *ngIf="openedPopover?.event && openedPopover?.parentElement"
  ></calendar-event-popover>
</div>
