export enum TaskType {
  TYPE_NOTE = 'note',
  TYPE_TASK = 'task',
  TYPE_CORRECTION = 'correction',
  TYPE_CHANGE = 'change',
  TYPE_ORDER = 'order',
  TYPE_CLIENT_TASK = 'client-task',
  TYPE_COMPANY_TASK = 'company-task',
}

export enum ObjectTaskType {
  PROTOCOL = 'protocol',
  PROJECT_BOX_1 = 'basic-box',
  PROJECT_BOX_2 = 'simple-box-2',
  PROJECT_BOX_3 = 'simple-box-3',
  BOX_TASKS = 'box-tasks',
  PLAIN = 'plain',
  PROJECT = 'project',
}
