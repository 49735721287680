import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { CalendarEventsList } from '../models/calendar-events-list.model';

export class CalendarViewController {
  loading: boolean = false; // Used to show loader on sidenav
  list: CalendarEventsList;

  constructor(public apiService: CalendarApiService) {}

  getMyWeekEvents(dates: { startDate: string; endDate: string }) {
    this.loading = true;
    this.apiService
      .getMyWeekEvents(dates)
      .subscribe({ next: (i: CalendarEventsList) => this.successGettingCalendars(i) })
      .add(() => (this.loading = false));
  }

  successGettingCalendars(i: CalendarEventsList) {
    this.list = i;
  }
}
