import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { Injectable } from '@angular/core';
import { CalendarViewController } from '../controllers/calendar-view.controller';

@Injectable({ providedIn: 'root' })
export class CalendarEventsSidenavService {
  calendarViewCtrl: CalendarViewController;

  constructor(public apiService: CalendarApiService) {
    this.calendarViewCtrl = new CalendarViewController(apiService);
  }
}
