<div class="contact-preview-subcontacts">
  <contact-preview-subcontacts-title [contact]="contact"></contact-preview-subcontacts-title>
  <contact-info-single
    (onRemove)="onRemoveSubcontact(childContact)"
    (onChangeContactPerson)="onChangeContactPerson($event)"
    (onRemoveContactPerson)="onRemoveContactPersonFromMain($event)"
    [changeContactPersonInTemplate]="false"
    [contact]="getContactWithParent(childContact)"
    [mainContact]="contact"
    *ngFor="let childContact of contact?.childContacts; trackBy: identify"
  ></contact-info-single>
  <contact-preview-subcontacts-empty-state
    [contact]="contact"
    *ngIf="!contact?.childContacts.length"
  ></contact-preview-subcontacts-empty-state>
</div>
