import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component } from '@angular/core';

@Component({
  selector: 'source-acquiring-contact',
  templateUrl: './source-acquiring-contact.component.html',
})
export class SourceAcquiringContactComponent {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  constructor() {}
}
