import { NgModule } from '@angular/core';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { NotificationsService } from './shared/services/notifications.service';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NOTIFICATIONS_COMPONENTS } from './notifications-components';
import { NotificationsApiService } from './shared/services/notifications-api.service';
import { TasksModule } from '@shared/modules/tasks/tasks.module';
import { UIModule } from '@shared/modules/ui/ui.module';
import { ProjectsModule } from '@modules/projects/projects.module';
import { NotificationEventComponent } from './shared/components/notification/types/event/notification-event/notification-event.component';

@NgModule({
  declarations: [NOTIFICATIONS_COMPONENTS, NotificationEventComponent],
  imports: [SharedModule,
    UIModule, TasksModule, ProjectsModule, InfiniteScrollModule],
  exports: [NotificationsComponent],
  providers: [NotificationsService, NotificationsApiService],
})
export class NotificationModule {}
