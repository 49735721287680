<list-chips *ngIf="chipsView"></list-chips>
<div class="new-filters-modal" [hidden]="chipsView">
  <div class="title__wrapper">
    <span class="title">{{ 'List.Filters.filters' | translate }}</span>
    <svg-icon class="clear-icon" [src]="'/assets/img/close-icon.svg'" (click)="cancel()"></svg-icon>
  </div>
  <div class="rows__wrapper">
    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'Contacts.List.function' | translate"
        [iconUrl]="'/assets/img/ic-tag.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[function-name][eq]'"
        [bindLabel]="'name'"
        [bindValue]="'name'"
        [multiple]="false"
        [translateLabelPrefix]="'Contacts.Function.'"
        (change)="onChangeFilter()"
        [requiredIdUrl]="Config.API + '/tag/type?type=contact-function'"
        (selectedValues)="handleSelectedValues($event, '[function-name][eq]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        [name]="'Contacts.Fields.contactType' | translate"
        [iconUrl]="'/assets/img/ic-user.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[a-type][eq]'"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [multiple]="false"
        (change)="onChangeFilter()"
        [localItems]="contactTypes"
        (selectedValues)="handleSelectedValues($event, '[a-type][eq]')"
      ></list-single-filter-select-v2>
    </div>

    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'Contacts.List.project' | translate"
        [iconUrl]="'/assets/img/ic-projects-outline.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[assignedProjects][]'"
        [multiple]="true"
        [valueTransform]="projectTransform.bind(this)"
        (change)="onChangeFilter()"
        [requiredIdUrl]="Config.API + '/project/search'"
        [sortByField]="'created'"
        (selectedValues)="handleSelectedValues($event, '[assignedProjects][]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        [name]="'Contacts.List.creator' | translate"
        [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[creator-id][eq]'"
        (change)="onChangeFilter()"
        [multiple]="false"
        [searchGetParams]="'search'"
        [requiredIdUrl]="Config.API + '/employee/search'"
        [sortByField]="'created'"
        [valueTransform]="creatorName.bind(this)"
        (selectedValues)="handleSelectedValues($event, '[creator-id][eq]')"
      ></list-single-filter-select-v2>
    </div>

    <list-single-filter-toggle
      [name]="'Contacts.List.showRemoved' | translate"
      [form]="service?.filtersForm"
      [controlName]="'[deleted]'"
      (change)="onChangeFilter()"
    ></list-single-filter-toggle>
  </div>

  <div class="buttons">
    <list-single-clear-filters-v2
      [form]="service?.filtersForm"
      (change)="onChangeFilter()"
      [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
      [clearFilters]="clearFilters.bind(this)"
    >
    </list-single-clear-filters-v2>
    <div class="buttons__inside">
      <app-button
        [type]="'button'"
        (onClick)="cancel()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
          text: 'DATERANGEPICKER.cancelBtn' | translate
        }"
      ></app-button>

      <app-button
        [type]="'button'"
        [disabled]="loading"
        [loading]="loading"
        (onClick)="submit()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_PRIMARY_BLUE,
          text: 'DATERANGEPICKER.acceptBtn' | translate,
          prependSrc: './assets/img/tick-icon.svg'
        }"
      ></app-button>
    </div>
  </div>
</div>
