import { CheckPermission } from '@core/permissions/check-permission';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProjectStoreService } from '../../services/project-store.service';
import { ProjectQuestionDirectoryBreadcrumbsLinks } from './project-question-directory-breadcrums-links';

@Component({
  selector: 'project-question-directory-breadcrumbs',
  templateUrl: './project-question-directory-breadcrumbs.component.html',
  styleUrls: ['./project-question-directory-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectQuestionDirectoryBreadcurmbsComponent implements OnInit {
  links: any[] = [];

  @Input() componentRefName: any;

  constructor(
    private t: TranslateService,
    public n: NavigateService,
    public projectStore: ProjectStoreService,
    public sidenav: SidenavService,
  ) {}

  ngOnInit() {
    this.setLinks();
  }

  openSideNav(link: any) {
    this.sidenav.open(link.componentRef, { class: 'scroll' });
  }

  setLinks() {
    const list = ProjectQuestionDirectoryBreadcrumbsLinks(
      this.projectStore.projectCtrl.project.projectEditIDs,
    );
    this.links = [];
    list
      .filter((i) => !!new CheckPermission(i.permissions).check())
      .map((i, index) => {
        let link = Object.assign({}, i);
        link.title = `${this.t.instant(i.title)} ${index + 1}`;
        this.links.push(link);
      });
  }
}
