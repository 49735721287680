<div class="relative d-inline-block">
  <div
    #tooltip="matTooltip"
    matTooltip="{{ 'Attachments.restore' | translate }}"
    matTooltipPosition="above"
    [matTooltipDisabled]="!attachment?.deleted || restoring"
    class="attachment__img__restore"
    *ngIf="attachment?.deleted"
  >
    <span *ngIf="edit" (click)="$event.stopPropagation(); restore()" class="attachment__img__restore-icon">
      <span
        *ngIf="restoring"
        class="spinner-border spinner-border-sm pointer"
        role="status"
        aria-hidden="true"
      ></span>
      <img
        draggable="false"
        class="pointer"
        *ngIf="!restoring"
        src="./assets/img/ic-restore.svg"
        alt="restore"
      />
    </span>
  </div>

  <div
    [ngClass]="{ 'attachment__img--deleted': !!attachment?.deleted }"
    class="attachment__img cursor-pointer d-flex align-items-center justify-content-center"
  >
    <a
      [href]="attachment?.file | authFile: null:null:!privateFile"
      [attr.data-fancybox]="fancyboxUrl"
      (click)="openImage($event)"
    >
      <img
        draggable="false"
        draggable="false"
        *ngIf="!!attachment?.file"
        class="attachment__img-file"
        [src]="attachment?.file | authFile: previewWidth:previewWidth:!privateFile"
      />
    </a>
  </div>
</div>
