import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export const HeaderConfig: { [key: string]: TabLink } = {
  myJob: {
    name: 'Header.myJob',
    link: 'my-work',
    iconUrl: '/assets/img/dashboard-icon.svg',
    permissionGroup: PermissionsGroups.MY_WORK,
    permissionAction: 'LIST',
  },
  projects: {
    name: 'Header.projects',
    link: 'projects-list',
    iconUrl: '/assets/img/project-icon.svg',
    permissionGroup: PermissionsGroups.PROJECTS,
    permissionAction: 'LIST',
  },
  calendar: {
    name: 'Header.calendar',
    link: 'calendar',
    iconUrl: '/assets/img/calendar-icon.svg',
    permissionGroup: PermissionsGroups.CALENDAR,
    permissionAction: 'PREVIEW',
  },
  contacts: {
    name: 'Header.contacts',
    link: 'contacts-list',
    iconUrl: '/assets/img/contact-icon.svg',
    permissionGroup: PermissionsGroups.CONTACTS,
    permissionAction: 'LIST',
  },
  // Removing: IS - 359,
  // protocols: {
  //   name: 'Header.protocols',
  //   link: 'protocols-list',
  //   permissionGroup: PermissionsGroups.PROTOCOLS,
  //   iconUrl: '/assets/img/ic-menu-protocol.svg',
  //   permissionAction: 'LIST',
  // },
};
