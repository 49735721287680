<div
  [formGroup]="formGroup"
  class="d-flex align-items-center justify-content-between attachment-inline attachment-inline--{{
    !!control?.value ? 'selected' : ''
  }}"
>
  <div class="d-flex align-items-center">
    <!-- #region Checkbox -->
    <div class="form-control-checkbox">
      <label class="m-0 d-flex align-items-center">
        <input [formControlName]="attachment?.id.toString()" type="checkbox" />
        <span></span>
      </label>
    </div>

    <!-- #endregion -->

    <!-- #region File -->
    <div
      *ngIf="!isImage"
      class="d-flex align-items-center pointer attachment-inline__ico__holder"
      docPreview
      [file]="!!attachment && attachment?.isUploaded ? attachment : null"
      (onLoading)="downloading = $event"
      [title]="attachment.name"
    >
      <span class="attachment-inline__ico d-flex align-items-center justify-content-center mr-2">
        <span
          *ngIf="!attachment?.isUploaded"
          style="width: 0.8rem; height: 0.8rem"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
          aria-hidden="true"
        ></span>
        <ng-container *ngIf="!!attachment?.isUploaded">
          <img draggable="false" height="24px" src="./assets/img/{{ icon }}" alt="ic-pdf" />
        </ng-container>
      </span>
      <span class="attachment-inline__title" [title]="attachment?.name">{{ attachment?.name }}</span>
    </div>
    <!-- #endregion -->

    <!-- #region Image -->
    <a
      draggable="false"
      *ngIf="isImage"
      class="d-flex align-items-center pointer attachment-inline__ico__holder"
      [href]="attachment?.file | authFile: null:null:!privateFile"
      [attr.data-fancybox]="fancyboxUrl"
      (click)="openImage($event)"
    >
      <span class="attachment-inline__ico d-flex align-items-center justify-content-center mr-2">
        <span
          *ngIf="!attachment?.isUploaded"
          style="width: 0.8rem; height: 0.8rem"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
          aria-hidden="true"
        ></span>
        <ng-container *ngIf="!!attachment?.isUploaded">
          <div
            class="attachment-inline__image"
            *ngIf="isImage"
            [style.background]="'url(' + (attachment.file | authFile) + ')'"
          ></div>
        </ng-container>
      </span>
      <span class="attachment-inline__title" [title]="attachment?.name">{{ attachment?.name }}</span>
    </a>
    <!-- #endregion -->
  </div>

  <div class="d-flex align-items-center">
    <div class="px-1 attachment-inline__created d-none d-md-block">
      {{ attachment?.created | dateTimeFormat }}
    </div>
    <div
      class="px-1 attachment-inline__creator d-none d-md-block"
      [title]="attachment?.creator?.userPerson?.previewName"
    >
      {{ attachment?.creator?.userPerson?.previewName }}
    </div>
    <div class="px-1">
      <span class="pointer attachment-inline__download d-flex align-items-center justify-content-center">
        <ng-container *ngIf="!!attachment?.isUploaded">
          <img
            draggable="false"
            class="ic-download"
            *ngIf="!downloading"
            (click)="downloadAttachment()"
            src="./assets/img/ic-download-outline-blue.svg"
            alt="download"
          />

          <span
            *ngIf="downloading"
            style="width: 0.8rem; height: 0.8rem"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
            aria-hidden="true"
          ></span>
        </ng-container>
      </span>
    </div>
  </div>
</div>
