import { getCalendarIcon } from '@shared/helpers/calendar-icon.helper';

export class CalendarEntity {
  calendarColor: string;
  created: string;
  deleted: string;
  id: number;
  isDefault: boolean;
  modified: string;
  name?: string;
  type: any;
  shareToken?: string;
  icon?: string;

  isSelected: boolean = false;

  constructor(entity?: CalendarEntity) {
    entity ? this.deserialize(entity) : null;
  }

  deserialize(entity: CalendarEntity) {
    Object.assign(this, entity);
    this.setColor();
    this.setIcon();
  }

  setSelected(value: boolean) {
    this.isSelected = value;
  }

  setColor() {
    this.calendarColor = this.calendarColor === 'ffffff' ? '187dbd' : this.calendarColor;
  }

  setIcon() {
    this.icon = getCalendarIcon(this);
  }
}
