import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeamAddEmployeeComponent } from './components/team-add-employee/team-add-employee.component';
import {
  SettingsService,
  SettingsEvent,
  SettingEventType,
} from '@modules/settings/shared/services/settings.service';
import { BaseComponent } from '@shared/components/base.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { publicFile } from '@shared/helpers/public-file.helper';
import { TeamEditEmployeeComponent } from './components/team-edit-employee/team-edit-employee.component';
import { Employee } from '@shared/models/employee.model';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
})
export class TeamSettingsComponent extends BaseComponent implements OnInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  testList: any[] = new Array(7);
  employees: Employee[] = [];
  listLoaded: boolean = false;
  companyCurrency: string;

  constructor(public dialog: MatDialog, private service: SettingsService) {
    super();
  }

  ngOnInit(): void {
    this.getEmployees();
    this.initListener();
    this.companyCurrency = this.store.Employee?.company?.currency?.symbol;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TeamAddEmployeeComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      disableClose: false,
      autoFocus: false,
    });
  }

  initListener() {
    this.sub = this.service.emitter.subscribe((e: SettingsEvent) => {
      if (e.type === SettingEventType.RELOAD_EMPLOYEES_LIST) {
        this.getEmployees();
      }
    });
  }

  getEmployees() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.service
      .getListEmployees()
      .subscribe({
        next: this.onSuccessGetEmployees.bind(this),
        error: this.onErrorGetEmployees.bind(this),
      })
      .add(() => {
        this.loading = false;
      });
  }

  onSuccessGetEmployees(employees: Employee[]) {
    this.employees = employees.map((e: Employee) => new Employee(e));
    this.listLoaded = true;
  }

  getPhoto(employee: Employee) {
    return employee.userPerson.photo ? publicFile(employee.userPerson.photo) : null;
  }

  onErrorGetEmployees(e: HttpError) {
    switch (e.messageCode) {
      default:
        this.s.error(this.t.instant('Settings.Team.Errors.errorGetEmployees'));
        break;
    }
  }

  blockEmployee(employee: Employee) {
    this.service.blockEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeBlocked'));
        employee.active = false;
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorBlockedEmployee'));
      },
    });
  }

  unblockEmployee(employee: Employee) {
    this.service.unblockEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeUnblocked'));
        employee.active = true;
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorBlockedEmployee'));
      },
    });
  }

  sendPasswordLink(employee: Employee) {
    this.service.sendPasswordLink(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.passwordLinkSent'));
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorSendPasswordLink'));
      },
    });
  }

  sendInviteAgain(employee: Employee) {
    this.service.sendInviteAgain(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.invitationSent'));
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorSendInvitationAgain'));
      },
    });
  }

  removeEmployee(employee: Employee) {
    this.service.removeEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeRemoved'));
        this.employees = this.employees.filter((e: Employee) => e.id !== employee.id);
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorRemovedEmployee'));
      },
    });
  }

  editEmployee(employee) {
    this.dialog.open(TeamEditEmployeeComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        employee,
      },
    });
  }
}
