<form [formGroup]="form">
  <ng-select
    class="new-select w-100"
    #select
    ngSelectExtension
    [changePromise]="changePromise.bind(this)"
    [initialValue]="initialValue"
    [searchGetParam]="'search'"
    [url]="URL"
    [clearAllText]="'clearAll' | translate"
    [multiple]="true"
    [loading]="loading"
    [loadingText]="'Protocols.loading' | translate"
    [placeholder]="'Protocols.assignTaskToPerson' | translate"
    [notFoundText]="'Protocols.notFoundText' | translate"
    [formControlName]="controlName"
    [ignoreLocalSearch]="true"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [tabIndex]="-1"
    [addItems]="addNewPerson"
    [readonly]="readonly"
    [addTagText]="'Protocols.add' | translate"
    [addNewItemFunction]="createNewPerson.bind(this)"
    [responseTransform]="transformEmployees.bind(this)"
    [addTag]="validateEmail.bind(this)"
  >
  </ng-select>
</form>
