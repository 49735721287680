import { Observable } from 'rxjs';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';
import { Commentary } from '../models/commentary.model';
import { Config } from '@shared/configs/config';
import { ICommentarySend } from '../interface/commentary-send.interface';

@Injectable()
export class CommentaryService extends BaseHttpService {
  constructor() {
    super();
  }

  addCommentary(data: ICommentarySend): Observable<Commentary> {
    const url = `${Config.API}/comment`;
    return this.postFile(url, data);
  }

  editCommentary(id: number, data: ICommentarySend): Observable<Commentary> {
    const url = `${Config.API}/comment/${id}`;
    return this.postFile(url, data);
  }

  deleteCommentary(id: number): Observable<Object> {
    const url = `${Config.API}/comment/${id}`;
    return this.delete(url);
  }
}
