import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Regex } from './regex';

export const ValidationErrors = {
  [GbxsoftErrorTypes.required]: 'FormErrors.required',
  [GbxsoftErrorTypes.email]: 'FormErrors.email',
  [Regex.email.toString()]: 'FormErrors.email',
  [Regex.url.toString()]: 'FormErrors.url',
  [GbxsoftErrorTypes.min]: 'FormErrors.min',
  [GbxsoftErrorTypes.max]: 'FormErrors.max',
  [GbxsoftErrorTypes.requiredTrue]: 'FormErrors.requiredTrue',
  [GbxsoftErrorTypes.minLength]: 'FormErrors.minLength',
  [GbxsoftErrorTypes.maxLength]: 'FormErrors.maxLength',
  [GbxsoftErrorTypes.pattern]: 'FormErrors.pattern',
};
