import { CheckPermission } from '@core/permissions/check-permission';
import { fadeInRightAnimation } from 'angular-animations';
import { calendarConfig } from './calendar.config';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CalendarOptions, ViewApi } from '@fullcalendar/common';
import { LanguageService } from '@core/services/language.service';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';
import { EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarEventPopoverComponent } from './components/calendar-event-popover/calendar-event-popover.component';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { IFullCalendarView } from '@modules/calendar/shared/interfaces/fullcalendar-view.interface';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { Task } from '@shared/models/task.model';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { TaskController } from '@shared/controllers/task.controller';
import { ECalendarAction } from '@modules/calendar/shared/enums/calendar-action.enum';
import { EventController } from '@shared/controllers/event.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';

@Component({
  selector: 'calendar-main-section',
  templateUrl: './calendar-main-section.component.html',
  styleUrls: ['./calendar-main-section.component.scss'],
  animations: [fadeInRightAnimation()],
})
export class CalendarMainSectionComponent implements OnInit, AfterViewInit {
  calendarOptions: CalendarOptions = calendarConfig(
    this.languageService,
    this.onEventSingleClick.bind(this),
    this.onEventDoubleClick.bind(this),
    this.onEventChange.bind(this),
  );
  openedPopover: { event: CalendarEvent; parentElement: HTMLBaseElement };

  @ViewChild('popoverComponent') popoverComponent: CalendarEventPopoverComponent;
  @ViewChild('fullCalendarComponent') fullCalendarComponent: FullCalendarComponent;

  constructor(public service: CalendarService, public languageService: LanguageService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.service.mainCtrl.registerFullCalendar(this.fullCalendarComponent);
    this.service.mainCtrl.getCalendarEvents();
  }

  private onEventChange(e: { event: EventApi; oldEvent: EventApi; relatedEvents?; revert?: Function }) {
    if (e.event?._def?.extendedProps?.calendarEvent) {
      this.updateEvent(e);
      return;
    }

    if (e.event._def.extendedProps?.task) {
      this.updateTask(e);
      return;
    }
  }

  private updateEvent(e: { event: EventApi; oldEvent: EventApi; relatedEvents?; revert?: Function }) {
    const event = new CalendarEvent(e.event?._def?.extendedProps?.calendarEvent);
    event.termEnd = this.getChangeEventNewDate(!e.event.end ? 'start' : 'end', e);
    event.termStart = this.getChangeEventNewDate('start', e);

    const ctrl = new EventController(event);
    ctrl
      .updateEvent(event)
      ?.subscribe(() =>
        this.service.subCalendarCtrl.calendarEmmiter.emit({ action: ECalendarAction.UPDATE_VIEW }),
      );
  }

  private updateTask(e: { event: EventApi; oldEvent: EventApi; relatedEvents?; revert?: Function }) {
    const task = new Task(e.event?._def?.extendedProps?.task);
    task.termStart = this.getChangeEventNewDate('start', e);
    task.termEnd = this.getChangeEventNewDate('end', e);

    const ctrl = new TaskController(task);
    ctrl
      .updateTask(task)
      ?.subscribe(() =>
        this.service.subCalendarCtrl.calendarEmmiter.emit({ action: ECalendarAction.UPDATE_VIEW }),
      );
  }

  private getChangeEventNewDate(type: string, e: { event: EventApi; oldEvent: EventApi; relatedEvents? }) {
    if (!!e?.event?.allDay && type === 'end') {
      return e.event[type]
        ? moment(e.event[type]).subtract(1, 'day').endOf('day').format(Config.DATE_SERVER)
        : moment(e.oldEvent[type]).subtract(1, 'day').endOf('day').format(Config.DATE_SERVER);
    }
    return e.event[type]
      ? moment(e.event[type]).format(Config.DATE_SERVER)
      : moment(e.oldEvent[type]).format(Config.DATE_SERVER);
  }

  canPreviewEvent() {
    const ctrl = new CheckPermission({ group: PermissionsGroups.CALENDAR, action: 'PREVIEW' });
    return ctrl.check();
  }

  canPreviewTask(task: Task) {
    const ctrl = new TaskPermissionController();
    ctrl.initPermissions(task);
    return ctrl.preview;
  }

  onEventDoubleClick(e: IFullCalendarView) {
    if (e.event._def.extendedProps?.calendarEvent) {
      const event = e.event._def.extendedProps?.calendarEvent || null;
      if (e.event._def.extendedProps?.isExternal) {
        event ? this.openEventPreview(e) : null;
        return;
      }

      if (!this.canPreviewEvent()) return;
      event ? this.openEventPreview(e) : null;
      return;
    }

    const task = e.event._def.extendedProps?.task || null;
    if (!this.canPreviewTask(task)) return;
    task ? this.openTaskPreview(e, task) : null;
  }

  onEventSingleClick(e: IFullCalendarView) {
    if (e.event._def.extendedProps?.calendarEvent) {
      if (!this.canPreviewEvent()) return;
      const event = this.getEvent(e);
      if (event?.id || event?.googleEventId) {
        this.openEventPreview(e);
        return;
      }
      this.openPopover(event, e.el as HTMLBaseElement);
      return;
    }

    const task = e.event._def.extendedProps?.task || null;
    if (!this.canPreviewTask(task)) return;
    task ? this.openTaskPreview(e, task) : null;
  }

  openEventPreview(e: IFullCalendarView) {
    const event = this.getEvent(e);
    const ctrl = new EventSidenavController();
    ctrl.openPreview(e.event._def.extendedProps?.calendarEvent);
  }

  openTaskPreview(e: IFullCalendarView, task) {
    const event = this.getTask(e);
    const ctrl = new TaskSidenavController();
    ctrl.previewTask(String(event.id));
  }

  getEvent(e): CalendarEvent {
    const def = e.event._def;
    return Object.assign(def.extendedProps.calendarEvent, {
      color: def?.ui?.backgroundColor,
    }) as CalendarEvent;
  }

  getTask(e): Task {
    const def = e.event._def;
    return new Task(def?.extendedProps?.task);
  }

  private openPopover(event: CalendarEvent, el: HTMLBaseElement) {
    this.openedPopover = {
      event: event,
      parentElement: el,
    };
  }

  private closePopover() {
    this.popoverComponent.onClose();
  }
}
