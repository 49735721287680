import { MailingSidenavModule } from './modules/mailing-templates/shared/modules/mailing-sidenav/mailing-sidenav.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { appInitializerFactory } from './initializer.factory';
import { AppInjector } from '@shared/services/app-injector.service';
import { NavigateService } from '@shared/services/navigate.service';
import { Routes } from '@shared/configs/routes';
import { SharedModule } from '@shared/shared.module';
import { NotificationModule } from '@modules/notification/notification.module';
import { HttpConfigInterceptor } from '@core/interceptors/auth.interceptor';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { TopProgressBarModule } from '@modules/top-progress-bar/top-progress-bar.module';
import { TopProgressBarService } from '@modules/top-progress-bar/shared/services/top-progress-bar.service';
import { TopProgressBarInterceptor } from '@core/interceptors/top-progressbar.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LanguageService } from '@core/services/language.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BasePanelModule } from '@shared/modules/base-panel/base-panel.module';
import { AppTourModule } from '@shared/modules/app-tour/app-tour.module';
import { TourMatMenuModule } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/md-menu.module';
import { TaskSidenavModule } from '@shared/modules/task-sidenav/task-sidenav.module';
import { QuillModule } from 'ngx-quill';
import { HttpLoaderFactory } from './http-loader.factory';
import { EventSidenavModule } from '@shared/modules/event-sidenav/event-sidenav.module';
import { ProjectCreatorEntitySidenavModule } from '@modules/project-creator/shared/modules/project-creator-entity-sidenav/project-creator-entity-sidenav.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { QuillHelper } from '@shared/helpers/quill-helper';
import { NgxCurrencyModule } from 'ngx-currency';

QuillHelper.init();

const imports = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  SharedModule.forRoot(),
  HttpClientModule,

  SortablejsModule.forRoot({ animation: 150 }),
  QuillModule.forRoot({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],
      imageResize: true,
    },
    formats: [
      'background',
      'bold',
      'color',
      'font',
      'code',
      'italic',
      'link',
      'size',
      'strike',
      'script',
      'underline',
      'blockquote',
      'header',
      'indent',
      'list',
      'align',
      'direction',
      'code-block',
      'formula',
      // 'image'
      // 'video'
    ],
  }),

  AngularSvgIconModule.forRoot(),
  TopProgressBarModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  ToastrModule.forRoot({
    maxOpened: 1,
    autoDismiss: true,
    closeButton: true,
    timeOut: 2000,
    positionClass: 'toast-bottom-left',
  }),

  NotificationModule,
  TaskSidenavModule,
  EventSidenavModule,
  MailingSidenavModule,
  ProjectCreatorEntitySidenavModule,

  RecaptchaV3Module,
  BasePanelModule,
  TourMatMenuModule.forRoot(),
  AppTourModule.forRoot(),
];

const providers = [
  //#region Sentry logs
  // {
  //   provide: ErrorHandler,
  //   useValue: Sentry.createErrorHandler({
  //     showDialog: true,
  //   }),
  // },
  // {
  //   provide: Sentry.TraceService,
  //   deps: [Router],
  // },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [TranslateService, Injector],
    multi: true,
  },
  // #endregion
  {
    provide: LOCALE_ID,
    deps: [LanguageService],
    useFactory: (lang: LanguageService) => {
      return lang.getCurrentLanguage();
    },
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TopProgressBarInterceptor,
    multi: true,
  },
  { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.CAPTCHA },
  NavigateService,
  TopProgressBarService,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    imports,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  exports: [TranslateModule],
  providers: [providers],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector, public navigate: NavigateService, private translate: TranslateService) {
    AppInjector.setInjector(injector);
    this.navigate.setAppRoutes(Routes);
  }
}
