<form [formGroup]="service.form" class="task__data">
  <div class="mb-4">
    <div class="new-select-label">{{ 'Projects.projectName' | translate }}</div>
    <project-selection
      [disabled]="!!service.task?.parent || !!service.connectedTask || !!service?.task?.isDeleted"
      [isFieldRequired]="!!service.isConfigChange"
      [requiredid]="service.projectID"
      [form]="service.form"
      [addTag]="canProjectAdd"
      [controlName]="service.TASK_SIDENAV_FORM.projectId"
      [class]="'w-100'"
    ></project-selection>
  </div>

  <ul class="task__data__selection note-type-v2 mb-4">
    <ng-container *ngFor="let item of taskList">
      <li
        class="px-3 py-2 cursor-pointer"
        [ngClass]="{
          active: isActiveTaskTypeTab(item)
        }"
        (click)="!service?.task?.isDeleted ? this.setTypeActive(item) : null"
        [innerHTML]="item.name | translate"
      ></li>
    </ng-container>
  </ul>

  <div class="mb-4">
    <div class="new-select-label">{{ 'Tasks.TaskType.' + service.FormType | translate }}</div>
    <input-v2
      #taskTitleInput
      [errMessages]="errorMessages(service.TASK_SIDENAV_FORM.description)"
      [class]="'required'"
      [disabled]="!!service?.task?.isDeleted"
      (onKeydown)="onEnter($event)"
      [formControlName]="service.TASK_SIDENAV_FORM.description"
      [placeholder]="'Projects.name' | translate"
    ></input-v2>
  </div>

  <div
    *ngIf="!!service.connectedTask"
    class="mb-4 d-flex justify-content-between align-items-center task__data-connected-task"
  >
    <span>{{ 'Projects.connectedChange' | translate }}</span>
    <span class="task__data-connected-task-title elipsis" [title]="service.connectedTask.description">
      {{ service.connectedTask.description }}
    </span>
  </div>

  <div class="relative">
    <quill-editor
      #editor
      [bounds]="'.quill-custom-img-size'"
      class="quill-v2 quill-custom-img-size"
      [modules]="modules"
      [formats]="formats"
      [readOnly]="!!service?.task?.isDeleted"
      [placeholder]="'Projects.enterDescription-' + service.FormType | translate"
      [formControlName]="service.TASK_SIDENAV_FORM.content"
    ></quill-editor>
  </div>

  <!-- <div [hidden]="!service.isCorrection" class="mt-3">
    <discount [disabled]="!!service?.task?.isDeleted" #discountTempl [interactive]="true"></discount>
  </div> -->

  <!-- #region Correction Section -->
  <div class="mt-4">
    <div class="mb-4" [hidden]="!service.isConfigChange">
      <task-selection-change-v2
        #financeTempl
        [message]="'Protocols.financeInfo' | translate"
        [items]="service.financial"
        [selectConfig]="{ placeholder: 'Protocols.selectType' | translate }"
        [title]="'Protocols.describeFinancialChange' | translate"
        [disabledInput]="isFinanceDisabled || !!service?.task?.isDeleted"
        [config]="{
          name: 'Protocols.enterChangeValue' | translate,
          type: 'price',
          appendContent: storage?.Company?.currency?.symbol
        }"
        (changeEvent)="influenceChangeEvent($event, 'financeTempl', 'currentFinanceState')"
      >
      </task-selection-change-v2>
    </div>

    <div class="mb-4" [hidden]="!service.isConfigChange">
      <task-selection-change-v2
        #daysTempl
        [message]="'Protocols.daysInfo' | translate"
        [items]="service.days"
        [selectConfig]="{ placeholder: 'Protocols.selectType' | translate }"
        [title]="'Protocols.describeTime' | translate"
        [disabledInput]="isDateDisabled || !!service?.task?.isDeleted"
        [config]="{ name: 'Protocols.enterDaysCount' | translate, type: 'number' }"
        (changeEvent)="influenceChangeEvent($event, 'daysTempl', 'currentDateState')"
      >
      </task-selection-change-v2>
    </div>

    <!-- <div class="mb-4">
      <ng-select
        class="select select-default select-with-title w-100"
        [formControlName]="service.TASK_SIDENAV_FORM.source"
        [items]="sources"
        bindLabel="name"
        bindValue="id"
        [readonly]="!!service?.task?.isDeleted"
        [notFoundText]="'Projects.notFoundText' | translate"
        [placeholder]="'Tasks.source' | translate"
      ></ng-select>
    </div> -->

    <div
      [hidden]="!service.isConfigChange"
      *ngIf="
        !(
          service?.storage?.Employee?.isClient ||
          service?.storage?.Employee?.isWorker ||
          service?.storage?.Employee?.isExternalUser
        ) && service?.isChange
      "
      class="row mb-4"
    >
      <div class="col-12 new-select-label">{{ 'ProjectChanges.List.settlementStatus' | translate }}</div>
      <ng-select
        [items]="settlementsItems"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [clearable]="false"
        [searchable]="false"
        [formControlName]="service.TASK_SIDENAV_FORM.settlementStatus"
        [tabIndex]="-1"
        [notFoundText]="'Projects.notFoundText' | translate"
        class="new-select col-6"
        [readonly]="
          service.task?.isDeleted || (!service.permissionCtrl.edit && (service.isEdit || service.isPreview))
        "
      ></ng-select>
    </div>

    <div class="mb-4">
      <task-sidenav-blue></task-sidenav-blue>
    </div>

    <!-- <div [hidden]="!service.isConfigChange">
      <ng-select
        #protocolList
        *ngIf="service.isProtocol"
        ngSelectExtension
        [formControlName]="service.TASK_SIDENAV_FORM.protocolId"
        [url]="protocolURL"
        class="select select-default w-100 ng-select--required"
        [ngClass]="{
          'ng-select--error': isControlInvalid(service.TASK_SIDENAV_FORM.protocolId)
        }"
        bindLabel="name"
        bindValue="id"
        [placeholder]="'Header.CreateOptions.protocol' | translate"
        [notFoundText]="'Projects.notFoundText' | translate"
        [responseTransform]="responseTransform.bind(this)"
        [enableSort]="false"
        [ignoreLocalSearch]="true"
        [readonly]="
          !service.form.get(this.service.TASK_SIDENAV_FORM.projectId)?.value || !!service?.task?.isDeleted
        "
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
          <span class="ng-value-label">
            <protocol-option-template [item]="item"></protocol-option-template>
          </span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <div class="ng-arrow-option ng-arrow-option--thin">
            <protocol-option-template [item]="item"></protocol-option-template>
          </div>
        </ng-template>
      </ng-select>
      <span
        *ngIf="
          isControlInvalid(service.TASK_SIDENAV_FORM.protocolId) &&
          !!service.form.get(this.service.TASK_SIDENAV_FORM.projectId)?.value
        "
        class="d-flex form-control-error-msg mt-1"
        [innerHTML]="'FormErrors.required' | translate"
      ></span>

      <span
        *ngIf="
          isControlInvalid(service.TASK_SIDENAV_FORM.protocolId) &&
          !service.form.get(this.service.TASK_SIDENAV_FORM.projectId)?.value
        "
        class="d-flex form-control-error-msg mt-1"
        [innerHTML]="'Projects.selectProjectWithProtocol' | translate"
      ></span>
    </div> -->
    <div [hidden]="!service.isConfigChange" *ngIf="!!service.isConfigChange && !storage?.Employee?.isClient">
      <div class="new-select-label">{{ 'Projects.internalNote' | translate }}</div>
      <div class="relative">
        <quill-editor
          [modules]="baseModules"
          [formats]="formats"
          [placeholder]="'Projects.enterInternalNote' | translate"
          [formControlName]="service.TASK_SIDENAV_FORM.internalNote"
          [bounds]="'.quill-custom-img-size'"
          class="quill-v2 quill-custom-img-size"
        ></quill-editor>
      </div>
    </div>
  </div>

  <!-- #endregion -->
</form>
