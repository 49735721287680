import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { DefaultListConfig } from '@shared/modules/list/configs/default-list.config';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { MyWorkListConfig } from '@modules/my-work/shared/configs/my-work-list.config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MyWorkFiltersComponent } from '@modules/my-work/pages/my-work/components/my-work-filters/my-work-filters.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ListService } from '@shared/modules/list/services/list.service';
import { Config } from '@shared/configs/config';
import { ActivatedRoute, Router } from '@angular/router';
import { ListTasksComponent } from '@shared/modules/list/components/list-tasks/list-tasks.component';
import { MyWorkService } from '@modules/my-work/shared/services/my-work.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { StorageService } from '@core/services/storage.service';
import { ButtonGroupConfig } from '@shared/modules/ui/components/button-group-v2/button-group-v2.component';

@Component({
  selector: 'my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.scss'],
})
export class MyWorkComponent implements OnInit, AfterViewInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  MyWorkFiltersComponent = MyWorkFiltersComponent;
  PermissionsGroups = PermissionsGroups;

  taskSidenavCtrl: TaskSidenavController;
  config: ListConfig;
  tabs: any[] = [];

  @ViewChild('listTasksComponent') listTasksComponent: ListTasksComponent;
  @ViewChild('selectTab') selectTab: NgSelectComponent;

  buttonGroupConfig: ButtonGroupConfig = {
    buttons: [
      {
        name: this.t.instant('MyWork.SideNavigation.myTasks'),
        prop: 'my',
        active: false,
      },
      {
        name: this.t.instant('MyWork.SideNavigation.delegatedTasks'),
        prop: 'delegated',
        active: false,
      },
    ],
  };

  constructor(
    public t: TranslateService,
    private zone: NgZone,
    private listService: ListService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private router: Router,
    public myWorkService: MyWorkService,
    private store: StorageService,
  ) {}

  ngOnInit(): void {
    this.taskSidenavCtrl = new TaskSidenavController();
    this.initListConfig();
  }

  ngAfterViewInit() {
    this.listTasksComponent.listTasksController.myWorkService = this.myWorkService;
    this.initActiveTab();
    this.listService.getRows();
  }

  createNewTask() {
    this.taskSidenavCtrl.addTask();
  }

  initListConfig() {
    this.config = cloneDeep(DefaultListConfig);
    this.config = Object.assign(this.config, MyWorkListConfig);
  }

  initActiveTab() {
    if (Number(this.route?.snapshot?.queryParams?.delegatedTasks) === 1) {
      this.buttonGroupConfig.buttons[1].active = true;
      this.selectTab.writeValue('delegated');
      this.listService.setDelegatedTasks(1);
    } else {
       this.buttonGroupConfig.buttons[0].active = true;
      this.selectTab.writeValue('my');
      this.listService.setDelegatedTasks(0);
    }
  }

  onTabClick(prop) {
    this.buttonGroupConfig.buttons.map((t) => {
      t.active = t.prop === prop;
    });

    this.selectTab.writeValue(prop);

    if (prop === 'delegated') {
      this.listService.setDelegatedTasks(1);
      this.router.navigate([], {
        queryParams: { delegatedTasks: 1 },
        queryParamsHandling: 'merge',
      });
    } else {
      this.listService.setDelegatedTasks(0);
      this.router.navigate([], {
        queryParams: { delegatedTasks: 0 },
        queryParamsHandling: 'merge',
      });
    }

    this.listService.getRows();
  }

  ngOnDestroy() {
    MyWorkListConfig.url = `${Config.API}/task-list/my-task?delegatedTasks=0`;
  }
}
