import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { ICommentaryEvent } from '@shared/modules/commentary/interface/commentary-event.interface';
import { CommentaryManageComponent } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { BaseDirectoryComponent } from './components/base-directory.component';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Config } from '@shared/configs/config';
import { Subscription } from 'rxjs';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PROJECT_DATA_FORM_BOX } from './consts/project-data-form-box';
import { ProjectAPIService } from '../../services/project-api.service';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { CommentaryType } from '@shared/modules/commentary/enums/commentary-type.enum';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import { BasicDataBox } from '../../models/project-basic-data-box.model';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';

@Component({
  selector: 'project-question-directory',
  templateUrl: './project-question-directory-first.component.html',
  styleUrls: ['./project-question-directory-first.component.scss'],
})
export class ProjectQuestionDirectoryFirstComponent
  extends BaseDirectoryComponent
  implements OnInit, OnDestroy {
  CONFIG = Config;
  CommentaryType = CommentaryType;
  PROJECT_DATA_FORM_BOX = PROJECT_DATA_FORM_BOX;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  UserRoles = UserRoles;
  searchRoles: string;

  initialPerson;

  sub: Subscription;
  isEditing: boolean = false;

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachments: 20,
    maxAttachmentFileSize: 50,
  };

  @ViewChild('comments') comments: CommentaryManageComponent;

  constructor(
    private footerService: StickyFooterService,
    public projectAPI: ProjectAPIService,
    public projectStore: ProjectStoreService,
    private active: ActivatedRoute,
  ) {
    super(projectStore);
  }

  ngOnInit(): void {
    this.isEditing = !!this.active?.snapshot.data?.edit;
    this.setSearchRoles();
    this.initialPerson = this.projectStore.projectCtrl?.project?.basicDataBox?.responsibleEmployee;
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.responsibleEmployeeId, 'initialPerson');
    this.subscribeSaving();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.projectStore.submitProject(true, false, this.isEditing);
          break;
        default:
          break;
      }
    });
  }

  setSearchRoles() {
    const params = new URLSearchParams();
    const roles = [
      UserRoles.ROLE_EMPLOYEE_ADMIN.toString(),
      UserRoles.ROLE_EMPLOYEE_MANAGER.toString(),
      UserRoles.ROLE_EMPLOYEE_WORKER.toString(),
    ];
    roles.forEach((role) => params.append('filters[a.role][in][]', role));
    this.searchRoles = params.toString();
  }

  transformEmployees(list: EmployeeSearchItem[]) {
    return list.map((i) => new EmployeeSearchItem(i).userPerson);
  }

  errorMessages() {
    const messages = Config.validationMessages;
    return messages;
  }

  commentaryEvent($event: ICommentaryEvent) {
    this.updateCommentaries($event.data);
  }

  updateCommentaries(c: Commentary) {
    const _data = this.projectStore.projectCtrl.project.basicDataBox || {};
    const basic: any = Object.assign(_data, {
      comments: this.comments.commentaries,
    });

    this.projectStore.projectCtrl.project.basicDataBox = new BasicDataBox(basic);
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
    this.projectStore.mainDirectoryFirstState = {
      formValues: this.projectStore.mainDirectoryFirstForm.value,
    };
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
