<button
  class="hamburger hamburger--spin"
  type="button"
  [ngClass]="{ 'is-active': menuOpened }"
  (click)="openMenu()"
>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
