<div class="contact-preview-projects">

  <contact-preview-projects-title [contact]="contact"
                                  (onAddProjectToContact)="onUpdateContact($event)"></contact-preview-projects-title>

  <contact-preview-projects-list
    (onUpdateContact)="onUpdateContact($event)"
    #projectList
    *ngIf="contact?.mainContactProjects.length || contact?.contactProjects.length"
    [contact]="contact"></contact-preview-projects-list>
  <contact-preview-projects-empty-state
    (onAddProjectToContact)="onUpdateContact($event)"
    [contact]="contact"
    *ngIf="!contact?.mainContactProjects.length && !contact?.contactProjects.length"></contact-preview-projects-empty-state>

  <app-button
    *ngIf="contact?.mainContactProjects.length || contact?.contactProjects.length"
    [permission]="{
        group: PermissionsGroups.PROJECTS,
        action: 'ADD'
      }"
    [class]="'m-2'"
    [loading]="loading"
    [disabled]="loading"
    (onClick)="createNewProject()"
    [config]="{
        type: ButtonTypes.PRIMARY_GREEN,
        text: 'Contacts.Preview.Projects.createNewProject' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
  >
  </app-button>

</div>

