import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'contact-manage-leave',
  templateUrl: './contact-manage-leave.component.html',
  styles: []
})
export class ContactManageLeaveComponent implements OnInit {

  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  currentRoute: ActivatedRouteSnapshot;
  leaveEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ContactManageLeaveComponent>,
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.leaveEmitter.emit(false);
    });
  }

  onCancel() {
    const currentUrlTree = this.router.createUrlTree([], this.currentRoute);
    const currentUrl = currentUrlTree.toString();
    this.location.go(currentUrl);
    this.leaveEmitter.emit(false);
    this.dialogRef.close();
  }
}
