import { tap, mergeMap, catchError } from 'rxjs/operators';
import { Subscription, Observable, throwError } from 'rxjs';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyService } from '@shared/services/company.service';
import { JWT } from '@shared/interfaces/jwt.interface';
import { HttpError } from '@shared/interfaces/error.interface';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { Employee } from '@shared/models/employee.model';
import { LanguageService } from '@core/services/language.service';
import { NotificationsService } from '@modules/notification/shared/services/notifications.service';
import { Company } from '@shared/models/company.model';
import { ActivatedRoute } from '@angular/router';
import { RedirectHelper } from '@shared/helpers/redirect-helper';
import { WindowHelper } from '@shared/helpers/window.helper';
import { HeaderService } from '@shared/modules/base-panel/components/header/services/header.service';

@Component({
  templateUrl: './company-selection.component.html',
  styleUrls: ['./company-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySelectionComponent extends BaseComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  form: FormGroup;
  companies: any = [];
  creatingCompany: boolean = false;

  constructor(
    private authService: AuthorizationService,
    private companyService: CompanyService,
    private changes: ChangeDetectorRef,
    private langService: LanguageService,
    private notificationsService: NotificationsService,
    private active: ActivatedRoute,
    private headerService: HeaderService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.setCompanies();
  }

  setCompanies() {
    this.companies = this.store?.UserPerson?.employees.filter((i) => !!i.active);
  }

  onCompanySelect(employee: Employee) {
    this.form.get('company').setValue(employee.id);
  }

  createForm() {
    this.form = new FormGroup({
      company: new FormControl(this.store?.UserPerson?.employees[0]?.id),
    });
  }

  submitSelection() {
    !this.store?.UserPerson?.employees?.length ? this.createCompany() : this.selectCompany();
  }

  createCompany() {
    if (this.loading || this.creatingCompany) {
      return;
    }
    this.creatingCompany = true;
    this.changes.detectChanges();

    this.companyService
      .createCompany()
      .pipe(
        tap(() => {
          this.s.success(this.t.instant('Auth.Messages.organizationCreated'));
          this.changes.detectChanges();
        }),
        catchError((err: HttpError) => {
          this.companyErrors(err);
          return throwError(err);
        }),
      )
      .pipe(mergeMap((c: Company) => this.selectCompanyById(c.id)))
      .subscribe()
      .add(() => {
        this.creatingCompany = false;
        this.changes.detectChanges();
      });
  }

  companyErrors(err: HttpError) {
    switch (err.messageCode) {
      case IntilioCodes.COMPANY_EXISTS:
        this.s.error(this.t.instant('Auth.Errors.alreadyOwnOrganisation'));
        break;
      default:
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        break;
    }
  }

  selectCompany() {
    if (this.form.invalid || this.loading || this.creatingCompany) {
      return;
    }
    this.loading = true;
    this.changes.detectChanges();

    this.selectCompanyById(Number(this.form.get('company').value))
      .subscribe()
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  selectCompanyById(id: number): Observable<JWT> {
    return this.authService.selectCompany(id).pipe(
      tap((jwt: JWT) => {
        this.s.success(this.t.instant('Auth.Messages.loginSelectedCompany'));
        this.store.Employee = jwt.employee;
        this.store.jwt = jwt.token;
        const redirect = this.active?.snapshot?.queryParams?.redirectTo;
        if (redirect) {
          const routeData = RedirectHelper.getRouteData(redirect);
          this.router.navigate([routeData?.link], { queryParams: routeData?.queryParams });
        } else {
          this.n.navigate(this.CONFIG.MAIN_VIEW, {}, {}, true);
        }
        WindowHelper.isMobileWidth ? this.headerService.openMenu() : null;
        this.notificationsService.startNotificationsInterval();
        this.changes.detectChanges();
      }),
      catchError((err) => {
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        return throwError(err);
      }),
    );
  }
}
