import { ChangeSummarySharedModule } from '@modules/projects/modules/change-summary-shared/change-summary-shared.module';
import { SharedModule } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { SourceAcquiringContactComponent } from './shared/components/source-acquiring-contact/source-acquiring-contact.component';
import { ProjectRightSectionComponent } from './shared/components/project-right-section/project-right-section.component';
import { ProjectRightSectionTitleComponent } from './shared/components/project-right-section/components/project-right-section-title/project-right-section-title.component';
import { ProjectMainContactModalComponent } from './shared/components/project-main-contact-modal/project-main-contact-modal.component';
import { ProjectLostStageModalComponent } from './shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { ProjectResponsibleCompanyModalComponent } from './shared/components/project-responsible-company-modal/project-responsible-company-modal.component';
import { ProjectRemoveModalComponent } from '@modules/projects/shared/components/project-remove-modal/project-remove-modal.component';
import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { TasksModule } from '@shared/modules/tasks/tasks.module';
import { CommentaryModule } from '@shared/modules/commentary/commentary.module';
import { EntryModule } from '@shared/modules/entry/entry.module';
import {
  PROJECT_CHANGES_SUMMARY_SHARED_COMPONENTS,
  PROJECT_MANAGE_SHARED_COMPONENTS,
  PROJECT_MANAGE_SHARED_COMPONENTS_EXPORT,
} from '.';
import { ProjectSendStageNotificationComponent } from './shared/components/project-send-stage-notification/project-send-stage-notification.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UIModule } from '@shared/modules/ui/ui.module';
import { ProjectInfoCreateAccountModalComponent } from './shared/components/project-info-create-account-modal/project-info-create-account-modal.component';
import { ProjectChangesService } from '@project-modules/project-changes/shared/services/project-changes.service';
import { SummaryAmountService } from '@project-modules/project-changes/shared/services/summary-amount.service';
import { MailingsModule } from '@shared/modules/mailings/mailings.module';
import { ProjectStarEndTermModalComponent } from './shared/components/project-star-end-term-modal/project-star-end-term-modal.component';

@NgModule({
  imports: [
    SharedModule,
    UIModule,
    CoreModule,
    AngularEditorModule,
    TranslateModule,
    TasksModule,
    CommentaryModule,
    EntryModule,
    MailingsModule,
    ChangeSummarySharedModule,
  ],
  declarations: [
    SourceAcquiringContactComponent,
    ProjectRightSectionComponent,
    ProjectRightSectionTitleComponent,
    ProjectMainContactModalComponent,
    ProjectLostStageModalComponent,
    ProjectResponsibleCompanyModalComponent,
    ProjectRemoveModalComponent,
    ProjectSendStageNotificationComponent,
    ProjectInfoCreateAccountModalComponent,
    PROJECT_CHANGES_SUMMARY_SHARED_COMPONENTS,
    PROJECT_MANAGE_SHARED_COMPONENTS,
    ProjectStarEndTermModalComponent,
  ],
  providers: [ProjectChangesService, SummaryAmountService],
  exports: [
    ProjectRightSectionComponent,
    ProjectRightSectionTitleComponent,
    ProjectLostStageModalComponent,
    ProjectRemoveModalComponent,
    PROJECT_CHANGES_SUMMARY_SHARED_COMPONENTS,
    PROJECT_MANAGE_SHARED_COMPONENTS_EXPORT,
  ],
})
export class ProjectsModule {}
