import { Subscription } from 'rxjs';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';

export class BaseDirectoryComponent {
  subSaving: Subscription;
  constructor(public projectStore: ProjectStoreService) {}

  setItemValueFromStore(formKey: string, key: string) {
    if (!this.projectStore.mainDirectoryFirstForm.get(formKey)) return;
    const value = this.projectStore.mainDirectoryFirstForm.get(formKey).value;
    if (!!value?.length) {
      this[key] = { name: value };
    }
  }
}
