<div class="tabs">

  <div class="tabs__content d-flex justify-content-between justify-content-md-start align-items-center w-100 w-sm-auto">
    <h1 class="tabs__title" [innerHTML]="title" #titleEl></h1>
    <app-button
      [class]="'ml-3'"
      (onClick)="onButtonClick.emit(true)"
      [permission]="permissionDecorator"
      [config]="actionButtonConfig ? actionButtonConfig : {
        type: ButtonTypes.PRIMARY_GREEN,
        text: buttonName,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL,
        prependSrc: prependSrc
      }">
    </app-button>
  </div>
  <button-group [config]="buttonsGroupConfig" (onClick)="onButtonClick.emit($event)"></button-group>

</div>
