<div class="contact-option-multiple-template">
  <span class="d-flex">
    <div [title]="contact?.fullName" class="contact-option-template__name">
      <span>{{ contact?.fullName }}</span>
    </div>
    <svg-icon
      [src]="'/assets/img/ic-close.svg'"
      (click)="onClear()"
      [svgStyle]="{ width: '14px', height: '14px' }"
    ></svg-icon>
  </span>
</div>
