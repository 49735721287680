<label class="d-flex align-items-center m-0 assignment" [ngClass]="{ disabled: disabled }">
  <img draggable="false" width="17px" class="mr-1" src="assets/img/ic-person-circle-outline.svg" alt="Person" />
  <ng-select
    ngSelectExtension
    #assingSelect
    [url]="URL"
    class="mini-select"
    [clearable]="false"
    [formControl]="control"
    [loading]="loading"
    [searchGetParam]="'search'"
    [bindValue]="'helperID'"
    [bindLabel]="'previewName'"
    [tabIndex]="-1"
    [addTag]="true"
    [addTag]="validateEmail.bind(this)"
    (change)="changeValue($event)"
    [addTagText]="'Protocols.add' | translate"
    [responseTransform]="transformEmployees.bind(this)"
    [loadingText]="'Protocols.loading' | translate"
    [notFoundText]="'Protocols.notFoundText' | translate"
    [placeholder]="'Protocols.assingTo' | translate"
    [ignoreLocalSearch]="true"
  >
  </ng-select>
</label>
