import { ProjectQuestionDirectoryFirstSidenavComponent } from '@modules/projects/shared/components/project-question-directory-first-sidenav/project-question-directory-first-sidenav.component';
import { ProjectQuestionDirectorySecondSidenavComponent } from '@modules/projects/shared/components/project-question-directory-second-sidenav/project-question-directory-second-sidenav.component';
import { ProjectQuestionDirectoryThirdSidenavComponent } from '@modules/projects/shared/components/project-question-directory-third-sidenav/project-question-directory-third-sidenav.component';
import { ProtocolStuffsSideComponent } from '@modules/protocols/shared/components/protocol-stuffs-side/protocol-stuffs-side.component';
import { CalendarEventsSidenavComponent } from '@modules/calendar/modules/calendar-events-sidenav/components/calendar-events-sidenav/calendar-events-sidenav.component';
import { TaskSidenavComponent } from '@shared/modules/task-sidenav/components/task-sidenav/task-sidenav.component';
import { EventSidenavComponent } from '@shared/modules/event-sidenav/components/event-sidenav/event-sidenav.component';
import { MailingSidenavComponent } from '@modules/mailing-templates/shared/modules/mailing-sidenav/components/mailing-sidenav/mailing-sidenav.component';
import { ProjectCreatorEntitySidenavComponent } from '@modules/project-creator/shared/modules/project-creator-entity-sidenav/components/project-creator-entity-sidenav/project-creator-entity-sidenav.component';

export const SidenavRouteComponents = {
  'project-creator-entity': ProjectCreatorEntitySidenavComponent,
  'protocol-stuffs': ProtocolStuffsSideComponent,
  'week-events': CalendarEventsSidenavComponent,
  task: TaskSidenavComponent,
  event: EventSidenavComponent,
  mailing: MailingSidenavComponent,
  'project-directory-first': ProjectQuestionDirectoryFirstSidenavComponent,
  'project-directory-second': ProjectQuestionDirectorySecondSidenavComponent,
  'project-directory-third': ProjectQuestionDirectoryThirdSidenavComponent,
};
