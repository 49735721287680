import { Component, Input, OnInit } from '@angular/core';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'notification-date-separator',
  templateUrl: './notification-date-separator.component.html',
  styles: [
  ]
})
export class NotificationDateSeparatorComponent implements OnInit {

  @Input() notifications: Notification<any>[] = [];
  @Input() index: number;

  constructor() { }

  ngOnInit(): void {
  }

}
