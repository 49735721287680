import { QuillModule } from 'ngx-quill';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { ConfirmationPopoverModule } from '@shared/libs/angular-confirmation-directive/confirmation-popover.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppTourModule } from '@shared/modules/app-tour/app-tour.module';
import { MatCardModule } from '@angular/material/card';
import { TourMatMenuModule } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/md-menu.module';
import { MatButtonModule } from '@angular/material/button';
import { AttachmentsModule } from '@shared/modules/attachments/attachments.module';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';

export const SHARED_MODULES = [
  CommonModule,
  TranslateModule,
  RouterModule,
  CoreModule,
  QuillModule,
  MatMenuModule,
  MatTabsModule,
  MatIconModule,
  MatDialogModule,
  MatSidenavModule,
  MatBadgeModule,
  MatCardModule,
  MatButtonModule,
  MatDatepickerModule,
  ConfirmationPopoverModule,
  AngularSvgIconModule,
  AttachmentsModule,
  TourMatMenuModule,
  AppTourModule,
  NgxPrettyCheckboxModule,
];

export const SHARED_MODULES_FOR_ROOT = [NgCircleProgressModule.forRoot()];
