import { ITaskStatus } from './../interfaces/task-status-list.interface';
import { TaskStatus } from '@shared/enums/task-status.enum';

export const TaskStatusList: ITaskStatus[] = [
  {
    id: TaskStatus.CREATED,
    label: `Projects.TaskStatus.${TaskStatus.CREATED}`,
  },
  {
    id: TaskStatus.COMPLETED,
    label: `Projects.TaskStatus.${TaskStatus.COMPLETED}`,
  },
  {
    id: TaskStatus.CANCELLED,
    label: `Projects.TaskStatus.${TaskStatus.CANCELLED}`,
  },
];
