import { Contact } from '@modules/contacts/shared/models/contact.model';
import { FormGroup } from '@angular/forms';
import { Component, Input, EventEmitter, ViewChild } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AssignedContactList } from '@shared/models/assigned-contact.model';

@Component({
  selector: 'employee-search',
  templateUrl: './employee-search.component.html',
})
export class EmployeeSearchComponent {
  @Input() controlName: string = 'assignedToIds';
  @Input() initialValue: any[] = [];
  @Input() form: FormGroup;
  @Input() projectId: string = null;
  @Input() requiredid: string = null;
  @Input() readonly: boolean = false;

  @ViewChild('select') select: NgSelectComponent;

  addNewPerson: EventEmitter<any> = new EventEmitter();

  list: AssignedContactList = new AssignedContactList();

  loading: boolean = false;

  get queryParams() {
    return `?${!!this.projectId ? 'projectId=' + this.projectId : ''}`;
  }

  get URL() {
    return `${Config.API}/contact/task-assign${this.queryParams}`;
  }

  get assignedEmails() {
    const items =
      this.select?.selectedItems
        .filter((i) => !!(i.value as any)?.isRecentlyCreated)
        .map((i) => (i.value as any)?.email) || [];
    return items;
  }

  get assignedIds() {
    const items =
      this.select?.selectedItems
        .filter((i) => !(i.value as any)?.isRecentlyCreated)
        .map((i) => (i.value as any)?.id) || [];
    return items;
  }

  get formData() {
    return { assignedToIds: this.assignedIds, assignedEmails: this.assignedEmails };
  }

  constructor(private s: SnackBarService, private t: TranslateService) {}

  createNewPerson(email: string, items: Array<any>): void {
    const user = this.list.createCustomTag(email);
    this.addNewPerson.emit([user]);
  }

  changePromise($event) {
    const contact = $event?.value?.object;
    if (!contact?.employee) {
      return this.delegatePromise();
    }
    return true;
  }

  addTagPromise(email: string) {
    return this.delegatePromise();
  }

  delegatePromise() {
    return new Promise((resolve, reject) => {
      const accepted = confirm(this.t.instant('Mailings.Templates.taskDelegation'));
      accepted ? resolve(true) : reject('Cannot delegate task!');
    });
  }

  async validateEmail(email: string) {
    if (!!email?.length && !Regex.email.test(email.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }

    if (this.addTagPromise) {
      const canAdd = await this.addTagPromise(email);
      canAdd ? this.list.createCustomTag(email) : false;
    }
    return this.list.createCustomTag(email);
  }

  transformEmployees(list: Contact[]) {
    this.list.updateList(list);
    return this.list.list;
  }
}
