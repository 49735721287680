import { delay, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { BaseProjectCreator } from './base-project-creator.controller';
import { EProjectCreatorSecondStepForm } from '../enums/project-creator-second-step.enum';

export class ProjectCreatorFifthStepController extends BaseProjectCreator {
  get defaultFormValues() {
    return {};
  }

  constructor(private fb: FormBuilder, private api: ProjectCreatorApiService, private internal: boolean) {
    super();
  }

  submit(token: string, step?: number): any {
    return of(null).pipe(switchMap(() => this.api.getFullProjectData(token)));
  }

  submitInternal(id: number, step?: number): any {
    return of(null).pipe(switchMap(() => this.api.getProjectById(id)));
  }
}
