import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sidenav-loading',
  templateUrl: './sidenav-loading.component.html',
})
export class SidenavLoadingComponent implements OnInit {
  @Input() cover: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
