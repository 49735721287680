import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {WindowHelper} from '@shared/helpers/window.helper';

@Component({
  selector: 'content-action-title',
  templateUrl: './content-action-title.component.html',
  styles: [
  ]
})
export class ContentActionTitleComponent implements OnInit {
  WindowHelper = WindowHelper;

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() noDivider: boolean = false;
  @Input() rightSideTemplate?: TemplateRef<any>;
  @Input() titleTemplate?: TemplateRef<any>;
  @Input() subTitleTemplate?: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
