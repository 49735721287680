import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { CHIPS_VIEW, ListService } from '@shared/modules/list/services/list.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { Employee } from '@shared/models/employee.model';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'contacts-filters',
  templateUrl: './contacts-filters.component.html',
})
export class ContactsFiltersComponent extends BaseListFiltersComponentComponent implements OnInit, OnDestroy {
  Config = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  contactTypes;

  constructor(
    public service: ListService,
    public t: TranslateService,
    @Optional() private dialogRef: MatDialogRef<ContactsFiltersComponent>,
    @Optional() @Inject(CHIPS_VIEW) public chipsView: boolean,
  ) {
    super(service);
  }

  ngOnInit() {
    this.createForm();
    this.setContactTypes();

    if (!this.chipsView) {
      this.service.readUrlParams(false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setContactTypes() {
    this.contactTypes = Object.values(ContactType).map((type) => {
      return {
        id: type,
        name: this.t.instant('Contacts.Type.' + type),
      };
    });
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[a-type][eq]': new FormControl(null),
      '[function-name][eq]': new FormControl(null),
      '[assignedProjects][]': new FormControl(null),
      '[creator-id][eq]': new FormControl(null),
      '[deleted]': new FormControl(null),
    });
  }

  projectTransform(project: Project) {
    return project.name;
  }

  creatorName(employee: Employee) {
    const e = new Employee(employee);
    return e.userPerson.previewName;
  }

  cancel() {
    this.dialogRef.close();
    this.refreshChips();
  }

  submit() {
    this.service.getRows();
    this.dialogRef.close();
    this.refreshChips();
  }
}
