import { ProtocolsStatuses } from '../enums/protocols-statuses.enum';
import { Task } from '@shared/models/task.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { AcceptanceData } from '../interfaces/acceptance-data.interface';
import { Company } from '@shared/models/company.model';
import { ProjectData } from '../interfaces/project-data.interface';
import { MeetingData } from '../interfaces/meeting-data.interface';
import { Employee } from '@shared/models/employee.model';
import { Acceptance } from '../interfaces/acceptance.interface';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';

export class Protocol {
  deleted?: string;
  created: string;
  company?: Company;
  modified: string;
  id: number;
  project?: Project;
  weather?: string;

  externalCompaniesContacts: Contact[] = []; // Used to Get Data
  externalClients: Contact[] = []; // Used to Get Data
  externalClientsIds?: number[] = []; // Used to Send Data
  externalCompaniesContactsIds?: number[] = []; // Used to Send Data

  idNumber?: number;
  tasks: Task[] = [];
  creator: Employee;
  lastAcceptance: Acceptance;
  sentAcceptances?: Acceptance[] = [];
  status: ProtocolsStatuses;
  type: string;
  acceptanceData?: AcceptanceData;
  acceptanceNote?: string;
  projectProtocolData?: ProjectData;
  meetingProtocolData?: MeetingData;
  clientSignature?: string;
  companySignature?: string;
  isAcceptanceSent?: boolean;
  isAcceptanceRecord?: boolean;
  taskAssignedIds?: number[] = [];

  get isDraft() {
    return this.status === ProtocolsStatuses.STATUS_DRAFT;
  }

  get isModified() {
    return this.status === ProtocolsStatuses.STATUS_MODIFIED;
  }

  get isChanged() {
    return !!this.isAcceptanceSent && this.status === ProtocolsStatuses.STATUS_CREATED;
  }

  constructor(data?: any) {
    this.deserialize(data);
  }

  deserialize(data?: any) {
    data ? Object.assign(this, data) : null;

    this.setCreator();
    this.setProject();
    this.setCompany();
    this.fillContacts();
    this.setTaskAssignedIds();
  }

  setCreator() {
    this.creator ? (this.creator = new Employee(this.creator)) : null;
  }

  setProject() {
    this.project ? (this.project = new Project(this.project)) : null;
  }

  setCompany() {
    this.company ? (this.company = new Company(this.company)) : null;
  }

  setTaskAssignedIds() {
    this.taskAssignedIds = [];
    this.tasks ? this.tasks.map((t: Task) => {
      t.assignedTo.map((e: EmployeeSearchItem) => {
        this.taskAssignedIds.push(e.id);
      });
    }) : '';
  }

  fillContacts() {
    this.externalClients ? (this.externalClients = this.externalClients?.map((i) => new Contact(i))) : null;
    this.externalCompaniesContacts
      ? (this.externalCompaniesContacts = this.externalCompaniesContacts?.map((i) => new Contact(i)))
      : null;
  }
}
