import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language.service';
declare var google: any;

export interface IGoogleUser {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
}

export enum GoogleLocale {
  'pl' = 'pl_PL',
  'en' = 'en_EN',
  'de' = 'de_DE',
}

@Injectable({ providedIn: 'root' })
export class OAuthService {
  loaded: boolean = false;
  constructor(private lang: LanguageService) {}

  initializeGoogleOAuth(callback: any) {
    if (this.loaded) return;
    google.accounts.id.initialize({
      client_id: environment.GOOOGLE_CLIENT_ID,
      callback: (response: IGoogleUser) => callback(response),
    });
    this.loaded = true;
  }

  renderGoogleButton(element: any) {
    console.log(GoogleLocale[this.lang.getCurrentLanguage()]);
    google.accounts.id.renderButton(element, {
      size: 'large',
      shape: 'pill',
      locale: 'en',
    });
  }
}
