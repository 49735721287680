<modal-title [title]="'Projects.PreviewHeader.setTermStartEnd' | translate"></modal-title>

<form [formGroup]="form">
  <mat-dialog-content>
    <div class="mt-3">
      <daterangepicker
        #datepicker
        [interactive]="true"
        [isCalendarTime]="true"
        (rangeChanged)="rangeChanged($event)"
      ></daterangepicker>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">
      <!-- #endregion -->
      <app-button
        [disabled]="loading"
        (onClick)="cancel()"
        [config]="{
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Projects.PreviewHeader.cancel' | translate,
          size: ButtonSize.LARGE
        }"
      >
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Projects.PreviewHeader.save' | translate,
          size: ButtonSize.LARGE,
          prependSrc: './assets/img/ic-save.svg'
        }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
