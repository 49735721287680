import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProjectCreatorApiService } from '@modules/project-creator/shared/services/project-creator-api.service';
import { NavigateService } from '@shared/services/navigate.service';
@Component({
  template: '<div>Middleware token: <b>{{active.snapshot.params.token}}</b></div>',
})
export class ProjectCreatorMiddleware implements OnInit {
  constructor(
    public active: ActivatedRoute,
    private api: ProjectCreatorApiService,
    private n: NavigateService,
  ) {
    console.log(this.active);
  }

  ngOnInit(): void {}
}
