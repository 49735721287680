<modal-title [title]="'Tasks.TaskType.' + task?.type | translate" *ngIf="task"></modal-title>
<modal-title [title]="" *ngIf="!task"></modal-title>

<mat-dialog-content>
  <!-- #region Modal Main Section -->
  <task-modal-content-preview
    [snackBottom]="data.snackBottom"
    [hiddenForClientShow]="data.hiddenForClientShow"
    (taskChanged)="taskChanged($event)"
    [task]="task"
    [preview]="data.preview"
    #modalContentPreview
  ></task-modal-content-preview>
  <!-- #endregion -->
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- #region Interaction Buttons -->
  <div class="d-flex justify-content-end">
    <button
      (click)="discardModal()"
      class="btn btn-cancel"
      [innerHTML]="'Protocols.close' | translate"
    ></button>
  </div>
  <!-- #endregion -->
</mat-dialog-actions>
