import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'label-info',
  templateUrl: './label-info.component.html',
  styleUrls: ['./label-info.component.scss']
})
export class LabelInfoComponent implements OnInit {

  @Input() title: string;
  @Input() value?: string;
  @Input() valueClass: string;
  @Input() titleClass: string;
  @Input() class?: string;
  @Input() noTitle: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
