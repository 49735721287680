import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProtocolsStatuses } from '@modules/protocols/shared/enums/protocols-statuses.enum';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { BaseComponent } from '@shared/components/base.component';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { ProtocolAcceptanceType } from '@modules/protocols/shared/components/protocol-acceptance/protocol-acceptance.component';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProtocolStuffsSideComponent } from '@modules/protocols/shared/components/protocol-stuffs-side/protocol-stuffs-side.component';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProtocolListRecord } from '@modules/protocols/shared/interfaces/protocol-list-record.interface';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'protocol-toggle-menu-cell',
  templateUrl: './protocol-toggle-menu-cell.component.html',
  styleUrls: ['./protocol-toggle-menu-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolToggleMenuCellComponent extends BaseComponent implements ListCustomCell, AfterViewInit {
  ProtocolsStatuses = ProtocolsStatuses;

  @ViewChild('template') template;
  @ViewChild('downloadMenu') downloadMenu: MatMenuTrigger;

  row: ProtocolListRecord;

  constructor(
    public pService: ProtocolApiService,
    public dialog: MatDialog,
    public changes: ChangeDetectorRef,
    private sidenav: SidenavService,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.changes.detectChanges();
  }

  openEdit() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.edit();
  }

  openSidenav() {
    this.sidenav.open(ProtocolStuffsSideComponent, {}, { protocolId: this.row?.protocol.id });
  }

  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const protocolController = new ProtocolController(this.pService, this.row?.protocol);
        protocolController.remove();
      })
      .subscribe(() => {});
  }

  download() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.download(false, this.employee);
  }

  downloadVersion() {
    const protocolController = new ProtocolController(
      this.pService,
      this.row?.protocol as unknown as Protocol,
    );
    protocolController.downloadAsClient(this.row?.protocol.id);
  }

  get isOwnerNotFilledFullData() {
    if (!this.downloadMenu || !this.downloadMenu.menuOpen) {
      return;
    }
    return this.store.Employee.isOwnerNotFilledFullData(this.t);
  }

  send(): void {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.send(false);
  }

  preview() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    this.row?.expanded
      ? protocolController.previewAcceptance(this.row?.protocol)
      : protocolController.preview(this.employee);
  }

  previewPDF() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    this.row?.expanded
      ? protocolController.previewAcceptance(this.row?.protocol)
      : protocolController.preview(this.employee, null, true);
  }

  signProtocol() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.signProtocol();
  }

  accept() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.acceptance(ProtocolAcceptanceType.ACCEPT);
  }

  decline() {
    const protocolController = new ProtocolController(this.pService, this.row?.protocol);
    protocolController.acceptance(ProtocolAcceptanceType.DECLINE);
  }
}
