import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';

@Component({
  selector: 'action-title-header',
  templateUrl: './action-title-header.component.html',
  styleUrls: ['./action-title-header.component.scss']
})
export class ActionTitleHeaderComponent implements OnInit, AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @Input() title: string;
  @Input() permissionDecorator: PermissionDecorator;
  @Input() buttonText: string;
  @Input() prependSrc?: string = './assets/img/ic-plus-add.svg';
  @Input() sticky?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() doubleHeightOnMobile: boolean = false;
  @Input() stickyWithSideNavigation?: boolean = false;
  @Output('onAction') onAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('header') header: ElementRef;

  constructor(
    private basePanelService: BasePanelService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.header.nativeElement.style.right = this.basePanelService.scrollbarWidth + 'px';
    });
  }
}
