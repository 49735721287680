<div class="subtasks-list">
  <ng-container *ngFor="let task of service.taskList; trackBy: trackBy">
    <list-tasks-single-task
      *ngIf="!task.deleted"
      (onChangeParentTask)="onChangeParentTask($event)"
      [parentTask]="service?.parentTask"
      [task]="task"
    ></list-tasks-single-task>
  </ng-container>
</div>
