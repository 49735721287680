<div class="subtask-list">
  <div class="subtask-list__header">
    <span class="subtask-list__header-title">{{ 'Tasks.subtasks' | translate }}</span>
    <app-button
      *ngIf="!addingTask && !disabled"
      class="text-uppercase"
      (click)="addTask()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'Tasks.addSubtask' | translate,
        prependSrc: './assets/img/ic-plus-add-blue.svg'
      }"
    ></app-button>
  </div>

  <div class="subtask-list__list">
    <!-- #region Create task -->
    <subtask-create *ngIf="addingTask && !disabled" (discard)="discardAdding()"></subtask-create>
    <!-- #endregion -->

    <!-- #region Task List -->
    <subtasks-list></subtasks-list>
    <!-- #endregion -->
  </div>
</div>
