<label
  *ngIf="!interactive"
  class="d-flex align-items-center m-0 fake-select cursor-pointer"
  [ngClass]="{ disabled: disabled }"
  [matMenuTriggerFor]="discountMenu"
>
  <img draggable="false" width="17px" class="mr-1" src="assets/img/ic-wallet.svg" alt="Wallet" />
  <span class="fake-select-title" [innerHTML]="'Protocols.discountTitle' | translate"> </span>
  <span class="ml-1">
    <img draggable="false" src="/assets/img/arrow-down-grey.svg" alt="arrow" />
  </span>
</label>
<mat-menu #discountMenu="matMenu" xPosition="after">
  <div class="discount-popover" (click)="$event.stopPropagation()">
    <div class="mb-1">
      <h3 [innerHTML]="'Protocols.discountTitle' | translate"></h3>
    </div>
    <div class="mb-1 relative">
      <gbxsoft-input
        [formControl]="discount"
        class="standard"
        [errMessages]="errorMessages()"
        [config]="{
          name: ' ',
          placeholder: 'Protocols.discountQt' | translate,
          showPlaceholderOnFocus: true,
          type: 'number',
          appendContent: currencySymbol
        }"
      >
      </gbxsoft-input>
    </div>
    <div>
      <p
        class="discount-popover-description grey-4"
        [innerHTML]="'Protocols.discountDescr' | translate"
        class="mb-2"
      ></p>
    </div>
    <div>
      <button
        (click)="submitDiscount()"
        class="btn btn-primary"
        [innerHTML]="'Protocols.accept' | translate"
      ></button>
    </div>
  </div>
</mat-menu>

<div *ngIf="interactive" class="discount-popover p-0">
  <div class="p-0 d-flex align-items-center justify-content-between mb-1">
    <h3 class="mb-0" [innerHTML]="'Protocols.discountTitle' | translate"></h3>
    <help-icon [message]="'Protocols.discountDescr' | translate"></help-icon>
  </div>
  <div class="mb-2 relative">
    <gbxsoft-input
      [formControl]="discount"
      class="standard"
      [errMessages]="errorMessages()"
      [config]="{
        name: ' ',
        placeholder: 'Protocols.discountQt' | translate,
        showPlaceholderOnFocus: true,
        type: 'number',
        appendContent: currencySymbol
      }"
    >
    </gbxsoft-input>
  </div>
</div>
