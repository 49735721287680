import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';

@Component({
    selector: 'list-tree-action',
    templateUrl: './list-tree-action.component.html',
    styleUrls: ['./list-tree-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTreeActionComponent implements OnInit {
    TreeStatusEnum = TreeStatusEnum;

    @Input() row;
    @Input() treeDisabled: Function;
    @Input() tourAnchorName: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
