<main>
  <form [formGroup]="ctrl.form" class="auth-form">
    <h1 class="blue-500 thin-title" [innerHTML]="'Auth.Register.create' | translate"></h1>
    <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.Register.organize' | translate"></div>
    <div class="grey-4 grey-text" [innerHTML]="'Auth.privacy' | translate"></div>
    <gbxsoft-input
      [errMessages]="ctrl.errorMessages(ctrl.REGISTER_FORM.email)"
      class="email-control special-control required"
      [formControlName]="ctrl.REGISTER_FORM.email"
      [config]="{ name: 'Auth.Login.email' | translate, type: 'email', placeholder: '' }"
    >
    </gbxsoft-input>
    <gbxsoft-input
      [errMessages]="ctrl.errorMessages(ctrl.REGISTER_FORM.password)"
      class="password-control special-control required"
      [formControlName]="ctrl.REGISTER_FORM.password"
      [config]="{ name: 'Auth.Login.password' | translate, type: 'password', placeholder: '' }"
    >
    </gbxsoft-input>

    <div class="d-flex align-items-center justify-content-between mb-5 mt-4 auth-submit">
      <div class="d-none d-sm-block">
        <span class="mr-2 grey-4" [innerHTML]="'Auth.Register.exist' | translate"></span>
        <a
          draggable="false"
          (click)="n.navigate('login')"
          class="blue-300 d-block"
          [innerHTML]="'Auth.Register.login' | translate"
          [title]="'Auth.Register.login' | translate"
        ></a>
      </div>
      <button
        type="submit"
        (click)="ctrl.submitSignUp()"
        [disabled]="ctrl.loading"
        class="btn btn-primary ml-1"
      >
        <span
          *ngIf="ctrl.loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <img
          draggable="false"
          *ngIf="!ctrl.loading"
          src="./assets/img/ic-arrow-right.svg"
          [alt]="'Auth.Login.createAccount' | translate"
        />
        {{ 'Auth.Register.createAccount' | translate }}
      </button>
    </div>

    <div class="auth-form pb-4">
      <div class="py-4 text-center text-lg-left h4 mb-0 blue-500">{{ 'loginWith' | translate }}</div>
      <div class="d-flex justify-content-center justify-content-lg-start">
        <div #googleButton></div>
      </div>
      <div class="mt-4 grey-4">
        <b class="grey-1">Intilio's</b> use and transfer to any other app of information received from Google APIs will adhere to
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
        >
          Google API Services User Data Policy,
        </a>
        including the Limited Use requirements.
      </div>
    </div>

    <div>
      <div class="grey-1 mb-1" [innerHTML]="'Auth.Register.acceptance' | translate"></div>
      <a
        draggable="false"
        class="blue-300 mb-1"
        [href]="'/assets/pdf/regulations.pdf'"
        target="_blank"
        [innerHTML]="'Auth.Register.regulations' | translate"
      ></a>
      <br />
      <a
        draggable="false"
        class="blue-300"
        [href]="'/assets/pdf/privacy.pdf'"
        target="_blank"
        [innerHTML]="'Auth.Register.privacy' | translate"
      ></a>
    </div>

    <div class="d-block d-sm-none mt-3">
      <span class="mr-2 grey-4" [innerHTML]="'Auth.Register.exist' | translate"></span>
      <a
        draggable="false"
        (click)="n.navigate('login')"
        class="blue-300"
        [innerHTML]="'Auth.Register.login' | translate"
        [title]="'Auth.Register.login' | translate"
      ></a>
    </div>
  </form>
</main>
