import { downloadFile } from '@shared/helpers/download-file.helper';
import { FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { privateFile } from '@shared/helpers/private-file.helper';
import { AttachmentsManageService } from '../../services/attachments-manage.service';
import { extensionIconV2 } from '@shared/helpers/extension-icon.helper';
import { Subscription } from 'rxjs';
@Component({
  selector: 'attachment-card-v2',
  styleUrls: ['./attachment-card-v2.component.scss'],
  templateUrl: './attachment-card-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentCardV2Component implements OnInit, OnDestroy {
  _attachment: Attachment = null;
  isImage: boolean = false;
  icon: string = 'ic-pdf.svg';

  sub: Subscription;

  @Input() index: number;
  @Input() form: FormGroup;
  @Input() privateFile: boolean = true;
  @Input() groupName: string = 'attachments';

  @Input()
  set attachment(_attachment: Attachment) {
    this._attachment = _attachment;
    this.isImage = this._attachment?.type?.toString().indexOf('image') > -1;
    this.setIcon();
    this.changes.detectChanges();
  }

  get attachment() {
    return this._attachment;
  }

  downloading: boolean = false;

  get fancyboxUrl() {
    return `gallery-attachment-inline-${this.index}`;
  }

  get formGroup() {
    return this.form?.get(this.groupName);
  }

  get control() {
    return this.formGroup?.get(this.attachment?.id?.toString());
  }

  constructor(private service: AttachmentsManageService, private changes: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscibeControlChange();
  }

  subscibeControlChange() {
    this.sub = this.form
      .get(this.groupName)
      .get(this.attachment?.id?.toString())
      .valueChanges.subscribe(() => this.changes.detectChanges());
  }

  setIcon() {
    this.icon = extensionIconV2(this._attachment?.type, this.attachment?.file);
  }

  downloadAttachment() {
    if (this.downloading) return;
    this.downloading = true;
    this.changes.detectChanges();
    this.service
      .getFileBlob(privateFile(this.attachment.file), false)
      .subscribe({
        next: (res) => downloadFile(res, this.attachment.name),
      })
      .add(() => {
        this.downloading = false;
        this.changes.detectChanges();
      });
  }

  openImage($event) {
    if (!this.fancyboxUrl) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }
}
