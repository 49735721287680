import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';

export enum ECalendarSelected {
  SELECTED_CALENDARS = 'SELECTED_CALENDARS',
  SELECTED_EMPLOYEE_CALENDARS = 'SELECTED_EMPLOYEE_CALENDARS',
  SELECTED_SHARED_CALENDARS = 'SELECTED_SHARED_CALENDARS',
  SELECTED_CALENDAR_VIEW = 'SELECTED_CALENDAR_VIEW',
}
@Injectable({ providedIn: 'root' })
export class CalendarStorageService extends BaseStorageService {
  get isCalendarsSelected() {
    return (
      !!this.selectedCalendars?.length ||
      !!this.selectedEmployeeCalendars?.length ||
      !!this.selectedSharedCalendars?.length
    );
  }

  // #region user calendars
  set selectedCalendars(calendarIds: string[]) {
    this.put(ECalendarSelected.SELECTED_CALENDARS, JSON.stringify(calendarIds));
  }

  get selectedCalendars() {
    return JSON.parse(this.get(ECalendarSelected.SELECTED_CALENDARS)) || [];
  }

  get isSelectedCalendarsSet() {
    const value = JSON.parse(this.get(ECalendarSelected.SELECTED_CALENDARS));
    return !!Array.isArray(value);
  }
  //#endregion

  // #region personal employee calendars
  set selectedSharedCalendars(calendarIds: string[]) {
    this.put(ECalendarSelected.SELECTED_SHARED_CALENDARS, JSON.stringify(calendarIds));
  }

  get selectedSharedCalendars() {
    return JSON.parse(this.get(ECalendarSelected.SELECTED_SHARED_CALENDARS)) || [];
  }
  //#endregion

  // #region personal employee calendars
  set selectedEmployeeCalendars(calendarIds: string[]) {
    this.put(ECalendarSelected.SELECTED_EMPLOYEE_CALENDARS, JSON.stringify(calendarIds));
  }

  get selectedEmployeeCalendars() {
    return JSON.parse(this.get(ECalendarSelected.SELECTED_EMPLOYEE_CALENDARS)) || [];
  }
  //#endregion

  // #region personal employee calendars
  set selectedCalendarView(view: string) {
    this.put(ECalendarSelected.SELECTED_CALENDAR_VIEW, view);
  }

  get selectedCalendarView() {
    return this.get(ECalendarSelected.SELECTED_CALENDAR_VIEW);
  }
  //#endregion

  constructor() {
    super();
  }

  deleteSingleCalendar(id: string | number) {
    this.selectedCalendars = this.selectedCalendars?.filter((i) => i !== id.toString()) || [];
  }

  updateSelectedCalendars(calendars: string[], employeeCalendars: string[], sharedCalendars: string[]) {
    this.selectedCalendars = calendars.length ? calendars : this.isSelectedCalendarsSet ? [] : null;
    this.selectedEmployeeCalendars = employeeCalendars || [];
    this.selectedSharedCalendars = sharedCalendars || [];
  }

  resetSelectedCalendars() {
    this.delete(ECalendarSelected.SELECTED_CALENDARS);
    this.delete(ECalendarSelected.SELECTED_EMPLOYEE_CALENDARS);
    this.delete(ECalendarSelected.SELECTED_SHARED_CALENDARS);
    this.delete(ECalendarSelected.SELECTED_CALENDAR_VIEW);
  }
}
