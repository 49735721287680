import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { CHIPS_VIEW, ListService } from '@shared/modules/list/services/list.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'project-team-filters',
  templateUrl: './project-team-filters.component.html',
  styles: [],
})
export class ProjectTeamFiltersComponent
  extends BaseListFiltersComponentComponent
  implements OnInit, OnDestroy
{
  Config = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  constructor(
    @Optional() @Inject(CHIPS_VIEW) public chipsView: boolean,
    public service: ListService,
    public t: TranslateService,
    @Optional() private dialogRef: MatDialogRef<ProjectTeamFiltersComponent>,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.createForm();

    if (!this.chipsView) {
      this.service.readUrlParams(false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[function-name][eq]': new FormControl(null),
    });
  }

  cancel() {
    this.dialogRef.close();
    this.refreshChips();
  }

  submit() {
    this.service.getRows();
    this.dialogRef.close();
    this.refreshChips();
  }
}
