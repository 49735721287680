import { TaskType } from '@shared/enums/task-type.enum';

export const TaskListConfig = [
  {
    title: 'TaskGroupsTypes.note',
    type: TaskType.TYPE_NOTE,
    opened: false,
    items: [],
    assignment: false,
    realization: false,
    discount: false,
    status: false,
  },
  {
    title: 'TaskGroupsTypes.tasks',
    type: TaskType.TYPE_TASK,
    opened: false,
    items: [],
    assignment: true,
    realization: true,
    discount: false,
    status: true,
  },
  {
    title: 'TaskGroupsTypes.corrections',
    type: TaskType.TYPE_CORRECTION,
    opened: false,
    items: [],
    assignment: true,
    realization: true,
    discount: true,
    status: true,
  },
  {
    title: 'TaskGroupsTypes.changes',
    type: TaskType.TYPE_CHANGE,
    opened: false,
    items: [],
    assignment: true,
    realization: true,
    discount: false,
    status: true,
  },
  {
    title: 'TaskGroupsTypes.orders',
    type: TaskType.TYPE_ORDER,
    opened: false,
    items: [],
    assignment: true,
    realization: true,
    discount: false,
    status: true,
  },
];
