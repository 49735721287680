import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ListSingleFilter} from '@shared/modules/list/interfaces/list-single-filter.interface';
import {WindowHelper} from "@shared/helpers/window.helper";
import {debounce} from "@shared/decorators/debounce.decorator";

@Component({
  selector: 'list-single-filter-date',
  templateUrl: './list-single-filter-date.component.html'
})
export class ListSingleFilterDateComponent implements ListSingleFilter, AfterViewInit {

  @Input() name: string;
  @Input() iconUrl: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @ViewChild('dp') dp: MatDatepicker<any>;
  @ViewChild('input') input: ElementRef;

  get isValue() {
    return this.form?.value[this.controlName] && this.form?.value[this.controlName].toString().length;
  }

  constructor() {
  }

  ngAfterViewInit() {
    this.setMaxInputWidth();
  }

  @HostListener('window:resize')
  @debounce(50)
  resize() {
    this.setMaxInputWidth();
  }

  setMaxInputWidth() {
    if (WindowHelper.isMobileWidth) {
      const placeholder = this.input.nativeElement.getAttribute("placeholder");
      this.input.nativeElement.removeAttribute("style");
    } else {
      const placeholder = this.input.nativeElement.getAttribute("placeholder").trim();
      const maxWidth = (placeholder.length * 6.5) > 130 ? 130 : (placeholder.length * 6.5);
      this.input.nativeElement.style.maxWidth = maxWidth + 'px';
    }
  }

  onArrowClick() {
    if (!this.dp.opened) {
      this.dp.open();
    }
  }

  clear() {
    this.form.get(this.controlName).setValue(null);
    this.change.emit();
  }

}
