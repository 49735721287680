import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { Subscription } from 'rxjs';
import { EmployeeSearchComponent } from '@shared/components/employee-search/employee-search.component';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { TaskTypeList } from '@modules/protocols/shared/consts/task-type.list';
import { ETaskGroup, ETaskTypeGroup } from '@shared/modules/task-sidenav/enums/task-group.enum';
import { TranslateService } from '@ngx-translate/core';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Config } from '@shared/configs/config';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { Task } from '@shared/models/task.model';

@Component({
  selector: 'task-sidenav-blue-preview',
  templateUrl: './task-sidenav-blue-preview.component.html',
  styleUrls: ['./task-sidenav-blue-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSidenavBluePreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  CONFIG = Config;
  TaskGroupList = [];
  TaskTypeList = TaskTypeList;
  initial: boolean = false;
  sub: Subscription;
  listTaskSub: Subscription = new Subscription();

  @ViewChild('datepicker') datepicker: DateRangePickerComponent;
  @ViewChild('search') search: EmployeeSearchComponent;

  get taskGroupURL() {
    const control = this.service.form.get(this.service.TASK_SIDENAV_FORM.projectId);
    return Config.API + '/task-group' + (control?.value ? `?projectId=${control?.value}` : '');
  }

  constructor(
    public service: TaskSidenavService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private listTaskService: ListTaskService,
  ) {}

  ngOnInit() {}

  changePromise($event) {
    const contact = $event?.value?.object;
    if (!contact?.employee) {
      return new Promise((resolve, reject) => {
        const accepted = confirm(this.t.instant('Mailings.Templates.taskDelegation'));
        accepted ? resolve(true) : reject(false);
      });
    }
    return true;
  }

  addTagPromise(email: string) {
    return new Promise((resolve, reject) => {
      const accepted = confirm(this.t.instant('Mailings.Templates.taskDelegation'));
      accepted ? resolve(true) : reject(false);
    });
  }

  ngAfterViewInit() {
    this.setInitialDate();
    this.listenListTaskEvents();
    this.service.registerSearchEmployee(this.search);
    this.sub = this.service.taskActionEmitter.subscribe(() => this.changes.detectChanges());
  }

  listenListTaskEvents() {
    this.listTaskSub.add(
      this.listTaskService.emitter.subscribe((e: ListTaskEvent) => {
        switch (e.type) {
          case ListTaskEventType.UPDATE_TASK:
            if (e.data.id === this.service?.task?.id) {
              //TODO Bohdan - here you have event with updated task
              this.changes.detectChanges();
            }
            break;
        }
      }),
    );
  }

  setInitialDate() {
    const start = this.service.form.get(this.service.TASK_SIDENAV_FORM.termStart);
    const end = this.service.form.get(this.service.TASK_SIDENAV_FORM.termEnd);
    start.value && end.value ? this.datepicker.setRange(start.value, end.value) : null;
    end.valueChanges.subscribe(() => {
      start.value && end.value ? this.datepicker.setRange(start.value, end.value) : null;
      this.changes.detectChanges();
    });
  }

  taskGroupTransform(list) {
    list = list?.filter((i) => i.name !== ETaskTypeGroup.GROUP_CHANGE);
    if (!this.initial && !this.service.defaultGroupID && !this.service.isPreview) {
      const found =
        list.filter((i) => i.name === ETaskGroup.ALL_TASKS || i.name === ETaskTypeGroup.GROUP_TASK)[0] ||
        null;
      this.service.form.get(this.service.TASK_SIDENAV_FORM.groupId).patchValue(found.id, { onlySelf: true });
      this.service.defaultGroupID = found?.id;
      if (found) {
        this.service.selectedGroup = {
          id: found.id,
          text: this.service.translate('Projects.TaskGroups.', found.name),
        };
      }
      this.initial = true;
    }

    this.TaskGroupList = list.map((i) => {
      return { id: i.id, text: this.service.translate('Projects.TaskGroups.', i.name) };
    });

    this.changes.detectChanges();
    return [...list];
  }

  changeRange($event) {
    this.service.rangeChanged($event);
  }

  setTaskGroup(i: { id: number; text: string }) {
    this.service.setTaskGroup(i);
  }

  setTaskType(i: { type: string; name: string }) {
    this.service.setTaskType(i);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.listTaskSub.unsubscribe();
  }
}
