<div class="projects-timeline-resource-label" #resourceLabel>
  <svg-icon
    *ngIf="project"
    [src]="'/assets/img/ic-star-filled.svg'"
    [svgClass]="
      project?.isFavourite
        ? 'projects-timeline-resource-label__favourite checked'
        : 'projects-timeline-resource-label__favourite'
    "
    [svgStyle]="{ width: '16px', height: '16px' }"
    (click)="setFavourite()"
  ></svg-icon>

  <div class="d-flex align-items-center w-100">
    <expander
      class="mr-2"
      (onClick)="toggle()"
      [opened]="resourceStage.opened"
      *ngIf="resourceStage?.projectsCount"
    ></expander>

    <div
      class="projects-timeline-resource-label__title"
      [matTooltip]="project?.fullName"
      [matTooltipPosition]="'right'"
      [matTooltipClass]="'side-navigation-tooltip'"
      [matTooltipDisabled]="isIpad() || WindowHelper.isMobileWidth"
      (click)="openProjectPopover()"
      [ngClass]="{
        'projects-timeline-resource-label__title--project': project,
        'projects-timeline-resource-label__title--padding-left': !resourceStage?.projectsCount && !project,
        'cursor-pointer': project
      }"
    >
      {{ resource.title }}
    </div>
  </div>

  <div
    class="projects-timeline-resource-label__counter"
    *ngIf="resourceStage?.projectsCount"
    [ngClass]="{
      'projects-timeline-resource-label__counter--red': resourceStage.stage === ProjectStage.STAGE_LOST
    }"
  >
    {{ resourceStage?.projectsCount }}
  </div>
</div>

<projects-timeline-popover
  (close)="openedPopover = null"
  (onProjectChange)="onProjectChange.emit($event)"
  [project]="openedPopover?.project"
  [parentElement]="openedPopover?.parentElement"
  *ngIf="openedPopover?.project && openedPopover?.parentElement"
></projects-timeline-popover>
