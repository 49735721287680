import { EventEmitter, Injectable } from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { IProjectPreviewEvent } from '@modules/projects/modules/project-preview/interfaces/project-preview-event.interface';
import { ProjectPreviewEventType } from '@modules/projects/modules/project-preview/enums/projcet-preview-event-type.enum';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectPreviewService {
  private _project: Project;
  projectServiceEventEmitter: EventEmitter<IProjectPreviewEvent> = new EventEmitter<IProjectPreviewEvent>();
  public projectEditIDs: number[] = [];

  set project(project: Project) {
    this._project = new Project(project);
    this.projectServiceEventEmitter.emit({
      type: ProjectPreviewEventType.UPDATE_PROJECT,
      project: this.project,
    });
    this.projectEditIDs = this._project?.basicDataBox?.responsibleEmployee?.id
      ? [this.project?.basicDataBox?.responsibleEmployee?.id]
      : [];
  }

  get project() {
    return this._project;
  }

  constructor(private projectAPIService: ProjectAPIService) {}

  updateProject() {
    return this.projectAPIService.getProject(this.project.id).subscribe({
      next: (res: Project) => {
        this.project = res;
      },
    });
  }

  clearService() {
    this.project = null;
    this.projectEditIDs = [];
  }

  isEmployeeResponsibleForProject(userId: number): boolean {
    return this.project?.basicDataBox?.responsibleEmployee?.id === userId;
  }

  isClientResponsibleForProject(userId: number): boolean {
    return this.project?.basicDataBox?.responsibleContact?.employeeId === userId;
  }
}
