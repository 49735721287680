import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Config } from '@shared/configs/config';
import { JWT } from '@shared/interfaces/jwt.interface';

@Injectable()
export class AuthorizationService extends BaseHttpService {
  email: string = null;

  constructor() {
    super();
  }

  signInGoogle(token: string): Observable<JWT> {
    const url = `${Config.API}/auth/login/google`;
    return this.post(url, { token }) as Observable<JWT>;
  }

  signIn(data: any): Observable<JWT> {
    const url = `${Config.API}/auth/login`;
    return this.post(url, data) as Observable<JWT>;
  }

  signUp(data: any): Observable<any> {
    const url = `${Config.API}/auth/register`;
    return this.post(url, data);
  }

  resetPassword(email: string): Observable<any> {
    const url = `${Config.API}/auth/password-reset-request`;
    return this.post(url, { email });
  }

  setNewPassword(data): Observable<any> {
    const url = `${Config.API}/auth/password-reset`;
    return this.post(url, data);
  }

  signInConfirmation(token: string): Observable<any> {
    const url = `${Config.API}/auth/register-confirm/${token}`;
    return this.post(url, {});
  }

  resendConfirmation(): Observable<any> {
    const url = `${Config.API}/auth/resend-confirm`;
    return this.post(url, { email: this.email });
  }

  selectCompany(id: number): Observable<any> {
    const url = `${Config.API}/auth/login-employee/${id}`;
    return this.post(url, {});
  }

  sendContact(data) {
    const url = `${Config.API}/public/contact`;
    return this.post(url, data, true);
  }
}
