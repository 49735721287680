<button *ngIf="taskPermissionCtrl.preview || taskPermissionCtrl.canAccept" mat-menu-item (click)="preview()">
  <span>{{ 'ProjectChanges.List.preview' | translate }}</span>
</button>

<button *ngIf="taskPermissionCtrl.edit" mat-menu-item (click)="edit()">
  <span>{{ 'ProjectChanges.List.edit' | translate }}</span>
</button>

<button *ngIf="taskPermissionCtrl.share" mat-menu-item (click)="share()">
  <span>{{ 'ProjectChanges.List.share' | translate }}</span>
</button>

<button *ngIf="!row?.deleted && taskPermissionCtrl.preview" mat-menu-item (click)="createTask()">
  <span>{{ 'ProjectChanges.List.createTask' | translate }}</span>
</button>

<button
  *ngIf="!row?.deleted && taskPermissionCtrl.remove"
  mat-menu-item
  class="hover_red red-500"
  (click)="remove()"
>
  <span>{{ 'ProjectChanges.List.remove' | translate }}</span>
</button>
