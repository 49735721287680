<modal-title [title]="'Contacts.Manage.clientInProject' | translate"></modal-title>
<mat-dialog-content>
  <form
    [formGroup]="form"
    class="contact-pemission-modal"
    [ngClass]="{ 'contact-pemission-modal--open': responsibleSelect.select.isOpen || mainContactSelect.select.isOpen }"
  >
    <div class="mb-3">
      <b>{{ 'Contacts.Manage.mainContactTitle' | translate }}</b>
      <ng-select
        #mainContactSelect="ngSelectExtensionDir"
        ngSelectExtension
        [formControlName]="CONTACT_FORM.isMainContact"
        class="w-100 select select-default"
        [placeholder]="'Contacts.Manage.selectContact' | translate"
        [notFoundText]="'Contacts.Manage.notFoundText' | translate"
        [responseTransform]="transformContacts.bind(this)"
        [tabIndex]="-1"
        [bindLabel]="'fullName'"
        [bindValue]="'id'"
        [items]="mainContactList"
        [clearable]="contactList?.length === 1 || data?.getContactsList"
      >
        <ng-container *ngIf="data?.getContactsList">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <contact-option-template [contact]="item" [isLabel]="true"></contact-option-template>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <contact-option-template [contact]="item"></contact-option-template>
          </ng-template>
        </ng-container>
      </ng-select>


    </div>

    <div>
      <b>{{ 'Contacts.Manage.responibleContactTitle' | translate }}</b>
      <ng-select
        #responsibleSelect="ngSelectExtensionDir"
        ngSelectExtension
        [formControlName]="CONTACT_FORM.isResponsible"
        class="w-100 select select-default"
        [placeholder]="'Contacts.Manage.selectContact' | translate"
        [notFoundText]="'Contacts.Manage.notFoundText' | translate"
        [responseTransform]="transformContacts.bind(this)"
        [tabIndex]="-1"
        [bindLabel]="'fullName'"
        [bindValue]="'id'"
        [items]="responsibleContactList"
        [clearable]="contactList?.length === 1 || data?.getContactsList"
      >
        <ng-container *ngIf="data?.getContactsList">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <contact-option-template [contact]="item" [isLabel]="true"></contact-option-template>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <contact-option-template [contact]="item"></contact-option-template>
          </ng-template>
        </ng-container>
      </ng-select>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-button
    (onClick)="closeDialog()"
    [class]="'mr-2 bold'"
    [config]="{
      text: 'Contacts.Preview.cancel' | translate,
      type: ButtonTypes.DISCARD_BUTTON,
      size: ButtonSize.LARGE
    }"
  ></app-button>
  <app-button
    [disabled]="loading"
    [loading]="loading"
    (onClick)="submit()"
    [config]="{
      text: 'Contacts.Preview.saveContactPerson' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-save.svg'
    }"
  ></app-button>
</mat-dialog-actions>
