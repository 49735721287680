import * as QuillNamespace from 'quill';
import { ImageHandler, Options } from 'ngx-quill-upload';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { CustomAttributes } from '@shared/helpers/quill-translation.helper';
import { ProjectCreatorEntitySidenavModule } from '@modules/project-creator/shared/modules/project-creator-entity-sidenav/project-creator-entity-sidenav.module';
import ImageResize from 'quill-image-resize-module';
import PlainClipboard from './quill-plain-clipboard.helper';

export class QuillHelper {
  static init() {
    const Quill: any = QuillNamespace;
    Quill.register(CustomAttributes, true); // helps world translation fix
    Quill.register('modules/imageHandler', ImageHandler);
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
    // Quill.register('modules/clipboard', PlainClipboard, true); // This logic destroys native change detection on form and it cant be saved
  }
}
