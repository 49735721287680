<form
  class="task-item_task-wrapper"
  [formGroup]="form.get('items')"
  [ngClass]="{ 'mt-2': !!index }"
  [dndDraggable]="{ task: task, previousIndex: index }"
  [dndEffectAllowed]="'copy'"
  [dndType]="task.type"
  (dndDrag)="drag($event)"
  (dndStart)="dragStarted($event)"
  (dndEnd)="dragEnded()"
  [dndDisableIf]="!checkDragPermission || preview || disabledDnd"
  dndDragImageRef
>
  <div
    class="task-item_task d-flex justify-content-between align-items-start"
    [ngClass]="{
      'task-item_task-note': isNote,
      'task-item_task--change': isChange,
      'task-item_task--disabled': preview,
      'task-item_task--no-edit': !checkDragPermission
    }"
    [title]="task.description"
  >
    <div class="task-item_task-selection pt-2 pr-2" [ngClass]="{ change: task.isChange }">
      <div
        *ngIf="!preview && taskPermissionCtrl.edit"
        class="form-control-checkbox"
        [ngClass]="{ circling: isNote, square: !isNote }"
      >
        <label class="m-0 d-flex align-items-center" (click)="$event.stopPropagation()">
          <input
            (change)="changeTaskSelection($event?.target?.checked, task)"
            [formControlName]="task.id.toString()"
            type="checkbox"
          />
          <span></span>
        </label>
      </div>
      <div class="task-holder">
        <div class="d-flex align-items-start">
          <svg-icon
            *ngIf="!preview && !task.deleted && taskPermissionCtrl.edit"
            (click)="edit(); $event.stopPropagation()"
            class="mr-2 ic-edit cursor-pointer"
            src="./assets/img/ic-edit-task.svg"
            [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
          ></svg-icon>
          <svg-icon
            class="mr-2"
            *ngIf="task.isHighPriority"
            src="./assets/img/ic-arrow-up-task-priority.svg"
          ></svg-icon>
          <span
            (click)="previewTask()"
            class="task-item_task-title cursor-pointer"
            [ngClass]="{ crossed: task?.isCompleted, 'task-item_task-title--priority': task.isHighPriority }"
            [innerText]="task.description"
          ></span>
          <svg-icon
            [title]="'Protocols.hiddenFromClient' | translate"
            *ngIf="task.hiddenForClient && !preview"
            [tourAnchor]="'protocolManage.s9.hidden' + index"
            class="ml-1"
            src="./assets/img/ic-eye-off.svg"
            [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
          >
          </svg-icon>
        </div>

        <ul class="mb-0 mt-2 d-flex align-items-center pb-2" *ngIf="task?.attachments?.length">
          <ng-container *ngIf="task?.imgAttachments?.length">
            <ng-container
              *ngFor="let attachment of task?.imgAttachments; let i = index; trackBy: trackAttachments"
            >
              <li
                [hidden]="i >= task.imagesToShow"
                class="mr-2"
                *ngIf="attachment?.type?.indexOf('image') > -1"
              >
                <attachment-image [attachment]="attachment" [fancyboxUrl]="'gallery-' + index + '-' + time">
                </attachment-image>
              </li>
            </ng-container>
            <ng-container *ngIf="task.showMoreImages">
              <li>
                <attachment-image-more [fancyboxUrl]="'gallery-' + index + '-' + time">
                </attachment-image-more>
              </li>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!!task.filesCount">
            <li (click)="previewTask()">
              <div
                class="task-attachment__file d-flex flex-column justify-content-between align-items-center mr-2 cursor-pointer"
              >
                <img
                  draggable="false"
                  class="task-attachment__file-items"
                  src="assets/img/ic-add-files.svg"
                  alt="Files"
                />
                <span>{{ task.filesCount }} {{ 'Protocols.files' | translate }}</span>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <!--#region Plus Icon-->
    <div
      class="d-flex align-items-start task-actions justify-content-end w-50"
      [ngClass]="{ 'py-2': !isNote }"
    >
      <div
        class="d-flex align-items-start justify-content-end flex-column"
        [ngClass]="{ 'task-actions-holder': !isNote }"
      >
        <!-- #region Assignment -->
        <div
          *ngIf="isAssignment && (task?.assignedTo?.length || task?.assignedEmails?.length)"
          class="task-item_task-assignment d-flex"
        >
          <div class="black text-left" [innerHTML]="'Protocols.assignedProtocol' | translate"></div>
          <div class="task-item_task-assignment-person text-right">
            <ng-container *ngFor="let person of task.assignedTo">
              <span [title]="person?.userPerson?.previewName" [innerHTML]="person?.userPerson?.previewName">
              </span>
              <br />
            </ng-container>
            <ng-container *ngFor="let email of task.assignedEmails">
              <span [title]="email" [innerHTML]="email"> </span>
              <br />
            </ng-container>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region Discount -->
        <div *ngIf="isDiscount" class="task-item_task-discount d-flex">
          <div class="black text-right" [innerHTML]="'Protocols.discountProtocol' | translate"></div>
          <div
            class="grey-4"
            [title]="!task.discount ? ('Protocols.withoutDiscount' | translate) : (task.discount | priceFormat)"
            [innerHTML]="!task.discount ? ('Protocols.withoutDiscount' | translate) : (task.discount | priceFormat)"
          ></div>
        </div>
        <!-- #endregion -->

        <!-- #region Realization -->
        <div *ngIf="isRealization && task.termEnd" class="d-flex task-item_task-realization-date grey-4">
          <div class="black text-left" [innerHTML]="'Protocols.termProtocol' | translate"></div>
          <div
            class="text-right"
            [title]="task.termEnd | dateFormat"
            [innerHTML]="task.termEnd | dateFormat"
          ></div>
        </div>
        <!-- #endregion -->

        <!-- #region Price -->
        <div
          *ngIf="isChange && taskPermissionCtrl.canSeePrice"
          class="task-item_task-price d-flex align-items-center"
        >
          <div class="black text-left" [innerHTML]="'Protocols.quantityProtocol' | translate"></div>
          <price-cell [price]="task.price" [isLocalCurrency]="true"></price-cell>
        </div>
        <!-- #endregion -->

        <!-- #region Type change -->
        <div class="task-item_task-change mr-2" *ngIf="isNote && !preview && taskPermissionCtrl.edit">
          <task-type-dropdown [task]="task" (typeEmitter)="changeType($event)"></task-type-dropdown>
        </div>
        <!-- #endregion -->
      </div>

      <div
        class="task-item_task-actions"
        [ngClass]="{ 'task-item_task-actions-note': isNote }"
        *ngIf="WindowHelper.isMobileWidth && !preview"
        [matMenuTriggerFor]="previewActions"
      >
        <svg-icon class="more-icon" src="./assets/img/ic-plus-add-sharp.svg"></svg-icon>
      </div>

      <mat-menu #previewActions="matMenu">
        <ul class="m-0" (click)="$event.stopPropagation()">
          <li
            mat-menu-item
            (click)="deleteTask(task)"
            class="d-flex align-items-center red-500 hover_red"
            [innerHTML]="'Protocols.QuickPreview.remove' | translate"
          ></li>
        </ul>
      </mat-menu>

      <!-- #region Remove bucket -->
      <div
        *ngIf="WindowHelper.isLargeWidth && !preview && taskPermissionCtrl.edit"
        (click)="deleteTask(task)"
        class="task-item_task-delete cursor-pointer"
        [ngClass]="{ 'task-item_task-delete-note': isNote }"
      >
        <svg-icon src="./assets/img/ic-trash-outline-red.svg"></svg-icon>
      </div>
      <!-- #endregion -->
    </div>
    <!--#endregion-->
  </div>
</form>
