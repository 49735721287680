export class Currency {
  code: string;
  id: number;
  leftSide: boolean;
  symbol: string;

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
  }

  get label()
  {
    return `${this.code} - ${this.symbol}`;
  }
}
