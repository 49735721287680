import { NgModule, InjectionToken, Type } from '@angular/core';
import { GbxsoftComponents, GbxsoftDirectives } from './gbxsoft-components';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [...GbxsoftComponents, ...GbxsoftDirectives],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [...GbxsoftComponents],
})
export class GbxsoftFormModule {}
export const ERROR_TRANSLATIONS = new InjectionToken<FormModuleOptions>('unique.string.for.config');
export interface FormModuleOptions {
  errMessages: { [key: string]: string };
}
