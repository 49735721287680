import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ListService } from '../../services/list.service';
import { Subscription } from 'rxjs';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';

@Component({
  selector: 'app-list-footer',
  templateUrl: './list-footer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListFooterComponent implements OnInit, OnDestroy {

  sub: Subscription = new Subscription();

  constructor(public service: ListService, private changes: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.listenListServiceEvents();
  }

  listenListServiceEvents() {
    this.sub.add(this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.END_GET_ROWS:
          this.changes.detectChanges();
          break;
      }
    }));
  }

  get end() {
    const end = (this.service?.Page) * this.service?.PerPage;
    return end > this.service?.total ? this.service?.total : end;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
