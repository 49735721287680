import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component } from '@angular/core';
import { NotificationsService } from '@modules/notification/shared/services/notifications.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';

@Component({
  selector: 'header-action-menu',
  templateUrl: './header-action-menu.component.html',
  styleUrls: ['./header-action-menu.component.scss'],
})
export class HeaderActionMenuComponent {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  PermissionsGroups = PermissionsGroups;

  constructor(public notificationsService: NotificationsService) {}

  createProtocol() {
    const ctrl = new ProtocolController(null, null);
    ctrl.add();
  }

  createContact() {
    const ctrl = new ContactController();
    ctrl.add();
  }

  createProjectDraft() {
    const ctrl = new ProjectController();
    ctrl.add();
  }

  createTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(null);
  }

  createChange() {
    const ctrl = new ChangesSidenavController();
    ctrl.addChange(null);
  }

  createEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent();
  }

  createEmail() {
    const ctrl = new MailingController();
    ctrl.openMailingSelection('');
  }
}
