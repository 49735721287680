<div
  class="project-panel-preview__header"
  [ngClass]="{ 'project-panel-preview__header--hidden': hiddenSideNavigation }"
  #header
>
  <div class="project-panel-preview__header__name">
    <h4 *ngIf="projectPreviewService?.project?.fullNameAndAddress">
      {{ projectPreviewService?.project?.fullNameAndAddress }}
    </h4>
    <div class="d-flex align-items-center">
      <h2>
        {{ projectPreviewService?.project?.nameAndProjectID }}
      </h2>
      <button
        class="btn-icon project-panel-preview__header__actions__toggle p-0 ml-2"
        [permission]="{
          group: PermissionsGroups.PROJECTS,
          action: 'EDIT',
          objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
        }"
        *ngIf="notEmptyList()"
        [matMenuTriggerFor]="menu"
      >
        <img src="./assets/img/ic-settings-grey.svg" alt="settings" />
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'EDIT',
            objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
          }"
          mat-menu-item
          (click)="edit()"
        >
          <span>{{ 'Projects.PreviewHeader.edit' | translate }}</span>
        </button>
        <button
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'EDIT',
            objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
          }"
          mat-menu-item
          (click)="editResponsible()"
        >
          <span>{{ 'Projects.PreviewHeader.editResponsible' | translate }}</span>
        </button>
        <button
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'ALERTS'
          }"
          mat-menu-item
          (click)="addAlert()"
        >
          <span>{{ 'Projects.PreviewHeader.addAlert' | translate }}</span>
        </button>
        <button mat-menu-item (click)="downloadSpec()" *ngIf="projectPreviewService?.project?.specification">
          <span>{{ 'Projects.PreviewHeader.downloadSpec' | translate }}</span>
        </button>
        <button
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'REMOVE',
            objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
          }"
          mat-menu-item
          (click)="remove()"
          class="hover_red red-500"
        >
          <span>{{ 'Projects.PreviewHeader.remove' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="project-panel-preview__header__actions">
    <!-- <button
      (click)="openMailings()"
      class="btn_sm btn btn-empty_blue d-inline-flex align-items-center font-400 mr-2 px-2"
    >
      <svg-icon [src]="'./assets/img/ic-mail.svg'"></svg-icon>
      <span class="ml-2 d-none d-lg-block">{{ 'Mailings.Templates.writeEmail' | translate }}</span>
    </button> -->
    <!-- <button
      class="btn_sm btn btn-empty_blue font-400 mr-2"
      *ngIf="WindowHelper.isMoreLessMD && isSummaryView()"
      (click)="toggleMobileSummary()"
    >
      <span>{{ 'ProjectSummary.RightSection.about' | translate }}</span>
    </button> -->

    <div class="d-md-none d-block">
      <div class="d-inline-flex">
        <div [matMenuTriggerFor]="stages">
          <app-button
            [class]="'mr-2 bold'"
            [permission]="{
              group: PermissionsGroups.PROJECTS,
              action: 'EDIT',
              objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
            }"
            [config]="{
              type: ButtonTypes.LIGHT_BLUE,
              text: 'Projects.Stage.' + projectPreviewService?.project?.stage | translate,
              size: ButtonSize.SMALL,
              appendSrc: './assets/img/ic-arrow-down-blue.svg'
            }"
          >
          </app-button>
        </div>
        <mat-menu #stages="matMenu" xPosition="after">
          <ng-container *ngFor="let key of Object.keys(ProjectStage)">
            <button
              mat-menu-item
              (click)="onStageChange(ProjectStage[key])"
              [disabled]="projectPreviewService.project.stage === ProjectStage[key]"
              [ngClass]="{
                'hover_red red-500': ProjectStage[key] === ProjectStage.STAGE_LOST,
                'blue-500': projectPreviewService.project.stage === ProjectStage[key]
              }"
            >
              <span>{{ 'Projects.Stage.' + ProjectStage[key] | translate }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div class="d-md-none d-block overflow-hidden">
  <div class="d-flex justify-content-center">
    <div class="category-list">
      <div class="category-list__item" *ngFor="let i of tabs">
        <a
          draggable="false"
          (click)="$event.preventDefault(); tabSelect(i)"
          [routerLink]="i.id"
          [routerLinkActive]="'category-list__item-wrapper--active'"
          class="category-list__item-wrapper"
        >
          <div>
            <svg-icon [src]="i.icon"></svg-icon>
          </div>
          <div>
            {{ i.text }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- <ng-select
  #selectTab
  ngSelectExtension
  class="select select-default project-panel-preview__header__select-tabs"
  [dropdownPosition]="'bottom'"
  [items]="tabs"
  [clearable]="false"
  [searchable]="false"
  [bindLabel]="'text'"
  [bindValue]="'id'"
  [placeholder]="'Projects.tabs' | translate"
  (change)="onTabClick($event, true)"
  [tabIndex]="-1"
>
</ng-select> -->
