<div *ngIf="isSomeSelected" class="chips__container">
  <ng-container *ngFor="let chip of service.chips | keyvalue">
    <ng-container *ngIf="chip.value.length">
      <div *ngFor="let value of chip.value" class="chip">
        <span>{{ value.name }}</span>
        <svg-icon
          class="remove-icon"
          [src]="'/assets/img/small-close-icon.svg'"
          (click)="remove(chip, value.id, chip.value.length > 1)"
        ></svg-icon></div
    ></ng-container>
  </ng-container>
</div>
