import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectCreatorManage } from '@modules/project-creator/pages/project-creator-manage/project-creator-manage.component';

@Component({
  selector: 'app-projects-configurator',
  templateUrl: './projects-configurator.component.html',
  styleUrls: ['./projects-configurator.component.scss'],
})
export class ProjectsConfiguratorComponent implements OnInit {
  @ViewChild('configurator') configurator: ProjectCreatorManage;
  constructor(public projectService: ProjectStoreService, public active: ActivatedRoute) {}

  ngOnInit() {}
}
