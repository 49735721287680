export class ProjectCreatorStepperController {
  currentStep: number = 1;

  constructor() {}

  setStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    this.currentStep++;
  }

  prevStep() {
    this.currentStep--;
  }
}
