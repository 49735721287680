import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { ChangeSource } from '@modules/projects/shared/enums/change-source.enum';
import { Component, OnInit } from '@angular/core';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'task-changes-summary',
  templateUrl: './task-changes-summary.component.html',
  styleUrls: ['./task-changes-summary.component.scss'],
})
export class TaskChangesSummaryComponent implements OnInit {
  ChangeSource = ChangeSource;
  protocolPreviewPermission: boolean = false;

  constructor(
    public service: TaskSidenavService,
    public pService: ProtocolApiService,
    public store: StorageService,
  ) {}

  ngOnInit(): void {
    this.protocolPreviewPermission = this.checkProtocolPreviewPermission(this.service.task?.protocol);
  }

  checkProtocolPreviewPermission(protocol: Protocol) {
    protocol = new Protocol(protocol);
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROTOCOLS,
      action: 'PREVIEW',
      objectCreatorId: [protocol?.creator?.id, ...(protocol?.taskAssignedIds || [])],
    });
    return ctrl.check();
  }

  preview(protocol: Protocol) {
    const protocolController = new ProtocolController(this.pService, new Protocol(protocol));
    protocolController.preview(this.store.Employee);
  }
}
