import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() controlName: string = 'checkbox';
  @Input() title: string;
  @Input() disabled: boolean = false;
  @Input() classes: string = '';

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    if (this.form) return;
    this.form = this.fb.group({ [this.controlName]: false });
  }
}
