import { Component, Injector, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'list-single-filter',
  templateUrl: './list-single-filter.component.html',
})
export class ListSingleFilterComponent {

  @Input() iconUrl: string;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() control: FormControl;
  @Input() template: TemplateRef<any>;

  injector: Injector;

  constructor() {
  }

  get isValue() {
    if (this.control) {
      return this.control.value && this.control.value.toString().length;
    }
    return this.form?.value[this.controlName] && this.form?.value[this.controlName].toString().length;
  }

}
