import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionType } from '../enums/permission-type.enum';
import { PermissionsAction } from '../interfaces/permissions-action.interface';
import { PermissionsConfig } from '../interfaces/permissions-config';
import { PermissionsGroups } from '../permissions.group';

/**
 * @description
 * @author Bohdan Masliannykov
 * @export
 * @class CommentariesPermissionsConfig
 * @implements {PermissionsConfig}
 */
export class CommentariesPermissionsConfig implements PermissionsConfig {
  static readonly EDIT: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.COMMENTARIES;
}
