import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { IEntryTemplateConfig } from './../../interfaces/entry-template-config.interface';
import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { EntryManageType } from '@shared/modules/entry/components/entry-list/entry-list.component';
import { ProjectAPIService } from '../../services/project-api.service';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { CommentaryManageComponent } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { ProjectQuestionDirectoryBase } from '../project-question-directory-base/project-question-directory-base.component';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { ProjectThirdBoxEntries } from '../../consts/project-third-box-entries';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';

@Component({
  templateUrl: './project-question-directory-third.component.html',
  styleUrls: ['./project-question-directory-third.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectQuestionDirecoryThirdComponent
  extends ProjectQuestionDirectoryBase
  implements OnInit, OnDestroy {
  ProjectThirdBoxEntries = ProjectThirdBoxEntries;
  entryListsConfig: IEntryTemplateConfig[] = this.filterList(
    ProjectThirdBoxEntries,
    'dataBox3',
    EntryManageType.BUTTON,
  );

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachments: 20,
    maxAttachmentFileSize: 50,
  };

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    private n: NavigateService,
    public projectAPI: ProjectAPIService,
    public changes: ChangeDetectorRef,
    public projectStore: ProjectStoreService,
    private footerService: StickyFooterService,
  ) {
    super(projectStore, changes);
  }

  ngOnInit() {
    this.additionalComment.setValue(
      this.projectStore.projectCtrl.project.dataBox3?.additionalComment || null,
    );
    this.subscribeSaving();
    this.changes.detectChanges();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submitDirectoryModel();
          break;
        default:
          break;
      }
    });
  }

  discard() {
    this.n.go('projects-list');
  }

  submitDirectoryModel() {
    this.projectStore.loading = true;
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.changes.detectChanges();
    this.projectAPI
      .updateBox3(this.projectStore.projectCtrl.project.id, this.directoryModel)
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Projects.projectBox3Updated'));
        },
      })
      .add(() => {
        this.projectStore.loading = false;
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.changes.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
