import { ProtocolType } from '@shared/enums/protocol-type.enum';

export class SentAcceptance {
  deleted?: any;
  created: string;
  modified: string;
  id: number;
  shownDate?: string;
  file: string;
  status: string;
  loading: boolean = false;
  token: string;

  constructor(acceptance?: SentAcceptance) {
    acceptance ? Object.assign(this, acceptance) : null;
  }
}

export interface RecievedAcceptance {
  deleted?: any;
  created: string;
  modified: string;
  id: number;
  weather: string;
  idNumber: string;
  status: string;
  type: ProtocolType;
  acceptanceNote?: any;
  lastAcceptance: SentAcceptance;
  sentAcceptances: SentAcceptance[];
}
