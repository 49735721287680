import { Injector } from '@angular/core';
import { Task } from '@shared/models/task.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { ChangesSidenavController } from '../controllers/changes-sidenav.controller';
import { TaskSidenavController } from '../controllers/task-sidenav.controller';
import { TaskSidenavService } from '../services/task-sidenav.service';
import { TaskType } from '@shared/enums/task-type.enum';

export class TaskSidenavManager {
  injector: Injector;
  service: TaskSidenavService;
  taskCtrl: TaskSidenavController;
  changesCtrl: ChangesSidenavController;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.service = this.injector.get(TaskSidenavService);
    this.taskCtrl = new TaskSidenavController();
    this.changesCtrl = new ChangesSidenavController();
  }

  createTask(
    projectId: number,
    connectedTaskId?: number,
    extra?: { objectType; taskId: number; type: TaskType; showInCalendar?: boolean; taskGroupId?: number },

  ) {
    if (this.service.isTaskSelection) {
      this.taskCtrl.addTask(
        projectId,
        extra?.taskGroupId || null,
        connectedTaskId,
        extra,
        null,
        null,
        extra?.showInCalendar,
      );
    }

    if (this.service.isConfigChange) {
      this.changesCtrl.addChange(projectId);
      // this.changesCtrl.addChange(projectId);
    }
  }

  previewTask(task: Task) {
    const id = task?.taskId || task.id?.toString();

    if (this.service.isTaskSelection) {
      this.taskCtrl.previewTask(id);
    }

    if (this.service.isConfigChange) {
      this.changesCtrl.previewChange(id);
    }
  }

  editTask(task: Task) {
    const id = task?.taskId || task.id?.toString();

    if (this.service.isTaskSelection) {
      this.service.loadingTask = true;
      this.taskCtrl.editTask(task.project?.id, id);
    }

    if (this.service.isConfigChange) {
      this.service.loadingTask = true;
      this.changesCtrl.editChange(task.project?.id, id);
    }
  }
}
