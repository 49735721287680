import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Component } from '@angular/core';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'task-sidenav-blue',
  styleUrls: ['./task-sidenav-blue.component.scss'],
  templateUrl: './task-sidenav-blue.component.html',
})
export class TaskSidenavBlueComponent {
  CONFIG = Config;

  constructor(public service: TaskSidenavService, private t: TranslateService) {}
}
