import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  SidenavEvent,
  SidenavEventType,
  SidenavService,
  SidenavSide,
} from '@shared/services/sidenav/sidenav.service';
import { SignupMobileNavigationComponent } from '../../../signup-mobile-navigation/signup-mobile-navigation.component';

@Component({
  selector: 'signup-humburger',
  templateUrl: './signup-humburger.component.html',
  styleUrls: ['./signup-humburger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupHumburgerComponent implements OnInit {
  menuOpened: boolean = false;

  constructor(private sidenavService: SidenavService, private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}

  openMenu() {
    this.menuOpened = true;

    this.sidenavService.open(SignupMobileNavigationComponent, {
      side: SidenavSide.LEFT,
      closeOnBackdrop: true,
      maxWidth: '85%',
    });

    this.changes.detectChanges();
    this.sidenavService.emitter.subscribe((e: SidenavEvent) => {
      if (e.type === SidenavEventType.CLOSE) {
        this.menuOpened = false;
        this.changes.detectChanges();
      }
    });
  }
}
