<modal-title></modal-title>
<div class="text-center verification-modal">
  <h2 class="auth-welcome blue-300" [innerHTML]="'Auth.Verification.verify' | translate"></h2>
  <div class="blue-300 auth-welcome-sub" [innerHTML]="'Auth.Verification.confirm' | translate"></div>
  <div class="grey-4 auth-fill mb-4" [innerHTML]="'Auth.Verification.confirmation' | translate"></div>
  <div class="resend">
    <app-button
      [class]="'ml-1'"
      [loading]="loading"
      [disabled]="loading || !!timeLeft"
      (onClick)="resendConfirmation()"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Auth.Verification.resend' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-arrow-right.svg'
      }"
    >
    </app-button>
    <p *ngIf="timeLeft" class="mt-2 grey-4">
      {{ 'Auth.Verification.tryIn' | translate }} <span class="timer" [innerHTML]="timeLeft"></span>
    </p>
  </div>
  <div>
    <app-button
      [class]="'bold'"
      (onClick)="discard()"
      [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Auth.Verification.close' | translate,
        size: ButtonSize.LARGE
      }"
    >
    </app-button>
  </div>
</div>
