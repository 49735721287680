import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';
import { NavigateService } from '@shared/services/navigate.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class CalendarResolver implements Resolve<any> {
  constructor(private calendarService: CalendarService, private n: NavigateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { code } = route?.queryParams;
    if (!code) {
      return;
    }

    return this.calendarService.grantGoogleCalendarAccess(code).pipe(
      finalize(() => {
        this.n.navigate('calendar', {}, {}, true);
      }),
    );
  }
}
