import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { ProjectPanelManageComponent } from '@modules/projects/modules/projects-create/pages/project-panel-manage/project-panel-manage.component';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ProjectsConfiguratorComponent } from '@modules/projects/modules/projects-configurator/projects-configurator.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectCreatorDeactivateComponent } from '@modules/project-creator/pages/project-creator-manage/components/project-creator-deactivate/project-creator-deactivate.component';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { Config } from '@shared/configs/config';

@Injectable({ providedIn: 'root' })
export class CanDeactivateProjectCreation implements CanDeactivate<ProjectsConfiguratorComponent> {
  constructor(private t: TranslateService, private service: ProjectStoreService, private dialog: MatDialog) {}

  canDeactivate(
    component: ProjectsConfiguratorComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): any {
    if (!!currentRoute?.data?.create) {
      if (!this.service.projectCreated) {
        return new Promise((resolve, reject) => {
          if (!component?.configurator?.isCurrentStepUnsaved) {
            return resolve(true);
          }
          this.dialog
            .open(ProjectCreatorDeactivateComponent, {
              width: Config.DEFAULT_MODAL_WIDTH,
              autoFocus: false,
              data: { viewState: 'create' },
            })
            .afterClosed()
            .subscribe((value) => resolve(!!value));
        });
      }
    } else {
      if (!!component?.configurator?.isCurrentStepUnsaved) {
        return new Promise((resolve, reject) => {
          this.dialog
            .open(ProjectCreatorDeactivateComponent, {
              width: Config.DEFAULT_MODAL_WIDTH,
              autoFocus: false,
              data: { viewState: 'edit' },
            })
            .afterClosed()
            .subscribe((value) => resolve(!!value));
        });
      }
    }

    return true;
    // return confirm(
    //   this.t.instant(
    //     !!currentRoute?.data?.edit ? 'Projects.leaveProjectEdit' : 'Projects.leaveProjectCreation',
    //   ),
    // );
  }
}
