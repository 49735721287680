<div class="signature" [ngClass]="{ active: editMode }">
  <div class="signature-header">
    <h5 [innerHTML]="title"></h5>
  </div>
  <div class="signature-content" #sketch>
    <div class="signature-pad" #signaturePadWrapper>
      <canvas
        #signaturePad
        (mousedown)="onMouseDown($event)"
        (mousemove)="onMouseMove($event)"
        (touchmove)="onTouchMove($event)"
        (touchstart)="onMouseDown($event)"
      ></canvas>
    </div>
    <div class="signature-content-buttons">
      <app-button
        *ngIf="
          !editMode && !form?.get(controlName)?.value?.attachment && !form?.get(controlName)?.value?.file
        "
        [config]="{
          text: 'Protocols.addSignature' | translate,
          type: ButtonTypes.SECONDARY_BLUE,
          prependSrc: '/assets/img/ic-pen.svg'
        }"
        (onClick)="editMode = true"
      ></app-button>

      <app-button
        *ngIf="
          !editMode && (form?.get(controlName)?.value?.attachment || form?.get(controlName)?.value?.file)
        "
        [config]="{
          text: 'Protocols.editSignature' | translate,
          type: ButtonTypes.SECONDARY_BLUE,
          prependSrc: '/assets/img/ic-pen.svg'
        }"
        (onClick)="setEditMode()"
      ></app-button>

      <div class="d-flex align-items-center justify-content-between flex-column flex-md-row" *ngIf="editMode">
        <app-button
          class="mb-2 mb-md-0 btn-sm-block"
          [class]="'btn-sm-block'"
          [config]="{
            text: 'Protocols.clearSignature' | translate,
            type: ButtonTypes.SECONDARY_BLUE,
            prependSrc: '/assets/img/ic-pen.svg'
          }"
          (onClick)="clearCanvas()"
        ></app-button>

        <app-button
          class="btn-sm-block"
          [class]="'btn-sm-block'"
          [config]="{
            text: 'Protocols.addSaveSignature' | translate,
            type: ButtonTypes.PRIMARY_BLUE,
            prependSrc: '/assets/img/ic-plus-add.svg'
          }"
          (onClick)="saveSignature()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
