import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class ListUrlController {

  constructor(public router: Router, public route: ActivatedRoute, public location: Location) {
  }

  readParamsFromUrl() {
    const queryParams = {};
    this.route.snapshot.queryParamMap.keys.forEach((key: string) => {
      const paramVal = this.route.snapshot.queryParamMap.get(key);
      if (key === 'fullFilters') {
        return;
      }


      if (paramVal.indexOf(',') > -1 && paramVal.indexOf('{') < 0) {
        queryParams[key] = paramVal.split(',');
      }

      // if object in string
      else if (paramVal.indexOf('{') > -1 && paramVal.indexOf('}') > -1) {
        queryParams[key] = this.getJSONsArrFromString(paramVal);
      } else {
        queryParams[key] = paramVal;
      }
    });
    return queryParams;
  }


  getJSONsArrFromString(paramsVal: string): string[] {
    const arr: string[] = [];

    const getOneObj = () => {
      const i = paramsVal.indexOf('{');
      const j = paramsVal.lastIndexOf('}');

      if (JSON.parse(paramsVal.substring(i, j + 1))) {
        arr.push(JSON.parse(paramsVal.slice(i, j + 1)));
        paramsVal = paramsVal.slice(j + 1);
      }

      if (paramsVal.indexOf('{') > -1 && paramsVal.indexOf('}') > -1) {
        getOneObj();
      }
    };

    if (paramsVal.indexOf('{') > -1 && paramsVal.indexOf('}') > -1) {
      getOneObj();
    }
    return arr;
  }

  setParamsToUrl(params) {
    const paramsForBrowserUrl: any = {};
    for (const k in params) {
      if (k === 'fullFilters') {
        continue;
      }

      if (!Array.isArray(params[k]) && typeof params[k] === 'object') {
        paramsForBrowserUrl[k] = params[k].toString();
      } else if ((params[k] || params[k] === 0) && params[k].toString().length) {
        paramsForBrowserUrl[k] = params[k].toString().trim();
      }
    }

    const createdUrl = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: paramsForBrowserUrl,
      preserveFragment: true
    });

    this.location.replaceState(this.router.serializeUrl(createdUrl));
  }
}
