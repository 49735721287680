import { Attachment } from '@shared/interfaces/attachment.interface';

export class AttachmentPacket {
  attachments: Attachment[] = [];
  created: string;
  deleted: string;
  id: number;
  modified: string;

  constructor(packet?: AttachmentPacket) {
    packet ? Object.assign(this, packet) : null;
  }
}
