import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ListSingleFilter } from '@shared/modules/list/interfaces/list-single-filter.interface';

@Component({
  selector: 'list-single-filter-checkbox',
  templateUrl: './list-single-filter-checkbox.component.html',
  styles: [
  ]
})
export class ListSingleFilterCheckboxComponent implements ListSingleFilter {

  @Input() name: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Output() change: EventEmitter<any> = new EventEmitter();


  constructor() { }

  get isValue() {
    return this.form?.value[this.controlName] && this.form?.value[this.controlName].toString().length;
  }

  onArrowClick(){}
}
