import { Employee } from '@shared/models/employee.model';

export class Attachment {
  deleted?: any;
  created: string;
  modified: string;
  id: number;
  type: string;
  name: string;
  file: string;
  size?: number; // Provided in bytes
  isUploaded?: boolean;
  creator: Employee;

  constructor(attachment?: Attachment) {
    attachment ? Object.assign(this, attachment) : null;
    this.setCreator();
  }

  setCreator() {
    this.creator = new Employee(this.creator || null);
  }
}
