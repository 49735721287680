import { Subscription } from 'rxjs';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { UserPerson } from '@shared/interfaces/user.interface';
import { TaskAction } from '@modules/protocols/shared/enums/task-action.enum';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { TaskType } from '@shared/enums/task-type.enum';
import { Task } from '@shared/models/task.model';
import { privateFile } from '@shared/helpers/private-file.helper';
import * as moment from 'moment';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProtocolAction } from '@modules/protocols/shared/enums/protocol-action.enum';
import { TaskEmitter } from '@modules/protocols/shared/interfaces/task-emitter.interface';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { ProtolActionEmitter } from '@modules/protocols/shared/interfaces/protocol-action-emitter.interface';
import { BaseTaskComponent } from '@shared/components/base-task/base-task.component';
import { TaskService } from '../../services/task-service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { Config } from '@shared/configs/config';
import { priceToPenny } from '@shared/helpers/price-to-penny.helper';
import { TaskListItem } from '@modules/protocols/shared/interfaces/task-list-item.interface';
import { DndDropEvent, DndDropzoneDirective } from 'ngx-drag-drop';
import { CustomDndDropEventInterface } from '@shared/interfaces/custom-dnd-drop-event.interface';
import { arrMove } from '@shared/helpers/arr-move.helper';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'task-item',
  styleUrls: ['./task-item.component.scss'],
  templateUrl: './task-item.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskItemComponent extends BaseTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription = new Subscription();
  PermissionsGroups = PermissionsGroups;

  enteredList: boolean = false;
  isShowMoreImages: boolean = false;
  anyItemChecked: boolean = false;

  @Input() opened: boolean = false;
  @Input() title: string;
  @Input() index: number;
  @Input() taskList: TaskListItem;
  @Input() type: TaskType;
  @Input() dropzoneType: TaskType[] = Object.values(TaskType);
  @Input() disabledDnd: boolean = false;

  @Output() plusClicked: EventEmitter<any> = new EventEmitter();

  @ViewChild(DndDropzoneDirective) dropzone;

  previewItems: Array<Task> = [];

  $items: Array<Task> = [];
  $hideHiddenFromClient: boolean = false;

  @Input()
  get items() {
    return this.$items;
  }

  set items(items: Array<Task>) {
    this.$items = [...items];
    this.setItems(items);
  }

  @Input()
  get hideHiddenFromClient() {
    return this.$hideHiddenFromClient;
  }

  set hideHiddenFromClient(value: boolean) {
    this.$hideHiddenFromClient = value;
    this.items ? this.setItems(this.items) : null;
  }

  form: FormGroup;
  changingState: boolean = false;

  get isAllSelected() {
    return !!this.form?.get('all')?.value;
  }

  get selectedIDs(): Array<number> {
    const items = this.form.get('items').value;
    return Object.keys(items)
      .filter((k: string) => items[k])
      .map((i) => parseInt(i));
  }

  get completedItemCount() {
    return this.previewItems?.filter((i) => i.status === TaskStatus.COMPLETED).length;
  }

  get isDisabled() {
    return !this.previewItems?.length || !this.anyItemChecked;
  }

  get formItems(): FormGroup {
    return this.form?.get('items') as FormGroup;
  }

  get tourAnchorName() {
    switch (this.type) {
      case TaskType.TYPE_NOTE:
        return 'protocolManage.s8.notes';
      case TaskType.TYPE_TASK:
        return 'protocolManage.s10.tasks';
      case TaskType.TYPE_CORRECTION:
        return 'protocolManage.s11.corrections';
      case TaskType.TYPE_CHANGE:
        return 'protocolManage.s12.changes';
      case TaskType.TYPE_ORDER:
        return 'protocolManage.s13.orders';
    }
  }

  constructor(
    private fb: FormBuilder,
    private pService: ProtocolApiService,
    public changes: ChangeDetectorRef,
    public imagePipe: AuthFilePipe,
    private s: SnackBarService,
    private t: TranslateService,
    public taskService: TaskService,
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.subscribeAllSelection();
    this.subscribeEvents();
  }

  ngAfterViewInit(): void {
    this.cleanDndPlaceholders();
  }

  cleanDndPlaceholders() {
    const sub = this.taskService.dragEnd
      .pipe(delay(10))
      .subscribe(() => this.dropzone?.cleanupDragoverState());
    this.subscription.add(sub);
  }

  trackTask(index: number, item: Task) {
    return item.id;
  }

  setItems(items: Array<Task>) {
    this.updateFormControls(items);
    this.deleteEmptyControls();
    this.previewItems = this.previewItems?.length
      ? this.previewItems.map((i) => this.getTaskPermissions(new Task(i)))
      : [];
    !this.previewItems?.length ? this.form.get('all').setValue(false) : null;
  }

  getTaskPermissions(task: Task) {
    switch (task.type) {
      case TaskType.TYPE_CLIENT_TASK:
      case TaskType.TYPE_COMPANY_TASK:
        return this.getProjectItem(task);
      default:
        return this.getProtocolItem(task);
    }
  }

  getProtocolItem(task: Task) {
    task.editPermission = {
      group: PermissionsGroups.PROTOCOLS,
      action: 'EDIT',
      objectCreatorId: [task?.creator?.id].concat(task.assignedTo.map((j) => j.id)),
    };

    task.deletePermission = {
      group: PermissionsGroups.TASKS,
      action: 'REMOVE',
      objectCreatorId: [task?.creator?.id].concat(task.assignedTo.map((j) => j.id)),
    };
    return task;
  }

  getProjectItem(task: Task) {
    task.editPermission = Object.assign({}, this.editPermission);
    task.deletePermission = Object.assign({}, this.deletePermission);
    return task;
  }

  subscribeEvents() {
    this.pService.manager.protocolAction.subscribe(($event: ProtolActionEmitter) => {
      switch ($event.type) {
        case ProtocolAction.UPDATE_CHECKBOX_LIST:
          this.updateCheckboxListByType($event.data);
          break;
        default:
          break;
      }
    });
  }

  updateCheckboxListByType(type: TaskType) {
    if (type === this.type) {
      this.unsetAllCheckboxes();
    }
  }

  checkDisabled() {
    if (this.isDisabled) {
      this.s.warn(
        this.t.instant('Protocols.checkRekord'),
        this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
      );
    }
  }

  enterList() {
    this.enteredList = true;
    this.changes.detectChanges();
  }

  exitList() {
    this.enteredList = false;
    this.changes.detectChanges();
  }

  createForm() {
    this.form = this.fb.group({
      all: false,
      items: this.fb.group({}),
    });
  }

  // #region Status

  statusChanged(status: TaskStatus) {
    this.statusEmitter({
      tasksIds: this.selectedIDs,
      status,
    });
  }

  statusEmitter(statusEvent: { tasksIds: Array<number>; status: TaskStatus; task?: Task }) {
    if (!statusEvent.tasksIds.length) {
      return;
    }
    this.taskService.groupEditStatus(statusEvent.tasksIds, statusEvent?.status, this.type).subscribe({
      next: (list) => this.successStatusChange(statusEvent, list),
      error: () => {},
    });
  }

  successStatusChange(statusEvent: { tasksIds: Array<number>; status: TaskStatus; type?: string }, tasks) {
    this.taskService.updateTaskList.emit(true);

    this.previewItems = this.previewItems.map((i) => {
      const filtered = tasks.filter((j) => i.id === j.id)[0];
      return new Task(filtered ? filtered : i);
    });

    if (statusEvent.tasksIds.length > 1) {
      let key = '';
      switch (statusEvent.status) {
        case TaskStatus.COMPLETED:
          key = 'Protocols.statusChangedMultiCompleted';
          break;
        case TaskStatus.CREATED:
          key = 'Protocols.statusChangedMultiNotCompleted';
          break;
        case TaskStatus.CANCELLED:
          key = 'Protocols.statusChangedMultiCancelled';
          break;
        default:
          break;
      }

      this.s.success(
        this.t.instant(key),
        this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
      );
    } else {
      let key = '';
      switch (statusEvent.status) {
        case TaskStatus.COMPLETED:
          key = 'Protocols.statusChangedOneCompleted';
          break;
        case TaskStatus.CREATED:
          key = 'Protocols.statusChangedOneNotCompleted';
          break;
        case TaskStatus.CANCELLED:
          key = 'Protocols.statusChangedOneNotCancelled';
          break;
        default:
          break;
      }

      this.s.success(
        this.t.instant(key),
        this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
      );
    }

    this.taskEmitter.emit({
      type: TaskAction.STATUS_POST_ACTION,
      data: {
        tasksIds: this.selectedIDs,
        status,
        type: this.type,
        list: this.previewItems,
      },
    });
  }

  // #endregion

  // #region Employee
  assignEmitter(data: {
    employee: UserPerson;
    taskIds: Array<number>;
    assignedIDs: Array<number>;
    assignedEmails?: Array<string>;
    type: TaskType;
  }) {
    if (!(data.assignedIDs.length || data.assignedEmails)) {
      return;
    }
    this.taskService
      .groupAssign(data?.taskIds, data?.assignedIDs, data?.assignedEmails)
      .subscribe((tasks: Array<Task>) => this.successAssigning(data, tasks));
  }

  successAssigning(
    employee: { employee: UserPerson; assignedIDs: Array<number>; type: TaskType },
    tasks: Array<Task>,
  ) {
    this.taskService.updateTaskList.emit(true);

    this.previewItems = this.previewItems.map((i) => {
      const filtered = tasks.filter((j) => i.id === j.id)[0];
      return new Task(filtered ? filtered : i);
    });
    this.s.success(
      this.t.instant('Protocols.userAssigned'),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    const data = Object.assign(employee, { list: this.previewItems });
    this.taskEmitter.emit({
      type: TaskAction.EMPLOYEE_ASSINGED,
      data,
    });
  }

  // #endregion

  // #region Discount
  discountChanged(discount: number) {
    this.discountAssign({ tasksIds: this.selectedIDs, discount, type: this.type });
  }

  discountAssign($event: { tasksIds: Array<number>; discount: number; type: TaskType }) {
    if (!$event.tasksIds.length) {
      return;
    }

    this.taskService
      .groupDiscount($event.tasksIds, priceToPenny($event.discount))
      .subscribe((tasks: Array<Task>) => this.successDiscountUpdate(tasks, $event.type));
  }

  successDiscountUpdate(tasks: Array<Task>, type: TaskType) {
    this.previewItems = this.previewItems.map((i) => {
      const filtered = tasks.filter((j) => i.id === j.id)[0];
      return new Task(filtered ? filtered : i);
    });

    this.s.success(
      this.t.instant('Protocols.discountAssigned'),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.taskEmitter.emit({
      type: TaskAction.DISCOUNT_CHANGED,
      data: { list: this.previewItems },
    });
  }

  // #endregion

  // #region Term
  rangeChanged($event: { start: moment.Moment; end: moment.Moment }) {
    this.termAssign({ tasksIds: this.selectedIDs, dates: $event, type: this.type });
  }

  termAssign($event: {
    tasksIds: Array<number>;
    dates: {
      start: moment.Moment;
      end: moment.Moment;
    };
    type: TaskType;
  }) {
    if (!$event.tasksIds.length) {
      return;
    }

    this.taskService
      .groupTerm(
        $event.tasksIds,
        $event.dates.start.format(Config.DATE_SERVER),
        $event.dates.end.format(Config.DATE_SERVER),
      )
      .subscribe((tasks: Array<Task>) => this.successTermUpdate(tasks, $event.type));
  }

  successTermUpdate(tasks: Array<Task>, type: TaskType) {
    this.taskService.updateTaskList.emit(true);
    this.previewItems = this.previewItems.map((i) => {
      const filtered = tasks.filter((j) => i.id === j.id)[0];
      return new Task(filtered ? filtered : i);
    });

    this.s.success(
      this.t.instant('Protocols.termAssigned'),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.taskEmitter.emit({
      type: TaskAction.TERM_CHANGED,
      data: { list: this.previewItems },
    });
    this.changes.detectChanges();
  }

  // #endregion

  updateFormControls(items: Array<Task>) {
    if (this.hideHiddenFromClient) {
      items = items.filter((i) => !i.hiddenForClient);
    }
    this.previewItems = items?.map((i: Task) => {
      const id = i?.id?.toString();
      const control = this.formItems?.get(id);
      i.selected =
        i.type === TaskType.TYPE_NOTE ? !!(i.status === TaskStatus.COMPLETED) : control?.value || false;
      const c = new FormControl(i.selected);
      control ? control.setValue(i.selected) : this.formItems?.addControl(id, c);
      return i;
    });
  }

  deleteEmptyControls() {
    if (!this.formItems) {
      return;
    }
    Object.keys(this.formItems?.value)?.forEach((key: string) => {
      const index = this.previewItems.findIndex((i) => i.id.toString() === key.toString());
      index === -1 ? this.formItems.removeControl(key.toString()) : null;
    });
  }

  confirmSelection(event) {
    event.clickEvent.stopPropagation();
    this.form.get('all').setValue(true);
    this.noteStatusCheck(TaskStatus.COMPLETED);
    this.changes.detectChanges();
  }

  noteStatusCheck(state) {
    if (this.type === TaskType.TYPE_NOTE) {
      const items = [];
      this.previewItems.forEach((t) => (this.editPermissionCheck(t) ? items.push(t) : null));
      this.statusEmitter({
        tasksIds: items.map((i) => i.id),
        status: state,
      });
    }
  }

  editPermissionCheck(task: Task) {
    return task.editPermission ? !!new CheckPermission(task.editPermission).check() : true;
  }

  dropEvent(e: DndDropEvent, taskList: TaskListItem) {
    const event = e as CustomDndDropEventInterface;
    if (!taskList || event.data.task.type === taskList.type) {
      if (event.index > event.data.previousIndex) {
        event.index--;
      }
      if (event.index === event.data.previousIndex) {
        return;
      }
    }
    event.newTaskList = taskList;

    if (!taskList || event.data.task.type === event.newTaskList.type) {
      this.moveTaskInTheSameList(event);
    } else {
      this.moveTaskToAnotherList(event);
    }

    this.taskService.dragEnd.emit(true);
  }

  moveTaskInTheSameList(event: CustomDndDropEventInterface) {
    if (this.taskList) {
      this.taskList.items = arrMove(this.taskList.items, event.data.previousIndex, event.index);
      this.updateSortOrders(event);
    } else {
      this.items = arrMove(this.items, event.data.previousIndex, event.index).map(
        (t: Task, index: number) => {
          t.sortOrder = index + 1;
          return t;
        },
      );
      this.updateTaskSortOrder(event.data.task, event.index + 1);
    }
  }

  moveTaskToAnotherList(event: CustomDndDropEventInterface) {
    this.pService.manager.taskList.map((taskList: TaskListItem) => {
      if (taskList.type === event.data.task.type) {
        taskList.items = taskList.items.filter((t) => t.id !== event.data.task.id);
        taskList.items.map((t: Task, index: number) => {
          t.sortOrder = index + 1;
          return t;
        });
      }
      return taskList;
    });
    event.data.task.type = event.newTaskList.type;
    this.taskList.items.splice(event.index, 0, new Task(event.data.task));
    this.updateSortOrders(event);
  }

  updateSortOrders(event: CustomDndDropEventInterface) {
    this.taskList.items.map((t: Task, index: number) => {
      t.sortOrder = index + 1;
      return t;
    });
    this.updateTaskSortOrder(event.data.task, event.index + 1);
    this.items = this.taskList.items;
  }

  updateTaskSortOrder(task: Task, newPosition: number) {
    this.pService.reorderTask(newPosition, task).toPromise();
  }

  cancelSelection(event) {
    event.clickEvent.stopPropagation();
    this.changes.detectChanges();
  }

  subscribeAllSelection() {
    this.subscribeItemsChange();
    this.subscribeAllOptionChange();
  }

  private subscribeAllOptionChange() {
    this.form.get('all').valueChanges.subscribe((state: boolean) => {
      if (this.changingState) {
        return;
      }
      if (!!state) {
        this.opened = !!state;
        this.taskEmitter.emit({
          type: TaskAction.OPEN_POST_ACTION,
          data: this.opened,
        });
      } else {
        this.noteStatusCheck(state ? TaskStatus.COMPLETED : TaskStatus.CREATED);
      }

      Object.keys(this.formItems.controls).forEach((key: string) => {
        const task: Task = this.previewItems.filter((i) => i.id.toString() === key)[0];
        if (task) {
          this.editPermissionCheck(task) ? this.formItems.controls[key].setValue(state) : null;
        }
      });
      this.changes.detectChanges();
    });
  }

  private subscribeItemsChange() {
    this.formItems.valueChanges.subscribe((values) => {
      const _values = this.getPermissionCheckedValues(values);
      this.changingState = true;
      if (!!Object.values(_values).length) {
        this.anyItemChecked = Object.values(_values).indexOf(true) > -1;
        if (Object.values(_values).indexOf(false) > -1) {
          !!this.form.get('all').value ? this.form.get('all').setValue(false) : null;
        } else {
          !this.form.get('all').value ? this.form.get('all').setValue(true) : null;
        }
      }
      this.changingState = false;
      this.changes.detectChanges();
    });
  }

  getPermissionCheckedValues(values: { [key: string]: boolean }): { [key: string]: boolean } {
    let _values = {};
    Object.keys(values).forEach((key: string) => {
      const task = this.previewItems.filter((i) => i.id.toString() === key)[0];
      if (task) {
        this.editPermissionCheck(task) ? (_values[key] = values[key]) : null;
      }
    });
    return _values;
  }

  taskEmitterChange($event: TaskEmitter) {
    switch ($event.type) {
      case TaskAction.STATUS_POST_ACTION:
        this.changeTaskSelection($event.data);
        break;
      case TaskAction.TYPE_CHANGE_ACTION:
        this.changeType($event.data);
        break;
      case TaskAction.DELETE_POST_ACTION:
        this.deleteTask($event.data);
        break;
      case TaskAction.STARTED_SAVING:
      case TaskAction.COMPLETED_SAVING:
        this.taskEmitter.emit({ type: $event.type, data: {} });
        break;
      case TaskAction.EDIT_TASK:
        this.editTaskEvent($event);
        break;
      default:
        break;
    }
  }

  editTaskEvent($event) {
    const task: Task = $event.data.task;
    this.editTaskFromList($event);

    this.taskEmitter.emit({
      type: $event.type,
      data: { list: this.previewItems, task, taskType: $event?.data?.taskType },
    });
  }

  editTaskFromList($event) {
    const task: Task = $event.data.task;
    const findIndex = this.previewItems.findIndex((i) => i.id === task.id);

    if (findIndex > -1) {
      if (task?.type !== $event?.data?.taskType) {
        this.previewItems.splice(findIndex, 1);
      } else {
        this.previewItems[findIndex] = task;
      }
    }
  }

  changeTaskSelection(task: Task) {
    this.editTaskFromList({ data: { task, taskType: task.type } });
    this.statusEmitter({
      tasksIds: [task.id],
      status: task.status,
    });
  }

  changeValue(event) {
    event.stopPropagation();
    this.changes.detectChanges();
  }

  openTaskAdd() {
    this.plusClicked.emit();
    this.pService.manager.protocolAction.emit({ type: ProtocolAction.OPEN_TASK_ADD, data: this.type });
    this.changes.detectChanges();
  }

  changeType(event: { item: Task; type: TaskType }) {
    this.taskEmitter.emit({
      type: TaskAction.TYPE_CHANGE_ACTION,
      data: { item: event.item, type: event.type },
    });
    this.changes.detectChanges();
  }

  deleteTask(item: Task) {
    const basicDeletePermissions = {
      group: PermissionsGroups.TASKS,
      action: 'REMOVE',
      objectCreatorId: [item.creator.id].concat(item.assignedTo.map((u) => u.id)),
    };
    const ctrl = new CheckPermission(this.deletePermission ? this.deletePermission : basicDeletePermissions);

    if (!ctrl.check()) {
      return;
    }

    this.startDeleteProccess(item);
    this.changes.detectChanges();
  }

  startDeleteProccess(task: Task) {
    this.taskService.deleteTask(task.id).subscribe(() => this.successDeleteTask(task));
  }

  successDeleteTask(task: Task) {
    this.taskService.updateTaskList.emit(true);
    this.removeTaskFromList(task);
    this.formItems.removeControl(task.id.toString());
    this.taskEmitter.emit({
      type: TaskAction.DELETE_POST_ACTION,
      data: { task, list: this.previewItems },
    });
    this.s.success(
      this.t.instant(`Tasks.${task.type}Deleted`),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.changes.detectChanges();
  }

  removeTaskFromList(task: Task) {
    const index = this.previewItems.findIndex((i) => i.id === task.id);
    index > -1 ? this.previewItems.splice(index, 1) : null;
    this.previewItems = this.previewItems.map((task: Task, i: number) => {
      i > index ? (task.sortOrder -= 1) : null;
      return task;
    });
  }

  checkDragPermission(task: Task) {
    const ctrl = new CheckPermission(task.editPermission);
    return task.editPermission ? ctrl.check() : true;
  }

  isItemTrue(id: string) {
    return !!this.form?.get('items')?.get(id)?.value;
  }

  getFile(url: string) {
    return privateFile(url);
  }

  changeEmployee(data: { user?: UserPerson; assignedEmails?: any[]; assignedIds?: any[] }) {
    this.assignEmitter({
      employee: data.user,
      taskIds: this.selectedIDs,
      assignedIDs: data.assignedIds,
      assignedEmails: data.assignedEmails,
      type: this.type,
    });
  }

  unsetAllCheckboxes() {
    const controls = (this.form?.get('items') as FormGroup).controls;
    Object.keys(controls).forEach((key: string) => {
      (this.form?.get('items') as FormGroup).get(key).setValue(false);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
