<div class="summary-box" [ngClass]="{ 'summary-box--closed': !opened }">
  <div (click)="opened = !opened; this.saveSummaryStateToStorage()" class="summary-box__title">
    <span class="summary-box__title-toggler">
      <img draggable="false" src="./assets/img/ic-toggle-blue.svg" alt="toggle" />
    </span>
    <div class="summary-box__title-text">{{ summaryConfig?.title | translate }}</div>
  </div>
  <div *ngIf="opened" [@fadeInOnEnter]="opened">
    <summary-list [items]="summaryConfig?.items"></summary-list>
  </div>
</div>
