import { Attachment } from '@shared/interfaces/attachment.interface';
import { Employee } from '@shared/models/employee.model';

export class Commentary {
  id: number;
  attachments: Attachment[];
  created: string;
  modified: string;
  deleted?: string;
  creator: Employee;
  childrenComments: Commentary[];
  content: string;
  fileAttachments?: Attachment[] = [];
  imgAttachments?: Attachment[] = [];

  constructor(commentary?: Commentary) {
    commentary ? this.deserialize(commentary) : null;
  }

  deserialize(commentary: Commentary) {
    Object.assign(this, commentary);
    this.fileAttachments = this.attachments?.filter((i) => i.type.indexOf('image') === -1) || [];
    this.imgAttachments = this.attachments?.filter((i) => i.type.indexOf('image') > -1) || [];

    if (commentary.creator) {
      this.creator = new Employee(commentary.creator);
    }

    if (commentary?.childrenComments?.length) {
      this.childrenComments = commentary?.childrenComments.map((c) => new Commentary(c));
    }
  }
}
