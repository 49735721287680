<div class="event-acceptation">
  <img draggable="false" class="event-acceptation-bg bottom" src="./assets/img/event-left-bottom.svg" alt="bottom" />
  <img draggable="false" class="event-acceptation-bg top" src="./assets/img/event-right-top.svg" alt="top" />
  <div class="container h-100">
    <div *ngIf="event" class="event-acceptation-wrapper">
      <div class="white-board-logo">
        <img draggable="false" src="/assets/img/logo.svg" alt="intilio" />
      </div>
      <div class="event-acceptation-item-wrapper">
        <h3
          class="text-success font-weight-bolder"
          [ngClass]="{
            'text-success': event?.myParticipation?.status === EAttendance.CONFIRMED,
            'text-danger': event?.myParticipation?.status === EAttendance.REJECTED
          }"
        >
          {{ 'Calendar.ATTENDEE.' + event?.myParticipation?.status | translate }}
        </h3>

        <div class="event-acceptation-item">
          <div class="event-acceptation-item__text mb-2">
            {{ event?.name }}
          </div>
          <div class="event-acceptation-item__date mb-1 text-capitalize">
            {{ event?.termStart | rangeFormat: event?.termEnd }}
          </div>

          <div class="mb-1" *ngIf="event?.project">
            <div>
              <b>{{ 'CalendarEvents.project' | translate }}:</b>
            </div>
            <div
              (click)="previewProject()"
              class="calendar-event-popover__content-text text-primary pointer"
              [title]="event?.project?.fullName"
            >
              {{ event?.project?.fullName }}
            </div>
          </div>

          <div *ngIf="event?.creator?.userPerson" class="event-acceptation-item__creator mb-1">
            {{ 'CalendarEvents.creator' | translate }}
            <b class="text-dark"> {{ event?.creator?.userPerson?.fullName }}</b>
          </div>

          <div class="event-acceptation-item__address">
            {{ event?.address }}
          </div>

          <div class="pt-2">
            <app-button
              [class]="'mr-2 bold'"
              [disabled]="pending"
              (onClick)="eventRejection()"
              [config]="{
                text: 'Protocols.QuickPreview.decline' | translate,
                size: ButtonSize.SMALL,
                type: ButtonTypes.SECONDARY_RED,
                prependSrc: './assets/img/ic-close.svg'
              }"
            ></app-button>

            <app-button
              [class]="'bold'"
              [disabled]="pending"
              (onClick)="eventAcceptation()"
              [config]="{
                text: 'Protocols.QuickPreview.accept' | translate,
                size: ButtonSize.SMALL,
                type: ButtonTypes.PRIMARY_GREEN,
                prependSrc: './assets/img/ic-tick.svg'
              }"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
