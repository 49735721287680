import { Observable } from 'rxjs';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';
import { Config } from '@shared/configs/config';
import { Company } from '@shared/models/company.model';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseHttpService {
  constructor() {
    super();
  }

  createCompany(): Observable<Company> {
    const url = `${Config.API}/company/create`;
    return this.post(url, {});
  }
}
