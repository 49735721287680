import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseRemoveModalComponent } from '@shared/components/base-remove-modal/base-remove-modal.component';
import { Config } from '@shared/configs/config';
import { AppInjector } from '@shared/services/app-injector.service';
import { tap } from 'rxjs/operators';

export class RemoveModalController {
  injector: Injector;
  dialog: MatDialog;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.dialog = this.injector.get(MatDialog);
  }

  remove(confirm: () => void = () => {}, cancel: () => void = () => {}, data: any = {}) {
    const ref = this.dialog.open(BaseRemoveModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data,
    });
    return ref.afterClosed().pipe(
      tap((state: boolean | undefined) => {
        console.log(state);
        if (!!state) {
          confirm ? confirm() : null;
        } else {
          cancel ? cancel() : null;
        }
      }),
    );
  }
}
