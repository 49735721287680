import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Notification } from '../../models/notification.model';
import { NotificationChangeEventType, NotificationsApiService } from '../../services/notifications-api.service';
import {NotificationType} from "@modules/notification/shared/models/notification-type";
import {ProtocolType} from "@shared/enums/protocol-type.enum";
import {NotificationsService} from "@modules/notification/shared/services/notifications.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy {

  static readonly componentName: string = 'NotificationsComponent';

  notifications: Notification<any>[] = [];
  page: number = 1;
  loading: boolean = true;
  errorGettingNotifications: boolean = false;
  sub: Subscription;

  constructor(private service: NotificationsApiService, private changes: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getNotifications();
    this.listenNewNotifications();
  }

  showAll() {
    this.service.showAll().subscribe();
    this.notifications.map((notification: Notification<any>) => {
      notification.shown = true;
    });
    const tempArr = [...this.notifications];
    this.notifications = [];
    this.changes.detectChanges();
    this.notifications = tempArr;
    this.changes.detectChanges();
    this.service.changeEmitter.emit({type: NotificationChangeEventType.CLEAR_COUNTER});
  }

  reload() {
    this.page = 1;
    this.getNotifications();
  }

  listenNewNotifications() {
    this.sub = this.service.changeEmitter.subscribe((event: { type: NotificationChangeEventType }) => {
      switch (event.type) {
        case NotificationChangeEventType.LOAD_NEW_NOTIFICATIONS:
          this.getNewNotifications();
          break;
      }
    });
  }

  getNewNotifications() {
    this.service.getNotifications(1).subscribe({
      next: (res: { records: Notification<any>[] }) => {
        this.setNewNotifications(res.records);
      },
    });
  }

  /**
   * @description Check first page of notifications and if exists some new notifications, then add them on the beginning of this.notifications list
   * @author Robert Juszczyk
   * @param {Notification<any>[]} notifications
   * @memberof NotificationsComponent
   */
  setNewNotifications(notifications: Notification<any>[]) {
    const newNotifications: Notification<any>[] = [];

    mainIterate:
    for (let i = 0; i <= notifications.length - 1; i++) {
      const notification = notifications[i];

      for (let j = 0; j <= this.notifications.length - 1; j++) {
        const currentNotification = this.notifications[j];
        if (currentNotification.id === notification.id) { // if some new notification exists, then do not iterate for the next notifications
          break mainIterate;
        } else {
          newNotifications.push(notification);
          break;
        }
      }

    }

    this.notifications = newNotifications.concat(this.notifications);
    this.changes.detectChanges();
  }

  getNotifications() {
    this.loading = true;
    this.service.getNotifications(this.page).subscribe({
      next: (res: { records: Notification<any>[] }) => {
        if (this.page > 1) {
          this.notifications.push(...res.records);
        } else {
          this.notifications = res.records;
        }
        this.errorGettingNotifications = false;
      },
      error: () => {
        this.errorGettingNotifications = true;
      }
    }).add(() => {
      this.loading = false;
      this.changes.detectChanges();
    });
  }

  onScroll() {
    this.page++;
    this.getNotifications();
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

}
