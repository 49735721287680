export enum EMailingGroup {
  GROUP_RECOMMENDATION = 'recommendation',
  GROUP_NEW_CONTACT = 'new-contact',
  GROUP_MEETING = 'meeting',
  GROUP_ESTIMATION = 'estimation',
  GROUP_DOCUMENTS = 'documents',
  GROUP_NEGOTIATIONS = 'negotiations',
  GROUP_REALIZATION = 'realization',
  GROUP_FINISHED = 'finished',
  GROUP_COMPLAINT = 'complaint',
}
