import { Task } from '@shared/models/task.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'task-share-modal',
  templateUrl: './task-share-modal.component.html',
})
export class TaskShareModalComponent {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task;
    },
    private changes: ChangeDetectorRef,
    public dialogRef: MatDialogRef<TaskShareModalComponent>,
  ) {}

  confirm() {
    this.dialogRef.close({ accepted: true });
  }
}
