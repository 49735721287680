<h2 mat-dialog-title>{{ data?.title | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{ data?.text | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="between">
  <button class="btn btn-link-primary mr-3" mat-button [mat-dialog-close]="IUnsavedStates.DISCARD">
    {{ data?.cancel | translate }}
  </button>
  <div class="d-inline-flex align-items-center flex-wrap">
    <button class="btn btn-light btn-text-primary mr-3" mat-button [mat-dialog-close]="IUnsavedStates.UNLOAD">
      {{ data?.unload | translate }}
    </button>
    <button class="btn btn-primary" mat-button [mat-dialog-close]="IUnsavedStates.SAVE_UNLOAD">
      {{ data?.saveUnload | translate }}
    </button>
  </div>
</mat-dialog-actions>
