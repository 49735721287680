import { EstimatedBudget } from './../../../enums/estimated-budget-type.enum';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ElectricityType } from '@modules/projects/shared/enums/electricity-type.enum';
import { HeatingType } from '@modules/projects/shared/enums/heating-type.enum';
import { OwnershipType } from '@modules/projects/shared/enums/ownership-type.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { TranslateService } from '@ngx-translate/core';
import { getObjectTranslate } from '@shared/helpers/translate-herper';
import { PROJECT_DATA_FORM_BOX } from '../../project-question-directory-first/consts/project-data-form-box';
import { PropertyType } from '@modules/projects/shared/enums/property-type.enum';

@Component({
  selector: 'project-question-directory-first-info-box',
  templateUrl: './project-question-directory-first-info-box.component.html',
  styleUrls: ['./project-question-directory-first-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectQuestionDirectoryFirstInfoBoxComponent implements OnInit {
  _project: Project;

  additionalProperties: { [key: string]: any } = {
    hasParking: {
      value: false,
      title: '',
      comment: PROJECT_DATA_FORM_BOX.parkingDescription,
    },
    hasBasement: {
      value: false,
      title: '',
      comment: PROJECT_DATA_FORM_BOX.basementDescription,
    },
    hasElevator: {
      value: false,
      title: '',
      comment: PROJECT_DATA_FORM_BOX.elevatorDescription,
    },
  };

  @Input()
  set project(_project: Project) {
    this._project = _project;
    this.translateKeys();
    this.setProjectAdditionalProperties();
  }

  get project() {
    return this._project;
  }

  get isWholeBuildingUsage() {
    const value = this.project?.basicDataBox?.propertyType;
    return value === PropertyType.SINGLE_FAMILY || value === PropertyType.TOWNHOUSE;
  }

  constructor(private t: TranslateService) {}

  ngOnInit(): void {}

  setProjectAdditionalProperties() {
    Object.keys(this.project.basicDataBox).forEach((key: string) => {
      const param = this.additionalProperties[key];
      if (!!param) {
        param.value = this.project.basicDataBox[key];
        param.title = this.getAdditionalText(!!param.value ? 'Projects.yes' : 'Projects.no', param);
      }
    });
  }

  getAdditionalText(text: string, param: any) {
    let _text = this.t.instant(text);
    if (this.project.basicDataBox[param.comment]) {
      _text = this.t.instant(text) + `, ` + this.project.basicDataBox[param.comment];
    }
    return _text;
  }

  translateKeys() {
    const heating = getObjectTranslate(
      HeatingType,
      this.project.basicDataBox.heatingType,
      'Projects.Heating.',
    );
    const electricity = getObjectTranslate(
      ElectricityType,
      this.project.basicDataBox.electricityType,
      'Projects.Electricity.',
    );

    const ownership = getObjectTranslate(
      OwnershipType,
      this.project.basicDataBox.ownershipType,
      'Projects.Ownership.',
    );
    const budget = getObjectTranslate(EstimatedBudget, this.project.basicDataBox.budget, 'Projects.Budget.');

    this.project.basicDataBox.heatingType = heating ? this.t.instant(heating) : null;
    this.project.basicDataBox.electricityType = electricity ? this.t.instant(electricity) : null;
    this.project.basicDataBox.ownershipType = ownership ? this.t.instant(ownership) : null;
    this.project.basicDataBox.budget = budget ? this.t.instant(budget) : null;
  }
}
