<div class="contact-quick-information" [ngClass]="class">
  <div class="contact-quick-information-field">{{ contact.fullName }}</div>

  <div class="contact-quick-information-field" *ngIf="contact.email">
    <a draggable="false" href="mailto:{{ contact.email }}">{{ contact.email }}</a>
  </div>

  <div class="contact-quick-information-field" *ngIf="contact.phone">
    <a draggable="false" href="tel:{{ formattedPhone }}">{{ formattedPhone }}</a>
  </div>
</div>
