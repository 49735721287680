import { CheckPermission } from '@core/permissions/check-permission';
import { NavigateService } from '@shared/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Injectable({ providedIn: 'root' })
export class ProjectSecondBoxEditGuard implements CanActivate {
  constructor(
    private n: NavigateService,
    private projectAPI: ProjectAPIService,
    private projectStore: ProjectStoreService,
    private t: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'PROJECT_BOX_SECOND_EDIT',
      objectCreatorId: [this.projectStore.projectCtrl.project?.basicDataBox?.responsibleEmployee?.id],
    });
    if (ctrl.check()) {
      return true;
    }
    this.n.go('projects-list');
    return false;
  }
}
