import {
  Component,
  Input,
  ViewRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GbxsoftInputConfig } from './interfaces/gbxsoft-input.interface';
import { GbxsoftBaseFormComponent } from '../gbxsoft-base.component';
import { GbxsoftValueAccessor, GbxsoftValidatorAccessor } from '../gbxsoft-accessors';
import { GbxsoftInputTypes } from './gbxsoft-input.types';
import { GbxsoftDatepickerController } from './controllers/gbxsoft-datepicker.controller';

@Component({
  selector: 'gbxsoft-input',
  templateUrl: './gbxsoft-input.component.html',
  styleUrls: ['./gbxsoft-input.component.scss'],
  providers: [GbxsoftValueAccessor(GbxsoftInputComponent), GbxsoftValidatorAccessor(GbxsoftInputComponent)],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // There is an error when adding OnPush strategy,
  // formcontrol cannot be updated from parent and marked as touched
  // to be resolved in next release.
})
export class GbxsoftInputComponent extends GbxsoftBaseFormComponent implements AfterViewInit {
  _config: GbxsoftInputConfig;
  @Input() readonly: boolean = false;
  tempValue: any;

  @Input('config')
  get config() {
    return this._config;
  }

  set config(conf: GbxsoftInputConfig) {
    this._config = Object.assign(this.defaultConfig, conf);
  }

  _disabled: boolean;
  @Input('disabled')
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get isNumeric() {
    return this.pattern === 'd*';
  }

  @Input() testAttr: string;
  @Output('onKeydown') onKeydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @ViewChild('gbxsoftInput') gbxsoftInput: ElementRef;
  inputElement: HTMLInputElement;
  isFocused: boolean = false;

  private datePickerCtrl: GbxsoftDatepickerController = null;

  get isNumericField() {
    return this.config?.type === GbxsoftInputTypes.NUMBER || this.config?.type === GbxsoftInputTypes.PRICE;
  }

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  ngAfterViewInit() {
    this.initInput();
    this.applyListeners();
  }

  applyListeners() {
    this.inputElement = this.gbxsoftInput.nativeElement;
    this.inputElement.addEventListener('focus', () => this.updateFocusState());
    this.inputElement.addEventListener('input', ($event: any) =>
      this.input(this.isNumeric ? this.replaceChars($event.target.value) : $event.target.value),
    );
    this.inputElement.addEventListener('paste', ($event: any) => this.checkNumberTypeValue($event));
    this.inputElement.addEventListener('focusout', () => this.updateFocusOut());
    this.inputElement.addEventListener('blur', ($event: any) =>
      this.blur(this.isNumeric ? this.replaceChars($event.target.value) : $event.target.value),
    );
    this.detectChanges();
  }

  updateFocusState() {
    this.setNumericClear();

    this.isFocused = true;
    this.detectChanges();
  }

  updateFocusOut() {
    this.setNumericZero();
    this.detectChanges();
  }

  setNumericClear() {
    if (this.isNumeric) {
      parseFloat(this.value) === 0 ? this.errorCtrl.control.setValue('') : null;
    }
  }

  setNumericZero() {
    if (this.isNumeric && !this.config.notSetNumericZero) {
      if (this.value === '') {
        this.errorCtrl.control.setValue(this.config?.type === GbxsoftInputTypes.PRICE ? '0.00' : 0);
      } else {
        if (this.config?.type === GbxsoftInputTypes.PRICE) {
          const _value = this.value?.toString() || '0';
          const splited: any[] = this.commaToDotHelper(_value?.replace(/\s/g, '')).split('.');
          const tail = splited.length > 1 ? splited[splited.length - 1] : '00';
          if (splited.length > 1) {
            splited.splice(splited.length - 1, 1);
          }
          const value = splited.join('') + (tail ? '.' + tail : tail);
          this.errorCtrl.control.setValue(value);
        }
      }
    }
  }

  replaceChars(text: string): string {
    return text?.replace(/,/g, '.');
  }

  /**
   *Init input by it`s type
   *
   * @memberof GbxsoftInputComponent
   */
  initInput(): void {
    this.initDatePicker();
    this.detectChanges();
  }

  /**
   *Overwrite method from base component
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  writeValue(value: any) {
    this.value = value;
    this.detectChanges();
  }

  /**
   *Generate datepicker by type
   *
   * @memberof GbxsoftInputComponent
   */
  initDatePicker(): void {
    this.isDatePicker ? (this.datePickerCtrl = new GbxsoftDatepickerController(this)) : null;
  }

  /**
   *Value change on type
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  input(value: any): void {
    if (this.disabled) {
      return;
    }
    this.writeValue(value);
    // Check if FormControl is defined in parent
    this.fnOnChange ? this.fnOnChange(value) : null;
    this.detectChanges();
  }

  /**
   *Blur event on input
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  blur(value): void {
    if (this.disabled) return;
    this.isFocused = false;
    this.config?.updateOnFocusOut ? this.writeValue(value) : null;
    //Check if FormControl is defined in parent
    this.fnOnTouched ? this.fnOnTouched(value) : null;
    this.detectChanges();
  }

  handlePositiveNumber(e: any) {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    let inputData = clipboardData?.getData('Text');
    e.stopPropagation();
    e.preventDefault();

    if (/^[0-9]+$/.test(inputData)) {
      this.input(inputData);
    }
    this.detectChanges();
  }

  handleNumber(e: any) {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    let inputData = clipboardData?.getData('Text');
    const dotIndex = inputData.indexOf('.');
    const commaIndex = inputData.indexOf(',');
    if (dotIndex > -1 && commaIndex > -1) {
      inputData = inputData.replaceAll(dotIndex < commaIndex ? '.' : ',', '');
    }
    const pastedData = this.replaceChars(inputData);
    const invalid = pastedData.toString().length && !pastedData.match(/^[-]?\d+(\.\d+)?$/);
    e.stopPropagation();
    e.preventDefault();
    this.input(!invalid ? pastedData : '');
    this.detectChanges();
  }

  handlePrice(e: any) {
    let clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();

    clipboardData = e.clipboardData || window['clipboardData'];
    pastedData = clipboardData.getData('Text');

    const splited: any[] = this.dotToCommaHelper(pastedData.replace(/\s/g, '')).split(',');
    const tail = splited.length > 1 ? splited[splited.length - 1] : '00';

    if (splited.length > 1) {
      splited.splice(splited.length - 1, 1);
    }

    let value = splited.join('') + (tail ? '.' + tail : tail);

    if (!isNaN(this.commaToDotHelper(value))) {
      console.log(value);
      this.config.disallowNegative ? (value = Math.abs(Number(value)).toString()) : null;
      this.tempValue = value;
      this.writeValue(value);
      this.gbxsoftInput.nativeElement.value = value;
    }
  }

  /**
   *
   * chcek if the input is number and cut strings
   * @memberof GbxsoftInputComponent
   */
  checkNumberTypeValue(e: any) {
    if (this.disabled) {
      return;
    }
    switch (this.config.type) {
      case GbxsoftInputTypes.NON_NEGATIVE_INTEGER:
        this.handlePositiveNumber(e);
        break;
      case GbxsoftInputTypes.NUMBER:
        this.handleNumber(e);
        break;
      case GbxsoftInputTypes.PRICE:
        this.handlePrice(e);
        break;
      default:
        break;
    }
  }

  dotToCommaHelper(value) {
    const v = value?.toString().replace(/\./g, ',');
    return v;
  }

  commaToDotHelper(value) {
    const v = value?.toString().replace(/\,/g, '.');
    return v?.length === 1 && v.charAt(0) === '.' ? 0 : v;
  }

  /**
   *
   *  check if input is number and prevent non numeric keyboard events
   * @param {KeyboardEvent} e
   * @memberof GbxsoftInputComponent
   */
  keypress(e: KeyboardEvent | any) {
    if (this.isNumericField || this.config.type === GbxsoftInputTypes.NON_NEGATIVE_INTEGER) {
      e = e || window.event;
      // only positive integer
      if (this.config.type === GbxsoftInputTypes.NON_NEGATIVE_INTEGER) {
        if (e.which === 46 || e.which === 45 || e.which === 44) {
          return false;
        }
      }
      if (e.which === 45 && !!this.config.disallowNegative) {
        return false;
      }

      // const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
      if (e.which !== 44 && e.which !== 46 && e.which !== 45 && !(e.which >= 48 && e.which <= 57)) {
        return false;
      }
    }
  }

  /**
   *Get Default config
   *
   * @readonly
   * @type {GbxsoftInputConfig}
   * @memberof GbxsoftInputComponent
   */
  get defaultConfig(): GbxsoftInputConfig {
    return {
      name: '',
      type: GbxsoftInputTypes.TEXT,
      placeholder: '',
      updateOnFocusOut: false,
      autocapitalize: false,
      spellcheck: true,
      autocomplete: 'off',
    };
  }

  get placeholder() {
    if (!this.config?.showPlaceholderOnFocus)
      return this.isFocused && this.config?.placeholder ? this.config?.placeholder : '';
    return this.config?.placeholder;
  }

  get type() {
    if (this.isNumericField) {
      return GbxsoftInputTypes.TEXT;
    }
    return this.config?.type ? this.config?.type : GbxsoftInputTypes.TEXT;
  }

  get pattern() {
    if (this.isNumericField) {
      return 'd*';
    }
    return '';
  }

  /**
   *Style Input States
   *
   * @readonly
   * @memberof GbxsoftInputComponent
   */
  get inputCSS(): { [key: string]: boolean } {
    return {
      disabled: !!this.disabled,
      'has-value': this.hasValue || this.gbxsoftInput?.nativeElement?.value?.length,
      'has-error': this.errorCtrl.isError && this.errorCtrl.errorMessage ? true : false,
      'has-append': !!this.config?.appendContent,
      'has-prepend': !!this.config?.prependContent,
    };
  }

  get hasValue(): boolean {
    return this.formatedValue?.toString().length;
  }

  /**
   *Check if type of input is GbxsoftInputTypes.CALENDAR
   *
   * @readonly
   * @memberof GbxsoftInputComponent
   */
  get isDatePicker(): boolean {
    return this.config?.type === GbxsoftInputTypes.CALENDAR;
  }

  get formatedValue() {
    return this.config?.type === GbxsoftInputTypes.TEXT ? (this.value ? this.value : '') : this.value;
  }

  detectChanges() {
    !(this.changes as ViewRef).destroyed ? this.changes.detectChanges() : null;
  }
}
