import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class AttachmentService extends BaseHttpService {
  constructor() {
    super();
  }

  uploadAttachment(data, queryParams: any = {}) {
    let str = Object.entries(queryParams || {})
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    const url = `${Config.API}/attachment${str?.length ? '?' + str : ''}`;
    return this.post(url, data, false, true);
  }

  removeAttachment(id: number) {
    const url = `${Config.API}/attachment/${id}`;
    return this.delete(url);
  }

  removeAttachmentGroup(ids: number[]) {
    const url = `${Config.API}/attachment/delete-multiple`;
    return this.post(url, { ids });
  }

  getAttachmentZip(files: string[]) {
    const query = new URLSearchParams();
    files.forEach((file, i) => query.append(`files[${i}]`, file));
    const getUrl = decodeURIComponent(query.toString());
    const url = `${Config.API}/attachment/zip?${getUrl}`;
    return this.getFileBlob(url, false);
  }

  restoreAttachment(id: number) {
    const url = `${Config.API}/attachment/${id}/restore`;
    return this.post(url, {}, false, true);
  }
}
