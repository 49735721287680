<div class="register-dialog">
  <modal-title [title]="'Auth.Confirmation.welcome' | translate"></modal-title>
  <mat-dialog-content>
    <form [formGroup]="ctrl.form" class="pb-4">
      <div class="row">
        <div class="col-12 pt-2">
          <h2>{{ 'Projects.registerTitle' | translate }}</h2>
          <p>{{ 'Projects.registerSubtitle' | translate }}</p>
        </div>
        <div class="col-12">
          <gbxsoft-input
            [disabled]="!!data?.email"
            [errMessages]="ctrl.errorMessages(ctrl.REGISTER_FORM.email)"
            class="email-control special-control required"
            [formControlName]="ctrl.REGISTER_FORM.email"
            [config]="{ name: 'Auth.Login.email' | translate, type: 'email', placeholder: '' }"
          >
          </gbxsoft-input>
        </div>
        <div class="col-12">
          <gbxsoft-input
            [errMessages]="ctrl.errorMessages(ctrl.REGISTER_FORM.password)"
            class="password-control special-control required"
            [formControlName]="ctrl.REGISTER_FORM.password"
            [config]="{ name: 'Auth.Login.password' | translate, type: 'password', placeholder: '' }"
          >
          </gbxsoft-input>
        </div>
      </div>

      <div>
        <div class="grey-1 mb-1" [innerHTML]="'Auth.Register.acceptance' | translate"></div>
        <a
          draggable="false"
          class="blue-300 mb-1"
          [href]="'/assets/pdf/regulations.pdf'"
          target="_blank"
          [innerHTML]="'Auth.Register.regulations' | translate"
        ></a>
        <br />
        <a
          draggable="false"
          class="blue-300"
          [href]="'/assets/pdf/privacy.pdf'"
          target="_blank"
          [innerHTML]="'Auth.Register.privacy' | translate"
        ></a>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-button
      (onClick)="dialogRef.close()"
      [class]="'mr-2 bold'"
      [config]="{
        text: 'ProjectChanges.Summary.cancel' | translate,
        type: ButtonTypes.DISCARD_BUTTON,
        size: ButtonSize.LARGE
      }"
    ></app-button>
    <app-button
      [disabled]="ctrl.loading"
      [loading]="ctrl.loading"
      (onClick)="ctrl.submitSignUp({ shareChangesToken: this.data.token })"
      [config]="{
        text: 'Auth.Login.createAccountModal' | translate,
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-save.svg'
      }"
    ></app-button>
  </mat-dialog-actions>
</div>
