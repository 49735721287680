import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Component({
  selector: 'contact-option-multiple-template',
  templateUrl: './contact-option-multiple-template.component.html',
  styleUrls: ['./contact-option-multiple-template.component.scss']
})
export class ContactOptionMultipleTemplateComponent implements OnInit {
  private _contact: Contact;
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set contact(contact) {
    this._contact = new Contact(contact);
  }

  get contact() {
    return this._contact;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onClear() {
    this.clear.emit(true);
  }
}
