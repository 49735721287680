<content-title [title]="'Help.Faq.Titles.' + faqContentType | translate" [noDivider]="true"></content-title>

<mat-accordion>
  <mat-expansion-panel
    hideToggle
    *ngFor="let faq of 'Help.Faq.Content.' + faqContentType | translate: { host: 'asd' }"
  >
    <mat-expansion-panel-header class="d-flex justify-content-between align-items-center">
      <mat-panel-title [innerHTML]="faq.title"></mat-panel-title>
      <plus-icon-button></plus-icon-button>
    </mat-expansion-panel-header>
    <p [innerHTML]="replaceTags(faq.description)"></p>
  </mat-expansion-panel>
</mat-accordion>
