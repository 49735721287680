import { StorageService } from '@core/services/storage.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-changes-info',
  templateUrl: './project-changes-info.component.html',
  styleUrls: ['./project-changes-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectChangesInfoComponent implements OnInit {
  @Input() changesSummary: any;

  get currency() {
    return this.storage?.Company?.currency;
  }

  constructor(private storage: StorageService) {}

  ngOnInit() {}
}
