export enum EProjectCreatorFourthStepForm {
  type = 'type',
  draft = 'draft',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  phoneCountry = 'phoneCountry',
  email = 'email',
  address = 'address',
  postalCode = 'postalCode',
  town = 'town',
  companyName = 'companyName',
  taxNumber = 'taxNumber',
  taxNumberEU = 'taxNumberEU',
  additionalPhone = 'additionalPhone',
  additionalPhoneCountry = 'additionalPhoneCountry',
  correspondenceAddress = 'correspondenceAddress',
  correspondenceTown = 'correspondenceTown',
  correspondencePostalCode = 'correspondencePostalCode',
  optimalContactTimeType = 'optimalContactTimeType',
  optimalContactTimeAt = 'optimalContactTimeAt',
  rodo = 'rodo',
  privacy = 'privacy',
  addressType = 'addressType',
  function = 'function',

  additionalLastName = 'additionalLastName',
  additionalCompanyName = 'additionalCompanyName',
}
