import {Component, OnInit} from '@angular/core';
import {NgxmTourService} from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/ngx-md-menu-tour.service';

@Component({
  selector: 'custom-tour-step',
  templateUrl: './custom-tour-step.component.html',
  styleUrls: ['./custom-tour-step.component.scss']
})
export class CustomTourStepComponent implements OnInit {

  constructor(
    public tourService: NgxmTourService) {
  }

  ngOnInit(): void {
  }

}
