<div class="custom-pdf-viewer">
  <!-- <div *ngIf="loaded" class="d-flex align-items-center custom-pdf-viewer__header">
    <button [disabled]="zoom >= 2" class="custom-pdf-viewer-btn" (click)="zoomIn()">+</button>
    <button [disabled]="zoom === 1" class="custom-pdf-viewer-btn" (click)="zoomOut()">-</button>
    <button class="custom-pdf-viewer-btn" (click)="resetZoom()">
      <img draggable="false" src="./assets/img/ic-cycle-back.svg" alt="reset" />
    </button>
  </div>
  <pdf-viewer
    #pdf
    [src]="content"
    [render-text]="renderText"
    [stick-to-page]="stickToPage"
    [fit-to-page]="fitToPage"
    [autoresize]="autoresize"
    [zoom]="zoom"
    (after-load-complete)="afterLoadComplete()"
  ></pdf-viewer> -->

  <ngx-extended-pdf-viewer [src]="content" useBrowserLocale="true" height="100%"></ngx-extended-pdf-viewer>
</div>
