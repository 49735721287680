import { LanguageService } from '@core/services/language.service';
import { JWT } from '@shared/interfaces/jwt.interface';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { BaseComponent } from '@shared/components/base.component';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { ActivatedRoute } from '@angular/router';
import { userCodes } from '@shared/consts/user-codes';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { RedirectHelper } from '@shared/helpers/redirect-helper';

@Component({
  templateUrl: './password-set.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordSetComponent extends BaseComponent implements OnInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  form: FormGroup;

  constructor(
    private active: ActivatedRoute,
    private authService: AuthorizationService,
    private changes: ChangeDetectorRef,
    private langService: LanguageService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        passwordRepeat: new FormControl('', [Validators.required, Validators.minLength(6)]),
        token: new FormControl(''),
      },
      { validators: [this.passwordRepeat] },
    );
    this.changes.detectChanges();
  }

  submitSetPassword() {
    this.form.get('token').setValue(this.active?.snapshot?.params?.token);
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    this.changes.detectChanges();

    if (this.form.invalid || this.loading) return;
    this.loading = true;
    this.changes.detectChanges();

    this.authService
      .setNewPassword(this.form.value)
      .subscribe({
        next: (jwt?: JWT) => this.successSetPassword(jwt),
        error: (err: HttpError) => this.errorSetPassword(err),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successSetPassword(_jwt?: JWT) {
    const jwt = _jwt;
    this.store.logout();
    setTimeout(() => (!!jwt ? this.setAuthorizationData(jwt) : this.ignoreAuthorization()), 100);
  }

  setAuthorizationData(jwt?: JWT) {
    this.store.setSignedIn(jwt);
    setTimeout(() => this.initLanguageAfterAuth(jwt), 10);
  }

  initLanguageAfterAuth(jwt?: JWT) {
    this.langService
      .useLanguage(jwt.userPerson.language)
      .subscribe()
      .add(() => {
        this.s.success(this.t.instant('Auth.Messages.successSignIn'));
        const redirect = this.active?.snapshot?.queryParams?.redirectTo;
        const exclude = ['sign-in'];

        if (!!redirect && exclude.indexOf(redirect) === -1) {
          const routeData = RedirectHelper.getRouteData(redirect);
          this.router.navigate([routeData?.link], { queryParams: routeData?.queryParams });
        } else {
          this.n.navigate('company-selection');
        }

        this.changes.detectChanges();
      });
  }

  ignoreAuthorization() {
    this.s.success(this.t.instant('Auth.Messages.passwordSet'));
    this.n.navigate('login');
  }

  errorSetPassword(err: HttpError) {
    switch (err.messageCode) {
      case userCodes.TOKEN_NOT_EXISTS:
        this.s.error(this.t.instant('Auth.Errors.tokenError'));
        break;
      case IntilioCodes.SIMPLE_PASSWORD:
        this.form.get('password').setValue(null);
        this.form.get('passwordRepeat').setValue(null);
        this.s.error(this.t.instant('Auth.Errors.passwordSimple'));
        break;
      default:
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        break;
    }
    this.changes.detectChanges();
  }

  passwordRepeat(formGroup: FormGroup) {
    const pass = formGroup?.get('password');
    const repeat = formGroup?.get('passwordRepeat');
    repeat?.value?.length && pass?.value !== repeat?.value ? repeat.setErrors({ compare: true }) : null;

    return repeat?.value?.length && pass?.value !== repeat?.value ? { compare: true } : null;
  }

  errorMessages(name: string): { [key: string]: string } {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength,
      });
    }

    if (control?.errors?.compare) {
      messages.compare = this.t.instant('FormErrors.compareError');
    }
    return messages;
  }

  discard() {
    this.n.navigate('login');
  }
}
