<div
  class="attachment-manager-list"
  [ngClass]="{ 'attachment-manager-list--disabled': disabled }"
  drag-drop
  (dragenter)="dragEnter($event)"
  (dragleave)="dragLeave()"
  (filesDropped)="dragDropped(); !disabled ? onFilesChange($event) : null"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
  #textAttachmentControl
>
  <!-- #region Upload Area -->
  <div class="dragging-file" [ngClass]="{ 'dragging-file--visible': entered }">
    <span>{{ 'Attachments.uploadFiles' | translate }}</span>
  </div>
  <!-- #endregion -->

  <!-- #region Header -->
  <div [formGroup]="form" class="d-flex justify-content-between attachment-manager-list__header mb-2">
    <div class="d-flex align-items-center attachment-manager-list__header__title">
      <div class="form-control-checkbox">
        <label *ngIf="fileAttachments?.length" class="m-0 d-flex align-items-center">
          <input type="checkbox" [formControlName]="'all'" />
          <span></span>
        </label>
      </div>
      <span class="attachment-manager-list__header__title-files">
        {{
          !!selectedFilesCount
            ? ('Attachments.selectedFiles' | translate) + selectedFilesCount
            : ('Attachments.files' | translate)
        }}</span
      >
    </div>
    <div class="d-flex align-items-center">
      <app-button
        [type]="'button'"
        [class]="'text-uppercase mr-2'"
        *ngIf="selectedFilesCount"
        (onClick)="downloadSelectedFiles()"
        [loading]="fileZipLoading"
        [disabled]="fileZipLoading"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          text: 'Attachments.downloadSelected' | translate,
          prependSrc: './assets/img/ic-download-outline-blue.svg'
        }"
      ></app-button>
      <app-button
        [type]="'button'"
        *ngIf="selectedFilesCount > 0 && !disabled"
        (click)="deleteSelectedFiles()"
        class="text-uppercase"
        [loading]="fileDeleting"
        [disabled]="fileDeleting || disabled"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_RED,
          text: 'Attachments.remove' | translate,
          prependSrc: './assets/img/ic-trash-outline-red.svg'
        }"
      ></app-button>

      <app-button
        [type]="'button'"
        *ngIf="!selectedFilesCount && !disabled"
        (onClick)="addFile()"
        class="text-uppercase add-file-btn"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          text: 'Attachments.addFiles' | translate,
          prependSrc: './assets/img/ic-file-plus.svg'
        }"
      ></app-button>
      <input type="file" #fileInput (change)="onFilesChange()" multiple [accept]="acceptFiles()" hidden />

      <button
        *ngIf="fileAttachments?.length > 0"
        class="btn-icon p-0 d-inline-flex pointer"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button (click)="sortAttachmentsByDate()" mat-menu-item>
          <span>{{ 'Attachments.sortByDate' | translate }}</span>
        </button>
        <button (click)="sortAttachmentsByName()" mat-menu-item>
          <span>{{ 'Attachments.sortByName' | translate }}</span>
        </button>
        <button
          [type]="'button'"
          *ngIf="!disabled && selectedFilesCount > 0"
          class="hover_red red-500"
          [disabled]="disabled"
          (click)="deleteSelectedFiles()"
          mat-menu-item
        >
          <span>{{ 'Attachments.deleteSelected' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Files List -->
  <ng-container *ngFor="let attachment of fileAttachments; let i = index; trackBy: trackAttachments">
    <attachment-inline
      [index]="date"
      *ngIf="(!!showLimit && i + 1 <= limit) || !showLimit || !!showAllFiles"
      [form]="form"
      [attachment]="attachment"
    ></attachment-inline>
  </ng-container>
  <!-- #endregion -->

  <div class="empty-state text-center p-4" *ngIf="!fileAttachments?.length">
    <img draggable="false" src="./assets/img/ic-empty-project-creator.svg" alt="empty" />
    <b class="bold text-center w-100 d-block">{{ 'Attachments.noFiles' | translate }}</b>
  </div>

  <app-button
    class="mt-3"
    [type]="'button'"
    *ngIf="!!showLimit && fileAttachments.length > limit"
    (onClick)="showAllFiles = !showAllFiles"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.DISCARD_BUTTON,
      text: (!showAllFiles ? 'Attachments.showAllFiles' : 'Attachments.hideFiles') | translate
    }"
  ></app-button>
</div>
