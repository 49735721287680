import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { RedirectHelper } from '@shared/helpers/redirect-helper';

@Injectable({ providedIn: 'root' })
export class CompanySettingsGuard implements CanActivate {
  constructor(
    private store: StorageService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {}

  canActivate(route?, state?) {
    if (!!this.store?.jwt && !!this.store?.UserPerson) {
      if (
        (this.store.Employee.isEmployeeOwner && !this.store.Employee.active) ||
        (!this.store.Employee.isEmployeeOwner &&
          !this.store.UserPerson.canCreateCompany &&
          this.store.Employee.active)
      ) {
        this.n.navigate('user-settings');
        return false;
      }
      return true;
    }
    this.s.error(this.t.instant('Auth.Errors.permissionsDenied'));
    this.n.navigate('login', { redirectTo: RedirectHelper.getWindowPath() });
    return false;
  }
}
