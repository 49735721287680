import { BaseComponent } from '@shared/components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';

@Component({
  selector: 'event-protocol-single',
  templateUrl: './event-protocol-single.component.html',
  styleUrls: ['./event-protocol-single.component.scss'],
})
export class EventProtocolSingleComponent extends BaseComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  @Input() item: Protocol;

  get deleteItem() {
    this.service.eventProtocolCtrl.delete(this.item);
    return this.service.updateEvent(true);
  }

  constructor(private pService: ProtocolApiService, private service: EventSidenavService) {
    super();
  }

  ngOnInit() {}

  deleteFromEvent() {
    this.deleteItem.subscribe({
      next: () => this.s.success(this.t.instant('CalendarEvents.unassingFromEventSuccess')),
    });
  }

  openEdit() {
    const protocolController = new ProtocolController(this.pService, this.item);
    protocolController.edit().then(() => this.service.closeEventSidenav());
  }

  remove() {
    const protocolController = new ProtocolController(this.pService, this.item);
    protocolController.remove();
    this.deleteItem.subscribe({
      next: () => this.s.success(this.t.instant('CalendarEvents.removedFromEventSuccess')),
    });
  }

  preview() {
    const protocolController = new ProtocolController(this.pService, this.item);
    protocolController.preview(this.employee).then(() => this.service.closeEventSidenav());
  }
}
