import { Subscription } from 'rxjs';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { CheckPermission } from '@core/permissions/check-permission';
import { TaskAction } from '@modules/protocols/shared/enums/task-action.enum';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ObjectTaskType, TaskType } from '@shared/enums/task-type.enum';
import { Task } from '@shared/models/task.model';
import { TaskModalComponent } from '@shared/modules/tasks/components/task-modal/task-modal.component';
import { StorageService } from '@core/services/storage.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Project } from '@modules/projects/shared/models/project.model';

@Component({
  selector: 'directory-first-tasks',
  templateUrl: './directory-first-tasks.component.html',
  styleUrls: ['./directory-first-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryFirstTasksComponent implements OnInit, OnDestroy {
  TaskType = TaskType;
  isPreview: boolean = true;
  editPermission: PermissionDecorator;
  subTaskSidenav: Subscription;

  get emptyTask(): any {
    return { type: TaskType.TYPE_TASK, status: TaskStatus.CREATED };
  }

  constructor(
    private dialog: MatDialog,
    public projectAPI: ProjectAPIService,
    public projectStore: ProjectStoreService,
    public storage: StorageService,
    private changes: ChangeDetectorRef,
    private taskSidenavService: TaskSidenavService,
  ) {}

  ngOnInit() {
    this.checkPermissions();
    this.subsribeTaskSidenav();
  }

  subsribeTaskSidenav() {
    this.subTaskSidenav = this.taskSidenavService.taskActionEmitter.subscribe((action) => {
      //Call API to update list
      this.projectAPI
        .findProjectByID(this.projectStore.projectCtrl.project.id)
        .subscribe((project) => {
          this.projectStore.projectCtrl.setProject(project);
          this.projectStore.setContactIds();
          this.changes.detectChanges();
        })
        .add(() => this.changes.detectChanges());
    });
  }

  checkPermissions() {
    this.editPermission = {
      group: PermissionsGroups.PROJECTS,
      action: 'PROJECT_BOX_FIRST_EDIT',
      objectCreatorId: this.projectStore.projectCtrl?.project?.projectEditIDs,
    };

    const ctrl = new CheckPermission(this.editPermission);
    this.isPreview = !ctrl.check();
  }

  taskCreate(type: TaskType) {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(this.projectStore.projectCtrl.project.id, null, null, {
      objectType: ObjectTaskType.PROJECT_BOX_1,
      type,
    });
  }

  taskCreateByType(type: TaskType, task: Task) {
    this.projectStore.projectCtrl.project.basicDataBox.addNewTask(task, 'projectTasks');
    this.changes.detectChanges();
  }

  updateListByType($event, taskType: TaskType) {
    this.projectStore.projectCtrl.project.basicDataBox.projectTasks = $event.data.list;
    this.changes.detectChanges();
  }

  taskEmitter($event, taskType: TaskType) {
    switch ($event.type) {
      case TaskAction.DELETE_POST_ACTION:
      case TaskAction.EDIT_TASK:
      case TaskAction.TERM_CHANGED:
      case TaskAction.EMPLOYEE_ASSINGED:
      case TaskAction.DROP_ACTION:
      case TaskAction.STATUS_POST_ACTION:
        this.updateListByType($event, taskType);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subTaskSidenav?.unsubscribe();
  }
}
