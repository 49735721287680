import { ISummaryConfig } from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-toggle',
  templateUrl: './summary-toggle.component.html',
  styleUrls: ['./summary-toggle.component.scss'],
})
export class SummaryToggleComponent implements OnInit {
  @Input() summary: any;
  @Input() summaryOpened: boolean = false;
  @Input() summaryConfig: ISummaryConfig = null;

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit() {}

  toggleSummary() {
    this.summaryOpened = !this.summaryOpened;
    this.changes.detectChanges();
  }
}
