<div class="ui-project__header">
  <h3 class="mb-0">{{ 'Projects.PreviewTabs.summary' | translate }}</h3>
</div>

<div class="ui-project__content pb-3">
  <div class="row">
    <div class="col-lg-6 mb-3 mb-lg-0">
      <div class="ui-card">
        <div class="mb-3 d-flex justify-content-between align-items-center">
          <h3 class="ui-card__title">{{ 'ProjectSummary.Content.projectData' | translate }}</h3>
          <svg-icon
            [permission]="{
              group: PermissionsGroups.PROJECTS,
              action: 'EDIT',
              objectCreatorId: projectPreviewService.projectEditIDs
            }"
            (click)="editProject()"
            class="ml-3 ic-edit flex-shrink"
            src="./assets/img/ic-edit-task.svg"
            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
          ></svg-icon>
        </div>

        <!--#region Address -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-map-pin.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.address' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.fullAddress">
            {{ projectPreviewService?.project?.address }}<br />
            {{ projectPreviewService?.project?.postalCode }} {{ projectPreviewService?.project?.town }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.fullAddress">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <!--#region Floor -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-building.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.floor' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.basicDataBox?.floor">
            {{ projectPreviewService?.project?.basicDataBox?.floor }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.basicDataBox?.floor">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <!--#region Name on DoorBell -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-user.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.nameOnDoorBell' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.basicDataBox?.nameOnDoorbell">
            {{ projectPreviewService?.project?.basicDataBox?.nameOnDoorbell }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.basicDataBox?.nameOnDoorbell">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <!--#region Realization -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-calendar.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.realizationTerm' | translate }}</strong>
          </div>
          <span
            *ngIf="
              projectPreviewService?.project?.basicDataBox?.termStart ||
              projectPreviewService?.project?.basicDataBox?.termEnd
            "
          >
            {{
              projectPreviewService?.project?.basicDataBox?.termStart
                | rangeFormat: projectPreviewService?.project?.basicDataBox?.termEnd
            }}
          </span>
          <span
            *ngIf="
              !(
                projectPreviewService?.project?.basicDataBox?.termStart ||
                projectPreviewService?.project?.basicDataBox?.termEnd
              )
            "
            >-</span
          >
        </project-summary-right-section-info>
        <!-- #endregion -->

        <!--#region Rodzaj projektu -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-home.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.projectType' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.basicDataBox?.propertyType">
            {{
              'Projects.PropertyType.' + projectPreviewService?.project?.basicDataBox?.propertyType
                | translate
            }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.basicDataBox?.propertyType">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <!--#region Creator -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-user.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.creator' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.creator?.userPerson?.previewName">
            {{ projectPreviewService?.project?.creator?.userPerson?.previewName }},
            {{ projectPreviewService?.project?.created | dateTimeFormat }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.creator?.userPerson?.previewName">-</span>

          <div class="mt-1">
            <strong>{{ 'ProjectSummary.Content.created' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.created">
            {{ projectPreviewService?.project?.created | dateTimeFormat }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.created">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->
        <!--#region Creator -->
        <project-summary-right-section-info
          [permission]="{ group: PermissionsGroups.PROJECTS, action: 'PROJECT_CLIENT_DATA_PREVIEW' }"
          [icon]="'/assets/img/ic-wallet.svg'"
        >
          <div>
            <strong>{{ 'ProjectSummary.Content.budget' | translate }}</strong>
          </div>
          <span *ngIf="projectPreviewService?.project?.basicDataBox?.budgetAmount">
            {{ projectPreviewService?.project?.basicDataBox?.budgetAmount | priceFormat: false:currency }}
          </span>
          <span *ngIf="!projectPreviewService?.project?.basicDataBox?.budgetAmount">-</span>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <div class="mt-3">
          <div class="d-flex mb-1">
            <svg-icon src="./assets/img/ic-user-bullets.svg"></svg-icon>
            <h4 class="ui-card__title ml-2">
              {{ 'ProjectSummary.Content.responsibleEmployee' | translate }}
            </h4>
          </div>
          <div
            class="ui-card"
            *ngIf="!!projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson"
          >
            <div>
              <div class="ui-card__subtitle elipsis w-100 d-inline-block">
                {{
                  projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.previewName
                }}
              </div>
              <div
                *ngIf="
                  !!projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.fullPhone
                "
              >
                <div
                  *ngIf="
                    !!projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.fullPhone
                  "
                >
                  <phone-info
                    [phone]="
                      projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.fullPhone
                    "
                    [phoneCountry]="
                      projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson
                        ?.phoneCountry
                    "
                  ></phone-info>
                </div>
              </div>

              <div>
                <a
                  draggable="false"
                  class="elipsis w-100 d-inline-block"
                  *ngIf="projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.email"
                  (click)="
                    sendMail(projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson);
                    $event.preventDefault()
                  "
                >
                  {{ projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.email }}
                </a>
                {{
                  !projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson?.email
                    ? '-'
                    : ''
                }}
              </div>
            </div>
          </div>
          <div *ngIf="!projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.userPerson">-</div>
        </div>
      </div>
    </div>

    <div
      [permission]="{ group: PermissionsGroups.PROJECTS, action: 'PROJECT_CLIENT_DATA_PREVIEW' }"
      class="col-lg-6"
    >
      <div class="ui-card ui-card--small">
        <div class="mb-3 d-flex justify-content-between align-items-center">
          <div class="d-flex">
            <svg-icon src="./assets/img/ic-user-bullets.svg"></svg-icon>
            <h3 class="ui-card__title ml-2">{{ 'ProjectSummary.Content.clientData' | translate }}</h3>
          </div>

          <svg-icon
            [permission]="{
              group: PermissionsGroups.PROJECTS,
              action: 'EDIT',
              objectCreatorId: projectPreviewService.projectEditIDs
            }"
            (click)="editMainContact()"
            class="ml-3 ic-edit"
            src="./assets/img/ic-edit-task.svg"
            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
          ></svg-icon>
        </div>

        <!--#region Address -->
        <project-summary-right-section-info [icon]="'/assets/img/ic-person-circle-outline.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.clientData' | translate }}</strong>
          </div>

          <span>
            {{ projectPreviewService?.project?.MainContact?.fullName || '-' }}
          </span>
          <external-link
            [offsetTop]="false"
            *ngIf="
              projectPreviewService?.project?.MainContact?.fullName &&
              !projectPreviewService?.project?.MainContact?.deleted
            "
            [permission]="{
              group: PermissionsGroups.CONTACTS,
              action: 'PREVIEW'
            }"
            [target]="'_self'"
            [link]="n.getPath('contact-preview', {}, { id: projectPreviewService?.project?.MainContact?.id })"
          >
          </external-link>
        </project-summary-right-section-info>
        <!-- #endregion -->

        <project-summary-right-section-info [icon]="'/assets/img/ic-map-pin.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.address' | translate }}</strong>
          </div>
          {{
            projectPreviewService?.project?.MainContact?.fullAddress
              ? projectPreviewService?.project?.MainContact?.fullAddress
              : '-'
          }}
        </project-summary-right-section-info>

        <project-summary-right-section-info [icon]="'/assets/img/ic-mail.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.email' | translate }}</strong>
          </div>
          <a
            draggable="false"
            *ngIf="projectPreviewService?.project?.MainContact?.email"
            class="elipsis w-100 d-inline-block"
            (click)="sendMail(projectPreviewService?.project?.MainContact); $event.preventDefault()"
          >
            {{ projectPreviewService?.project?.MainContact?.email }}
          </a>
          {{ !projectPreviewService?.project?.MainContact?.email ? '-' : '' }}
        </project-summary-right-section-info>

        <project-summary-right-section-info [icon]="'/assets/img/ic-phone.svg'">
          <div>
            <strong>{{ 'ProjectSummary.Content.phone' | translate }}</strong>
          </div>
          <phone-info
            [phone]="projectPreviewService?.project?.MainContact?.fullPhone"
            [phoneCountry]="projectPreviewService?.project?.MainContact?.phoneCountry"
          ></phone-info>
        </project-summary-right-section-info>

        <div class="mt-3">
          <div class="d-flex mb-1">
            <svg-icon src="./assets/img/ic-user-bullets.svg"></svg-icon>
            <h4 class="ui-card__title ml-2">{{ 'ProjectSummary.Content.responsibleContact' | translate }}</h4>
          </div>

          <div
            *ngIf="!!projectPreviewService?.project?.basicDataBox?.responsibleContact?.fullName"
            class="ui-card ui-card--small"
          >
            <div>
              <div class="ui-card__subtitle elipsis w-100 d-inline-block">
                {{ projectPreviewService?.project?.basicDataBox?.responsibleContact?.fullName }}
              </div>

              <div *ngIf="!!projectPreviewService?.project?.basicDataBox?.responsibleContact?.fullPhone">
                <phone-info
                  [phone]="projectPreviewService?.project?.basicDataBox?.responsibleContact?.fullPhone"
                  [phoneCountry]="
                    projectPreviewService?.project?.basicDataBox?.responsibleContact?.phoneCountry
                  "
                ></phone-info>
              </div>

              <div>
                <a
                  draggable="false"
                  class="elipsis w-100 d-inline-block"
                  *ngIf="!!projectPreviewService?.project?.basicDataBox?.responsibleContact?.email"
                  (click)="
                    sendMail(projectPreviewService?.project?.basicDataBox?.responsibleContact);
                    $event.preventDefault()
                  "
                >
                  {{ projectPreviewService?.project?.basicDataBox?.responsibleContact?.email }}
                </a>
              </div>
            </div>
          </div>

          <div *ngIf="!projectPreviewService?.project?.basicDataBox?.responsibleContact?.fullName">-</div>
        </div>
      </div>
    </div>
  </div>
</div>
