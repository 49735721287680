import { BaseTaskSidenavController } from './base-task-sidenav.controller';
import { Config } from '@shared/configs/config';
import { TaskViewState } from '@shared/enums/task-view-state.enum';
import { TaskGroupModalComponent } from '../components/task-group-modal/task-group-modal.component';
import { TaskSidenavComponent } from '../components/task-sidenav/task-sidenav.component';
import { Task } from '@shared/models/task.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { ComponentType } from '@angular/cdk/portal';

export class TaskSidenavController extends BaseTaskSidenavController {
  static readonly component: ComponentType<TaskSidenavComponent> = TaskSidenavComponent;

  constructor() {
    super();
  }

  addTask(
    projectID?: number,
    taskGroupId?: number,
    connectedTaskId?: number,
    extra?: any,
    assignToEmails?: string[],
    assignToIds?: number[],
    showInCalendar?: boolean,
  ) {
    const obj: any = { viewState: TaskViewState.CREATE, replaceUrl: true };
    projectID ? (obj.projectID = projectID) : null;
    taskGroupId ? (obj.taskGroupId = taskGroupId) : null;
    connectedTaskId ? (obj.connectedTaskId = connectedTaskId) : null;
    assignToEmails ? (obj.assignToEmails = assignToEmails) : null;
    assignToIds ? (obj.assignToIds = assignToIds) : null;
    showInCalendar ? (obj.showInCalendar = showInCalendar) : null;

    let ex = { type: TaskType.TYPE_TASK };
    extra ? (ex = Object.assign(ex, extra)) : null;

    this.sidenav.open(TaskSidenavController.component, this.getSidenavConfig(ex), obj);
  }

  editTask(projectID?: number, taskId?: string, extra?: any) {
    const obj: any = { viewState: TaskViewState.EDIT, replaceUrl: true };
    projectID ? (obj.projectID = projectID) : null;
    taskId ? (obj.taskId = taskId) : null;

    let ex = { type: TaskType.TYPE_TASK };
    extra ? (ex = Object.assign(ex, extra)) : null;

    this.sidenav.open(TaskSidenavController.component, this.getSidenavConfig(ex), obj);
  }

  previewTask(taskId: string, extra?: any) {
    const obj: any = { viewState: TaskViewState.PREVIEW, replaceUrl: true };
    taskId ? (obj.taskId = taskId) : null;

    let ex = { type: TaskType.TYPE_TASK };
    extra ? (ex = Object.assign(ex, extra)) : null;

    this.sidenav.open(TaskSidenavController.component, this.getSidenavConfig(ex), obj);
  }

  getPreviewTaskPath(task: Task) {
    return this.n.getPath('project-preview-tasks', {}, { projectId: task?.project?.id });
  }

  addTaskGroup(projectId: number) {
    return this.dialog.open(TaskGroupModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: true,
      data: {
        projectId,
      },
    });
  }
}
