import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { BaseComponent } from '@shared/components/base.component';
import { Config } from '@shared/configs/config';
import { UserPerson } from '@shared/interfaces/user.interface';
import { Company } from '@shared/models/company.model';
import { Employee } from '@shared/models/employee.model';
import { StorageEventType } from '@core/services/storage.service';

@Component({
  selector: 'protocol-fill-data',
  templateUrl: './protocol-fill-data.component.html',
  styleUrls: ['./protocol-fill-data.component.scss'],
})
export class ProtocolFillDataComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  constructor(public settingsService: SettingsService) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      firstName: new FormControl(this.userPerson?.firstName, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      lastName: new FormControl(this.userPerson?.lastName, [Validators.required, Validators.maxLength(100)]),
      companyName: new FormControl(this?.employee?.company?.name, [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }
    return messages;
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }

    this.loading = true;
    this.settingsService
      .setBasicData(this.form.value)
      .subscribe({
        next: this.onSuccessSetBasicData.bind(this),
        error: () => {
          this.s.error(this.t.instant('Protocols.FillData.errorSendingData'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  onSuccessSetBasicData(userPerson: UserPerson) {
    this.s.success(this.t.instant('Protocols.FillData.successSendingData'));
    this.store.UserPerson = userPerson;
    this.userPerson = userPerson;
    if (this.form.value['companyName']) {
      const company = this.store.Company;
      company.name = this.form.value['companyName'];
      this.store.Company = company;
      this.store.emmiter.emit({ type: StorageEventType.UPDATE_COMPANY, company });
    }
    this.store.emmiter.emit({type: StorageEventType.UPDATE_USER_PERSON, userPerson});
    this.n.navigate('protocols-list-all');
  }

}
