<div class="ui-project__header">
  <h3 class="mb-0">{{ 'ProjectChanges.List.title' | translate }}</h3>
  <div class="actions__buttons">
    <app-button
      class="mr-1"
      [permission]="{
        group: PermissionsGroups.TASKS,
        action: 'CHANGE_ADD',
        objectCreatorId: projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id
      }"
      (onClick)="createNewChange()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.PRIMARY_GREEN,
        text: WindowHelper.isMobileWidth ? '' : ('ProjectChanges.newChange' | translate),
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>

    <app-button
      confirmationPopover
      [disabled]="!listService?.rows?.length"
      [isDisabled]="!listService?.rows?.length"
      [permission]="{ group: PermissionsGroups.TASKS, action: 'CHANGE_SHARE' }"
      (confirm)="shareChanges()"
      [confirmButtonType]="'success'"
      placement="bottom"
      [popoverTitle]="'Projects.shareChanges' | translate"
      [popoverMessage]="
        !checkedTaskIds.length
          ? ('Projects.shareChangesAll' | translate) + ' (' + (listService?.total || 0) + ')'
          : ('Projects.shareChangesCount' | translate : { count: checkedTaskIds.length })
      "
      [cancelText]="'Projects.cancel' | translate"
      [confirmText]="'Projects.yes' | translate"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.SECONDARY_BLUE,
        text: WindowHelper.isMobileWidth
          ? ''
          : !checkedTaskIds.length
          ? ('ProjectChanges.shareAllChanges' | translate) + ' (' + (listService?.total || 0) + ')'
          : ('ProjectChanges.shareNChanges' | translate : { amount: this.checkedTaskIds.length }),
        prependSrc: './assets/img/ic-share.svg'
      }"
    ></app-button>

    <list-search-v2></list-search-v2>

    <list-filters-btn-v2 class="ml-auto" [filters]="ProjectChangesFiltersComponent"></list-filters-btn-v2>
  </div>
</div>

<div class="project-changes-list-wrapper">
  <div class="container-fluid--30 project-changes-list__content pb-2">
    <app-list
      *ngIf="inited"
      [config]="config"
      [filters]="ProjectChangesFiltersComponent"
      [emptyTemplate]="emptyTemplate"
      [headerTemplate]="headerTemplate"
      [searchName]="'ProjectChanges.List.search' | translate"
      [selected]="selected"
      [showToggleMenu]="showToggleMenu.bind(this)"
      #list
    >
    </app-list>
    <!-- (activate)="activate($event)" -->
  </div>

  <div class="sticky-footer">
    <app-summary-toggle [summary]="summary" [summaryConfig]="summaryConfig"></app-summary-toggle>
  </div>
</div>

<ng-template #headerTemplate>
  <!-- <div class="project-changes-list__headerTemplate">
    <app-button
      class="ml-md-4 m-1 mr-md-2"
      confirmationPopover
      [disabled]="!listService?.rows?.length"
      [isDisabled]="!listService?.rows?.length"
      [permission]="{ group: PermissionsGroups.TASKS, action: 'CHANGE_SHARE' }"
      (confirm)="shareChanges()"
      [confirmButtonType]="'success'"
      placement="top"
      [popoverTitle]="'Projects.shareChanges' | translate"
      [popoverMessage]="
        !checkedTaskIds.length
          ? ('Projects.shareChangesAll' | translate) + ' (' + (listService?.total || 0) + ')'
          : ('Projects.shareChangesCount' | translate: { count: checkedTaskIds.length })
      "
      [cancelText]="'Projects.cancel' | translate"
      [confirmText]="'Projects.yes' | translate"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.SECONDARY_BLUE,
        text: !checkedTaskIds.length
          ? ('ProjectChanges.shareAllChanges' | translate) + ' (' + (listService?.total || 0) + ')'
          : ('ProjectChanges.shareNChanges' | translate: { amount: this.checkedTaskIds.length }),
        prependSrc: './assets/img/ic-share.svg'
      }"
    ></app-button>
  </div> -->
</ng-template>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/empty-state-changes.svg"
      alt="{{ 'ProjectChanges.List.noRecords' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'ProjectChanges.List.noRecords' | translate }}</div>
  </div>
</ng-template>

<changes-name-cell #changesNameCellComponent></changes-name-cell>
<changes-source-cell #changesSourceCellComponent></changes-source-cell>
<add-days-cell #addDaysCellComponent></add-days-cell>
<changes-settlement-cell #changesSettlementCellComponent></changes-settlement-cell>
<changes-price-cell #changesPriceCellComponent></changes-price-cell>
<changes-deadline-cell #changesDeadlineCellComponent></changes-deadline-cell>
<changes-status-cell #changesStatusCellComponent></changes-status-cell>
<changes-created-cell #changesCreatedCellComponent></changes-created-cell>
<changes-name-header-cell #changesNameHeaderCellComponent></changes-name-header-cell>
