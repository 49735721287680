import { Protocol } from '@modules/protocols/shared/models/protocol';
export class EventProtocolsController {
  protocols: Protocol[] = [];

  get protocolsIds() {
    return this.protocols.map((i) => i.id) || [];
  }

  constructor() {}

  add(protocol: Protocol) {
    this.protocols.push(protocol);
  }

  delete(protocol: Protocol) {
    this.protocols = this.protocols.filter((i) => i.id !== protocol.id);
  }

  set(protocols: Protocol[]) {
    this.protocols = protocols;
  }

  reset() {
    this.protocols = [];
  }
}
