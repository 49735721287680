import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskType } from '@shared/enums/task-type.enum';

@Component({
  templateUrl: './project-creator-entity-discard.component.html',
})
export class ProjectCreatorEntityDiscardComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(
    public dialogRef: MatDialogRef<ProjectCreatorEntityDiscardComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { type: TaskType; viewState: string; confirm: () => void },
  ) {}

  confirm() {
    this.dialogRef.close();
    this.data?.confirm();
  }
}
