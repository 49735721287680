import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { NavigateService } from '@shared/services/navigate.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProjectAPIService } from '../../services/project-api.service';
import { ProjectStoreService } from '../../services/project-store.service';
import { ProjectQuestionDirectoryBase } from '../project-question-directory-base/project-question-directory-base.component';

@Component({
  selector: 'project-question-directory-first-sidenav',
  templateUrl: './project-question-directory-first-sidenav.component.html',
  styleUrls: ['./project-question-directory-first-sidenav.component.scss'],
})
export class ProjectQuestionDirectoryFirstSidenavComponent
  extends ProjectQuestionDirectoryBase
  implements OnInit {
  PermissionsGroups = PermissionsGroups;

  static componentName: string = 'ProjectQuestionDirectoryFirstSidenavComponent';

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachments: 20,
    maxAttachmentFileSize: 50,
  };

  constructor(
    public changes: ChangeDetectorRef,
    public projectAPI: ProjectAPIService,
    public projectStore: ProjectStoreService,
    public n: NavigateService,
    public globalSideNav: SidenavService,
  ) {
    super(projectStore, changes);
  }

  ngOnInit(): void {}

  goToEdit() {
    this.n
      .go('question-directory-edit-first', { projectid: this.projectPreviewService?.project.id })
      .then(() => this.globalSideNav.close());
  }

  submitDirectoryModel() {}
}
