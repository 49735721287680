import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProjectQuestionDirectoryFirstSidenavComponent } from '../project-question-directory-first-sidenav/project-question-directory-first-sidenav.component';
import { ProjectQuestionDirectorySecondSidenavComponent } from '../project-question-directory-second-sidenav/project-question-directory-second-sidenav.component';
import { ProjectQuestionDirectoryThirdSidenavComponent } from '../project-question-directory-third-sidenav/project-question-directory-third-sidenav.component';

export const ProjectQuestionDirectoryBreadcrumbsLinks = (objectCreatorId: number[]) => {
  return [
    {
      title: `Metatitles.Projects.questionDirectory`,
      name: 'ProjectQuestionDirectoryFirstSidenavComponent',
      componentRef: ProjectQuestionDirectoryFirstSidenavComponent,
      permissions: {
        group: PermissionsGroups.PROJECTS,
        action: 'PROJECT_BOX_FIRST_PREVIEW',
        objectCreatorId,
      },
    },
    {
      title: `Metatitles.Projects.questionDirectory`,
      name: 'ProjectQuestionDirectorySecondSidenavComponent',
      componentRef: ProjectQuestionDirectorySecondSidenavComponent,
      permissions: {
        group: PermissionsGroups.PROJECTS,
        action: 'PROJECT_BOX_SECOND_PREVIEW',
        objectCreatorId,
      },
    },
    {
      title: `Metatitles.Projects.questionDirectory`,
      name: 'ProjectQuestionDirectoryThirdSidenavComponent',
      componentRef: ProjectQuestionDirectoryThirdSidenavComponent,
      permissions: { group: PermissionsGroups.PROJECTS, action: 'PROJECT_BOX_THIRD_PREVIEW' },
    },
  ];
};
