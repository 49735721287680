import { HttpError } from '@shared/interfaces/error.interface';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { TaskViewState } from '@shared/enums/task-view-state.enum';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { SnackBarService } from '@core/services/snackbar.service';
import { ListTaskService } from '@shared/modules/list/services/list-task.service';
import { Task } from '@shared/models/task.model';
import { TaskSidenavManager } from '../../managers/task-sidenav.manager';
import { TaskType } from '@shared/enums/task-type.enum';
import { TASK_SIDENAV_FORM } from '../../const/task-sidenav-form';

@Component({
  selector: 'task-sidenav-white',
  styleUrls: ['./task-sidenav-white.component.scss'],
  templateUrl: './task-sidenav-white.component.html',
})
export class TaskSidenavWhiteComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  taskManager: TaskSidenavManager = new TaskSidenavManager();

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    public service: TaskSidenavService,
    private taskService: TaskService,
    private sidenav: SidenavService,
    public listTaskService: ListTaskService,
  ) {}

  ngOnInit() {
    // this.sidenav.backdropFuncion = this.service.discardSaving.bind(this.service);
  }

  submitForm() {
    if (!!this.service?.task?.isDeleted) return;
    Object.keys(this.service.TASK_SIDENAV_FORM).forEach((key: string) => {
      this.service.form.get(key)?.markAsTouched();
      this.service.form.get(key)?.updateValueAndValidity({ emitEvent: false });
    });
    this.service.isFormValid ? this.saveForm() : null;
  }

  saveForm() {
    switch (this.service.viewState) {
      case TaskViewState.CREATE:
        this.service.addNewTask().subscribe({
          next: this.successCreatingTask.bind(this),
          error: this.errorCreatingTask.bind(this),
        });
        break;
      case TaskViewState.EDIT:
        this.service
          .saveForm()
          .subscribe({ next: this.successUpdatingTask.bind(this), error: this.errorUpdatingTask.bind(this) });
        break;
      default:
        break;
    }
  }

  successCreatingTask(task: Task) {
    this.s.success(this.t.instant(`Tasks.${task.type}Created`));
    this.service.form.get(TASK_SIDENAV_FORM.type).setValue(task?.type, { emitEvent: false });
    this.taskManager.createTask(this.service.projectID, this.service?.connectedTask?.id, {
      objectType: this.service.objectType,
      type: task?.type || this.service.TaskConfigType,
      taskId: task.id,
      taskGroupId: task?.type !== TaskType.TYPE_CHANGE ? task?.taskGroup?.id : null,
      showInCalendar: this.service.showInCalendar,
    });
  }

  errorCreatingTask(err) {
    this.s.error(this.t.instant(`Tasks.errorTask`));
  }

  successUpdatingTask(task: Task) {
    this.s.success(this.t.instant(`Tasks.${task.type}Changed`));
    this.taskManager.previewTask(task);
  }

  errorUpdatingTask(err: HttpError) {
    this.s.error(this.t.instant(`Tasks.errorTaskUpdate`));
  }
}
