import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeValuePipe } from '@shared/pipes/change-value.pipe';

@Component({
  selector: 'day-cell',
  templateUrl: './day-cell.component.html',
  styleUrls: ['./day-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayCellComponent implements OnInit {
  _day: string;
  _dayValue: number;
  @Input() customText:string = '';

  @Input()
  set day(day: number) {
    this._dayValue = Number(day) || 0;
    this.setDay(this._dayValue);
    this.changes.detectChanges();
  }

  constructor(
    private changValue: ChangeValuePipe,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
  ) {}

  ngOnInit(): void {}

  setDay(day: number) {
    this._day = `${this.changValue.transform(day, day, true)} ${this.t.instant('Protocols.days')}`;
  }
}
