import { Config } from '@shared/configs/config';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MailingHttpService extends BaseHttpService {
  constructor() {
    super();
  }

  shareChange(body: any) {
    const url = `${Config.API}/task/share-changes`;
    return this.post(url, body, false);
  }

  getProjectChanges(id: number, tasksIds: number[]) {
    return this.get(`${Config.API}/project/${id}/changes-email`, false, { 'tasksIds[]': tasksIds });
  }

  getEmailTemplateById(id: number, projectId: number, getByNumber: boolean = false) {
    const params = {};
    projectId ? (params['projectId'] = projectId) : null;
    getByNumber ? (params['getByNumber'] = 1) : null;
    return this.get(`${Config.API}/email-template/${id}`, false, params);
  }

  getProjectStageTemplates(params: any = {}) {
    return this.get(`${Config.API}/email-template/project-stage`, false, params);
  }

  sendProjectStageEmail(data: any) {
    return this.post(`${Config.API}/email-template/project-stage`, data, false, true);
  }
}
