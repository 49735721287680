<modal-title [title]="'ProjectChanges.changeShareDialogTitle' | translate"></modal-title>
<mat-dialog-content>
  <form class="change-share-dialog-content" [formGroup]="form">
    <!-- #region Preview Changes -->
    <div class="change-share-dialog-content__selected mb-3">
      <div class="change-share-dialog-content__selected-title d-flex flex-wrap">
        <span class="d-inline-block mr-2">{{ 'ProjectChanges.multipleChanges' | translate }}:</span>
        <span class="change-share-dialog-content__selected-description">
          <ng-container>
            {{
              data?.taskIds?.length || data?.task
                ? data?.taskIds?.length || 1
                : ('ProjectChanges.allChanges' | translate)
            }}
          </ng-container>
        </span>
      </div>
      <div class="change-share-dialog-content__selected-description" *ngIf="data?.task">
        {{ data?.task?.description }}
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region change contacts share -->
    <div class="mb-3">
      <ng-select
        [ngClass]="{ 'ng-select--error': isControlInvalid(TASK_CHANGE_DIALOG_FORM.email) }"
        #contactSelect
        ngSelectExtension
        [url]="EmailsUrl"
        class="select select-default select-with-title ng-select--required w-100"
        [placeholder]="'ProjectChanges.emailOrContact' | translate"
        [responseTransform]="transformEmails.bind(this)"
        [searchGetParam]="'search'"
        [bindLabel]="'fullName'"
        [bindValue]="'id'"
        [addTagText]="'Protocols.add' | translate"
        [addTag]="validateEmail.bind(this)"
        [notFoundText]="'Projects.notFoundText' | translate"
        [formControlName]="TASK_CHANGE_DIALOG_FORM.email"
        [originalList]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <contact-option-template [contact]="item"></contact-option-template>
        </ng-template>
      </ng-select>
      <span
        *ngIf="isControlInvalid(TASK_CHANGE_DIALOG_FORM.email)"
        class="d-flex form-control-error-msg mt-1"
        [innerHTML]="'FormErrors.required' | translate"
      ></span>
    </div>
    <!-- #endregion -->

    <!-- #region Description to change -->
    <div class="mb-3">
      <gbxsoft-input
        class="required"
        [errMessages]="errorMessages"
        [formControlName]="TASK_CHANGE_DIALOG_FORM.title"
        [config]="{ name: 'ProjectChanges.changeMailTitle' | translate }"
      ></gbxsoft-input>
    </div>
    <!-- #endregion -->

    <!-- #region Description to change -->
    <div>
      <div class="relative">
        <quill-editor
          [formControlName]="TASK_CHANGE_DIALOG_FORM.content"
          [placeholder]="('ProjectChanges.changeMailDewscription' | translate) + '*'"
        ></quill-editor>
      </div>

      <span
        *ngIf="isControlInvalid(TASK_CHANGE_DIALOG_FORM.content)"
        class="d-flex form-control-error-msg mt-1"
        [innerHTML]="'FormErrors.required' | translate"
      ></span>
    </div>
    <!-- #endregion -->
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="d-flex justify-content-end">
    <app-button
      (onClick)="dialogRef.close()"
      [class]="'bold'"
      [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'ProjectChanges.List.cancel' | translate,
        size: ButtonSize.LARGE
      }"
    >
    </app-button>
    <app-button
      [disabled]="loading"
      [loading]="loading"
      [class]="'ml-3'"
      (onClick)="submitShare()"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'ProjectChanges.shareChange' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-share.svg'
      }"
    >
    </app-button>
  </div>
</mat-dialog-actions>
