import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Injectable,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { debounce } from '@shared/decorators/debounce.decorator';
import { ListService } from '@shared/modules/list/services/list.service';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-toggle-menu',
  templateUrl: './list-toggle-menu.component.html',
  styleUrls: ['./list-toggle-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListToggleMenuComponent implements AfterViewInit, OnDestroy {
  sub: Subscription;
  private _row;

  @Input() component;

  @Input() set row(row) {
    this._row = row;
    this.createToggleMenuTemplateRef();
    this.setHeightForToggleButton();
    this.changes.detectChanges();
  }

  get row() {
    return this._row;
  }

  @Input() tourAnchorName;
  @Input() showToggleMenu?: Function;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private service: ListService,
    private changes: ChangeDetectorRef,
    public resolver: ComponentFactoryResolver,
  ) {}

  @HostListener('window:resize', ['$event'])
  @debounce(30)
  windowResize(e: KeyboardEvent) {
    this.setHeightForToggleButton();
  }

  ngAfterViewInit() {
    this.createToggleMenuTemplateRef();
    this.setHeightForToggleButton();
  }

  createToggleMenuTemplateRef() {
    if ((this.showToggleMenu && this.showToggleMenu(this.row)) || !this.showToggleMenu) {
      if (!this.container) return;
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(this.component);
      const component = this.container.createComponent<any>(factory);
      component.instance.row = this.row;
    }
  }

  show() {
    return this.showToggleMenu ? this.showToggleMenu(this.row) : true;
  }

  setHeightForToggleButton() {
    setTimeout(() => {
      this.toggleButton
        ? (this.toggleButton.nativeElement.style.height =
            this.toggleButton.nativeElement.parentNode.parentNode.parentNode.parentNode.offsetHeight + 'px')
        : null;
      this.changes.detectChanges();
    }, 0);
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }
}
