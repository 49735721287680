export const environment = {
  production: false,
  hmr: false,
  API: 'https://dev-intilio.develtio.dev/api',
  PUBLIC_PHOTO: 'https://dev-intilio.develtio.dev/api/upload/photo/',
  PRIVATE_ATTACHMENTS: 'https://dev-intilio.develtio.dev/api/attachment/',
  SERVER: 'https://dev-intilio.develtio.dev',
  CAPTCHA: '6LcTuqYZAAAAAKMIHXqo1V1TUpra5ZvZGOo-nrHD',
  GTAG: 'UA-176572901-2',
  CONTACT_EMAIL: 'support@intilio.com',
  DEBUG: false,
  INSTANCE_NAME: '[DEV]',
  FULL_CALENDAR_LICENSE_KEY: '0588865760-fcs-1604309687',
  GOOOGLE_CLIENT_ID: '1072276359606-u1frugemk5c3bfq4he9gs5398f6rh1jt.apps.googleusercontent.com',
};
