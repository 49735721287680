<div class="projects-panel">
  <main>
    <content-with-tabs
      [tabLinks]="tabLinks"
      [tabsTemplate]="projectsTopBar"
      [tabsDescriptionTemplate]="projectDescription"
    ></content-with-tabs>
  </main>
  <ng-template #projectsTopBar>
    <div class="mb-2">
      <img draggable="false" src="./assets/img/project-circle.svg" alt="Project" />
    </div>
  </ng-template>

  <ng-template #projectDescription>
    <a draggable="false" [routerLink]="link" class="d-block projects-panel__link">
      <span class="projects-panel__link-address d-flex align-items-center justify-content-center">
        {{ projectStore?.projectCtrl?.project?.clientNameWithAddress }}
      </span>
      <span
        class="projects-panel__link-name d-flex align-items-center justify-content-center"
        [routerLink]="link"
      >
        {{ projectStore?.projectCtrl?.project?.projectNameWithID }}
      </span>
    </a>
  </ng-template>

  <!-- #region Project Buttons -->
  <div *ngIf="footerExistance" class="container sticky-footer">
    <div class="row">
      <div class="container">
        <div class="col-12 py-2">
          <div class="row justify-content-between">
            <app-button
              class="mr-2 bold"
              [disabled]="loading"
              (onClick)="location.back()"
              [config]="{
                type: ButtonTypes.DISCARD_BUTTON,
                text: 'Projects.discard' | translate,
                size: ButtonSize.LARGE
              }"
            ></app-button>
            <app-button
              (onClick)="startSaving()"
              [loading]="loading"
              [disabled]="loading"
              [config]="{
                type: ButtonTypes.PRIMARY_BLUE,
                text: 'Projects.save-' + title | translate,
                size: ButtonSize.LARGE,
                prependSrc: '/assets/img/ic-save.svg'
              }"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
</div>
