<div class="phone-control">
  <form [formGroup]="form">
    <country-picker
      #countryPickerComponent
      [phoneFlagName]="phoneFlagName"
      [form]="form"
      [disabled]="disabled"
      [emitter]="changesEmitter"
    >
    </country-picker>
    <gbxsoft-input
      class="number-control"
      [errMessages]="errorMessages(phoneName)"
      formControlName="{{ phoneName }}"
      [config]="config"
      [disabled]="disabled"
      #input
    ></gbxsoft-input>
  </form>
</div>
