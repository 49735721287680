<div class="inside-warning-notification">
  <div class="col-12 d-flex align-items-center justify-content-between inside-warning-notification__container">
    <div class="inside-warning-notification__information">
      <div class="inside-warning-notification__title" [innerHTML]="title"></div>
      <div class="inside-warning-notification__subtitle" [innerHTML]="subtitle"></div>
      <div class="inside-warning-notification__add-event" *ngIf="addEventButton">
        <app-button
          [permission]="{
            group: PermissionsGroups.CALENDAR,
            action: 'ADD_EVENT'
          }"
          [class]="'btn-sm-block'"
          (onClick)="addEvent()"
          [config]="{
          text: 'ProjectSummary.SendNotificationAboutStageChange.addEvent' | translate,
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          prependSrc: '/assets/img/ic-calendar.svg'
      }"></app-button>
      </div>
    </div>
    <div class="d-block w-100 w-sm-auto d-sm-flex align-items-end justify-content-end flex-wrap mt-3 mt-md-0">
      <app-button
        [attr.class]="'m-2 m-md-2'"
        *ngIf="discardButtonText"
        [class]="'btn-sm-block'"
        (onClick)="onDiscard.emit(true)"
        [config]="{
          text: discardButtonText,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          prependSrc: '/assets/img/ic-close.svg'
      }"></app-button>
      <app-button
        [attr.class]="'m-2 m-md-2'"
        *ngIf="actionButtonText"
        [class]="'btn-sm-block'"
        (onClick)="onAction.emit(true)"
        [config]="{
          text: actionButtonText,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_BLUE,
          prependSrc: '/assets/img/ic-arrow-right.svg'
      }"></app-button>
    </div>
  </div>
</div>
