import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';

@Pipe({ name: 'rangeFormatV2', pure: true })
export class RangeFormatV2Pipe implements PipeTransform {
  transform(start: string, end: string, isFullDay: boolean = false, onlyTime: boolean = false, onlyDate = false): string {
    const _start = start ? moment(start, Config.DATE_SERVER) : '';
    const _end = end ? moment(end, Config.DATE_SERVER) : '';

    const startDate = _start ? _start.format(Config.DATE_FORMAT_DOTS) : '';
    const endDate = _end ? _end.format(Config.DATE_FORMAT_DOTS) : '';

    const startTime = _start ? _start.format(Config.TIME) : '';
    const endTime = _end ? _end.format(Config.TIME) : '';

    const sameDates = startDate === endDate;

    if (onlyTime) {
      return `${startTime} - ${endTime}`;
    }

    if (isFullDay || onlyDate) {
      return sameDates ? `${startDate}` : `${startDate} - ${endDate}`;
    }

    return sameDates
      ? `${startDate} ${startTime} - ${endTime}`
      : `${startDate} ${startTime} - ${endDate} ${endTime}`;
  }
}
