import { Component, OnInit } from '@angular/core';
import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { CalendarEventsSidenavService } from '../../shared/services/calendar-events-sidenav.service';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';

@Component({
  selector: 'calendar-events-sidenav',
  templateUrl: './calendar-events-sidenav.component.html',
  styleUrls: ['./calendar-events-sidenav.component.scss'],
})
export class CalendarEventsSidenavComponent implements OnInit {
  static componentName: string = 'CalendarEventsSidenavComponent';
  weekStart: string;
  weekEnd: string;

  constructor(public service: CalendarEventsSidenavService, private calendarService: CalendarService) {}

  ngOnInit(): void {
    this.setCurrentDate();
    this.service.calendarViewCtrl.getMyWeekEvents({
      startDate: this.weekStart,
      endDate: this.weekEnd,
    });
  }

  setCurrentDate() {
    const isoDay = moment(this.calendarService.mainCtrl.calendarAPI.view.activeStart);
    this.weekStart = isoDay.startOf('isoWeek').format(Config.DATE_SERVER);
    this.weekEnd = isoDay.endOf('isoWeek').format(Config.DATE_SERVER);
  }
}
