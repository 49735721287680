import { Config } from '@shared/configs/config';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'doc-previewer',
  templateUrl: './doc-previewer.component.html',
  styleUrls: ['./doc-previewer.component.scss'],
})
export class DocPreviewerComponent implements OnInit, OnDestroy {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  downloading: boolean = false;
  src: string = '';

  constructor(
    private filePipe: AuthFilePipe,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DocPreviewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      file: Attachment;
      downloadFunc: Function;
      isPDF: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.setIframe();
  }

  setIframe() {
    this.src = (this.data.isPDF
      ? this.filePipe.transform(this.data.file.file)
      : this.sanitizer.bypassSecurityTrustResourceUrl(
          Config.GOOGLE_DOCS_LINK(this.filePipe.transform(this.data.file.file)),
        )) as string;
  }

  load() {}

  ngOnDestroy() {
    this.src = null;
  }
}
