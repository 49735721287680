import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import {
  ESummaryValueType,
  ISummaryBoxCosts,
} from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import { SummaryAmountService } from '@modules/projects/modules/project-changes/shared/services/summary-amount.service';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';

@Component({
  selector: 'summary-amount-dialog',
  templateUrl: './summary-amount-dialog.component.html',
  styleUrls: ['./summary-amount-dialog.component.scss'],
})
export class SummaryAmountDialogComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  loading: boolean = false;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SummaryAmountDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ISummaryBoxCosts,
    private fb: FormBuilder,
    private service: SummaryAmountService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {}

  ngOnInit() {
    this._createForm();
  }

  private _createForm() {
    this.form = this.fb.group({
      [this.data.apiConfig?.fieldName]: [
        this.data.apiConfig?.value || 0,
        [Validators.min(0), Validators.max(Config.MAX_NUMBER / 100)],
      ],
    });
  }

  submitAmount() {
    if (this.loading || this.form.invalid) return;
    this.loading = true;
    this.sendRequest();
  }

  sendRequest() {
    const data = this.form.value;
    data[this.data.apiConfig?.fieldName] =
      this.data.apiConfig.type === ESummaryValueType.MONEY
        ? data[this.data.apiConfig?.fieldName] * 100
        : data[this.data.apiConfig?.fieldName];

    this.service
      .updateAmountField(this.data.apiConfig.url, data)
      .subscribe(() => {
        this.s.success(this.t.instant('ProjectChanges.Summary.summaryChanged'));
        this.service.updateList.emit(true);
        this.resetForm();
      })
      .add(() => {
        this.loading = false;
      });
  }

  resetForm() {
    this.form.reset();
    this.dialogRef.close();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.min?.min || control?.errors?.min?.min === 0) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.min', {
        number: control.errors?.min?.min.toString(),
      });
    }

    if (control?.errors?.max?.max) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max?.max.toString(),
      });
    }

    return messages;
  }
}
