//Full Datepciker documentation find by link https://www.daterangepicker.com/
import { GbxsoftInputComponent } from '../gbxsoft-input.component';

export class GbxsoftDatepickerController {
  c: GbxsoftInputComponent;

  format: string = 'DD-MM-YYYY';

  constructor(c: GbxsoftInputComponent) {
    this.c = c;

    this.validateLibrariesInclude ? this.initDatePicker() : null;
  }

  get validateLibrariesInclude(): boolean {
    if (!$) {
      console.warn('Include jqeury.min.js in angular.json');
      return false;
    }

    if (!(<any>$(this.c.gbxsoftInput.nativeElement)).daterangepicker) {
      console.warn('Include daterangepicker.js in angular.json');
      return false;
    }

    return true;
  }

  initDatePicker() {
    (<any>$(this.c.gbxsoftInput.nativeElement)).daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: true,
      locale: {
        format: this.format
      }
    });

    this.onApplyEvent();
  }

  onApplyEvent() {
    (<any>$(this.c.gbxsoftInput.nativeElement)).on('apply.daterangepicker', (ev, picker) => {
      const value = picker.startDate.format(this.format);
      $(this.c.gbxsoftInput.nativeElement).val(value);
      this.c.input(value);
      this.c.errorCtrl.control.markAsTouched();
      this.c.detectChanges();
    });
  }
}
