import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Task } from '@shared/models/task.model';

export class TaskPermissionController {
  objectCreatorIds: number[] = [];

  task: Task;

  add: boolean = false;
  edit: boolean = false;
  preview: boolean = false;
  share: boolean = false;
  remove: boolean = false;
  canSeePrice: boolean = false;
  canAccept: boolean = false;

  linkedPreview: boolean = false;

  constructor() {}

  initPermissions(task?: Task) {
    this.initTask(task);
    this.setAllPermissions();
  }

  initTask(task?: Task) {
    this.task = new Task(task);
  }

  setObjectCreatorIds() {
    this.objectCreatorIds = this.setIDs();
  }

  setIDs(withoutClient: boolean = true, mainContact: boolean = false) {
    if (!this.task) return [];
    const ids =
      (withoutClient ? this.task?.assignedTo?.filter((i) => !i.isClient) : this.task?.assignedTo).map(
        (u: EmployeeSearchItem) => u?.id,
      ) || [];
    return [
      this.task?.creator?.id,
      this.task?.project?.basicDataBox?.responsibleEmployee?.id,
      mainContact ? this.task?.project?.MainContact?.employeeId : null,
    ]
      .concat(ids)
      .filter(Boolean);
  }

  setAllPermissions() {
    // 1. Set List of creator Id`s
    this.setObjectCreatorIds();

    // 2. Set up permissions
    this.setCreatePermission();
    this.setEditPermission();
    this.setPreviewPermission();
    this.setSharePermission();
    this.setRemovePermission();
    this.setLinkedPermission();
    this.setPriceVisibilityPermission();
  }

  setCreatePermission() {
    const action: string = this.task?.isChange ? 'CHANGE_EDIT' : 'ADD';
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action));
    this.add = ctrl.check();
  }

  setEditPermission() {
    const action: string = this.task?.isChange ? 'CHANGE_EDIT' : 'EDIT';
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action));
    this.edit = ctrl.check();
  }

  setPreviewPermission() {
    const action: string = this.task?.isChange ? 'CHANGE_PREVIEW' : 'EDIT';
    const ids = this.setIDs(false);
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action, ids));
    this.preview = ctrl.check();
    this.canAccept = !!this.task?.canAccept;
  }

  setSharePermission() {
    const action: string = this.task?.isChange ? 'CHANGE_SHARE' : 'EDIT';
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action));
    this.share = ctrl.check();
  }

  setRemovePermission() {
    const action: string = this.task?.isChange ? 'CHANGE_REMOVE' : 'EDIT';
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action));
    this.remove = ctrl.check();
  }

  setLinkedPermission() {
    const action = 'CHANGE_PREVIEW';
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action));
    this.linkedPreview = ctrl.check();
  }

  setPriceVisibilityPermission() {
    const action = 'CHANGE_PRICE_VISIBILITY';
    const ids = this.setIDs(true, false);
    const ctrl: CheckPermission = new CheckPermission(this.getPermissionConfig(action, ids));
    this.canSeePrice = ctrl.check();
  }

  getPermissionConfig(action: string, ids?: number[]) {
    return {
      group: PermissionsGroups.TASKS,
      action,
      objectCreatorId: ids || this.objectCreatorIds,
    };
  }
}
