import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { HttpError } from '@shared/interfaces/error.interface';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject } from '@angular/core';
import { ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { TaskGroup } from '@shared/models/task-group.model';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  templateUrl: './task-group-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskGroupModalComponent {
  Config = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;

  group: FormControl = new FormControl(null, [Validators.required]);
  onGroupAdd: EventEmitter<string> = new EventEmitter<string>();
  onGroupRemove: EventEmitter<TaskGroup> = new EventEmitter<TaskGroup>();

  get errorMessages() {
    return Config.validationMessages;
  }

  constructor(
    private dialogRef: MatDialogRef<TaskGroupModalComponent>,
    private changes: ChangeDetectorRef,
    private taskService: TaskService,
    private t: TranslateService,
    private s: SnackBarService,
    private listTaskService: ListTaskService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectId: number;
      notSendNewGroup: boolean;
      edit?: boolean;
      taskGroup?: TaskGroup;
    },
  ) {}

  close() {
    this.group.reset();
    this.dialogRef.close();
  }

  submit() {
    this.addNewTaskGroup();
  }

  addNewTaskGroup() {
    this.group.markAsTouched();
    if (!this.group.valid) {
      return;
    }

    if (this.data.notSendNewGroup) {
      //for editable state of all task groups
      this.onGroupAdd.emit(this.group.value);
      this.close();
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;
    this.changes.detectChanges();

    this.taskService
      .createTaskGroup(this.group.value, this.data.projectId)
      .subscribe({
        next: this.successCreateTaskGroup.bind(this),
        error: this.errorCreateTaskGroup.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successCreateTaskGroup(taskGroup: TaskGroup) {
    this.onGroupAdd.emit(this.group.value);
    this.s.success(this.t.instant('Projects.taskGroupCreated'));
    this.close();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.ADD_TASK_GROUP, data: taskGroup });
  }

  errorCreateTaskGroup(error: HttpError) {
    switch (error?.messageCode) {
      case IntilioCodes.ALREADY_EXISTS:
        this.s.error(this.t.instant('Projects.taskGroupFailedExists'));
        break;
      default:
        this.s.error(this.t.instant('Projects.taskGroupFailed'));
        break;
    }
  }

  onRemoveGroup() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.close();
        this.onGroupRemove.emit(this.data.taskGroup);
      })
      .subscribe();
  }
}
