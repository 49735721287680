export class RedirectHelper {
  static getWindowPath() {
    return `${window.location.pathname}${window.location.search}`.substr(1);
  }

  constructor() {}

  static getRouteData(url: string) {
    const splitedUrl = url.split('?');
    const pathInline = splitedUrl[0];
    const searchInline = splitedUrl[1];

    return {
      link: `/${pathInline}`,
      queryParams: RedirectHelper.getQueryParamsFromInlineURL(searchInline),
    };
  }

  static getQueryParamsFromInlineURL(query: string) {
    return !!query
      ? JSON.parse(
          '{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        )
      : {};
  }
}
