<div class="protocol-stuffs">
  <sidenav-title
    [title]="'Protocols.Stuffs.title' | translate"
    [template]="stuffsHeadTemplate"
  ></sidenav-title>

  <div
    class="d-flex mt-3"
    [permission]="{
      group: PermissionsGroups.PROTOCOLS,
      action: 'SEND',
      objectCreatorId: pService.manager.protocol?.creator.id
    }"
    *ngIf="pService.manager.protocol?.isChanged"
  >
    <p class="orange-500 m-0" [innerHTML]="'Protocols.Stuffs.sendAgainProtocolText' | translate"></p>
    &nbsp;
    <a draggable="false" class="blue-500 text-underlined" (click)="protocolController.send(false)">{{
      'Protocols.Stuffs.sendAgainProtocolLink' | translate
    }}</a>
  </div>

  <protocol-task-list #taskList [titleHidden]="true" [hideAddAction]="true"></protocol-task-list>
</div>

<ng-template #stuffsHeadTemplate>
  <div class="w-100 w-sm-auto d-flex flex-column flex-md-row d-md-block mt-3 mt-md-0">
    <!-- #region SEND -->
    <button
      class="btn btn-empty_blue mb-1 mb-md-0"
      [permission]="{
        group: PermissionsGroups.PROTOCOLS,
        action: 'SEND',
        objectCreatorId: pService.manager.protocol?.creator.id
      }"
      (click)="protocolController.send(false)"
      *ngIf="pService.manager.protocol?.status !== ProtocolsStatuses.STATUS_DRAFT"
    >
      <svg-icon src="./assets/img/ic-contact-outline-blue.svg"></svg-icon>
      {{ 'Protocols.Stuffs.send' | translate }}
    </button>
    <!-- #endregion -->

    <!-- #region EDIT -->
    <button
      class="btn btn-primary ml-md-1"
      (click)="edit()"
      [permission]="{
        group: PermissionsGroups.PROTOCOLS,
        action: 'EDIT',
        objectCreatorId: pService.manager.protocol?.creator.id
      }"
    >
      <img
        draggable="false"
        src="./assets/img/ic-edit-3-white.svg"
        [alt]="'Protocols.Stuffs.edit' | translate"
      />
      {{ 'Protocols.Stuffs.edit' | translate }}
    </button>
    <!-- #endregion -->
  </div>
</ng-template>
