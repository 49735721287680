<modal-title [title]="'Tasks.TaskType.' + task?.type | translate"></modal-title>
<mat-dialog-content>
  <!-- #region Task Shared -->
  <div class="task-modal-shared">
    <div>
      <text-attachments-input
        #textAttach
        [attachments]="attachments?.length ? attachments : []"
        [fileName]="'files'"
        [textName]="'note'"
        [form]="form"
        [config]="config"
        [disabled]="updatingTask"
        [attachmentsConfig]="attachmentsConfig"
        (removeFileEvent)="removeFileEvent($event)"
      >
      </text-attachments-input>
      <span
        *ngIf="NoteControl?.touched && !NoteControl.value?.length"
        class="d-flex form-control-error-msg mt-1"
        [innerHTML]="'FormErrors.required' | translate"
      ></span>
    </div>
    <div
      *ngIf="data.isTypeSelection"
      class="d-flex align-items-center justify-content-center task-types-list"
    >
      <span class="grey-1 change-for m-1">
        <b [innerHTML]="'Protocols.changeForTaskType' | translate"></b>
      </span>

      <ul class="m-0 mx-2 flex-wrap d-flex align-items-center justify-content-center">
        <ng-container *ngFor="let _task of taskTypeList">
          <li
            class="px-2 py-1 cursor-pointer m-1"
            (click)="setTypeChange(_task.type)"
            [ngClass]="{ active: typeToChange === _task.type }"
            [innerHTML]="_task.name | translate"
          ></li>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Modal Main Section -->
  <task-modal-content #taskModalContent [task]="task" [type]="typeToChange"></task-modal-content>
  <!-- #endregion -->
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- #region Interaction Buttons -->
  <div class="d-flex justify-content-end">
    <app-button
      [class]="'bold'"
      (onClick)="discardModal()"
      [config]="{
        text: 'Protocols.discard' | translate,
        type: ButtonTypes.DISCARD_BUTTON,
        size: ButtonSize.LARGE
      }"
    >
    </app-button>

    <app-button
      class="ml-2"
      (onClick)="submitModal()"
      [config]="{
        text: 'Protocols.editSave' | translate,
        prependSrc: './assets/img/ic-save.svg',
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE
      }"
      [loading]="updatingTask"
      [disabled]="updatingTask"
    >
    </app-button>
  </div>
  <!-- #endregion -->
</mat-dialog-actions>
