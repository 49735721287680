import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'app-project-creator-deactivate',
  templateUrl: './project-creator-deactivate.component.html',
  styleUrls: ['./project-creator-deactivate.component.scss'],
})
export class ProjectCreatorDeactivateComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(
    public dialogRef: MatDialogRef<ProjectCreatorDeactivateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { viewState: string },
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
