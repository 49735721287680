import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { ButtonConfig, ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ButtonGroupConfig } from '@shared/modules/ui/components/button-group/button-group.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @Input() title: string = '';
  @Input() buttonName: string = '';
  @Input() prependSrc?: string = './assets/img/ic-plus-add.svg';
  @Input() permissionDecorator: PermissionDecorator;
  @Input() buttonsGroupConfig: ButtonGroupConfig;
  @Input() actionButtonConfig: ButtonConfig;

  @Output('onButtonClick') onButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.listenToScrollTabs();
  }

  listenToScrollTabs() {
    ($ as any)('.tabs').on('click', '.mat-tab-header-pagination-before', this.scrollLeft.bind(this));
    ($ as any)('.tabs').on('click', '.mat-tab-header-pagination-after', this.scrollRight.bind(this));
  }

  scrollLeft() {
    let currentScroll = ($ as any)('.mat-tab-link-container').scrollLeft();
    const moveValue = window.innerWidth / 2;
    currentScroll - moveValue < 0 ? currentScroll = 0 : currentScroll = currentScroll - moveValue;
    ($ as any)('.mat-tab-link-container').animate({scrollLeft: currentScroll}, 300);
  }

  scrollRight() {
    let currentScroll = ($ as any)('.mat-tab-link-container').scrollLeft();
    const moveValue = window.innerWidth / 2;
    currentScroll = currentScroll + moveValue;
    ($ as any)('.mat-tab-link-container').animate({scrollLeft: currentScroll}, 300);
  }

}
