<ng-container *ngIf="emptyTemplate && !service?.rows?.length && !service.loading && !service?._search.length && !isActiveFilter">
  <div class="datatable-empty">
    <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
  </div>
</ng-container>

<div
  class="datatable-empty"
  *ngIf="!emptyTemplate && !service?.rows?.length && !service?.loading && !service?._search.length && !isActiveFilter"
>
  {{ 'Global.emptyListState' | translate }}
</div>

<div
  class="datatable-empty"
  *ngIf="!service?.rows?.length && !service?.loading && (service?._search.length || isActiveFilter)"
>
  {{ 'Global.noResultsState' | translate }}
</div>
