import {AppTourConfig} from '@shared/modules/app-tour/shared/interfaces/app-tour-config.interface';
import {AppTourTypes} from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import {CustomIStepOptionInterface} from '@shared/modules/app-tour/shared/interfaces/custom-i-step-option.interface';

export class AppTourProtocolsListConfig implements AppTourConfig {
  static readonly type: AppTourTypes = AppTourTypes.PROTOCOLS_LIST;
  static readonly steps: CustomIStepOptionInterface[] = [
    {
      anchorId: 'protocolsList.s0.welcome',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s1.settings'
    },
    {
      anchorId: 'protocolsList.s2.notifications'
    },
    {
      anchorId: 'protocolsList.s3.loggedCompany'
    },
    {
      anchorId: 'protocolsList.s4.filters',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s5.list',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s6.version0',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s7.status0',
      hiddenOnMobile: true,
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s8.stuffs0',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s9.toggleMenu0',
      preventScrolling: true
    },
    {
      anchorId: 'protocolsList.s10.add',
      preventScrolling: true
    }
  ];
  static readonly startComponentName = 'protocols-list-all';
}
