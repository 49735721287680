<div class="list-header">
  <div class="list-header-title">
    {{ title | translate }}
    <ng-container *ngTemplateOutlet="headerTemplate">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-container>
  </div>
  <div class="list-header__actions">
    <app-button
      *ngIf="service.config.urlExport"
      class="mr-1"
      (onClick)="downloadExcel()"
      [config]="{
        type: ButtonTypes.LIGHT_BLUE,

        size: ButtonSize.SMALL,
        appendSrc: './assets/img/ic-download-outline-blue.svg'
      }"
    >
    </app-button>

    <app-button
      class="columns-action"
      [matMenuTriggerFor]="showColumnsMenu"
      [config]="{
        type: ButtonTypes.LIGHT_BLUE,
        text: 'List.showColumns' | translate,
        size: ButtonSize.SMALL,
        appendSrc: './assets/img/ic-arrow-down-blue.svg'
      }"
    >
    </app-button>
    <mat-menu #showColumnsMenu="matMenu" xPosition="after">
      <ng-container
        *ngFor="
          let column of service.config.columns.concat(service.config.hideColumns).sort(columnsSort);
          trackBy: identify
        "
      >
        <div
          class="form-control-checkbox d-block mb-2 mt-2"
          *ngIf="!column.toggleMenu && !column.hideNotShow"
          (click)="$event.stopPropagation()"
          [ngClass]="{ 'form-control-checkbox--disabled': column.canNotShow === false }"
        >
          <label class="m-0 d-flex align-items-center">
            <input
              type="checkbox"
              [checked]="!column.notShow"
              (change)="onColumnShowChange(column)"
              [disabled]="column.canNotShow === false"
            />
            <span></span>
            <span [innerHTML]="column.name"></span>
          </label>
        </div>
      </ng-container>
    </mat-menu>
  </div>
</div>
