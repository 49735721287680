import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  constructor() {}

  transform(html: string): string {
    return html?.toString()?.replace(/(<([^>]+)>)/gi, '');
  }
}
