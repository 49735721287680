<ng-container *ngIf="service.calendarEvent"
  ><sidenav-title
    [title]="service.calendarEvent?.name"
    [isEditEnabled]="true"
    (editClick)="editOpen()"
  ></sidenav-title>
  <div class="details">
    <div *ngIf="service.calendarEvent?.calendarName" class="mb-4">
      <div class="new-select-label">{{ 'Header.calendar' | translate }}</div>
      <div class="details-value">{{ service.calendarEvent?.calendarName }}</div>
    </div>

    <div class="mb-4">
      <div class="details-value">{{ service.calendarEvent?.name }}</div>
    </div>
    <div class="mb-4 d-flex flex-row time-gap">
      <div>
        <div class="new-select-label">{{ 'CalendarEvents.dateAndTime' | translate }}</div>
        <div class="details-value">
          {{
            service.calendarEvent.termStart
              | rangeFormatV2 : service.calendarEvent.termEnd : service.calendarEvent.isFullDay : false : true
          }}
        </div>
      </div>
      <div>
        <div class="new-select-label"></div>
        <div class="details-value">
          {{
            service.calendarEvent.termStart
              | rangeFormatV2 : service.calendarEvent.termEnd : service.calendarEvent.isFullDay : true
          }}
        </div>
      </div>
    </div>
    <div *ngIf="service.calendarEvent.isFullDay" class="mb-4">
      <div class="details-value">{{ 'CalendarEvents.wholeDay' | translate }}</div>
    </div>

    <div *ngIf="service.calendarEvent?.project?.fullName" class="mb-4">
      <div class="new-select-label">{{ 'Projects.projectName' | translate }}</div>
      <div class="details-value">
        {{ service.calendarEvent.project?.fullName || '' }}
      </div>
    </div>

    <div *ngIf="service.calendarEvent?.address" class="mb-4">
      <div class="details-value">
        {{ service.calendarEvent?.address }}
      </div>
    </div>

    <div class="mb-4">
      <div class="new-select-label">{{ 'CalendarEvents.participants' | translate }}</div>
      <div class="details-value">
        <span *ngFor="let participant of service.calendarEvent?.participants; let last = last">
          {{ participant.contact?.fullName || participant.email
          }}<ng-container *ngIf="!last">,&nbsp;</ng-container>
        </span>
      </div>
    </div>

    <div *ngIf="service.calendarEvent?.description" class="mb-4">
      <div
        class="details-value ql-editor"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(service.calendarEvent?.description)"
      ></div>
    </div>
  </div>
</ng-container>
