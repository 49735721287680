import {TranslateService} from '@ngx-translate/core';

export const getTranslatedFunctions = (functions: { id: number, name: string }[], t: TranslateService) => {
  const translatedFunctions: string[] = [];
  functions ? functions.map((singleFunction) => {
    const translation = t.instant(`Contacts.Function.${singleFunction.name}`);
    if (translation !== `Contacts.Function.${singleFunction.name}`) {
      translatedFunctions.push(translation);
    } else {
      translatedFunctions.push(singleFunction.name);
    }
  }) : null;
  return translatedFunctions;
};
