import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';

@Component({
  selector: 'calendar-events-time',
  templateUrl: './calendar-events-time.component.html',
  styleUrls: ['./calendar-events-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarEventsTimeComponent implements OnInit {
  tempDate: string;
  formattedDate: string;
  iscurrentDate: boolean;

  @Input()
  set date(_date: string) {
    this.tempDate = _date;

    this.formattedDate = moment(this.tempDate, Config.DATE_SERVER_YYMMDD).format('dddd, DD.MM');
    this.iscurrentDate = moment(this.tempDate, Config.DATE_SERVER_YYMMDD).isSame(new Date(), 'day');
    this.changes.detectChanges();
  }

  get date() {
    return this.tempDate;
  }

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
