import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root',
})
export class ContactPreviewResolver implements Resolve<Contact> {
  constructor(
    private service: ContactService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Contact> {
    const id = route.params['id'];

    return this.service.getContact(Number(id)).pipe(
      map((contact: Contact) => {
        if (isNotNullOrUndefined(contact.deleted)) {
          this.s.error(this.t.instant('Contacts.Preview.errorGettingContactDeleted'));
          this.n.go('contacts-list');
          return null;
        }
        return new Contact(contact);
      }),
      catchError((error) => {
        this.s.error(this.t.instant('Contacts.Preview.errorGettingContact'));
        this.n.go('contacts-list');
        return of(error);
      }),
    );
  }
}
