<section class="project-question-directory">
  <div class="project-question-directory__title project-question-directory__title--no-image">
    <content-action-title
      [title]="('Projects.Menu.questions' | translate) + ' 3'"
      [subtitle]="'Projects.mainContactDescription' | translate"
      [rightSideTemplate]="rightSideTemplate"
    >
      <ng-template #rightSideTemplate>
        <div class="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap">
          <div class="form-control-checkbox mt-3">
            <label class="m-0 d-flex align-items-center">
              <input type="checkbox" [formControl]="showRemovedEntries" />
              <span></span>
              <span [innerHTML]="'Projects.showRemoved' | translate"></span>
            </label>
          </div>
        </div>
      </ng-template>
    </content-action-title>
  </div>

  <div class="pt-2" *ngFor="let entry of entryListsConfig; let i = index">
    <div
      [ngClass]="{ 'project-question-directory__region-title--single': !entry?.description?.length }"
      class="project-question-directory__region-title"
    >
      {{ i + 1 }}. {{ entry.title | translate }}
    </div>
    <div *ngIf="entry.description" class="project-question-directory__region-subtitle">
      {{ entry.description | translate }}
    </div>

    <entry-list
      [snackBottom]="false"
      [showDeleted]="showRemovedEntries.value"
      [id]="projectStore.projectCtrl?.project?.id"
      [objectType]="EntryObjectType.OBJECT_TYPE_DATA_BOX_3"
      [buttonTitle]="'Projects.addEntry' | translate"
      [placeholder]="'Projects.enterText' | translate"
      [type]="entry.entryType"
      [manageType]="entry.type"
      [entryModalConfig]="entry.entryModalConfig"
      [attachmentsConfig]="entry.attachmentsConfig"
      [items]="entry.list"
      (onEntry)="updateListEntry(ProjectThirdBoxEntries, $event, entry.entryType, 'dataBox3')"
    ></entry-list>
    <hr />
  </div>

  <!-- <div class="w-100 client-comments">
    <div class="project-question-directory__region-title project-question-directory__region-title--single">
      {{ entryListsConfig?.length + 1 }}. {{ 'Projects.additionalMarks' | translate }}
    </div>
    <gbxsoft-textarea
      [formControl]="additionalComment"
      [config]="{ name: 'Projects.additionalComments' | translate }"
    ></gbxsoft-textarea>
  </div> -->

  <!-- <directory-first-tasks></directory-first-tasks> -->
  <div class="mt-5">
    <commentary-manage
      #comments
      [attachmentsConfig]="attachmentsConfig"
      [id]="projectStore.projectCtrl?.project?.id"
      [type]="CommentaryType.BOX_THIRD"
      [commentaries]="projectStore.projectCtrl.project?.dataBox3?.comments"
      (commentaryEvent)="updateCommentaries($event.data, 'dataBox3')"
    ></commentary-manage>
  </div>
</section>
