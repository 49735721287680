import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ProjectTeamToggleMenuCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-toggle-menu-cell/project-team-toggle-menu-cell.component';

export const ProjectTeamListConfig: ListConfig = {
  columns: [
    {
      prop: 'employee',
      name: 'ProjectTeam.List.employee',
      componentRef: 'projectTeamEmployeeCellComponent',
      sortField: 'user.name',
      resizeable: false,
      hideOnMobile: false,
      width: 350,
      mobileWidth: 250,
      isTreeColumn: false,
      canNotShow: false,
      order: 1
    },
    {
      prop: 'role',
      name: 'ProjectTeam.List.role',
      componentRef: 'projectTeamRoleCellComponent',
      sortField: 'a.position',
      resizeable: false,
      hideOnMobile: true,
      width: 150,
      order: 2
    },
    {
      prop: 'phone',
      name: 'ProjectTeam.List.phone',
      componentRef: 'projectTeamPhoneCellComponent',
      sortField: 'userPerson.phone',
      resizeable: false,
      hideOnMobile: false,
      width: 200,
      order: 4
    },
    {
      prop: 'email',
      name: 'ProjectTeam.List.email',
      componentRef: 'projectTeamEmailCellComponent',
      sortField: 'userPerson.email',
      resizeable: false,
      hideOnMobile: true,
      width: 250,
      order: 5
    },
    {
      prop: 'function',
      name: 'ProjectTeam.List.function',
      componentRef: 'projectTeamFunctionCellComponent',
      sortField: 'function.name',
      resizeable: false,
      hideOnMobile: false,
      width: 200,
      order: 6
    },
    {
      prop: 'roleInProject',
      name: 'ProjectTeam.List.roleInProject',
      componentRef: 'projectTeamRoleProjectCellComponent',
      resizeable: false,
      hideOnMobile: false,
      sortable: false,
      width: 250,
      order: 7
    },
    {
      toggleMenu: true,
      component: ProjectTeamToggleMenuCellComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 0,
      sortable: false,
      order: 8
    }
  ],
  url: `${Config.API}/project/[{projectId}]/employee/search-advanced`,
  scrollbarH: true,
  listName: 'project-team-list',
  responseKey: 'records',
  listTitle: 'ProjectTeam.List.title'
};
