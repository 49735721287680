import { AppTourProtocolsListConfig } from '@shared/modules/app-tour/shared/tours/app-tour-protocols-list.config';
import { AppTourProtocolManageConfig } from '@shared/modules/app-tour/shared/tours/app-tour-protocol-manage.config';
import { AppTourSecondBoxConfig } from './app-tour-second-box.config';
import { AppTourSecondBoxModalConfig } from './app-tour-second-box-modal.config';

export const appTours: any[] = [
  AppTourProtocolsListConfig,
  AppTourProtocolManageConfig,
  AppTourSecondBoxConfig,
  AppTourSecondBoxModalConfig,
];
