import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { JWT } from '@shared/interfaces/jwt.interface';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { BaseComponent } from '@shared/components/base.component';
import { StorageEvent, StorageEventType } from '@core/services/storage.service';
import { Company } from '@shared/models/company.model';
import { Employee } from '@shared/models/employee.model';

@Component({
  selector: 'header-action-menu-company',
  templateUrl: './header-action-menu-company.component.html',
  styleUrls: ['./header-action-menu-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderActionMenuCompanyComponent extends BaseComponent implements OnInit {
  loading: boolean = false;

  constructor(private authService: AuthorizationService, private changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initListener();
  }

  initListener() {
    this.sub = this.store.emmiter.subscribe((e: StorageEvent) => {
      switch (e.type) {
        case StorageEventType.UPDATE_COMPANY:
          this.store.Company = e.company;
          this.employee.company = e.company;
          this.updateCompanyInUserPersonModel(e.company);
          break;
      }
      this.changes.detectChanges();
    });
  }

  updateCompanyInUserPersonModel(company: Company) {
    const userPerson = this.userPerson;
    userPerson.employees.map((em: Employee) => {
      if (em.company.id === company.id) {
        em.company = company;
      }
    });
    this.userPerson = userPerson;
    this.store.UserPerson = userPerson;
    this.changes.detectChanges();
  }

  logToCompany(employeeId: number) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.authService
      .selectCompany(employeeId)
      .subscribe({
        next: (jwt: JWT) => {
          this.s.success(this.t.instant('Auth.Messages.loginSelectedCompany'));
          this.store.Employee = jwt.employee;
          this.store.jwt = jwt.token;
          this.store.resetCalendars();
          setTimeout(() => {
            window.location.href = window.location.pathname;
          }, 300);
        },
        error: () => {
          this.s.error(this.t.instant('Auth.Errors.serverError'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
