import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsAction } from '../interfaces/permissions-action.interface';
import { PermissionsConfig } from '../interfaces/permissions-config';
import { PermissionsGroups } from '../permissions.group';
import { PermissionType } from '@core/permissions/enums/permission-type.enum';

/**
 * @description
 * @author Robert Juszczyk
 * @export
 * @class ContactPermissionsConfig
 * @implements {PermissionsConfig}
 */
export class ContactPermissionsConfig implements PermissionsConfig {
  static readonly ADD: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly LIST: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.CONTACTS;
}
