<modal-title [title]="'Contacts.Preview.addPersonContact' | translate"></modal-title>
<form [formGroup]="form">
  <ng-select
    ngSelectExtension
    [items]="mainContact?.childContacts"
    [formControlName]="'subcontact'"
    class="w-100 select select-default mt-4 mb-4"
    [placeholder]="'Contacts.Preview.chooseSingleSubcontact' | translate"
    [notFoundText]="'Contacts.Preview.notFoundTextContactPerson' | translate"
    [tabIndex]="-1">
    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      {{ getContactInstance(item).fullName }}
    </ng-template>
  </ng-select>
</form>
<mat-dialog-actions align="end">
  <div class="d-flex justify-content-end">
    <app-button
      [class]="'bold'"
      (onClick)="dialogRef.close()"
      [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Contacts.Preview.cancel' | translate
        }"
    ></app-button>
    <app-button
      [class]="'ml-2'"
      [loading]="loading"
      [disabled]="loading"
      (onClick)="setAsContactPerson()"
      [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Contacts.Preview.saveContactPerson' | translate,
          prependSrc: './assets/img/ic-save.svg'
        }"
    >
    </app-button>
  </div>
</mat-dialog-actions>

