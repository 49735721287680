
export class NotificationType {

  static readonly TASK = {

    ASSIGNED_EMPLOYEE: 'task_assigned_employee',                            // (N.2.2.1)

    EDITED_EMPLOYEE: 'task_edited_employee',                                // (N.2.2.2)
    EDITED_CREATOR: 'task_edited_creator',                                  // (N.2.2.3)

    REMOVED_CREATOR: 'task_removed_creator',                                // (N.2.2.4)

    COMPLETED_CREATOR: 'task_completed_creator',                            // (N.2.2.5)
    COMPLETED_EMPLOYEE: 'task_completed_employee',                          // (N.2.2.6)

    REMOVED_EMPLOYEE: 'task_removed_employee',                              // (N.2.2.7)

    EXPIRED_CREATOR: 'task_expired_creator',                                // (N.2.2.8)
    EXPIRED_EMPLOYEE: 'task_expired_employee',                              // (N.2.2.9)

    ASSIGNED_GROUPED: 'task_assigned_grouped',                              // (N.2.2.10)
    EDITED_GROUPED_EMPLOYEE: 'task_edited_grouped_employee',                // (N.2.2.11)
    EDITED_GROUPED_CREATOR: 'task_edited_grouped_creator',                  // (N.2.2.12)

    TASK_COMMENT_CREATED: 'task_comment_created',                          // (N.2.2.13)
  };

  static readonly PROTOCOL = {
    ACCEPTANCE_SENT: 'protocol_acceptance_sent',                            // (N.2.1.2)
    ACCEPTANCE_ACCEPTED: 'protocol_acceptance_accepted',                    // (N.2.1.3)
    ACCEPTANCE_REMINDER: 'protocol_acceptance_reminder',                    // (N.2.1.4)
    ACCEPTANCE_SHOWN_REMINDER: 'protocol_acceptance_shown_reminder',        // (N.2.1.5)

    DRAFT_REMINDER: 'protocol_draft_reminder'                               // (N.2.1.6)
  };

  static readonly PROJECT = {
    PROJECT_CREATED: 'project_created',                                      // (N.2.0.1)
    PROJECT_RESPONSIBLE_ASSIGNED: 'project_responsible_assigned',            // (N.2.0.2)
    PROJECT_STAGE_CHANGED: 'project_stage_changed',                          // (N.2.0.3)
    PROJECT_STAGE_LOST: 'project_stage_lost',                                // (N.2.0.4)
    PROJECT_REMOVED: 'project_removed',                                      // (N.2.0.5)
    PROJECT_ACCOUNT_CREATED_INFO: 'project_account_created_info',            // (N.2.0.7)

    PROJECT_RESPONSIBLE_CHANGED: 'project_responsible_changed',              // (N.2.0.10)
    PROJECT_BOX_TWO_FILLED: 'project_box_two_filled',                        // (N.2.0.12)
  }

  static readonly CALENDAR = {
    EVENT_CREATED: 'calendar_event_created',                                  // (N.4.1)
    EVENT_REMINDER: 'calendar_event_reminder',                                // (N.4.2)
    EVENT_REMOVED: 'calendar_event_removed'                                   // (N.4.3)
  }

}
