export class TaskGroup {
  created: string;
  deleted: string;
  id: number;
  modified: string;
  name: string;
  sortOrder: number;
  immutable?: boolean;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}
