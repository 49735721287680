<div
  class="projects-configurator"
  [ngClass]="{ 'projects-configurator--custom-header': !!active?.snapshot.data?.customHeaderStyles }"
>
  <app-project-creator-manage
    *ngIf="!!projectService?.projectCtrl?.project"
    #configurator
    [headerStepOutOfTemplate]="true"
    [internalCreator]="true"
    [hideFooter]="true"
    [showHeaderLogo]="false"
    [projectValue]="projectService.projectCtrl.project"
    [config]="{
      firstStep: {
        showProjectName: true
      },
      secondStep: {
        showInternalDocumentation: true,
        internal: true
      },
      thirdStep: {},
      fourthStep: {
        internal: true
      }
    }"
  ></app-project-creator-manage>
</div>
