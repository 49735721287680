<span
  class="price-cell"
  [ngClass]="{
    'price-cell-grey': _priceValue === 0,
    'price-cell-green': _priceValue > 0,
    'price-cell-red': _priceValue < 0
  }"
  [title]="_price"
  [innerHTML]="_price"
  *ngIf="!customText?.length"
>
</span>

<span class="price-cell price-cell-orange" *ngIf="!!customText?.length">
  {{ customText }}
</span>
