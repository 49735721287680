import Quill from 'quill';
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
  quill: any;
  constructor(quill: any, options: any) {
    super(quill, options);
    quill = quill;
  }

  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

export default PlainClipboard;

export const editorInit = (quill: any) => {
  quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
    delta.forEach((e) => {
      if (e.attributes) {
        e.attributes.color = '';
        e.attributes.background = '';
      }
    });
    return delta;
  });
};
