<form [formGroup]="form">
  <ng-select
    #projectSelect="ngSelectExtensionDir"
    ngSelectExtension
    [ngClass]="{
      'ng-select--required': isFieldRequired,
      'ng-select--error': isControlInvalid(controlName)
    }"
    class="new-select {{ class }}"
    [tourAnchor]="'protocolManage.s5.project'"
    [url]="URL"
    [addItems]="addProject"
    [initialValue]="initialValue"
    [clearAllText]="'clearAll' | translate"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [addTag]="addTag ? validateProjectTag.bind(this) : false"
    [loadingText]="'Protocols.loading' | translate"
    [loading]="loading"
    [placeholder]="(placeholder ? placeholder : 'Protocols.addProject') | translate"
    [notFoundText]="'Protocols.notFoundText' | translate"
    [addTagText]="'Protocols.addNewProject' | translate"
    [responseTransform]="successOnLoadProjects.bind(this)"
    [addExistItemFunction]="setProject.bind(this)"
    [changePromise]="changePromiseFunc.bind(this)"
    [tabIndex]="-1"
    [readonly]="disabled"
    [clearable]="clearable"
    [formControlName]="controlName"
    [sortByField]="'created'"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
      <span class="ng-value-label">
        {{ item.display }}
      </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
      <span class="ng-arrow-option" [innerHTML]="item.display" [title]="item.address"></span>
    </ng-template>
  </ng-select>
  <span
    *ngIf="isControlInvalid(controlName)"
    class="d-flex form-control-error-msg mt-1"
    [innerHTML]="'FormErrors.required' | translate"
  ></span>
</form>
