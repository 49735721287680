import { Component, OnInit, ChangeDetectorRef, Inject, AfterViewInit } from '@angular/core';
import { GbxsoftSelectConfig } from '@form/src/lib/gbxsoft-select/gbxsoft-select.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService, SettingEventType } from '@modules/settings/shared/services/settings.service';
import { BaseComponent } from '@shared/components/base.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { Employee } from '@shared/models/employee.model';

@Component({
  selector: 'app-team-edit-employee',
  templateUrl: './team-edit-employee.component.html',
  styles: [],
})
export class TeamEditEmployeeComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  positionSelectConfig: GbxsoftSelectConfig = {
    id: 'id',
    label: 'text',
    notFoundText: this.t.instant('Global.notFoundResults'),
    searching: false,
    multiple: false,
    allowClear: false,
    closeOnClear: false,
    closeOnSelect: true,
    debounceTime: 0,
    valueName: 'id',
    addTag: false,
    enableUnselect: false,
    placeholder: this.t.instant('Settings.Team.employeePosition'),
    labelName: this.t.instant('Settings.Team.employeePosition'),
  };

  positionSelectOptions = [];

  constructor(
    public dialogRef: MatDialogRef<TeamEditEmployeeComponent>,
    public service: SettingsService,
    private changes: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.setPositions();
    this.setEmployee(this.data['employee']);
  }

  ngAfterViewChecked() {
    this.changes.detectChanges();
  }

  setPositions() {
    Object.keys(UserRoles).forEach((key) => {
      this.positionSelectOptions.push({
        id: UserRoles[key],
        text: this.t.instant('ROLES.' + key),
      });
    });
  }

  setEmployee(employee: Employee) {
    this.form.get('role').setValue(employee.role);
  }

  createForm() {
    this.form = new FormGroup({
      role: new FormControl('', [Validators.required]),
      hourlyRate: new FormControl(this.data['employee'].hourlyRate, [Validators.min(0)]),
      id: new FormControl(this.data['employee'].id),
    });
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }
    this.loading = true;
    this.service
      .editEmployee(this.form.value)
      .subscribe({
        next: this.onSuccessEditEmployee.bind(this),
        error: this.onErrorEditEmployee.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  onSuccessEditEmployee() {
    this.s.success(this.t.instant('Settings.Team.employeeEdited'));
    this.service.emitter.emit({type: SettingEventType.RELOAD_EMPLOYEES_LIST});
    this.form.reset();
    this.dialogRef.close();
  }

  onErrorEditEmployee(e: HttpError) {
    switch (e.messageCode) {
      default:
        this.s.error(this.t.instant('Settings.Team.Errors.errorEditEmployee'));
        break;
    }
  }

  closeModal() {
    this.form.reset();
    this.dialogRef.close();
  }

  get currencySymbol() {
    return this.store.Employee?.company?.currency?.symbol;
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return messages;
  }
}
