import { Component, EventEmitter, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectAlert } from '@modules/projects/shared/models/project-alert.model';

@Component({
  selector: 'project-alerts-modal',
  templateUrl: './project-alerts-modal.component.html',
  styleUrls: ['./project-alerts-modal.component.scss']
})
export class ProjectAlertsModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  project: Project;
  eventEmitter: EventEmitter<Project> = new EventEmitter<Project>();
  form: FormGroup = new FormGroup({
    alerts: new FormArray([])
  });

  get alertsForms(): FormArray {
    return this.form.get('alerts') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<ProjectAlertsModalComponent>,
    private t: TranslateService,
    private service: ProjectAPIService,
    private s: SnackBarService
  ) {
  }

  ngOnInit(): void {
    this.project.alerts.map((a: ProjectAlert) => this.addAlert(a.costsLimit));
  }

  addAlert(value?: number) {
    if(this.alertsForms?.length >= 5) return;
    this.alertsForms.push(new FormControl(value || value === 0 ? value : '', [Validators.required, Validators.min(0)]));
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.loading || !this.form.valid) {
      return;
    }
    this.loading = true;
    this.service.setAlerts(this.project.id, this.alertsForms.value).subscribe({
      next: (res: ProjectAlert[]) => {
        this.s.success(this.t.instant('ProjectSummary.RightSection.successAddAlerts'));
        this.project.alerts = res;
        this.eventEmitter.emit(this.project);
        this.dialogRef.close();
      },
      error: () => {
        this.s.error(this.t.instant('ProjectSummary.RightSection.errorAddAlerts'));
      }
    }).add(() => {
      this.loading = false;
    });
  }

  errorMessages(control: AbstractControl) {
    const messages = Config.validationMessages;

    if (control?.errors?.max?.max) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max?.max,
      });
    }

    if (control?.errors?.min?.min || control?.errors?.min?.min === 0) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.min', {
        number: control.errors?.min?.min.toString(),
      });
    }

    return messages;
  }

  removeAlert(index) {
    this.alertsForms.removeAt(index);
  }

}
