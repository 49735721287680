import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modal-title',
  templateUrl: './modal-title.component.html',
  styleUrls: ['./modal-title.component.scss'],
})
export class ModalTitleComponent implements OnInit {
  @Input() title: string;
  constructor() {}

  ngOnInit(): void {}
}
