import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Project } from '@modules/projects/shared/models/project.model';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { StorageService } from '@core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'inside-warning-notification',
  templateUrl: './inside-warning-notification.component.html',
  styleUrls: ['./inside-warning-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsideWarningNotificationComponent implements OnInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  PermissionsGroups = PermissionsGroups;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() actionButtonText: string;
  @Input() discardButtonText: string;
  @Input() addEventButton: boolean = false;
  @Input() project: Project;

  @Output('onAction') onAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onDiscard') onDiscard: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private store: StorageService, private t: TranslateService) {}

  ngOnInit(): void {}

  addEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent({
      projectId: this.project.id,
      title: this.t.instant('Projects.meetingWith', { companyName: this.store.Company.name }),
    });
  }
}
