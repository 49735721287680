import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { SnackBarService } from '@core/services/snackbar.service';
import { endDateHelper } from '@shared/helpers/date-format.helper';

@Component({
  selector: 'daterangepicker',
  templateUrl: './date-rangepicker.component.html',
  styleUrls: ['./date-rangepicker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, AfterViewInit {
  dateControl: FormControl = new FormControl();
  isError: boolean = false;
  private startDate: moment.Moment;
  private endDate: moment.Moment;
  private isUpdatingCalendar: boolean = false;
  private isUpdatingForm: boolean = false;

  locale: any = {
    format: Config.DATETIME_FORMAT_DOTS,
    applyLabel: this.t.instant('DATERANGEPICKER.acceptBtn'),
    cancelLabel: this.t.instant('DATERANGEPICKER.cancelBtn'),
    firstDay: 1,
    daysOfWeek: this.t.instant('DATERANGEPICKER.daysOfWeek'),
    monthNames: this.t.instant('DATERANGEPICKER.monthNames'),
  };

  _config: any = this.defaultConfig;
  @Input()
  set config(_config: any) {
    this._config = Object.assign(this.defaultConfig, _config);
    this.setRangeFromControl();
  }

  get config() {
    return this._config;
  }

  @Input() title: string = null;
  @Input() remoteSelect: boolean = false;
  @Input() interactive: boolean = false;
  @Input() disabled: boolean = false;
  @Input() form: FormGroup;
  @Input() hasError: boolean = false;
  @Input() clearable: boolean = true;
  @Input() controlStart: string = 'start';
  @Input() controlEnd: string = 'end';

  @Input() isCalendarTime: boolean = false; // add option

  @Output() rangeChanged: EventEmitter<{ start: moment.Moment; end: moment.Moment }> = new EventEmitter();

  @ViewChild('daterangepicker') daterangepicker: DaterangepickerComponent;
  @ViewChild(MatMenuTrigger) picker: MatMenuTrigger;

  updateTimer = null;

  totalControl: FormControl = new FormControl();

  get defaultConfig() {
    return {
      timePicker24Hour: true,
      timePicker: true,
      timePickerIncrement: 1,
      viewFormat: Config.DATE_FORMAT_DOTS,
    };
  }

  get startDateValue() {
    return this.getServerValue(this.controlStart);
  }

  get endDateValue() {
    return this.getServerValue(this.controlEnd);
  }

  constructor(
    private fb: FormBuilder,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private s: SnackBarService,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    this.daterangepicker.clickCancel = () => this.picker.closeMenu();

    if (this.isCalendarTime) {
      //Todo setup end date, Only for tasks
      // Setup initial end date of 00:00:00
      this.daterangepicker.setEndDate(moment().startOf('day'));
    }
  }

  setRangeFromControl() {
    const startCtrl = this.form?.get(this.controlStart);
    const endCtrl = this.form?.get(this.controlEnd);

    if (startCtrl?.value && endCtrl?.value) {
      const start = moment(startCtrl.value, this.config.viewFormat).format(Config.DATE_SERVER);
      const end = moment(endCtrl.value, this.config.viewFormat).format(Config.DATE_SERVER);
      this.setRange(start, end);
    }
  }

  getServerValue(name: string) {
    return moment(this.form.get(name).value, Config.DATETIME_FORMAT_DOTS).format(Config.DATE_SERVER);
  }

  resetValue() {
    this.dateControl.setValue(null);
    this.form.get(this.controlStart).setValue(null);
    this.form.get(this.controlEnd).setValue(null);
    this.daterangepicker.clear();
    this.daterangepicker.updateCalendars();
    this.daterangepicker.updateView();
    this.rangeChanged.emit({ start: null, end: null });
    this.resetTotalCount();
    this.changes.detectChanges();
  }

  setDefaultRange() {
    this.setRange(this.form.get(this.controlStart).value, this.form.get(this.controlEnd).value);
  }

  private createForm(): void {
    if (!this.form) {
      this.form = this.fb.group({
        [this.controlStart]: [moment().format(this.config.viewFormat)],
        [this.controlEnd]: [moment().format(this.config.viewFormat)],
      });
    } else {
      this.setDefaultRange();
    }

    this.totalControl.setValue(['0D']);

    this.form.get(this.controlStart)?.valueChanges.subscribe((startDate: string) => {
      if (!!this.isUpdatingCalendar) {
        this.isUpdatingCalendar = false;
        return;
      }
      this.isUpdatingForm = true;
      this.updateStartCalendarDate(startDate);
    });

    this.form.get(this.controlEnd)?.valueChanges.subscribe((endDate: string) => {
      if (!!this.isUpdatingCalendar) {
        this.isUpdatingCalendar = false;
        return;
      }
      this.isUpdatingForm = true;
      this.updateEndCalendarDate(endDate);
    });
  }

  setRange(_start: string, _end: string) {
    const start = moment(_start, Config.DATE_SERVER).format(this.config.viewFormat);
    const end = moment(_end, Config.DATE_SERVER).format(this.config.viewFormat);
    this.form.get(this.controlStart).setValue(start);
    this.form.get(this.controlEnd).setValue(end);
    this.startDate = moment(start, this.config.viewFormat);
    this.endDate = moment(end, this.config.viewFormat);
    this.dateControl.setValue(start === end ? end : `${start}-${end}`);

    this.daterangepicker?.setStartDate(
      moment(_start, Config.DATE_SERVER).format(Config.DATETIME_FORMAT_DOTS),
    );

    let currentEnd = _end;

    if (this.isCalendarTime) {
      const endOfDay = moment(_end, Config.DATE_SERVER).endOf('day').format(Config.DATE_SERVER);
      const end = moment(_end, Config.DATE_SERVER).format(Config.DATE_SERVER);
      if (endOfDay === end) {
        currentEnd = moment(_end, Config.DATE_SERVER).startOf('day').format(Config.DATE_SERVER);
      }
    }
    this.daterangepicker?.setEndDate(
      moment(currentEnd, Config.DATE_SERVER).format(Config.DATETIME_FORMAT_DOTS),
    );
    this.daterangepicker?.updateCalendars();
    this.daterangepicker?.updateView();
    this.setTotalCount();
  }

  private updateStartCalendarDate(startDate: string) {
    if (!moment(startDate, Config.DATETIME_FORMAT_DOTS).isValid()) return;
    this.daterangepicker.setStartDate(startDate);
    this.daterangepicker.updateCalendars();
    this.daterangepicker.updateView();
    this.changes.detectChanges();
  }

  private updateEndCalendarDate(endDate: string) {
    if (!moment(endDate, Config.DATETIME_FORMAT_DOTS).isValid()) return;
    this.daterangepicker.setEndDate(endDate);
    this.daterangepicker.updateCalendars();
    this.daterangepicker.updateView();
    this.changes.detectChanges();
  }

  public startDateChanged($event: { startDate: moment.Moment }): void {
    this.startDate = $event.startDate;
    if (!this.isUpdatingForm) {
      this.isUpdatingCalendar = true;
      this.form.get(this.controlStart).setValue(this.startDate.format(this.config.viewFormat));
    }
    this.isUpdatingForm = false;
    this.setTotalCount();
  }

  public endDateChanged($event: { endDate: moment.Moment }): void {
    this.endDate = $event.endDate;
    if (!this.isUpdatingForm) {
      this.isUpdatingCalendar = true;
      this.form.get(this.controlEnd).setValue(this.endDate.format(this.config.viewFormat));
    }
    this.isUpdatingForm = false;
    this.setTotalCount();
  }

  private setTotalCount() {
    if (!this.endDate) return;
    clearTimeout(this.updateTimer);
    const totalCount = () => {
      const diff = this.startDate.diff(this.endDate, 'days');
      const count = this.addWeekdays(this.startDate, Math.abs(diff));
      this.totalControl.setValue(`${count + 1}${this.t.instant('Protocols.dayShort')}`);
      this.daterangepicker.updateView();
    };
    this.updateTimer = setTimeout(() => totalCount(), 600);
  }

  resetTotalCount() {
    this.totalControl.setValue(`1${this.t.instant('Protocols.dayShort')}`);
  }

  public chosenDate($event: { startDate: moment.Moment; endDate: moment.Moment }) {
    this.isError = false;
    const start = this.startDate.format(this.config.viewFormat);
    const end = this.endDate.format(this.config.viewFormat);
    const startValue = moment(this.form.get(this.controlStart).value, this.config.viewFormat);
    const endValue = moment(this.form.get(this.controlEnd).value, this.config.viewFormat);
    if (startValue.isValid() && endValue.isValid()) {
      if (startValue.isSameOrBefore(endValue)) {
        this.dateControl.setValue(start === end ? end : `${start}-${end}`);
        this.emitDates();
      } else {
        this.isError = true;
        this.s.error(this.t.instant('Protocols.chronolodyError'));
      }
    } else {
      this.dateControl.setValue(null);
      this.emitDates();
    }
  }

  emitDates() {
    this.isUpdatingForm = false;
    this.picker.closeMenu();
    let endDate = moment(this.endDate.clone());

    if (this.isCalendarTime) {
      const startDay = moment(endDate.clone()).startOf('day').format(Config.DATE_SERVER);
      const end = moment(endDate.clone()).format(Config.DATE_SERVER);
      if (startDay === end) {
        endDate = endDate.endOf('day');
      }
    }

    this.rangeChanged.emit({
      start: this.startDate,
      end: moment(endDateHelper(endDate.format(Config.DATE_SERVER)), Config.DATE_SERVER),
    });
  }

  private addWeekdays(date: moment.Moment, days: number): number {
    date = moment(date);
    let counter = 0;
    while (!!days) {
      date = date.add(1, 'days');
      counter += 1;
      days -= 1;
    }
    return counter;
  }
}
