import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Employee } from '@shared/models/employee.model';

export class AssignSearchDropdown {
  contacts: Contact[];
  employees: Employee[];

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    this.deserialize();
  }

  private deserialize() {
    this.contacts.length ? this.contacts = this.contacts.map(c => new Contact(c)) : '';
    this.employees.length ? this.employees = this.employees.map(e => new Employee(e)) : '';
  }

}
