import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { Injector } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';
import { AppInjector } from '@shared/services/app-injector.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthorizationService } from '../service/authorization.service';
import { EmailVerificationComponent } from '@modules/authorization/pages/auth-panel/components/email-verification.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { userCodes } from '@shared/consts/user-codes';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { finalize, timeoutWith } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const REGISTER_FORM = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  captcha: 'captcha',
  gdprAccepted: 'gdprAccepted',
};

export class RegisterController {
  REGISTER_FORM = REGISTER_FORM;

  private injector: Injector;
  private service: AuthorizationService;
  private fb: FormBuilder;
  private t: TranslateService;
  private recaptchaV3Service: ReCaptchaV3Service;
  private s: SnackBarService;
  private dialog: MatDialog;

  callback: () => void;

  loading: boolean = false;
  form: FormGroup;

  get initialFormValues() {
    return {
      [REGISTER_FORM.firstName]: null,
      [REGISTER_FORM.lastName]: null,
      [REGISTER_FORM.email]: null,
      [REGISTER_FORM.password]: null,
      [REGISTER_FORM.captcha]: null,
      [REGISTER_FORM.gdprAccepted]: true,
    };
  }

  constructor() {
    this.injector = AppInjector.getInjector();
    this.service = this.injector.get(AuthorizationService);
    this.fb = this.injector.get(FormBuilder);
    this.t = this.injector.get(TranslateService);
    this.s = this.injector.get(SnackBarService);
    this.dialog = this.injector.get(MatDialog);
    this.recaptchaV3Service = this.injector.get(ReCaptchaV3Service);
  }

  createForm() {
    this.form = this.fb.group(this.initialFormValues);
  }

  setCallback(callback: () => void): void {
    this.callback = callback;
  }

  setValidatorsSimple() {
    this.setSingleValidator(REGISTER_FORM.email, [Validators.required, Validators.pattern(Regex.email)]);
    this.setSingleValidator(REGISTER_FORM.password, [Validators.required, Validators.minLength(6)]);
  }

  setValidatorsPersonalData() {
    this.setSingleValidator(REGISTER_FORM.firstName, [Validators.required]);
    this.setSingleValidator(REGISTER_FORM.lastName, [Validators.required]);
  }

  setSingleValidator(name: string, validators: ValidatorFn[]) {
    const control = this.form.get(name);
    control.setValidators(validators);
    control.updateValueAndValidity({ emitEvent: false });
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength,
      });
    }

    return messages;
  }

  submitSignUp(extra?: any) {
    this.form.controls.gdprAccepted.setValue(true);
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid) return;
    this.loading = true;

    this.captchaValidation()
      .then((token: string) => {
        this.form.controls.captcha.setValue(token);

        if (!token.length) {
          this.s.error(this.t.instant('Auth.Errors.captchaValidation'));
          return;
        }
        this._signUpHandler(extra);
      })
      .catch(() => this._signUpHandler(extra));
  }

  private captchaValidation(): Promise<string> {
    return this.recaptchaV3Service
      .execute('signUp')
      .pipe(timeoutWith(5000, throwError(new Error('timeout'))))
      .toPromise();
  }

  private _signUpHandler(extra: any = {}) {
    this.service.email = this.form.controls.email.value;
    const model = Object.assign(this.form.value, extra);

    this.service
      .signUp(model)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (data) => this._successSignUp(data),
        error: (err) => this._errorSignUp(err),
      });
  }

  private _successSignUp(data) {
    this.loading = false;
    this.form.reset();
    this.dialog.open(EmailVerificationComponent, {
      width: '1170px',
    });
    this.s.success(this.t.instant('Auth.Messages.successSignUp'));
    this.callback ? this.callback() : null;
  }

  private _errorSignUp(err: HttpError) {
    this.loading = false;
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    switch (err.messageCode) {
      case userCodes.ALREADY_EXISTS:
        this.s.error(this.t.instant('Auth.Errors.accountExist'));
        break;
      case IntilioCodes.SIMPLE_PASSWORD:
        this.form.get(REGISTER_FORM.password).setValue(null);
        this.s.error(this.t.instant('Auth.Errors.passwordSimple'));
        break;
      default:
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        break;
    }
  }
}
