import { Attachment } from '@shared/interfaces/attachment.interface';
import { AttachmentService } from '@shared/services/attachment.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { Injector } from '@angular/core';

export class AttachmentController {
  injector: Injector;
  service: AttachmentService;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.service = this.injector.get(AttachmentService);
  }

  restoreAttachment(attachment: Attachment) {
    return this.service.restoreAttachment(attachment.id);
  }
}
