import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { ITaskStatus } from '../interfaces/task-status-list.interface';

export const ChangeStatusList: ITaskStatus[] = [
  {
    id: ProjectChangesStatus.CHANGE_STATUS_CREATED,
    label: `Tasks.ChangeStatus.${ProjectChangesStatus.CHANGE_STATUS_CREATED}`,
    disabled: false,
  },
  {
    id: ProjectChangesStatus.CHANGE_STATUS_SHARED,
    label: `Tasks.ChangeStatus.${ProjectChangesStatus.CHANGE_STATUS_SHARED}`,
    disabled: false,
  },
  {
    id: ProjectChangesStatus.CHANGE_STATUS_ACCEPTED,
    label: `Tasks.ChangeStatus.${ProjectChangesStatus.CHANGE_STATUS_ACCEPTED}`,
    disabled: false,
  },
  {
    id: ProjectChangesStatus.CHANGE_STATUS_REJECTED,
    label: `Tasks.ChangeStatus.${ProjectChangesStatus.CHANGE_STATUS_REJECTED}`,
    disabled: false,
  },
  {
    id: ProjectChangesStatus.CHANGE_STATUS_AUTO_ACCEPT,
    label: `Tasks.ChangeStatus.${ProjectChangesStatus.CHANGE_STATUS_AUTO_ACCEPT}`,
    disabled: true,
  },
];
