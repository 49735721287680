<div class="mailing-changes-summary py-3">
  <div>
    <!-- #region tasks data -->
    <div class="mailing-changes-summary__title">{{ 'Mailings.Summary.changesToAccept' | translate }}</div>
    <div class="p-4 mailing-changes-summary-costs">
      <div class="mailing-changes-summary-costs__text">
        <span class="d-inline-block mb-1">{{ 'Mailings.Summary.increasingBudget' | translate }}</span>
        <span class="grey-1">
          {{
            changesSummary?.selectedChangesSummary?.increasingChangesSum || 0 | priceFormat: false:currency
          }}
        </span>
      </div>
      <div class="mailing-changes-summary-costs__text">
        <span class="d-inline-block mb-1">{{ 'Mailings.Summary.decreasingBudget' | translate }}</span>
        <span class="text-danger bold">
          {{
            changesSummary?.selectedChangesSummary?.decreasingChangesSum || 0 | priceFormat: false:currency
          }}
        </span>
      </div>
      <div class="mailing-changes-summary-costs__text">
        <span class="d-inline-block mb-1">{{ 'ProjectChanges.additionalDays' | translate }}</span>
        <span class="blue-500">
          {{ changesSummary?.selectedChangesSummary?.additionalTimeSum || 0 }}
        </span>
      </div>
    </div>
    <!-- #endregion -->
  </div>
  <hr />
  <div class="mailing-changes-summary__title">{{ 'Mailings.Summary.costAcceptedChanges' | translate }}</div>
  <div class="p-4 mailing-changes-summary-costs">
    <div class="mailing-changes-summary-costs__text">
      <span class="d-inline-block mb-1">{{ 'Mailings.Summary.increasingBudget' | translate }}</span>
      <span class="grey-1">
        {{ changesSummary?.changesSummary?.increasingChangesSum || 0 | priceFormat: false:currency }}
      </span>
    </div>
    <div class="mailing-changes-summary-costs__text">
      <span class="d-inline-block mb-1">{{ 'Mailings.Summary.decreasingBudget' | translate }}</span>
      <span class="text-danger bold">
        {{ changesSummary?.changesSummary?.decreasingChangesSum || 0 | priceFormat: false:currency }}
      </span>
    </div>
    <div class="mailing-changes-summary-costs__text">
      <span class="d-inline-block mb-1">{{ 'ProjectChanges.additionalDays' | translate }}</span>
      <span class="blue-500">
        {{ changesSummary?.changesSummary?.additionalTimeSum || 0 }}
      </span>
    </div>
  </div>
</div>
