import { DateTimeFormatPipe } from '@shared/pipes/dateTimeFormat.pipe';
import { HttpError } from '@shared/interfaces/error.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Task } from '@shared/models/task.model';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { Regex } from '@shared/configs/regex';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TaskSidenavService } from '../../services/task-sidenav.service';
import { ETaskAction } from '../../enums/task-action.enum';
import { Project } from '@modules/projects/shared/models/project.model';

export const TASK_CHANGE_DIALOG_FORM = {
  title: 'title',
  content: 'content',
  email: 'email',
  tasksIds: 'tasksIds',
  projectId: 'projectId',
};
@Component({
  templateUrl: './task-change-share-dialog.component.html',
  styleUrls: ['./task-change-share-dialog.component.scss'],
})
export class TaskChangeShareDialogComponent implements OnInit {
  TASK_CHANGE_DIALOG_FORM = TASK_CHANGE_DIALOG_FORM;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  form: FormGroup;

  loading: boolean = false;

  onShared: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NgSelectComponent) contactSelect: NgSelectComponent;

  get project() {
    return this.data?.project || this.data?.task?.project || null;
  }

  get projectShortInfo() {
    return `${this.project?.fullAddress || ''} ${this.project?.name || ''} ${
      this.project?.projectId || ''
    }`.trim();
  }

  get title() {
    return this.t.instant('ProjectChanges.newChangeInProject', {
      project: '',
    });
  }

  get message() {
    return `${this.t.instant('ProjectChanges.greetings')}<br><br>${
      this.data?.task?.protocol ? this.protocolMessage : this.projectMessage
    }<br><br>${this.t.instant('ProjectChanges.pleaseAcceptChanges')}`;
  }

  get protocolMessage() {
    const date = new DateTimeFormatPipe().transform(this.data?.task?.protocol?.modified);
    const type = this.t.instant('Protocols.Types.' + this.data?.task?.protocol?.type);
    const protocolNumber = this.data?.task?.protocol?.idNumber || '';
    return this.t.instant(
      protocolNumber
        ? 'ProjectChanges.pleaseAcceptDataWithActivation'
        : 'ProjectChanges.pleaseAcceptDataWithoutActivation',
      { project: this.projectShortInfo, type, protocolNumber, date },
    );
  }

  get projectMessage() {
    return this.t.instant('ProjectChanges.newChangeInProjectEdit', {
      project: this.projectShortInfo,
    });
  }

  get assignedEmail() {
    return this.contactSelect?.selectedItems.map((i) => (i.value as any)?.email)[0] || null;
  }

  get initialFormValues() {
    return {
      [TASK_CHANGE_DIALOG_FORM.projectId]: Number(this.data.projectId),
      [TASK_CHANGE_DIALOG_FORM.title]: this.title,
      [TASK_CHANGE_DIALOG_FORM.content]: this.message,
      [TASK_CHANGE_DIALOG_FORM.email]: null,
      [TASK_CHANGE_DIALOG_FORM.tasksIds]: [],
    };
  }

  get EmailsUrl() {
    return `${Config.API}/contact/emails${!!this.data?.projectId ? '?projectId=' + this.data.projectId : ''}`;
  }

  get errorMessages() {
    return Config.validationMessages;
  }

  constructor(
    private fb: FormBuilder,
    private service: TaskService,
    private t: TranslateService,
    private s: SnackBarService,
    private taskSidenav: TaskSidenavService,
    public dialogRef: MatDialogRef<TaskChangeShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { task?: Task; taskIds?: number[]; projectId: number; project?: Project },
  ) {}

  ngOnInit(): void {
    this._createForm();
    this._setValidators();
    let ids = [];
    this.data?.taskIds?.length ? (ids = this.data?.taskIds) : null;
    this.data?.task?.id ? (ids = [this.data?.task.id]) : null;
    this.setTaskIds(ids);
  }

  private _createForm() {
    this.form = this.fb.group(this.initialFormValues);
  }

  setTaskIds(taskIds: number[]) {
    this.form.get(TASK_CHANGE_DIALOG_FORM.tasksIds).setValue(taskIds);
  }

  _setValidators() {
    this.setRequiredField(TASK_CHANGE_DIALOG_FORM.title);
    this.setRequiredField(TASK_CHANGE_DIALOG_FORM.content);
    this.setRequiredField(TASK_CHANGE_DIALOG_FORM.email);
  }

  setRequiredField(name: string) {
    this.form.get(name).setValidators([Validators.required]);
    this.form.get(name).updateValueAndValidity();
  }

  transformEmails(resp) {
    const contacts: any = resp?.contacts?.length ? resp.contacts : [];
    const emails: any = resp?.emails?.length ? resp.emails : [];
    return contacts?.map((i) => new Contact(i)).concat(emails?.map((i) => this.createCustomTag(i?.email)));
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.createCustomTag($event);
  }

  createCustomTag(name: string): Record<any, string> {
    return { email: name, fullName: name, id: name };
  }

  // #region Share change
  submitShare() {
    Object.keys(TASK_CHANGE_DIALOG_FORM).forEach((key: string) => {
      this.form.get(key)?.markAsTouched();
      this.form.get(key)?.updateValueAndValidity({ emitEvent: false });
    });

    if (this.loading || this.form.invalid) return;
    this.loading = true;
    const model = Object.assign({}, this.form.value);
    model.email = this.assignedEmail;

    this.service
      .shareChange(model)
      .subscribe({ next: this.successOnShareChange.bind(this), error: this.errorOnShareChange.bind(this) })
      .add(() => (this.loading = false));
  }

  successOnShareChange() {
    this.taskSidenav.taskActionEmitter.emit({
      action: ETaskAction.ACTION_SHARE,
      data: { task: this.data?.task, taskIds: this.data?.taskIds },
    });
    this.onShared.emit(true);
    this.dialogRef.close();

    this.s.success(
      this.t.instant(
        this.data?.taskIds?.length > 1 || !this.data?.task
          ? 'ProjectChanges.successSharedChanges'
          : 'ProjectChanges.successSharedChange',
      ),
    );
  }

  errorOnShareChange(err: HttpError) {
    this.s.error(
      this.t.instant(
        this.data?.taskIds?.length > 1 || !this.data?.taskIds?.length
          ? 'ProjectChanges.errorSharedChanges'
          : 'ProjectChanges.errorSharedChange',
      ),
    );
  }
  // #endregion

  isControlInvalid(name: string) {
    return this.form?.get(name)?.touched && this.form?.get(name)?.invalid;
  }
}
