import { EventEmitter } from '@angular/core';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { CalendarLeftSectionController } from './calendar-left-section.controller';
import { CalendarSettingsController } from './calendar-settings.controller';
import { Subscription } from 'rxjs';
import { CallendarMainController } from './calendar-main.controller';
import { EventAction } from '@shared/modules/event-sidenav/enums/event-action.enum';
import { ETaskAction } from '@shared/modules/task-sidenav/enums/task-action.enum';
import { ECalendarAction } from '../enums/calendar-action.enum';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ICalendarEmitter } from '../interfaces/calendar-emitter.interface';
import { CalendarView } from '../models/calendar-view.model';

export class CalendarSubscriptionController {
  calendarEmmiter: EventEmitter<ICalendarEmitter> = new EventEmitter();
  calendarUpdateSub: Subscription = new Subscription();

  get calendarEvents() {
    return this.mainCtrl.fullCalendarView.pipe(
      tap((i: CalendarView) => this.calendarEmmiter.emit({ action: ECalendarAction.CALENDAR_UP_TO_DATE })),
    );
  }

  constructor(
    private settingsCtrl: CalendarSettingsController,
    private leftCtrl: CalendarLeftSectionController,
    private mainCtrl: CallendarMainController,
    private eventService: EventSidenavService,
    private taskService: TaskSidenavService,
  ) {}

  initSubscription() {
    this.calendarUpdateSub = new Subscription();
  }

  resetSubscription() {
    this.calendarUpdateSub?.unsubscribe();
  }

  subscribeEvents() {
    this.subscribeSettingsEmitter();
    this.subscribeLeftSectionEmitter();
    this.subscribeCalendarEmitter();
    this.subscribeSidenavEventsEmitter();
    this.subscribeSidenavTasksEmitter();
  }

  subscribeSettingsEmitter() {
    const sub = this.mainCtrl.optionsChanged
      .pipe(debounceTime(250))
      .pipe(switchMap(() => this.calendarEvents))
      .subscribe();
    this.calendarUpdateSub.add(sub);
  }

  subscribeLeftSectionEmitter() {
    // 1. Allow only one request to be called
    const sub = this.leftCtrl.calendarsObservables
      .pipe(debounceTime(250))
      .pipe(switchMap(() => this.calendarEvents))
      .subscribe();
    this.calendarUpdateSub.add(sub);
  }

  //#region Calendar Emitter
  subscribeCalendarEmitter() {
    const sub = this.calendarEmmiter
      .pipe(
        filter((i) => {
          const events = [ECalendarAction.UPDATE_VIEW, ECalendarAction.UPDATE_COLOR];
          return events.indexOf(i.action) > -1;
        }),
      )
      .subscribe(() => this.calendarEvents.subscribe());
    this.calendarUpdateSub.add(sub);
  }
  //#endregion

  //#region Event Emitter
  subscribeSidenavEventsEmitter() {
    const sub = this.eventService.CalendarEventEmitter.pipe(
      filter((i) => {
        const events = [EventAction.ACTION_ADD, EventAction.ACTION_EDIT, EventAction.ACTION_DELETE];
        return events.indexOf(i.action) > -1;
      }),
    ).subscribe(() => this.calendarEvents.subscribe());
    this.calendarUpdateSub.add(sub);
  }
  //#endregion

  //#region Task Emitter
  subscribeSidenavTasksEmitter() {
    const sub = this.taskService.taskActionEmitter
      .pipe(
        filter((i) => {
          const events = [
            ETaskAction.ACTION_CREATE,
            ETaskAction.ACTION_UPDATE,
            ETaskAction.ACTION_DELETE,
            ETaskAction.ACTION_SHARE,
          ];
          return events.indexOf(i.action) > -1;
        }),
      )
      .subscribe(() => this.calendarEvents.subscribe());
    this.calendarUpdateSub.add(sub);
  }
  //#endregion
}
