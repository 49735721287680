import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import { EntryManageType } from '@shared/modules/entry/components/entry-list/entry-list.component';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { IEntryTemplateConfig } from '../interfaces/entry-template-config.interface';
import {
  ProjectBoxEntryConfig,
  ProjectPhotoBoxEntryConfig,
  ProjectVideoBoxEntryConfig,
} from './project-box-entry-config';

export const ProjectSecondBoxEntries: IEntryTemplateConfig[] = [
  {
    title: 'Projects.basicBox2Titles.propertyPlans',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ESTATE_PLAN,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.propertyPlans',
      editTitle: 'Projects.basicBox2Titles.propertyPlansEdit',
    },
    btnTourAnchor: 'questionDirectory.s1.entryButton',
    attachmentTour: {
      input: 'questionDirectory.s2.modalInput',
      button: 'questionDirectory.s3.modalButton',
    },
    attachmentTourType: AppTourTypes.QUESTION_DIRECTORY_SECOND_MODAL,
  },
  {
    title: 'Projects.basicBox2Titles.executivePlans',
    // description: 'Projects.basicBox2Descriptions.executivePlans',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_EXECUTIVE_PLAN,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.executivePlans',
      editTitle: 'Projects.basicBox2Titles.executivePlansEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.specification',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_MATERIALS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.specification',
      editTitle: 'Projects.basicBox2Titles.specificationEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.permits',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_PERMISSION,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.permits',
      editTitle: 'Projects.basicBox2Titles.permitsEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.otherDocuments',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_OTHER_DOCUMENTS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.otherDocuments',
      editTitle: 'Projects.basicBox2Titles.otherDocumentsEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.apartmentPhoto',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ESTATE_PHOTOS,
    attachmentsConfig: ProjectPhotoBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.apartmentPhoto',
      editTitle: 'Projects.basicBox2Titles.apartmentPhotoEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.apartmentVideo',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ESTATE_VIDEOS,
    attachmentsConfig: ProjectVideoBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.apartmentVideo',
      editTitle: 'Projects.basicBox2Titles.apartmentVideoEdit',
    },
  },
  {
    title: 'Projects.basicBox2Titles.inspirations',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_INSPIRATIONS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox2Titles.inspirations',
      editTitle: 'Projects.basicBox2Titles.inspirationsEdit',
    },
  },
];
