export enum IntilioCodes {
  ITEM_NOT_EXISTS = 'intilio_1',
  SIMPLE_PASSWORD = 'intilio_8',
  COMPANY_EXISTS = 'intilio_9',
  WRONG_EXTENSION = 'intilio_11',
  EMAIL_IS_TAKEN = 'intilio_12',
  INVALID_CURRENT_PASSWORD = 'intilio_13',
  EMPLOYE_ADDED = 'intilio_14',
  FILE_IS_BIG = 'intilio_15',
  WRONG_DATES = 'intilio_36',
  EMPLOYEE_EXISTS = 'intilio_40',
  PROJECT_REMOVED = 'intilio_42',
  ALREADY_EXISTS = 'intilio_16',
  GROUP_NOT_EMPTY = 'intilio_71',
  PROJECT_STAGE_ERROR = 'intilio_68',
  GOOGLE_AUTH_ALREADY_EXIST = 'intilio_76',
  GOOGLE_AUTH_RESET_IMPOSSIBLE = 'intilio_77',
  GOOGLE_AUTH_EXPIRED = 'intilio_78',
  UNKOWN = 'intilio_999',
}
