export /**
 *Search in enum
 *
 * @param {{}} obj
 * @param {string} objectValue
 * @returns {boolean}
 */
const isEnumTranslatable = (obj: {}, objectValue: string): boolean => {
  return !!Object.keys(obj).filter((i) => obj[i] === objectValue)[0];
};

export const getObjectTranslate = (obj: {}, value: string, translatePrefix: string) => {
  if (!!value?.length && isEnumTranslatable(obj, value)) {
    return `${translatePrefix}${value}`;
  }
  return !!value?.length ? value : '';
};
