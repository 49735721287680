export enum ProtocolsStatuses {
  STATUS_DRAFT = 'draft',
  STATUS_CREATED = 'created',
  STATUS_SENT = 'sent',
  STATUS_ACCEPTED = 'accepted',
  STATUS_MODIFIED = 'modified',
  STATUS_NOT_ACCEPTED = 'not_accepted',
  STATUS_REJECTED = 'rejected',
  STATUS_AUTO_ACCEPTED = 'auto_accepted',
  STATUS_EXPIRED = 'expired',
}
