import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@shared/components/base.component';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';

@Component({
  selector: 'contacts-panel',
  templateUrl: './contacts-panel.component.html',
})
export class ContactsPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  window = window;

  bottomBarVisible: boolean = false;
  isEdit: boolean = false;

  subSaving: Subscription;

  constructor(
    private active: ActivatedRoute,
    public location: Location,
    private footerService: StickyFooterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeSaving();
  }

  setBottomBarVisibility() {
    this.bottomBarVisible = !!this.active?.snapshot?.firstChild?.data?.bottomBar;
    this.isEdit = !!this.active?.snapshot?.firstChild?.data?.edit;
  }

  startSaving() {
    this.footerService.emitter.emit({ type: StickyFooterEventType.SUBMITTED });
  }

  subscribeSaving() {
    this.setBottomBarVisibility();
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setBottomBarVisibility();
      }
    });

    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.START_SAVING:
          this.loading = true;
          break;
        case StickyFooterEventType.END_SAVING:
          this.loading = false;
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
