import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { GbxsoftValueAccessor } from '@form/src/lib/gbxsoft-accessors';
import { GbxsoftBaseFormComponent } from '@form/src/lib/gbxsoft-base.component';

@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [GbxsoftValueAccessor(RadioButtonComponent)],
})
export class RadioButtonComponent extends GbxsoftBaseFormComponent {
  @Input() valueName: any = '';
  @Input() name: string = '';
  @Input() text: string = '';

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  change($event) {
    this.fnOnChange ? this.fnOnChange(this.valueName) : null;
  }
}
