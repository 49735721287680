import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ListService } from '../../services/list.service';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-pager',
  templateUrl: './list-pager.component.html',
  styleUrls: ['./list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPagerComponent implements OnInit, OnDestroy {
  Math = Math;
  sub: Subscription = new Subscription();
  pagination: number[] = [1];

  constructor(public service: ListService, private changes: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    this.listenListServiceEvents();
  }

  listenListServiceEvents() {
    this.sub.add(this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.END_GET_ROWS:
          this.setPagination();
          this.changes.detectChanges();
          break;
      }
    }));
  }

  setPagination() {
    this.pagination = [];
    for (let i = this.service.Page - 3; i <= Math.ceil(this.service.total / this.service.PerPage) && i < this.service.Page + 3; i++) {
      if (i > 0) {
        this.pagination.push(i);
      }
    }
  }

  setPage(page: number) {
    this.service.setPage(page);
    this.service.getRows();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
