<div class="client-directory__main-wrapper relative">
  <main class="client-directory__main">
    <div class="client-directory">
      <div *ngIf="project?.dataBox2?.project2Box" class="container mb-3">
        <div class="client-directory__project">
          <div class="mb-3">
            <h1 class="text-center mb-2">{{ 'Projects.fillProjectOffer' | translate }}</h1>
            <p>
              {{
                'Projects.fillProjectOfferDescription'
                  | translate: { company: project?.dataBox2?.project2Box?.company?.name }
              }}
              {{ 'Projects.fillProjectOfferDescriptionAsk' | translate }}
            </p>
          </div>

          <div class="client-directory__project__data d-flex justify-content-between">
            <div class="text-left pr-2">
              <div class="client-directory__project__data-address mb-2">
                {{ project?.dataBox2?.project2Box?.fullNameAndAddress }}
              </div>
              <div class="client-directory__project__data-name">
                {{ project?.dataBox2?.project2Box?.nameAndProjectID }}
              </div>
            </div>

            <div class="client-directory__project__additional text-right pl-2">
              <div>
                {{ 'Projects.projectCreatedTitle' | translate }}:
                {{ project?.dataBox2?.project2Box?.created | dateFormat }}
              </div>
              <div>
                <div>
                  {{ 'Projects.responsiblePerson' | translate }}
                  {{ project?.dataBox2?.project2Box?.company?.name }}:
                </div>
                <div>
                  {{
                    project?.dataBox2?.project2Box?.basicDataBox?.responsibleEmployee?.userPerson?.previewName
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container text-left">
        <div class="card card_big">
          <div class="project-question-directory">
            <div class="project-question-directory__title project-question-directory__title">
              <content-title
                [title]="('Projects.Menu.questions' | translate) + ' 2'"
                [subtitle]="'Projects.mainContactDescription' | translate"
              >
              </content-title>
            </div>

            <div class="pt-3">
              <div *ngFor="let entry of entryListsConfig; let i = index">
                <div
                  [ngClass]="{
                    'project-question-directory__region-title--single': !entry?.description?.length
                  }"
                  class="project-question-directory__region-title"
                >
                  {{ i + 1 }}. {{ entry.title | translate }}
                </div>
                <div *ngIf="entry.description" class="project-question-directory__region-subtitle">
                  {{ entry.description | translate }}
                </div>
                <entry-list
                  #entryList
                  [snackBottom]="false"
                  [btnTourAnchor]="entry.btnTourAnchor"
                  [attachmentTour]="entry.attachmentTour"
                  [attachmentTourType]="entry.attachmentTourType"
                  [limitEditAction]="true"
                  [items]="entry.list"
                  [objectToken]="store.sharedToken"
                  [id]="projectStore.projectCtrl?.project?.id"
                  [objectType]="EntryObjectType.OBJECT_TYPE_DATA_BOX_2"
                  [buttonTitle]="'Projects.addEntry' | translate"
                  [placeholder]="'Projects.enterText' | translate"
                  [manageType]="entry.type"
                  [type]="entry.entryType"
                  [attachmentsConfig]="entry.attachmentsConfig"
                  [entryModalConfig]="entry.entryModalConfig || {}"
                  (onEntry)="updateListEntry(ProjectSecondBoxEntries, $event, entry.entryType, 'dataBox2')"
                ></entry-list>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <div class="container sticky-footer">
    <div class="col-12 py-2">
      <div class="row justify-content-between">
        <app-button
          [loading]="loading"
          [disabled]="loading"
          (onClick)="submitDirectoryModel()"
          [config]="{
            type: ButtonTypes.PRIMARY_BLUE,
            text: 'Projects.saveAndEnd' | translate,
            size: ButtonSize.LARGE,
            prependSrc: '/assets/img/ic-save.svg'
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
