<modal-title
  [title]="'Projects.PreviewHeader.createdAccountTitle' | translate"></modal-title>

<form>
  <mat-dialog-content>
    <h1 class="grey-1 mb-4">
      {{'Projects.PreviewHeader.createdAccountMainTitle' | translate }}
    </h1>
    <p [innerHTML]="'Projects.PreviewHeader.createAccountDescription' | translate: {projectName: project?.fullName}">
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">
      <!-- #endregion -->
      <app-button
        [class]="'ml-3'"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Projects.PreviewHeader.ok' | translate,
        size: ButtonSize.LARGE
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
