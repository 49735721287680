import { Config } from '@shared/configs/config';
import { delay, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { BaseProjectCreator } from './base-project-creator.controller';
import { EProjectCreatorThirdStepForm } from '../enums/project-creator-third-step.enum';
import { EBudgetType } from '../enums/budget-type.enum';
import { priceToPenny } from '@shared/helpers/price-to-penny.helper';

export class ProjectCreatorThirdStepController extends BaseProjectCreator {
  get defaultFormValues() {
    return {
      [EProjectCreatorThirdStepForm.budgetType]:
        this.project?.basicDataBox?.budgetType || EBudgetType.BUDGET_TYPE_OVERALL,
      [EProjectCreatorThirdStepForm.budgetAmount]: this.project?.basicDataBox?.budgetAmount
        ? (Number(this.project?.basicDataBox?.budgetAmount) / 100).toFixed(2)
        : null,
      [EProjectCreatorThirdStepForm.termEnd]: this.project?.basicDataBox?.termEnd || null,
      [EProjectCreatorThirdStepForm.termStart]: this.project?.basicDataBox?.termStart || null,
    };
  }

  constructor(private fb: FormBuilder, private api: ProjectCreatorApiService, private internal: boolean) {
    super();
    this.createForm(fb);
    this.setValidators();
  }

  setValidators() {
    this.form
      .get(EProjectCreatorThirdStepForm.budgetAmount)
      .setValidators([Validators.max(Config.MAX_NUMBER), this.validateNumber()]);
    this.form.get(EProjectCreatorThirdStepForm.budgetAmount).updateValueAndValidity();
  }

  validateNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return isNaN(Number(control?.value?.toString() || '')) ? { isNaN: true } : null;
    };
  }

  setBudgetType(type: string) {
    this.form.get(EProjectCreatorThirdStepForm.budgetType).setValue(type);
  }

  submit(token: string, step?: number): any {
    const obj = Object.assign({}, this.form.value);
    obj[EProjectCreatorThirdStepForm.budgetAmount] = !!obj[EProjectCreatorThirdStepForm.budgetAmount]
      ? priceToPenny(obj[EProjectCreatorThirdStepForm.budgetAmount])
      : 0;
    return this.api.updateThirdStep(token, obj).pipe(switchMap(() => this.api.getFullProjectData(token)));
  }

  submitInternal(id: number, step?: number) {
    const obj = Object.assign({}, this.form.value);
    obj[EProjectCreatorThirdStepForm.budgetAmount] = !!obj[EProjectCreatorThirdStepForm.budgetAmount]
      ? priceToPenny(obj[EProjectCreatorThirdStepForm.budgetAmount])
      : 0;
    return this.api.updateThirdStepInternal(id, obj).pipe(switchMap(() => this.api.getProjectById(id)));
  }
}
