<div class="form-control" [ngClass]="inputCSS">
  <label>
    <textarea
      trim
      autocomplete="off"
      [value]="value"
      [placeholder]="placeholder"
      (input)="input($event.target.value)"
      (blur)="blur($event.target.value)"
      [attr.readOnly]="readonly ? 'readonly' : null"
    ></textarea>
    <span [innerText]="config.name"></span>
  </label>
  <span *ngIf="errorCtrl.isError" class="form-control-error-msg" [innerHTML]="errorCtrl.errorMessage"></span>
</div>
