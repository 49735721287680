import { ProjectDataBox } from './../../../projects/shared/models/project-second-box.model';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@shared/services/navigate.service';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ClientQuestionDirectorySecondResolver implements Resolve<ProjectDataBox> {
  constructor(
    private service: ProjectAPIService,
    private projectStore: ProjectStoreService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectDataBox> {
    const id = route.params['id'];
    return this.service.getSharedSecondBox(id).pipe(
      map((secondBox) => {
        secondBox.project2Box = new Project(secondBox.project2Box);
        this.projectStore.projectCtrl.setProject({ id: secondBox.id, dataBox2: secondBox });
        return this.projectStore.projectCtrl.project;
      }),
      catchError((error) => {
        Config.DEBUG ? console.log(error) : null;
        this.s.error(this.t.instant('Projects.errorGettingProject'));
        this.n.go('login');
        return of(error);
      }),
    );
  }
}
