import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'signup-mobile-navigation',
  templateUrl: './signup-mobile-navigation.component.html',
  styleUrls: ['./signup-mobile-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupMobileNavigationComponent implements OnInit {
  Config = Config;
  ButtonTypes = ButtonTypes;

  constructor(public n: NavigateService) {}

  ngOnInit(): void {}
}
