import { Component } from '@angular/core';
import { ListService } from '@shared/modules/list/services/list.service';

@Component({
  selector: 'list-chips',
  templateUrl: './list-chips.component.html',
  styleUrls: ['./list-chips.component.scss'],
})
export class ListChipsComponent {
  constructor(public service: ListService) {}

  remove(chip: any, value: any, multi: boolean = false) {
    if (multi) {
      this.service.removeFilterByKeyAndValue(chip.key, value);
    } else {
    this.service.removeFilterByKey(chip.key);
    }
  }

  get isSomeSelected(): boolean {
    return Object.values(this.service.chips).some((v) => ((v as any) || []).length);
  }
}
