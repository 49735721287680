<span
  class="status-cell"
  [ngClass]="{
    'status-cell-grey': color === EStatusColor.COLOR_DEFAULT,
    'status-cell-green': color === EStatusColor.COLOR_SUCCESS,
    'status-cell-red': color === EStatusColor.COLOR_DANGER
  }"
  [title]="status"
  [innerHTML]="status"
>
</span>
