import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Config } from '@shared/configs/config';
import { filter } from 'rxjs/operators';
declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private router: Router) {
  }

  public initGA() {
    this.listenRouter();
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + Config.GTAG;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + Config.GTAG + `', {'send_page_view': false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  listenRouter() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        gtag('config', Config.GTAG, {
          'page_path': event.urlAfterRedirects,
        });
        if (isDevMode()) {
          console.log('Sending Google Analytics hit for route', event.urlAfterRedirects.toString().slice(0, 50) + '...');
        }
      });
  }
}
