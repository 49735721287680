<div class="tags-record no-select" #tagsRecord>

  <span *ngIf="!_tagsVisible.length">-</span>

  <span class="tags-record-item no-select" [title]="tag" [ngClass]="{'cursor-pointer':clickable}" *ngFor="let tag of _tagsVisible" (click)="clickable ? onTagClick.emit(tag) : null">{{tag}}</span>

  <span class="tags-record-item d-flex align-items-center justify-content-center no-select"
        *ngIf="_tagsHidden.length > 0"
        [ngClass]="{'tags-record-item--active':hiddenList.menuOpen}"
        (onMenuOpen)="changes.detectChanges()"
        (onMenuClose)="changes.detectChanges()"
        [matMenuTriggerFor]="menu" #hiddenList="matMenuTrigger">
    <mat-icon
      role="img"
      class="cursor-pointer p-0 m-0 mat-icon notranslate material-icons mat-icon-no-color border-color no-select"
      aria-hidden="true">more_horiz</mat-icon>
  </span>
  <mat-menu #menu="matMenu" xPosition="after" (click)="$event.preventDefault();" class="tags-record-hidden-list">
    <span class="tags-record-item" [title]="tag" [ngClass]="{'cursor-pointer':clickable}" *ngFor="let tag of _tagsHidden" (click)="onTagClick.emit(tag)">{{tag}}</span>
  </mat-menu>
</div>
