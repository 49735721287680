<div class="d-flex align-items-center justify-content-between event-details__section">
  <span class="event-details__section-title">{{ 'CalendarEvents.protocols' | translate }}</span>
  <app-button
    [class]="'text-uppercase ml-2'"
    (onClick)="createProtocol()"
    [permission]="{
      group: PermissionsGroups.CALENDAR,
      action: 'EVENT_EDIT',
      objectCreatorId: [service?.calendarEvent?.participantCreator?.contact?.employeeId]
    }"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.SECONDARY_BLUE,
      text: 'CalendarEvents.newProtocol' | translate,
      prependSrc: './assets/img/ic-plus-add-blue.svg'
    }"
  ></app-button>
</div>

<ul *ngIf="!!service.eventProtocolCtrl?.protocols?.length">
  <li *ngFor="let protocol of service.eventProtocolCtrl?.protocols; trackBy: trackPortocol">
    <event-protocol-single [item]="protocol"></event-protocol-single>
  </li>
</ul>

<div class="text-muted py-2" *ngIf="!service.eventProtocolCtrl?.protocols?.length">
  {{ 'CalendarEvents.noInfo' | translate }}
</div>
