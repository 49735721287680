<modal-title
  [title]="'Contacts.Preview.addToSystem' | translate"></modal-title>

<form [formGroup]="form" class="contact-add-to-system">
  <mat-dialog-content [ngClass]="{'contact-add-to-system--min-height' : roleSelect?.select?.isOpen}">
    <label class="grey-1 mt-2" *ngIf="!contact.email"><b>{{'Contacts.Fields.email' | translate}}</b></label>
    <gbxsoft-input *ngIf="!contact.email"
                   class="email-control special-control required"
                   [errMessages]="errorMessages('email')"
                   formControlName="email"
                   [config]="{ name: 'Contacts.Fields.email' | translate, type: 'email', placeholder: '' }"></gbxsoft-input>
    <div class="mt-3">
      <label class="grey-1"><b>{{'Contacts.Preview.Projects.roleInSystem' | translate}}</b></label>
      <ng-select
        #roleSelect="ngSelectExtensionDir"
        ngSelectExtension
        class="select select-default w-100"
        [dropdownPosition]="'bottom'"
        [items]="roles"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [placeholder]="'Contacts.Preview.Projects.chooseRole' | translate"
        [tabIndex]="-1"
        formControlName="role">
      </ng-select>
      <span
        *ngIf="form?.get('role')?.touched && !form?.get('role')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'Contacts.Preview.Projects.notChosenRole' | translate"
      ></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">

      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Contacts.Preview.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="addContactToSystem()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Contacts.Preview.addContact' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
