<main>
  <content-with-tabs
    [tabLinks]="tabLinks"
    [hideTopTabIconOnMobile]="true"
    [tabsDescription]="'Contacts.Preview.Menu.previewDescription' | translate : {
      contactName: contact?.fullName,
      contactType: 'Contacts.Type.'+contact?.type | translate
    }"
    [tabsTemplate]="contactPreviewTopMenu"
  ></content-with-tabs>
</main>
<ng-template #contactPreviewTopMenu>
  <contact-preview-top-menu [contactType]="contact?.type"></contact-preview-top-menu>
</ng-template>
