import { DeviceHelper } from './shared/helpers/device.helper';
export class AppController {
  constructor() {
    this.setFancyboxConfig();
    this.setSimpleToggling();
  }

  setFancyboxConfig() {
    ($ as any).fancybox.defaults.hash = false;
    ($ as any).fancybox.defaults.backFocus = false;
    ($ as any).fancybox.defaults.mobile.clickSlide = () => 'close';
  }

  closeFancybox() {
    ($ as any).fancybox?.getInstance('close');
  }

  setSimpleToggling() {
    $('body').on('click', '[data-toggle]', (e) => {
      const clickedEl: HTMLElement = e.currentTarget;
      const targetEl = $($(clickedEl).attr('data-target'));
      targetEl.toggleClass('show');
      $(clickedEl).attr('aria-expanded', !!targetEl.hasClass('show') ? 'true' : 'false');
    });
  }

  defineWindowHeightRwd() {
    let vh = 0;
    if (DeviceHelper.isIOS && !DeviceHelper.isPWA) {
      vh = !window.matchMedia('(orientation: portrait)').matches
        ? screen.width * 0.0083
        : screen.height * 0.0083;
    } else {
      vh = window.innerHeight * 0.01;
    }
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
