import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ButtonSize, ButtonTypes} from '@shared/modules/ui/components/button/button.component';
import {Contact} from '@modules/contacts/shared/models/contact.model';
import {ContactService} from '@modules/contacts/shared/services/contact.service';
import {SnackBarService} from '@core/services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'contact-list-subcontacts-modal',
  templateUrl: './contact-list-subcontacts-modal.component.html',
  styles: []
})
export class ContactListSubcontactsModalComponent implements OnInit {

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  form: FormGroup = new FormGroup({
    subcontact: new FormControl(null)
  });

  mainContact: Contact;

  contactPersonEmitter: EventEmitter<Contact> = new EventEmitter<Contact>();

  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ContactListSubcontactsModalComponent>,
    private service: ContactService,
    private s: SnackBarService,
    private t: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

  setAsContactPerson() {
    const subcontact = this.form.get('subcontact').value;
    if (this.loading || !subcontact) {
      return;
    }
    this.loading = true;

    this.service.setSubcontactAsContactPerson(subcontact.id, this.mainContact.id).subscribe({
      next: () => {
        this.s.success(this.t.instant('Contacts.Preview.Subcontacts.successAddContactPerson'));
        this.contactPersonEmitter.emit(subcontact);
        this.mainContact.childContacts.map((childContact: Contact) => {
          if (childContact.id === subcontact.id) {
            childContact.isContactPerson = true;
            this.mainContact.contactPerson = new Contact(childContact);
          }
        });
        this.dialogRef.close();
      },
      error: () => {
        this.s.error(this.t.instant('Contacts.Preview.Subcontacts.errorAddContactPerson'));
      }
    }).add(() => {
      this.loading = false;
    });
  }

  getContactInstance(contact: Contact): Contact {
    return new Contact(contact);
  }


}
