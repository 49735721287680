import { SharedModule } from '@shared/shared.module';
import { GbxsoftFormModule } from '@form/src/public-api';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsManageModule } from '@shared/modules/attachment-manage/attachments-manage.module';
import { ProjectCreatorEntitySidenavComponent } from './components/project-creator-entity-sidenav/project-creator-entity-sidenav.component';
import { ProjectCreatorEntityDiscardComponent } from './components/project-creator-entity-discard/project-creator-entity-discard.component';

@NgModule({
  imports: [CommonModule, GbxsoftFormModule, SharedModule, AttachmentsManageModule],
  declarations: [ProjectCreatorEntitySidenavComponent, ProjectCreatorEntityDiscardComponent],
})
export class ProjectCreatorEntitySidenavModule {}
