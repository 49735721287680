import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'calendar-arrows',
  templateUrl: './calendar-arrows.component.html',
  styleUrls: ['./calendar-arrows.component.scss']
})
export class CalendarArrowsComponent implements OnInit {


  @Output('onNext') onNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onPrev') onPrev: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
