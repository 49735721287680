import { Employee } from '@shared/models/employee.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { SideNavigationTabType } from '@shared/modules/side-navigation/enums/side-navigation-tab-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { ISideNavigationConfig } from '@shared/modules/side-navigation/interfaces/side-navigation-config.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { ISideNavigationTab } from '@shared/modules/side-navigation/interfaces/side-navigation-tab.interface';

export const projectPanelPreviewSideConfig = (
  t: TranslateService,
  n: NavigateService,
  project: Project,
  employee: Employee,
): ISideNavigationConfig => {
  const projectId = project.id;

  const config: ISideNavigationConfig = {
    // title: t.instant('ProjectsList.stagesTitle'),
    // backAvailable: false,
    name: 'project-preview',
    canHidden: false,
    tabs: [
      {
        name: t.instant('Projects.PreviewTabs.summary'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-summary', {}, { projectId }),
        icon: '/assets/img/ic-home.svg',
        permissionAction: 'SUMMARY_TAB',
      },
      {
        name: t.instant('Projects.PreviewTabs.projectConfigurator'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-configurator', {}, { projectId }),
        icon: '/assets/img/design-tools-line.svg',
        permissionAction: 'PROJECT_CONFIGURATOR_PREVIEW',
        objectCreatorId: [project?.basicDataBox?.responsibleEmployee?.id].filter(Boolean),
      },

      {
        name: t.instant('Projects.PreviewTabs.tasks'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-tasks', {}, { projectId }),
        icon: '/assets/img/ic-check-square.svg',
        permissionAction: 'TASKS_TAB',
      },
      {
        name: t.instant('Projects.PreviewTabs.changes'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-changes', {}, { projectId }),
        icon: '/assets/img/ic-tool.svg',
        permissionAction: 'CHANGES_TAB',
        objectCreatorId: [
          project?.basicDataBox?.responsibleEmployee?.id,
          project?.MainContact?.employeeId,
          project?.hasChangesAccess ? employee.id : null,
        ].filter(Boolean),
      },
      // Removing: IS-359
      // {
      //   name: t.instant('Projects.PreviewTabs.protocols'),
      //   type: SideNavigationTabType.TAB,
      //   link: n.getPath('project-preview-protocols', {}, { projectId }),
      //   icon: '/assets/img/ic-file-text.svg',
      //   permissionAction: 'PROTOCOLS_TAB',
      // },
      {
        name: t.instant('Projects.PreviewTabs.team'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-team', {}, { projectId }),
        icon: '/assets/img/multiple.svg',
        permissionAction: 'TEAM_TAB',
      },
      {
        name: t.instant('Projects.PreviewTabs.contacts'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-contacts', {}, { projectId }),
        icon: '/assets/img/users-contacts-line.svg',
        permissionAction: 'CONTACTS_TAB',
        objectCreatorId: [project?.basicDataBox?.responsibleEmployee?.id].filter(Boolean),
      },

      {
        name: t.instant('Projects.PreviewTabs.calendar'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-calendar', {}, { projectId }),
        icon: '/assets/img/ic-calendar.svg',
        permissionAction: 'SUMMARY_TAB',
      },
      {
        name: t.instant('Projects.PreviewTabs.email'),
        type: SideNavigationTabType.TAB,
        link: n.getPath('project-preview-email', {}, { projectId }),
        icon: '/assets/img/ic-mail.svg',
        permissionAction: 'EMAIL_TAB',
      },
    ],
  };

  config.tabs = config.tabs.filter((t: ISideNavigationTab) => {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: t.permissionAction,
      objectCreatorId: t?.objectCreatorId || [],
    });
    return ctrl.check();
  });

  return config;
};
