export enum ETaskGroup {
  ALL_TASKS = 'all-tasks',
  START_TASKS = 'start-tasks',
}

export enum ETaskTypeGroup {
  GROUP_NOTE = 'note',
  GROUP_TASK = 'task',
  GROUP_CHANGE = 'change',
  GROUP_CORRECTION = 'correction',
  GROUP_ORDER = 'order',
}
