<div
  class="notifications"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
>
  <sidenav-title
    [title]="'Notifications.title' | translate"
    [columnOnMobile]="false"
    [template]="notificationsTemplate"
  ></sidenav-title>
  <ng-template #notificationsTemplate>
    <div class="notifications-read">
      <button class="btn-icon "
              mat-icon-button
              [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu"
                xPosition="before">
        <button mat-menu-item (click)="showAll()">
          {{ 'Notifications.setRead' | translate }}
        </button>
      </mat-menu>
    </div>
  </ng-template>

  <div class="notifications-list">
    <sidenav-loading *ngIf="loading" [cover]="true"></sidenav-loading>

    <error-notifications *ngIf="errorGettingNotifications" (reload)="reload()"></error-notifications>

    <div class="notifications-list-date-wrapper" *ngIf="!errorGettingNotifications">
      <ng-container *ngFor="let notification of notifications; let index = index">

        <notification-date-separator
          [notifications]="notifications"
          [index]="index"
        ></notification-date-separator>

        <notification [notification]="notification"></notification>
      </ng-container>
    </div>
  </div>
</div>
