import { Contact } from '@modules/contacts/shared/models/contact.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { UserPerson } from '@shared/interfaces/user.interface';
import { CalendarEntity } from './calendar-entity.model';

export class EmployeeCalendar {
  active: boolean;
  calendarColor: string;
  created: string;
  defaultCalendar: CalendarEntity;
  deleted: string;
  hasResponsibleProjects: boolean;
  id: number;
  modified: string;
  removed: boolean;
  role: UserRoles;
  userPerson: UserPerson;
  contacts: Contact[] = [];

  get contact() {
    return this.contacts[0] || null;
  }

  constructor(entity?: EmployeeCalendar) {
    entity ? this.deserialize(entity) : null;
  }

  deserialize(entity: EmployeeCalendar) {
    Object.assign(this, entity);

    this.defaultCalendar = this.defaultCalendar ? new CalendarEntity(this.defaultCalendar) : null;
    this.userPerson = this.userPerson ? new UserPerson(this.userPerson) : null;
    this.contacts = this.contacts?.map((i) => new Contact(i));
  }
}
