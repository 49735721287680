import { NavigateService } from '@shared/services/navigate.service';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Regex } from '@shared/configs/regex';
import { Config } from '@shared/configs/config';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  templateUrl: './project-box-second-invite-modal.component.html',
  styleUrls: ['./project-box-second-invite-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectBoxSecondInviteModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  token: string = '';

  loading: boolean = false;

  continueProcessEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('tooltip') tooltip: MatTooltip;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern(Regex.email),
    ]),
    share: new FormControl(false),
    notShowAgain: new FormControl(true),
  });

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private projectAPI: ProjectAPIService,
    private n: NavigateService,
    private dialog: MatDialogRef<ProjectBoxSecondInviteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: Contact; project: Project },
  ) {}

  ngOnInit() {
    this.getSharedToken();
  }

  showTooltip() {
    setTimeout(() => {
      this.tooltip.show();
      setTimeout(() => {
        this.tooltip.hide();
      }, 600);
    }, 0);
  }

  getSharedToken() {
    this.projectAPI.getProjectShareLink(this.data.project?.id).subscribe((data) => {
      this.token = `${Config.SERVER}${this.n.getPath('project-second-box-edit', {}, { id: data.token })}`;
      this.changes.detectChanges();
    });
  }

  selectShareType(shareType: boolean) {
    this.form.get('share').setValue(shareType);
  }

  submit() {
    const type = this.form.get('share').value;
    const email = !!this.data?.contact?.email ? this.data.contact.email : this.form.get('email').value;
    if (!this.data?.contact?.email) {
      if (this.form.invalid) {
        this.form.get('email').markAsTouched();
        this.form.get('email').updateValueAndValidity();
        return;
      }
    }

    if (this.loading) return;
    this.loading = true;
    this.changes.detectChanges();
    const request = !!type
      ? this.projectAPI.shareSecondBox(this.data.project.id, email)
      : this.projectAPI.shareSecondBoxWithToken(this.data.project.id, email);

    request
      .subscribe(
        () => this.successSharing(),
        () => this.errorSharing(),
      )
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successSharing() {
    this.s.success(this.t.instant('Projects.boxSecondSuccessShare'));
    this.continueProcessEmitter.emit(true);
    this.dialog.close();
  }

  errorSharing() {
    this.s.error(this.t.instant('Projects.boxSecondErrorShare'));
  }

  discard() {
    this.continueProcessEmitter.emit(true);
    this.projectAPI.projectWithoutShare(this.data.project.id).subscribe();
    this.dialog.close();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return messages;
  }
}
