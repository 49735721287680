import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ListService } from '@shared/modules/list/services/list.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'list-filters-btn-v2',
  templateUrl: './list-filters-btn-v2.component.html',
  styleUrls: ['./list-filters-btn-v2.component.scss'],
})
export class ListFiltersButtonV2Component {
  readonly ButtonSize = ButtonSize;
  readonly ButtonTypes = ButtonTypes;
  readonly WindowHelper = WindowHelper;

  @Input() filters;

  constructor(
    public dialogRef: MatDialog,
    private changes: ChangeDetectorRef,
    private service: ListService,
  ) {}

  openFilters() {
    if (!this.filters) return;
    this.service.isFiltersOpened = true;
    const dialog = this.dialogRef.open(this.filters, { disableClose: true });

    dialog.afterClosed().pipe(
      tap(() => (this.service.isFiltersOpened = false)),
      tap(() => this.changes.detectChanges()),
    );
  }
}
