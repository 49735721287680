import { Injectable } from '@angular/core';
import { GlobalData } from '@shared/interfaces/global-data.interface';
import { Resolve } from '@angular/router';
import { GlobalDataService } from '@shared/services/global-data.service';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import { StorageService } from '@core/services/storage.service';
import { Config } from '@shared/configs/config';
import { of } from 'rxjs';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserPerson } from '@shared/interfaces/user.interface';
import { Employee } from '@shared/models/employee.model';
import { SettingsService } from '@modules/settings/shared/services/settings.service';

@Injectable()
export class GlobalDataResolve implements Resolve<GlobalData> {

  private lastError: boolean = false;

  constructor(
    private service: GlobalDataService,
    private store: StorageService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
    private settingsService: SettingsService) {
  }

  resolve() {
    const globalData = this.store.GlobalData;
    this.getUserDataInBackground();
    if (globalData && moment().diff(moment(globalData.saveTime, Config.DATE_SERVER), 'days') < 1) { // one per day refresh data
      return globalData;
    }
    if(this.lastError) return null;

    return this.service.getGlobalData().pipe(map((res) => {
      this.lastError = false;
      this.store.GlobalData = res;
      return res;
    }), catchError(error => {
      this.lastError = true;
      this.store.clearAllStorage();
      this.s.error(this.t.instant('errorGettingGlobalData'));
      this.n.go('/');
      return of(error);
    }));
  }

  getUserDataInBackground() {
    if(!this.store.isLogged) return;
    this.settingsService.getUserData().subscribe({
      next: (user: UserPerson) => {
        this.store.UserPerson = user;
        this.store.UserPerson.employees.map((employee: Employee) => {
          if (employee.id == this.store.Employee.id) {
            this.store.Employee = employee;
          }
        });
      }
    });
  }

}
