<div
  class="attachment-file pointer"
  docPreview
  [token]="token"
  [file]="!!file ? file : null"
  [title]="file.name"
>
  <div class="attachment-file__icon">
    <img draggable="false" [src]="'./assets/img/' + file?.icon" [alt]="file?.icon" />
  </div>
  <div class="attachment-file__data">
    <div class="w-100 elipsis attachment-file__data-name">{{ file?.name }}</div>
    <div class="w-100 elipsis attachment-file__data-type">{{ file?.customType }}</div>
  </div>
</div>
