<div *ngIf="!loadingAcceptance && isError" class="white-board-logo p-4 text-left">
  <img draggable="false" src="/assets/img/logo.svg" alt="intilio" />
</div>
<div class="container-fluid acceptance-container">
  <ng-container *ngIf="loadingAcceptance">
    <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">{{ 'Protocols.loadingAccept' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!loadingAcceptance && isError">
    <div class="acceptance-container-title d-flex align-items-center justify-content-center">
      <h1 class="acceptance-page-title p-4" [innerHTML]="'Protocols.notFoundAccept' | translate"></h1>
    </div>
  </ng-container>

  <ng-container *ngIf="!loadingAcceptance && !isError">
    <div class="row">
      <div class="col-12 col-lg-6 d-none d-lg-block">
        <div class="row flex-column">
          <div class="pdf-preview">
            <full-page-loader [cover]="true" *ngIf="loadingPDF"></full-page-loader>
            <custom-pdf-viewer
              *ngIf="!loadingPDF"
              [content]="content"
              [renderText]="false"
            ></custom-pdf-viewer>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-left">
        <div class="acceptance-box">
          <div class="white-board-logo">
            <img draggable="false" src="/assets/img/logo.svg" alt="intilio" />
          </div>
          <h1 class="acceptance-page-title mb-4" [innerHTML]="'Protocols.acceptProtocol' | translate"></h1>
          <div class="acceptance-protocol mb-2 text-left">
            {{ protocolTypeTitle ? (protocolTypeTitle | translate) : '' }},
            <span class="capitalize">{{ recievedAcceptance?.created | dateFormat }}</span>
          </div>
          <div>
            <p
              class="acceptance-description text-left"
              [innerHTML]="'Protocols.acceptDescription' | translate"
            ></p>
          </div>

          <div class="acceptance-wrapper" *ngIf="lastAcceptance">
            <div class="table-wrapper">
              <table cellpadding="0" spacing="0">
                <tr>
                  <td>
                    <span class="protocol-number-icon mr-2">
                      <img draggable="false" src="./assets/img/ic-protocol-outline.svg" alt="Protocol" />
                    </span>
                    <span class="protocol-number" [innerHTML]="recievedAcceptance.idNumber"> </span><br />
                    <span (click)="openPdfPreview(lastAcceptance)" class="mobile-show protocol-show">
                      {{ 'Protocols.List.showStuffs' | translate }} PDF
                    </span>
                  </td>
                  <td class="capitalize">
                    <div
                      [ngClass]="{
                        'text-success': lastAcceptance.status === protocolStatuses.STATUS_ACCEPTED,
                        'text-warning': lastAcceptance.status === protocolStatuses.STATUS_SENT,
                        'text-danger': lastAcceptance.status === protocolStatuses.STATUS_REJECTED
                      }"
                      [innerHTML]="getAcceptanceStatus(lastAcceptance?.status, true) | translate"
                    ></div>
                    <div class="d-flex align-items-center">
                      <span class="mr-1">{{ 'Protocols.created' | translate }}: </span>
                      {{ lastAcceptance?.created | dateFormat }}
                    </div>
                  </td>
                  <td class="text-right">
                    <span
                      (click)="openPdfPreview(lastAcceptance)"
                      class="cursor-pointer d-inline-flex align-items-center"
                    >
                      <img draggable="false"
                        *ngIf="!lastAcceptance.loading"
                        src="./assets/img/ic-external-link-blue.svg"
                        [alt]="'Protocols.openProtocol' | translate"
                      />
                      <span
                        *ngIf="lastAcceptance.loading"
                        class="spinner-border spinner-border-sm text-primary"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span
                        class="ml-2 blue-300 mobile-hide"
                        [innerHTML]="'Protocols.openProtocol' | translate"
                      ></span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div *ngIf="lastAcceptance.status === protocolStatuses.STATUS_SENT">
              <div>
                <div class="mt-3">
                  <textarea
                    [formControl]="commentControl"
                    [placeholder]="'Protocols.addCommentary' | translate"
                  ></textarea>
                  <div class="d-flex justify-content-end mt-3">
                    <app-button
                      class="mr-2 danger"
                      (onClick)="submitAcceptance(false)"
                      [config]="{
                        type: ButtonTypes.DANGER_BUTTON,
                        size: ButtonSize.LARGE,
                        text: 'Protocols.Protocoldiscard' | translate,
                        prependSrc: './assets/img/close-white.svg'
                      }"
                      [loading]="loading"
                      [disabled]="loading"
                    >
                    </app-button>

                    <app-button
                      (onClick)="submitAcceptance(true)"
                      [config]="{
                        type: ButtonTypes.PRIMARY_GREEN,
                        size: ButtonSize.LARGE,
                        text: 'Protocols.Protocolaccept' | translate,
                        prependSrc: './assets/img/tick-white.svg'
                      }"
                      [loading]="loading"
                      [disabled]="loading"
                    >
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="acceptance-wrapper" *ngIf="historyAcceptance?.length">
            <hr />
            <h2 class="acceptance-title" [innerHTML]="'Protocols.historyProtocols' | translate"></h2>
            <div class="table-wrapper">
              <table cellpadding="0" spacing="0">
                <tr *ngFor="let item of historyAcceptance">
                  <td>
                    <span class="protocol-number-icon mr-2">
                      <img draggable="false" src="./assets/img/ic-protocol-outline.svg" alt="Protocol" />
                    </span>
                    <span class="protocol-number" [innerHTML]="recievedAcceptance?.idNumber"> </span> <br />
                    <span (click)="openPdfPreview(item)" class="mobile-show protocol-show">
                      {{ 'Protocols.List.showStuffs' | translate }} PDF
                    </span>
                  </td>
                  <td class="capitalize">
                    <div
                      [ngClass]="{
                        'text-success': item.status === protocolStatuses.STATUS_ACCEPTED,
                        'text-danger': item.status === protocolStatuses.STATUS_REJECTED
                      }"
                      [innerHTML]="getAcceptanceStatus(item.status) | translate"
                    ></div>
                    <div class="d-flex align-items-center">
                      <span class="mobile-show mr-1">{{ 'Protocols.created' | translate }}: </span>
                      {{ item.created | dateFormat }}
                    </div>
                  </td>
                  <td class="text-right">
                    <span
                      (click)="openPdfPreview(item)"
                      class="cursor-pointer d-inline-flex align-items-center"
                    >
                      <img draggable="false"
                        *ngIf="!item.loading"
                        src="./assets/img/ic-external-link-blue.svg"
                        [alt]="'Protocols.openProtocol' | translate"
                      />
                      <span
                        *ngIf="item.loading"
                        class="spinner-border spinner-border-sm text-primary"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span
                        class="blue-300 ml-2 mobile-hide"
                        [innerHTML]="'Protocols.openProtocol' | translate"
                      ></span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
