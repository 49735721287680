import Quill from 'quill';
const Inline = Quill.import('blots/inline');

export class CustomAttributes extends Inline {
  static blotName = 'customAttributes';
  static tagName = 'FONT';

  constructor(domNode, value) {
    super(domNode, value);

    const span = super.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    Object.keys(domNode.attributes).forEach(function (key) {
      if (domNode.attributes[key].name != 'style') {
        var value = domNode.attributes[key].value;
        var name = domNode.attributes[key].name;
        if (name == 'face') name = 'font-family';
        span.format(name, value);
      }
    });

    super.remove();
    return span;
  }
}

//Usage
// 1. In module where you import Quill add next line of code
// Quill.register(CustomAttributes, true); // helps world translation fix
