export class ContactExistsEvent {
  type: ContactExistsInfoType;
  value: string;
}

export enum ContactExistsInfoType {
  COMPANY_NAME = 'companyName',
  PHONE = 'phone',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName'
}
