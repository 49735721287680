import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'projects-timeline-favourites',
  templateUrl: './projects-timeline-favourites.component.html',
  styleUrls: ['./projects-timeline-favourites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsTimelineFavouritesComponent implements OnInit {

  favourited: boolean = false;

  @Output('onFavouriteClick') onFavouriteClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('container') container: ElementRef;

  constructor(
    private changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  setFavourite() {
    this.favourited = !this.favourited;
    this.onFavouriteClick.emit(this.favourited);
    this.changes.detectChanges();
  }

  mouseover() {
    this.container.nativeElement.classList.add('projects-timeline-favourites--hover');
  }

  mouseleave() {
    this.container.nativeElement.classList.remove('projects-timeline-favourites--hover');
  }
}
