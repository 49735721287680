import { MailingPageComponent } from '@modules/mailing-templates/pages/mailing-page/mailing-page.component';
import { MailingPanelComponent } from '@modules/mailing-templates/pages/mailing-panel/mailing-panel.component';
import { EMailingGroup } from '@modules/mailing-templates/shared/enums/mailing-group.enum';
import { MailingDataResolver } from '@modules/mailing-templates/shared/resolvers/mailing-data.resolver';
import { MailingActivateRouteGuard } from '@modules/project-creator/shared/guards/canActivateMailingRoute.guard';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const MailingTemplateRoutes: CustomRoutes = [
  {
    path: '',
    component: MailingPanelComponent,
    name: 'mailing-templates',
    resolve: { records: MailingDataResolver },
    canActivate: [MailingActivateRouteGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'new-contact',
      },
      {
        path: EMailingGroup.GROUP_RECOMMENDATION,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.recommenadation',
          pathName: 'recommendation',
          group: EMailingGroup.GROUP_RECOMMENDATION,
        },
      },
      {
        path: EMailingGroup.GROUP_NEW_CONTACT,
        name: 'new-contact',
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.new-contact',
          pathName: 'new-contact',
          group: EMailingGroup.GROUP_NEW_CONTACT,
        },
      },
      {
        path: EMailingGroup.GROUP_MEETING,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.terms',
          pathName: 'terms',
          group: EMailingGroup.GROUP_MEETING,
        },
      },
      {
        path: EMailingGroup.GROUP_ESTIMATION,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.estimation',
          pathName: 'estimation',
          group: EMailingGroup.GROUP_ESTIMATION,
        },
      },
      {
        path: EMailingGroup.GROUP_DOCUMENTS,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.documents',
          pathName: 'documents',
          group: EMailingGroup.GROUP_DOCUMENTS,
        },
      },
      {
        path: EMailingGroup.GROUP_NEGOTIATIONS,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.negotiations',
          pathName: 'negotiations',
          group: EMailingGroup.GROUP_NEGOTIATIONS,
        },
      },
      {
        path: EMailingGroup.GROUP_REALIZATION,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.realization',
          pathName: 'realization',
          group: EMailingGroup.GROUP_REALIZATION,
        },
      },
      {
        path: EMailingGroup.GROUP_FINISHED,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.realized',
          pathName: 'realized',
          group: EMailingGroup.GROUP_FINISHED,
        },
      },
      {
        path: EMailingGroup.GROUP_COMPLAINT,
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.complaint',
          pathName: 'complaint',
          group: EMailingGroup.GROUP_COMPLAINT,
        },
      },
      {
        path: 'execution-mails',
        component: MailingPageComponent,
        data: {
          metaTitle: 'Mailings.execution-mails',
          pathName: 'execution-mails',
        },
      },
    ],
  },
];
