import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Project } from '@modules/projects/shared/models/project.model';

@Component({
  selector: 'project-info-create-account-modal',
  templateUrl: './project-info-create-account-modal.component.html',
  styleUrls: ['./project-info-create-account-modal.component.scss']
})
export class ProjectInfoCreateAccountModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  project: Project;

  constructor(
    public dialogRef: MatDialogRef<ProjectInfoCreateAccountModalComponent>) {
  }

  ngOnInit(): void {
  }

}
