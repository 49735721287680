import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-file-preview',
  templateUrl: './attachment-file-preview.component.html',
  styleUrls: ['./attachment-file-preview.component.scss'],
})
export class AttachmentFilePreviewComponent implements OnInit {
  @Input() file: any;
  @Input() token: string;

  constructor() {}

  ngOnInit() {}
}
