import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Protocol } from '@modules/protocols/shared/models/protocol';

@Component({
  selector: 'protocol-option-template',
  templateUrl: './protocol-option-template.component.html',
  styleUrls: ['./protocol-option-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolOptionTemplateComponent implements OnInit {
  @Input() item: Protocol;

  constructor() {}

  ngOnInit(): void {}
}
