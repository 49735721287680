import {Component, OnInit, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'content-title',
  templateUrl: './content-title.component.html'
})
export class ContentTitleComponent implements OnInit {

  _title: string;
  _subtitle: string;

  @Input()
  set title(title: string) {
    this._title = title;
  }

  @Input()
  set subtitle(subtitle: string) {
    this._subtitle = subtitle;
  }

  @Input() noDivider: boolean = false;
  @Input() subtitleTemplate: TemplateRef<any>;

  constructor() {
  }

  ngOnInit(): void {
  }
}
