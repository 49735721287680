import { Config } from '@shared/configs/config';
import { BaseHttpService } from '@core/http/base-http.service';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SummaryAmountService extends BaseHttpService {
  updateList: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    super();
  }

  updateAmountField(url: string, data: any) {
    url = `${Config.API}${url}`;
    return this.post(url, data, false);
  }
}
