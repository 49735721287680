import { Attachment } from '@shared/interfaces/attachment.interface';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ICommentaryEvent } from '../../interface/commentary-event.interface';
import { CommentaryEventType } from '../../enums/commentary-event-type.enum';
import { Commentary } from '../../models/commentary.model';
import { Employee } from '@shared/models/employee.model';
import { publicFile } from '@shared/helpers/public-file.helper';
import { StorageService } from '@core/services/storage.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'commentary-item',
  templateUrl: './commentary-item.component.html',
  styleUrls: ['./commentary-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentaryItemComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;

  @Input() maxImagesToShow: number = 3;
  @Input() commentary: Commentary = null;
  @Output() commentaryEvent: EventEmitter<ICommentaryEvent> = new EventEmitter();

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit() {}

  getPhoto(employee: Employee) {
    return employee?.userPerson?.photo ? publicFile(employee.userPerson.photo) : null;
  }

  @Permission({
    group: PermissionsGroups.COMMENTARIES,
    action: 'EDIT',
    objectCreatorIdMethod: (self: CommentaryItemComponent) => [self?.commentary?.creator?.id],
  })
  edit() {
    this.commentaryEvent.emit({ type: CommentaryEventType.EDIT, data: this.commentary });
    this.changes.detectChanges();
  }

  @Permission({
    group: PermissionsGroups.COMMENTARIES,
    action: 'REMOVE',
    objectCreatorIdMethod: (self: CommentaryItemComponent) => [self?.commentary?.creator?.id],
  })
  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.commentaryEvent.emit({ type: CommentaryEventType.REMOVE, data: this.commentary });
        this.changes.detectChanges();
      })
      .subscribe(() => {});
  }

  trackAttachment(index: number, attachment: Attachment) {
    return attachment.id;
  }
}
