<div
  class="sidenav-title d-flex flex-row align-items-center justify-content-between flex-md-row"
  [ngClass]="{ 'sidenav-title-wrap': wrap }"
>
  <div class="d-flex align-items-center w-100 w-sm-auto">
    <div
      class="sidenav-title-close mr-3 align-content-center justify-content-center"
      (click)="!!closeFunc ? closeFunc() : sidenav.close()"
    >
      <img draggable="false" src="/assets/img/hide-icon.svg" alt="'Global.hideSidenav'" />
    </div>
    <h2 class="mr-3" [title]="title" [innerHTML]="title"></h2>
  </div>
  <div
    *ngIf="isEditEnabled"
    class="sidenav-title-edit align-content-center justify-content-center"
    (click)="editClick.emit()"
  >
    <img draggable="false" src="/assets/img/pencil-icon.svg" alt="Edit" />
  </div>
  <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
</div>
