<div
  [formGroup]="form"
  class="form-control-checkbox"
  [ngClass]="{ 'form-control-checkbox--disabled': disabled }"
>
  <label class="m-0 d-flex align-items-center">
    <input [formControlName]="controlName" [attr.disabled]="disabled ? '' : null" type="checkbox" />
    <span></span>
    <span [ngClass]="classes" class="form-control-checkbox__text" [innerHTML]="title"></span>
  </label>
</div>
