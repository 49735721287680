import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PairTimepickerComponent } from '@shared/components/pair-timepicker/pair-timepicker.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { BaseQuillModules } from '@shared/consts/base-quill-modules';
import { InputV2Component } from '@shared/components/input-v2/input-v2.component';

@Component({
  selector: 'event-creation',
  templateUrl: './event-creation.component.html',
  styleUrls: ['./event-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCreationComponent implements AfterViewInit {
  Config = Config;

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  @ViewChild('daterangepicker') daterangepicker: DateRangePickerComponent;
  @ViewChild('participants') participants: NgSelectComponent;
  @ViewChild('timepicker') timepicker: PairTimepickerComponent;
  @ViewChild('nameInput') nameInput: InputV2Component;

  firstLoad: boolean = false;
  canProjectAdd: boolean = false;

  baseModules = BaseQuillModules;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link'],
    ],
  };

  formats: string[] = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
  ];

  constructor(
    public service: EventSidenavService,
    private t: TranslateService,
    private storage: StorageService,
    private s: SnackBarService,
  ) {}

  ngAfterViewInit() {
    this.service.participants = this.participants;
    this.service.daterangepicker = this.daterangepicker;

    this.daterangepicker?.setRange(
      this.service.form?.controls?.termStart?.value,
      this.service.form?.controls?.termEnd?.value,
    );
    this.checkProjectPermissions();
    this.setFocusToName();
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.service.createCustomTag($event);
  }

  setFocusToName() {
    this.nameInput.inputv2.nativeElement.focus();
  }

  successLoadCalendars(resp) {
    const calendarId = this.service.form.get(this.service.EVENT_SIDENAV_FORM.calendarId);
    setTimeout(() => {
      if (!calendarId.value) {
        const index = resp.findIndex((i) => i.type === 'default');
        if (index > -1) {
          calendarId.setValue(resp[index].id);
        }
      }
    }, 100);
    return resp.map((i) => {
      i.type === 'default' ? (i.name = this.t.instant('Calendar.myCalendarTitle')) : null;
      return i;
    });
  }

  successOnLoadContacts(resp) {
    this.service.setCreatorContact(resp[0]);
    if (!this.firstLoad) {
      this.service.setCreatorAsParticipant(resp[0]);
      this.firstLoad = true;
    }
    return [...resp.map((i) => new Contact(i))];
  }

  checkProjectPermissions() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'ADD',
      objectCreatorId: [],
    });
    this.canProjectAdd = ctrl.check();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.service.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength,
      });
    }

    return Object.keys(messages)
      .filter((key) => Object.keys(control.errors || {}).includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: messages[key] });
      }, {});
  }
}
