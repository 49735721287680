import { EventEmitter, Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { ListResponse } from '@shared/modules/list/model/list-response.model';
import { Notification } from '../models/notification.model';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationsApiService extends BaseHttpService {

  public changeEmitter: EventEmitter<{ type: NotificationChangeEventType }> = new EventEmitter();

  constructor() {
    super();
  }

  getNotificationsStatus() {
    const url = `${Config.API}/notification/status`;
    return this.get(url, false, {}, true);
  }

  getNotifications(page: number) {
    const url = `${Config.API}/notification`;
    return this.get(url, false, { page, records: 20 }).pipe(map((res: ListResponse<Notification<any>>) => {
      res.records = res.records.map((record) => {
        return new Notification(record);
      });
      return res;
    }));
  }

  show(notificationId: number) {
    const url = `${Config.API}/notification/${notificationId}/show`;
    return this.post(url, {});
  }

  showAll() {
    const url = `${Config.API}/notification/show-all`;
    return this.post(url, {});
  }

}

export enum NotificationChangeEventType {
  LOAD_NEW_NOTIFICATIONS,
  INCREASE_COUNTER,
  DECREASE_COUNTER,
  CLEAR_COUNTER
}

