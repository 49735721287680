export class DeviceHelper {
  static get isIOS() {
    return /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  static get isIPad() {
    return (
      /iPad/.test(navigator.userAgent) && !window.MSStream ||
      /iPad/.test(navigator.platform) ||
      (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform))
    );
  }

  static get isAndroid() {
    return /(android)/i.test(navigator.userAgent);
  }

  static get isMobileDevice() {
    return DeviceHelper.isIOS || DeviceHelper.isAndroid;
  }

  static get isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
}
