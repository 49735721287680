import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarProgressBarComponent } from './shared/components/top-bar-progress-bar/top-bar-progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [TopBarProgressBarComponent],
  exports: [TopBarProgressBarComponent],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  providers: []
})
export class TopProgressBarModule { }
