import { Component } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'intilio-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent {
  constructor() {
    super();
  }
}
