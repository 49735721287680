import { EventCycle } from '@shared/modules/event-sidenav/enums/event-cycle.enum';
import { EMyEventType } from '../enums/my-event-type.enum';

export class MyWeekEvent {
  id: number;
  isFullDay: boolean;
  termStart: string;
  termEnd: string;
  name: string;
  type: string;
  description: string;
  isEvent: boolean;
  isTask: boolean;
  cycleType: EventCycle;
  extra: any;
  isDivider: boolean = false;

  constructor(data: MyWeekEvent) {
    Object.assign(this, data);

    this.isEvent = this.type === EMyEventType.EVENT;
    this.isTask = this.type === EMyEventType.TASK;
  }
}
