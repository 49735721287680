import { NgModule } from '@angular/core';
import { Routes } from '@shared/configs/routes';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [RouterModule.forRoot(Routes.global.routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
