<div
  docPreview
  [file]="file"
  (onLoading)="downloading = $event"
  [title]="file.name"
  [disabled]="!!file.deleted || restoring"
  class="file-preview"
  #tooltip="matTooltip"
  matTooltip="{{ 'Attachments.restore' | translate }}"
  matTooltipPosition="above"
  [matTooltipDisabled]="!file.deleted || restoring"
  [ngClass]="{ 'cursor-pointer': downloadable, 'file-preview--deleted': !!file.deleted }"
  (click)="!!file.deleted && edit ? restoreAttachment() : null"
>
  <span class="file-preview-name" [innerText]="file.name"></span>
  <span
    class="file-preview-size"
    *ngIf="file.size && !downloading"
    [innerHTML]="'(' + (file.size | fileSize) + ')'"
  >
  </span>

  <div class="spinner-holder">
    <span
      *ngIf="downloading || restoring"
      style="width: 1rem; height: 1rem"
      class="spinner-border text-primary ml-2"
      role="status"
      aria-hidden="true"
    ></span>

    <img draggable="false" *ngIf="!!file.deleted && !restoring && edit" src="./assets/img/ic-restore.svg" alt="restore" />
  </div>
</div>
