export enum ECalendarAction {
  UPDATE_VIEW,
  UPDATE_COLOR,
  UPDATE_EVENT,
  ADD_EVENT,
  DELETE_EVENT,
  DELETE_SINGLE_CALENDAR,
  CALENDAR_UP_TO_DATE,
  UPDATE_CALENDARS_LIST,
}
