import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { Config } from '@shared/configs/config';
import { ListService } from '@shared/modules/list/services/list.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectContactsListConfig } from '@project-modules/project-contacts/shared/configs/project-contacts-list.config';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { CheckPermission } from '@core/permissions/check-permission';
import { ProjectContactsFiltersComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contacts-filters/project-contacts-filters.component';
import { ProjectContactNameCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-name-cell/project-contact-name-cell.component';
import { ProjectContactTypeCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-type-cell/project-contact-type-cell.component';
import { ProjectContactPhoneCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-phone-cell/project-contact-phone-cell.component';
import { ProjectContactEmailCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-email-cell/project-contact-email-cell.component';
import { ProjectContactFunctionCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-function-cell/project-contact-function-cell.component';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { MatDialog } from '@angular/material/dialog';
import { ProjectContactsAddModalComponent } from '@project-modules/project-contacts/shared/components/project-contacts-add-modal/project-contacts-add-modal.component';
import { ProjectContactRoleCellComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-role-cell/project-contact-role-cell.component';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'project-contacts-list-component',
  templateUrl: './project-contacts-list.component.html',
  styleUrls: ['./project-contacts-list.component.scss'],
})
export class ProjectContactsListComponent extends BaseListComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  ProjectContactsFiltersComponent = ProjectContactsFiltersComponent;
  WindowHelper = WindowHelper;
  projectId: number;

  @ViewChild('projectContactNameCellComponent')
  public projectContactNameCellComponent: ProjectContactNameCellComponent;
  @ViewChild('projectContactTypeCellComponent')
  public projectContactTypeCellComponent: ProjectContactTypeCellComponent;
  @ViewChild('projectContactPhoneCellComponent')
  public projectContactPhoneCellComponent: ProjectContactPhoneCellComponent;
  @ViewChild('projectContactEmailCellComponent')
  public projectContactEmailCellComponent: ProjectContactEmailCellComponent;
  @ViewChild('projectContactFunctionCellComponent')
  public projectContactFunctionCellComponent: ProjectContactFunctionCellComponent;
  @ViewChild('projectContactRoleCellComponent')
  public projectContactRoleCellComponent: ProjectContactRoleCellComponent;

  constructor(
    public changes: ChangeDetectorRef,
    public listService: ListService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private projectPreviewService: ProjectPreviewService,
  ) {
    super(changes, listService);
    this.checkProjectId();
    this.initListConfig(ProjectContactsListConfig);
  }

  ngOnInit(): void {}

  checkProjectId() {
    if (this.route?.parent?.parent?.snapshot?.params?.projectId) {
      this.projectId = this.route?.parent?.parent?.snapshot?.params?.projectId;
      ProjectContactsListConfig.url = `${Config.API}/contact/search-advenced?filters[assignedProjects][]=${this.projectId}`;
    } else {
      ProjectContactsListConfig.url = `${Config.API}/contact/search-advenced?filters[assignedProjects][]=[{projectId}]`;
    }
  }

  @Permission({
    group: PermissionsGroups.PROJECT_CONTACTS,
    action: 'ADD',
  })
  addNewContact() {
    const dialog = this.dialog.open(ProjectContactsAddModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: true,
    });
    this.sub.add(
      dialog.componentInstance.onChooseContact.subscribe((contact: Contact) => {
        this.projectPreviewService.updateProject().add(() => {
          this.listService.getRows();
        });
      }),
    );
  }

  showToggleMenu(row: Contact) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECT_CONTACTS,
      action: 'EDIT',
    });

    return !isNotNullOrUndefined(row.deleted) && ctrl.check();
  }
}
