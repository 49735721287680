import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private toastr: ToastrService, private t: TranslateService) {}

  success(text: string, config: Partial<IndividualConfig> = {}) {
    this.toastr.success(text, null, Object.assign({ timeOut: this.getTimeOut(text) }, config));
  }

  error(text: string, config: Partial<IndividualConfig> = {}) {
    this.toastr.error(text, null, Object.assign({ timeOut: this.getTimeOut(text) }, config));
  }

  warn(text: string, config: Partial<IndividualConfig> = {}) {
    this.toastr.warning(text, null, Object.assign({ timeOut: this.getTimeOut(text) }, config));
  }

  info(text: string, config: Partial<IndividualConfig> = {}) {
    this.toastr.info(text, null, Object.assign({ timeOut: this.getTimeOut(text) }, config));
  }

  defaultError() {
    this.error(this.t.instant('errorDefault'));
  }

  getTimeOut(text) {
    let timeOut = 2000;
    if (text.length > 50) {
      timeOut = text.length * 40;
    }

    return timeOut;
  }
}
