import { Options } from 'ngx-quill-upload';

export const MailingQuillModules = (upload?: Function, imageDropAndPaste?: Function) => {
  return {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],
    imageHandler: {
      upload: (file) => upload(file),
      accepts: ['*'],
    } as Options,
    imageDropAndPaste: {
      handler: imageDropAndPaste,
    },
  };
};
