import { Task } from '@shared/models/task.model';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { CalendarEntity } from './calendar-entity.model';

export class Calendar {
  calendar: CalendarEntity = null;
  events: CalendarEvent[] = [];
  tasks: Task[] = [];

  constructor(view?: Calendar) {
    view ? this.deserialize(view) : null;
  }

  deserialize(view: Calendar) {
    Object.assign(this, view);
    this.initCalendar();
    this.initEvents();
  }

  initCalendar() {
    this.calendar = this.calendar ? new CalendarEntity(this.calendar) : null;
  }

  initEvents() {
    this.events = this.events?.map((i) => new CalendarEvent(i)) || [];
  }

  initTasks() {
    this.tasks = this.tasks?.map((i) => new Task(i)) || [];
  }
}
