import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectTeamListComponent } from '@project-modules/project-team/pages/project-team-list/project-team-list.component';
import { ProjectContactsListComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/project-contacts-list.component';

export const ProjectContactsRoutes: CustomRoutes = [
  {
    path: '',
    component: ProjectContactsListComponent
  },
];
