import { Component, OnInit } from '@angular/core';
import { isIOS, isMobile } from 'mobile-device-detect';
import { isBrowser } from 'mobile-device-detect';
import { StorageService } from '@core/services/storage.service';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  hidden: boolean = true;
  deferredPrompt;

  get isDesktopVersion() {
    return !isMobile && isBrowser;
  }

  constructor(
    private store: StorageService
  ) {
  }

  ngOnInit(): void {
    this.checkShowDownloadAppMessage();
  }

  checkShowDownloadAppMessage() {
    if (this.isDesktopVersion) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.setDownloadAppMessage();
      });
    } else {
      this.setDownloadAppMessage();
    }
  }

  setDownloadAppMessage() {
    if (!this.store.DownloadAppMessage || moment(this.store.DownloadAppMessage, Config.DATE_SERVER).diff(moment(), 'days') > 5) {
      timer(1000).subscribe(() => {
        this.hidden = false;
      });
    }
  }

  closeDownloadAppMessage() {
    this.hidden = true;
    this.store.DownloadAppMessage = moment().format(Config.DATE_SERVER);
  }

  downloadApp() {
    if (isBrowser && !isMobile) {
      this.deferredPrompt.prompt();
    }

    if (!isBrowser && isMobile) {
      if (isIOS) {
        window.location.href = Config.APP_STORE_URL;
      } else {
        window.location.href = Config.GOOGLE_PLAY_URL;
      }
    }

    this.hidden = true;
    this.store.DownloadAppMessage = moment().format(Config.DATE_SERVER);
  }

}
