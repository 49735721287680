import { ProjectQuestionDirecoryThirdComponent } from '@modules/projects/shared/components/project-question-directory-third/project-question-directory-third.component';
import { ProjectQuestionDirecorySecondComponent } from '@modules/projects/shared/components/project-question-directory-second/project-question-directory-second.component';
import { ProjectEditPanelComponent } from '@modules/projects/modules/projects-edit/pages/project-edit-panel/project-edit-panel.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectQuestionDirectoryFirstComponent } from '@modules/projects/shared/components/project-question-directory-first/project-question-directory-first.component';
import { ProjectEditGuard } from '@core/guards/projects/project-edit.guard';

import { ProjectEditPanelResolver } from '@modules/projects/modules/projects-edit/pages/project-edit-panel/project-edit-panel.resolver';
import { ProjectSecondBoxEditGuard } from '@core/guards/projects/project-thirdbox-edit.guard';
import { ProjectFirstBoxEditGuard } from '@core/guards/projects/project-firstbox-edit.guard';
import { ProjectThirdBoxEditGuard } from '@core/guards/projects/project-secondbox-edit.guard';
import { ProjectPages } from '@modules/projects/shared/enums/project-pages.enum';

export const ProjectEditRoutes: CustomRoutes = [
  {
    path: ':projectid',
    component: ProjectEditPanelComponent,
    resolve: [ProjectEditPanelResolver],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'question-directory-first',
      },
      {
        path: 'question-directory-first',
        name: 'question-directory-edit-first',
        component: ProjectQuestionDirectoryFirstComponent,
        canActivate: [ProjectFirstBoxEditGuard],
        data: {
          edit: true,
          metaTitle: 'Projects.questionDirectory',
          page: ProjectPages.FIRST,
          notRestorationPosition: true,
          footerExistance: true,
        },
      },
      {
        path: 'question-directory-second',
        name: 'question-directory-edit-second',
        component: ProjectQuestionDirecorySecondComponent,
        canActivate: [ProjectSecondBoxEditGuard],
        data: {
          metaTitle: 'Projects.questionDirectory',
          page: ProjectPages.SECOND,
          notRestorationPosition: true,
          footerExistance: false,
        },
      },
      {
        path: 'question-directory-third',
        name: 'question-directory-edit-third',
        component: ProjectQuestionDirecoryThirdComponent,
        canActivate: [ProjectThirdBoxEditGuard],
        data: {
          metaTitle: 'Projects.questionDirectory',
          page: ProjectPages.THIRD,
          notRestorationPosition: true,
          footerExistance: false,
        },
      },
    ],
  },
];
