import { ListGroupTask } from '@shared/modules/list/model/list-group-task.model';
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  ViewContainerRef,
} from '@angular/core';
import { ListTasksGroupComponent } from '@shared/modules/list/components/list-tasks/components/list-tasks-group/list-tasks-group.component';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { Task } from '@shared/models/task.model';
import { TaskGroup } from '@shared/models/task-group.model';
import { Subscription } from 'rxjs';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { ListTasksSingleTaskComponent } from '@shared/modules/list/components/list-tasks/components/list-tasks-single-task/list-tasks-single-task.component';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProjectTaskAPIService } from '@modules/projects/shared/services/project-task-api.service';
import { StorageService } from '@core/services/storage.service';
import { ListService } from '@shared/modules/list/services/list.service';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { MyWorkService } from '@modules/my-work/shared/services/my-work.service';
import { TaskType } from '@shared/enums/task-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInjector } from '@shared/services/app-injector.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpError } from '@shared/interfaces/error.interface';
import { arrMove } from '@shared/helpers/arr-move.helper';
import { ProjectPermissionsConfig } from '@core/permissions/configs/project-permission.config';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class ListTasksController {
  container: ViewContainerRef;
  listGroupsComponents: { [key: number]: ComponentRef<ListTasksGroupComponent> } = {};
  tasksComponents: { [key: number]: ComponentRef<ListTasksSingleTaskComponent> } = {};
  projectApiTaskService: ProjectTaskAPIService;
  myWorkService: MyWorkService;
  sub: Subscription = new Subscription();
  private readonly injector = AppInjector.getInjector();
  route: ActivatedRoute;

  constructor(
    public resolver: ComponentFactoryResolver,
    public changes: ChangeDetectorRef,
    public zone: NgZone,
    public listTaskService: ListTaskService,
    public store: StorageService,
    public listService: ListService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {
    this.route = this.injector.get(ActivatedRoute);
    this.listenListServiceEvents();
  }

  private listenListServiceEvents() {
    this.sub = this.listTaskService.emitter.subscribe((event: ListTaskEvent) => {
      switch (event.type) {
        case ListTaskEventType.ADD_TASK:
          this.addNewTaskToGroup(event.data);
          break;
        case ListTaskEventType.ADD_SUB_TASK:
          this.addNewSubtask(event.data);
          break;
        case ListTaskEventType.ADD_TASK_GROUP:
          const taskGroup = this.addNewTaskGroup(event.data);
          setTimeout(() => {
            taskGroup.taskGroup.sortOrder = taskGroup.taskGroup.sortOrder + 1;
            this.handleReorder(taskGroup, 0);
          }, 100);
          break;
        case ListTaskEventType.UPDATE_TASK_ATTACHMENTS:
        case ListTaskEventType.UPDATE_TASK:
          this.updateTask(event.data);
          break;
        case ListTaskEventType.REMOVE_TASK:
          this.removeTask(event.data);
          break;
        case ListTaskEventType.UPDATE_COUNTERS:
          this.updateCounters();
          break;
        default:
          break;
      }
    });
  }

  handleReorder(taskGroup, _index, ignoreDirection: boolean = false) {
    let listGroupTasks = [...this.listTaskService.listGroupTasksHashMap.values()];
    if (taskGroup.sortOrder < _index && !ignoreDirection) {
      _index--;
    }
    listGroupTasks = arrMove(listGroupTasks, taskGroup.sortOrder - 1, _index).filter(Boolean);
    listGroupTasks = listGroupTasks.map((listGroupTask: ListGroupTask, index) => {
      listGroupTask.taskGroup.sortOrder = index + 1;
      return listGroupTask;
    });
    this.changes.detectChanges();
    //# working
    this.dragReorderGroups(listGroupTasks);
    this.changes.detectChanges();

    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
  }

  onDataLoad() {
    this.createListGroupComponents();
    this.createListSingleTaskComponents();
  }

  createListGroupComponents() {
    for (const [key, listGroupTasks] of this.listTaskService.listGroupTasksHashMap) {
      if (
        Object.values(this.listGroupsComponents).length &&
        this.listGroupsComponents[listGroupTasks.taskGroup.id]
      ) {
        this.setListGroupOrder(listGroupTasks);
      } else {
        this.createListGroupComponent(listGroupTasks);
      }
    }
    this.checkDeletedGroups();
  }

  private createListSingleTaskComponents() {
    this.createListSingleTaskComponent(0);
    this.checkDeletedTasks();
  }

  private createListSingleTaskComponent(index: number, iterations = 1) {
    const createFunc = (index: number, iterations) => {
      iterations++;
      const keys = [
        ...new Map([
          ...this.listTaskService.tasksHashMap,
          ...this.listTaskService.completedTasksHashMap,
        ]).keys(),
      ];
      const task = new Map([
        ...this.listTaskService.tasksHashMap,
        ...this.listTaskService.completedTasksHashMap,
      ]).get(keys[index]);

      //if dont exist task or it's deleted
      if (!isNotNullOrUndefined(task) || task.deleted) {
        if (keys[index + 1]) {
          this.createListSingleTaskComponent(index + 1, iterations);
        }
        return;
      }

      if (
        !this.listTaskService.isMyWorkTaskLists &&
        (!task.project || task.project.id !== this.listTaskService.projectId)
      ) {
        if (keys[index + 1]) {
          this.createListSingleTaskComponent(index + 1, iterations);
        }
        return;
      }

      if (task.type === TaskType.TYPE_CHANGE) {
        if (keys[index + 1]) {
          this.createListSingleTaskComponent(index + 1, iterations);
        }
        return;
      }

      if (this.notDisplaySubtaskOnList(task)) {
        if (keys[index + 1]) {
          this.createListSingleTaskComponent(index + 1, iterations);
        }
        return;
      }

      if (this.tasksComponents[task.id]) {
        this.setListSingleTaskContainer(task);
        if (keys[index + 1]) {
          this.createListSingleTaskComponent(index + 1, iterations);
        }
        return;
      }

      this.zone.runOutsideAngular(() => {
        let listGroupComponent = this.listGroupsComponents[task?.taskGroup?.id];
        if (this.listTaskService.isMyWorkTaskLists) {
          listGroupComponent = this.listGroupsComponents[task.project ? 1 : 2];
        }
        if (!listGroupComponent) {
          return;
        }
        this.prepareListSingleComponent(task, listGroupComponent, index, keys[index + 1] ? true : false);
        listGroupComponent.instance.changes.detectChanges();
      });
    };

    if (iterations > 10) {
      //avoid to block main thread in generating DOM elements
      iterations = 0;
      setTimeout(() => {
        createFunc(index, iterations);
      }, 60);
    } else {
      createFunc(index, iterations);
    }
    iterations++;
  }

  getParent(task: Task): Task {
    const parentTask = task.parent || this.listTaskService.getTask(task.parentId);
    if (!parentTask) {
      return task;
    }
    return parentTask;
  }

  prepareListSingleComponent(
    task: Task,
    listGroupComponent: ComponentRef<ListTasksGroupComponent>,
    index: number,
    isNextKey: boolean,
  ) {
    const container =
      task.status === TaskStatus.COMPLETED
        ? listGroupComponent.instance.completedContainer
        : listGroupComponent.instance.tasksContainer;
    const factory = this.resolver.resolveComponentFactory(ListTasksSingleTaskComponent);
    const component = container.createComponent<ListTasksSingleTaskComponent>(factory);
    this.tasksComponents[task.id] = component;

    task.parentId || task.parent ? (component.instance.parentId = task.parentId || task.parent.id) : '';
    component.instance.manuallySetTask(task);
    component.instance.dataFromHashmap = true;

    const sub = component.instance.initEmitter.subscribe(() => {
      component.instance.renderer.setStyle(
        component.instance.elRef.nativeElement,
        'order',
        task.taskGroupOrder,
      );
      isNextKey ? this.createListSingleTaskComponent(index + 1) : '';
      sub.unsubscribe();
    });
    component.instance?.changes?.detectChanges();
  }

  private setListSingleTaskContainer(task: Task) {
    this.zone.runOutsideAngular(() => {
      const taskComponent = this.tasksComponents[task?.id];
      if (!taskComponent) {
        return;
      }
      for (const key in this.listGroupsComponents) {
        const listGroupComponent = this.listGroupsComponents[key];
        const taskIndex = listGroupComponent.instance.tasksContainer.indexOf(taskComponent.hostView);
        const completedTaskIndex = listGroupComponent.instance.completedContainer.indexOf(
          taskComponent.hostView,
        );
        let taskGroupId = task?.taskGroup?.id;
        if (this.listTaskService.isMyWorkTaskLists) {
          taskGroupId = task.project ? 1 : 2;
        }

        if (task.status === TaskStatus.COMPLETED) {
          //changing container place
          taskIndex !== -1 ? listGroupComponent.instance.tasksContainer.detach(taskIndex) : '';
          listGroupComponent.instance.taskGroupId === taskGroupId
            ? listGroupComponent.instance.completedContainer.insert(taskComponent.hostView)
            : '';
        } else {
          completedTaskIndex !== -1
            ? listGroupComponent.instance.completedContainer.detach(completedTaskIndex)
            : '';
          listGroupComponent.instance.taskGroupId === taskGroupId && !taskComponent?.hostView?.destroyed
            ? listGroupComponent?.instance?.tasksContainer?.insert(taskComponent.hostView)
            : '';
        }

        taskComponent.instance.setTask();
        taskComponent.instance.renderer.setStyle(
          taskComponent.instance.elRef.nativeElement,
          'order',
          taskComponent.instance.task.taskGroupOrder,
        );
      }
    });
  }

  private setSorts() {
    Object.values(this.tasksComponents).map((taskComponent: ComponentRef<ListTasksSingleTaskComponent>) => {
      taskComponent.instance.renderer.setStyle(
        taskComponent.instance.elRef.nativeElement,
        'order',
        taskComponent.instance.task.taskGroupOrder,
      );
    });
  }

  private setListGroupOrder(listGroupTask: ListGroupTask) {
    const listGroupComponent = this.listGroupsComponents[listGroupTask.taskGroup.id];
    listGroupComponent.instance.renderer.setStyle(
      listGroupComponent.instance.elRef.nativeElement,
      'order',
      listGroupTask.taskGroup.sortOrder,
    );
  }

  private createListGroupComponent(listGroupTask: ListGroupTask) {
    this.zone.runOutsideAngular(() => {
      const component: ComponentRef<ListTasksGroupComponent> = this.createComponent();
      component.instance.taskGroupId = listGroupTask.taskGroup.id;

      if (this.listTaskService.project) {
        const project = this.listTaskService.project;
        component.instance.dragPermissions = {
          group: PermissionsGroups.PROJECTS,
          action: 'DRAG_PROJECT_TASKS',
          objectCreatorId: [
            project?.basicDataBox?.responsibleContact?.contact?.employee?.id,
            project?.basicDataBox?.responsibleEmployee?.id,
            project?.MainContact?.employee?.id,
          ].filter(Boolean),
        };
      }

      const sub = component.instance.initEmitter.subscribe(() => {
        component.instance.renderer.setStyle(
          component.instance.elRef.nativeElement,
          'order',
          listGroupTask.taskGroup.sortOrder,
        );
        sub.unsubscribe();
      });
      this.listGroupsComponents[listGroupTask.taskGroup.id] = component;
      this.changes.detectChanges();
    });
  }

  destroyTasks() {
    Object.keys(this.tasksComponents).forEach((taskId: string) => {
      this.tasksComponents[taskId].destroy();
    });
    Object.keys(this.listGroupsComponents).forEach((taskGroupId: string) => {
      this.listGroupsComponents[taskGroupId].destroy();
    });

    this.listGroupsComponents = {};
    this.tasksComponents = {};
  }

  private checkDeletedGroups() {
    Object.keys(this.listGroupsComponents).map((taskGroupId: string) => {
      if (
        !this.listTaskService.listGroupTasksHashMap.get(
          `${ListTaskService.LIST_GROUP_TASK_PREFIX}${taskGroupId}`,
        )
      ) {
        this.listGroupsComponents[taskGroupId].destroy();
        delete this.listGroupsComponents[taskGroupId];
      }
    });
  }

  dragReorderGroups(listGroupTasks) {
    this.listTaskService.clearGroups();
    this.listTaskService.listGroupsTask = listGroupTasks;
    this.onDataLoad();
    this.reorderGroups();
    this.changes.detectChanges();

    this.sendBulkEdit();
  }

  prepareGroupOrders() {
    let listGroupTasks = [...this.listTaskService.listGroupTasksHashMap.values()];

    let groupsOrders = listGroupTasks.map((listGroupTasks: ListGroupTask, index) => {
      let obj = {
        id: listGroupTasks.taskGroup?.id,
        order: listGroupTasks.taskGroup.sortOrder,
      };
      !listGroupTasks.taskGroup.id ? (obj['name'] = listGroupTasks.taskGroup.name) : '';
      return obj;
    });

    return groupsOrders;
  }

  sendBulkEdit() {
    let groupsOrders = this.prepareGroupOrders();
    this.projectApiTaskService
      .taskGroupBulkEdit({ groupsOrders: groupsOrders, projectId: this.listTaskService.projectId })
      .subscribe({
        next: () => {},
        error: (error: HttpError) => {
          if (error.messageCode === 'intilio_16') {
            this.s.error(
              this.t.instant('ProjectTasks.RightSection.errorExistGroup', { groupName: error?.data?.name }),
            );
          } else {
            this.s.error(this.t.instant('ProjectTasks.RightSection.errorSaveGroups'));
          }
        },
      })
      .add(() => {
        this.changes.detectChanges();
      });
  }

  reorderGroups() {
    Object.keys(this.listGroupsComponents).forEach((id: string) => {
      const group = this.listTaskService.listGroupTasksHashMap.get(
        `${ListTaskService.LIST_GROUP_TASK_PREFIX}${id}`,
      );
      this.container.move(this.listGroupsComponents[id].hostView, group.taskGroup.sortOrder - 1);
    });
  }

  notDisplaySubtaskOnList(task: Task) {
    if (!task) {
      return false;
    }

    const parent = this.getParent(task);
    if (this.listTaskService.isMyWorkTaskLists) {
      return (
        parent?.id !== task?.id &&
        isNotNullOrUndefined(task.parentId || task.parent) &&
        parent.status !== TaskStatus.COMPLETED
      ); //subtask can be in not completed list if the highest parent is completed
    } else {
      return (
        parent?.id !== task?.id &&
        isNotNullOrUndefined(task.parentId || task.parent) &&
        this.listTaskService.getTask(task.parentId)?.taskGroup?.id === task.taskGroup?.id && //subtask can be in few places if has different taskGroup than parent
        parent.status !== TaskStatus.COMPLETED
      ); //subtask can be in not completed list if the highest parent is completed
    }
  }

  private checkDeletedTasks() {
    Object.values(this.tasksComponents).map((taskComponent: ComponentRef<ListTasksSingleTaskComponent>) => {
      const task = this.listTaskService.getTask(taskComponent.instance.taskId);

      if (!task) {
        taskComponent.destroy();
        delete this.tasksComponents[taskComponent.instance.taskId];
        return;
      }

      if (task.type === TaskType.TYPE_CHANGE) {
        taskComponent.destroy();
        delete this.tasksComponents[taskComponent.instance.taskId];
        return;
      }

      if (
        !this.listTaskService.isMyWorkTaskLists &&
        (!task.project || task.project.id !== this.listTaskService.projectId)
      ) {
        taskComponent.destroy();
        delete this.tasksComponents[taskComponent.instance.taskId];
        return;
      }

      if (this.listTaskService.isMyWorkTaskLists && !this.isTaskToAddInMyWorkView(task)) {
        taskComponent.destroy();
        delete this.tasksComponents[taskComponent.instance.taskId];
        return;
      }

      if (this.notDisplaySubtaskOnList(task)) {
        taskComponent.destroy();
        delete this.tasksComponents[task.id];
        return;
      }
    });
  }

  private updateTask(task: Task) {
    if (this.listTaskService.isMyWorkTaskLists && !this.isTaskToAddInMyWorkView(task)) {
      this.removeTask(task);
      return;
    }

    if (task.type === TaskType.TYPE_CHANGE) {
      this.removeTask(task);
      return;
    }

    const taskFromMap = this.listTaskService.tasksHashMap.get(this.listTaskService.getKeyForTask(task));

    // whenever type swaps to change remove it from list
    if (!!taskFromMap?.type && task?.isChange) {
      this.removeTask(task);
      return;
    }

    const completedTaskFromMap = this.listTaskService.completedTasksHashMap.get(
      this.listTaskService.getKeyForTask(task),
    );
    this.updateSubtasks(task);

    //change hashmap for task if change status
    if (taskFromMap && task.status === TaskStatus.COMPLETED) {
      this.listTaskService.tasksHashMap.delete(this.listTaskService.getKeyForTask(task));
      this.listTaskService.completedTasksHashMap.set(
        this.listTaskService.getKeyForTask(task),
        new Task(task),
      );
    } else if (taskFromMap) {
      this.listTaskService.tasksHashMap.set(this.listTaskService.getKeyForTask(task), new Task(task));
    }

    //change hashmap for completedTask if change status
    if (completedTaskFromMap && task.status === TaskStatus.COMPLETED) {
      this.listTaskService.completedTasksHashMap.set(
        this.listTaskService.getKeyForTask(task),
        new Task(task),
      );
    } else if (completedTaskFromMap) {
      this.listTaskService.completedTasksHashMap.delete(this.listTaskService.getKeyForTask(task));
      this.listTaskService.tasksHashMap.set(this.listTaskService.getKeyForTask(task), new Task(task));
    }
    this.listTaskService.sortTaskInHashmap();
    this.createListSingleTaskComponents();
    this.setSorts();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
  }

  private updateSubtasks(newTask: Task) {
    const setGroupToChild = (task: Task) => {
      task.children.map((subtask: Task) => {
        if (!subtask.deleted) {
          if (subtask.status === TaskStatus.COMPLETED) {
            this.listTaskService.completedTasksHashMap.set(
              this.listTaskService.getKeyForTask(subtask),
              new Task(subtask),
            );
          } else {
            this.listTaskService.tasksHashMap.set(
              this.listTaskService.getKeyForTask(subtask),
              new Task(subtask),
            );
          }
        }
        setGroupToChild(subtask);
      });
    };
    setGroupToChild(newTask);
  }

  private removeTask(task: Task) {
    this.updateParentRemovedSubtask(task);
    this.listTaskService.removeTask(task);
    if (this.tasksComponents[task.id]) {
      this.tasksComponents[task.id].destroy();
      delete this.tasksComponents[task.id];
    }
    this.listTaskService.sortTaskInHashmap();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
  }

  updateParentRemovedSubtask(subtask: Task) {
    if (subtask.parentId) {
      let task = this.listTaskService.getTask(subtask.parentId);
      task.children = task.children.map((subt: Task) => {
        if (subt.id === subtask.id) {
          subt.deleted = moment().format(Config.DATE_SERVER);
        }
        return subt;
      });

      task.children?.filter((i) => !i.deleted).length === 0 ? (task.openedSubtasks = false) : null;
      this.listTaskService.updateTask(task);
    }
  }

  private addNewTaskGroup(taskGroup: TaskGroup) {
    let listGroupTasks = [...this.listTaskService.listGroupTasksHashMap.values()];
    taskGroup.sortOrder = listGroupTasks.length;

    const listGroupTask: ListGroupTask = {
      allCount: 0,
      completedCount: 0,
      currentCompletedCount: 0,
      currentAllCount: 0,
      tasks: [],
      completedTasks: [],
      taskGroup: taskGroup,
      projectGroup: true,
    };

    this.listTaskService.listGroupTasksHashMap.set(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${taskGroup.id}`,
      listGroupTask,
    );
    this.createListGroupComponent(listGroupTask);
    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
    return listGroupTask;
  }

  private getLowestSortOrderForListGroupTask(): number {
    let sortOrder = 10;
    [...this.listTaskService.listGroupTasksHashMap.values()].map((listGroupTask: ListGroupTask) => {
      if (listGroupTask.taskGroup.sortOrder < sortOrder) {
        sortOrder = listGroupTask.taskGroup.sortOrder;
      }
    });

    return sortOrder;
  }

  private addNewSubtask(subtask: Task) {
    const taskFromMap = this.listTaskService.getTask(subtask.parent.id);
    if (taskFromMap) {
      taskFromMap.children.unshift(subtask);
      this.listTaskService.updateTask(taskFromMap);
    }
    this.listTaskService.addTask(subtask);
    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
  }

  private addNewTaskToGroup(task: Task) {
    if (this.listTaskService.isMyWorkTaskLists && !this.isTaskToAddInMyWorkView(task, true)) {
      return;
    }

    if (task.type === TaskType.TYPE_CHANGE) {
      return;
    }
    this.listTaskService.addTask(task);
    this.listTaskService.sortTaskInHashmap();
    this.createListSingleTaskComponents();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
  }

  private isTaskToAddInMyWorkView(task: Task, creating?: boolean) {
    const amIAssigned = task.assignedTo.filter((e) => e.id === this.store.Employee.id);

    const url = new URL(window.location.href);

    if (Number(url.searchParams.get('delegatedTasks')) === 1) {
      if (!task.assignedEmails?.length && !task.assignedTo?.length && !task.assignedUsers?.length) {
        return false;
      }

      if (creating) {
        if (task.assignedTo?.length || task.assignedEmails?.length) {
          return true;
        }
      } else {
        return task?.isDelegated;
      }
    } else {
      if (amIAssigned?.length) {
        return true;
      }
      if (
        task.creator.id === this.store.Employee.id &&
        Number(url.searchParams.get('filters[showUnassigned]')) !== undefined
      ) {
        return true;
      }
    }

    return false;
  }

  private createComponent(): ComponentRef<ListTasksGroupComponent> {
    const factory = this.resolver.resolveComponentFactory(ListTasksGroupComponent);
    return this.container.createComponent<ListTasksGroupComponent>(factory);
  }

  private updateCounters() {
    const service = this.projectApiTaskService ? this.projectApiTaskService : this.myWorkService;
    const queryParams = Object.assign({}, this.route.snapshot.queryParams);
    const paramsToDelete = ['modalData', 'backdrop', 'modal', 'config'];
    paramsToDelete?.forEach((key: string) => delete queryParams[key]);

    service.getListGroupTasksCounters(this.listTaskService.projectId, queryParams).subscribe({
      next: (listGroupTasks: ListGroupTask[]) => {
        listGroupTasks.map((listGroupTask: ListGroupTask) => {
          let taskGroupId = listGroupTask?.taskGroup?.id;
          if (this.listTaskService.isMyWorkTaskLists) {
            taskGroupId = listGroupTask.withProject ? 1 : 2;
          }
          const listGroupHash = this.listTaskService.listGroupTasksHashMap.get(
            `${ListTaskService.LIST_GROUP_TASK_PREFIX}${taskGroupId}`,
          );

          if (!!listGroupHash) {
            listGroupHash.completedCount = listGroupTask?.completedCount;
            listGroupHash.allCount = listGroupTask.allCount;
            this.listTaskService.listGroupTasksHashMap.set(
              `${ListTaskService.LIST_GROUP_TASK_PREFIX}${taskGroupId}`,
              listGroupHash,
            );
          }
        });
        this.listTaskService.emitter.emit({ type: ListTaskEventType.REFRESH_LIST_GROUPS });
      },
    });
  }
}
