<app-button
  *ngIf="showClearFilters"
  [type]="'button'"
  (onClick)="clear()"
  [config]="{
    size: ButtonSize.LARGE,
    smSize: ButtonSize.SMALL,
    type: ButtonTypes.SECONDARY_RED,
    prependSrc: '/assets/img/close-icon.svg',
    text: 'List.Filters.clearFilters' | translate
  }"
></app-button>
