import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Regex } from '@shared/configs/regex';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MailingService } from '../../services/mailing.service';
import { Subscription } from 'rxjs';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'mailing-selection-modal',
  templateUrl: './mailing-selection-modal.component.html',
  styleUrls: ['./mailing-selection-modal.component.scss'],
})
export class MailingSelectionModalComponent implements OnInit, OnDestroy {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  sub: Subscription = new Subscription();

  form: FormGroup;
  projectId: number;

  @ViewChild('select') select;

  constructor(
    private fb: FormBuilder,
    private service: MailingService,
    @Inject(MAT_DIALOG_DATA) private data: { email: string; projectId: number },
    private dialog: MatDialogRef<MailingSelectionModalComponent>,
    private s: SnackBarService,
    private t: TranslateService,
    private n: NavigateService,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.projectId = this.data?.projectId || null;
    this.form = this.fb.group({ project: [this.projectId, Validators.required] });
  }

  withoutProject() {
    this.goToTemplates();
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.goToTemplates(this.form.controls?.project?.value);
  }

  goToTemplates(projectId: number = null) {
    this.n
      .go('new-contact', {}, { queryParams: { projectId, email: this.data?.email } })
      .then(() => this.discard());
  }

  discard() {
    this.dialog.close();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
