import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@shared/components/base.component';
import { Subscription } from 'rxjs';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { Location } from '@angular/common';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'project-edit-panel',
  templateUrl: './project-edit-panel.component.html',
  styleUrls: ['./project-edit-panel.component.scss'],
})
export class ProjectEditPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  tabLinks: TabLink[] = [];
  subSaving: Subscription;
  link: string = '';

  get TabLinks() {
    return [
      {
        name: `${this.t.instant('Projects.Menu.questions')} 1`,
        link: this.n.getPath(
          'question-directory-edit-first',
          {},
          { projectid: this.projectStore.projectCtrl.project.id },
        ),
        permissionGroup: PermissionsGroups.PROJECTS,
        permissionAction: 'PROJECT_BOX_FIRST_EDIT',
        objectCreatorId: [this.projectStore.projectCtrl?.project.basicDataBox?.responsibleEmployee?.id],
      },
      {
        name: `${this.t.instant('Projects.Menu.questions')} 2`,
        link: this.n.getPath(
          'question-directory-edit-second',
          {},
          { projectid: this.projectStore.projectCtrl.project.id },
        ),
        permissionGroup: PermissionsGroups.PROJECTS,
        permissionAction: 'PROJECT_BOX_SECOND_EDIT',
        objectCreatorId: [this.projectStore.projectCtrl?.project.basicDataBox?.responsibleEmployee?.id],
      },
      {
        name: `${this.t.instant('Projects.Menu.questions')} 3`,
        link: this.n.getPath(
          'question-directory-edit-third',
          {},
          { projectid: this.projectStore.projectCtrl.project.id },
        ),
        permissionGroup: PermissionsGroups.PROJECTS,
        permissionAction: 'PROJECT_BOX_THIRD_EDIT',
      },
    ];
  }

  get footerExistance() {
    return !!this.active?.snapshot?.firstChild?.data?.footerExistance;
  }

  get title() {
    return this.active?.snapshot?.firstChild?.data?.page;
  }

  constructor(
    public location: Location,
    public projectStore: ProjectStoreService,
    private footerService: StickyFooterService,
    private active: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    const project = this.projectStore?.projectCtrl?.project;
    this.link = this.n.getPath('project-summary', {}, { id: project?.id });
    this.tabLinks = this.TabLinks;
    this.subscribeSaving();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.START_SAVING:
          this.loading = true;
          break;
        case StickyFooterEventType.END_SAVING:
          this.loading = false;
          break;
        default:
          break;
      }
    });
  }

  startSaving() {
    this.footerService.emitter.emit({ type: StickyFooterEventType.SUBMITTED });
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
