<div class="settings settings-content">
  <content-title
    [title]="'Settings.Team.title' | translate"
    [subtitle]="'Settings.Team.manageTeam' | translate"
  >
  </content-title>
  <h2 class="blue-500 settings-content-subtitle" [innerHTML]="'Settings.Team.teamTitle' | translate"></h2>
  <div
    class="grey-4 settings-content-description"
    [innerHTML]="'Settings.Team.teamDescription' | translate"
  ></div>
  <div>
    <app-button
      *ngIf="store.Company.name"
      class="mt-4"
      (onClick)="openDialog()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Settings.Team.addEmployee' | translate,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>

    <div *ngIf="!store.Company.name">
      <h4 class="settings-content-fill-company-name mt-4">
        {{ 'Settings.Team.fillCompanyNameToAddEmployee' | translate }}
      </h4>
      <button class="btn btn-primary mt-1" (click)="n.navigate('company-settings')">
        {{ 'Settings.Team.fillCompanyNameToAddEmployeeButton' | translate }}
      </button>
    </div>
  </div>

  <div class="settings-content-usersList mt-4 position-relative" *ngIf="store.Company.name">
    <table>
      <thead>
      <th>{{ 'Settings.Team.person' | translate }}</th>
      <th class="d-none d-md-table-cell">{{ 'Settings.Team.position' | translate }}</th>
      <th class="d-none d-sm-table-cell">{{ 'Settings.Team.email' | translate }}</th>
      <th class="d-none d-sm-table-cell">{{ 'Settings.Team.salary' | translate }}</th>
      <th></th>
      <th></th>
      </thead>
      <tbody>
      <tr *ngIf="!employees.length && listLoaded">
        <td colspan="4">{{ 'Settings.Team.noEmployees' | translate }}</td>
      </tr>
      <tr *ngFor="let empl of employees">
        <td>
          <div class="align-items-center ml-2 threeDots d-flex mr-2">
            <avatar [width]="26" class="mr-2" [imageUrl]="getPhoto(empl)"></avatar
            >
            <b
              class="threeDots"
              matTooltip="{{ empl.userPerson.previewName }}"
              [matTooltipPosition]="'above'"
            >{{ empl.userPerson.previewName }}</b
            >
          </div>
        </td>
        <td class="d-none d-md-table-cell">{{ 'ROLES.' + empl.role | translate }}</td>
        <td class="d-none d-sm-table-cell">
            <span
              class="threeDots mr-3 d-block"
              matTooltip="{{ empl.userPerson.email }}"
              [matTooltipPosition]="'above'"
            >
              {{ empl.userPerson.email }}
            </span>
        </td>
        <td class="d-none d-sm-table-cell">
            <span
              class="threeDots mr-3 d-block"
              matTooltip="{{ empl.hourlyRate }}"
              [matTooltipPosition]="'above'">
              {{ empl?.hourlyRate ? (empl.hourlyRate + companyCurrency) : '-' }}
            </span>
        </td>
        <td>
          <div
            class="settings-content-usersList-status settings-content-usersList-status"
            *ngIf="empl.userPerson.accountConfirmed"
          >
            {{ 'Settings.Team.confirmed' | translate }}
          </div>

          <div
            class="settings-content-usersList-status settings-content-usersList-status_notConfirmed"
            *ngIf="!empl.userPerson.accountConfirmed"
          >
            {{ 'Settings.Team.notConfirmed' | translate }}
          </div>
        </td>
        <td class="text-right">
          <button class="btn-icon" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="editEmployee(empl)" *ngIf="employee?.isEmployeeOwner">
              <span>{{ 'Settings.Team.edit' | translate }}</span>
            </button>
            <button mat-menu-item (click)="blockEmployee(empl)" *ngIf="empl.active">
              <span>{{ 'Settings.Team.block' | translate }}</span>
            </button>
            <button mat-menu-item (click)="unblockEmployee(empl)" *ngIf="!empl.active">
              <span>{{ 'Settings.Team.unblock' | translate }}</span>
            </button>
            <button mat-menu-item (click)="sendPasswordLink(empl)">
              <span>{{ 'Settings.Team.passwordLink' | translate }}</span>
            </button>
            <button mat-menu-item (click)="sendInviteAgain(empl)">
              <span>{{ 'Settings.Team.sendAgain' | translate }}</span>
            </button>
            <button
              mat-menu-item
              class="hover_red red-500"
              (click)="$event.stopPropagation()"
              confirmationPopover
              (confirm)="removeEmployee(empl)"
              [cancelText]="'Settings.Team.cancel' | translate"
              [confirmText]="'Settings.Team.yesRemove' | translate"
              placement="top"
              [popoverTitle]="'Settings.Team.confirmRemoveTitle' | translate"
              [popoverMessage]="'Settings.Team.confirmRemoveDescr' | translate"
            >
              <span>{{ 'Settings.Team.remove' | translate }}</span>
            </button>
          </mat-menu>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
