<div class="entry-list">
  <div class="protocol-manager-section-title" [innerHTML]="title"></div>

  <!-- #region Static Entry Input -->
  <div *ngIf="isTypeStatic" class="d-flex align-items-start justify-content-between entry-list-wrapper">
    <div>
      <text-attachments-input
        class="w-100"
        #textAttach
        [attachments]="attachments"
        [fileName]="'files'"
        [textName]="'note'"
        [form]="form"
        [config]="config"
        [disabled]="loading"
        [attachmentsConfig]="attachmentsConfig"
        (removeFileEvent)="removeFileEvent($event)"
      >
      </text-attachments-input>
    </div>
    <app-button
      (onClick)="entryToEdit ? editEntry() : addEntry()"
      [config]="{
        text: (entryToEdit ? 'Protocols.editSave' : 'Protocols.add') | translate,
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>
  </div>
  <!-- #endregion -->

  <!-- #region entry action button -->
  <div
    *ngIf="isTypeButton && !preview"
    class="d-flex flex-column align-items-start entry-list-wrapper mb-2 mt-4"
    [ngClass]="{ 'entry-list-wrapper-employee': isTypeButton }"
  >
    <div class="mb-1" *ngIf="isEmployee">
      <b [innerHTML]="config.placeholder"></b>
    </div>
    <app-button
      [tourAnchor]="btnTourAnchor"
      [class]="'p-3'"
      (onClick)="openEntryModal()"
      [config]="{
        text: buttonTitle ? buttonTitle : ('Protocols.add' | translate),
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>
  </div>
  <div *ngIf="isTypeButton && preview && !$previewItems.length">
    {{ 'Protocols.noRegistry' | translate }}
  </div>

  <!-- #endregion -->

  <div *ngFor="let entry of $previewItems; let i = index" class="pb-2">
    <entry-item
      [preview]="preview"
      [edit]="edit"
      [limitEditAction]="limitEditAction"
      (entryAction)="entryAction($event)"
      [showAllImages]="showAllImages"
      [item]="entry"
      [index]="i + 1"
    >
    </entry-item>
  </div>
</div>
