import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { ProjectChangesSummaryHelperController } from '../../shared/controllers/project-changes-summary-helper.controller';
import { ISummaryResponse } from '../../shared/interfaces/summary-config.interface';
import { ProjectChangesService } from '../../shared/services/project-changes.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Subscription } from 'rxjs';

@Component({
  selector: 'project-changes-summary',
  templateUrl: './project-changes-summary.component.html',
  styleUrls: ['./project-changes-summary.component.scss'],
})
export class ProjectChangesSummaryComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  projectChangesCtrl: ProjectChangesSummaryHelperController = new ProjectChangesSummaryHelperController();
  summaryVisible: boolean = true;

  get project() {
    return this.projectStore?.projectCtrl?.project;
  }

  constructor(
    private projectAPI: ProjectAPIService,
    private service: ProjectChangesService,
    private projectStore: ProjectStoreService,
    private changes: ChangeDetectorRef,
    private taskSidenavService: TaskSidenavService,
    public projectPreviewService: ProjectPreviewService,
  ) {}

  ngOnInit() {
    this.setProject(this.project);
    this.setSummaryVisibility();
    this.getChangesSummary();
    this.updateSub();
  }

  setProject(p: Project) {
    this.projectStore.projectCtrl.setProject(p);
    this.projectChangesCtrl.setProject(p);
    this.projectPreviewService.project = p;
  }

  setSummaryVisibility() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'CHANGES_VISIBILITY',
      objectCreatorId: [
        this.project?.basicDataBox?.responsibleEmployee?.id,
        this.project?.MainContact?.employeeId,
      ].filter(Boolean),
    });

    this.summaryVisible = ctrl.check();
  }

  updateSub() {
    const updateList$ = this.service.updateEmitter.subscribe(() => this.getChangesSummary());
    const updateSingleTask$ = this.taskSidenavService.taskActionEmitter.subscribe(() =>
      this.getChangesSummary(),
    );
    this.sub.add(updateList$);
    this.sub.add(updateSingleTask$);
  }

  updateList() {
    this.projectAPI.findProjectByID(this.project.id).subscribe((project) => {
      const p = new Project(project);
      this.setProject(p);
      this.getChangesSummary();
    });
  }

  getChangesSummary() {
    if (!this.summaryVisible) return;
    this.service.getChangesSummary(this.project.id).subscribe((resp: ISummaryResponse) => {
      this.projectChangesCtrl.setSummary(resp, this.project);
      this.changes.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
