<modal-title [title]="'Projects.ResponsibleCompany.title' | translate"></modal-title>

<form [formGroup]="form" class="project-responsible-company">
  <mat-dialog-content
    [ngClass]="{ 'project-responsible-company--min-height': responsibleSelect?.select?.isOpen }"
  >
    <div class="mt-3">
      <label class="grey-1"
        ><b>{{ 'Projects.ResponsibleCompany.projectTeam' | translate }}</b></label
      >
      <ng-select
        #responsibleSelect="ngSelectExtensionDir"
        ngSelectExtension
        class="select select-default w-100 ng-select--required"
        [dropdownPosition]="'bottom'"
        [url]="responsibleUrl"
        [clearAllText]="'clearAll' | translate"
        [searchGetParam]="'search'"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [loadingText]="'Projects.ResponsibleCompany.loading' | translate"
        [placeholder]="'Projects.ResponsibleCompany.chooseEmployee' | translate"
        [notFoundText]="'Projects.ResponsibleCompany.notFound' | translate"
        [tabIndex]="-1"
        formControlName="responsible"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label" [innerHTML]="getEmployee(item).userPerson.previewName"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <span class="ng-arrow-option" [innerHTML]="getEmployee(item).userPerson.previewName"></span>
        </ng-template>
      </ng-select>
      <span
        *ngIf="form?.get('responsible')?.touched && !form?.get('responsible')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'Projects.ResponsibleCompany.notChosenEmployee' | translate"
      ></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">
      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Projects.ResponsibleCompany.cancel' | translate,
          size: ButtonSize.LARGE
        }"
      >
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Projects.ResponsibleCompany.save' | translate,
          size: ButtonSize.LARGE,
          prependSrc: './assets/img/ic-save.svg'
        }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
