<modal-title [title]="modalTitle | translate"></modal-title>
<mat-dialog-content>
  <contact-exists-info [form]="form" [isInited]="true" #contactExistsInfoComponent></contact-exists-info>

  <form [formGroup]="form">
    <ng-select
      [clearable]="false"
      [searchable]="false"
      [bindValue]="'id'"
      [items]="contactTypeList"
      [notFoundText]="'Contacts.Manage.notFoundText' | translate"
      class="select select-default w-100 mb-3"
      [placeholder]="'Contacts.Fields.contactType' | translate"
      [formControlName]="NEW_CONTACT_FORM.type"
    ></ng-select>

    <div *ngIf="isCompany" class="row">
      <div class="col-12">
        <gbxsoft-input
          class="required"
          [errMessages]="errorMessages(NEW_CONTACT_FORM.companyName)"
          [config]="{ name: 'Contacts.Fields.companyName' | translate }"
          [formControlName]="NEW_CONTACT_FORM.companyName"
        ></gbxsoft-input>
      </div>
    </div>

    <div *ngIf="!isCompany" class="row">
      <div class="col-6">
        <gbxsoft-input
          class="required"
          [errMessages]="errorMessages(NEW_CONTACT_FORM.firstName)"
          [config]="{ name: 'Contacts.Manage.Personal.name' | translate }"
          [formControlName]="NEW_CONTACT_FORM.firstName"
        ></gbxsoft-input>
      </div>
      <div class="col-6">
        <gbxsoft-input
          class="required"
          [errMessages]="errorMessages(NEW_CONTACT_FORM.lastName)"
          [config]="{ name: 'Contacts.Manage.Personal.lastName' | translate }"
          [formControlName]="NEW_CONTACT_FORM.lastName"
        ></gbxsoft-input>
      </div>
    </div>

    <div class="mb-3">
      <ng-select
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=acquisition-source'"
        class="w-100 select select-default"
        [placeholder]="'Contacts.Fields.contactSource' | translate"
        [notFoundText]="'Contacts.Manage.notFoundSource' | translate"
        [addTagText]="'Contacts.Manage.addContactSource' | translate"
        [formControlName]="NEW_CONTACT_FORM.acquisitionSource"
        [bindLabel]="'name'"
        [bindValue]="'name'"
        [addTag]="true"
        [tabIndex]="-1"
        [translateLabelPrefix]="'Contacts.Source.'"
      ></ng-select>
    </div>

    <div class="mb-3">
      <gbxsoft-input
        [formControlName]="NEW_CONTACT_FORM.availability"
        [config]="{
          name: 'Contacts.Fields.availability' | translate,
          type: GbxsoftInputTypes.TEXT
        }"
      ></gbxsoft-input>
      <!-- <ng-select
        ngSelectExtension
        [url]="CONFIG.API + '/tag/type?type=client-availability'"
        [formControlName]="NEW_CONTACT_FORM.availability"
        class="w-100 select select-default"
        [placeholder]="'Contacts.Fields.availability' | translate"
        [notFoundText]="'Contacts.Manage.notFoundAvailability' | translate"
        [addTagText]="'Contacts.Manage.addContactAvailability' | translate"
        [bindLabel]="'name'"
        [bindValue]="'name'"
        [addTag]="true"
      ></ng-select> -->
    </div>

    <phone-input
      [form]="form"
      [phoneFlagName]="NEW_CONTACT_FORM.phoneCountry"
      [phoneName]="NEW_CONTACT_FORM.phone"
      [config]="{ name: 'Contacts.Manage.phone' | translate, placeholder: '' }"
    ></phone-input>

    <gbxsoft-input
      [errMessages]="errorMessages(NEW_CONTACT_FORM.email)"
      class="email-control special-control"
      [config]="{ name: 'Contacts.Fields.email' | translate }"
      [formControlName]="NEW_CONTACT_FORM.email"
    ></gbxsoft-input>

    <ng-select
      ngSelectExtension
      [dropdownPosition]="'top'"
      [url]="CONFIG.API + '/tag/type?type=contact-function'"
      [formControlName]="NEW_CONTACT_FORM.function"
      class="w-100 select select-default"
      [placeholder]="'Contacts.Manage.contactFunction' | translate"
      [notFoundText]="'Contacts.Manage.notFoundFunction' | translate"
      [addTagText]="'Contacts.Manage.addContactFunction' | translate"
      [bindLabel]="'name'"
      [bindValue]="'name'"
      [addTag]="true"
      [multiple]="true"
      [tabIndex]="-1"
      [translateLabelPrefix]="'Contacts.Function.'"
    >
    </ng-select>

    <div *ngIf="!data?.hideResponsible && !data.parentContact" class="form-control-checkbox mt-3">
      <label class="m-0 d-flex align-items-center">
        <input type="checkbox" [formControlName]="NEW_CONTACT_FORM.isResponsible" />
        <span></span>
        <span [innerHTML]="'Projects.useResponsibleContact' | translate"></span>
      </label>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-button
    (onClick)="closeDialog()"
    [class]="'mr-2 bold'"
    [config]="{
      text: 'Contacts.Preview.cancel' | translate,
      type: ButtonTypes.DISCARD_BUTTON,
      size: ButtonSize.LARGE
    }"
  ></app-button>
  <app-button
    [disabled]="loading"
    [loading]="loading"
    (onClick)="submitContact()"
    [config]="{
      text: 'Contacts.Preview.saveContactPerson' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-save.svg'
    }"
  ></app-button>
</mat-dialog-actions>
