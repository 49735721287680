import { PermissionType } from '@core/permissions/enums/permission-type.enum';
import { PermissionsConfig } from '@core/permissions/interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class CalendarPermissionsConfig implements PermissionsConfig {
  static readonly ADD_EVENT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
  ];

  // TODO CHECK PERMISSION
  static readonly PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
    UserRoles.ROLE_EMPLOYEE_CLIENT,
  ];

  static readonly COLOR_EMPLOYEE_PICK: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly SHOW_TASKS_CALENDAR: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.CALENDAR;

  // only organizer can edit event
  static readonly EVENT_EDIT: (PermissionsAction | string)[] = [
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];
}
