import { ITaskGroupBulkEdit } from '@modules/projects/shared/interfaces/task-group-bulk-edit.interface';
import { Config } from '@shared/configs/config';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';
import { Task } from '@shared/models/task.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignSearchDropdown } from '@modules/projects/shared/models/assign-search-dropdown.model';
import { ListGroupTask } from '@shared/modules/list/model/list-group-task.model';

@Injectable({ providedIn: 'root' })
export class ProjectTaskAPIService extends BaseHttpService {
  constructor() {
    super();
  }

  taskGroupBulkEdit(data: any) {
    const url = `${Config.API}/task-group/bulk`;
    return this.post(url, data);
  }

  getEmployeeToAssigneeToTask(query: string, task: Task, project: Project): Observable<AssignSearchDropdown> {
    const url = `${Config.API}/project/${project.id}/employee/search`;
    const params = {
      taskId: task.id,
      query,
    };
    return this.get(url, false, params).pipe(map((res) => new AssignSearchDropdown(res)));
  }

  getListGroupTasksCounters(projectId: number, params?: any): Observable<ListGroupTask[]> {
    const url = `${Config.API}/project/${projectId}/task?withoutData=1`;
    return this.get(url, false, params) as Observable<ListGroupTask[]>;
  }
}
