import { Project } from '@modules/projects/shared/models/project.model';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MailingDataService } from '@modules/mailing-templates/shared/services/mailing-data.service';

@Component({
  selector: 'app-mailing-page',
  templateUrl: './mailing-page.component.html',
  styleUrls: ['./mailing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailingPageComponent implements OnInit {
  rows: [] = [];
  email: string;

  get pathName(): string {
    return this.active?.snapshot?.data?.pathName;
  }

  get group(): string {
    return this.active?.snapshot?.data?.group;
  }

  constructor(
    private changes: ChangeDetectorRef,
    private active: ActivatedRoute,
    public mailingService: MailingDataService,
  ) {}

  ngOnInit(): void {
    const { groups } = this.active.snapshot?.parent?.data?.records;

    this.email = this.active.snapshot.queryParamMap.get('email') || null;
    this.rows = groups.get(this.group);
    this.changes?.detectChanges();
  }
}
