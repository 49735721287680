<h5 class="notifications-list-date-wrapper_date" *ngIf="notifications[index]?.today && index === 0">
  {{ 'Notifications.today' | translate }}
</h5>

<h5
  class="notifications-list-date-wrapper_date"
  *ngIf="
    (index > 0 && notifications[index - 1].today && !notifications[index]?.today) ||
    (!notifications[index]?.today && index === 0)
  "
>
  {{ 'Notifications.earlier' | translate }}
</h5>
