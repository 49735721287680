import { TaskType } from '@shared/enums/task-type.enum';
export const sortListByGroup = (_list: any[] = []) => {
  let group = _list?.reduce((r, a) => {
    r[a.type] = [...(r[a.type] || []), a];
    return r;
  }, {});
  Object.keys(group).forEach((key) => (group[key] = group[key].sort((a, b) => a?.sortOrder - b?.sortOrder)));
  const orders = [
    TaskType.TYPE_NOTE,
    TaskType.TYPE_TASK,
    TaskType.TYPE_CORRECTION,
    TaskType.TYPE_CHANGE,
    TaskType.TYPE_ORDER,
  ];
  let list = [];
  orders.forEach((type: TaskType) => (list = list.concat((group[type] || []).filter(Boolean))));
  return list;
};
