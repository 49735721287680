import { Weather } from '@shared/enums/weather.enum';

export const WeatherList = [
  {
    id: 1,
    name: Weather.WEATHER_COLD,
    text: '',
  },
  {
    id: 2,
    name: Weather.WEATHER_MODERATE,
    text: '',
  },
  {
    id: 3,
    name: Weather.WEATHER_SUNNY,
    text: '',
  },
  {
    id: 4,
    name: Weather.WEATHER_HOT,
    text: '',
  },
  {
    id: 5,
    name: Weather.WEATHER_FROST,
    text: '',
  },
  {
    id: 6,
    name: Weather.WEATHER_RAINFALL,
    text: '',
  },
  {
    id: 7,
    name: Weather.WEATHER_SNOWFALL,
    text: '',
  },
];
