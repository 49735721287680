import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { tap, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MailingHttpService } from '@shared/modules/mailings/shared/services/mailing-http.service';
import { groupBy } from '@shared/helpers/groupBy.helper';

@Injectable()
export class MailingDataResolver implements Resolve<any> {
  constructor(private http: MailingHttpService, private projectAPI: ProjectAPIService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id =
      Number(route.queryParamMap.get('projectId')) ||
      Number(route?.parent?.parent?.parent?.parent?.params?.projectId) || //if component is nested
      null;

    if (id) {
      return this.getProjectById(id).pipe(switchMap((project) => this.getTemplates(project)));
    }
    return this.getTemplates();
  }

  getProjectById(id: number) {
    return this.projectAPI.getProject(id);
  }

  getTemplates(project?: Project) {
    const params = {};
    project?.id ? (params['projectId'] = project?.id) : null;

    return this.http.getProjectStageTemplates(params).pipe(
      map((data) => {
        return {
          project: project,
          groups: groupBy(
            data.records.filter((i) => i.number !== 25),
            (x: any) => x?.templateGroup,
          ),
        };
      }),
    );
  }
}
