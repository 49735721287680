import { WindowHelper } from './../../../../../../shared/helpers/window.helper';
import { filter } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';

import { ProjectSummaryAPIService } from '@modules/projects/shared/services/project-summary-api.service';
import {
  ESummaryColor,
  ISummaryConfig,
} from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ListService } from '@shared/modules/list/services/list.service';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { ProjectChangesFiltersComponent } from '@project-modules/project-changes/pages/project-changes-list/components/project-changes-filters/project-changes-filters.component';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProjectChangesListConfig } from '@project-modules/project-changes/shared/configs/project-changes-list.config';
import { ChangesNameCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-name-cell/changes-name-cell.component';
import { ChangesSourceCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-source-cell/changes-source-cell.component';
import { AddDaysCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/add-days-cell/add-days-cell.component';
import { ChangesPriceCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-price-cell/changes-price-cell.component';
import { ChangesDeadlineCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-deadline-cell/changes-deadline-cell.component';
import { ChangesStatusCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-status-cell/changes-status-cell.component';
import { ChangesCreatedCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-created-cell/changes-created-cell.component';
import { ChangesToggleCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-toggle-cell/changes-toggle-cell.component';
import { Config } from '@shared/configs/config';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Task } from '@shared/models/task.model';
import { ChangesNameHeaderCellComponent } from '@project-modules/project-changes/pages/project-changes-list/components/changes-name-header-cell/changes-name-header-cell.component';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { ITaskActionEvent } from '@shared/modules/task-sidenav/interfaces/task-action-event.interface';
import { ETaskAction } from '@shared/modules/task-sidenav/enums/task-action.enum';
import { CheckPermission } from '@core/permissions/check-permission';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import {
  EMailingStates,
  IMailing,
  MailingService,
} from '@shared/modules/mailings/shared/services/mailing.service';
import * as moment from 'moment';
import { ChangesSettlementCellComponent } from './components/changes-settlment-cell/changes-settlment-cell.component';
import { PriceFormatPipe } from '@shared/pipes/price-format.pipe';

@Component({
  selector: 'project-changes-list',
  templateUrl: './project-changes-list.component.html',
  styleUrls: ['./project-changes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectChangesListComponent extends BaseListComponent implements OnInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;
  ProjectChangesFiltersComponent = ProjectChangesFiltersComponent;

  projectId: number;
  checkedTaskIds: number[] = [];

  WindowHelper = WindowHelper;

  @ViewChild('changesNameCellComponent') public changesNameCellComponent: ChangesNameCellComponent;
  @ViewChild('changesSourceCellComponent') public changesSourceCellComponent: ChangesSourceCellComponent;
  @ViewChild('addDaysCellComponent') public addDaysCellComponent: AddDaysCellComponent;
  @ViewChild('changesPriceCellComponent') public changesPriceCellComponent: ChangesPriceCellComponent;
  @ViewChild('changesDeadlineCellComponent')
  public changesDeadlineCellComponent: ChangesDeadlineCellComponent;
  @ViewChild('changesSettlementCellComponent')
  public changesSettlementCellComponent: ChangesSettlementCellComponent;
  @ViewChild('changesStatusCellComponent') public changesStatusCellComponent: ChangesStatusCellComponent;
  @ViewChild('changesCreatedCellComponent') public changesCreatedCellComponent: ChangesCreatedCellComponent;
  @ViewChild('changesToggleCellComponent') public changesToggleCellComponent: ChangesToggleCellComponent;
  @ViewChild('changesNameHeaderCellComponent')
  public changesNameHeaderCellComponent: ChangesNameHeaderCellComponent;

  summary: any;
  summaryConfig: ISummaryConfig = null;
  ProjectChangesListConfig: any = null;

  get _config() {
    return {
      items: [
        {
          title: 'ProjectChanges.createdProjectChanges',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.created?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.created?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.created?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.created?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.created?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.created?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.created?.changesBalance),
            color: this.summary?.created?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
        {
          title: 'ProjectChanges.waitingProjectChanges',
          tip: 'ProjectChanges.sharedProjectChangesInfo',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.waiting?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.waiting?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.waiting?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.waiting?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.waiting?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.waiting?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.waiting?.changesBalance),
            color: this.summary?.waiting?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
        {
          title: 'ProjectChanges.acceptedProjectChanges',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.accepted?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.accepted?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.accepted?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.accepted?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.accepted?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.accepted?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.accepted?.changesBalance),
            color: this.summary?.accepted?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
        {
          title: 'ProjectChanges.rejectedProjectChanges',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.rejected?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.rejected?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.rejected?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.rejected?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.rejected?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.rejected?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.rejected?.changesBalance),
            color: this.summary?.rejected?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
      ],
    };
  }

  constructor(
    public changes: ChangeDetectorRef,
    public listService: ListService,
    private contactService: ContactService,
    private basePanelService: BasePanelService,
    private route: ActivatedRoute,
    private taskSidenavService: TaskSidenavService,
    private projectStore: ProjectStoreService,
    public projectPreviewService: ProjectPreviewService,
    private changesService: ProjectSummaryAPIService,
    private pricePipe: PriceFormatPipe,
    private storage: StorageService,
    private mailingService: MailingService,
  ) {
    super(changes, listService);
    this.ProjectChangesListConfig = ProjectChangesListConfig(this.route, this.storage);
    this.checkProjectId();
    this.ProjectChangesListConfig.rowClassFunction = this.rowClass.bind(this);
    this.ProjectChangesListConfig.treeAction.treeActionFunction = this.treeAction.bind(this);
    this.ProjectChangesListConfig.treeAction.treeDisabledFunction = this.treeDisabled.bind(this);
    this.initListConfig(this.ProjectChangesListConfig);
  }

  ngOnInit(): void {
    this.listenProjectChangeChanges();
    this.getChangesSummary();
    this.listenChangeRoute();
  }

  listenChangeRoute() {
    const sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.changes.detectChanges();
    });

    this.sub.add(sub);
  }

  getChangesSummary() {
    this.changesService.getChangesSummary(this.projectId).subscribe((summary) => {
      this.summary = summary;
      this.summaryConfig = Object.assign({}, this._config);
      this.changes.detectChanges();
    });
  }

  private _getMoneyAmount(value: number, withoutSymbol: boolean = false) {
    return this.pricePipe?.transform(value, withoutSymbol, this.storage?.Company?.currency);
  }

  getValueColor(value) {
    if (value === 0) {
      return ESummaryColor.BLACK;
    }
    return value > 0 ? ESummaryColor.GREEN : ESummaryColor.RED;
  }

  checkProjectId() {
    if (this.route?.parent?.parent?.snapshot?.params?.projectId) {
      this.projectId = this.route?.parent?.parent?.snapshot?.params?.projectId;
      this.ProjectChangesListConfig.url = `${Config.API}/project/${this.projectId}/changes`;
    } else {
      this.ProjectChangesListConfig.url = `${Config.API}/project/[{projectId}]/changes`;
    }
  }

  listenProjectChangeChanges() {
    this.sub.add(
      this.taskSidenavService.taskActionEmitter.subscribe((e: ITaskActionEvent) => {
        switch (e.action) {
          case ETaskAction.ACTION_CREATE:
          case ETaskAction.ACTION_DELETE:
          case ETaskAction.ACTION_UPDATE:
          case ETaskAction.ACTION_SHARE:
            this.listService.getRows();
            this.getChangesSummary();
            break;
        }
      }),
    );

    this.sub.add(
      this.mailingService.mailingSubject.subscribe((res: IMailing) => {
        switch (res.action) {
          case EMailingStates.EMAIL_SENT:
            this.listService.eventEmitter.emit({ type: ListEventType.CLEAR_CHECKBOX_SELECTION });
            this.listService.getRows();
            this.getChangesSummary();
            break;
          default:
            break;
        }
      }),
    );
  }

  treeDisabled(event: { row: Task }) {
    if (event.row?.versions?.length > 0) {
      return false;
    }
    return true;
  }

  treeAction(event: { row: Task }) {
    const row = event.row;
    if (row.treeStatus === TreeStatusEnum.COLLAPSED || !row.treeStatus) {
      this.openTree(row);
    } else {
      this.closeTree(row);
    }
  }

  openTree(row: Task): void {
    row.treeStatus = TreeStatusEnum.LOADING;

    this.getVersions(row);
  }

  closeTree(row: Task): void {
    row.treeStatus = TreeStatusEnum.COLLAPSED;
    this.list.service.rows = [...this.list.service.rows];
  }

  rowClass(row: Task) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.TASKS,
      action: 'CHANGE_PREVIEW',
      objectCreatorId: [
        row?.creator?.id,
        ...row.assignedTo.map((e) => e.id),
        row?.project?.basicDataBox?.responsibleEmployee?.id,
      ].filter(Boolean),
    });
    return !isNotNullOrUndefined(row?.deleted) && ctrl.check() && !row?.expanded ? 'cursor-pointer' : '';
  }

  private getVersions(row: Task) {
    let records = [...row.versions];
    for (let i = 0; i < records.length; i++) {
      const record = records[i];

      for (let j = 0; j < this.list.service.rows.length; j++) {
        const currentTask: Task = this.list.service.rows[j];
        if (Number(record.id) === Number(currentTask.id)) {
          records.splice(i, 1);
          i--;
          continue;
        } else {
          record['mainTaskId'] = row.id;
          record['expanded'] = true;
        }
      }
    }

    //Sort chronologically from newest to oldest
    records.sort(
      (a, b) => moment(b.created, Config.DATE_SERVER).unix() - moment(a.created, Config.DATE_SERVER).unix(),
    );

    this.list.table.count += records.length;
    this.list.service.rows = [...this.list.service?.rows, ...records];
    row.treeStatus = TreeStatusEnum.EXPANDED;
    this.list.changes.detectChanges();
  }

  createNewChange() {
    const ctrl = new ChangesSidenavController();
    ctrl.addChange(this.projectId);
  }

  activate(e: { type: string; row: Task; column: CustomTableColumn; event }) {
    if ((e.type === 'click' || e.type === 'touchstart') && !isNotNullOrUndefined(e.row.deleted)) {
      let hasNoSelect: boolean = false;
      const path = e.event.path || (e.event.composedPath && e.event.composedPath());
      path.map((el) => {
        if (el.classList && el.classList.contains('no-select')) {
          hasNoSelect = true;
        }
      });
      if (!e.column?.toggleMenu && !hasNoSelect && !e.row?.expanded) {
        const permissionCtrl = new TaskPermissionController();
        permissionCtrl.initPermissions(e?.row);

        const ctrl = new ChangesSidenavController();
        permissionCtrl.preview || permissionCtrl.canAccept
          ? ctrl.previewChange(e?.row?.id?.toString())
          : null;
      }
    }
  }

  showToggleMenu(row: Task) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.TASKS,
      action: 'CHANGE_PREVIEW',
      objectCreatorId: [
        row?.creator?.id,
        ...row.assignedTo.map((e) => e.id),
        this.projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id,
      ].filter(Boolean),
    });
    return (!isNotNullOrUndefined(row.deleted) && !row.expanded && ctrl.check()) || row?.canAccept;
  }

  shareChanges() {
    const ctrl = new ChangesSidenavController();
    // const sub =
    ctrl.shareMultipleChanges(this.checkedTaskIds, this.projectId, this.projectStore?.projectCtrl?.project);
    // .subscribe(() => {
    //   sub.unsubscribe();
    // });
  }

  listenListEvents() {
    this.listService.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.CONFIG_COLUMNS:
          this.configColumns();
          break;
        case ListEventType.END_GET_ROWS:
          this.checkedTaskIds = [];
          document.querySelectorAll('.changes-name-cell input').forEach((checkboxEl) => {
            (checkboxEl as HTMLInputElement).checked = false;
          });
          this.changes.detectChanges();
          break;
        case ListEventType.ALL_CHECKBOXES_SELECT:
          this.checkedTaskIds = this.listService.rows
            .filter((i) => i?.level === 0)
            .map((r) => {
              if (r.checked) {
                return r.id;
              }
            });
          this.changes.detectChanges();
          break;
        case ListEventType.ALL_CHECKBOXES_UNSELECT:
          this.checkedTaskIds = [];
          this.changes.detectChanges();
          break;
        case ListEventType.SINGLE_CHECKBOX_SELECT:
          this.checkedTaskIds.push(e.data.row.id);
          this.changes.detectChanges();
          break;
        case ListEventType.SINGLE_CHECKBOX_UNSELECT:
          const index = this.checkedTaskIds.indexOf(e.data.row.id);
          this.checkedTaskIds.splice(index, 1);
          this.changes.detectChanges();
          break;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sub.unsubscribe();
  }
}
