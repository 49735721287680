import { Injectable, EventEmitter } from '@angular/core';
import { MailingHttpService } from './mailing-http.service';

export interface IMailing {
  action: EMailingStates;
  data?: any;
}

export enum EMailingStates {
  EMAIL_SENT,
}

@Injectable({ providedIn: 'root' })
export class MailingService {
  mailingSubject: EventEmitter<IMailing> = new EventEmitter();

  constructor(private http: MailingHttpService) {}

  sendProjectStageEmail(data) {
    return this.http.sendProjectStageEmail(data);
  }

  getProjectStageTemplates() {
    return this.http.getProjectStageTemplates();
  }
}
