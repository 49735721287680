<main class="relative confirmation-page">
  <div
    *ngIf="loading"
    class="confirmation-page__loader d-flex flex-column align-items-center justify-content-center"
  >
    <div class="spinner-grow text-primary" role="status"></div>
    <p
      class="text-primary mt-5 auth-welcome-sub"
      [innerHTML]="'Auth.Confirmation.confirmationLoading' | translate"
    ></p>
  </div>

  <div *ngIf="!loading" class="container-fluid text-center">
    <h1
      class="auth-welcome"
      [innerHTML]="
        (confirmed ? 'Auth.Confirmation.confirmed' : 'Auth.Confirmation.alreadyConfirmed') | translate
      "
    ></h1>
    <div class="blue-300 auth-welcome-sub" [innerHTML]="'Auth.Confirmation.welcome' | translate"></div>
    <div
      class="grey-4 auth-fill"
      [innerHTML]="(confirmed ? 'Auth.Confirmation.fillData' : 'Auth.Confirmation.goToLoginPage') | translate"
    ></div>
    <ng-container *ngIf="confirmed">
      <div>
        <button (click)="n.navigate('company-settings')" class="btn btn-primary">
          <img draggable="false" src="./assets/img/ic-arrow-right.svg" />
          <span [innerHTML]="'Auth.Confirmation.companyForm' | translate"></span>
        </button>
      </div>
      <div class="mt-5">
        <button
          (click)="n.navigate('user-settings')"
          class="btn btn-light"
          [innerHTML]="'Auth.Confirmation.fillLater' | translate"
        ></button>
      </div>
    </ng-container>
    <ng-container *ngIf="!confirmed">
      <button (click)="n.navigate('login')" class="btn btn-primary">
        <img draggable="false" src="./assets/img/ic-arrow-right.svg" />
        <span [innerHTML]="'Auth.Confirmation.goToLogin' | translate"></span>
      </button>
    </ng-container>
  </div>
</main>
