import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EAttendance } from '@shared/modules/event-sidenav/enums/attendance.enum';

@Component({
  selector: 'app-event-acceptation',
  templateUrl: './event-acceptation.component.html',
  styleUrls: ['./event-acceptation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAcceptationComponent implements OnInit {
  EAttendance = EAttendance;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  event: CalendarEvent;
  pending: boolean = false;

  get token() {
    return this.active.snapshot?.params?.token;
  }

  constructor(
    private active: ActivatedRoute,
    private service: ClientAcceptanceService,
    private changes: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.service.getEventByToken(this.token).subscribe({
      next: (event: CalendarEvent) => this.successEvent(event),
    });
  }

  successEvent(event: CalendarEvent) {
    this.event = new CalendarEvent(event);
    this.changes.detectChanges();
  }

  previewProject() {
    if (!this.event?.project) return;
    const ctrl = new ProjectController(this.event.project);
    ctrl.preview(this.event.project);
  }

  eventAcceptation() {
    if (this.pending) return;
    this.pending = true;
    this.service.acceptEventByToken(this.token).subscribe({
      next: () => {
        this.pending = false;
        this.event.myParticipation.status = EAttendance.CONFIRMED;
        this.changes.detectChanges();
      },
      error: () => {
        this.pending = false;
        this.changes.detectChanges();
      },
    });
  }

  eventRejection() {
    if (this.pending) return;
    this.pending = true;
    this.service.rejectEventByToken(this.token).subscribe({
      next: () => {
        this.pending = false;
        this.event.myParticipation.status = EAttendance.REJECTED;
        this.changes.detectChanges();
      },
      error: () => {
        this.pending = false;
        this.changes.detectChanges();
      },
    });
  }
}
