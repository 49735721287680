import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppInjector } from '@shared/services/app-injector.service';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';

export class CalendarSettingsController {
  settingsOpened: boolean;
  selectedDate: any;

  constructor() {
    this.initializeDefaultViewState();
  }

  initializeDefaultViewState() {
    this.settingsOpened = false;
    this.selectedDate = moment();
  }
}
