import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { RegisterController } from '@modules/authorization/shared/controllers/register.controller';

@Component({
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss'],
})
export class RegisterDialogComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  ctrl: RegisterController;

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { token: string; email: string; callback: () => void },
  ) {}

  ngOnInit(): void {
    this.ctrl = new RegisterController();
    this.ctrl.setCallback(this.callback.bind(this));
    this.ctrl.createForm();
    this.ctrl.setValidatorsSimple();
    this.ctrl.form.get(this.ctrl.REGISTER_FORM.email).setValue(this.data?.email || null);
  }

  callback() {
    this.dialogRef.close();
    this.data.callback();
  }
}
