import { Component, OnInit, ViewChild } from '@angular/core';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Employee } from '@shared/models/employee.model';
import { ListService } from '@shared/modules/list/services/list.service';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTeamService } from '@modules/projects/shared/services/project-team.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectTeamEditMemberModalComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-edit-memeber-modal/project-team-edit-memeber-modal.component';
import { Config } from '@shared/configs/config';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { Project } from '@modules/projects/shared/models/project.model';
import { CalendarController } from '@modules/calendar/shared/controllers/calendar.controller';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'project-team-toggle-menu-cell',
  templateUrl: './project-team-toggle-menu-cell.component.html',
  styles: []
})
export class ProjectTeamToggleMenuCellComponent implements OnInit, ListCustomCell {
  PermissionsGroups = PermissionsGroups;

  row: { employee: Employee, isMainContact: boolean, isContactPerson: boolean, deleted: boolean, isResponsibleEmployee: boolean };

  @ViewChild('template') template;

  constructor(
    private listService: ListService,
    private projectApiService: ProjectAPIService,
    public projectPreviewService: ProjectPreviewService,
    private projectTeamService: ProjectTeamService,
    private s: SnackBarService,
    private t: TranslateService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  editTeamMember() {
    this.dialog.open(ProjectTeamEditMemberModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      data: this.row
    });
  }

  setResponsible() {
    this.projectApiService.setResponsibleEmployee(this.projectPreviewService.project.id, this.row.employee.id).subscribe({
      next: (res: Project) => {
        this.projectPreviewService.updateProject();
        this.s.success(this.t.instant('ProjectTeam.List.successSetResponsible'));
        this.listService.getRows();
      },
      error: () => {
        this.s.defaultError();
      }
    });
  }

  calendar() {
    const ctrl = new CalendarController();
    ctrl.openEmployeeCalendar([this.row?.employee?.defaultCalendar?.id]);
  }

  previewContact() {
    const ctrl = new ContactController(this.row.employee.contacts[0]);
    ctrl.preview(this.row.employee.contacts[0].id);
  }

  removeFromProject() {
    const ctrl = new RemoveModalController();
    ctrl
    .remove(() => {
      this.projectTeamService.removeTeamMember(this.row.employee.id, this.projectPreviewService.project.id).subscribe({
        next: () => {
          this.projectPreviewService.updateProject();
          this.s.success(this.t.instant('ProjectTeam.List.removedSuccess'));
          this.listService.getRows();
        },
        error: () => {
          this.s.error(this.t.instant('ProjectTeam.List.removedError'));
        }
      });
    })
    .subscribe(() => {});
  }

  getPreviewName() {
    const employee = new Employee(this.row.employee);
    return employee.userPerson.previewName;
  }
}
