import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { AttachmentPacket } from '../models/attachment-packet.model';
import { AttachmentService } from '@shared/services/attachment.service';

@Injectable()
export class AttachmentsManageService extends BaseHttpService {
  attachmentPacket: AttachmentPacket = null;

  constructor(public attachmentService: AttachmentService) {
    super();
    this.setInitialAttachmentPacket();
  }

  setInitialAttachmentPacket() {
    this.attachmentPacket = new AttachmentPacket();
  }

  createAttachmentPacket(attachments?: any[], config?: any): Observable<AttachmentPacket> {
    let str = Object.entries(config || {})
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    const url = `${Config.API}/attachment-packet${str?.length ? '?' + str : ''}`;
    return this.post(url, { attachments }, false, true).pipe(
      map((attachmentPacket) => {
        this.attachmentPacket = new AttachmentPacket(attachmentPacket);
        return this.attachmentPacket;
      }),
    );
  }

  updateAttachmentPacket(id: number, attachments: any[], config?: any): Observable<AttachmentPacket> {
    let str = Object.entries(config || {})
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    const url = `${Config.API}/attachment-packet/${id}${str?.length ? '?' + str : ''}`;
    return this.post(url, { attachments }, false, true).pipe(
      map((attachmentPacket) => {
        this.attachmentPacket = new AttachmentPacket(attachmentPacket);
        return this.attachmentPacket;
      }),
    );
  }

  deleteAttachmentPacket(id: number): Observable<object> {
    const url = `${Config.API}/attachment-packet/${id}`;
    return this.delete(url);
  }
}
