import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { LanguageService } from '@core/services/language.service';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ProjectsTimelineFavouritesComponent } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/components/projects-timeline-favourites/projects-timeline-favourites.component';
import { projectsTimelineCalendarConfig } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/projects-timeline-calendar.config';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { SnackBarService } from '@core/services/snackbar.service';
import { Subscription } from 'rxjs';
import { ProjectsTimelineResourceLabelComponent } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/components/projects-timeline-resource-label/projects-timeline-resource-label.component';
import { IProjectsTimelineResourceEvent } from '@modules/projects/modules/projects-timeline/shared/interfaces/projects-timeline-resource-event.interface';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { Duration, EventApi, ViewApi } from '@fullcalendar/common';
import { ResourceApi } from '@fullcalendar/resource-common';
import { ProjectsTimelineEventController } from './controllers/projects-timeline-event.controller';
import { ProjectsTimelineResourceController } from './controllers/projects-timeline-resource.controller';
import { WindowHelper } from '@shared/helpers/window.helper';
import { debounce } from '@shared/decorators/debounce.decorator';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import {
  ProjectChangeStageEvent,
  ProjectChangeStageEventType,
} from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { ProjectsTimelineStorageService } from '../../shared/services/projects-timeline-storage.service';
import { ProjectsTimelineHeaderComponent } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/components/projects-timeline-header/projects-timeline-header.component';
import { ProjectsTimelinePopoverComponent } from '@modules/projects/modules/projects-timeline/pages/projects-timeline/components/projects-timeline-popover/projects-timeline-popover.component';

@Component({
  selector: 'projects-timeline',
  templateUrl: './projects-timeline.component.html',
  styleUrls: ['./projects-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsTimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  calendarOptions: CalendarOptions = projectsTimelineCalendarConfig(
    this.languageService,
    this.getFavouritesButtonComponent.bind(this),
    this.getResourceLabelComponent.bind(this),
    this.onEventChange.bind(this),
    this.onEventClick.bind(this),
    this.eventDidMount.bind(this),
    this.projectsTimelineStorage,
    this.slotLabelClick.bind(this),
  );

  favourited: boolean = false;
  sub: Subscription = new Subscription();
  eventController: ProjectsTimelineEventController;
  resourceController: ProjectsTimelineResourceController;
  favouriteButton;
  blockEventUpdate: boolean = false;
  openedPopover: { project: Project; parentElement: HTMLBaseElement };

  @ViewChild('fullCalendarComponent') fullCalendarComponent: FullCalendarComponent;
  @ViewChild('timeline') timeline: ElementRef;
  @ViewChild('calendarContainer') calendarContainer: ElementRef;
  @ViewChild('timelineHeaderComponent') timelineHeaderComponent: ProjectsTimelineHeaderComponent;
  @ViewChild('popoverComponent') popoverComponent: ProjectsTimelinePopoverComponent;

  get calendar() {
    return this.fullCalendarComponent?.getApi();
  }

  constructor(
    private t: TranslateService,
    public n: NavigateService,
    private changes: ChangeDetectorRef,
    private languageService: LanguageService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private projectStageApiService: ProjectStageAPIService,
    private projectApiService: ProjectAPIService,
    private s: SnackBarService,
    public renderer: Renderer2,
    public viewContainerRef: ViewContainerRef,
    public projectsTimelineStorage: ProjectsTimelineStorageService,
  ) {}

  ngOnInit(): void {
    this.getStages();
  }

  ngAfterViewInit() {
    this.eventController = new ProjectsTimelineEventController(this.calendar);
    this.resourceController = new ProjectsTimelineResourceController(this.calendar);
    this.setContainersHeight();
  }

  slotLabelClick(e: { level: number; time: Duration; date: Date; view: ViewApi; text: string }) {
    if (e.view.type === 'resourceTimelineYear' && e.level === 1) {
      this.timelineHeaderComponent.onChangeViewType('resourceTimelineMonth');
      this.calendar.gotoDate(e.date);
      this.changes.detectChanges();
    }

    if (e.view.type === 'resourceTimelineWeeksSlot') {
      this.timelineHeaderComponent.onChangeViewType('resourceTimelineMonth');
      this.calendar.gotoDate(e.date);
      this.changes.detectChanges();
    }

    if (e.view.type === 'resourceTimelineMonth' && e.level === 1) {
      this.timelineHeaderComponent.onChangeViewType('resourceTimelineWeek');
      this.calendar.gotoDate(e.date);
      this.changes.detectChanges();
    }
  }

  private getStages() {
    this.projectStageApiService.getStages(this.favourited).subscribe({
      next: this.onGetStagesSuccess.bind(this),
      error: () => {
        this.s.error(this.t.instant('ProjectsTimeline.errorGetStages'));
      },
    });
  }

  private onGetStagesSuccess(stages: IProjectStage[]) {
    this.calendar.removeAllEvents();
    this.resourceController.removeAllResources();
    this.resourceController.addStagesResources(stages);
    this.eventController.addStagesEvents(stages);
  }

  private getFavouritesButtonComponent() {
    if (this.favouriteButton) {
      return {
        domNodes: [this.favouriteButton],
      };
    }
    this.favouriteButton = document.createElement('div');
    let container = this.getDomPortalHost(this.favouriteButton);

    const reference = container.attach(new ComponentPortal(ProjectsTimelineFavouritesComponent));
    reference.instance.favourited = this.favourited;
    const sub = reference.instance.onFavouriteClick.subscribe((e: boolean) => {
      this.favourited = e;
      this.getStages();
    });
    this.sub.add(sub);
    return {
      domNodes: [this.favouriteButton],
    };
  }

  private getResourceLabelComponent(content: { resource: ResourceApi; fieldValue: string }) {
    const resourceLabel = document.createElement('span');
    let container = this.getDomPortalHost(resourceLabel);

    const reference = container.attach(new ComponentPortal(ProjectsTimelineResourceLabelComponent));
    reference.instance.resource = content.resource;
    const sub = reference.instance.eventEmitter.subscribe(this.onResourceToggle.bind(this));
    const sub2 = reference.instance.onProjectChange.subscribe(this.onProjectChange.bind(this));
    this.sub.add(sub);
    this.sub.add(sub2);

    return {
      domNodes: [resourceLabel],
    };
  }

  private getDomPortalHost(element: Element) {
    return new DomPortalHost(element, this.componentFactoryResolver, this.appRef, this.injector);
  }

  private onResourceToggle(e: IProjectsTimelineResourceEvent) {
    const oldScroll = document.querySelector('.fc-timeline-body').parentElement.scrollTop;

    if (e.open) {
      this.getProjectsForStage(e.stage, e.resource).add(() => {
        this.setScrollAfterGetProjects(oldScroll);
      });
    } else {
      e.resource.setExtendedProp('opened', false);
      this.resourceController.removeProjectsResourcesFromStage(e.stage);
      this.eventController.removeEventsWithoutResources();
      this.setScrollAfterGetProjects(oldScroll);
    }
  }

  private getProjectsForStage(stage: ProjectStage, resource: ResourceApi) {
    return this.projectApiService.getProjectsForStage(stage, this.favourited).subscribe({
      next: (projects: Project[]) => {
        this.resourceController.addProjectsResources(projects);
        this.eventController.addProjectsEvents(projects);
        resource.setExtendedProp('opened', !resource._resource.extendedProps.opened);
      },
    });
  }

  private setScrollAfterGetProjects(oldScroll) {
    document.querySelector('.fc-timeline-body').parentElement.scrollTop = oldScroll;
    document.querySelector('.fc-datagrid-body').parentElement.scrollTop = oldScroll;
  }

  private onEventClick(e: { el: Element; event: EventApi; view: ViewApi; jsEvent: MouseEvent }) {
    if (e.event._def.extendedProps.isStageEvent) {
      this.onStageEventClick(e);
    } else {
      this.onProjectEventClick(e);
    }
  }

  private onStageEventClick(e: { el: Element; event: EventApi; view: ViewApi; jsEvent: MouseEvent }) {
    const stageResource = this.calendar.getResourceById(e.event.id);
    this.onResourceToggle({
      open: !stageResource.extendedProps.opened,
      stage: e.event.id as ProjectStage,
      resource: stageResource,
    });
  }

  private onProjectEventClick(e: { el: Element; event: EventApi; view: ViewApi; jsEvent: MouseEvent }) {
    if (this.openedPopover && this.openedPopover.parentElement === e.el) {
      this.closePopover();
    } else if (this.openedPopover && this.openedPopover.parentElement !== e.el) {
      this.closePopover();
      this.openPopover(e.event._def.extendedProps as Project, e.el as HTMLBaseElement);
    } else {
      this.openPopover(e.event._def.extendedProps as Project, e.el as HTMLBaseElement);
    }
  }

  private openPopover(project: Project, el: HTMLBaseElement) {
    this.openedPopover = {
      project: new Project(project),
      parentElement: el,
    };
    this.changes.detectChanges();
  }

  private closePopover() {
    this.popoverComponent.onClose();
    this.changes.detectChanges();
  }

  measureText(pText: string, pFontSize: number) {
    let lDiv: any = document.createElement('div');

    document.body.appendChild(lDiv);
    lDiv.style.fontSize = '' + pFontSize + 'px';
    lDiv.style.position = 'absolute';
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.innerHTML = pText;

    let lResult = lDiv.clientWidth;

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
  }

  private eventDidMount(e: { el: HTMLElement }) {
    const pos = e.el.getBoundingClientRect();
    const scroller = document.querySelector('.fc-timeline-body').parentNode as HTMLElement;
    const textContent = e.el.querySelector('.fc-event-title').textContent;

    e.el.setAttribute('title', textContent);

    if (scroller) {
      if (scroller.clientWidth !== scroller.scrollWidth) {
        const contentWidth = this.measureText(textContent, 11);
        if (
          pos.right > scroller.clientWidth ||
          pos.right < 0 ||
          (pos.width < contentWidth && pos.right + contentWidth - 200 > scroller.clientWidth)
        ) {
          e.el.classList.add('rtl');
        }
      }
    }
  }

  private onEventChange(e: { event: EventApi; oldEvent: EventApi; relatedEvents?; revert?: Function }) {
    if (!e.event?._def?.extendedProps?.id || this.blockEventUpdate) {
      return;
    } //if it's edit event for whole stage not event connected with project

    this.blockEventUpdate = true;
    const project = new Project(e.event._def.extendedProps);
    let newStage = project.stage;
    const startDate = this.getChangeEventNewDate('start', e);
    const endDate = this.getChangeEventNewDate('end', e);
    project.basicDataBox.termStart = startDate;
    project.basicDataBox.termEnd = endDate;
    e.event._def.extendedProps.basicDataBox.termStart = startDate;
    e.event._def.extendedProps.basicDataBox.termEnd = endDate;
    e.event.setProp('classNames', 'fc-event--' + newStage + ' fc-event--project-event');

    if (e.event?._def?.resourceIds[0] !== project.id.toString()) {
      newStage = e.event._def.resourceIds[0] as ProjectStage;
      if (newStage === ProjectStage.STAGE_LOST) {
        this.setLostStage(project, newStage, startDate, endDate, e);
        return false;
      }
      this.resourceController.updateResourcesAfterEventChange(project, newStage, e.event);
    } else {
      this.eventController.updateOpenedStageEvent(newStage);
    }
    this.resourceController.updateProjectResource(project);
    const ctrl = new ProjectController(project);
    ctrl.setStage(project, newStage, startDate, endDate).toPromise();
    this.blockEventUpdate = false;

    this.changes.detectChanges();
  }

  onProjectChange(e: { project: Project; oldStage?: ProjectStage }) {
    this.blockEventUpdate = true;
    this.eventController.updateProjectEvent(e.project);
    this.resourceController.updateProjectResource(e.project);
    const newStage = e.project.stage;
    e.oldStage ? (e.project.stage = e.oldStage) : '';
    e.oldStage ? this.resourceController.updateResourcesAfterEventChange(e.project, newStage, null) : '';
    this.blockEventUpdate = false;
    this.changes.detectChanges();
  }

  private setLostStage(
    project: Project,
    newStage: ProjectStage,
    startDate: string,
    endDate: string,
    e: { event: EventApi; revert?: Function },
  ) {
    const ctrl = new ProjectController(project);
    ctrl
      .setStage(project, newStage, startDate, endDate)
      .subscribe((projectChangeStageEvent: ProjectChangeStageEvent) => {
        switch (projectChangeStageEvent.type) {
          case ProjectChangeStageEventType.CANCELED:
            e.revert();
            this.blockEventUpdate = false;
            this.changes.detectChanges();
            break;
          case ProjectChangeStageEventType.CHANGED:
            this.resourceController.updateResourcesAfterEventChange(project, newStage, e.event);
            this.blockEventUpdate = false;
            this.changes.detectChanges();
            break;
        }
      });
    return false;
  }

  private getChangeEventNewDate(type: string, e: { event: EventApi; oldEvent: EventApi; relatedEvents? }) {
    return e.event[type]
      ? moment(e.event[type]).format(Config.DATE_SERVER)
      : moment(e.oldEvent[type]).format(Config.DATE_SERVER);
  }

  private setContainersHeight() {
    if (WindowHelper.isMobileWidth) {
      let clientHeight = document.documentElement.clientHeight;
      this.timeline.nativeElement.style.height = clientHeight - 65 + 'px';
      this.calendarContainer.nativeElement.style.height = clientHeight - 65 - 55 + 'px';
    }
  }

  @HostListener('window:resize')
  @debounce(100)
  windowResize() {
    this.setContainersHeight();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
