import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'project-right-section-title',
  templateUrl: './project-right-section-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectRightSectionTitleComponent implements OnInit {

  @Input() title: string;
  @Input() class: string;

  constructor() { }

  ngOnInit(): void {
  }

}
