export enum TASK_SIDENAV_FORM {
  type = 'type',
  status = 'status',
  id = 'id',
  content = 'content',
  description = 'description',
  termStart = 'termStart',
  termEnd = 'termEnd',
  groupId = 'groupId',
  assignedTo = 'assignedToIds',
  tags = 'tags',
  isHighPriority = 'isHighPriority',
  hiddenForClient = 'hiddenForClient',
  attachments = 'attachments',
  discount = 'discount',
  objectType = 'objectType',
  attachmentPacketId = 'attachmentPacketId',
  additionalTime = 'additionalTime',
  price = 'price',
  changeStatus = 'changeStatus',
  changeStatusHelper = 'changeStatusHelper',
  source = 'source',
  protocol = 'protocol',
  linkedChangeId = 'linkedChangeId',
  projectId = 'projectId',
  protocolId = 'protocolId',
  showInCalendar = 'showInCalendar',
  isPriceEstimated = 'isPriceEstimated',
  isAdditionalTimeEstimated = 'isAdditionalTimeEstimated',
  internalNote = 'internalNote',
  settlementStatus = 'settlementStatus',
}
