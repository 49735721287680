<main>
  <form [formGroup]="form" class="auth-form">
    <h1
      class="blue-500 thin-title thin-title--small-mb"
      [innerHTML]="'Auth.Contact.contact' | translate"
    ></h1>
    <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.Contact.contactForm' | translate"></div>
    <div class="grey-4 grey-text" [innerHTML]="'Auth.Contact.contactHelp' | translate"></div>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="person-control special-control required"
      formControlName="name"
      [config]="{ name: 'Auth.Contact.name' | translate, type: 'text', placeholder: '' }"
    >
    </gbxsoft-input>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="email-control special-control required"
      formControlName="email"
      [config]="{ name: 'Auth.Contact.email' | translate, type: 'email', placeholder: '' }"
    >
    </gbxsoft-input>
    <gbxsoft-input
      [errMessages]="errorMessages"
      class="info-control special-control required"
      formControlName="topic"
      [config]="{ name: 'Auth.Contact.subject' | translate, type: 'text', placeholder: '' }"
    >
    </gbxsoft-input>
    <gbxsoft-textarea
      [errMessages]="errorMessages"
      class="textarea required"
      formControlName="description"
      [config]="{ name: 'Auth.Contact.content' | translate, type: 'text', placeholder: '' }"
    >
    </gbxsoft-textarea>
    <div class="d-flex align-items-center justify-content-end mt-4">
      <app-button
        [class]="'bold'"
        (onClick)="location.back()"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Auth.Contact.back' | translate
        }"
      >
      </app-button>

      <app-button
        [class]="'ml-2'"
        (onClick)="submit()"
        [loading]="loading"
        [disabled]="loading"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Auth.Contact.send' | translate,
          prependSrc: './assets/img/ic-arrow-right.svg'
        }"
      >
      </app-button>
    </div>
    <div class="auth-form-write">
      <h5 class="auth-form-write-title" [innerHTML]="'Auth.Contact.contactWriteToUs' | translate"></h5>
      <a draggable="false" class="auth-form-write-email" href="mailto:{{ CONFIG.CONTACT_EMAIL }}">{{
        CONFIG.CONTACT_EMAIL
      }}</a>
    </div>
  </form>
</main>
