import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { ProjectStatistics } from '@modules/projects/shared/models/project-statistics.interface';
import { ProjectSummaryAPIService } from '@modules/projects/shared/services/project-summary-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { EventAction } from '@shared/modules/event-sidenav/enums/event-action.enum';
import { IEventAction } from '@shared/modules/event-sidenav/interfaces/event-action.interface';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-calendar',
  templateUrl: './project-calendar.component.html',
  styleUrls: ['./project-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCalendarComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  projectStatistics: ProjectStatistics;
  subs: Subscription = new Subscription();

  constructor(
    private projectPreviewService: ProjectPreviewService,
    private projectSummaryApiService: ProjectSummaryAPIService,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    private s: SnackBarService,
    private eventService: EventSidenavService,
  ) {}

  ngOnInit() {
    this.getStatistics();
    this.listenEventsManage();
  }

  getStatistics() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'SUMMARY_STATISTICS',
      objectCreatorId: this.projectPreviewService.project.basicDataBox?.responsibleEmployee?.id,
    });

    if (this.loading || !ctrl.check()) {
      return;
    }
    this.loading = true;
    this.projectSummaryApiService
      .getSummary(this.projectPreviewService.project.id)
      .subscribe({
        next: (res: ProjectStatistics) => {
          res.upcomingEvents.sort((a: CalendarEvent, b: CalendarEvent) => {
            return moment(a.termStart, Config.DATE_SERVER).diff(moment(b.termStart, Config.DATE_SERVER));
          });
          this.projectStatistics = res;
          this.changes.detectChanges();
        },
        error: () => {
          this.s.error(this.t.instant('ProjectSummary.errorGettingStatistics'));
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  listenEventsManage() {
    const sub = this.eventService.CalendarEventEmitter.subscribe((e: IEventAction) => {
      switch (e.action) {
        case EventAction.ACTION_EDIT:
        case EventAction.ACTION_ADD:
          this.getStatistics();
          break;
      }
    });
    this.subs.add(sub);
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
