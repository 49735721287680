import { Injectable } from '@angular/core';

@Injectable()
export class TopProgressBarService {
  private _loading: boolean = false;
  timeout;

  constructor() {
  }

  showTopProgressBar() {
    if (this._loading) {
      return;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => (this._loading = true), 0);
  }

  hideTopProgressBar() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => (this._loading = false), 200);
  }

  get loading() {
    return this._loading;
  }
}
