<list-single-filter [template]="filter" [name]="name" [iconUrl]="iconUrl" [form]="form" [controlName]="controlName"></list-single-filter>

<ng-template #filter>
  <div class="list-filter-single__value" [ngClass]="{ hasValue: isValue }">
    <input
      #input
      matSuffix
      class="list-filter-single__date-input"
      placeholder="{{ 'List.Filters.notChosen' | translate }}"
      [formControl]="form.get(controlName)"
      matInput
      (click)="dp.open()"
      readonly
      (dateChange)="change.emit()"
      [matDatepicker]="dp"
    />
    <mat-datepicker #dp disabled="false"></mat-datepicker>

    <div class="list-filter-single__actions">
      <span class="list-filter-single__clear" (click)="clear()" *ngIf="isValue">×</span>
      <span class="list-filter-single__arrow" (click)="onArrowClick()"></span>
    </div>
  </div>
</ng-template>
