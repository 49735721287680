<div class="download-app" [ngClass]="{'hidden': hidden}">
  <div class="container">
    <div class="d-flex justify-content-between align-items-start align-items-xl-center  flex-column flex-xl-row">

      <div class="download-app-content">
        <h2 [innerHTML]="'DownloadApp.title' | translate"></h2>
        <h5 *ngIf="isDesktopVersion" [innerHTML]="'DownloadApp.subtitleDesktop' | translate"></h5>
        <h5 *ngIf="!isDesktopVersion" [innerHTML]="'DownloadApp.subtitleMobile' | translate"></h5>
      </div>

      <div class="download-app-actions align-items-center">
        <app-button
          (click)="closeDownloadAppMessage()"
          [config]="
        {
          size: ButtonSize.LARGE,
          text: 'DownloadApp.close' | translate,
          prependSrc: '/assets/img/ic-close.svg',
          type:  ButtonTypes.SECONDARY_WHITE
        }">
        </app-button>

        <app-button
          (click)="downloadApp()"
          class="ml-2"
          [config]="
          {
            size: ButtonSize.LARGE,
            text: isDesktopVersion ? ('DownloadApp.downloadDesktop' | translate) : ('DownloadApp.downloadMobile' | translate),
            prependSrc: '/assets/img/ic-download-outline.svg',
            type:  ButtonTypes.PRIMARY_GREEN
          }">
        </app-button>
      </div>
    </div>
  </div>
</div>
