import { INotificationManager } from '@modules/notification/shared/interfaces/notification-manager.interface';
import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { INotificationAvatarConfig } from '@modules/notification/shared/interfaces/notification-avatar-config.interface';
import { Notification } from '@modules/notification/shared/models/notification.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { NotificationBaseComponent } from '@modules/notification/shared/components/notification/types/notification-base/notification-base.component';
import { NotificationType } from '@modules/notification/shared/models/notification-type';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { ProtocolsStatuses } from '@modules/protocols/shared/enums/protocols-statuses.enum';
import { NotificationProtocolAcceptanceComponent } from '@modules/notification/shared/components/notification/types/protocol/notification-protocol-acceptance/notification-protocol-acceptance.component';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { Project } from '@modules/projects/shared/models/project.model';

export class NotificationProtocolManager implements INotificationManager {
  resolver: ComponentFactoryResolver;
  t: TranslateService;
  sidenav: SidenavService;
  avatarConfig: INotificationAvatarConfig = {
    src: '/assets/img/ic-document-text.svg',
    type: 'svg',
    class: 'blue-300',
    hideAvatar: false,
  };

  constructor(private notification: Notification<any>, private markAsShown?: Function) {
    this.resolver = AppInjector.getInjector().get(ComponentFactoryResolver);
    this.t = AppInjector.getInjector().get(TranslateService);
    this.sidenav = AppInjector.getInjector().get(SidenavService);
  }

  getFactory(): ComponentFactory<any> {
    return this.resolver.resolveComponentFactory(NotificationBaseComponent);
  }

  createComponent(content: ViewContainerRef): Function | null {
    let factory = this.getFactory();
    let component = content.createComponent<NotificationBaseComponent>(factory);

    switch (this.notification.type) {
      // (N.2.1.2)
      case NotificationType.PROTOCOL.ACCEPTANCE_SENT:
        factory = this.resolver.resolveComponentFactory(NotificationProtocolAcceptanceComponent);
        component = content.createComponent<NotificationProtocolAcceptanceComponent>(factory);
        (component.instance as NotificationProtocolAcceptanceComponent).protocol = new Protocol(
          this.notification.data.last_acceptance.protocol,
        );
        (component.instance as NotificationProtocolAcceptanceComponent).id = this.notification.data.last_acceptance.id;
        const sub = (component.instance as NotificationProtocolAcceptanceComponent).markAsReadEmitter.subscribe(
          () => {
            this.markAsShown ? this.markAsShown() : '';
            sub.unsubscribe();
          },
        );
        component.instance.text = this.t.instant(
          `Notifications.Texts.Protocol.${this.notification.type}`,
          this.getNotificationTextData(),
        );
        return null;

      // (N.2.1.3) // (N.2.1.4) // (N.2.1.5)
      case NotificationType.PROTOCOL.ACCEPTANCE_ACCEPTED:
      case NotificationType.PROTOCOL.ACCEPTANCE_REMINDER:
      case NotificationType.PROTOCOL.ACCEPTANCE_SHOWN_REMINDER:
        component.instance.text = this.t.instant(
          `Notifications.Texts.Protocol.${this.notification.type}`,
          this.getNotificationTextData(),
        );
        return () => {
          const protocolController = new ProtocolController(
            null,
            this.notification.data.protocol_acceptance.protocol,
          );
          protocolController.previewAcceptance(this.notification.data.protocol_acceptance);
        };

      // (N.2.1.6)
      case NotificationType.PROTOCOL.DRAFT_REMINDER:
        this.avatarConfig.src = '/assets/img/ic-alert-circle-orange.svg';
        this.avatarConfig.class = 'orange-500';
        component.instance.text = this.t.instant(
          `Notifications.Texts.Protocol.${this.notification.type}`,
          this.getNotificationTextData(true),
        );

        return () => {
          const protocolController = new ProtocolController(null, this.notification.data.protocol);
          protocolController.edit();
          this.sidenav.close();
        };
    }

    return null;
  }

  getNotificationTextData(isStandaloneProtocol: boolean = false) {
    const protocol_acceptance = this.notification.data.protocol_acceptance;
    let protocol: Protocol = new Protocol(protocol_acceptance?.protocol);

    if (isStandaloneProtocol) {
      protocol = this.notification.data.protocol;
    }
    const project = new Project(protocol.project);

    return {
      protocol_type: this.t.instant(`Protocols.Types.${protocol.type}`),
      protocol_number: protocol.idNumber ? protocol.idNumber : this.t.instant('Protocols.newName'),
      project_fullName: project.fullName ? project.fullName : '',
      protocol_acceptance_type:
        protocol_acceptance?.status === ProtocolsStatuses.STATUS_ACCEPTED
          ? this.t.instant('Notifications.Texts.Protocol.accepted')
          : this.t.instant('Notifications.Texts.Protocol.declined'),
    };
  }
}
