import { Component, Input } from '@angular/core';

@Component({
  selector: 'radio-box',
  templateUrl: './radio-box.component.html',
  styleUrls: ['./radio-box.component.scss'],
})
export class RadioBoxComponent {
  @Input() checked: boolean;
  @Input() title: string;
  @Input() description?: string;
  @Input() smallText?: boolean = false;

  constructor() {}
}
