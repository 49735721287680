import { Contact } from '@modules/contacts/shared/models/contact.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { UserPerson } from '@shared/interfaces/user.interface';
import { ListItem } from '@shared/interfaces/list-item.interface';

export class EmployeeSearchItem {
  deleted?: string;
  created: string;
  modified: string;
  id: number;
  userPerson: UserPerson;
  role: string;
  active: boolean;
  removed: UserRoles;
  contacts: Contact[] = [];
  defaultCalendar?: any;

  get isClient() {
    return this.role === UserRoles.ROLE_EMPLOYEE_CLIENT;
  }

  get ListItem(): ListItem {
    return {
      id: this.id,
      name: this.userPerson?.previewName,
    };
  }

  get firstContact() {
    return this.contacts[0];
  }

  constructor(data?: EmployeeSearchItem) {
    data ? Object.assign(this, data) : null;
    this.userPerson = new UserPerson(this.userPerson);
    this.userPerson.helperID = this.id;
    this.contacts = data?.contacts?.map((i) => new Contact(i)) || [];
  }
}
