<div>
  <div class="project-question-directory__box mb-3">
    <div class="project-question-directory__box__title">1. {{ 'Projects.defaultData' | translate }}</div>
    <div class="project-question-directory__box__data row mb-4">
      <div class="col-12 mb-2">
        <div class="row">
          <div class="col-md-6 mb-2 mb-md-0">
            <div class="project-question-directory__box__data__item">
              <div class="project-question-directory__box__data__item__title">
                {{ 'Projects.projectType' | translate }}
              </div>
              <div class="project-question-directory__box__data__item__text">
                {{ 'Projects.apartment-' + project?.basicDataBox?.propertyType | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-2 mb-md-0">
            <div class="project-question-directory__box__data__item">
              <div class="project-question-directory__box__data__item__title">
                {{ 'Projects.buildYear' | translate }}
              </div>
              <div class="project-question-directory__box__data__item__text">
                {{
                  project?.basicDataBox?.propertyAge
                    ? project?.basicDataBox?.propertyAge
                    : ('Projects.projectNoInfo' | translate)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- column -->
      <div class="col-md-6 mb-2 mb-md-0">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.projectName' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">{{ project?.name }}</div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.buildAddress' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{ project?.fullAddress ? project?.fullAddress : ('Projects.projectNoInfo' | translate) }}
          </div>
        </div>
      </div>
      <!-- column -->
      <div class="col-md-6">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.typeHeating' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.heatingType
                ? project?.basicDataBox?.heatingType
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.typeElectricity' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.electricityType
                ? project?.basicDataBox?.electricityType
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.typeOwned' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.ownershipType
                ? project?.basicDataBox?.ownershipType
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
      </div>
      <!-- column -->
    </div>
    <div class="project-question-directory__box__title">{{ 'Projects.otherBuildInfo' | translate }}</div>
    <div class="project-question-directory__box__data row">
      <!-- column -->
      <div class="col-md-6 mb-2 mb-md-0">
        <!-- #region -->
        <div *ngIf="!isWholeBuildingUsage" class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.whichFloor' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.floor
                ? project?.basicDataBox?.floor
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <!-- #endregion -->

        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.addFloorCount' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.floorsCount
                ? project?.basicDataBox?.floorsCount
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.parking' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{ additionalProperties?.hasParking?.title }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.underground' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{ additionalProperties?.hasBasement?.title }}
          </div>
        </div>
      </div>
      <!-- column -->
      <div class="col-md-6">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.lastnameOnRing' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.nameOnDoorbell
                ? project?.basicDataBox?.nameOnDoorbell
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.meters' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.area
                ? project?.basicDataBox?.area
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.elevator' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{ additionalProperties?.hasElevator?.title }}
          </div>
        </div>
      </div>
      <!-- column -->
    </div>
  </div>
  <div class="project-question-directory__box">
    <div class="project-question-directory__box__title">2. {{ 'Projects.termsAndBudget' | translate }}</div>
    <div class="project-question-directory__box__data row mb-2">
      <!-- column -->
      <div class="col-md-6 mb-2 mb-md-0">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.plannedRealizationTime' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.fullTerm
                ? project?.basicDataBox?.fullTerm
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
      </div>
      <!-- column -->
      <div class="col-md-6">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.budgetText' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text">
            {{
              project?.basicDataBox?.budget
                ? project?.basicDataBox?.budget
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
      </div>
      <!-- column -->
    </div>
    <div class="project-question-directory__box__data row">
      <div class="col-12">
        <div class="project-question-directory__box__data__item">
          <div class="project-question-directory__box__data__item__title">
            {{ 'Projects.additionalMarks' | translate }}
          </div>
          <div class="project-question-directory__box__data__item__text no-wrap">
            {{
              project?.basicDataBox?.clientComment
                ? project?.basicDataBox?.clientComment
                : ('Projects.projectNoInfo' | translate)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
