import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectChangesListComponent } from '@project-modules/project-changes/pages/project-changes-list/project-changes-list.component';

export const ProjectChangeRoutes: CustomRoutes = [
  {
    path: '',
    name: 'project-changes',
    component: ProjectChangesListComponent,
  },
];
