import { Config } from '@shared/configs/config';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProtocolAction } from '@modules/protocols/shared/enums/protocol-action.enum';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatPipe } from '@shared/pipes/dateFormat.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Task } from '@shared/models/task.model';
import * as moment from 'moment';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { TaskController } from '@shared/controllers/task.controller';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { CheckPermission } from '@core/permissions/check-permission';
import { TaskStatusList } from '@modules/protocols/shared/consts/task-status.list';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { Attachment } from '@shared/interfaces/attachment.interface';

@Component({
  selector: 'task-modal-content-preview',
  templateUrl: './task-modal-content-preview.component.html',
  styleUrls: ['./task-modal-content-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskModalContentPreviewComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;

  hiddenFromClients: FormControl = new FormControl();
  status: FormControl = new FormControl();
  editing: boolean = false;
  taskController: TaskController;

  objectCreatorIds: number[] = [];

  @Input() preview: boolean = false;
  @Input() snackBottom: boolean = true;
  @Input() hiddenForClientShow: boolean = true;
  @Output() taskChanged: EventEmitter<Task> = new EventEmitter();

  taskStatusList: Array<any> = TaskStatusList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  _task: Task;
  @Input('task')
  set task(task: Task) {
    this._task = new Task(task);
    this.setObjectCreatorIds();
    this.changes.detectChanges();
  }

  get task() {
    return this._task;
  }

  get assignedUsers() {
    const assignedUsers = this.task?.assignedTo
      .map((i) => `<span class="person">${i?.userPerson?.previewName}</span>`)
      .join('<br>');
    const assignedEmails = this.task?.assignedEmails
      ?.map((email) => `<span class="person">${email}</span>`)
      .join('<br>');
    return assignedUsers + assignedEmails;
  }

  setObjectCreatorIds() {
    if (!this.task) {
      this.objectCreatorIds = [];
      return;
    }
    const ids = this.task.assignedTo.map((u: EmployeeSearchItem) => u?.id);
    this.objectCreatorIds = [this.task?.creator?.id].concat(ids);
  }

  get realizationTerm() {
    if (this.task?.termStart && this.task?.termEnd) {
      const start = moment(this.task.termStart, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS);
      const end = moment(this.task.termEnd, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS);

      if (start === end) {
        return this.dateFormat.transform(this.task.termEnd);
      }
      return `${this.dateFormat.transform(this.task.termStart)} - ${this.dateFormat.transform(
        this.task.termEnd,
      )}`;
    }

    return null;
  }

  get patchData() {
    return {
      status: this.status.value,
      hiddenForClient: this.hiddenFromClients.value,
    };
  }

  constructor(
    public dialog: MatDialog,
    private dateFormat: DateFormatPipe,
    private t: TranslateService,
    private pService: ProtocolApiService,
    public changes: ChangeDetectorRef,
    private s: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.setFormData();
  }

  trackAttachment(index: number, attachment: Attachment) {
    return attachment?.id;
  }

  setFormData() {
    this.hiddenFromClients.setValue(!!this.task?.hiddenForClient);
    this.status.setValue(this.task?.status);
    if (this.task) {
      this.taskController = new TaskController(this.task);
      this.hiddenFromClients.valueChanges.subscribe(() => (!this.editing ? this.patchTask() : null));
      !this.onPatchTaskPermissionCheck() ? this.status.disable() : this.status.enable();
    }
  }

  openEditModal() {
    const refDialog = this.taskController.edit();
    if (refDialog) {
      const instance: TaskModalComponent = refDialog.componentInstance;
      refDialog.beforeClosed().subscribe(() => {
        this.editing = true;
        this.task = instance.task;
        this.taskChanged.emit(this.task);
        this.setObjectCreatorIds();
        this.setFormData();
        this.editing = false;
        this.changes.detectChanges();
      });
    }
  }

  changeStatus($event) {
    this.patchTask();
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self.objectCreatorIds,
  })
  patchTask() {
    this.pService.patchTask(this.patchData, this.task.id).subscribe({
      next: this.successPatchTask.bind(this),
      error: this.errorPatchTask.bind(this),
    });
  }

  onPatchTaskPermissionCheck(event?: MouseEvent) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.TASKS,
      action: 'EDIT',
      objectCreatorId: this.objectCreatorIds,
    });

    if (!ctrl.check()) {
      event ? event.preventDefault() : null;
      return false;
    }
    return true;
  }

  successPatchTask(task: Task) {
    this.task = new Task(task);
    this.taskChanged.emit(this.task);
    this.pService.manager.protocolAction.emit({ type: ProtocolAction.EDIT_TASK_CUSTOM, data: this.task });
    this.s.success(
      this.t.instant('Protocols.recordUpdated'),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.changes.detectChanges();
  }

  errorPatchTask() {
    this.s.error(this.t.instant('Protocols.errorUpdating'));
  }
}
