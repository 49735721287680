import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

const ZOOM_STEP: number = 0.25;
const DEFAULT_ZOOM: number = 1;

@Component({
  selector: 'custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.scss'],
})
export class CustomPdfViewerComponent implements OnInit, AfterViewInit {
  @Input() content: any;
  @Input() renderText: boolean = true;
  @Input() stickToPage: boolean = true;
  @Input() fitToPage: boolean = true;
  @Input() autoresize: boolean = true;

  @ViewChild('pdf') pdf: PdfViewerComponent;

  public zoom: number = DEFAULT_ZOOM;
  loaded: boolean = false;

  constructor() {}

  ngOnInit() {}

  public zoomIn() {
    this.zoom += ZOOM_STEP;
  }

  public zoomOut() {
    if (this.zoom > DEFAULT_ZOOM) {
      this.zoom -= ZOOM_STEP;
    }
  }

  public resetZoom() {
    this.zoom = DEFAULT_ZOOM;
  }

  ngAfterViewInit() {
    console.log(this.pdf);
  }

  afterLoadComplete() {
    this.loaded = true;
  }
}
