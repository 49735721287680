export enum BaseErrors {
  CREDENTIALS = 'Invalid credentials.',
  DISABLED = 'Account is disabled.',
  EXPIRED = 'Expired JWT Token',
  NOT_FOUND_JWT = 'JWT Token not found',
  DENIED = 'Access denied.',
  REMOVED = 'Account removed',
  ALREADY_CONFIRMED = 'Account already confirmed',
  EMPLOYEE_BLOCKED = 'Employee account blocked',
}
