export enum EProjectCreatorFirstStepForm {
  propertyType = 'propertyType',
  propertyTypeName = 'propertyTypeName',
  address = 'address',
  town = 'town',
  postalCode = 'postalCode',
  area = 'area',
  floorsCount = 'floorsCount',
  floor = 'floor',
  heatingType = 'heatingType',
  energySource = 'energySource',
  propertyAge = 'propertyAge',
  lastRenovationYear = 'lastRenovationYear',
  lastRenovationNote = 'lastRenovationNote',
  projectGoal = 'projectGoal',
  materials = 'materials',
  hasElevator = 'hasElevator',
  elevatorDescription = 'elevatorDescription',
  parkingSituation = 'parkingSituation',
  parkingDescription = 'parkingDescription',
  nameOnDoorbell = 'nameOnDoorbell',
  adressUnknown = 'adressUnknown',

  name = 'name',
  responsibleEmployeeId = 'responsibleEmployeeId',
}
