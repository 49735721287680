import { DeviceHelper } from '@shared/helpers/device.helper';
import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  EventEmitter,
  isDevMode,
  HostListener,
} from '@angular/core';
import {
  SidenavService,
  SidenavEvent,
  SidenavEventType,
  SidenavSide,
} from '@shared/services/sidenav/sidenav.service';
import { Subscription, timer } from 'rxjs';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { ActivatedRoute, Router } from '@angular/router';
import { SidenavRouteComponents } from './sidenav-route-components';
import { SidenavLoadingComponent } from './components/sidenav-loading/sidenav-loading.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenavContent', { read: ViewContainerRef }) sidenavContent: ViewContainerRef;
  @ViewChild('sidenavEl') sidenavEl: ElementRef;
  @ViewChild('sidenavPopup') sidenavPopup: ElementRef;

  static MODAL_PARAM = 'modal';
  static BACKDROP_PARAM = 'backdrop';
  static DATA_PARAM = 'modalData';
  static DATA_CONFIG = 'config';

  DeviceHelper = DeviceHelper;

  backdrop: boolean = true;
  openedComponent = null;
  lastOpenedComponent = null;
  openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  side: SidenavSide = SidenavSide.RIGHT;
  maxWidth: string = null;

  sub: Subscription;
  openedSub: Subscription;
  paramsSub: Subscription;
  routeChangeSub = new Subscription();
  opened: boolean = false;
  class: string;
  hideBackdrop: boolean = false;

  constructor(
    private resolver: ComponentFactoryResolver,
    public sidenav: SidenavService,
    public sidenavData: SIDENAV_DATA,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initListener();
    this.initRouteOpen();
  }

  initListener() {
    this.sub = this.sidenav.emitter.subscribe((event: SidenavEvent) => {
      this.class = event.config?.class || '';
      this.hideBackdrop = event.config?.hideBackdrop || false;

      switch (event.type) {
        case SidenavEventType.OPEN:
          if (this.opened && event.config.side !== this.side) {
            this.closeSidenav(() => {
              this.openSidenav(event);
            });
          } else {
            this.openSidenav(event);
          }
          break;
        case SidenavEventType.CLOSE:
          this.closeSidenav();
          break;
      }
    });
  }

  initRouteOpen() {
    this.paramsSub = this.route.queryParams.subscribe((params) => {
      if (params[SidenavComponent.MODAL_PARAM]) {
        this.openSidenavFromRoute(
          params[SidenavComponent.MODAL_PARAM],
          params[SidenavComponent.BACKDROP_PARAM],
          params[SidenavComponent.DATA_PARAM],
          params[SidenavComponent.DATA_CONFIG],
        );
      }
    });
  }

  @HostListener('window:popstate')
  onPopState() {
    if (this.opened) {
      if (!this.dialog.openDialogs?.length) {
        this.sidenav.close();
      }
    }
  }

  openSidenavFromRoute(componentName: string, backdrop?, data?: any, _config?: any) {
    backdrop = backdrop === 'true';

    if (SidenavRouteComponents[componentName]) {
      data = data ? JSON.parse(data) : {};
      _config = _config ? JSON.parse(_config) : {};

      let config = {
        closeOnBackdrop: backdrop,
        side: this.side,
        maxWidth: null,
      };
      _config ? (config = Object.assign(config, _config)) : null;
      const event: SidenavEvent = {
        component: SidenavRouteComponents[componentName],
        config,
        data,
        type: SidenavEventType.OPEN,
      };
      //Open component only from external source
      // this.openSidenav(event)
      !this.opened ? this.openSidenav(event) : null;
    } else {
      if (isDevMode()) {
        console.warn('Did you forget to add componentName variable on component to open class?');
      }
    }
  }

  openSidenav(event: SidenavEvent) {
    this.opened = true;
    this.maxWidth = event.config.maxWidth;
    this.side = event.config.side;
    event.config?.class ? (this.class = event.config?.class) : null;

    setTimeout(() => {
      if (!event?.config?.hardReload) {
        if (this.lastOpenedComponent) {
          if (this.lastOpenedComponent.componentName === event.component['componentName']) {
            // check if current component is the same as new one
            return;
          }
        }
      }

      this.lastOpenedComponent = event.component;
      this.setLoadingComponent();
      this.backdrop = event.config.closeOnBackdrop;
      this.animOpenSidenav();
      this.updateRouteParams(event);
      this.openedSub ? this.openedSub.unsubscribe() : null;
      this.openedSub = this.openedChange.subscribe((e: boolean) => {
        e ? this.onOpenSidenav(event) : this.onCloseSidenav();
      });
    }, 20);
  }

  closeSidenav(callback?: Function) {
    if (!this.opened) {
      return;
    }
    this.clearComponent();
    this.animCloseSidenav(callback);
  }

  createComponent(event: SidenavEvent) {
    this.sidenavData.data = event.data;
    this.sidenavContent.clear();
    const factory = this.resolver.resolveComponentFactory(event.component);
    this.sidenavContent.createComponent(factory);
  }

  setLoadingComponent() {
    this.sidenavContent?.clear();
    const factory = this.resolver.resolveComponentFactory(SidenavLoadingComponent);
    this.sidenavContent?.createComponent(factory);
  }

  toggle(event: SidenavEvent) {
    timer(200).subscribe(() => this.createComponent(event));
  }

  updateRouteParams(event: SidenavEvent) {
    let exist: string = '';

    for (const key in SidenavRouteComponents) {
      const component = SidenavRouteComponents[key];
      if (event.component.componentName && event.component.componentName === component.componentName) {
        exist = key;
      }
    }
    if (exist !== '') {
      const queryParams = {
        [SidenavComponent.MODAL_PARAM]: exist,
        [SidenavComponent.BACKDROP_PARAM]: event.config.closeOnBackdrop,
        [SidenavComponent.DATA_PARAM]: JSON.stringify(event.data),
        [SidenavComponent.DATA_CONFIG]: JSON.stringify(event.config),
      };

      const replaceUrl = event?.data?.replaceUrl ? event.data.replaceUrl : false;
      this.router.navigate([], { queryParams, queryParamsHandling: 'merge', replaceUrl });
    }
  }

  clearRouteParams() {
    const queryParams = {
      [SidenavComponent.MODAL_PARAM]: null,
      [SidenavComponent.BACKDROP_PARAM]: null,
      [SidenavComponent.DATA_PARAM]: null,
      [SidenavComponent.DATA_CONFIG]: null,
    };
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      replaceUrl: true,
    });
  }

  onOpenSidenav(event: SidenavEvent) {
    this.openedComponent ? this.toggle(event) : this.createComponent(event);
    this.openedComponent = event.component;
  }

  onCloseSidenav() {
    this.openedComponent = null;
    this.lastOpenedComponent = null;
    this.sidenavData.data = {};
    this.openedSub.unsubscribe();
    this.clearComponent();
    this.clearRouteParams();
  }

  clearComponent() {
    this.sidenavContent ? this.sidenavContent.clear() : null;
  }

  animOpenSidenav() {
    const popup: HTMLElement = this.sidenavPopup?.nativeElement;
    this.sidenavEl?.nativeElement.classList.remove('hide');
    popup?.classList.remove('out');

    if (this.openedComponent) {
      setTimeout(() => this.openedChange.emit(true), 100);
      return;
    }

    const onOpenAnimation = () => {
      this.openedChange.emit(true);
      this.removeEventListeners(popup, onOpenAnimation);
    };
    this.addEventListeners(popup, onOpenAnimation);
  }

  animCloseSidenav(callback?: Function) {
    const popup = this.sidenavPopup?.nativeElement;

    popup?.classList.add('out');
    const onCloseAnimation = () => {
      this.sidenavEl.nativeElement.classList.add('hide');
      this.openedChange.emit(false);
      this.opened = false;
      this.removeEventListeners(popup, onCloseAnimation);
      callback ? callback() : null;
    };
    this.addEventListeners(popup, onCloseAnimation);
  }

  removeEventListeners(popup: HTMLElement, callback: () => void) {
    popup?.removeEventListener('webkitTransitionEnd', callback);
    popup?.removeEventListener('transitionend', callback);
    popup?.removeEventListener('oTransitionEnd', callback);
  }

  addEventListeners(popup: HTMLElement, callback: () => void) {
    popup?.addEventListener('webkitTransitionEnd', callback);
    popup?.addEventListener('transitionend', callback);
    popup?.addEventListener('oTransitionEnd', callback);
  }

  restoreAnimClass() {
    this.sidenavPopup.nativeElement.classList.add('out');
    this.sidenavEl.nativeElement.classList.add('hide');
  }

  closeBackdrop() {
    if (this.backdrop) {
      !!this.sidenav.backdropFuncion ? this.sidenav.backdropFuncion() : this.sidenav.close();
    }
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
    this.openedSub ? this.openedSub.unsubscribe() : null;
    this.paramsSub ? this.paramsSub.unsubscribe() : null;
    this.routeChangeSub.unsubscribe();
  }
}
