import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IUnsavedModalData {
  title: string;
  text: string;
  saveUnload: string;
  unload: string;
  cancel: string;
}

export enum IUnsavedStates {
  DISCARD,
  SAVE_UNLOAD,
  UNLOAD,
}

@Component({
  selector: 'app-changes-unsaved-modal',
  templateUrl: './changes-unsaved-modal.component.html',
  styleUrls: ['./changes-unsaved-modal.component.scss'],
})
export class ChangesUnsavedModalComponent implements OnInit {
  IUnsavedStates = IUnsavedStates;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IUnsavedModalData) {
    !this.data.saveUnload ? (this.data.saveUnload = 'saveUnload') : null;
    !this.data.unload ? (this.data.unload = 'unload') : null;
    !this.data.cancel ? (this.data.cancel = 'cancel') : null;
  }

  ngOnInit(): void {}
}
