import { PermissionsConfig } from '@core/permissions/interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { PermissionType } from '@core/permissions/enums/permission-type.enum';

export class ProjectTeamPermissionsConfig implements PermissionsConfig {

  static readonly ADD: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly HOURLY: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.PROJECT_TEAM;
}
