import { Component, Input } from '@angular/core';

@Component({
  selector: 'summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.scss'],
})
export class SummaryListComponent {
  @Input() items: any;

  constructor() {}
}
