import { filter } from 'rxjs/operators';
import { Config } from '@shared/configs/config';
import { Task } from '@shared/models/task.model';
import { EventCycle } from '@shared/modules/event-sidenav/enums/event-cycle.enum';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import * as moment from 'moment';
import { EMyEventType } from '../enums/my-event-type.enum';
import { MyWeekEvent } from './my-week-event.model';
import { Moment } from 'moment';

export class CalendarEventsList {
  events: CalendarEvent[] = [];
  tasks: Task[] = [];

  list: MyWeekEvent[] = [];
  range: { startDate?: string; endDate?: string } = {};

  constructor(list?: CalendarEventsList, range?: { startDate: string; endDate: string }) {
    list ? this.deserialize(list) : null;
    range ? (this.range = Object.assign(this.range, range)) : null;
    this.setEventsList();
  }

  deserialize(list: CalendarEventsList) {
    Object.assign(this, list);
    this.setEvents();
    this.setTasks();
  }

  setEventsList() {
    if (!this.range) return;
    this.initializeFilledGroups();
    this.sortEventsByStartTerm();
    this.deleteElementsNotInRange();
    this.setListDivider();
  }

  setListDivider() {
    let lastDayDate: string = null;

    this.list.forEach((item: MyWeekEvent) => {
      let currentDate = moment(item.termStart, Config.DATE_SERVER).format(Config.DATE_SERVER_YYMMDD);
      if (
        !lastDayDate ||
        (!!lastDayDate && moment(currentDate, Config.DATE_SERVER_YYMMDD).isAfter(lastDayDate))
      ) {
        item.isDivider = true;
      }

      lastDayDate = currentDate;
    });
  }

  deleteElementsNotInRange() {
    this.list = this.list.filter((item: MyWeekEvent, index: number) => {
      return (
        moment(item.termStart, Config.DATE_SERVER).isSameOrAfter(this.range.startDate) &&
        moment(item.termStart, Config.DATE_SERVER).isSameOrBefore(this.range.endDate)
      );
    });
  }

  initializeFilledGroups() {
    this.list.forEach((item: MyWeekEvent, index: number) => {
      this.list[index] = !item.cycleType ? this.list[index] : this.initializeCycleTypeList(this.list[index]);
    });
  }

  initializeCycleTypeList(item: MyWeekEvent) {
    switch (item.cycleType) {
      case EventCycle.CYCLE_EVERY_YEAR:
        return this.initializeCycleType('year', 0, item);
      case EventCycle.CYCLE_EVERY_SECOND_WEEK:
        return this.initializeCycleType('week', 2, item);
      case EventCycle.CYCLE_EVERY_THIRD_MONTH:
        return this.initializeCycleType('month', 3, item);
      case EventCycle.CYCLE_EVERY_MONTH:
        return this.initializeCycleType('month', 0, item);
      case EventCycle.CYCLE_EVERY_WEEK:
        return this.initializeCycleType('week', 0, item);
      case EventCycle.CYCLE_EVERY_DAY:
        return this.initializeCycleType('day', 0, item);
      default:
        break;
    }
  }

  initializeCycleType(type: any, interval: number = 0, item: MyWeekEvent) {
    return type === 'day'
      ? this.initilizeNextDays(type, item)
      : this.initializeCycleInWeek(type, interval, item);
  }

  initializeCycleInWeek(type: any, interval: number = 0, item: MyWeekEvent) {
    const termStart = moment(item.termStart, Config.DATE_SERVER);
    const termEnd = moment(item.termEnd, Config.DATE_SERVER);

    const todayDate = moment(new Date(), Config.DATE_SERVER);
    const diff = termStart.diff(todayDate, type, false);

    item.termStart = termStart.add(Math.abs(diff + interval), type).format(Config.DATE_SERVER);
    item.termEnd = termEnd.add(Math.abs(diff + interval), type).format(Config.DATE_SERVER);

    return item;
  }

  initilizeNextDays(type: any, item: MyWeekEvent) {
    const start = moment(this.range.startDate, Config.DATE_SERVER);
    const end = moment(this.range.endDate, Config.DATE_SERVER);

    const startItem = moment(item.termStart, Config.DATE_SERVER);

    const dateStart = startItem.isBefore(start) ? start : startItem;
    const daysDiff = end.diff(dateStart, type, false);

    item.termStart = dateStart.format(Config.DATE_SERVER);

    for (let i = 0; i < daysDiff; i++) {
      const j = new CalendarEvent(item.extra);
      let _item = new MyWeekEvent(this.getConfig(j, j.name, EMyEventType.EVENT));
      _item.termStart = dateStart.add(1, type).format(Config.DATE_SERVER);
      this.list.push(_item);
    }

    return item;
  }

  sortEventsByStartTerm() {
    this.list.sort(
      (a, b) =>
        moment(a.termStart, Config.DATE_SERVER).unix() - moment(b.termStart, Config.DATE_SERVER).unix(),
    );
  }

  getConfig(j: any, name: string, type: EMyEventType) {
    return {
      id: j.id,
      termStart: j.termStart,
      termEnd: j.termEnd,
      name,
      type,
      cycleType: j?.cycleType || null,
      description: j?.project?.fullName || null,
      extra: j,
    } as any;
  }

  setEvents() {
    this.events =
      this.events?.map((i) => {
        const j = new CalendarEvent(i);
        this.list.push(new MyWeekEvent(this.getConfig(j, j.name, EMyEventType.EVENT)));
        return j;
      }) || [];
  }

  setTasks() {
    this.tasks =
      this.tasks?.map((i) => {
        const j = new Task(i);
        this.list.push(new MyWeekEvent(this.getConfig(j, j.description, EMyEventType.TASK)));
        return j;
      }) || [];
  }
}
