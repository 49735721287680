<modal-title
  [title]="'Projects.PreviewHeader.stageLostModalTitle' | translate"></modal-title>

<form [formGroup]="form">
  <mat-dialog-content>
    <div class="mt-3">
      <ng-select
        ngSelectExtension
        class="select select-default w-100 ng-select--required"
        [dropdownPosition]="'bottom'"
        [items]="stageLostReason"
        [bindValue]="'id'"
        [searchable]="false"
        [addTag]="false"
        [bindLabel]="'text'"
        [placeholder]="'Projects.PreviewHeader.chooseStageLostReason' | translate"
        [tabIndex]="-1"
        [addTagText]="'Protocols.add' | translate"
        formControlName="reason">
      </ng-select>
      <span
        *ngIf="form?.get('reason')?.touched && !form?.get('reason')?.value"
        class="d-flex form-control-error-msg mt-2"
        [innerHTML]="'Projects.PreviewHeader.reasonIsRequired' | translate"
      ></span>
    </div>
    <div class="mt-3">
      <!-- #region Content -->
      <gbxsoft-textarea
        class="textarea"
        [errMessages]="errorMessages('failureNote')"
        formControlName="failureNote"
        [config]="{ name: ' ', placeholder: 'Projects.PreviewHeader.chooseStageLostReasonDescr' | translate }"
      >
      </gbxsoft-textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">
      <!-- #endregion -->
      <app-button
        [disabled]="loading"
        (onClick)="cancel();"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Projects.PreviewHeader.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Projects.PreviewHeader.save' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-save.svg'
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
