import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { ActivatedRoute } from '@angular/router';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ContactManageFormComponent } from '@modules/contacts/shared/components/contact-manage-form/contact-manage-form.component';
import { BaseComponent } from '@shared/components/base.component';
import { CONTACT_FORM } from '@modules/contacts/shared/consts/contact-form.const';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { countries } from '@shared/datas/countries';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { HttpError } from '@shared/interfaces/error.interface';

@Component({
  selector: 'contact-manage',
  templateUrl: './contact-manage.component.html',
  styleUrls: ['./contact-manage.component.scss'],
})
export class ContactManageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  tabLinks: TabLink[] = [];
  contactType: ContactType = ContactType.PERSONAL;
  loading: boolean = false;
  isEdit: boolean = false;
  isInited: boolean = false;

  hasChanges: boolean = false;
  mainContactId: number;
  isContactPerson: boolean = false;
  assignToProject: number;
  backToProject: number;

  valueChangesSub: Subscription;
  subSaving: Subscription;

  @ViewChild('contactManageFormComponent') contactManageFormComponent: ContactManageFormComponent;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (this.hasChanges) {
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private footerService: StickyFooterService,
    private route: ActivatedRoute,
    private service: ContactService,
    private projectApiService: ProjectAPIService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkQueryParams();
    this.listenRouteParams();
    this.subscribeSaving();
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParams['firstName']) {
      this.contactManageFormComponent.form
        .get('firstName')
        .setValue(this.route.snapshot.queryParams['firstName']);
    }
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submit();
          break;
        default:
          break;
      }
    });
  }

  listenRouteParams() {
    this.route.params.subscribe((params) => {
      if (params['id'] && this.route.snapshot.data['edit']) {
        this.setEditState(Number(params['id']));
      } else {
        this.isInited = true;
        this.setType(params['type']);
        this.initTabLinks();
        this.waitForManageFormComponent();
      }
    });
  }

  listenFormChanges() {
    if (!this.contactManageFormComponent?.form) {
      return;
    }
    this.valueChangesSub = this.contactManageFormComponent.form.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe((valuesArray) => {
        const newVal = JSON.stringify(valuesArray[1]);
        const oldVal = JSON.stringify(valuesArray[0]);
        if (
          isNotNullOrUndefined(valuesArray[1]) &&
          isNotNullOrUndefined(valuesArray[0]) &&
          newVal !== oldVal
        ) {
          this.hasChanges = true;
        }
      });
  }

  listenTypeChange() {
    this.contactManageFormComponent.form
      .get(CONTACT_FORM.type)
      .valueChanges.subscribe((type: ContactType) => {
        this.setType(type);
        this.initTabLinks();
      });
  }

  checkQueryParams() {
    if (this.route.snapshot.queryParams['mainContact']) {
      this.mainContactId = Number(this.route.snapshot.queryParams['mainContact']);
    }
    if (Number(this.route.snapshot.queryParams['contactPerson']) === 1) {
      this.isContactPerson = true;
    }
    if (this.route.snapshot.queryParams['assignToProject']) {
      this.assignToProject = Number(this.route.snapshot.queryParams['assignToProject']);
    }
    if (this.route.snapshot.queryParams['backToProject']) {
      this.backToProject = Number(this.route.snapshot.queryParams['backToProject']);
    }
  }

  waitForManageFormComponent() {
    const interval = setInterval(() => {
      if (this.contactManageFormComponent) {
        clearInterval(interval);
        this.setMainContactInCreateMode();
        this.listenFormChanges();
      }
    }, 100);
  }

  setType(type) {
    if (type === ContactType.COMPANY) {
      this.contactType = ContactType.COMPANY;
    } else {
      this.contactType = ContactType.PERSONAL;
    }
  }

  private setMainContactInCreateMode() {
    if (this.mainContactId) {
      this.service.getContact(this.mainContactId).subscribe((c: Contact) => {
        this.contactManageFormComponent.form.get(CONTACT_FORM.parentsIds).setValue([c.id]);
      });
    }
  }

  initTabLinks(): void {
    let componentName = 'contact-create';
    let translationName = 'create';
    let params: any = {
      type: this.contactType,
    };
    if (this.isEdit) {
      componentName = 'contact-edit';
      translationName = 'edit';
      params = {
        id: this.contactManageFormComponent.form.get(CONTACT_FORM.id).value,
      };
    }
    this.tabLinks = [
      {
        link: this.n.getPath(componentName, {}, params),
        name: this.t.instant('Contacts.Manage.Menu.' + translationName),
      },
    ];
  }

  setEditState(id: number) {
    this.isEdit = true;
    this.service.getContact(id).subscribe({
      next: (res: Contact) => {
        if (this.isDeletedContact(res)) {
          return;
        }

        Object.keys(res).map((key) => {
          if (CONTACT_FORM[key]) {
            this.contactManageFormComponent.form.get(CONTACT_FORM[key]).setValue(res[key]);
          }
        });
        const functions = this.contactManageFormComponent.form.get(CONTACT_FORM.function).value;
        this.contactManageFormComponent.form
          .get(CONTACT_FORM.function)
          .setValue(functions.map((func) => func.name));

        this.contactManageFormComponent.form
          .get(CONTACT_FORM.parentsIds)
          .setValue(res.parentContacts.map((c) => c.id));

        this.contactManageFormComponent.contact = new Contact(res);
        this.isInited = true;
        this.contactType = this.contactManageFormComponent.form.get(CONTACT_FORM.type).value;
        this.setCountry(res.country);
        this.listenTypeChange();
        this.listenFormChanges();
      },
      error: (e) => {
        this.s.error(this.t.instant('Contacts.Manage.errorGettingContact'));
      },
    });
  }

  isDeletedContact(contact: Contact) {
    if (contact.deleted) {
      this.s.error(this.t.instant('Contacts.Preview.errorGettingContactDeleted'));
      this.n.go('contacts-list');
      return true;
    }
    return false;
  }

  setCountry(country: string) {
    const c = countries.filter((c) => c.id === country)[0];
    c && c !== undefined ? this.contactManageFormComponent.form.get(CONTACT_FORM.country).setValue(c) : null;
  }

  discard(): void {
    window.history.back();
  }

  submit(): void {
    this.contactManageFormComponent.markControlsAsTouched();
    this.contactManageFormComponent.contactBasicDataComponent.contactExistsInfoComponent.unsubscribeExistsInfo();

    if (this.contactManageFormComponent.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }

    this.loading = true;

    const value = this.contactManageFormComponent.getFormValue();

    if (this.isContactPerson) {
      value['isContactPerson'] = true;
    }

    if (this.isEdit) {
      this.editContact(value);
    } else {
      this.createContact(value);
    }
  }

  editContact(value) {
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.service
      .updateContact(value)
      .subscribe({
        next: (contact: Contact) => this.onSuccessSaveContact(contact),
        error: (e: HttpError) => {
          switch (e.messageCode) {
            case 'intilio_45':
              this.s.error(this.t.instant('Contacts.Manage.errorCircular'));
              break;
            default:
              this.s.error(this.t.instant('Contacts.Manage.errorCreatingContact'));
              break;
          }
        },
      })
      .add(() => {
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.loading = false;
      });
  }

  createContact(value) {
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.service
      .setContact(value)
      .subscribe({
        next: (contact: Contact) => this.onSuccessSaveContact(contact),
        error: (e) => {
          this.s.error(this.t.instant('Contacts.Manage.errorCreatingContact'));
        },
      })
      .add(() => {
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.loading = false;
      });
  }

  onSuccessSaveContact(contact: Contact) {
    this.hasChanges = false;
    if (!!this.route?.snapshot?.queryParams?.redirectURI) {
      this.router.navigate([this.route.snapshot.queryParams.redirectURI], { queryParams: { step: 4 } });
      return;
    }

    if (this.assignToProject) {
      return this.assignContactAsMainToProject(this.assignToProject, contact.id);
    }

    if (this.backToProject) {
      return this.n.go('project-preview-summary', { projectId: this.backToProject });
    }

    if (this.mainContactId) {
      //go back to the preview of contact for which is created contact person
      this.n.go('contact-preview', { id: this.mainContactId });
    } else {
      this.n.go('contact-preview', { id: contact.id });
    }

    if (this.isEdit) {
      this.s.success(this.t.instant('Contacts.Manage.successEditingContact'));
    } else {
      this.s.success(this.t.instant('Contacts.Manage.successCreatingContact'));
    }
  }

  private assignContactAsMainToProject(projectId: number, contactId: number) {
    this.projectApiService.assignMainContactToProject(projectId, contactId).subscribe(() => {
      this.n.go('project-preview-summary', { projectId });
    });
  }

  ngOnDestroy() {
    this.valueChangesSub ? this.valueChangesSub.unsubscribe() : null;
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
