import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ProtocolToggleMenuCellComponent } from '@modules/protocols/pages/protocols-list/components/protocol-toggle-menu-cell/protocol-toggle-menu-cell.component';

export const ProtocolsListConfig: ListConfig = {
  columns: [
    {
      prop: 'protocol.idNumber',
      name: 'Protocols.List.protocolNumber',
      componentRef: 'protocolNumber',
      sortField: 'a.idNumber',
      resizeable: false,
      hideOnMobile: false,
      width: 250,
      mobileWidth: 120,
      isTreeColumn: true,
      treeLevelIndent: 24,
      canNotShow: false,
      order: 1
    },
    {
      prop: 'protocol.externalClients',
      name: 'Protocols.List.client',
      componentRef: 'protocolClient',
      sortField: 'externalClient.name',
      resizeable: false,
      hideOnMobile: true,
      width: 210,
      order: 2
    },
    {
      prop: 'protocol.project.address',
      name: 'Protocols.List.projectAddress',
      mobileName: 'Protocols.List.project',
      sortField: 'project.address',
      resizeable: false,
      canAutoResize: false,
      componentRef: 'protocolAddress',
      width: 280,
      mobileWidth: 120,
      order: 3
    },
    {
      prop: 'protocol.project.name',
      name: 'Protocols.List.project',
      resizeable: false,
      sortField: 'project.name',
      canAutoResize: false,
      componentRef: 'protocolProjectName',
      hideOnMobile: true,
      width: 220,
      order: 4
    },
    {
      prop: 'protocol.type',
      name: 'Protocols.List.protocolType',
      resizeable: false,
      componentRef: 'protocolType',
      sortField: 'a.type',
      hideOnMobile: true,
      width: 150,
      order: 5
    },
    {
      prop: 'protocol.creator',
      name: 'Protocols.List.creator',
      resizeable: false,
      sortField: 'a.created',
      componentRef: 'protocolCreator',
      hideOnMobile: true,
      width: 150,
      order: 6
    },
    {
      prop: 'protocol.status',
      name: 'Protocols.List.status',
      sortField: 'a.status',
      resizeable: false,
      componentRef: 'protocolStatus',
      hideOnMobile: true,
      width: 175,
      order: 7
    },
    {
      prop: 'completedTaskCount',
      name: 'Protocols.List.stuffs',
      sortField: 'completedTaskCount',
      mobileName: 'Protocols.List.shortStuffs',
      resizeable: false,
      componentRef: 'protocolStuffs',
      width: 150,
      mobileWidth: 65,
      notShowInExpand: true,
      order: 8
    },
    {
      toggleMenu: true,
      component: ProtocolToggleMenuCellComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 30,
      sortable: false,
      order: 9
    }
  ],
  url: `${Config.API}/protocol`,
  scrollbarH: true,
  responseKey: 'records',
  listName: 'protocols-list',
  rowClass: 'cursor-pointer',
  selectionType: SelectionType.single,
  listTitle: 'Protocols.List.tabs.all',
  treeAction: {
    treeToRelation: 'protocol.id',
    treeFromRelation: 'acceptanceId'
  }
};
