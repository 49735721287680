export const downloadFile = (file: File | Blob, title: string) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = `${title}`;
  a.click();
};

export const downloadExcel = (name: string, data: any) => {
  const link = document.createElement('a');
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', name + '.xlsx');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
