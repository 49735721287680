<div class="expander"
     [ngClass]="{'expander--loading':loading, 'expander--disabled':disabled}"
     [tourAnchor]="tourAnchor"
     (click)="!disabled ? onClick.emit(true) : ''">
  <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
  <svg-icon
    [src]="'/assets/img/arrow-down.svg'"
    [svgStyle]="{ 'width.px': 9  }"
    [svgClass]="opened ? 'rotate-0' : ''"
  ></svg-icon>
</div>
