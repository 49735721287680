<main class="calendar">
  <action-title-header-v2
    [title]="'Header.calendar' | translate"
    [buttonText]="'Calendar.addNew' | translate"
    [doubleHeightOnMobile]="false"
    [stickyWithSideNavigation]="false"
    ><calendar-search></calendar-search>
    <div class="buttons__wrapper">
      <button-group-v2
        class="w-100 w-sm-auto"
        #viewTypesButtonComponent
        [config]="viewTypesButtonGroupConfig"
        (onClick)="onChangeViewType($event)"
      ></button-group-v2>

      <app-button
        (onClick)="service.settingsCtrl.settingsOpened = true"
        class="btn-settings"
        [ngClass]="{ 'btn-settings--active': service.settingsCtrl.settingsOpened }"
        [config]="{
          type: ButtonTypes.NEW_PRIMARY_BLUE,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          prependSrc: './assets/img/ic-settings.svg'
        }"
      >
      </app-button>
    </div>
    <ng-template #action>
      <app-button
        class="btn-add"
        [ngClass]="{ opened: openedMenu }"
        [matMenuTriggerFor]="menu"
        [config]="{
          type: ButtonTypes.NEW_PRIMARY_BLUE,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,

          text: WindowHelper.isLessXXL ? '' : ('Calendar.addNew' | translate),
          prependSrc: WindowHelper.isLessXXL ? './assets/img/ic-plus-add.svg' : '',
          appendSrc: WindowHelper.isLessXXL ? '' : './assets/img/select-arrow-icon.svg'
        }"
        (menuOpened)="handleOpen(true)"
        (menuClosed)="handleOpen(false)"
      >
      </app-button>

      <mat-menu #menu="matMenu" yPosition="below" class="menu-v2">
        <button
          [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
          (click)="addTask()"
          mat-menu-item
        >
          <span>{{ 'Header.CreateOptions.task' | translate }}</span>
        </button>
        <button
          [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
          (click)="addEvent()"
          mat-menu-item
        >
          <span>{{ 'CalendarEvents.event' | translate }}</span>
        </button>
      </mat-menu>
    </ng-template>
  </action-title-header-v2>
  <div class="calendar__wrapper">
    <calendar-left-section></calendar-left-section>
    <calendar-main-section></calendar-main-section>
  </div>
</main>
