<button
  mat-menu-item
  [permission]="{
      group: PermissionsGroups.CONTACTS,
      action: 'PREVIEW'
    }"
  (click)="preview()">
  <span>{{ 'ProjectContacts.List.preview' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.id !== projectPreviewService?.project?.MainContact?.id && row?.id !== projectPreviewService?.project?.basicDataBox?.responsibleContact?.id"
  [permission]="{
      group: PermissionsGroups.PROJECTS,
      action: 'EDIT',
      objectCreatorId: projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id
    }"
  (click)="markAsClient()">
  <span>{{ 'ProjectContacts.List.markAsClient' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.id !== projectPreviewService?.project?.basicDataBox?.responsibleContact?.id && row?.id !== projectPreviewService?.project?.MainContact?.id"
  [permission]="{
      group: PermissionsGroups.PROJECTS,
      action: 'EDIT',
      objectCreatorId: projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id
    }"
  (click)="markAsResponsibleClient()">
  <span>{{ 'ProjectContacts.List.markAsResponsibleClient' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="!row?.employee"
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'ADD'
  }"
  (click)="createAccount()">
  <span>{{ 'ProjectContacts.List.createAccount' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.employee || row?.email"
  [permission]="{
    group: PermissionsGroups.TASKS,
    action: 'ADD'
  }"
  (click)="createTask()">
  <span>{{ 'ProjectContacts.List.createTask' | translate }}</span>
</button>

<button
  [permission]="{
      group: PermissionsGroups.PROJECT_TEAM,
      action: 'REMOVE'
    }"
  *ngIf="!row?.deleted"
  mat-menu-item
  (click)="removeFromProject()"
  class="hover_red red-500">
  <span>{{ 'ProjectTeam.List.removeFromProject' | translate }}</span>
</button>
