<side-navigation-view
  [config]="config"
  (onBackClick)="back()"
  (onHiddenSideNavigation)="hiddenSideNavigation = $event"
>
  <div class="project-panel-preview">
    <project-panel-preview-header
      [hiddenSideNavigation]="hiddenSideNavigation"
    ></project-panel-preview-header>
    <div class="project-panel-preview__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</side-navigation-view>

<ng-template #headerTemplate>
  <div class="d-block w-100 px-2">
    <div class="d-inline-flex w-100">
      <div [matMenuTriggerFor]="stages" class="w-100">
        <app-button
          [class]="'bold w-100'"
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'EDIT',
            objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
          }"
          [config]="{
            type: ButtonTypes.LIGHT_BLUE,
            text: 'Projects.Stage.' + projectPreviewService?.project?.stage | translate,
            size: ButtonSize.SMALL,
            appendSrc: './assets/img/ic-arrow-down-blue.svg'
          }"
        >
        </app-button>
      </div>
      <mat-menu #stages="matMenu" xPosition="after">
        <ng-container *ngFor="let key of Object.keys(ProjectStage)">
          <button
            mat-menu-item
            (click)="onStageChange(ProjectStage[key])"
            [disabled]="projectPreviewService.project.stage === ProjectStage[key]"
            [ngClass]="{
              'hover_red red-500': ProjectStage[key] === ProjectStage.STAGE_LOST,
              'blue-500': projectPreviewService.project.stage === ProjectStage[key]
            }"
          >
            <span>{{ 'Projects.Stage.' + ProjectStage[key] | translate }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</ng-template>
