import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ListService } from '@shared/modules/list/services/list.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { ContactAddToSystemComponent } from '@shared/components/contact-add-to-system/contact-add-to-system.component';
import { Config } from '@shared/configs/config';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'project-contact-toggle-menu',
  templateUrl: './project-contact-toggle-menu.component.html',
  styleUrls: ['./project-contact-toggle-menu.component.scss']
})
export class ProjectContactToggleMenuComponent implements OnInit, ListCustomCell, OnDestroy {
  PermissionsGroups = PermissionsGroups;

  _row: Contact;
  sub: Subscription = new Subscription();

  set row(row: Contact) {
    this._row = new Contact(row);
  }

  get row() {
    return this._row;
  }

  @ViewChild('template') template;

  constructor(
    public projectPreviewService: ProjectPreviewService,
    private projectAPIService: ProjectAPIService,
    private listService: ListService,
    private s: SnackBarService,
    private t: TranslateService,
    private dialogRef: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  preview() {
    const ctrl = new ContactController(this.row);
    ctrl.preview(this.row.id);
  }

  markAsClient() {
    this.projectAPIService.assignMainContactToProject(this.projectPreviewService.project.id, this.row.id).subscribe((project: Project) => {
      this.listService.getRows();
      this.projectPreviewService.project = project;
      this.s.success(this.t.instant('ProjectContacts.List.contactMarkedAsClient'));
    });
  }

  markAsResponsibleClient() {
    this.projectAPIService.addContactToProject(this.row.id, this.projectPreviewService.project.id, true).subscribe((project: Project) => {
      this.listService.getRows();
      this.projectPreviewService.project = project;
      this.s.success(this.t.instant('ProjectContacts.List.contactMarkedAsResponsible'));
    });
  }

  createTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(this.projectPreviewService.project.id, null, null, null, !this.row.employee ? [this.row.email] : null, this.row.employee ? [this.row.employee.id] : null);
  }

  createAccount() {
    const dialog = this.dialogRef.open(ContactAddToSystemComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false
    });

    dialog.componentInstance.contact = this.row;
    this.sub = dialog.componentInstance.onAddContactToSystem.subscribe((contact: Contact) => {
      this.row = new Contact(contact);
      this.listService.getRows();
      this.sub.unsubscribe();
    });
  }

  removeFromProject() {
    const ctrl = new RemoveModalController();
    ctrl
    .remove(() => {
      const _ctrl = new ProjectController(this.projectPreviewService.project);
      _ctrl.removeContactFromProject(this.row.id, this.projectPreviewService.project.id).subscribe({
        next: () => {
          this.listService.getRows();
          this.projectPreviewService.updateProject();
          this.s.success(this.t.instant('ProjectContacts.List.contactRemovedFromProjects'));
        },
        error: () => {
          this.s.error(this.t.instant('ProjectContacts.List.errorContactRemovingFromProject'));
        }
      });
    })
    .subscribe(() => {});
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
