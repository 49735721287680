import { CanDeactivateProjectCreation } from '@modules/projects/shared/guards/canDeactivateProjectCreation.guard';
import { ProjectCreateGuard } from '@core/guards/projects/project-create.guard';
import { ProjectContactComponent } from '@modules/projects/modules/projects-create/pages/project-contact/project-contact.component';
import { ProjectPanelManageComponent } from '@modules/projects/modules/projects-create/pages/project-panel-manage/project-panel-manage.component';
import { ProjectQuestionDirectoryFirstComponent } from '@modules/projects/shared/components/project-question-directory-first/project-question-directory-first.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectCreateRoutes: CustomRoutes = [
  {
    path: ':projectid',
    canActivate: [ProjectCreateGuard],
    // canDeactivate: [CanDeactivateProjectCreation],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contact',
      },
      {
        path: 'configurator',
        name: 'project-contact',
        data: {
          metaTitle: 'Projects.projectConfigurator',
          notRestorationPosition: true,
          customHeaderStyles: true,
          create: true,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/projects-configurator/projects-configurator.module`).then(
            (m) => m.ProjectsConfiguratorModule,
          ),
      },
    ],
  },
];
