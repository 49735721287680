import * as _ from 'lodash';
import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { Injectable, EventEmitter } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { throwError, Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WeatherList } from '@shared/consts/weather';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { IProtocolsDraft } from '../interfaces/protocols-draft.interface';
import { Protocol } from '../models/protocol';
import { Task } from '@shared/models/task.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TaskType } from '@shared/enums/task-type.enum';
import { Invoice } from '@shared/interfaces/invoice.interface';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProtocolAction } from '../enums/protocol-action.enum';
import { ProtolActionEmitter } from '../interfaces/protocol-action-emitter.interface';
import { ProtocolSelectionType } from '../enums/protocol-selection-type.enum';
import { priceToPenny } from '@shared/helpers/price-to-penny.helper';
import { TaskListConfig } from '../consts/task-list.config';
import { ListItem } from '@shared/interfaces/list-item.interface';
import { TaskListItem } from '../interfaces/task-list-item.interface';
import { ProtocolsStorageService } from './protocols-storage.service';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { WindowHelper } from '@shared/helpers/window.helper';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { EntryProtocolType } from '../enums/entry-protocol-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ProtocolsManageService extends BaseHttpService {
  RouteSub: Subscription;
  TaskContainer: HTMLElement;
  Offset: number;
  $hideHiddenTasks = false;
  hideHiddenTasks: EventEmitter<boolean> = new EventEmitter();

  protocolAcceptanceType = ProtocolSelectionType;

  protocolAction: EventEmitter<ProtolActionEmitter> = new EventEmitter();
  protocol: Protocol = null;
  protocolType: ProtocolType = null;

  form: FormGroup = null;
  acceptanceForm: FormGroup = null;

  projects: Array<Project> = [];
  adresses: Array<Project> = [];
  clients: Array<Contact> = [];
  employees: Array<Contact> = [];
  weather: Array<any> = WeatherList.map((i) => {
    i.text = this.t.instant(`WEATHER.${i.name}`);
    return i;
  });

  employeesToAssign: Array<ListItem> = [];
  allEmployeesToAssign: Array<any> = [];

  taskList: Array<TaskListItem> = TaskListConfig.map((i) => {
    i.title = this.t.instant(i.title);
    return i;
  });

  ProtocolEntries: { [key: string]: Array<Entry> } = this.EmptyEntryList;

  get ProtocolDraft(): IProtocolsDraft {
    return {
      type: this.protocolType,
      projectId: this.ProjectID,
      externalCompaniesContactsIds: this.companyContacts,
      externalClientsIds: this.clientContacts,
      weather: this.Weather,
    };
  }

  get EmptyEntryList() {
    return _.fromPairs(Object.values(EntryProtocolType).map((i) => [i, []]));
  }

  get ProjectID() {
    return this.form.get('project')?.value ? this.form.get('project')?.value : null;
  }

  get Project(): Project {
    return this.protocol?.project;
  }

  get companyContacts() {
    const employee = this.form.get('employee')?.value;
    const selected = this.form.get('employee')?.value?.length;
    return selected
      ? this.employees.filter((company) => !!employee.filter((i) => i === company.id).length).map((j) => j.id)
      : [];
  }

  get clientContacts() {
    const _client = this.form.get('client')?.value;
    const selected = _client?.length;
    return selected
      ? this.clients?.filter((client) => !!_client.filter((i) => i === client.id).length).map((j) => j.id)
      : [];
  }

  get Weather() {
    const selected = this.form.get('weather')?.value;
    return selected
      ? this.weather.filter((i) => i.id === selected).map((j) => j.name.toLowerCase())[0]
      : null;
  }

  get ProtocolTexttype() {
    return this.protocol.type ? `${this.protocol.type}-protocol-data` : '';
  }

  constructor(private fb: FormBuilder, private pStore: ProtocolsStorageService) {
    super();
    this.createForm();
  }

  clearService() {
    this.pStore.NoteType = TaskType.TYPE_NOTE;
    this.protocol = null;
    this.protocolType = null;

    this.projects = [];
    this.adresses = [];
    this.clients = [];
    this.employees = [];
    this.employeesToAssign = [];
    this.allEmployeesToAssign = [];

    this.clearTaskLists();

    this.ProtocolEntries = this.EmptyEntryList;
    this.form.reset();
  }

  clearTaskLists() {
    this.taskList = this.taskList.map((i) => {
      i.items = [];
      return i;
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      project: null,
      address: null,
      id: null,
      client: null,
      employee: null,
      weather: null,
    });

    this.acceptanceForm = this.fb.group({
      type: ProtocolSelectionType.PARTIAL,
    });
  }

  successGetEmployees(emList: Array<EmployeeSearchItem>) {
    const formated = emList.map((e: EmployeeSearchItem) => new EmployeeSearchItem(e).ListItem);
    const concated = this.employeesToAssign.concat(formated);

    this.employeesToAssign = Array.from(new Set(concated.map((i) => i.id))).map((id) =>
      concated.find((a) => a.id === id),
    );
    return this.employeesToAssign;
  }

  //TODO remove any type
  successGetProtocolDraft(p: Protocol | any, assign: boolean = false) {
    if (assign) {
      this.protocol ? (this.protocol.status = p.status) : null;
    } else {
      if (this.protocol) {
        p = Object.assign(this.protocol, p);
      }
      this.setFullProtocol(p);
    }

    return this.protocol;
  }

  setProtocolsData() {
    this.form.get('id').setValue(this.protocol.project?.projectId);
    this.form
      .get('weather')
      .setValue(WeatherList.filter((i) => i.name.toLowerCase() === this.protocol?.weather)[0]?.id);
  }

  setProtocolsTasks() {
    this.clearTaskLists();
    this.protocol.tasks.forEach((task: Task) => {
      this.taskList = this.taskList.map((i: TaskListItem) => {
        if (i.type === task.type) {
          i.items = [...i.items, task];
          i.opened = true;
        }
        return i;
      });
    });
  }

  setEntryData() {
    this.ProtocolEntries = this.EmptyEntryList;

    if (!!this.protocol.acceptanceData) {
      this.protocol.acceptanceData?.entries.forEach((entry: Entry) =>
        this.ProtocolEntries[entry.type].push(new Entry(entry)),
      );
    }
    if (!!this.protocol.projectProtocolData) {
      this.protocol.projectProtocolData?.entries.forEach((entry: Entry) =>
        this.ProtocolEntries[entry.type].push(new Entry(entry)),
      );
    }

    if (this.protocol?.acceptanceData?.type) {
      this.acceptanceForm?.get('type').setValue(this.protocol?.acceptanceData?.type);
    }
  }

  setFullProtocol(p: Protocol) {
    this.protocol = new Protocol(p);
    this.setProtocolsData();
    this.setProtocolsTasks();
    this.setEntryData();
  }

  amountsToPenny(data: Invoice) {
    data.grossAmount ? (data.grossAmount = priceToPenny(data.grossAmount)) : null;
    data.netAmount ? (data.netAmount = priceToPenny(data.netAmount)) : null;
    data.paidAmount ? (data.paidAmount = priceToPenny(data.paidAmount)) : null;
    return data;
  }

  setProtocol(p: Protocol) {
    this.protocol = new Protocol(p);
    return p;
  }

  successAcceptanceDataUpdate(i) {
    this.ProtocolEntries[EntryType.TYPE_ACCEPTANCE_STAGE] = [];
    return i;
  }

  successAddEntry(entry: Entry) {
    const $entry = new Entry(entry);
    $entry.opened = true;
    this.ProtocolEntries[entry.type].push($entry);
    return entry;
  }

  successEditEntry(entry: Entry) {
    const findIndex = this.ProtocolEntries[entry.type].findIndex((i) => i.id === entry.id);
    const $entry = new Entry(entry);
    $entry.opened = true;
    findIndex > -1 ? (this.ProtocolEntries[entry.type][findIndex] = $entry) : null;
    return entry;
  }

  successDeleteEntry(entry: Entry) {
    this.ProtocolEntries[entry.type].forEach((e: Entry, i: number) => {
      e.id === entry.id ? this.ProtocolEntries[entry.type].splice(i, 1) : null;
    });
    return {};
  }

  successSaving(p: any) {
    this.endSave();
    if (this.TaskContainer && WindowHelper.isMoreLessMD) {
      this.TaskContainer.scrollTop = this.Offset;
      this.TaskContainer = null;
    }

    return p;
  }

  errorSaving(err: HttpErrorResponse) {
    this.protocolAction.emit({ type: ProtocolAction.END_SAVED_ERROR });
    return throwError(err);
  }

  startSave() {
    this.protocolAction.emit({ type: ProtocolAction.START_SAVING });
  }

  endSave() {
    this.protocolAction.emit({ type: ProtocolAction.END_SAVED_SUCCESS });
  }
}
