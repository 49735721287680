<button
  mat-menu-item
  [permission]="{
    group: PermissionsGroups.PROJECT_TEAM,
    action: 'EDIT',
    objectCreatorId: [projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id]
  }"
  (click)="editTeamMember()"
>
  <span>{{ 'ProjectTeam.List.manage' | translate }}</span>
</button>

<button
  *ngIf="!row?.isMainContact && !row?.isContactPerson && !row?.isResponsibleEmployee"
  mat-menu-item
  (click)="setResponsible()"
>
  <span>{{ 'ProjectTeam.List.setResponsiblePerson' | translate }}</span>
</button>

<button
  mat-menu-item
  [permission]="{
    group: PermissionsGroups.CALENDAR,
    action: 'PREVIEW'
  }"
  (click)="calendar()"
>
  <span>{{ 'ProjectTeam.List.calendar' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.employee?.contacts?.length"
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'PREVIEW'
  }"
  (click)="previewContact()"
>
  <span>{{ 'ProjectTeam.List.previewContact' | translate }}</span>
</button>

<button
  [permission]="{
    group: PermissionsGroups.PROJECT_TEAM,
    action: 'REMOVE',
    objectCreatorId: [projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id]
  }"
  *ngIf="!row?.deleted"
  mat-menu-item
  (click)="removeFromProject()"
  class="hover_red red-500"
>
  <span>{{ 'ProjectTeam.List.removeFromProject' | translate }}</span>
</button>
