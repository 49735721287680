import { publicFile } from '@shared/helpers/public-file.helper';
import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '@shared/models/employee.model';
import { EParticipantStatus } from '@shared/modules/event-sidenav/enums/participant-status.enum';
import { Participant } from '@shared/modules/event-sidenav/models/participant.model';
import { EStatusColor } from '@shared/modules/ui/components/status-cell/status-cell.component';

@Component({
  selector: 'event-participant-item',
  templateUrl: './event-participant-item.component.html',
  styleUrls: ['./event-participant-item.component.scss'],
})
export class EventParticipantItemComponent implements OnInit {
  @Input() participant: Participant;

  constructor() {}

  ngOnInit(): void {}

  imageUrl(employee: Employee) {
    return publicFile(employee?.userPerson?.photo, 40);
  }

  getColor(participant: Participant) {
    let color = EStatusColor.COLOR_DEFAULT;
    switch (participant.status) {
      case EParticipantStatus.STATUS_ATTENDANCE_CONFIRMED:
        color = EStatusColor.COLOR_SUCCESS;
        break;
      case EParticipantStatus.STATUS_ATTENDANCE_REJECTED:
        color = EStatusColor.COLOR_DANGER;
        break;
      default:
        color = EStatusColor.COLOR_DEFAULT;
        break;
    }

    return color;
  }
}
