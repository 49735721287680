<span
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="color pointer"
  [ngStyle]="{ background: color }"
  [ngClass]="{ 'color--disabled': disabled }"
></span>
<mat-menu #menu="matMenu" xPosition="before">
  <ul class="color-selection-wrapper">
    <li
      (click)="pickColor(icolor)"
      class="color-selection"
      [ngClass]="{ 'color-selection--active': icolor === color }"
      [ngStyle]="{ background: icolor }"
      *ngFor="let icolor of colors"
    ></li>
  </ul>
</mat-menu>
