<div class="mb-1 bold">{{'Contacts.Fields.contactSource'}}</div>
<div class="mb-4">
  <ng-select class="w-100 select select-default" [placeholder]="'Dodaj źródło'"></ng-select>
</div>
<app-button
  [class]="'px-4 py-2'"
  [config]="{
    prependSrc: '/assets/img/arrow-right.svg',
    type: ButtonTypes.PRIMARY_BLUE,
    size: ButtonSize.SMALL,
    text: 'Dodaj'
  }"
></app-button>
