import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'sign-up-header',
  templateUrl: './sign-up-header.component.html',
  styleUrls: ['./sign-up-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpHeaderComponent implements OnInit {
  Config = Config;
  ButtonTypes = ButtonTypes;

  constructor(public n: NavigateService) {}

  ngOnInit(): void {}
}
