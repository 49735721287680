import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Config } from '@shared/configs/config';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProjectCreatorManageResolver implements Resolve<any> {
  constructor(
    private api: ProjectCreatorApiService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.api.getFullProjectData(route.params.token).pipe(
      catchError((error) => {
        Config.DEBUG ? console.log(error) : null;
        this.n.go('/', {}, { replaceUrl: true });
        return of(error);
      }),
    );
  }
}
