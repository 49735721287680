import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { ListConfig } from '../interfaces/list-config.interface';

export const DefaultListConfig: ListConfig = {
  columnMode: ColumnMode.force,
  limit: 50,
  loadingIndicator: true,
  listName: '',
  rowHeight: undefined,
  listTitle: null,
  sortType: null,
  availablePerPage: [50, 100, 200],
  offset: 0,
  url: '',
  scrollbarV: false,
  scrollbarH: false
};
