import { ControlValueAccessor, Validator, FormControl } from '@angular/forms';
import {
  GbxsoftFomControlErrorController,
  GbxsoftControlError,
} from './controllers/gbxsoft-form-control-error.controller';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
@Component({ template: '' })
export class GbxsoftBaseFormComponent implements ControlValueAccessor, Validator {
  @Input() errMessages: GbxsoftControlError;

  public errorCtrl: GbxsoftFomControlErrorController;

  public value: any = null;

  protected fnOnChange: Function;
  protected fnOnTouched: Function;

  constructor(public changes: ChangeDetectorRef) {
    this.errorCtrl = new GbxsoftFomControlErrorController(this);
  }

  /**
   *Inserting value from FormContol
   *
   * @param {*} value
   * @memberof GbxsoftBaseFormComponent
   */
  writeValue(value: any) {
    this.value = value;
  }

  /**
   *Updating FormControl value on Change
   *
   * @param {*} fn
   * @memberof GbxsoftBaseFormComponent
   */
  registerOnChange(fn) {
    this.fnOnChange = fn;
  }

  /**
   *Updating FormControl value on Blur
   *
   * @param {*} fn
   * @memberof GbxsoftBaseFormComponent
   */
  registerOnTouched(fn) {
    this.fnOnTouched = fn;
  }

  public validate(c: FormControl) {
    this.errorCtrl.updateControl(c);
    return null;
  }
}
