<div #manageBox class="commentary__manage" [ngClass]="{ 'commentary__manage--bordered': isCommentsDefault }">
  <div
    class="commentary__manage__titled d-flex justify-content-between"
    [ngClass]="{ 'commentary__manage__title--first': isCommentsFirst }"
  >
    <span>
      {{ (isCommentsDefault ? 'Commentaries.title' : 'Commentaries.titleMany') | translate }}
    </span>
    <button *ngIf="manualSort" class="btn-icon p-0 d-inline-flex pointer" [matMenuTriggerFor]="menu">
      <img draggable="false" src="./assets/img/ic-sort-amount-down.svg" alt="ic-sort-amount-down" />
    </button>
  </div>

  <mat-menu #menu="matMenu" xPosition="before">
    <button (click)="sortFromLatest()" mat-menu-item>
      <span>{{ 'Commentaries.fromLatest' | translate }}</span>
    </button>
    <button (click)="sortFromOldest()" mat-menu-item>
      <span>{{ 'Commentaries.fromOldest' | translate }}</span>
    </button>
  </mat-menu>

  <ng-container *ngIf="isCommentsFirst">
    <ul *ngIf="commentaries?.length" class="commentary__manage__list">
      <li
        *ngFor="let commentary of commentaries; let i = index; trackBy: trackCommentaries"
        [@fadeInOnEnter]="commentary"
        [@fadeOutOnLeave]
      >
        <div>
          <commentary-item
            (commentaryEvent)="emitCommentaryEvent($event)"
            [commentary]="commentary"
          ></commentary-item>
        </div>
      </li>
    </ul>
  </ng-container>

  <div #commentaryInp class="commentary__manage__attachments d-flex mb-3">
    <text-attachments-input
      #textAttach
      [fileName]="'files'"
      [textName]="'note'"
      [form]="form"
      [config]="config"
      [attachmentsConfig]="attachmentsConfig"
    ></text-attachments-input>
    <app-button
      [loading]="loading"
      [disabled]="loading"
      (onClick)="addCommentary()"
      [class]="'ml-2'"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        text: 'Commentaries.add' | translate,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>
  </div>

  <ng-container *ngIf="isCommentsDefault">
    <ul *ngIf="commentaries?.length" class="commentary__manage__list">
      <li
        *ngFor="let commentary of commentaries; let i = index; trackBy: trackCommentaries"
        [@fadeInOnEnter]="commentary"
        [@fadeOutOnLeave]
      >
        <div>
          <commentary-item
            (commentaryEvent)="emitCommentaryEvent($event)"
            [commentary]="commentary"
          ></commentary-item>
        </div>
      </li>
    </ul>
  </ng-container>
</div>
