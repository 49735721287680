import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { TaskStatus } from '@shared/enums/task-status.enum';
import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { debounce } from '@shared/decorators/debounce.decorator';
import { Task } from '@shared/models/task.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { FormGroup } from '@angular/forms';
import { WindowHelper } from '@shared/helpers/window.helper';
import { MatDialog } from '@angular/material/dialog';
import { TaskController } from '@shared/controllers/task.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Employee } from '@shared/models/employee.model';
import { StorageService } from '@core/services/storage.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { TaskAction } from '@modules/protocols/shared/enums/task-action.enum';
import { BaseTaskComponent } from '@shared/components/base-task/base-task.component';
import { TaskModalPreviewComponent } from '../task-modal-preview/task-modal-preview.component';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { CdkDrag, DragRef } from '@angular/cdk/drag-drop';
import { TaskListItem } from '@modules/protocols/shared/interfaces/task-list-item.interface';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'task-entity',
  templateUrl: './task-entity.component.html',
  styleUrls: ['./task-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEntityComponent extends BaseTaskComponent implements AfterViewInit {
  WindowHelper = WindowHelper;
  PermissionsGroups = PermissionsGroups;
  checkDragPermission: boolean;
  taskPermissionCtrl: TaskPermissionController = new TaskPermissionController();

  _task: Task;
  @Input()
  set task(_task: Task) {
    this._task = _task;
    this.taskPermissionCtrl.initPermissions(this._task);
    this.getDragPermission(_task);
  }

  get task() {
    return this._task;
  }

  @Input() index: number;
  @Input() taskList: TaskListItem;
  @Input() selected: boolean;
  @Input() form: FormGroup;
  @Input() snackBottom: boolean = true;
  @Input() disabledDnd: boolean = false;

  time = Date.now();
  taskController: TaskController;
  employee: Employee;
  stop: boolean = true;

  dragging: boolean = false;
  interval = null;
  mousePosition = { x: 0, y: 0 };

  constructor(private changes: ChangeDetectorRef, private dialog: MatDialog, private store: StorageService) {
    super();
    this.employee = store.Employee;
  }

  ngAfterViewInit(): void {
    this.taskController = new TaskController(this.task);
  }

  trackAttachments(i: number, a: Attachment) {
    return a.id;
  }

  dragStarted($event) {
    this.dragging = true;
    this.scrollInterval();
    this.changes.detectChanges();
  }

  drag(e: DragEvent) {
    this.mousePosition = Object.assign({}, { x: e.clientX, y: e.clientY });
    this.changes.detectChanges();
  }

  dragEnded() {
    this.dragging = false;
    this.changes.detectChanges();
  }

  scrollInterval() {
    const offset = WindowHelper.isLessMD ? 90 : 200;
    const speedOffset = WindowHelper.isLessMD ? 25 : 200;
    const sidenav = document.querySelector('.protocol-stuffs');
    const element = document.querySelector(WindowHelper.isLessMD ? '.cupertino-container' : '.base-panel');
    const _scrollElement: any = !!sidenav ? sidenav : element;

    this.interval = setInterval(() => {
      const mousePositionY = WindowHelper.isLessMD ? this.mousePosition.y - 120 : this.mousePosition.y;
      if (!this.dragging) {
        clearInterval(this.interval);
        this.interval = null;
      }
      const currentScrollTop = _scrollElement.scrollTop;

      // Scrolling Down
      if (mousePositionY > _scrollElement.clientHeight - offset) {
        _scrollElement.scrollTo(_scrollElement.scrollLeft, currentScrollTop + speedOffset);
      }

      //Scrolling Up
      if (this.mousePosition.y < offset) {
        _scrollElement.scrollTo(_scrollElement.scrollLeft, currentScrollTop - speedOffset);
      }
      this.changes.detectChanges();
    }, 200);
  }

  changeTaskSelection(option: boolean, task) {
    if (task.isNote) {
      task.status = option ? TaskStatus.COMPLETED : TaskStatus.CREATED;
      this.taskEmitter.emit({
        type: TaskAction.STATUS_POST_ACTION,
        data: task,
      });
    }

    this.changes.detectChanges();
  }

  changeType(event: { item: Task; type: TaskType }) {
    this.taskEmitter.emit({
      type: TaskAction.TYPE_CHANGE_ACTION,
      data: event,
    });
    this.changes.detectChanges();
  }

  deleteTask(item: Task) {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.taskEmitter.emit({
          type: TaskAction.DELETE_POST_ACTION,
          data: item,
        });
        this.changes.detectChanges();
      })
      .subscribe(() => {});
  }

  edit() {
    const ctrl = new TaskSidenavController();
    ctrl.editTask(null, String(this.task.id));
  }

  previewTask() {
    const ctrl = new TaskSidenavController();
    this.taskPermissionCtrl.preview || this.taskPermissionCtrl.canAccept
      ? ctrl.previewTask(String(this.task.id))
      : null;
  }

  getDragPermission(task: Task) {
    const ctrl = new CheckPermission(task.editPermission);
    this.checkDragPermission = task.editPermission ? ctrl.check() : true;
  }

  @HostListener('window:resize', ['$event'])
  @debounce(30)
  windowResize(e: KeyboardEvent) {
    this.changes.detectChanges();
  }
}
