import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ISideNavigationConfig } from '@shared/modules/side-navigation/interfaces/side-navigation-config.interface';
import { projectPanelPreviewSideConfig } from '@modules/projects/modules/project-preview/components/project-panel-preview/project-panel-preview-side-config.const';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { StorageService } from '@core/services/storage.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import {
  ProjectChangeStageEvent,
  ProjectChangeStageEventType,
} from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { Subscription } from 'rxjs';
import { ProjectPreviewEventType } from '../../enums/projcet-preview-event-type.enum';

@Component({
  selector: 'project-panel-preview',
  templateUrl: './project-panel-preview.component.html',
  styleUrls: ['./project-panel-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPanelPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  config: ISideNavigationConfig;
  hiddenSideNavigation: boolean = false;
  ButtonTypes = ButtonTypes;
  PermissionsGroups = PermissionsGroups;
  ProjectStage = ProjectStage;
  ButtonSize = ButtonSize;
  Object = Object;
  subs: Subscription = new Subscription();

  @ViewChild('headerTemplate', { read: TemplateRef }) headerTemplate;

  constructor(
    private t: TranslateService,
    public n: NavigateService,
    public projectPreviewService: ProjectPreviewService,
    private projectApi: ProjectAPIService,
    public projectStore: ProjectStoreService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: { project: Project }) => {
      this.projectPreviewService.project = data.project;
      this.initSideNavigationConfig();
      this.changes.detectChanges();
    });
  }

  initSideNavigationConfig() {
    this.config = projectPanelPreviewSideConfig(
      this.t,
      this.n,
      this.projectPreviewService.project,
      this.storage.Employee,
    );
    this.config.headerTemplate = this.headerTemplate;
    this.changes.detectChanges();
  }

  ngAfterViewInit(): void {
    this.config.headerTemplate = this.headerTemplate;
    this.changes.detectChanges();
  }

  back() {
    this.n.go('projects-list', {}, { queryParams: { tour: null }, queryParamsHandling: 'preserve' });
  }

  onStageChange(stage: ProjectStage) {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    const sub = ctrl
      .setStage(
        this.projectPreviewService.project,
        stage,
        this.projectPreviewService.project.basicDataBox.termStart,
        this.projectPreviewService.project.basicDataBox.termEnd,
      )
      .subscribe((e: ProjectChangeStageEvent) => {
        switch (e.type) {
          case ProjectChangeStageEventType.CHANGED:
            this.projectPreviewService.project = e.project;
            break;
        }
        this.projectPreviewService.projectServiceEventEmitter.emit({
          type: ProjectPreviewEventType.CHANGE_STAGE,
        });
      });

    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
    this.projectPreviewService.clearService();
    this.projectPreviewService.project = null;
  }
}
