import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { NavigateService } from '@shared/services/navigate.service';
import { Config } from '@shared/configs/config';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { UserPerson } from '@shared/interfaces/user.interface';
import { Employee } from '@shared/models/employee.model';

@Injectable({providedIn: 'root'})
export class AuthorizationGuard implements CanActivate {
  constructor(private store: StorageService, private n: NavigateService, public settingsService: SettingsService) {
  }

  canActivate() {

    if (this.store.jwt && this.store.UserPerson && this.store.Employee) {
      this.n.navigate(Config.MAIN_VIEW);
      return false;
    }

    return true;
  }
}
