import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';

export const COLOR_PALLETE = [
  '#AD1457',
  '#D81B60',
  '#D50000',
  '#E67C73',
  '#F4511E',
  '#EF6C00',
  '#F09300',
  '#F6BF26',
  '#E4C441',
  '#C0CA33',
  '#7CB342',
  '#33B679',
  '#0B8043',
  '#009688',
  '#039BE5',
  '#4285F4',
  '#187DBD',
  '#3F51B5',
  '#7986CB',
  '#B39DDB',
  '#9E69AF',
  '#8E24AA',
  '#795548',
  '#616161',
  '#A79B8E',
];

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements OnInit {
  static readonly defaultColor: string = '#187DBD';

  @Input() color: string = ColorPickerComponent.defaultColor;
  @Input() colors: string[] = COLOR_PALLETE;

  @Input() disabled: boolean = true;

  @Output() colorChanges: EventEmitter<string> = new EventEmitter();

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}

  pickColor(color: string) {
    this.color = color;
    this.colorChanges.emit(this.color);
    this.changes.detectChanges();
  }
}
