import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';

export const DocumentTypesList = [
  {
    name: '',
    value: EntryType.TYPE_ESTATE_PLAN,
  },
  {
    name: '',
    value: EntryType.TYPE_EXECUTIVE_PLAN,
  },
  {
    name: '',
    value: EntryType.TYPE_MATERIALS,
  },
  {
    name: '',
    value: EntryType.TYPE_MATERIAL_LIST,
  },
  // USUNAC PO TESTACH
  // {
  //   name: '',
  //   value: EntryType.TYPE_ORDERS,
  // },
  // {
  //   name: '',
  //   value: EntryType.TYPE_PERMISSION,
  // },
  // {
  //   name: '',
  //   value: EntryType.TYPE_INSPIRATIONS,
  // },
  // USUNAC PO TESTACH
  {
    name: '',
    value: EntryType.TYPE_OTHER_DOCUMENTS,
  },
  {
    name: '',
    value: EntryType.TYPE_PHOTOS_VIDEOS,
  },
];

export const DocumentInternalTypeList = [
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_OFFERS,
  },
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_CONTRACTS,
  },
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_PARTNER_OFFER,
  },
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_ORDERS,
  },
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_PROTOCOL,
  },
  {
    name: '',
    value: EntryType.TYPE_INTERNAL_DOCUMENTS,
  },
];
