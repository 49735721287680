<div
  tourAnchor="protocolsList.s3.loggedCompany"
  class="header-action-menu-company"
  [matMenuTriggerFor]="companyMenu"
  *ngIf="userPerson?.employees.length > 1"
>
  <div class="d-flex align-content-center">
    <span class="company-name threeDots">{{
      employee?.company.name ? employee.company.name : ('Global.noCompanyName' | translate)
    }}</span>
    <img draggable="false" class="ml-2" src="/assets/img/arrow-down-solid.svg" alt="Arrow" />
  </div>
  <mat-menu #companyMenu="matMenu" xPosition="after">
    <h6 class="header-action-menu-company_change">{{ 'Header.changeCompany' | translate }}</h6>
    <ng-container *ngFor="let empl of userPerson.employees">
      <button mat-menu-item (click)="logToCompany(empl.id)" *ngIf="empl.company.id !== employee.company.id">
        <span class="threeDots">{{
          empl.company.name ? empl.company.name : ('Global.noCompanyName' | translate)
        }}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>

<div
  tourAnchor="protocolsList.s3.loggedCompany"
  class="header-action-menu-company no-hover cursor-default"
  *ngIf="userPerson.employees.length === 1"
>
  <div class="d-flex align-content-center">
    <span class="company-name threeDots">{{
      employee.company.name ? employee.company.name : ('Global.noCompanyName' | translate)
    }}</span>
  </div>
</div>
