import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectsListComponent } from '@modules/projects/modules/projects-list/pages/projects-list/projects-list.component';
import { ProjectsListGuard } from '@core/guards/projects/projects-list.guard';

export const ProjectsListRoutes: CustomRoutes = [
  {
    path: '',
    name: 'projects-list',
    component: ProjectsListComponent,
    canActivate: [ProjectsListGuard],
    data: {
      metaTitle: 'Projects.list',
    },
  },
];
