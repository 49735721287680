export enum EventReminderFullDay {
  REMIND_ONE_DAY = 'REMIND_ONE_DAY',
  REMIND_THREE_DAYS = 'REMIND_THREE_DAYS',
  REMIND_SEVEN_DAYS = 'REMIND_SEVEN_DAYS',
}

export enum EventReminderNonFullDay {
  REMIND_TEN_MINUTES = 'REMIND_TEN_MINUTES',
  REMIND_THIRTY_MINUTES = 'REMIND_THIRTY_MINUTES',
  REMIND_ONE_HOUR = 'REMIND_ONE_HOUR',
  REMIND_THREE_HOURS = 'REMIND_THREE_HOURS',
  REMIND_TWENTY_FOUR_HOURS = 'REMIND_TWENTY_FOUR_HOURS',
}
