import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'listGroupTranslate', pure: true})

export class ListGroupTranslatePipe implements PipeTransform {

  constructor(private t: TranslateService) {
  }

  transform(value: string): string {
    const translation = this.t.instant(`Projects.TaskGroups.${value}`);
    if (translation === `Projects.TaskGroups.${value}`) {
      return value;
    } else {
      return translation;
    }
  }
}
