import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectSummaryComponent } from '@modules/projects/modules/project-summary/pages/project-summary/project-summary.component';

export const ProjectSummaryRoutes: CustomRoutes = [
  {
    path: '',
    component: ProjectSummaryComponent,
    name: 'project-summary',
  },
];
