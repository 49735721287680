import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { NavigateService } from '@shared/services/navigate.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Injectable({ providedIn: 'root' })
export class ContactsListGuard implements CanActivate {
  constructor(private store: StorageService, private n: NavigateService) {}

  canActivate() {
    const employee = this.store.Employee;
    const ctrl = new CheckPermission({
      group: PermissionsGroups.CONTACTS,
      action: 'LIST',
      objectCreatorId: employee?.hasResponsibleProjects ? employee.id : null,
    });
    if (ctrl.check()) {
      return true;
    } else {
      this.n.go('user-settings');
      return false;
    }
  }
}
