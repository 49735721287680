<main class="bg-white contacts-list">
  <action-title-header-v2
    [title]="'Contacts.List.title' | translate"
    [buttonText]="'Contacts.List.addContact' | translate"
    (onAction)="addContact()"
    [sticky]="true"
  >
    <list-search-v2></list-search-v2
    ><list-filters-btn-v2 [filters]="ContactsFiltersComponent"></list-filters-btn-v2>
    <ng-template #action>
      <div class="d-flex gap-4">
        <app-button
          *ngIf="listService.config?.urlExport"
          [loading]="loading"
          (onClick)="downloadExcel()"
          [config]="{
            type: ButtonTypes.NEW_PRIMARY_BLUE,
            size: ButtonSize.LARGE,
            smSize: ButtonSize.SMALL,
            appendSrc: './assets/img/download-icon.svg'
          }"
        >
        </app-button>
        <column-config-button *ngIf="!WindowHelper.isMobileWidth"></column-config-button>
        <app-button
          [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
          (onClick)="addContact()"
          [loading]="loading"
          [config]="{
            type: ButtonTypes.NEW_PRIMARY_BLUE,
            text: WindowHelper.isLessXXL ? '' : ('Contacts.List.addContact' | translate),
            size: ButtonSize.LARGE,
            smSize: ButtonSize.SMALL,
            prependSrc: './assets/img/ic-plus-add.svg'
          }"
        >
        </app-button>
      </div>
    </ng-template>
  </action-title-header-v2>

  <div class="container-fluid--30 contacts-list__content">
    <app-list
      *ngIf="inited"
      [filters]="ContactsFiltersComponent"
      [config]="config"
      [emptyTemplate]="emptyTemplate"
      [searchName]="'Contacts.List.searchContact' | translate"
      [selected]="selected"
      (activate)="activate($event)"
      [showToggleMenu]="showToggleMenu.bind(this)"
      [headerHidden]="true"
      #list
    >
    </app-list>
  </div>
</main>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/Contact.svg"
      alt="{{ 'Contacts.List.addContact' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'Contacts.List.emptyList' | translate }}</div>
    <app-button
      [permission]="{
        group: PermissionsGroups.CONTACTS,
        action: 'ADD'
      }"
      (onClick)="addContact()"
      [config]="{
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'Contacts.List.addContact' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    >
    </app-button>
  </div>
</ng-template>

<contact-name-cell #contactNameCell></contact-name-cell>
<contact-type-cell #contactTypeCcell></contact-type-cell>
<contact-phone-cell #contactPhoneCell></contact-phone-cell>
<contact-email-cell #contactEmailCell></contact-email-cell>
<contact-availability-cell #contactAvailabilityCell></contact-availability-cell>
<contact-parents-count-cell #contactParentCountCell></contact-parents-count-cell>
<contact-subcontacts-count-cell #contactSubcontactsCountCell></contact-subcontacts-count-cell>
<contact-creator-cell #contactCreatorCell></contact-creator-cell>
<contact-function-cell #contactFunctionCell></contact-function-cell>
