import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { Config } from '@shared/configs/config';

export const ProjectBoxEntryConfig: TextAttachmentsConfig = {
  photoAttachments: true,
  fileAttachments: true,
  showText: true,
  maxAttachmentImages: 50,
  maxAttachments: 50,
  maxAttachmentFileSize: 50,
  maxAttachmentImageSize: 50,
};

export const ProjectPhotoBoxEntryConfig: TextAttachmentsConfig = {
  photoAttachments: true,
  fileAttachments: false,
  showText: true,
  maxAttachments: 50,
  maxAttachmentImageSize: 50,
};

export const ProjectVideoBoxEntryConfig: TextAttachmentsConfig = {
  photoAttachments: false,
  fileAttachments: false,
  videoAttachments: true,
  showText: true,
  maxAttachments: 50,
  requiredExtensions: Config.VIDEO_EXTENSIONS,
  maxAttachmentFileSize: 1800,
};
