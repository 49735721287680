import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { NotificationType } from './notification-type';
import { EParticipantStatus } from '@shared/modules/event-sidenav/enums/participant-status.enum';

export class Notification<T> {
  deleted?: any;
  created: string;
  modified: string;
  id: number;
  type: NotificationType;
  data: T;
  eventParticipant?: { status: EParticipantStatus };
  shown: boolean;
  today: boolean = false;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
      this.checkToday();
    }
  }

  private checkToday() {
    if (moment(this.created, Config.DATE_SERVER).isSame(moment(), 'd')) { // set today flag
      this.today = true;
    }
  }
}

