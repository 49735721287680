import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionType } from '../enums/permission-type.enum';
import { PermissionsAction } from '../interfaces/permissions-action.interface';
import { PermissionsConfig } from '../interfaces/permissions-config';
import { PermissionsGroups } from '../permissions.group';

/**
 * @description
 * @author Robert Juszczyk
 * @export
 * @class TaskPermissionsConfig
 * @implements {PermissionsConfig}
 */
export class TaskPermissionsConfig implements PermissionsConfig {
  static readonly EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly ADD: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    UserRoles.ROLE_EMPLOYEE_WORKER,
    UserRoles.ROLE_EMPLOYEE_EXTERNAL,
  ];

  static readonly REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  // #region Permissions Attached to TaskType CHANGE
  static readonly CHANGE_ADD: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGE_PREVIEW: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGE_EDIT: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGE_SHARE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGE_REMOVE: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly CHANGE_PRICE_VISIBILITY: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];
  //#endregion

  static readonly type: PermissionsGroups = PermissionsGroups.TASKS;
}
