import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
})
export class HelpIconComponent implements OnInit {
  @Input() message: string;
  @Input() className: string = '';

  constructor() {}

  ngOnInit(): void {}
}
