<app-button
  (onClick)="openFilters()"
  [config]="{
    size: ButtonSize.LARGE,
    smSize: ButtonSize.SMALL,
    type: ButtonTypes.NEW_SECONDARY_BLUE,
    text: WindowHelper.isSuperSmallMobileWidth ? '' : ('List.Filters.filters' | translate),
    prependSrc: './assets/img/filter-icon.svg'
  }"
></app-button>
