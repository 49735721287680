import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';
import { debounce } from '@shared/decorators/debounce.decorator';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { IProjectPreviewEvent } from '@modules/projects/modules/project-preview/interfaces/project-preview-event.interface';
import { ProjectPreviewEventType } from '@modules/projects/modules/project-preview/enums/projcet-preview-event-type.enum';
import { Subscription } from 'rxjs';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { privateFile } from '@shared/helpers/private-file.helper';
import { downloadFile } from '@shared/helpers/download-file.helper';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import {
  ProjectChangeStageEvent,
  ProjectChangeStageEventType,
} from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { WindowHelper } from '@shared/helpers/window.helper';
import { projectPanelPreviewSideConfig } from '@project-modules/project-preview/components/project-panel-preview/project-panel-preview-side-config.const';
import { SideNavigationTabType } from '@shared/modules/side-navigation/enums/side-navigation-tab-type.enum';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NavigateService } from '@shared/services/navigate.service';
import { NavigationEnd, Router } from '@angular/router';
import { ListTaskService } from '@shared/modules/list/services/list-task.service';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'project-panel-preview-header',
  templateUrl: './project-panel-preview-header.component.html',
  styleUrls: ['./project-panel-preview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPanelPreviewHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  ProjectStage = ProjectStage;
  PermissionsGroups = PermissionsGroups;
  Object = Object;
  Boolean = Boolean;
  WindowHelper = WindowHelper;

  subs: Subscription = new Subscription();
  tabs: {
    id: string;
    text: string;
    icon: string;
  }[] = [];
  @Input() hiddenSideNavigation: boolean = false;
  @ViewChild('header') header: ElementRef;
  @ViewChild('matMenu') matMenu: ElementRef;
  @ViewChild('selectTab') selectTab: NgSelectComponent;

  @HostListener('window:resize', ['$event'])
  @debounce(30)
  onResize() {
    this.changes?.detectChanges();
  }

  constructor(
    public projectPreviewService: ProjectPreviewService,
    private changes: ChangeDetectorRef,
    private basePanelService: BasePanelService,
    public service: ProjectAPIService,
    public projectStore: ProjectStoreService,
    private s: SnackBarService,
    public t: TranslateService,
    public n: NavigateService,
    private router: Router,
    private listTaskService: ListTaskService,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    this.listenProjectPreviewEvents();
    this.listenChangeTab();
    this.setTabs();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.header.nativeElement.style.right = this.basePanelService.scrollbarWidth + 'px';
      this.notEmptyList();
    });
    this.setActiveTab();
  }

  listenChangeTab() {
    this.subs.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setActiveTab();
        }
      }),
    );
  }

  isSummaryView() {
    return window.location.href.indexOf('summary') !== -1;
  }

  setTabs() {
    const configTabs = projectPanelPreviewSideConfig(
      this.t,
      this.n,
      this.projectPreviewService.project,
      this.storage.Employee,
    );
    Object.values(configTabs.tabs).map(
      (tab: { name: string; type: SideNavigationTabType; link: string; icon: string }) => {
        this.tabs.push({
          id: tab.link,
          text: tab.name,
          icon: tab.icon,
        });
      },
    );
  }

  setActiveTab() {
    this.selectTab
      ? this.tabs.map((tab: { id; text }) => {
          if (window.location.href.indexOf(tab.id) !== -1) {
            this.selectTab.writeValue(tab.id);
          }
        })
      : '';
  }

  listenProjectPreviewEvents() {
    const sub = this.projectPreviewService.projectServiceEventEmitter.subscribe(
      (event: IProjectPreviewEvent) => {
        switch (event.type) {
          case ProjectPreviewEventType.UPDATE_PROJECT:
            this.changes.detectChanges();
            break;
        }
      },
    );
    this.subs.add(sub);
  }

  onStageChange(stage: ProjectStage) {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    const sub = ctrl
      .setStage(
        this.projectPreviewService.project,
        stage,
        this.projectPreviewService.project.basicDataBox.termStart,
        this.projectPreviewService.project.basicDataBox.termEnd,
      )
      .subscribe((e: ProjectChangeStageEvent) => {
        switch (e.type) {
          case ProjectChangeStageEventType.CHANGED:
            this.projectPreviewService.project = e.project;
            break;
        }
        this.projectPreviewService.projectServiceEventEmitter.emit({
          type: ProjectPreviewEventType.CHANGE_STAGE,
        });
      });

    this.subs.add(sub);
  }

  edit() {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    ctrl.edit(this.projectPreviewService.project);
  }

  openMailings() {
    const ctrl = new MailingController();
    ctrl.goToTemplates('', this.projectPreviewService.project.id);
  }

  addAlert() {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    const sub = ctrl.openAlerts(this.projectPreviewService.project).subscribe((p: Project) => {
      this.projectPreviewService.project = p;
    });
    this.subs.add(sub);
  }

  remove() {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    ctrl.remove(this.projectPreviewService.project);
  }

  downloadSpec() {
    this.service
      .getFileBlob(privateFile(this.projectPreviewService.project.specification.file), false)
      .subscribe({
        next: (res) => {
          downloadFile(res, this.projectPreviewService.project.specification.name);
        },
        error: () => {
          this.s.error(this.t.instant('ProjectSummary.RightSection.errorDownloadSpecification'));
        },
      });
  }

  toggleMobileSummary() {
    this.projectPreviewService.projectServiceEventEmitter.emit({
      type: ProjectPreviewEventType.TOGGLE_RIGHT_SECTION,
    });
  }

  editResponsible() {
    const ctrl = new ProjectController(this.projectPreviewService.project);
    const sub = ctrl.setResponsibleCompany(this.projectPreviewService.project).subscribe((p: Project) => {
      this.projectPreviewService.project = p;
    });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs ? this.subs.unsubscribe() : null;
  }

  notEmptyList() {
    this.matMenu ? console.log(this.matMenu.nativeElement.childCount) : '';
    return true;
  }

  onTabClick(clickedTab, isFromMobileSelect?: boolean) {
    this.selectTab.writeValue(clickedTab.id);
  }

  tabSelect(clickedTab: any) {
    this.router.navigate([clickedTab.id]).then(() => {
      setTimeout(() => {
        document.querySelector('.project-panel-preview__content').scrollIntoView();
      }, 100);
    });
  }
}
