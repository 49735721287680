import { EventCycle } from '../enums/event-cycle.enum';

export const EventCycleList = [
  {
    id: EventCycle.CYCLE_EVERY_DAY,
    label: 'CalendarEvents.Cycle.day',
  },
  {
    id: EventCycle.CYCLE_EVERY_WEEK,
    label: 'CalendarEvents.Cycle.week',
  },
  {
    id: EventCycle.CYCLE_EVERY_SECOND_WEEK,
    label: 'CalendarEvents.Cycle.secondweek',
  },
  {
    id: EventCycle.CYCLE_EVERY_MONTH,
    label: 'CalendarEvents.Cycle.month',
  },
  {
    id: EventCycle.CYCLE_EVERY_THIRD_MONTH,
    label: 'CalendarEvents.Cycle.thirdmonth',
  },
  {
    id: EventCycle.CYCLE_EVERY_YEAR,
    label: 'CalendarEvents.Cycle.year',
  },
];
