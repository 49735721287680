<div [formGroup]="form" class="task-change-acceptance">
  <div class="mb-2">
    <b>{{ 'ProjectChanges.changeAcceptanceTitle' | translate }}</b>
  </div>
  <div class="task-change-acceptance__textarea mb-3">
    <gbxsoft-textarea
      [formControlName]="ECHANGE_ACCEPTANCE_FIELDS.comment"
      [config]="{ name: 'ProjectChanges.addAcceptanceCommentary' | translate }"
    ></gbxsoft-textarea>
  </div>

  <div class="d-flex align-items-center justify-content-end task-change-acceptance__action-bar">
    <app-button
      [disabled]="loading"
      (onClick)="submitTask(false)"
      [config]="{
        type: ButtonTypes.DANGER_BUTTON,
        size: ButtonSize.SMALL,
        text: 'Protocols.List.decline' | translate,
        prependSrc: './assets/img/ic-decline.svg'
      }"
    ></app-button>
    <app-button
      [disabled]="loading"
      [class]="'ml-2'"
      (onClick)="submitTask(true)"
      [config]="{
        type: ButtonTypes.PRIMARY_GREEN,
        size: ButtonSize.SMALL,
        text: 'Protocols.List.accept' | translate,
        prependSrc: './assets/img/ic-check.svg'
      }"
    ></app-button>
  </div>
</div>
