import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/components/base.component';
import { Regex } from '@shared/configs/regex';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { userCodes } from '@shared/consts/user-codes';
import { Config } from '@shared/configs/config';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Component({
  templateUrl: './forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  constructor(private authService: AuthorizationService, private changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
    });
  }

  submitReseting() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    this.changes.detectChanges();
    if (this.form.invalid || this.loading) return;
    this.loading = true;
    this.changes.detectChanges();
    this.authService
      .resetPassword(this.form.controls.email.value)
      .subscribe({
        next: (res) => this.successReset(res),
        error: (err: HttpError) => this.errorReset(err),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successReset(res) {
    this.form.reset();
    this.s.success(this.t.instant('Auth.Messages.accountReset'));
  }

  errorReset(err: HttpError) {
    switch (err.messageCode) {
      case IntilioCodes.GOOGLE_AUTH_RESET_IMPOSSIBLE:
        this.s.error(this.t.instant('Auth.Errors.googleResetImpossible'));
        break;
      case userCodes.NOT_EXISTS:
        this.s.error(this.t.instant('Auth.Errors.accountNotExists'));
        break;
      default:
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        break;
    }
  }

  get errorMessages() {
    return Config.validationMessages;
  }
}
