import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';

@Pipe({ name: 'dateFromNow', pure: true })

export class DateFromNowPipe implements PipeTransform {
  transform(value: string): string {
    return value ? moment(value, Config.DATE_SERVER).fromNow() : '';
  }
}
