import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { debounce } from '@shared/decorators/debounce.decorator';
import { Router } from '@angular/router';

@Component({
  selector: 'button-group-v2',
  templateUrl: './button-group-v2.component.html',
  styleUrls: ['./button-group-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupV2Component {
  window = window;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @Input() config: ButtonGroupConfig;
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public changes: ChangeDetectorRef, private router: Router) {}

  getRouterLinkActive(link: string) {
    return window.location.href.indexOf(link) !== -1;
  }

  onDropdownButtonClick(btn: { name: string; link?: string; icon?: string; active?: boolean; prop?: any }) {
    if (btn.link) {
      this.router.navigate([btn.link]);
    } else {
      this.onClick.emit(btn.prop);
    }
  }

  @HostListener('window:resize')
  @debounce(150)
  resize() {
    this.changes.detectChanges();
  }
}

export interface ButtonGroupConfig {
  buttons: {
    name: string;
    link?: string;
    icon?: string;
    active?: boolean;
    prop?: any;
  }[];
  mobileDropdown?: number;
}
