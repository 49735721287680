import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class DialogHelperService {
  htmlModal: HTMLElement;
  dialog: MatDialogRef<any>;

  constructor(private dialogRef: MatDialog) {}

  subscribeModals() {
    const resizeListener = this.addShadowToModal.bind(this);
    this.dialogRef.afterOpened.subscribe((dialog) => {
      this.dialog = dialog;
      this.setPopStateListener();
      dialog.beforeClosed().subscribe(() => window.removeEventListener('resize', resizeListener));
      dialog.afterOpened().subscribe(() => {
        this.htmlModal = document.getElementById(dialog.id);
        this.addShadowToModal();
        window.addEventListener('resize', resizeListener);
      });
    });
  }

  addShadowToModal() {
    const actions = this.htmlModal?.querySelector('.mat-dialog-actions');
    const wrapper = this.htmlModal?.querySelector('.mat-dialog-content');
    if (wrapper && wrapper.scrollHeight > wrapper.clientHeight) {
      actions?.classList?.add('scrollable-content');
    } else {
      actions?.classList?.remove('scrollable-content');
    }
  }

  popStateClose() {
    this.dialog.close();
    window.removeEventListener('popstate', this.popStateClose, false);
  }

  setPopStateListener() {
    this.popStateClose = this.popStateClose.bind(this);
    window.addEventListener('popstate', this.popStateClose, false);
  }
}
