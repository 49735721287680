<modal-title [title]="'LoseData.headerTitle' | translate"></modal-title>

<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1" [innerHTML]="'LoseData.title' | translate"></h4>
  <p>{{ 'LoseData.description' | translate }}</p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    [class]="'mr-2 bold'"
    (onClick)="dialogRef.close()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.DISCARD_BUTTON,
      text: 'LoseData.cancel' | translate
    }"
  ></app-button>

  <app-button
    [class]="'bold'"
    (onClick)="confirm()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.DANGER_BUTTON,
      text: 'LoseData.confirm' | translate,
      prependSrc: './assets/img/ic-trash-outline-white.svg'
    }"
  ></app-button>
</mat-dialog-actions>
<!-- #endregion -->
