import { AddressType } from '../enums/address-type.enum';

export const AddressTypeList = [
  {
    id: AddressType.FROM_CLIENT,
    label: 'Projects.sameClientAddress',
  },
  {
    id: AddressType.NOT_BUILT_YET,
    label: 'Projects.notBuildYet',
  },
  {
    id: AddressType.OTHER,
    label: 'Projects.otherAddress',
  },
];
