import { DeviceHelper } from './device.helper';

export class Clipboard {
  static textarea: HTMLTextAreaElement;

  static copyText(text: string) {
    return new Promise((resolve, reject) => {
      this.createTextArea(text);
      this.selectText();
      this.copyToClipboard();
      resolve(text);
    });
  }

  private static createTextArea(text: string) {
    this.textarea = document.createElement('textarea') as HTMLTextAreaElement;
    this.textarea.value = text;
    document.body.appendChild(this.textarea);
  }

  private static selectText() {
    let range;
    let selection;

    if (DeviceHelper.isIOS) {
      range = document.createRange();
      range.selectNodeContents(this.textarea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      this.textarea.setSelectionRange(0, 999999);
    } else {
      this.textarea.select();
    }
  }

  private static copyToClipboard() {
    document.execCommand('copy');
    document.body.removeChild(this.textarea);
  }
}
