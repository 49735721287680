import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { Cacheable } from 'ngx-cacheable';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalDataService extends BaseHttpService {

  constructor() {
    super();
  }

  @Cacheable()
  getGlobalData() {
    const url = `${Config.API}/public/lists`;
    return this.get(url);
  }

}
