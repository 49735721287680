<div class="datepicker-control">
  <form [formGroup]="form">
    <mat-datepicker #dp></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="dp">
      <img draggable="false" src="/assets/img/ic-calendar.svg" alt="Calendar" matDatepickerToggleIcon />
    </mat-datepicker-toggle>

    <div class="special-control text-control">
      <div
        class="form-control"
        [ngClass]="{
          'has-value': this.hasValue,
          'has-error': this.hasRequiredError,
          required: this.required
        }"
      >
        <label>
          <input
            #input
            [matDatepicker]="dp"
            [matDatepickerFilter]="maxDateFilter"
            autocomplete="off"
            type="text"
            formControlName="{{ controlName }}"
            [placeholder]="placeholder"
            (focus)="isFocused = true; dp.open()"
            (blur)="isFocused = false"
          />

          <span [innerText]="inputConfig?.name"></span>
        </label>
        <span
          *ngIf="hasRequiredError"
          class="form-control-error-msg"
          [innerHTML]="'FormErrors.required' | translate"
        ></span>
      </div>
    </div>
  </form>
</div>
