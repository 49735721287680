<div class="event-protocol-single d-flex w-100 justify-content-between align-items-center">
  <span
    [title]="'Protocols.Types.' + item?.type | translate"
    class="event-protocol-single__title d-flex align-items-center"
  >
    <span сlass="d-block mr-2">
      <img draggable="false" src="assets/img/ic-document-text.svg" alt="protocol" />
    </span>
    {{ 'Protocols.Types.' + item?.type | translate }}
  </span>

  <div class="d-flex align-items-center">
    <span
      [title]="item?.idNumber ? item?.idNumber : ('Protocols.newName' | translate)"
      class="event-protocol-single__type text-right pr-2"
    >
      {{ item?.idNumber ? item?.idNumber : ('Protocols.newName' | translate) }}
    </span>

    <span [title]="item?.created | dateTimeFormat" class="event-protocol-single__date">
      {{ item?.created | dateTimeFormat }}
    </span>
    <span>
      <button class="btn-icon" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button
          mat-menu-item
          [permission]="{
            group: PermissionsGroups.PROTOCOLS,
            action: 'PREVIEW',
            objectCreatorId: item?.creator?.id
          }"
          (click)="preview()"
        >
          <span>{{ 'Protocols.QuickPreview.preview' | translate }}</span>
        </button>

        <button
          mat-menu-item
          [permission]="{
            group: PermissionsGroups.PROTOCOLS,
            action: 'EDIT',
            objectCreatorId: item?.creator?.id
          }"
          (click)="openEdit()"
        >
          <span>{{ 'Protocols.QuickPreview.edit' | translate }}</span>
        </button>

        <button (click)="deleteFromEvent()" mat-menu-item>
          <span>{{ 'CalendarEvents.unassingFromEvent' | translate }}</span>
        </button>

        <button
          [permission]="{
            group: PermissionsGroups.PROTOCOLS,
            action: 'REMOVE',
            objectCreatorId: item?.creator?.id
          }"
          mat-menu-item
          confirmationPopover
          class="hover_red red-500"
          placement="top"
          [cancelText]="'Protocols.List.cancel' | translate"
          [confirmText]="'Protocols.List.confirm' | translate"
          [popoverTitle]="'Protocols.List.removeTitleConfirmation' | translate"
          [popoverMessage]="'Protocols.List.removeDescriptionConfirmation' | translate"
          (click)="$event.stopPropagation()"
          (confirm)="remove()"
        >
          <span>{{ 'Protocols.List.remove' | translate }}</span>
        </button>
      </mat-menu>
    </span>
  </div>
</div>
