import { AttachmentType } from '@shared/components/text-attachments-input/enums/text-attachment-type.enum';

export class TextAttachment {
  type: AttachmentType;
  name?: string;
  content?: string;
  url?: string;
  id: string;
  file?: File;
  isUploaded?: boolean;

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
  }
}
