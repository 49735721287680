<div class="ui-project__header d-flex justify-content-between align-items-center flex-wrap">
  <div class="projects-header">
    <h3 class="mb-0">{{ 'Projects.PreviewTabs.tasks' | translate }}</h3>
    <div class="projects-header__inside">
      <app-button
        class="mr-2 my-1 my-lg-0"
        [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
        (onClick)="createNewTask()"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_GREEN,
          text: WindowHelper.isMobileWidth ? '' : ('Projects.newTask' | translate),
          prependSrc: './assets/img/ic-plus-add.svg'
        }"
      ></app-button>
      <app-button
        class="mr-2 my-1 my-lg-0"
        (onClick)="createNewTaskGroup()"
        [permission]="{ group: PermissionsGroups.PROJECTS, action: 'EDIT' }"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          text: 'Projects.newTaskGroup' | translate,
          prependSrc: './assets/img/ic-plus-add.svg'
        }"
      ></app-button>

      <list-search-v2></list-search-v2>

      <list-filters-btn-v2 class="ml-auto" [filters]="ProjectTasksFiltersComponent"></list-filters-btn-v2>
    </div>
  </div>
</div>
<div class="project-tasks">
  <!-- #region Content -->
  <div class="project-tasks__content">
    <div class="project-tasks__content__tasks">
      <list-tasks
        #listTasksComponent
        [config]="config"
        [filters]="ProjectTasksFiltersComponent"
        [project]="projectPreviewService?.project"
        [projectId]="projectPreviewService.project.id"
      ></list-tasks>
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Right Section -->
  <!-- <project-tasks-right-section></project-tasks-right-section> -->
  <!-- #endregion -->
</div>
