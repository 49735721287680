export enum ESummaryBoxStyle {
  DEFAULT,
  BLUE,
}

export enum ESummaryColor {
  DEFAULT = 'blue',
  GREEN = 'green',
  RED = 'red',
  BLACK = 'black',
}

export enum ESummaryValueType {
  MONEY,
  VALUE,
}
export interface ISummaryApiConfig {
  url?: string;
  fieldName?: string;
  currency?: string;
  value?: number;
  type?: ESummaryValueType;
}

export interface ISummaryBoxCosts {
  title: string;
  value: any;
  color?: ESummaryColor;
  apiConfig?: ISummaryApiConfig;
}

export interface ISummaryBox {
  title: string;
  tip?: string;
  style?: ESummaryBoxStyle;
  items: ISummaryBoxCosts[];
  total?: ISummaryBoxCosts;
}

export interface ISummaryConfig {
  title?: string;
  items: ISummaryBox[];
}

export interface ISummaryResponse {
  increasingChangesSum: number;
  decreasingChangesSum: number;
  zeroChangesCount: number;
  additionalTimeSum: number;
  changesBalance: number;
  discountsSum: number;
}
