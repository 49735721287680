<div #pairTimePicker [formGroup]="form" class="pair-timepicker d-flex align-items justify-content-between">
  <label class="pair-timepicker__option">
    <input
      type="text"
      (blur)="updateControl(startControl)"
      [formControlName]="startControl"
      placeholder="08:00"
      class="time start"
    />
  </label>
  <span class="d-inline-block mx-2">–</span>
  <label class="pair-timepicker__option">
    <input
      type="text"
      (blur)="updateControl(endControl)"
      [formControlName]="endControl"
      placeholder="08:30"
      class="time end"
    />
  </label>
</div>
