import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ProjectContactToggleMenuComponent } from '@project-modules/project-contacts/pages/project-contacts-list-component/components/project-contact-toggle-menu/project-contact-toggle-menu.component';

export const ProjectContactsListConfig: ListConfig = {
  columns: [
    {
      prop: 'id',
      name: 'ProjectContacts.List.name',
      componentRef: 'projectContactNameCellComponent',
      sortField: 'a.sortName',
      resizeable: false,
      hideOnMobile: false,
      width: 450,
      mobileWidth: 250,
      isTreeColumn: false,
      canNotShow: false,
      order: 1
    },
    {
      prop: 'type',
      name: 'ProjectContacts.List.type',
      componentRef: 'projectContactTypeCellComponent',
      sortField: 'a.type',
      resizeable: false,
      hideOnMobile: true,
      width: 130,
      order: 2
    },
    {
      prop: 'phone',
      name: 'ProjectContacts.List.phone',
      componentRef: 'projectContactPhoneCellComponent',
      sortField: 'a.phone',
      resizeable: false,
      hideOnMobile: true,
      width: 250,
      order: 3
    },
    {
      prop: 'email',
      name: 'ProjectContacts.List.email',
      componentRef: 'projectContactEmailCellComponent',
      sortField: 'a.email',
      resizeable: false,
      hideOnMobile: true,
      width: 250,
      order: 4
    },
    {
      prop: 'roleInProject',
      name: 'ProjectTeam.List.roleInProject',
      componentRef: 'projectContactRoleCellComponent',
      resizeable: false,
      hideOnMobile: true,
      sortable: false,
      width: 200,
      order: 5
    },
    {
      prop: 'function',
      name: 'ProjectContacts.List.function',
      componentRef: 'projectContactFunctionCellComponent',
      sortField: 'function.name',
      resizeable: false,
      hideOnMobile: false,
      width: 250,
      order: 6
    },
    {
      toggleMenu: true,
      component: ProjectContactToggleMenuComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 0,
      sortable: false,
      order: 7
    }
  ],
  url: `${Config.API}/contact/search-advenced?filters[assignedProjects][]=[{projectId}]`,
  scrollbarH: true,
  listName: 'project-contacts-list',
  responseKey: 'records',
  listTitle: 'ProjectContacts.title',
  defaultSort: {
    sortDirection: 'DESC',
    sortField: 'projectContactId'
  }
};
