import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskType } from '@shared/enums/task-type.enum';
import { Task } from '@shared/models/task.model';

@Component({
  selector: 'task-type-dropdown',
  templateUrl: './task-type-dropdown.component.html',
})
export class TaskTypeDropdownComponent implements OnInit {
  TaskTypes = TaskType;
  id: string = 'dropDown';

  @Input() task: Task = null;
  @Output() typeEmitter: EventEmitter<{ item: Task; type: TaskType }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  changeType(item: Task, type: TaskType) {
    this.typeEmitter.emit({ item, type });
  }
}
