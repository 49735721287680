import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '@shared/components/base.component';
import {NotificationsService} from '@modules/notification/shared/services/notifications.service';
import {publicFile} from '@shared/helpers/public-file.helper';
import {StorageEvent, StorageEventType} from '@core/services/storage.service';
import {Company} from '@shared/models/company.model';
import {Employee} from '@shared/models/employee.model';

@Component({
  selector: 'header-action-menu-user',
  templateUrl: './header-action-menu-user.component.html',
  styleUrls: ['./header-action-menu-user.component.scss']
})
export class HeaderActionMenuUserComponent extends BaseComponent implements OnInit {
  @Input() isExpanded = true;

  imageUrl: string = '';

  constructor(
    private notificationsService: NotificationsService,
    private changes: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setImageUrl();
    this.initListener();
  }

  initListener() {
    this.sub = this.store.emmiter.subscribe((e: StorageEvent) => {
      switch (e.type) {
        case StorageEventType.UPDATE_USER_PERSON:
          this.store.UserPerson = e.userPerson;
          this.userPerson = e.userPerson;
          this.setImageUrl();
          break;
      }
      this.changes.detectChanges();
    });
  }

  logout() {
    this.store.logout();
    this.notificationsService.stopInterval();
    this.n.navigate('login', {}, {}, true);
    this.s.success(this.t.instant('loggedOut'));
  }

  setImageUrl() {
    const userPerson = this.store.UserPerson;
    userPerson.photo ? (this.imageUrl = publicFile(userPerson.photo, 100)) : null;
    this.changes.detectChanges();
  }

}
