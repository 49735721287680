import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { WindowHelper } from '@shared/helpers/window.helper';
import { Languages } from '@core/services/language.service';
import { debounce } from '@shared/decorators/debounce.decorator';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { ListService } from '@shared/modules/list/services/list.service';

@Component({
  selector: 'list-search-v2',
  templateUrl: './list-search-v2.component.html',
  styleUrls: ['./list-search-v2.component.scss'],
})
export class ListSearchV2Component implements OnInit, OnDestroy {
  WindowHelper = WindowHelper;
  Languages = Languages;

  private subject: Subject<string> = new Subject();
  private destroy$: Subject<string> = new Subject();

  form: FormGroup;

  @Input() searchName: string;

  @HostListener('window:resize')
  @debounce(50)
  resize() {
    this.changes.detectChanges();
  }

  constructor(private service: ListService, private changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createForm();
    this.debounceSearch();
    this.listenQueryParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  debounceSearch() {
    this.subject.pipe(debounceTime(500)).subscribe((e) => {
      this.service.setSearch(this.form.get('search').value);
    });
  }

  listenQueryParams() {
    this.service.eventEmitter.pipe(takeUntil(this.destroy$)).subscribe((e: ListEvent<any>) => {
      if (e.type === ListEventType.QUERY_PARAMS && e.data.search) {
        this.form.get('search').setValue(e.data.search);
      }
    });
  }

  createForm() {
    this.form = new FormGroup({
      search: new FormControl(''),
    });
    this.service?._search.length ? this.form.get('search').setValue(this.service._search) : null;
  }

  onChange() {
    this.subject.next();
  }
}
