import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConfirmationPopoverOptions } from '@shared/libs/angular-confirmation-directive/confirmation-popover-options.provider';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import parsePhoneNumber from 'libphonenumber-js';

@Component({
  selector: 'contact-quick-information-popover',
  templateUrl: './contact-quick-information-popover.component.html',
  styleUrls: ['./contact-quick-information-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactQuickInformationPopoverComponent implements OnInit {
  ContactType = ContactType;

  formattedPhone: string = '';
  @Input() contact: Contact;
  @Input() options: ConfirmationPopoverOptions;
  @Input() class: string;

  constructor(
    private changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.formatPhoneNumber();
    this.changes.detectChanges();
  }

  formatPhoneNumber() {
    const phoneNumber = parsePhoneNumber(this.contact.fullPhone);
    phoneNumber ? this.formattedPhone = phoneNumber.formatInternational() : this.formattedPhone = this.contact.fullPhone;
  };
}
