import { Employee } from '@shared/models/employee.model';
import { BaseUserPerson } from '@shared/models/base-user-person.model';

export class UserPerson extends BaseUserPerson {
  employees: Employee[];
  helperID?: number;
  showDoneTasks?: boolean;

  constructor(data?: any) {
    super(data);
    data ? Object.assign(this, data) : null;
    this.setFullPhone();
  }
}

export enum UserRole {
  ROLE_USER = 'ROLE_USER',
}
