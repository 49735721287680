<div class="content-with-tabs content-with-tabs-menu">
  <div [ngClass]="{ 'd-none d-md-block': hideTopTabIconOnMobile }" *ngIf="tabsTemplate">
    <ng-container *ngIf="tabsTemplate" [ngTemplateOutlet]="tabsTemplate"></ng-container>
  </div>

  <div
    class="content-with-tabs-menu-description"
    [ngClass]="{ 'd-none d-md-block': hideTopTabIconOnMobile }"
    *ngIf="tabsDescriptionTemplate"
  >
    <ng-container *ngIf="tabsDescriptionTemplate" [ngTemplateOutlet]="tabsDescriptionTemplate"></ng-container>
  </div>

  <div
    *ngIf="tabsDescription"
    class="content-with-tabs-menu-description"
    [innerHTML]="tabsDescription"
    [ngClass]="{ 'd-none d-md-block': hideTopTabIconOnMobile }"
  ></div>

  <div class="content-with-tabs-menu-tabs" [ngClass]="menuTabsClass">
    <div [hidden]="!(!selectTabsOnMobile || (selectTabsOnMobile && !isMobile))">
      <nav mat-tab-nav-bar>
        <a
          draggable="false"
          mat-tab-link
          (click)="scrollToContent(tabLink.link)"
          [routerLinkActive]="['active']"
          [routerLink]="[tabLink.link]"
          [queryParams]="tabLink.queryParams"
          [permission]="
            tabLink?.permissionGroup && tabLink?.permissionAction
              ? {
                  group: tabLink?.permissionGroup,
                  action: tabLink?.permissionAction,
                  objectCreatorId: tabLink?.objectCreatorId || null
                }
              : {}
          "
          *ngFor="let tabLink of tabLinks"
        >
          <span *ngIf="tabLink?.iconUrl" class="content-tab-icon">
            <svg-icon [src]="tabLink?.iconUrl"></svg-icon>
          </span>
          <span>{{ tabLink.name }}</span>
        </a>
      </nav>
    </div>

    <div [hidden]="!(selectTabsOnMobile && isMobile)">
      <div class="d-flex justify-content-center align-items-center px-2 py-3">
        <ng-select
          #selectTab
          ngSelectExtension
          class="select select-default w-100"
          [dropdownPosition]="'bottom'"
          [items]="tabLinks"
          [clearable]="false"
          [searchable]="false"
          [bindLabel]="'name'"
          [bindValue]="'link'"
          [placeholder]="'Projects.tabs' | translate"
          (change)="onTabClick($event)"
          [tabIndex]="-1"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
