<modal-title
  [title]="'ProjectSummary.RightSection.alertTitle' | translate"></modal-title>

<form [formGroup]="form" class="project-alerts-modal">
  <mat-dialog-content>
    <p class="mt-2">
      {{ 'ProjectSummary.RightSection.alertsDescription' | translate }}
    </p>
    <ng-container formArrayName="alerts">
      <div class="project-alerts-modal__alert-record" *ngFor="let control of alertsForms.controls; let i = index">
        <div class="project-alerts-modal__alert-record__name"
             [innerHTML]="'ProjectSummary.RightSection.alertName' | translate"></div>
        <div class="project-alerts-modal__alert-record__input">
          <gbxsoft-input
            [errMessages]="errorMessages(control)"
            #input
            [formControl]="control"
            [config]="{
            name: 'ProjectSummary.RightSection.setPercent' | translate,
            type: 'number',
            appendContent: '%'
          }"
          ></gbxsoft-input>
        </div>
        <div class="project-alerts-modal__alert-record__remove" (click)="removeAlert(i)">
          <svg-icon [src]="'/assets/img/ic-trash-outline.svg'" [svgStyle]="{width: '16px', height: '16px'}"
                    class="ic-remove"></svg-icon>
        </div>
      </div>
    </ng-container>
    <link-button
      *ngIf="alertsForms?.length < 5"
      [class]="'mt-3'"
      [name]="'ProjectSummary.RightSection.addAlert' | translate"
      [iconUrl]="'/assets/img/ic-plus-add.svg'"
      (onClick)="addAlert()"
    ></link-button>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">

      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'ProjectSummary.RightSection.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'ProjectSummary.RightSection.saveAlerts' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-save.svg'
      }">
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
