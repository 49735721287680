import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { DateFormatPipe } from '@shared/pipes/dateFormat.pipe';
import { ProtocolAction } from '../../enums/protocol-action.enum';
import { Protocol } from '../../models/protocol';
import { ProtocolApiService } from '../../services/protocol-api.service';
import { HttpError } from '@shared/interfaces/error.interface';

@Component({
  selector: 'protocol-acceptance',
  templateUrl: './protocol-acceptance.component.html',
})
export class ProtocolAcceptanceComponent implements OnInit {
  ProtocolAcceptanceType = ProtocolAcceptanceType;
  form: FormGroup;
  protocol: Protocol;
  type: ProtocolAcceptanceType;
  loading: boolean;
  id: number;

  constructor(
    public dialogRef: MatDialogRef<ProtocolAcceptanceComponent>,
    public service: ClientAcceptanceService,
    public pService: ProtocolApiService,
    private dateFormatPipe: DateFormatPipe,
    private t: TranslateService,
    private s: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: { protocol: Protocol; type: ProtocolAcceptanceType; id: number },
  ) {
    this.protocol = new Protocol(data.protocol);
    this.type = this.data.type;
    this.id = data.id;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      accepted: new FormControl(this.type),
      note: new FormControl(''),
    });
  }

  get title() {
    return `${this.t.instant('Protocols.Types.' + this.protocol.type)}, ${this.dateFormatPipe.transform(
      this.protocol.created,
    )}`;
  }

  closeModal() {
    this.form.reset();
    this.dialogRef.close();
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      return;
    }
    this.loading = true;

    this.service
      .sentAcceptance(
        this.id ? this.id.toString() : this.protocol.lastAcceptance.id.toString(),
        this.form.value,
        false,
      )
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Protocols.protoclAccepted'));
          this.pService.manager.protocolAction.emit({ type: ProtocolAction.RELOAD_LIST });
          this.form.reset();
          this.dialogRef.close();
        },
        error: (error: HttpError) => {
          if (error.messageCode === 'intilio_24') {
            this.s.error(this.t.instant('Protocols.protocolAlreadyAccepted'));
          } else {
            this.s.error(this.t.instant('Protocols.errorProtoclAccepted'));
          }
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  get errorMessages() {
    return Config.validationMessages;
  }
}

export enum ProtocolAcceptanceType {
  DECLINE,
  ACCEPT,
}
