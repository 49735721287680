<!-- Desktop header -->
<header class="d-flex align-items-center header-desktop" #header [@openClose]="isExpanded">
  <nav class="h-100 w-100">
    <div class="nav-items">
      <div class="logo-wrapper" [ngClass]="{ expanded: isExpanded }">
        <header-logo [hidden]="!isExpanded"></header-logo>
      </div>
      <div *ngIf="store.isLogged" class="nav-links">
        <ng-container *ngFor="let tabLink of tabLinks; let index = index">
          <a
            draggable="false"
            [routerLink]="n.getPath(tabLink.link)"
            [routerLinkActive]="['new-link--active']"
            [ngClass]="{ 'new-link--active2': isTimelineRoute(tabLink) }"
            class="new-link"
            *ngIf="!tabLink.hidden"
            data-type="tab"
            id="tab{{ index }}"
            ><svg-icon [src]="tabLink.iconUrl" [title]="tabLink.name | translate"></svg-icon>
            <span
              [innerHTML]="tabLink.name | translate"
              [hidden]="!isExpanded"
              class="new-link__text"
              [ngClass]="{ expanded: isExpanded }"
            ></span>
          </a>
        </ng-container>
      </div>
      <div *ngIf="store.isLogged" class="nav-bottom">
        <button
          id="createMenu"
          *ngIf="!employee?.isClient"
          [matMenuTriggerFor]="createMenu"
          class="new-link new-link__btn"
        >
          <svg-icon src="/assets/img/plus-icon.svg"></svg-icon>
          <span class="new-link__text" [ngClass]="{ expanded: isExpanded }" [hidden]="!isExpanded">{{
            'Header.create' | translate
          }}</span>
        </button>
        <div>
          <header-action-menu-user [isExpanded]="isExpanded"></header-action-menu-user>
        </div>
      </div>
      <div *ngIf="!store.isLogged" class="nav-bottom">
        <button
          class="new-link new-link__btn new-link__btn--white"
          (click)="n.go('login'); !!headerService.isMenuOpen ? headerService.closeMenu() : null"
        >
          <svg-icon src="/assets/img/new-arrow-right.svg"></svg-icon>
          <span
            class="new-link__text new-link__text--blue"
            [ngClass]="{ expanded: isExpanded }"
            [hidden]="!isExpanded"
            >{{ 'Auth.Login.signIn' | translate }}</span
          >
        </button>
      </div>
    </div>
  </nav>
</header>

<!-- Mobile header -->
<header class="header-mobile">
  <nav class="container-fluid w-100">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-between p-0">
        <div class="d-flex align-items-center">
          <header-hamburger></header-hamburger>
          <header-logo (click)="!!headerService.isMenuOpen ? headerService.closeMenu() : null"></header-logo>
        </div>
        <header-action-menu
          (click)="!!headerService.isMenuOpen ? headerService.closeMenu() : null"
          *ngIf="store.isLogged"
        ></header-action-menu>
        <app-button
          *ngIf="!store.isLogged"
          class="mr-2"
          (click)="n.go('login'); !!headerService.isMenuOpen ? headerService.closeMenu() : null"
          [config]="{
            text: 'Auth.Login.signIn' | translate,
            type: ButtonTypes.PRIMARY_WHITE
          }"
        ></app-button>
      </div>
    </div>
  </nav>
</header>

<!-- Hamburger menu -->
<div [@enterAnimation] *ngIf="headerService.isMenuOpen" class="ui-mobile-nav">
  <div class="mobile-navigation-tabs">
    <div class="row justify-content-center">
      <ng-container *ngFor="let tabLink of tabLinks; let index = index">
        <a
          draggable="false"
          [routerLink]="n.getPath(tabLink.link)"
          [routerLinkActive]="['mobile-navigation-tabs-tab--active']"
          class="mobile-navigation-tabs-tab"
          (click)="headerService.closeMenu()"
          data-type="tab"
          id="tab{{ index }}"
        >
          <svg-icon [src]="tabLink.iconUrl"></svg-icon>
          <span [innerHTML]="tabLink.name | translate" class="mobile-navigation-tabs-tab-text"></span>
        </a>
      </ng-container>
    </div>
    <div>
      <div class="mobile-navigation-create">
        <app-button
          [class]="'btn-block font-16'"
          [matMenuTriggerFor]="createMenu"
          [config]="{
            type: ButtonTypes.PRIMARY_GREEN,
            size: ButtonSize.LARGE,
            text: 'Header.create' | translate
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
<mat-menu #createMenu="matMenu" yPosition="above" class="mt-1">
  <button
    [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
    mat-menu-item
    (click)="createContact()"
  >
    <span>{{ 'Header.CreateOptions.contact' | translate }}</span>
  </button>

  <button
    [permission]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
    mat-menu-item
    (click)="createProjectDraft()"
  >
    <span>{{ 'Header.CreateOptions.project' | translate }}</span>
  </button>

  <button
    mat-menu-item
    [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
    (click)="createTask()"
  >
    <span>{{ 'Header.CreateOptions.task' | translate }}</span>
  </button>

  <button
    mat-menu-item
    [permission]="{ group: PermissionsGroups.TASKS, action: 'CHANGE_ADD' }"
    (click)="createChange()"
  >
    <span>{{ 'Header.CreateOptions.change' | translate }}</span>
  </button>

  <button
    mat-menu-item
    [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
    (click)="createEvent()"
  >
    <span>{{ 'CalendarEvents.event' | translate }}</span>
  </button>
  <button
    mat-menu-item
    [permission]="{ group: PermissionsGroups.MAILINGS, action: 'MAILING_FUNCTION' }"
    (click)="createEmail()"
  >
    <span>{{ 'E-mail' | translate }}</span>
  </button>
</mat-menu>
