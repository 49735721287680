<div class="datatable-loader"
     *ngIf="service?.loading"
     [ngClass]="{'datatable-loader--with-records':service?.rows.length}">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  <table>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>


  </table>
</div>
