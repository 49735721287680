import { BaseStorageService } from '@core/services/base-storage.service';
import { Injectable } from '@angular/core';
import { TaskType } from '@shared/enums/task-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectsTimelineStorageService extends BaseStorageService {
  constructor() {
    super();
  }

  get TimelineView(): string {
    const note = this.get('TimelineView') as string;
    return !!note ? note : 'resourceTimelineWeek';
  }

  set TimelineView(timelineView: string) {
    this.put('TimelineView', timelineView);
  }
}
