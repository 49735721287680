<div>
  <div class="project-question-directory__notes-box__title">{{ 'Projects.tasksName' | translate }}</div>
  <div class="mb-4">
    <div class="project-question-directory__notes-task-title mb-2">
      {{ 'Projects.tasksName' | translate }}
      <span class="grey-1 bold">{{ storage?.Employee?.company?.name }}</span>
    </div>
    <task-item
      [snackBottom]="false"
      [preview]="isPreview"
      [editPermission]="editPermission"
      [deletePermission]="editPermission"
      [isTypeSelection]="false"
      [opened]="true"
      [items]="projectStore.projectCtrl?.project?.basicDataBox?.projectTasks"
      [title]="'Projects.tasksName' | translate | uppercase"
      [isStatus]="true"
      [isRealization]="true"
      [isAssignment]="true"
      [type]="TaskType.TYPE_TASK"
      [dropzoneType]="[TaskType.TYPE_TASK]"
      (taskEmitter)="taskEmitter($event, TaskType.TYPE_TASK)"
      (plusClicked)="taskCreate(TaskType.TYPE_TASK)"
    ></task-item>
  </div>
</div>
