import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'error-notifications',
  templateUrl: './error-notifications.component.html',
  styles: [
  ]
})
export class ErrorNotificationsComponent implements OnInit {

  ButtonTypes = ButtonTypes;

  @Output() reload: EventEmitter<any> = new EventEmitter();

  get buttonText() {
    return this.t.instant('Notifications.errorGettingTryAgain');
  }

  constructor(private t: TranslateService) { }

  ngOnInit(): void {
  }

}
