export const PROJECT_DATA_FORM_BOX = {
  id: 'id',
  name: 'name',
  town: 'town',
  country: 'country',
  postalCode: 'postalCode',
  address: 'address',
  addressType: 'addressType',
  propertyType: 'propertyType',
  propertyTypeName: 'propertyTypeName',
  propertyAge: 'propertyAge',
  heatingType: 'heatingType',
  electricityType: 'electricityType',
  ownershipType: 'ownershipType',
  addressNote: 'addressNote',
  floor: 'floor',
  floorsCount: 'floorsCount',
  nameOnDoorbell: 'nameOnDoorbell',
  hasParking: 'hasParking',
  parkingDescription: 'parkingDescription',
  hasBasement: 'hasBasement',
  basementDescription: 'basementDescription',
  hasElevator: 'hasElevator',
  elevatorDescription: 'elevatorDescription',
  termStart: 'termStart',
  termEnd: 'termEnd',
  workToDo: 'workToDo',
  budget: 'budget',
  area: 'area',
  clientComment: 'clientComment',
  responsibleEmployeeId: 'responsibleEmployeeId',
  mainContactId: 'mainContactId',
  contactsIds: 'contactsIds',
  isResponsible: 'isResponsible',
};
