import { ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ContactManageLeaveComponent} from '@modules/contacts/shared/components/contact-manage-leave/contact-manage-leave.component';
import {ContactManageComponent} from '@modules/contacts/pages/contact-manage/contact-manage.component';
import {Config} from '@shared/configs/config';

@Injectable({providedIn: 'root'})

export class ContactManageDeactivateGuard implements CanDeactivate<ContactManageComponent> {
  constructor(
    private dialogRef: MatDialog
  ) {
  }

  canDeactivate(component: ContactManageComponent, currentRoute: ActivatedRouteSnapshot) {
    if (!component.hasChanges) {
      return true;
    }
    const dialog = this.dialogRef.open(ContactManageLeaveComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false
    });
    dialog.componentInstance.currentRoute = currentRoute;
    return dialog.componentInstance.leaveEmitter.asObservable();

  }
}
