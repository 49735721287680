import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFooterComponent } from './components/list-footer/list-footer.component';
import { ListPagerComponent } from './components/list-pager/list-pager.component';
import { ListComponent } from './list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { ListService } from './services/list.service';
import { ListUrlController } from './controllers/list-url.controller';
import { ListToggleMenuComponent } from './components/list-toggle-menu/list-toggle-menu.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListEmptyStatesComponent } from './components/list-empty-states/list-empty-states.component';
import { ListSingleFilterDateComponent } from './components/list-filter/components/list-single-filter-date/list-single-filter-date.component';
import { ListSingleFilterSelectComponent } from './components/list-filter/components/list-single-filter-select/list-single-filter-select.component';
import { ListSingleFilterComponent } from './components/list-filter/components/list-single-filter/list-single-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListSingleFilterCheckboxComponent } from './components/list-filter/components/list-single-filter-checkbox/list-single-filter-checkbox.component';
import { ListSingleClearFiltersComponent } from './components/list-filter/components/list-single-clear-filters/list-single-clear-filters.component';
import { ListTreeActionComponent } from './components/list-tree-action/list-tree-action.component';
import { ListCellCreatorComponent } from './components/list-cell-creator/list-cell-creator.component';
import { SharedModule } from '@shared/shared.module';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { UIModule } from '@shared/modules/ui/ui.module';
import { ListSingleFilterRangeDateComponent } from '@shared/modules/list/components/list-filter/components/list-single-filter-range-date/list-single-filter-range-date.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ListTasksComponent } from './components/list-tasks/list-tasks.component';
import { ListTasksGroupComponent } from './components/list-tasks/components/list-tasks-group/list-tasks-group.component';
import { ListTasksSingleTaskComponent } from './components/list-tasks/components/list-tasks-single-task/list-tasks-single-task.component';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { ConfirmationPopoverModule } from '@shared/libs/angular-confirmation-directive/confirmation-popover.module';
import { ListGroupTranslatePipe } from '@shared/modules/list/pipes/list-group-translate.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ListLoaderComponent } from './components/list-loader/list-loader.component';
import { ListCheckboxComponent } from './components/list-checkbox/list-checkbox.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { DndModule } from 'ngx-drag-drop';
import { ListSingleFilterSelectV2Component } from './components/list-filter/components/list-single-filter-select-v2/list-single-filter-select-v2.component';
import { ListSingleFilterV2Component } from './components/list-filter/components/list-single-filter-v2/list-single-filter-v2.component';
import { ListSingleFilterToggleComponent } from './components/list-filter/components/list-single-filter-toggle/list-single-filter-toggle.component';
import { ListSingleClearFiltersV2Component } from './components/list-filter/components/list-single-clear-filters-v2/list-single-clear-filters-v2.component';
import { ListSingleFilterRangeDateV2Component } from './components/list-filter/components/list-single-filter-range-date-v2/list-single-filter-range-date-v2.component';
import { ListChipsActiveFiltersComponent } from './components/list-chips-active-filters/list-chips-active-filters.component';
import { ListChipsComponent } from './components/list-filter/components/list-chips/list-chips.component';

@NgModule({
  declarations: [
    ListFooterComponent,
    ListPagerComponent,
    ListComponent,
    ListToggleMenuComponent,
    ListFilterComponent,
    ListEmptyStatesComponent,
    ListSingleFilterDateComponent,
    ListSingleFilterSelectComponent,
    ListSingleFilterSelectV2Component,
    ListSingleFilterComponent,
    ListSingleFilterV2Component,
    ListSingleFilterCheckboxComponent,
    ListSingleFilterToggleComponent,
    ListSingleClearFiltersComponent,
    ListSingleClearFiltersV2Component,
    ListTreeActionComponent,
    ListCellCreatorComponent,
    ListHeaderComponent,
    ListSingleFilterRangeDateComponent,
    ListSingleFilterRangeDateV2Component,
    ListTasksComponent,
    ListTasksGroupComponent,
    ListTasksSingleTaskComponent,
    ListGroupTranslatePipe,
    ListLoaderComponent,
    ListCheckboxComponent,
    ListChipsActiveFiltersComponent,
    ListChipsComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UIModule,
    CommonModule,
    TranslateModule,
    ConfirmationPopoverModule,
    NgxDatatableModule,
    NgSelectModule,
    ScrollingModule,
    NgxPrettyCheckboxModule,
    MatProgressBarModule,
    // #region drag drop
    DndModule,
    SortablejsModule,
    // #endregion
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    ListComponent,
    ListTasksComponent,
    ListTasksSingleTaskComponent,
    ListToggleMenuComponent,
    ListSingleFilterDateComponent,
    ListSingleFilterComponent,
    ListSingleFilterV2Component,
    ListSingleFilterSelectComponent,
    ListSingleFilterSelectV2Component,
    ListSingleFilterCheckboxComponent,
    ListSingleFilterToggleComponent,
    ListSingleClearFiltersComponent,
    ListSingleClearFiltersV2Component,
    ListCellCreatorComponent,
    ListSingleFilterRangeDateComponent,
    ListSingleFilterRangeDateV2Component,
    NgxPrettyCheckboxModule,
    ListGroupTranslatePipe,
    ListChipsComponent,
  ],
  providers: [ListService, ListUrlController, ListGroupTranslatePipe],
})
export class ListModule {}
