<form [formGroup]="form">
  <input
    #inputv2
    class="input-v2"
    [ngClass]="{ 'error-border': isError && errorMessage }"
    [ngStyle]="
      prependSrc
        ? {
            background: 'url(' + prependSrc + ') no-repeat scroll 16px 11px',
            paddingLeft: '42px'
          }
        : {}
    "
    autocomplete="off"
    formControlName="control"
    [type]="type"
    [attr.readOnly]="readonly ? 'readonly' : null"
    [placeholder]="placeholder"
  />

  <span *ngIf="isError" class="form-control-error-msg d-flex mt-2" [innerHTML]="errorMessage"></span>
</form>
