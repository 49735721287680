import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '@shared/modules/list/services/list.service';
import { ProtocolsListConfig } from '@modules/protocols/shared/configs/protocols-list.config';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'project-protocols',
  templateUrl: './project-protocols.component.html',
  styleUrls: ['./project-protocols.component.scss']
})
export class ProjectProtocolsComponent implements OnInit, OnDestroy {

  constructor(
    public projectPreviewService: ProjectPreviewService,
    public route: ActivatedRoute,
    private listService: ListService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.listService.clearService();
    ProtocolsListConfig.url = `${Config.API}/protocol`;
  }
}
