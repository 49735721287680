import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NotificationsService} from '@modules/notification/shared/services/notifications.service';

@Component({
  selector: 'header-action-menu-notification',
  templateUrl: './header-action-menu-notification.component.html'
})
export class HeaderActionMenuNotificationComponent implements OnInit {

  constructor(
    public notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {
  }

}
