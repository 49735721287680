<form [formGroup]="form" class="task-modal-main">
  <div class="task-modal-main-container">
    <!-- #region Employees -->
    <div [hidden]="!(!isNote && !isClient)">
      <!-- <employee-search
        (setUsers)="setUsers($event)"
        #assingedSelect
        [initialValue]="assignedUsers"
        [form]="form"
      ></employee-search> -->
    </div>

    <!-- #endregion -->

    <!--#region daterangepicker-->

    <ng-container *ngIf="isCorrection">
      <div class="discount-popover p-0 d-flex align-items-center justify-content-between mb-1">
        <h3 class="mb-0" [innerHTML]="'Protocols.realizationTerm' | translate"></h3>
        <help-icon [message]="'Protocols.termtDescr' | translate"></help-icon>
      </div>
    </ng-container>
    <daterangepicker
      *ngIf="!isNote"
      #datepicker
      [isCalendarTime]="true"
      [interactive]="true"
    ></daterangepicker>
    <!-- #endregion -->

    <!-- #region Status -->
    <ng-select
      [items]="taskStatusList"
      class="select select-default w-100 mb-3"
      [searchable]="false"
      [clearable]="false"
      [multiple]="false"
      [bindLabel]="'label'"
      [bindValue]="'id'"
      [placeholder]="'Protocols.status' | translate"
      [notFoundText]="'Protocols.notFoundText' | translate"
      [tabIndex]="-1"
      formControlName="status"
    >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span [innerHTML]="item.label" [title]="item.label"></span>
      </ng-template>
    </ng-select>
    <!-- #endregion -->

    <div class="mb-4 discount">
      <discount #discountTempl [hidden]="!isCorrection" [interactive]="true"> </discount>
    </div>

    <div [hidden]="!isChange">
      <div class="mb-4">
        <task-selection-change
          #financeTempl
          [message]="'Protocols.financeInfo' | translate"
          [items]="financial"
          [selectConfig]="{ placeholder: 'Protocols.selectType' | translate }"
          [title]="'Protocols.describeFinancialChange' | translate"
          [disabledInput]="isFinanceDisabled"
          [config]="{
            name: 'Protocols.enterChangeValue' | translate,
            type: 'price',
            appendContent: storage?.Company?.currency?.symbol
          }"
          (changeEvent)="influenceChangeEvent($event, 'financeTempl', 'currentFinanceState')"
        >
        </task-selection-change>
      </div>
      <div class="mb-4">
        <task-selection-change
          #daysTempl
          [message]="'Protocols.daysInfo' | translate"
          [items]="days"
          [selectConfig]="{ placeholder: 'Protocols.selectType' | translate }"
          [title]="'Protocols.describeTime' | translate"
          [disabledInput]="isDateDisabled"
          [config]="{ name: 'Protocols.enterDaysCount' | translate, type: 'number' }"
          (changeEvent)="influenceChangeEvent($event, 'daysTempl', 'currentDateState')"
        >
        </task-selection-change>
      </div>
    </div>

    <!-- #region Content -->
    <div class="mb-3">
      <div class="relative">
        <quill-editor
          [placeholder]="'Protocols.description' | translate"
          [formControlName]="'content'"
        ></quill-editor>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region Visibility -->
    <div class="form-control-checkbox" *ngIf="!isClient">
      <label class="m-0 d-flex align-items-center">
        <input type="checkbox" formControlName="hiddenForClient" />
        <span></span>
        <span [innerHTML]="'Protocols.hiddenFromClient' | translate"></span>
      </label>
    </div>
    <!-- #endregion -->
  </div>
</form>
