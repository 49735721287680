<modal-title
  [title]="'Projects.MainContact.title' | translate"></modal-title>

<form [formGroup]="form" class="project-main-contact-modal">
  <mat-dialog-content
    [ngClass]="{'project-main-contact-modal--min-height' : contactSelect?.select?.isOpen || responsibleSelect?.select?.isOpen}">
    <div class="mt-3">
      <label class="grey-1"><b>{{'Projects.MainContact.client' | translate}}</b></label>
      <ng-select
        #contactSelect="ngSelectExtensionDir"
        ngSelectExtension
        class="select select-default w-100"
        [dropdownPosition]="'bottom'"
        [addTag]="getCheckPermissionController().check() ? addNewContactAndAssign.bind(this) : false"
        [url]="contactUrl"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [notFoundText]="'Projects.MainContact.notFound' | translate"
        [placeholder]="'Projects.MainContact.chooseContact' | translate"
        [tabIndex]="-1"
        [addTagText]="'Protocols.add' | translate"
        formControlName="mainContact">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <contact-option-template [contact]="item" [isLabel]="true"></contact-option-template>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <contact-option-template [contact]="item"></contact-option-template>
        </ng-template>
      </ng-select>
    </div>
    <div class="mt-3">
      <label class="grey-1"><b>{{'Projects.MainContact.responsiblePerson' | translate}}</b></label>
      <ng-select
        #responsibleSelect="ngSelectExtensionDir"
        ngSelectExtension
        class="select select-default w-100"
        [dropdownPosition]="WindowHelper.isMobileWidth ? 'top' : 'bottom'"
        [url]="responsibleUrl"
        [addTag]="getCheckPermissionController().check() ? addNewContact.bind(this) : false"
        [addTagText]="'Projects.MainContact.addContact' | translate"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [notFoundText]="'Projects.MainContact.notFound' | translate"
        [placeholder]="'Projects.MainContact.chooseContact' | translate"
        [tabIndex]="-1"
        [addTagText]="'Protocols.add' | translate"
        formControlName="responsible">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <contact-option-template [contact]="item" [isLabel]="true"></contact-option-template>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
          <contact-option-template [contact]="item"></contact-option-template>
        </ng-template>
      </ng-select>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">

      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'ProjectSummary.RightSection.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'ProjectSummary.RightSection.saveAlerts' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-save.svg'
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
