import { SummaryAmountDialogComponent } from '@project-modules/project-changes/pages/components/summary-amount-dialog/summary-amount-dialog.component';
import {
  ESummaryBoxStyle,
  ISummaryBox,
  ISummaryBoxCosts,
} from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent implements OnInit {
  @Input() summary: ISummaryBox;

  get isBoxBlue() {
    return ESummaryBoxStyle.BLUE === this.summary?.style;
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openChangeDialog(summary: ISummaryBoxCosts) {
    this.dialog.open(SummaryAmountDialogComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: summary,
    });
  }
}
