<modal-title [title]="'ProjectTeam.Manage.editModalTitle' | translate"></modal-title>
<!-- #region Content -->

<form [formGroup]="form" class="project-team-manage">
  <p class="grey-4 mt-4 mb-4" [innerHTML]="'ProjectTeam.Manage.editModalDescription' | translate"></p>
  <mat-dialog-content>

    <gbxsoft-input
      [errMessages]="errorMessages('position')"
      [formControlName]="'position'"
      [config]="{ name: 'ProjectTeam.Manage.position' | translate, type: 'text', placeholder: '' }"
    ></gbxsoft-input>

    <gbxsoft-input
      [permission]="{group: PermissionsGroups.PROJECT_TEAM, action: 'HOURLY'}"
      [errMessages]="errorMessages('hourlyRate')"
      [formControlName]="'hourlyRate'"
      [config]="{ name: 'ProjectTeam.Manage.hourlyRate' | translate, type: 'number', appendContent: currencySymbol, placeholder: '' }"
    ></gbxsoft-input>

  </mat-dialog-content>
</form>

<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    (onClick)="dialogRef.close()"
    [class]="'mr-2 bold'"
    [config]="{
      text: 'ProjectTeam.Manage.cancel' | translate,
      type: ButtonTypes.DISCARD_BUTTON,
      size: ButtonSize.LARGE
    }"
  ></app-button>
  <app-button
    [disabled]="loading"
    [loading]="loading"
    (onClick)="submit()"
    [config]="{
      text: 'ProjectTeam.Manage.save' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-save.svg'
    }"
  ></app-button>

</mat-dialog-actions>
<!-- #endregion -->
