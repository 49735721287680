import { SharedModule } from '@shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentsModule } from '../attachments/attachments.module';
import { AssignmentComponent } from './components/task-components/assignment/assignment.component';
import { DiscountComponent } from './components/task-components/discount/discount.component';
import { TaskStatusComponent } from './components/task-components/task-status/task-status.component';
import { TaskEntityComponent } from './components/task-entity/task-entity.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { TaskModalContentPreviewComponent } from './components/task-modal-content-preview/task-modal-content-preview.component';
import { TaskModalContentComponent } from './components/task-modal-content/task-modal-content.component';
import { TaskModalPreviewComponent } from './components/task-modal-preview/task-modal-preview.component';
import { TaskModalComponent } from './components/task-modal/task-modal.component';
import { TaskSelectionChangeComponent } from './components/task-selection-change/task-selection-change.component';
import { TaskTypeDropdownComponent } from './components/task-type-dropdown/task-type-dropdown.component';
import { UIModule } from '@shared/modules/ui/ui.module';
import { DndModule } from 'ngx-drag-drop';
import { TaskShareModalComponent } from './components/task-share-modal/task-share-modal.component';
import { TaskSelectionV2ChangeComponent } from './components/task-selection-change-v2/task-selection-change-v2.component';

export const TASKS_COMPONENTS = [
  TaskItemComponent,
  TaskEntityComponent,
  TaskTypeDropdownComponent,
  AssignmentComponent,
  DiscountComponent,
  TaskStatusComponent,
  TaskSelectionChangeComponent,
  TaskSelectionV2ChangeComponent,
  TaskModalComponent,
  TaskModalContentComponent,
  TaskModalContentPreviewComponent,
  TaskModalPreviewComponent,
  TaskShareModalComponent,
];

export const TASKS_EXPORT = [
  TaskItemComponent,
  DiscountComponent,
  TaskSelectionChangeComponent,
  TaskSelectionV2ChangeComponent,
];
export const TASKS_PROVIDERS = [];

export const TASK_MODULES = [
  CoreModule,
  SharedModule,
  TranslateModule,
  AttachmentsModule,
  DragDropModule,
  DndModule,
  UIModule,
];
