import { TaskInfluence } from '../enums/task-influence.enum';

export const DaysList = [
  {
    id: TaskInfluence.NO_ESTIMATION,
    label: 'Financial.withoutEstimation',
    image: 'ic-question-mark.svg',
  },
  {
    id: TaskInfluence.PLUS,
    label: 'Days.additionalCost',
    image: 'ic-plus-add-blue.svg',
  },
  {
    id: TaskInfluence.MINUS,
    label: 'Days.savedCost',
    image: 'ic-minus.svg',
  },
  {
    id: TaskInfluence.NO_CHANGES,
    label: 'Days.withoutInfluence',
    image: 'ic-zero.svg',
  },
];
