import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
@Directive({
  selector: '[drag-drop]',
})
export class DragDropDirective {
  @Output() filesDropped: EventEmitter<FileList> = new EventEmitter();
  @Input() attachments?: TextAttachment[] = [];
  @Input() attachmentsConfig: TextAttachmentsConfig;

  @HostBinding('class.fileover') fileOver: boolean = false;

  @HostListener('dragover', ['$event']) onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.attachments?.length >= this.attachmentsConfig?.maxAttachments) return;
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
