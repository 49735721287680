<div>
  <main>
    <router-outlet></router-outlet>
  </main>
  <div *ngIf="bottomBarVisible" class="container sticky-footer">
    <div class="col-12 py-2">
      <div class="row justify-content-between">
        <app-button
          class="bold mr-2"
          [disabled]="loading"
          (onClick)="window.history.go(-1)"
          [config]="{
            type: ButtonTypes.DISCARD_BUTTON,
            text: 'Projects.discard' | translate,
            size: ButtonSize.LARGE
          }"
        ></app-button>
        <app-button
          [loading]="loading"
          [disabled]="loading"
          (onClick)="startSaving()"
          [config]="{
            type: ButtonTypes.PRIMARY_BLUE,
            text: (this.isEdit ? 'Contacts.Manage.save' : 'Contacts.Manage.saveAndAdd') | translate,
            size: ButtonSize.LARGE,
            prependSrc: '/assets/img/ic-save.svg'
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
