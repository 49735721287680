import { CalendarApi, EventApi } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { ProjectsTimelineEventController } from './projects-timeline-event.controller';
import { ResourceApi } from '@fullcalendar/resource-common';
import * as moment from 'moment';

export class ProjectsTimelineResourceController {
  t: TranslateService;
  eventController: ProjectsTimelineEventController;

  constructor(private calendar: CalendarApi) {
    this.t = AppInjector.getInjector().get(TranslateService);
    this.eventController = new ProjectsTimelineEventController(this.calendar);
  }

  addProjectsResources(projects: Project[]) {
    for (let i = 0; i < projects.length; i++) {
      const project = new Project(projects[i]);
      this.addProjectResource(project);
    }
  }

  addStagesResources(stages: IProjectStage[]) {
    for (let i = 0; i < stages.length; i++) {
      this.addStageResource(stages[i]);
    }
  }

  updateResourcesAfterEventChange(project: Project, newStage: ProjectStage, projectEvent: EventApi) {

    const projectResource: ResourceApi = this.calendar.getResourceById(project.id.toString());
    const newStageResource: ResourceApi = this.calendar.getResourceById(newStage);
    const oldStageResource: ResourceApi = this.calendar.getResourceById(project.stage);
    !projectEvent ? projectEvent = this.calendar.getEventById(project.id.toString()) : '';

    projectResource.setProp('parentId', newStage);

    newStageResource.setExtendedProp(
      'projectsCount',
      newStageResource._resource.extendedProps.projectsCount + 1,
    );

    oldStageResource.setExtendedProp(
      'projectsCount',
      oldStageResource._resource.extendedProps.projectsCount - 1,
    );

    if (newStageResource._resource.extendedProps.opened) {
      projectEvent?.setExtendedProp('stage', newStage);
      projectEvent?.setResources([project.id.toString()]);
      projectEvent?.setProp('classNames', 'fc-event--' + newStage + ' fc-event--project-event');
      this.eventController.updateOpenedStageEvent(newStage);
    } else {
      this.eventController.updateClosedStageEvent(
        newStage,
        projectEvent?.start ? moment(projectEvent?.start) : null,
        projectEvent?.end ? moment(projectEvent?.end) : null,
      );
      projectResource.remove();
      projectEvent?.remove();
    }

    oldStageResource._resource.extendedProps.projectsCount === 0
      ? oldStageResource.setExtendedProp('opened', false)
      : null;

    oldStageResource._resource.extendedProps.opened
      ? this.eventController.updateOpenedStageEvent(project.stage)
      : this.eventController.updateClosedStageEvent(project.stage, null, null);
  }

  private addProjectResource(project: Project) {
    if (this.calendar.getResourceById(project.id.toString())) {
      return;
    }
    this.calendar.addResource({
      parentId: project.stage,
      id: project.id.toString(),
      title: project.fullName,
      extendedProps: project,
    });
  }

  private addStageResource(stage: IProjectStage) {
    if (this.calendar.getResourceById(stage.stage)) {
      return;
    }
    this.calendar.addResource({
      id: stage.stage,
      title: this.t.instant('Projects.Stage.' + stage.stage),
      opened: false,
      extendedProps: stage,
    });
  }

  updateProjectResource(project: Project) {
    const projectResource = this.calendar.getResourceById(project.id.toString());
    projectResource.setExtendedProp('basicDataBox', project.basicDataBox);
    projectResource.setExtendedProp('town', project.town);
    projectResource.setExtendedProp('postalCode', project.postalCode);
    projectResource.setExtendedProp('country', project.country);
    projectResource.setExtendedProp('fullAddress', project.fullAddress);
    projectResource.setExtendedProp('fullName', project.fullName);
    projectResource.setExtendedProp('creator', project.creator);
    projectResource.setExtendedProp('projectId', project.projectId);
    projectResource.setExtendedProp('stage', project.stage);
    projectResource.setProp('parentId', project.stage);
  }

  removeProjectsResourcesFromStage(stage: ProjectStage) {
    const resources = this.calendar.getResources();

    for (let i = 0; i < resources.length; i++) {
      const r = resources[i];
      if (r._resource.parentId === stage) {
        r.remove();
      }
    }
  }

  removeAllResources() {
    this.calendar.setOption('resources', []);
  }
}
