<div
  *ngIf="!content"
  class="text-attachments-control"
  [ngClass]="{
    'text-attachments-control__double-attachments': isDoubleAttachments,
    hasFocus: input?.isFocused
  }"
  drag-drop
  (filesDropped)="onFilesChange($event)"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
  #textAttachmentControl
>
  <div class="text-attachments-control-drag-drop align-items-center justify-content-center">
    {{ 'Global.dragDropFileText' | translate }}
  </div>

  <div class="text-attachments-control__text-line">
    <form [tourAnchor]="inputTour" [formGroup]="form">
      <gbxsoft-input
        *ngIf="attachmentsConfig.showText"
        formControlName="{{ textName }}"
        [config]="config"
        [disabled]="disabled"
        #input
      ></gbxsoft-input>
    </form>

    <app-button
      [tourAnchor]="buttonTour"
      *ngIf="
        attachments.length < attachmentsConfig.maxAttachments ||
        (!attachmentsConfig.maxAttachments && !onlyTextInteraction)
      "
      (onClick)="addFile()"
      [disabled]="fileLoading || disabled"
      [loading]="fileLoading"
      [config]="{
        text: buttonText,
        prependSrc: '/assets/img/' + imageSrc,
        type: ButtonTypes.SECONDARY_BLUE
      }"
    ></app-button>

    <input
      type="file"
      #fileInput
      multiple
      [accept]="acceptFiles()"
      (click)="markAsTouched()"
      (change)="onFilesChange()"
    />
  </div>
</div>
<img draggable="false" src="/assets/img/ic-file-plus-white.svg" class="d-none" />

<!-- V2 below -->

<div
  *ngIf="content"
  class="w-100"
  drag-drop
  (filesDropped)="onFilesChange($event)"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
>
  <div>
    <form [tourAnchor]="inputTour" [formGroup]="form">
      <input
        class="custom-input mb-2"
        *ngIf="attachmentsConfig.showText"
        [formControlName]="textName"
        [placeholder]="config.placeholder"
        [disabled]="disabled"
        #input
      />
    </form>
    <div *ngIf="attachments.length" class="mb-3">
      <div
        class="text-attachments-control__attachments-line_files d-flex"
        [ngClass]="{
          'text-attachments-control__attachments-line_files__notext': !attachmentsConfig.showText
        }"
      >
        <ng-container *ngFor="let item of attachments">
          <div
            [title]="item?.name"
            class="text-attachments-control-item text-attachments-control-item__file"
            *ngIf="item.type === AttachmentType.FILE"
          >
            <div class="text-attachments-control-item__file-icon">
              <img draggable="false" src="/assets/img/ic-attach.svg" alt="File" draggable="false" />
            </div>
            <div class="text-attachments-control-item__file-name">
              {{ item.name }}
            </div>
            <div
              *ngIf="!disabled && !onlyTextInteraction"
              class="text-attachments-control-item__file-trash"
              (click)="removeItem(item)"
            >
              <img draggable="false" src="/assets/img/ic-close-red.svg" alt="Remove" />
            </div>
          </div>
        </ng-container>
      </div>
      <div class="text-attachments-control__attachments-line_photos d-flex align-content-center">
        <ng-container *ngFor="let item of attachments">
          <div
            class="text-attachments-control-item text-attachments-control-item__photo"
            *ngIf="item.type === AttachmentType.PHOTO"
          >
            <div
              class="text-attachments-control-item__photo-thumb"
              [ngStyle]="{ 'background-image': 'url(' + (item?.url ? item.url : item?.content) + ')' }"
            ></div>
            <div
              *ngIf="!disabled && !onlyTextInteraction"
              class="text-attachments-control-item__photo-trash"
              (click)="removeItem(item)"
            >
              <img
                draggable="false"
                src="/assets/img/ic-trash-outline-white.svg"
                alt="Remove"
                draggable="false"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <input
      type="file"
      #fileInput
      multiple
      [accept]="acceptFiles()"
      (click)="markAsTouched()"
      (change)="onFilesChange()"
    />
  </div>
  <div class="w-100 d-flex flex-row justify-content-between">
    <app-button
      [tourAnchor]="buttonTour"
      *ngIf="
        attachments.length < attachmentsConfig.maxAttachments ||
        (!attachmentsConfig.maxAttachments && !onlyTextInteraction)
      "
      (onClick)="addFile()"
      [disabled]="fileLoading || disabled"
      [loading]="fileLoading"
      [config]="{
        text: 'Commentaries.addAttachment' | translate,
        type: ButtonTypes.NEW_SECONDARY_BLUE
      }"
    ></app-button>
    <ng-content></ng-content>
  </div>
</div>
