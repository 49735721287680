import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss']
})
export class FullPageLoaderComponent implements OnInit {

  @Input() cover: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
