import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';

export class ProjectStatistics {
  changesStats: { accepted: number, rejected: number, to_accept: number, total: number };
  changesSummary: {
    additionalTimeSum: number,
    changesBalance: number,
    decreasingChangesSum: number,
    discountsSum: number,
    increasingChangesSum: number,
    zeroChangesCount: number,
  };
  protocolsStats: {
    acceptance: number,
    accepted: number,
    meeting: number,
    project: number,
    rejected: number,
  };
  tasksStats: {
    completed: number,
    expired: number,
    to_do: number,
    total: number,
  };
  upcomingEvents: CalendarEvent[];

  constructor(data: any) {
    data ? Object.assign(this, data) : '';
    this.deserialize();
  }

  private deserialize() {
    this.upcomingEvents?.length ? this.upcomingEvents = this.upcomingEvents.map((e => new CalendarEvent(e))) : '';
  }
}
