<span
  *ngIf="!customText?.length"
  class="day-cell"
  [ngClass]="{
    'day-cell-grey': _dayValue === 0,
    'day-cell-green': _dayValue > 0,
    'day-cell-red': _dayValue < 0
  }"
  [title]="_day"
  [innerHTML]="_day"
>
</span>

<span
  *ngIf="!!customText?.length"
  class="day-cell day-cell-orange"
  [title]="customText"
  [innerHTML]="customText"
>
</span>
