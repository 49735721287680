import { Component, OnInit, Input, TemplateRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { debounce } from '@shared/decorators/debounce.decorator';
import { MediaQuery } from '@shared/configs/media-query';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'content-with-tabs',
  templateUrl: './content-with-tabs.component.html',
  styleUrls: ['./content-with-tabs.component.scss'],
})
export class ContentWithTabsComponent implements OnInit {
  @Input() tabLinks: TabLink[];
  @Input() tabsDescription: string;
  @Input() tabsDescriptionTemplate: TemplateRef<any>;
  @Input() tabsTemplate: TemplateRef<any>;
  @Input() contentTemplate?: TemplateRef<any>;
  @Input() hideTopTabIconOnMobile: boolean = false;
  @Input() selectTabsOnMobile: boolean = false;

  maxTabsWidth: number;
  @ViewChild('tabs') tabs: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.scroll();
    this.resize();
  }

  @HostListener('window:scroll')
  @debounce(13)
  scroll() {
    if (!this.tabs?.nativeElement) return;

    const pos = this.tabs ? this.tabs.nativeElement.parentElement.getBoundingClientRect() : 0;
    const posTop = pos.y + this.tabs.nativeElement.parentElement.ownerDocument.defaultView.pageYOffset;
    const windowsScrollTop = document.querySelector('.base-panel').scrollTop + 10;
    if (posTop + 1 < windowsScrollTop && window.innerWidth > MediaQuery.MD) {
      this.tabs.nativeElement.style.width = this.maxTabsWidth + 'px';
      this.tabs.nativeElement.classList.add('sticky-top');
      this.tabs.nativeElement.classList.add('position-fixed');
    } else {
      this.tabs.nativeElement.classList.remove('sticky-top');
      this.tabs.nativeElement.classList.remove('position-fixed');
      this.tabs.nativeElement.style.width = null;
    }
  }

  @HostListener('window:resize')
  @debounce(30)
  resize() {
    this.maxTabsWidth = this.tabs.nativeElement.parentElement.offsetWidth - 25;
    this.scroll();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
