<div>
  <main>
    <content-with-tabs
      [tabLinks]="tabLinks"
      [tabsDescription]="'Settings.Menu.description' | translate"
      [tabsTemplate]="settingsTopMenu"
    ></content-with-tabs>
  </main>
  <ng-template #settingsTopMenu>
    <settings-top-menu [showProgressBar]="showProgressBar"></settings-top-menu>
  </ng-template>

  <!-- #region Settings Buttons -->
  <div *ngIf="bottomBarVisible" class="container sticky-footer">
    <div class="col-12 py-2">
      <div class="row justify-content-between">
        <app-button
          class="bold mr-2"
          [disabled]="loading"
          (onClick)="location.back()"
          [config]="{
            type: ButtonTypes.DISCARD_BUTTON,
            text: 'Projects.discard' | translate,
            size: ButtonSize.LARGE
          }"
        ></app-button>
        <app-button
          (onClick)="startSaving()"
          [loading]="loading"
          [disabled]="loading"
          [config]="{
            type: ButtonTypes.PRIMARY_BLUE,
            text: 'Settings.User.saveUserData' | translate,
            size: ButtonSize.LARGE,
            prependSrc: '/assets/img/ic-save.svg'
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
