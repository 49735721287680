import { Task } from '@shared/models/task.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class SubtasksService extends TaskService {
  parentId: number;
  parentTask: Task;
  type: TaskType;
  taskList: Task[] = [];
  disabled: boolean = false;

  taskAdd: EventEmitter<Task> = new EventEmitter();

  constructor() {
    super();
  }
}
