import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from '@shared/services/multi-translate-http-loader.service';

export function HttpLoaderFactory(http: HttpClient) {
  const prefix = './assets/i18n/';
  return new MultiTranslateHttpLoader(http, [
    { prefix: `${prefix}`, suffix: '.json' },
    { prefix: `${prefix}auth/auth-`, suffix: '.json' },
    { prefix: `${prefix}entries/entries-`, suffix: '.json' },
    { prefix: `${prefix}tasks/tasks-`, suffix: '.json' },
    { prefix: `${prefix}project-creator/project-creator-`, suffix: '.json' },
    { prefix: `${prefix}settings/settings-`, suffix: '.json' },
    { prefix: `${prefix}attachments/attachments-`, suffix: '.json' },
    { prefix: `${prefix}notifications/notifications-`, suffix: '.json' },
    { prefix: `${prefix}help/help-`, suffix: '.json' },
    { prefix: `${prefix}apptour/apptour-`, suffix: '.json' },
    { prefix: `${prefix}commentaries/commentaries-`, suffix: '.json' },
    { prefix: `${prefix}contacts/contacts-`, suffix: '.json' },
    { prefix: `${prefix}projects/projects-`, suffix: '.json' },
    { prefix: `${prefix}projects/protocols/protocols-`, suffix: '.json' },
    { prefix: `${prefix}projects/list/projects-list-`, suffix: '.json' },
    { prefix: `${prefix}projects/timeline/projects-timeline-`, suffix: '.json' },
    { prefix: `${prefix}projects/summary/project-summary-`, suffix: '.json' },
    { prefix: `${prefix}projects/changes/project-changes-`, suffix: '.json' },
    { prefix: `${prefix}projects/tasks/project-tasks-`, suffix: '.json' },
    { prefix: `${prefix}projects/team/project-team-`, suffix: '.json' },
    { prefix: `${prefix}projects/contacts/project-contacts-`, suffix: '.json' },
    { prefix: `${prefix}calendar/calendar/calendar-`, suffix: '.json' },
    { prefix: `${prefix}calendar/events/events-`, suffix: '.json' },
    { prefix: `${prefix}my-work/my-work-`, suffix: '.json' },
    { prefix: `${prefix}mailings/mailings-`, suffix: '.json' },
  ]);
}
