import { ActivatedRoute } from '@angular/router';
import { WindowHelper } from '@shared/helpers/window.helper';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';

@Component({
  styleUrls: ['./task-sidenav.component.scss'],
  templateUrl: './task-sidenav.component.html',
})
export class TaskSidenavComponent implements OnInit, OnDestroy {
  static componentName: string = 'TaskSidenavComponent';

  WindowHelper = WindowHelper;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    public service: TaskSidenavService,
    private active: ActivatedRoute,
  ) {}

  ngOnInit() {
    this._setTaskConfig();
  }

  private _setTaskConfig() {
    const config = this.active?.snapshot?.queryParams?.config
      ? JSON.parse(this.active.snapshot.queryParams.config)
      : null;
    config.extra?.type ? (this.service.TaskConfigType = config.extra?.type) : null;
    const options = Object.assign(this.sideData?.data, { extra: { type: this.service.TaskConfigType } });
    this.service.setInitialData(options);
  }

  ngOnDestroy() {
    this.service.resetTaskSidenav();
    this.service.resetDefaultValuesWithinSidenav();
  }
}
