import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'input-v2',
  templateUrl: './input-v2.component.html',
  styleUrls: ['./input-v2.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputV2Component), multi: true }],
})
export class InputV2Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() errMessages: { [key: string]: string };
  @Input() prependSrc = '';
  @Input() set disabled(value: boolean) {
    value ? this.form.disable() : this.form.enable();
  }
  @Input() set readonly(value: boolean) {
    this._readonly = value;
  }

  get readonly(): boolean {
    return this._readonly;
  }

  _readonly = false;

  @ViewChild('inputv2') inputv2: ElementRef;

  form = new FormGroup({
    control: new FormControl(''),
  });

  destroy$ = new Subject();

  get isError() {
    return this.control && this.control.touched;
  }

  get control() {
    return this.form.get('control');
  }

  get errorMessage() {
    if (!this.errMessages) return;

    if (!this.isError && !Object.keys(this.errMessages).length) return;

    let message = '';
    Object.keys(this.errMessages).forEach((error) => (message = this.getValidatorMessage(error)));
    return message;
  }

  constructor() {}

  onChange = (value: string) => {};

  onTouched = () => {};

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((values) => {
          this.onChange(values.control);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  writeValue(value: string): void {
    this.form.patchValue({ control: value });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  getValidatorMessage(name: string) {
    switch (name) {
      case GbxsoftErrorTypes.pattern:
        const pattern = this.control.errors.pattern.requiredPattern;
        return this.errMessages && this.errMessages[pattern]
          ? this.errMessages[pattern]
          : 'No pattern validator';
      default:
        return this.errMessages && this.errMessages[name]
          ? this.errMessages[name]
          : this.getDefaultMessages(name);
    }
  }

  getDefaultMessages(errorType: string) {
    const err = this.control.errors[errorType];

    switch (errorType) {
      case GbxsoftErrorTypes.red:
        return '&nbsp;';
      case GbxsoftErrorTypes.required:
        return 'This field is required.';
      case GbxsoftErrorTypes.min:
        return `Please enter a value greater than or equal to ${err.min}.`;
      case GbxsoftErrorTypes.max:
        return `Please enter a value less than or equal to ${err.max}.`;
      case GbxsoftErrorTypes.email:
        return 'Please enter a valid email address.';
      case GbxsoftErrorTypes.minLength:
        return `Please enter at least ${err.requiredLength} characters.`;
      case GbxsoftErrorTypes.maxLength:
        return `Please enter no more than ${err.requiredLength} characters.`;
      default:
        return 'No validator message';
    }
  }
}
