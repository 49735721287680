import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class PermissionsDecoratorService {
  private static storageService: StorageService | undefined = undefined;
  public constructor(service: StorageService) {
    PermissionsDecoratorService.storageService = service;
  }
  public static getStorageService(): StorageService {
    if (!PermissionsDecoratorService.storageService) {
      throw new Error('DecoratorService not initialized');
    }
    return PermissionsDecoratorService.storageService;
  }
}
