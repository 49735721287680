<div class="my-work">
  <action-title-header-v2
    [title]="'MyWork.title' | translate"
    [buttonText]="'MyWork.newTask' | translate"
    (onAction)="createNewTask()"
    [stickyWithSideNavigation]="true"
    [permissionDecorator]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
  >
    <list-search-v2></list-search-v2>
    <div class="work-list__tabs">
      <button-group-v2
        [config]="buttonGroupConfig"
        (onClick)="onTabClick($event)"
        class="w-100 w-sm-auto"
      ></button-group-v2>
    </div>
    <list-filters-btn-v2 [filters]="MyWorkFiltersComponent"></list-filters-btn-v2>
  </action-title-header-v2>

  <!-- #region Content -->
  <div class="my-work__content">
    <div class="my-work__content__tasks">
      <ng-select
        [ngStyle]="{ display: 'none' }"
        #selectTab
        ngSelectExtension
        class="select select-default my-work__content__select-tabs"
        [dropdownPosition]="'bottom'"
        [items]="tabs"
        [clearable]="false"
        [searchable]="false"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [placeholder]="'Projects.tabs' | translate"
        (change)="onTabClick($event)"
        [tabIndex]="-1"
      >
      </ng-select>
      <list-tasks
        #listTasksComponent
        [isMyWork]="true"
        [filters]="MyWorkFiltersComponent"
        [config]="config"
      ></list-tasks>
    </div>
  </div>
  <!-- #endregion -->
</div>
