import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { ProjectStatistics } from '@modules/projects/shared/models/project-statistics.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProjectSummaryAPIService extends BaseHttpService {
  constructor() {
    super();
  }

  getSummary(projectId: number): Observable<ProjectStatistics> {
    const url = `${Config.API}/project/${projectId}/summary`;
    return this.get(url).pipe(
      map((projectStatistics: ProjectStatistics) => {
        return new ProjectStatistics(projectStatistics);
      }),
    );
  }

  getChangesSummary(projectId: number): Observable<any> {
    const url = `${Config.API}/project/${projectId}/changes-status-summary`;
    return this.get(url).pipe(
      map((projectStatistics: any) => {
        return projectStatistics;
      }),
    );
  }
}
