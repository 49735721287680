<label class="switch" [ngClass]="{ disabled: disabled }">
  <div class="inside">
    <input
      type="checkbox"
      [disabled]="disabled"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
    />
    <span class="slider round"></span>
  </div>
  <div class="text">{{ label }}</div>
</label>
