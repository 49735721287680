import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Observable } from 'rxjs';
import { ListGroupTask } from '@shared/modules/list/model/list-group-task.model';
import { Config } from '@shared/configs/config';

@Injectable({ providedIn: 'root' })
export class MyWorkService extends BaseHttpService {
  constructor() {
    super();
  }

  getListGroupTasksCounters(projectId = 0, params?: any): Observable<ListGroupTask[]> {
    const url = `${Config.API}/task-list/my-task?withoutData=1`;
    return this.get(url, false, params) as Observable<ListGroupTask[]>;
  }
}
