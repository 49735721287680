import { ProjectCreatorManage } from '@modules/project-creator/pages/project-creator-manage/project-creator-manage.component';
import { ProjectCreatorMiddleware } from '@modules/project-creator/pages/project-creator-middleware/project-creator-middleware.component';
import { ProjectCreatorManageResolver } from '@modules/project-creator/shared/resolvers/project-creator-manage.resolver';
import { ProjectCreatorMiddlewareResolver } from '@modules/project-creator/shared/resolvers/project-creator-middleware.resolver';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectCreatorRoutes: CustomRoutes = [
  {
    path: 'middleware/:token',
    component: ProjectCreatorMiddleware,
    resolve: { project: ProjectCreatorMiddlewareResolver },
    data: {
      hideHeader: true,
      notRestorationPosition: true,
    },
  },
  {
    path: 'manage/:token',
    name: 'project-creator-manage',
    component: ProjectCreatorManage,
    resolve: { project: ProjectCreatorManageResolver },
    data: {
      hideHeader: true,
      notRestorationPosition: true,
    },
  },
];
