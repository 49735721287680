import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '@core/core.module';
import { AttachmentInlineComponent } from './components/attachment-inline/attachment-inline.component';
import { AttachmentManagerListComponent } from './components/attachment-manager-list/attachment-manager-list.component';
import { AttachmentManagerListV2Component } from './components/attachment-manager-list-v2/attachment-manager-list-v2.component';
import { AttachmentCardV2Component } from './components/attachment-card-v2/attachment-card-v2.component';

@NgModule({
  declarations: [
    AttachmentManagerListComponent,
    AttachmentManagerListV2Component,
    AttachmentInlineComponent,
    AttachmentCardV2Component,
  ],
  imports: [CoreModule, MatIconModule],
  exports: [AttachmentManagerListComponent, AttachmentManagerListV2Component],
  providers: [],
})
export class AttachmentsManageModule {}
