import { Config } from '@shared/configs/config';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { TaskChangeAcceptance } from '@modules/client-panel/shared/models/task-change-acceptance.interface';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import {
  ESummaryColor,
  ISummaryConfig,
} from '@modules/projects/modules/project-changes/shared/interfaces/summary-config.interface';
import { RegisterDialogComponent } from '@shared/components/register-dialog/register-dialog.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { NavigateService } from '@shared/services/navigate.service';
import * as moment from 'moment';
import { PriceFormatPipe } from '@shared/pipes/price-format.pipe';

@Component({
  templateUrl: './task-change-acceptance.component.html',
  styleUrls: ['./task-change-acceptance.component.scss'],
})
export class TaskChangeAcceptanceComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  acceptance: TaskChangeAcceptance;
  projectId: number;
  token: string;

  summary: any;
  summaryOpened: boolean = false;
  summaryConfig: ISummaryConfig = null;

  get _config() {
    return {
      items: [
        {
          title: 'ProjectChanges.waitingProjectChanges',
          tip: 'ProjectChanges.sharedProjectChangesInfo',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.waiting?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.waiting?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.waiting?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.waiting?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.waiting?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.waiting?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.waiting?.changesBalance),
            color: this.summary?.waiting?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
        {
          title: 'ProjectChanges.acceptedProjectChanges',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.accepted?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.accepted?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.accepted?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.accepted?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.accepted?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.accepted?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.accepted?.changesBalance),
            color: this.summary?.accepted?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
        {
          title: 'ProjectChanges.rejectedProjectChanges',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.rejected?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.rejected?.increasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.rejected?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.rejected?.decreasingChangesSum),
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: `${this.summary?.rejected?.zeroChangesCount.toString()}`,
            },

            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.rejected?.additionalTimeSum}`,
            },
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: this._getMoneyAmount(this.summary?.rejected?.changesBalance),
            color: this.summary?.rejected?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED,
          },
        },
      ],
    };
  }
  constructor(
    private active: ActivatedRoute,
    private store: StorageService,
    private n: NavigateService,
    private dialog: MatDialog,
    private storage: StorageService,
    private service: ClientAcceptanceService,
    private pricePipe: PriceFormatPipe,
    private changes: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setInitialData();
    this.getSummary();
  }

  setInitialData() {
    this.store.sharedToken = this.active.snapshot.params.token;
    this.acceptance = this.active.snapshot.data?.acceptance;
    this.acceptance.tasks?.sort(
      (a, b) => moment(b.created, Config.DATE_SERVER).unix() - moment(a.created, Config.DATE_SERVER).unix(),
    );
    this.token = this.active.snapshot.params.token;
    this.projectId = this.acceptance?.tasks[0]?.project?.id || null;
  }

  getSummary() {
    this.service.getChangesSharedSummary(this.token).subscribe((summary) => {
      this.summary = summary;
      this.summaryConfig = Object.assign({}, this._config);
      this.changes.detectChanges();
    });
  }

  private _getMoneyAmount(value: number, withoutSymbol: boolean = false) {
    return this.pricePipe?.transform(value, withoutSymbol, this.acceptance?.tasks[0]?.company?.currency);
  }

  getValueColor(value) {
    if (value === 0) {
      return ESummaryColor.BLACK;
    }
    return value > 0 ? ESummaryColor.GREEN : ESummaryColor.RED;
  }

  checkProject() {
    if (!this.projectId) return;
    if (!this.acceptance.contact && !!this.acceptance.email) {
      this.openRegisterDialog();
      return;
    }
    this.goToProject();
  }

  openRegisterDialog() {
    this.dialog.open(RegisterDialogComponent, {
      autoFocus: false,
      width: '375px',
      data: { email: this.acceptance.email, callback: this.goToProject.bind(this), token: this.token },
    });
  }

  goToProject() {
    if (!this.storage.isLogged) {
      const link = this.n.getPath('project-changes', {}, { id: this.projectId });
      this.n.navigate('login', { redirectTo: link });
    } else {
      this.n.go('project-changes', { id: this.projectId });
    }
  }

  toggleSummary() {
    this.summaryOpened = !this.summaryOpened;
    this.changes.detectChanges();
  }
}
