import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ListService } from '@shared/modules/list/services/list.service';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'contact-toggle-menu-cell',
  templateUrl: './contact-toggle-menu-cell.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactToggleMenuCellComponent implements OnInit, ListCustomCell {
  PermissionsGroups = PermissionsGroups;

  _row: Contact;

  set row(row) {
    this._row = new Contact(row);
  }

  @ViewChild('template') template;

  constructor(private listService: ListService) {}

  ngOnInit(): void {}

  edit() {
    const ctrl = new ContactController();
    ctrl.edit(this?._row);
  }

  preview() {
    const ctrl = new ContactController();
    ctrl.preview(this?._row.id);
  }

  addSubcontact() {
    const ctrl = new ContactController();
    ctrl.addSubcontact(this?._row.id);
  }

  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const _ctrl = new ContactController();
        _ctrl.remove(this?._row).subscribe(() => {
          this.listService.getRows();
        });
      })
      .subscribe(() => {});
  }
}
