import { SideNavigationTabType } from '@shared/modules/side-navigation/enums/side-navigation-tab-type.enum';
import { SideNavigationTabCountType } from '@shared/modules/side-navigation/enums/side-navigation-tab-count-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStage } from '@shared/enums/project-stage.enum';

export const projectsListSideNavigationConfig = (t: TranslateService) => {
  return {
    title: t.instant('ProjectsList.stagesTitle'),
    backAvailable: false,
    name: 'projects-list',
    canHidden: false,
    tabs: [
      {
        name: t.instant('ProjectsList.allProjects'),
        type: SideNavigationTabType.TAB,
        prop: 'all',
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.project-creator'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_CREATOR,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.created'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_CREATED,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.meeting'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_MEETING,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.estimate'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_ESTIMATE,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.negotiations'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_NEGOTIATIONS,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.won'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_WON,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.before-realization'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_BEFORE_REALIZATION,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.realization'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_REALIZATION,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.approved'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_APPROVED,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.payment'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_PAYMENT,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.finished'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_FINISHED,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.complaint'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_COMPLAINT,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
      {
        name: t.instant('Projects.Stage.lost'),
        type: SideNavigationTabType.TAB,
        prop: ProjectStage.STAGE_LOST,
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0,
      },
    ],
  };
};
