<label [matMenuTriggerFor]="previewStatus" class="d-flex align-items-center m-0 fake-select cursor-pointer">
  <svg-icon src="/assets/img/ic-checkmark-circle-outline.svg" class="mr-1"></svg-icon>
  <span class="fake-select-title" [innerHTML]="'Protocols.statusTitle' | translate"> </span>
  <span class="ml-1">
    <svg-icon src="/assets/img/arrow-down-grey.svg"></svg-icon>
  </span>
</label>

<mat-menu #previewStatus="matMenu">
  <ul class="m-0">
    <li
      *ngFor="let i of statusList"
      mat-menu-item
      class="d-flex align-items-center"
      [innerHTML]="i.label | translate"
      (click)="setNewStatus(i.id)"
    ></li>
  </ul>
</mat-menu>
