import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Currency } from '@shared/models/currency.interface';
import { ChangeValuePipe } from '@shared/pipes/change-value.pipe';
import { PriceFormatPipe } from '@shared/pipes/price-format.pipe';

@Component({
  selector: 'price-cell',
  templateUrl: './price-cell.component.html',
  styleUrls: ['./price-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceCellComponent implements OnInit, AfterViewInit {
  _price: string;
  _priceValue: number;
  @Input() isLocalCurrency: boolean = false;
  @Input() localCurrency: Currency = null;
  @Input()
  set price(price: number) {
    this._priceValue = price || 0;
    this.setPrice(this._priceValue);
    this.changes.detectChanges();
  }

  @Input() customText: string = null;

  get currency() {
    return this.isLocalCurrency
      ? this.storage?.Company?.currency
      : this.localCurrency
      ? this.localCurrency
      : null;
  }

  constructor(
    private changValue: ChangeValuePipe,
    private pricePipe: PriceFormatPipe,
    private changes: ChangeDetectorRef,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setPrice(this._priceValue);
    this.changes.detectChanges();
  }

  setPrice(price: number) {
    this._price = this.changValue.transform(price, this.pricePipe.transform(price, false, this.currency));
  }
}
