<main>
  <form class="auth-form">
    <h1
      class="mb-4 h1 grey-1"
      [innerHTML]="(companies.length ? 'Auth.Company.select' : 'Auth.Company.noCompany') | translate"
    ></h1>
    <ul class="m-0" *ngIf="companies.length">
      <ng-container *ngFor="let empl of companies">
        <li>
          <radio-box
            [checked]="empl?.id == form?.get('company')?.value"
            [title]="empl.company.name ? empl.company.name : ('Global.noCompanyName' | translate)"
            [description]="('Auth.Company.role' | translate) + ' ' + (empl.role | userRole)"
            (change)="onCompanySelect(empl)"
          ></radio-box>
        </li>
      </ng-container>
    </ul>

    <div *ngIf="companies?.length" class="d-flex justify-content-end submit-btn">
      <app-button
        [class]="'bold'"
        [disabled]="loading || creatingCompany"
        [loading]="loading"
        (onClick)="submitSelection()"
        [config]="{
          text: 'Auth.Company.loginSelected' | translate,
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          prependSrc: './assets/img/ic-arrow-right.svg'
        }"
      ></app-button>
    </div>

    <div *ngIf="!companies.length">
      <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.Company.accountCompany' | translate"></div>
      <div class="grey-4 grey-text" [innerHTML]="'Auth.Company.addOwnCompany' | translate"></div>

      <app-button
        [class]="'bold'"
        [disabled]="creatingCompany || loading"
        [loading]="creatingCompany"
        (onClick)="createCompany()"
        [config]="{
          text: 'Auth.Company.createOwnOrganisation' | translate,
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          prependSrc: './assets/img/ic-arrow-right.svg'
        }"
      ></app-button>
    </div>

    <div class="auth-organisation" *ngIf="store.UserPerson?.canCreateCompany" >
      <h2 class="blue-500" [innerHTML]="'Auth.Company.createOwn' | translate"></h2>
      <p class="grey-4" [innerHTML]="'Auth.Company.areYouCreator' | translate"></p>

      <app-button
        [class]="'bold'"
        [disabled]="creatingCompany || loading"
        [loading]="creatingCompany"
        (onClick)="createCompany()"
        [config]="{
          text: 'Auth.Company.ownOrganisation' | translate,
          size: ButtonSize.LARGE,
          type: ButtonTypes.SECONDARY_BLUE
        }"
      ></app-button>
    </div>
  </form>
</main>
