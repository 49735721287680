import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Task } from '@shared/models/task.model';

export class TaskChangeAcceptance {
  acceptanceStatus: string;
  created: string;
  deleted: string;
  id: number;
  modified: string;
  tasks: Task[] = [];
  type: string;
  contact: Contact;
  email: string;

  constructor(i?: TaskChangeAcceptance) {
    this.deserialize(i);
  }

  deserialize(i?: TaskChangeAcceptance) {
    i ? Object.assign(this, i) : null;
    this.tasks = i.tasks.map((t) => new Task(t));
  }
}
