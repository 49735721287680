import { Injectable } from '@angular/core';
import { CreatorPermission } from '../enums/creator-permisson.enum';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { Employee } from '@shared/models/employee.model';
import { ExistingProjectConfigurationPermissionConfig } from '@core/permissions/configs/existing-project-configuration-permission.config';
import { ProjectPerson } from '../enums/project-person.enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectCreatorPermissionResolverService {
  private readonly PERMISSIONS = ExistingProjectConfigurationPermissionConfig;

  constructor(private readonly projectPreviewService: ProjectPreviewService) {}

  resolvePermissions(employee: Employee, previewAction: string, editAction: string): CreatorPermission[] {
    const permissions: CreatorPermission[] = [];

    const canPreviewAsUser = this.hasPermissionAsUser(previewAction);
    const canEditAsUser = this.hasPermissionAsUser(editAction);

    const isClientResponsible = this.projectPreviewService.isClientResponsibleForProject(employee.id);
    const canPreviewAsClient = isClientResponsible && this.hasPermissionAsClient(previewAction);
    const canEditAsClient = isClientResponsible && this.hasPermissionAsClient(editAction);

    const isEmployeeResponsible = this.projectPreviewService.isEmployeeResponsibleForProject(employee.id);
    const canPreviewAsEmployee = isEmployeeResponsible && this.hasPermissionAsEmployee(previewAction);
    const canEditAsEmployee = isEmployeeResponsible && this.hasPermissionAsEmployee(editAction);

    if (canPreviewAsUser || canPreviewAsEmployee || canPreviewAsClient)
      permissions.push(CreatorPermission.PREVIEW);

    if (canEditAsUser || canEditAsEmployee || canEditAsClient) permissions.push(CreatorPermission.EDIT);

    return permissions;
  }

  private hasPermissionAsUser(action: string): boolean {
    return new CheckPermission({
      group: PermissionsGroups.EXISTING_PROJECT_CONFIGURATOR,
      action: action,
    }).check();
  }

  private hasPermissionAsClient(action: string): boolean {
    return this.hasPermissionAsPersonResponsibleForProject(
      action,
      ProjectPerson.CLIENT_RESPONSIBLE_FOR_PROJECT,
    );
  }

  private hasPermissionAsEmployee(action: string): boolean {
    return this.hasPermissionAsPersonResponsibleForProject(
      action,
      ProjectPerson.EMPLOYEE_RESPONSIBLE_FOR_PROJECT,
    );
  }

  private hasPermissionAsPersonResponsibleForProject(action: string, person: ProjectPerson): boolean {
    const perms = this.PERMISSIONS[action];
    return perms?.includes(person);
  }
}
