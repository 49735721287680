import { Config } from '@shared/configs/config';
import { Task } from '@shared/models/task.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { TaskModalContentPreviewComponent } from '../task-modal-content-preview/task-modal-content-preview.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';

@Component({
  selector: 'task-modal-preview',
  templateUrl: './task-modal-preview.component.html',
  styleUrls: ['./task-modal-preview.component.scss'],
})
export class TaskModalPreviewComponent implements OnInit {
  task: Task;

  @ViewChild(TaskModalContentPreviewComponent) modalContentPreview: TaskModalContentPreviewComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task;
      preview: boolean;
      getTaskData?: boolean;
      hiddenForClientShow?: boolean;
      snackBottom?: boolean;
    },
    private changes: ChangeDetectorRef,
    public pService: ProtocolApiService,
    private t: TranslateService,
    private s: SnackBarService,
    public dialogRef: MatDialogRef<any>,
  ) {
    if (data.getTaskData) {
      this.getTaskData(data.task.id);
    } else {
      this.task = data.task;
    }
  }

  ngOnInit(): void {}

  discardModal() {
    this.dialogRef.close();
    this.changes.detectChanges();
  }

  taskChanged(task: Task) {
    this.task = task;
    this.changes.detectChanges();
  }

  getTaskData(id: number) {
    this.pService.getTask(id).subscribe({
      next: (task: Task) => {
        this.task = task;
        this.modalContentPreview.task = task;
        this.modalContentPreview.setFormData();
        this.changes.detectChanges();
      },
      error: (e: HttpError) => {
        switch (e.messageCode) {
          case IntilioCodes.ITEM_NOT_EXISTS:
            this.s.error(
              this.t.instant('Protocols.errorTaskNotExists'),
              this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
            );
            break;
          default:
            this.s.error(
              this.t.instant('Protocols.errorGetTaskData'),
              this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
            );
            break;
        }
        this.dialogRef.close();
      },
    });
  }
}
