import { Injectable } from '@angular/core';
import { AppRoute } from '@shared/configs/routes';
import { CustomRoute } from '@shared/interfaces/routes/custom-route.interface';
import { Router, ActivatedRoute, NavigationEnd, NavigationExtras } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class NavigateService {
  private routesTree: RoutePathName = {};

  private url = {
    current: '',
    previous: '',
    secondPrevious: '',
  };

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.pushPreviousUrl(event.url);
      });
  }

  /**
   *
   * !DEPRECATED!
   * @param {string} name
   * @param {*} [queryParams={}]
   * @param {*} [params] - you can add route params in object which could be replaced, e.x. {id: 23}
   * @param {boolean} [clearCurrentParams]
   * @returns
   * @memberof NavigateService
   */
  navigate(name: string, queryParams: any = {}, params?: any, clearCurrentParams?: boolean) {
    if (!name || name === '') {
      this.router.navigate(['/'], {queryParams});
    }
    if (!this.routesTree[name]) {
      return;
    }
    !clearCurrentParams ? (queryParams = Object.assign(queryParams, this.route.snapshot.queryParams)) : null;
    const routePath = this.getRouteWithRouteParams(this.routesTree[name], params);
    this.router.navigate([routePath], {queryParams});
  }

  /**
   *
   *
   * @param {string} name
   * @param {*} [params] - you can add route params in object which could be replaced, e.x. {id: 23}
   * @param {NavigationExtras} [extras]
   * @returns
   * @memberof NavigateService
   */
  go(name: string, params?: any, extras?: NavigationExtras) {
    if (!name || name === '' || name === '/') {
      this.router.navigate(['/']);
    }
    if (!this.routesTree[name]) {
      return;
    }
    const routePath = this.getRouteWithRouteParams(this.routesTree[name], params);
    return this.router.navigate([routePath], extras);
  }

  /**
   *
   *
   * @param {string} name
   * @param {*} [params] - you can add route params in object which could be replaced, e.x. {id: 23}
   * @param {NavigationExtras} [extras]
   * @returns
   * @memberof NavigateService
   */
  goWithPromise(name: string, params?: any, extras?: NavigationExtras): Promise<boolean> {
    if (!name || name === '' || name === '/') {
      this.router.navigate(['/']);
    }
    if (!this.routesTree[name]) {
      return;
    }
    const routePath = this.getRouteWithRouteParams(this.routesTree[name], params);
    return this.router.navigateByUrl(routePath, extras);
  }

  getRouteWithRouteParams(route: string, params: any) {
    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      route = route.replace(`:${key}`, params[key]);
    }
    return route;
  }

  /**
   *
   *
   * @param {string} name
   * @param {*} [queryParams={}]
   * @param {*} [params]
   * @param {boolean} [clearCurrentParams]
   * @returns
   * @memberof NavigateService
   */
  getPath(name: string, queryParams: any = {}, params?: any, clearCurrentParams?: boolean) {
    if (!this.routesTree[name]) {
      return '';
    }
    !clearCurrentParams ? (queryParams = Object.assign(queryParams, this.route.snapshot.queryParams)) : null;
    const routePath = this.getRouteWithRouteParams(this.routesTree[name], params);
    return routePath;
  }

  /**
   *
   *
   * @param {*} routes
   * @memberof NavigateService
   */
  setAppRoutes(routes) {
    for (const key of Object.keys(routes)) {
      const routesObj: AppRoute = routes[key];
      routesObj.routes.forEach((routeObj) => this.createPathName([], routeObj, routesObj.startPath));
    }
  }

  /**
   *
   *
   * @param {CustomRoute[]} arr
   * @param {CustomRoute} routeObject
   * @param {string} startPath
   * @returns
   * @memberof NavigateService
   */
  createPathName(arr: CustomRoute[], routeObject: CustomRoute, startPath: string) {
    const newArr = arr.slice();
    newArr.push(routeObject);

    const pushPath = (startPath, arr, routeObject) => {
      let path = '';
      startPath !== '' ? (path = `/${startPath}`) : null;

      arr.forEach((element, i) => {
        element.path !== '' ? (path += `/${element.path}`) : null;
      });

      routeObject.name ? (this.routesTree[routeObject.name] = path) : null;
    };

    if (!routeObject.children) {
      pushPath(startPath, newArr, routeObject);
      return;
    } else {
      routeObject.children.forEach((routeObject: CustomRoute) => {
        this.createPathName(newArr, routeObject, startPath);
      });
    }

    if (routeObject.name) {
      pushPath(startPath, newArr, routeObject);
    }
  }

  pushPreviousUrl(url: string) {
    this.url.secondPrevious = this.url.previous;
    this.url.previous = this.url.current;
    this.url.current = url;
  }

  /**
   *
   * Get previous URL
   * @readonly
   * @type {string}
   * @memberof NavigateService
   */
  get previousUrl(): string {
    return this.url.previous;
  }

  get currentUrl(): string {
    return this.url.current;
  }

  /**
   *
   * Get second previous URL
   * @readonly
   * @type {string}
   * @memberof NavigateService
   */
  get secondPreviousUrl(): string {
    return this.url.secondPrevious;
  }
}

export interface RoutePathName {
  [key: string]: string;
}
