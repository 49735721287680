import { ProjectCalendarComponent } from '@modules/projects/modules/project-calendar/pages/project-calendar/project-calendar.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectCalendarRoutes: CustomRoutes = [
  {
    path: '',
    name: 'project-preview-calendar',
    component: ProjectCalendarComponent,
  },
];
