import { TaskType } from '@shared/enums/task-type.enum';
import { Company } from '@shared/models/company.model';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import { UserPerson } from '@shared/interfaces/user.interface';
import { Employee } from '@shared/models/employee.model';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';

export class BaseTaskModel {
  deleted?: any;
  created?: string;
  modified?: string;
  id?: number;
  description?: string;
  completed?: any;
  content?: any;
  type?: TaskType;
  assignedTo?: EmployeeSearchItem[] = [];
  status?: TaskStatus;
  price?: number;
  additionalTime?: any;
  termStart?: any;
  termEnd?: any;
  attachments?: any[] = [];
  sortOrder?: number;
  source?: string;
  completedDate?: string;
  hiddenForClient?: boolean = false;
  creator?: Employee;
  assignedUsers?: UserPerson[] = [];
  comments?: Commentary[] = [];
  canAccept?: boolean;
  company?: Company;

  constructor() {}

  get isNote() {
    return this.type === TaskType.TYPE_NOTE;
  }

  get isChange() {
    return this.type === TaskType.TYPE_CHANGE;
  }

  get isCorrection() {
    return this.type === TaskType.TYPE_CORRECTION;
  }

  /**
   *Task Type change cannot be completed only accepted or rejected by client
   *
   * @readonly
   * @memberof BaseTaskModel
   */
  get isCompleted() {
    return this.status === TaskStatus.COMPLETED && this.type === TaskType.TYPE_NOTE;
  }
}
