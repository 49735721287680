<div class="form-control" [ngClass]="inputCSS">
  <label>
    <span
      class="form-control-prepend"
      *ngIf="config?.prependContent"
      [innerHTML]="config?.prependContent"
    ></span>

    <input
      #gbxsoftInput
      trim
      [attr.data-test]="testAttr"
      [autocomplete]="config?.autocomplete"
      [autocapitalize]="config?.autocapitalize"
      [spellcheck]="config?.spellcheck"
      [ctrl]="errorCtrl?.control"
      [type]="type"
      [value]="formatedValue"
      [placeholder]="placeholder"
      (keypress)="keypress($event)"
      (keydown)="onKeydown.emit($event)"
      [pattern]="pattern"
      [name]="config?.fieldName"
      [attr.disabled]="disabled ? '' : null"
      [attr.readOnly]="readonly ? 'readonly' : null"
    />

    <span [innerText]="config?.name"></span>
    <span
      class="form-control-append"
      *ngIf="config?.appendContent"
      [innerHTML]="config?.appendContent"
    ></span>
  </label>
  <span *ngIf="errorCtrl.isError" class="form-control-error-msg" [innerHTML]="errorCtrl.errorMessage"></span>
</div>
