import { Config } from '@shared/configs/config';
import { downloadFile } from './../helpers/download-file.helper';
import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { BaseHttpService } from '@core/http/base-http.service';
import { MatDialog } from '@angular/material/dialog';
import { DocPreviewerComponent } from '@shared/components/doc-previewer/doc-previewer.component';

@Directive({
  selector: '[docPreview]',
})
export class DocPreviewDirective implements OnInit, OnDestroy {
  downloading: boolean = false;
  modalInstance: DocPreviewerComponent;
  listenerFn: () => void;
  @Input() disabled: boolean = false;
  @Input() file: any;
  @Input() token: string;
  @Output() onLoading: EventEmitter<boolean> = new EventEmitter();

  get FileExt() {
    const splited = this.file?.name?.split('.');
    return splited[splited.length - 1];
  }

  get isExtValid() {
    return !!this.FileExt && Config.GOOGLE_DOCS_EXTENSIONS.includes(this.FileExt?.toUpperCase());
  }

  get isPDF() {
    return this.FileExt?.toUpperCase().indexOf('PDF') > -1;
  }

  // get isSizeValid() {
  //   return this.file.size <= Config.GOOGLE_DOCS_MAX_FILE_SIZE;
  // }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private filePipe: AuthFilePipe,
    private http: BaseHttpService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.clickListener();
  }

  clickListener() {
    this.listenerFn = this.renderer.listen(this.el.nativeElement, 'click', () => {
      if (!this.file || this.disabled) return;
      if (this.isExtValid) {
        // if (this.isSizeValid) {
        const refDialog = this.dialog.open(DocPreviewerComponent, {
          panelClass: 'doc-previewer-modal',
          height: '100vh',
          width: '100vw',
          autoFocus: false,
          data: { file: this.file, downloadFunc: this.downloadFile.bind(this), isPDF: this.isPDF },
        });
        this.modalInstance = refDialog.componentInstance;
        // } else {
        //   this.downloadFile();
        // }
      } else {
        this.downloadFile();
      }
    });
  }

  downloadFile() {
    if (this.downloading) return;
    this.downloading = true;
    this.modalInstance ? (this.modalInstance.downloading = this.downloading) : null;
    this.onLoading.emit(this.downloading);
    const url = this.filePipe.transform(this.file.file);

    this.http
      .getFileBlob(url, false)
      .subscribe((blob) => downloadFile(blob, this.file.name))
      .add(() => {
        this.downloading = false;
        this.modalInstance ? (this.modalInstance.downloading = this.downloading) : null;
        this.onLoading.emit(this.downloading);
      });
  }

  ngOnDestroy() {
    this.modalInstance = null;
    if (this.listenerFn) {
      this.listenerFn();
    }
  }
}
