import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Task } from '@shared/models/task.model';
import { ObjectTaskType } from '@shared/enums/task-type.enum';

@Component({
  selector: 'project-team-manage-success-modal',
  templateUrl: './project-team-manage-success-modal.component.html',
  styleUrls: ['./project-team-manage-success-modal.component.scss']
})
export class ProjectTeamManageSuccessModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;


  constructor(
    public dialogRef: MatDialogRef<ProjectTeamManageSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      emails: string[]
    },
  ) { }

  ngOnInit(): void {
  }

}
