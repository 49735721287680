import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ContactExistsInfoType } from '@modules/contacts/shared/models/contact-exists-event.model';
import { FormGroup } from '@angular/forms';
import { CONTACT_FORM } from '@modules/contacts/shared/consts/contact-form.const';
import { ListResponse } from '@shared/modules/list/model/list-response.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Employee } from '@shared/models/employee.model';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'contact-exists-info',
  templateUrl: './contact-exists-info.component.html',
  styleUrls: ['./contact-exists-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactExistsInfoComponent implements OnInit, AfterViewInit {


  foundContacts: { type: ContactExistsInfoType; contacts: Contact[] } = null;
  searchingTimeout;
  sub: Subscription = new Subscription();
  parentContactToAssign: Contact;
  loggedEmployee: Employee;

  @Input() form: FormGroup;
  @Input() isInited: boolean;
  @Input() mainContactId: number;

  constructor(private service: ContactService,
              private changes: ChangeDetectorRef,
              private s: SnackBarService,
              private t: TranslateService,
              private store: StorageService
  ) {
  }

  ngOnInit(): void {
    this.loggedEmployee = this.store.Employee;
    this.listenFormChanges();
  }

  ngAfterViewInit() {
    if (this.mainContactId) {
      this.service.getContact(this.mainContactId).subscribe({
        next: (contact) => {
          this.parentContactToAssign = contact;
        }
      });
    }
  }

  listenFormChanges() {
    this.sub.add(this.form.get(CONTACT_FORM.companyName).valueChanges.subscribe((value: string) => {
      if (!this.form.get(CONTACT_FORM.companyName).valid) {
        return;
      }
      this.searchContact(ContactExistsInfoType.COMPANY_NAME, value);
    }));
    this.sub.add(this.form.get(CONTACT_FORM.email).valueChanges.subscribe((value: string) => {
      if (!this.form.get(CONTACT_FORM.email).valid) {
        return;
      }
      this.searchContact(ContactExistsInfoType.EMAIL, value);
    }));
    this.sub.add(this.form.get(CONTACT_FORM.phone).valueChanges.subscribe((value: string) => {
      if (!this.form.get(CONTACT_FORM.phone).valid) {
        return;
      }
      this.searchContact(ContactExistsInfoType.PHONE, value);
    }));
    this.sub.add(this.form.get(CONTACT_FORM.firstName).valueChanges.subscribe((value: string) => {
      if (!this.form.get(CONTACT_FORM.firstName).valid) {
        return;
      }
      this.searchContact(ContactExistsInfoType.FIRST_NAME, value);
    }));
    this.sub.add(this.form.get(CONTACT_FORM.lastName).valueChanges.subscribe((value: string) => {
      if (!this.form.get(CONTACT_FORM.lastName).valid) {
        return;
      }
      this.searchContact(ContactExistsInfoType.LAST_NAME, value);
    }));
  }

  searchContact(type: ContactExistsInfoType, value: string): void {
    if (!this.isInited) {
      return;
    } //block listen changes if it's init for edit contact
    if (!value || !value.length) {
      this.foundContacts = null;
      this.changes.detectChanges();
      return;
    }
    clearTimeout(this.searchingTimeout);
    this.searchingTimeout = setTimeout(() => {
      const params = {
        [`filters[a.${type}][eq]`]: value,
      };

      if (type === ContactExistsInfoType.LAST_NAME || type === ContactExistsInfoType.FIRST_NAME) {
        params[`filters[a.type][eq]`] = ContactType.PERSONAL;
      }

      this.service.search(params).subscribe({
        next: (res: ListResponse<Contact>) => {
          this.onSuccessSearchContact(res, type);
        }
      });
    }, 200);
  }

  unsubscribeExistsInfo() {
    clearTimeout(this.searchingTimeout);
    this.sub.unsubscribe();
  }

  onSuccessSearchContact(res: ListResponse<Contact>, type: ContactExistsInfoType) {
    if (res.records.length) {
      this.foundContacts = {
        type,
        contacts: res.records.map((contact: Contact) => {
          return new Contact(contact);
        }),
      };
      this.cutCurrentEditedContact();
    } else {
      this.foundContacts = null;
    }
    this.changes.detectChanges();
  }

  cutCurrentEditedContact() {
    this.foundContacts.contacts.map((contact: Contact, index: number) => {
      if (contact.id === this.form.get(CONTACT_FORM.id)?.value) {
        this.foundContacts.contacts.splice(index, 1);
      }
    });
  }

  preview(contact: Contact) {
    const ctrl = new ContactController();
    ctrl.preview(contact.id, true);
  }

  assignAsSubcontact(contact: Contact) {
    this.service.setAsSubcontact(contact.id, this.mainContactId).subscribe({
      next: (res: Contact) => {
        contact = res;
        this.s.success(this.t.instant('Contacts.Manage.ContactExistsInfo.contactAssigned'));
        const ctrl = new ContactController();
        ctrl.preview(res.id, false);
        this.changes.detectChanges();
      }
    });
  }
}
