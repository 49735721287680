import { PermissionsConfig } from '@core/permissions/interfaces/permissions-config';
import { PermissionsAction } from '@core/permissions/interfaces/permissions-action.interface';
import { PermissionType } from '@core/permissions/enums/permission-type.enum';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '../permissions.group';

export class MailingPermissionsConfig implements PermissionsConfig {
  static readonly MAILING_FUNCTION: (PermissionsAction | string)[] = [
    UserRoles.ROLE_EMPLOYEE_ADMIN,
    UserRoles.ROLE_EMPLOYEE_MANAGER,
    {
      type: PermissionType.OBJECT_CREATOR_ID,
    },
  ];

  static readonly type: PermissionsGroups = PermissionsGroups.MAILINGS;
}
