import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'contact-preview-subcontacts',
  templateUrl: './contact-preview-subcontacts.component.html',
  styleUrls: ['./contact-preview-subcontacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPreviewSubcontactsComponent implements OnInit, OnDestroy {
  contact: Contact;
  contactController: ContactController;
  sub: Subscription;

  constructor(private route: ActivatedRoute, private changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.sub = this.route.parent.data.subscribe((data: { contact: Contact }) => {
      this.contact = new Contact(data.contact);
      this.contactController = new ContactController(this.contact);
      this.changes.detectChanges();
    });
  }

  onRemoveSubcontact(removedContact: Contact) {
    this.contact.childContacts = this.contact.childContacts.filter((childContact: Contact) => {
      return childContact.id !== removedContact.id;
    });
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onChangeContactPerson(contactPerson: Contact) {
    this.contact.childContacts.map((childContact: Contact) => {
      if (childContact.id === contactPerson.id) {
        this.contact.contactPerson = new Contact(contactPerson);
        childContact.isContactPerson = true;
      } else {
        childContact.isContactPerson = false;
      }
      return childContact;
    });
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  onRemoveContactPersonFromMain(contactPerson: Contact) {
    this.contact.childContacts.map((childContact: Contact) => {
      if (childContact.id === contactPerson.id) {
        this.contact.contactPerson = null;
        childContact.isContactPerson = false;
      }
    });
    this.route.parent.snapshot.data.contact = this.contact;
    this.changes.detectChanges();
  }

  getContactWithParent(contact) {
    contact = new Contact(contact);
    contact.parentContacts = [];
    contact.parentContacts.push(this.contact);
    return contact;
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

  identify(index, item: Contact) {
    return item.id;
  }
}
