import { ProjectEmailComponent } from '@modules/projects/modules/project-email/pages/project-email/project-email.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectEmailRoutes: CustomRoutes = [
  {
    path: '',
    name: 'project-preview-email',
    component: ProjectEmailComponent,
    children: [
      {
        path: '',
        data: { innerEmails: true },
        loadChildren: () =>
          import('@modules/mailing-templates/mailing-templates.module').then((m) => m.MailingTemplatesModule),
      },
    ],
  },
];
