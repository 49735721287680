import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'changeValue', pure: true })
export class ChangeValuePipe implements PipeTransform {
  transform(value: number, valueWithCurrency: string | number, mod: boolean = false): string {
    if (value === 0 || value === null) return valueWithCurrency.toString();
    if (value && value > 0) return '+ ' + valueWithCurrency;
    // tslint:disable-next-line: max-line-length
    if (value && value < 0)
      if (mod) {
        valueWithCurrency = Math.abs(valueWithCurrency as number);
      }
    return (
      '- ' +
      (typeof valueWithCurrency === 'number' ? valueWithCurrency : valueWithCurrency.toString().substr(1))
    );
    if (!value) return (0).toString();
  }
}
