import { Config } from '@shared/configs/config';
import { Component, ViewChild, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Task } from '@shared/models/task.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { TranslateService } from '@ngx-translate/core';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { ObjectTaskType, TaskType } from '@shared/enums/task-type.enum';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { AttachmentService } from '@shared/services/attachment.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TaskModalContentComponent } from '../task-modal-content/task-modal-content.component';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { TaskTypeItem, TaskTypeList } from '@modules/protocols/shared/consts/task-type.list';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { TaskService } from '../../services/task-service';
import { TaskAction } from '@modules/protocols/shared/enums/task-action.enum';

@Component({
  selector: 'task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
})
export class TaskModalComponent implements OnInit, AfterViewInit {
  @ViewChild('textAttach') textAttach: TextAttachmentsInputComponent;
  @ViewChild('taskModalContent') taskModalContent: TaskModalContentComponent;
  task: Task;
  tempTask: Task = null;
  attachmentsToDelete: Array<number> = [];
  attachments: Array<TextAttachment> = [];
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  isSuccess: boolean = false;
  updatingTask: boolean = false;
  typeToChange: TaskType = null;
  taskTypeList: Array<TaskTypeItem> = TaskTypeList;
  form: FormGroup;

  config: GbxsoftInputConfig = {
    name: ' ',
    type: GbxsoftInputTypes.TEXT,
    placeholder: this.t.instant('Protocols.enterToAddWithoutEnter'),
    showPlaceholderOnFocus: true,
  };

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
  };

  get taskModel() {
    const oldState = Object.assign({}, this.task);

    const model = Object.assign(oldState, this.taskModalContent.taskData, {
      type: this.typeToChange,
      description: this.form?.get('note')?.value || null,
      attachments: this.form?.get('files')?.value || null,
    });

    this.data?.objectType ? (model['objectType'] = this.data?.objectType) : null;
    const list = [
      'assignedTo',
      'assignedUsers',
      'creator',
      'deletePermission',
      'editPermission',
      'taskGroup',
      'projectBasicDataBox',
      'project',
      'protocol',
    ];

    list.forEach((e) => (!!model[e] ? delete model[e] : null));
    // console.log(oldState?.type, model?.type);
    // oldState?.type !== model?.type ? 0 :
    model.dataChanges = 1;

    return model;
  }

  get NoteControl() {
    return this.form.get('note');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task;
      preview: boolean;
      isTypeSelection: boolean;
      objectType?: ObjectTaskType;
      assignID?: number;
      snackBottom: boolean;
    },
    private changes: ChangeDetectorRef,
    public pService: ProtocolApiService,
    private fb: FormBuilder,
    private t: TranslateService,
    private attachmentService: AttachmentService,
    private s: SnackBarService,
    public dialogRef: MatDialogRef<TaskModalComponent>,
    public taskService: TaskService,
  ) {
    this.task = data.task;
    this.tempTask = Object.assign({}, this.task);
    this.typeToChange = this.task.type;
  }

  ngOnInit(): void {
    this.createForm();
  }

  setTypeChange(type: TaskType) {
    this.typeToChange = type;
    this.changes.detectChanges();
  }

  createForm() {
    this.form = this.fb.group({ note: [this.task?.description, [Validators.required]], files: null });
    this.changes.detectChanges();
  }

  ngAfterViewInit() {
    this.attachments = this.task?.attachments.map((attachment: Attachment) =>
      this.textAttach.getAttachment(attachment as any, null, attachment),
    );
    this.textAttach.setFilesToFormControl();
    this.changes.detectChanges();
  }

  removeFileEvent(attachment: TextAttachment) {
    // attachment.id ? this.attachmentsToDelete.push(parseInt(attachment.id)) : null;
    // this.changes.detectChanges();
    const index = this.attachments.findIndex((x) => x.id.toString() === attachment.id.toString());
    if (index === -1 || !this.attachments?.length) return;
    this.attachmentService
      .removeAttachment(parseInt(attachment.id))
      .subscribe({
        next: () => this.successFileRemove(attachment, index),
        error: this.errorFileRemove.bind(this),
      })
      .add(() => this.changes.detectChanges());
  }

  successFileRemove(attachment: TextAttachment, index: number) {
    this.s.success(this.t.instant('fileRemoved'));
    this.changes.detectChanges();
  }

  errorFileRemove() {}

  submitModal() {
    this.NoteControl.markAsTouched();
    this.NoteControl.updateValueAndValidity();

    if (this.updatingTask || this.NoteControl.invalid) return;
    this.task?.id ? this.editTask() : this.createTask();
  }

  // #region Create Action
  createTask() {
    this.updatingTask = true;
    const model = this.taskModel;
    this.data.assignID ? (model.id = this.data.assignID) : null;
    this.taskService
      .createTask(model)
      .subscribe({
        next: this.successCreateTask.bind(this),
        error: this.errorCreateTask.bind(this),
      })
      .add(() => {
        this.updatingTask = false;
        this.changes.detectChanges();
      });
  }

  successCreateTask(task: Task) {
    this.attachmentsToDelete = [];
    this.task = new Task(task);
    this.isSuccess = true;
    this.s.success(
      this.t.instant(`Tasks.${task.type}Created`),
      this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.discardModal();
  }

  errorCreateTask() {
    this.s.error(this.t.instant('Protocols.errorUpdating'));
  }
  // #endregion

  // #region Edit Action
  editTask() {
    this.updatingTask = true;
    this.taskService
      .editTask(this.taskModel, this.task.id)
      .subscribe({
        next: this.successUpdateTask.bind(this),
        error: this.errorUpdateTask.bind(this),
      })
      .add(() => {
        this.updatingTask = false;
        this.changes.detectChanges();
      });
  }

  successUpdateTask(task: Task) {
    // this.attachmentsToDelete = [];

    // this.task = new Task(task);

    // this.taskService.taskEvent.emit({
    //   type: TaskAction.EDIT_TASK,
    //   data: { old: this.tempTask, new: this.task },
    // });

    // this.s.success(
    //   this.t.instant('Protocols.recordUpdated'),
    //   this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    // );
    // this.discardModal();
  }

  errorUpdateTask() {
    this.s.error(this.t.instant('Protocols.errorUpdating'));
  }
  // #endregion

  discardModal() {
    this.form.reset();
    this.dialogRef.close();
    this.changes.detectChanges();
  }
}
