import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Config } from '@shared/configs/config';
import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';
import { Task } from '@shared/models/task.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TaskType } from '@shared/enums/task-type.enum';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { StorageService } from '@core/services/storage.service';
import { PricePipe } from '@shared/pipes/price.pipe';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DiscountComponent } from '../task-components/discount/discount.component';
import { DaysList } from '@modules/protocols/shared/consts/days.list';
import { FinancialList } from '@modules/protocols/shared/consts/financial.list';
import { TaskStatusList } from '@modules/protocols/shared/consts/task-status.list';
import { TaskInfluence } from '@modules/protocols/shared/enums/task-influence.enum';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { TaskSelectionChangeComponent } from '../task-selection-change/task-selection-change.component';
import { SnackBarService } from '@core/services/snackbar.service';
import { Regex } from '@shared/configs/regex';
import { EmployeeSearchComponent } from '@shared/components/employee-search/employee-search.component';

@Component({
  selector: 'task-modal-content',
  templateUrl: './task-modal-content.component.html',
  styleUrls: ['./task-modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskModalContentComponent implements OnInit {
  CONFIG = Config;
  assignedUsers: Array<any> = [];
  addNewPerson: EventEmitter<any> = new EventEmitter();

  @ViewChild('datepicker') datepicker: DateRangePickerComponent;
  @ViewChild('financeTempl') financeTempl: TaskSelectionChangeComponent;
  @ViewChild('daysTempl') daysTempl: TaskSelectionChangeComponent;
  @ViewChild('discountTempl') discountTempl: DiscountComponent;
  @ViewChild('assingedSelect') assingedSelect: EmployeeSearchComponent;

  currentFinanceState: TaskInfluence;
  currentDateState: TaskInfluence;

  @Input() task: Task;
  @Input() type: TaskType;

  form: FormGroup;

  days: Array<any> = DaysList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });
  financial: Array<any> = FinancialList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });
  taskStatusList: Array<any> = TaskStatusList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  get isClient() {
    return this.type === TaskType.TYPE_CLIENT_TASK;
  }

  get isNote() {
    return this.type === TaskType.TYPE_NOTE;
  }

  get isChange() {
    return this.type === TaskType.TYPE_CHANGE;
  }

  get isCorrection() {
    return this.type === TaskType.TYPE_CORRECTION;
  }

  get taskData() {
    const value = Object.assign({}, !!this.form?.value ? this.form?.value : {});
    delete value['assignedToIds'];
    const assignedEmails =
      this.assingedSelect?.select?.selectedItems
        .filter((i) => !!(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.previewName) || [];

    const assignedToIds =
      this.assingedSelect?.select?.selectedItems
        .filter((i) => !(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.helperID) || [];

    const isChangeSelected = !!(this.form?.value?.type === TaskType.TYPE_CHANGE);
    const isCorrectionSelected = !!(this.form?.value?.type === TaskType.TYPE_CORRECTION);

    const obj = Object.assign(
      this.form.value,
      { assignedEmails },
      { assignedToIds },
      this.datePickerData,
      this.isChange || isChangeSelected ? this.influenceData : {},
      this.isCorrection || isCorrectionSelected ? this.discountData : {},
    );
    return obj;
  }

  get datePickerData() {
    return {
      termStart:
        this.datepicker?.dateControl.value &&
        moment(this.datepicker.startDateValue, Config.DATE_SERVER).isValid()
          ? this.datepicker.startDateValue
          : null,
      termEnd:
        this.datepicker?.dateControl.value &&
        moment(this.datepicker.endDateValue, Config.DATE_SERVER).isValid()
          ? this.datepicker.endDateValue
          : null,
    };
  }

  get influenceData() {
    return {
      price: this.getInfluenceCount('financeTempl') * 100,
      additionalTime: this.getInfluenceCount('daysTempl'),
    };
  }

  get discountData() {
    return {
      discount: this.discountTempl.discount.value * 100,
    };
  }

  get SelectPriceValue() {
    if (this.task.price > 0) {
      return this.financial[0];
    } else if (this.task.price < 0) {
      return this.financial[1];
    } else {
      return this.financial[2];
    }
  }

  get InputPriceValue() {
    return this.pricePipe.insertValueToInput(Math.abs(!!this.task.price ? this.task.price : 0), true);
  }

  get SelectDaysValue() {
    if (this.task.additionalTime > 0) {
      return this.days[0];
    } else if (this.task.additionalTime < 0) {
      return this.days[1];
    } else {
      return this.days[2];
    }
  }

  get InputDaysValue() {
    return Math.abs(!!this.task.additionalTime ? this.task.additionalTime : 0);
  }

  get isFinanceDisabled() {
    return this.currentFinanceState === TaskInfluence.NO_CHANGES;
  }

  get isDateDisabled() {
    return this.currentDateState === TaskInfluence.NO_CHANGES;
  }

  constructor(
    private fb: FormBuilder,
    private changes: ChangeDetectorRef,
    public pService: ProtocolApiService,
    public storage: StorageService,
    private pricePipe: PricePipe,
    private t: TranslateService,
    private s: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.setRange();
    this.setDiscount();
    this.setFinanceAndDays();

    this.assignedUsers = [
      ...this.task?.assignedUsers.concat(
        this.task?.assignedEmails?.map((i) => this.createCustomTag(i)) || [],
      ),
    ];
  }

  setUsers($event: { item: any }) {
    this.task.assignedUsers = [...this.task.assignedUsers, $event.item];
    this.changes.detectChanges();
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.createCustomTag($event);
  }

  createNewPerson(name: string, items: Array<any>): void {
    const user = this.createCustomTag(name);
    this.task.assignedUsers = [...this.task.assignedUsers, user];
    this.addNewPerson.emit([user]);
  }

  createCustomTag(name: string): any {
    return { email: name, previewName: name, helperID: name, newItem: true, label: name };
  }

  getInfluenceCount(templateName: string) {
    const select = this[templateName]?.form.get('select').value;
    const input = this[templateName]?.form.get('input').value;
    let value = Math.abs(input);
    switch (select.id) {
      case TaskInfluence.PLUS:
        break;
      case TaskInfluence.MINUS:
        value = value * -1;
        break;
      case TaskInfluence.NO_CHANGES:
        value = 0;
        break;
    }
    return value;
  }

  setFinanceAndDays() {
    this.currentFinanceState = this.SelectPriceValue.id;
    this.currentDateState = this.SelectDaysValue.id;

    this.financeTempl.form.setValue({
      select: this.SelectPriceValue,
      input: this.InputPriceValue,
    });

    this.daysTempl.form.setValue({
      select: this.SelectDaysValue,
      input: this.InputDaysValue,
    });
  }

  influenceChangeEvent($event, templateName: string, stateName: string) {
    if ($event.id === TaskInfluence.NO_CHANGES) {
      this[templateName].form.get('input').setValue(0);
    } else {
      if (this[templateName].form.get('input').value === 0) {
        this[templateName].form.get('input').setValue('');
      }
      setTimeout(() => this[templateName].input?.inputElement?.focus(), 0);
    }
    this[stateName] = $event.id;
  }

  setRange() {
    this.task.termStart && this.task.termEnd
      ? this.datepicker?.setRange(this.task.termStart, this.task.termEnd)
      : null;
  }

  setDiscount() {
    this.discountTempl?.discount.setValue(this.pricePipe.insertValueToInput(this.task.discount, true));
  }

  createForm() {
    const ids = this.task.assignedTo
      .map((i) => i.id)
      .concat((this.task?.assignedEmails?.map((i: string) => i) as any) || []);

    this.form = this.fb.group({
      status: [this.task?.status],
      assignedToIds: [ids],
      content: [this.task?.content],
      hiddenForClient: [this.task?.hiddenForClient],
    });
    this.changes.detectChanges();
  }

  transformEmployees(list: EmployeeSearchItem[]) {
    return [...list.map((i) => new EmployeeSearchItem(i).userPerson)];
  }
}
