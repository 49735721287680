<div class="subtask-create" [formGroup]="form">
  <div>
    <gbxsoft-input
      #taskTitle
      [errMessages]="errorMessages"
      [class]="'required'"
      [formControlName]="TASK_SIDENAV_FORM.description"
      [config]="{ name: 'Tasks.subtaskTitle' | translate }"
    ></gbxsoft-input>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <employee-search
        #employeeSearch
        [form]="form"
        [projectId]="taskService.formProjectId"
        [controlName]="TASK_SIDENAV_FORM.assignedTo"
      ></employee-search>
    </div>
    <div class="col-12 col-md-6">
      <daterangepicker
        #datepicker
        (rangeChanged)="rangeChanged($event)"
        [interactive]="true"
        [isCalendarTime]="true"
      ></daterangepicker>
    </div>
  </div>
  <div class="d-flex justify-content-end relative">
    <app-button
      [hidden]="form.dirty"
      [class]="'px-4 mr-2'"
      [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        size: ButtonSize.SMALL,
        text: 'Tasks.discard' | translate
      }"
      (onClick)="discardAdding()"
    ></app-button>
    <app-button
      [hidden]="!form.dirty"
      [class]="'px-4 mr-2'"
      confirmationPopover
      (confirm)="discardAdding()"
      [cancelText]="'Tasks.discard' | translate"
      [confirmText]="'Tasks.accept' | translate"
      placement="top"
      [popoverTitle]="'Tasks.acceptDiscardSubtask' | translate"
      [popoverMessage]="'Tasks.acceptDiscardSubtaskText' | translate"
      [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        size: ButtonSize.SMALL,
        text: 'Tasks.discard' | translate
      }"
    ></app-button>

    <app-button
      (onClick)="saveSubtask()"
      [class]="'px-3'"
      [disabled]="loading"
      [loading]="loading"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.SMALL,
        text: 'Tasks.add' | translate,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    ></app-button>
  </div>
</div>
