<div class="d-flex align-items-center justify-content-between event-details__section">
  <span class="event-details__section-title">{{ 'CalendarEvents.participants' | translate }}</span>
  <app-button
    (onClick)="sendMailings()"
    [class]="'text-uppercase ml-2'"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.SECONDARY_BLUE,
      text: 'CalendarEvents.sendMailToAll' | translate,
      prependSrc: './assets/img/ic-mail.svg'
    }"
  ></app-button>
</div>

<ul>
  <li class="w-100">
    <event-participant-item
      [participant]="participant"
      *ngFor="let participant of service.calendarEvent?.participants"
    ></event-participant-item>
  </li>
</ul>
