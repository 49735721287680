<footer class="d-flex">
  <nav class="container-fluid">
    <div class="row h-100">
      <div class="col-12 col-sm-6 d-flex align-items-center">
        <ul class="list-unstyled d-flex">
          <li>
            <a
              draggable="false"
              [routerLink]="n.getPath('contact')"
              [innerHTML]="'Auth.Footer.contact' | translate"
              [title]="'Auth.Footer.contact' | translate"
            >
            </a>
          </li>
          <li>
            <a
              draggable="false"
              [routerLink]="n.getPath('faq')"
              [innerHTML]="'Auth.Footer.faq' | translate"
              [title]="'Auth.Footer.faq' | translate"
            >
            </a>
          </li>
          <li>
            <a
              draggable="false"
              [href]="'/assets/pdf/regulations.pdf'"
              target="_blank"
              [innerHTML]="'Auth.Register.regulations' | translate"
              [title]="'Auth.Register.regulations' | translate"
            >
            </a>
          </li>
          <li>
            <a
              draggable="false"
              [href]="'/assets/pdf/privacy.pdf'"
              target="_blank"
              [innerHTML]="'Auth.Register.privacyStatic' | translate"
              [title]="'Auth.Register.privacyStatic' | translate"
            >
            </a>
          </li>
        </ul>
      </div>
      <div
        class="grey-4 col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <span [innerHTML]="'companyName' | translate"></span>
      </div>
    </div>
  </nav>
</footer>
