export interface GbxsoftSelectConfig {
  id?: string;
  label?: string;
  notFoundText?: string;
  placeholder?: string;
  searching?: boolean;
  multiple?: boolean;
  allowClear?: boolean;
  closeOnClear?: boolean;
  closeOnSelect?: boolean;
  debounceTime?: number;
  addTag?: boolean;
  enableUnselect?: boolean;
  labelName?: string;
  valueName?: string;
}

export class DefaultSelectConfig implements GbxsoftSelectConfig {
  id = 'id';
  label = 'text';
  notFoundText = 'No results found...';
  placeholder = 'Select item...';
  searching = false;
  multiple = false;
  allowClear = false;
  closeOnClear = false;
  closeOnSelect = false;
  debounceTime = 300;
  addTag = false;
  enableUnselect = false;

  constructor() {}
}
