import { HttpError } from '@shared/interfaces/error.interface';
import { EntryModalComponent } from '@shared/modules/entry/components/entry-modal/entry-modal.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { TranslateService } from '@ngx-translate/core';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { ICommentaryEvent } from '../../interface/commentary-event.interface';
import { CommentaryEventType } from '../../enums/commentary-event-type.enum';
import { Commentary } from '../../models/commentary.model';
import { CommentaryService } from '../../services/commentary.service';
import { ICommentarySend } from '../../interface/commentary-send.interface';
import { CommentaryType } from '../../enums/commentary-type.enum';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Config } from '@shared/configs/config';
import { IEntryEmitter } from '@shared/modules/entry/components/entry-list/entry-list.component';
import { SnackBarService } from '@core/services/snackbar.service';
import * as moment from 'moment';

const ATTACHMENT_FORM = {
  files: 'files',
  note: 'note',
};

export enum CommentaryViewType {
  DEFAULT,
  COMMENTS_FIRST,
}

@Component({
  selector: 'commentary-manage',
  templateUrl: './commentary-manage.component.html',
  styleUrls: ['./commentary-manage.component.scss'],
  animations: [fadeInOnEnterAnimation({ duration: 400 }), fadeOutOnLeaveAnimation({ duration: 300 })],
  providers: [CommentaryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentaryManageComponent implements AfterViewInit, OnDestroy {
  CommentaryViewType = CommentaryViewType;
  ATTACHMENT_FORM = ATTACHMENT_FORM;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  config: GbxsoftInputConfig = {
    name: ' ',
    type: GbxsoftInputTypes.TEXT,
    placeholder: this.t.instant('Commentaries.addCommentary'),
    showPlaceholderOnFocus: true,
  };

  form: FormGroup = this.fb.group({
    [ATTACHMENT_FORM.files]: [null],
    [ATTACHMENT_FORM.note]: [null],
  });

  dialogRef: MatDialogRef<EntryModalComponent>;
  _commentaries: Commentary[] = [];
  loading: boolean = false;

  @ViewChild('textAttach') textAttach: TextAttachmentsInputComponent;
  @ViewChild('commentaryInp', { static: false }) commentaryInp: ElementRef;
  @ViewChild('manageBox') manageBox: ElementRef;

  @Input() viewType: CommentaryViewType = CommentaryViewType.DEFAULT;

  @Input() manualSort: boolean = false;

  @Input()
  set commentaries(_commentaries) {
    this._commentaries = _commentaries?.length ? _commentaries : [];
    this.sortFromLatest();
  }
  get commentaries() {
    return this._commentaries;
  }
  @Input() append?: boolean = true;
  @Input() appendElement: string = '.project-question-directory-sidenav';
  @Input() id: number = null;
  @Input() type: CommentaryType = CommentaryType.COMMENT;
  @Input() attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
  };
  @Output() commentaryEvent: EventEmitter<ICommentaryEvent> = new EventEmitter();

  get transformedForm(): ICommentarySend {
    const controls = this.form.controls;
    const content = controls[ATTACHMENT_FORM.note].value ? controls[ATTACHMENT_FORM.note].value : '';
    const attachments = controls[ATTACHMENT_FORM.files].value ? controls[ATTACHMENT_FORM.files].value : [];
    const type = this.type;
    const id = this.id;

    return {
      id,
      content,
      attachments,
      type,
    };
  }

  get hasFormValues() {
    return (
      !!this.form.controls[ATTACHMENT_FORM.note].value?.length ||
      !!this.form.controls[ATTACHMENT_FORM.files].value?.length
    );
  }

  get isCommentsFirst() {
    return this.viewType === CommentaryViewType.COMMENTS_FIRST;
  }

  get isCommentsDefault() {
    return this.viewType === CommentaryViewType.DEFAULT;
  }

  constructor(
    private t: TranslateService,
    private fb: FormBuilder,
    private service: CommentaryService,
    private dialog: MatDialog,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this.textAttach.input.inputElement.addEventListener('keyup', this.setEventListener.bind(this));
    this.rerenderCommentsBlock();
  }

  rerenderCommentsBlock() {
    if (this.isCommentsFirst && this.append) {
      this.renderer.appendChild(document.querySelector(this.appendElement), this.commentaryInp.nativeElement);
    }
  }

  sortFromLatest() {
    this._commentaries.sort((a, b) => {
      return moment(b.created, Config.DATE_SERVER).unix() - moment(a.created, Config.DATE_SERVER).unix();
    });
  }

  sortFromOldest() {
    this._commentaries.sort((a, b) => {
      return moment(a.created, Config.DATE_SERVER).unix() - moment(b.created, Config.DATE_SERVER).unix();
    });
  }

  setEventListener(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.addCommentary();
    }
  }

  resetForm() {
    this.form.reset();
    this.textAttach.removeAllAttachments();
    this.changes.detectChanges();
  }

  // #region ADD ACTION
  addCommentary() {
    if (this.loading || !this.hasFormValues) return;
    this.loading = true;
    this.changes.detectChanges();
    this.service
      .addCommentary(this.transformedForm)
      .subscribe({
        next: (c: Commentary) => this.successAddCommentary(c),
        error: (err: HttpError) => this.errorAddCommentary(err),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successAddCommentary(c: Commentary) {
    c = new Commentary(c);
    this.commentaries.push(c);
    this.resetForm();
    this.commentaryEvent.emit({ type: CommentaryEventType.ADD, data: { item: c, list: this.commentaries } });
    this.s.success(this.t.instant('Commentaries.commentaryAdded'));
    this.sortFromLatest();
    this.manageBox?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
    this.changes.detectChanges();
  }

  errorAddCommentary(err: HttpError) {
    this.s.error(this.t.instant('Commentaries.commentaryAddedFail'));
    this.changes.detectChanges();
  }
  //#endregion

  // #region DELETE ACTION
  deleteCommentary(c: Commentary) {
    this.service.deleteCommentary(c.id).subscribe({
      next: (_c: Commentary) => this.successDeleteCommentary(c),
      error: (err: HttpError) => this.errorDeleteCommentary(err),
    });
  }

  successDeleteCommentary(c: Commentary) {
    const index = this.commentaries.findIndex((i) => i.id === c.id);
    this.commentaries.splice(index, 1);
    this.commentaryEvent.emit({
      type: CommentaryEventType.REMOVE,
      data: { item: c, list: this.commentaries },
    });
    this.s.success(this.t.instant('Commentaries.commentaryRemoved'));
    this.sortFromLatest();
    this.changes.detectChanges();
  }

  errorDeleteCommentary(err: HttpError) {
    this.s.error(this.t.instant('Commentaries.commentaryRemovedFail'));
    this.changes.detectChanges();
  }
  //#endregion

  // #region EDIT ACTION
  openEditModal(commentary: Commentary) {
    this.dialogRef = this.dialog.open(EntryModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        entry: commentary,
        entryModalConfig: {
          editTitle: this.t.instant('Commentaries.editTitle'),
          descriptionField: 'content',
          attachmentsField: 'attachments',
        },
        config: this.config,
        attachmentsConfig: this.attachmentsConfig,
        acceptEmptyState: false,
      },
    });

    const entryModal: EntryModalComponent = this.dialogRef.componentInstance;
    this.changes.detectChanges();

    const entrySub = entryModal.onEntry.subscribe((value: IEntryEmitter) => {
      this.editCommentary(value, commentary);
      this.changes.detectChanges();
    });

    this.dialogRef.afterClosed().subscribe(() => {
      entrySub ? entrySub.unsubscribe() : null;
      this.changes.detectChanges();
    });
  }

  editCommentary(event: IEntryEmitter, commentary: Commentary) {
    if (this.dialogRef.componentInstance.loading) return;

    this.dialogRef.componentInstance.loading = true;
    const transformedForm = Object.assign(this.transformedForm, event.data);
    transformedForm.type = this.type;
    this.changes.detectChanges();
    this.service
      .editCommentary(commentary.id, transformedForm)
      .subscribe({
        next: (c: Commentary) => this.successEditCommentary(c),
        error: (err: HttpError) => this.errorEditCommentary(err),
      })
      .add(() => {
        this.dialogRef.componentInstance.loading = false;
      });
  }

  successEditCommentary(c: Commentary) {
    c = new Commentary(c);
    const index = this.commentaries.findIndex((i) => i.id === c.id);
    if (index > -1) {
      this.commentaries[index] = c;
      this.commentaryEvent.emit({
        type: CommentaryEventType.EDIT,
        data: { item: c, list: this.commentaries },
      });
    }
    this.s.success(this.t.instant('Commentaries.commentaryEdited'));
    this.dialogRef.close();
    this.sortFromLatest();
    this.changes.detectChanges();
  }

  errorEditCommentary(err: HttpError) {
    this.s.error(this.t.instant('Commentaries.commentaryEditedFail'));
    this.changes.detectChanges();
  }
  //#endregion

  emitCommentaryEvent($event: ICommentaryEvent) {
    switch ($event.type) {
      case CommentaryEventType.REMOVE:
        this.deleteCommentary($event.data);
        break;
      case CommentaryEventType.EDIT:
        this.openEditModal($event.data);
        break;
      default:
        break;
    }
    this.changes.detectChanges();
  }

  trackCommentaries(index: number, commentary: Commentary) {
    return commentary.id;
  }

  ngOnDestroy() {
    if (this.textAttach?.input?.inputElement) {
      this.textAttach.input.inputElement.removeEventListener('keyup', this.setEventListener.bind(this));
    }
  }
}
