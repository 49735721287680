<sidenav-title
  [closeFunc]="service.discardSaving.bind(this.service)"
  [title]="'CalendarEvents.addEvent' | translate"
></sidenav-title>
<form [formGroup]="service.form" class="pt-4 event-creation">
  <div class="mb-4">
    <div class="new-select-label">{{ 'Header.calendar' | translate }}</div>
    <ng-select
      ngSelectExtension
      [formControlName]="service.EVENT_SIDENAV_FORM.calendarId"
      [searchable]="false"
      [responseTransform]="successLoadCalendars.bind(this)"
      [url]="service.SystemCalendars"
      [clearable]="false"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [placeholder]="'Calendar.Settings.selectCalendar' | translate"
      [notFoundText]="'Projects.notFoundText' | translate"
      class="new-select"
    ></ng-select>
  </div>

  <div class="mb-4">
    <input-v2
      #nameInput
      class="required"
      [formControlName]="service.EVENT_SIDENAV_FORM.name"
      [errMessages]="errorMessages(service.EVENT_SIDENAV_FORM.name)"
      [placeholder]="'CalendarEvents.eventName' | translate"
    ></input-v2>
  </div>

  <div class="mb-4">
    <div class="new-select-label">{{ 'CalendarEvents.dateAndTime' | translate }}</div>
    <div class="row">
      <div class="col-12" [ngClass]="{ 'col-md-6': !service.isWholeDaySelected }">
        <daterangepicker-v2
          [config]="{
            timePicker: false,
            timePicker24Hour: false,
            viewFormat: Config.DATE_FORMAT_DOTS
          }"
          #daterangepicker
          [form]="service.form"
          [controlStart]="service.EVENT_SIDENAV_FORM.helperStartDate"
          [controlEnd]="service.EVENT_SIDENAV_FORM.helperEndDate"
          [title]="'CalendarEvents.date' | translate"
          [interactive]="true"
        ></daterangepicker-v2>
        <span
          *ngIf="
            !daterangepicker?.dateControl?.value ||
            service.controlInvalid(service.EVENT_SIDENAV_FORM.termStart) ||
            service.controlInvalid(service.EVENT_SIDENAV_FORM.termEnd)
          "
          class="form-control-error-msg d-flex mt-2"
        >
          {{ 'FormErrors.required' | translate }}
        </span>
      </div>

      <div [hidden]="service.isWholeDaySelected" class="col-12 col-md-6 mt-3 mt-md-0">
        <pair-timepicker-v2
          #timepicker
          [form]="service.form"
          [startControl]="service.EVENT_SIDENAV_FORM.helperStartTime"
          [endControl]="service.EVENT_SIDENAV_FORM.helperEndTime"
          (blurEvent)="service.updateTime($event)"
          [scrollId]="'sidenav-popup'"
        ></pair-timepicker-v2>
        <span
          *ngIf="
            service.controlInvalid(service.EVENT_SIDENAV_FORM.helperStartTime) ||
            service.controlInvalid(service.EVENT_SIDENAV_FORM.helperEndTime)
          "
          class="form-control-error-msg d-flex mt-2"
        >
          {{ 'FormErrors.required' | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <new-toggle-checkbox-v2
      [formControlName]="service.EVENT_SIDENAV_FORM.isFullDay"
      [label]="'CalendarEvents.wholeDay' | translate"
    ></new-toggle-checkbox-v2>
    <!-- <div class="form-control-checkbox">
      <label class="m-0 d-flex align-items-center">
        <input type="checkbox" [formControlName]="service.EVENT_SIDENAV_FORM.isFullDay" />
        <span></span>
        <span>{{ 'CalendarEvents.wholeDay' | translate }}</span>
      </label>
    </div> -->
  </div>

  <!-- <div class="row">
    <div class="col-12 col-md-6 mb-4">
      <ng-select
        [formControlName]="service.EVENT_SIDENAV_FORM.reminderTypes"
        [items]="service.reminderItems"
        [multiple]="true"
        [bindLabel]="'label'"
        [bindValue]="'id'"
        class="select select-default w-100"
        [notFoundText]="'Projects.notFoundText' | translate"
        [placeholder]="'CalendarEvents.addReminder' | translate"
      ></ng-select>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <ng-select
        [formControlName]="service.EVENT_SIDENAV_FORM.cycleType"
        [items]="service.EventCycleList"
        [bindLabel]="'label'"
        [bindValue]="'id'"
        class="select select-default select-with-title w-100"
        [notFoundText]="'Projects.notFoundText' | translate"
        [placeholder]="'CalendarEvents.cyclicEvent' | translate"
      ></ng-select>
    </div>
  </div> -->

  <div class="mb-4">
    <div class="new-select-label">{{ 'Projects.projectName' | translate }}</div>
    <div class="mb-4">
      <project-selection
        [form]="service.form"
        [requiredid]="service.projectID"
        [addTag]="canProjectAdd"
        [controlName]="service.EVENT_SIDENAV_FORM.projectId"
        [class]="'w-100'"
      ></project-selection>
    </div>
    <autocomplete-v2 [form]="service.form"></autocomplete-v2>
  </div>

  <div class="mb-4">
    <div class="new-select-label">{{ 'CalendarEvents.participants' | translate }}</div>
    <ng-select
      #participants
      ngSelectExtension
      [url]="service.ContactsUrl"
      [responseTransform]="successOnLoadContacts.bind(this)"
      [formControlName]="service.EVENT_SIDENAV_FORM.contactsIds"
      [ignoreLocalSearch]="true"
      [multiple]="true"
      [bindValue]="'id'"
      [bindLabel]="'fullName'"
      [clearable]="service.form.get(service.EVENT_SIDENAV_FORM.contactsIds)?.value?.length > 1"
      [addTag]="validateEmail.bind(this)"
      [initialValue]="service.assignedParticipants"
      [notFoundText]="'Projects.notFoundText' | translate"
      [placeholder]="'CalendarEvents.selectParticipatns' | translate"
      [addTagText]="'Protocols.add' | translate"
      class="new-select w-100"
      [searchGetParam]="'query'"
    ></ng-select>
  </div>

  <div class="mb-4">
    <!-- <textarea-v2
      [formControlName]="service.EVENT_SIDENAV_FORM.description"
      [placeholder]="'CalendarEvents.content' | translate"
    ></textarea-v2> -->
    <div class="relative">
      <quill-editor
        [modules]="baseModules"
        [formats]="formats"
        [placeholder]="'CalendarEvents.content' | translate"
        [formControlName]="service.EVENT_SIDENAV_FORM.description"
        [bounds]="'.quill-custom-img-size'"
        class="quill-v2 quill-custom-img-size"
      ></quill-editor>
    </div>
  </div>
</form>
