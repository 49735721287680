<modal-title
  [title]="'ProjectSummary.SendNotificationAboutStageChange.sendNotificationModalTitle' | translate"></modal-title>

<form [formGroup]="form" class="project-send-stage-notification">
  <mat-dialog-content>
    <p class="mt-2">
      {{ 'ProjectSummary.SendNotificationAboutStageChange.description' | translate }}
    </p>
    <angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end">

      <app-button
        [disabled]="loading"
        (onClick)="dialogRef.close()"
        [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'ProjectSummary.SendNotificationAboutStageChange.cancel' | translate,
        size: ButtonSize.LARGE
      }">
      </app-button>
      <app-button
        [class]="'ml-3'"
        [loading]="loading"
        [disabled]="loading"
        (onClick)="submit()"
        [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'ProjectSummary.SendNotificationAboutStageChange.saveAndSend' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-save.svg'
      }"
      >
      </app-button>
    </div>
  </mat-dialog-actions>
</form>
