import {AppTourConfig} from '@shared/modules/app-tour/shared/interfaces/app-tour-config.interface';
import {AppTourTypes} from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import {CustomIStepOptionInterface} from '@shared/modules/app-tour/shared/interfaces/custom-i-step-option.interface';

export class AppTourProtocolManageConfig implements AppTourConfig {

  static readonly type: AppTourTypes = AppTourTypes.PROTOCOL_MANAGE;

  static readonly steps: CustomIStepOptionInterface[] = [
    {
      anchorId: 'protocolManage.s0.welcome'
    },
    {
      anchorId: 'protocolManage.s1.stuffs',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s1.stuffsMobile',
      hiddenOnDesktop: true
    },
    {
      anchorId: 'protocolManage.s2.send'
    },
    {
      anchorId: 'protocolManage.s3.download'
    },
    {
      anchorId: 'protocolManage.s4.preview'
    },
    {
      anchorId: 'protocolManage.s5.project'
    },
    {
      anchorId: 'protocolManage.s6.side',
    },
    {
      anchorId: 'protocolManage.s7.stuffsSection',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s8.notes',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s9.hidden0',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s10.tasks',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s11.corrections',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s12.changes',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s13.orders',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s14.bulkEdit1',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s15.getBy',
      hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s16.finances',
      // hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s17.changesSummary',
      // hiddenOnMobile: true
    },
    {
      anchorId: 'protocolManage.s18.signatures',
      hiddenOnMobile: true
    },
  ];
  static readonly startComponentName = 'acceptance-protocol-edit';
  static readonly startComponentParams = {id: 0};
  static readonly endComponentName = 'protocols-list-all';
  static readonly blockScroll = true;
  static readonly markedAsShownType = 'protocol-manage';
}
