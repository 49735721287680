import { Project } from './../models/project.model';
import { EventEmitter, Injectable } from '@angular/core';
import { BasicDataBox } from '../models/project-basic-data-box.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ProjectManageService {
  private _project: Project = null;
  public projectUpdate: EventEmitter<Project> = new EventEmitter();

  set project(project: Project) {
    this._project = new Project(project);
  }

  get project() {
    return this._project;
  }

  constructor(private fb: FormBuilder) {}

  successFindProjectByID(project: Project): Project {
    this.project = new Project(project);
    this.projectUpdate.emit(this.project);
    return this.project;
  }

  successEditProjectDataBox(basicData: BasicDataBox) {
    this.project.basicDataBox = new BasicDataBox(basicData);
    this.projectUpdate.emit(this.project);
    return this.project.basicDataBox;
  }
}
