<avatar
  *ngIf="items?.length === 1 || !items.length"
  [imageUrl]="url(items[0])"
  [width]="width"
  [mobileConfig]="mobileConfig"
  (menuOpened)="assignSearchDropdownComponent.searchEmployee()"
  [matMenuTriggerFor]="menu"
  class="cursor-pointer"
  [ngClass]="{ 'disabled-search': disabledSearch }"
></avatar>

<div
  class="d-flex align-items-center avatar-multiple"
  [ngClass]="{ 'avatar-multiple--no-assign': !assignSearchDropdownTask }"
  *ngIf="items?.length > 1"
  (menuOpened)="assignSearchDropdownComponent.searchEmployee()"
  [matMenuTriggerFor]="menu"
>
  <avatar [imageUrl]="url(items[0])" [width]="width" [mobileConfig]="mobileConfig"></avatar>
  <div
    [ngStyle]="{
      width: avatarWidth + 'px',
      height: avatarWidth + 'px'
    }"
    class="avatar-multiple__counter"
  >
    +{{ items?.length - 1 }}
  </div>
</div>

<mat-menu
  #menu="matMenu"
  xPosition="after"
  (closed)="assignSearchDropdownComponent.ngOnDestroy()"
  [permission]="permissionDecorator"
>
  <assign-search-dropdown
    #assignSearchDropdownComponent
    (onTaskUpdate)="onTaskUpdate($event)"
    (click)="$event.stopPropagation()"
    [task]="assignSearchDropdownTask"
  ></assign-search-dropdown>
</mat-menu>
