<button mat-menu-item (click)="preview()">
  <span>{{ 'ProjectsList.preview' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.accessToEditSecondFromList"
  (click)="editSecondBox()"
>
  <span>{{ 'ProjectsList.editSecondBox' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.accessToEdit"
  (click)="edit()"
>
  <span>{{ 'ProjectsList.edit' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.accessToEdit"
  [matMenuTriggerFor]="stages"
>
  <span>{{ 'ProjectsList.changeStage' | translate }}</span>
</button>

<mat-menu #stages="matMenu" xPosition="after">
  <button
    mat-menu-item
    *ngFor="let key of Object.keys(ProjectStage)"
    (click)="onStageChange(ProjectStage[key])"
    [disabled]="row?.stage === ProjectStage[key]"
    [ngClass]="{
      'hover_red red-500': ProjectStage[key] === ProjectStage.STAGE_LOST,
      'blue-500': row?.stage === ProjectStage[key]
    }"
  >
    <span>{{ 'Projects.Stage.' + ProjectStage[key] | translate }}</span>
  </button>
</mat-menu>

<button
  mat-menu-item
  *ngIf="row?.accessToEdit"
  (click)="changeResponsible()"
>
  <span>{{ 'ProjectsList.changeResponsible' | translate }}</span>
</button>

<button
  mat-menu-item
  *ngIf="row?.accessToRemove"
  (click)="remove()"
  class="hover_red red-500"
>
  <span>{{ 'ProjectsList.remove' | translate }}</span>
</button>
