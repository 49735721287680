<section class="container-fluid auth-panel" [ngClass]="{ 'auth-panel-full': isFullWidth }">
  <div class="row">
    <div [ngClass]="{ 'col-lg-6': !isFullWidth, 'col-12': isFullWidth }">
      <header class="auth-header">
        <a draggable="false" [routerLink]="'/'" [title]="'projectName' | translate">
          <img draggable="false" src="./assets/img/logo.svg" [alt]="'projectName' | translate" />
        </a>
      </header>
      <router-outlet></router-outlet>
    </div>
    <div
      *ngIf="!isFullWidth"
      class="col-lg-6 auth-info text-left d-flex flex-column justify-content-between"
      [ngClass]="{ 'auth-info-register': isRegister }"
    >
      <div [hidden]="isRegister" class="auth-info-title">
        <div><span [innerHTML]="'Auth.slogan' | translate"></span></div>
        <div class="auth-info-subtitle">
          <b> <span [innerHTML]="'Auth.subslogan' | translate"></span></b>
        </div>
      </div>
      <div [hidden]="isRegister" class="row justify-content-end">
        <img
          draggable="false"
          src="./assets/img/laptop-{{ t.currentLang }}.png"
          [alt]="'Auth.slogan' | translate"
        />
      </div>
      <div [hidden]="!isRegister" class="row justify-content-end">
        <img
          draggable="false"
          src="./assets/img/sign-in-{{ t.currentLang }}.svg"
          [alt]="'Auth.slogan' | translate"
        />
      </div>
    </div>
  </div>
</section>
