<modal-title [title]="data?.title || 'RemoveData.headerTitle' | translate"></modal-title>
<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1" [innerHTML]="data?.subtitle || 'RemoveData.title' | translate"></h4>
  <p>{{ data?.description || 'RemoveData.description' | translate }}</p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <button class="btn btn-cancel mr-md-2 mb-2 mb-md-0 btn-sm-block" (click)="dialogRef.close()">
    {{ data?.cancelBtn || 'RemoveData.cancel' | translate }}
  </button>
  <button class="btn btn-danger btn-danger__trash ml-md-1 btn-sm-block" (click)="dialogRef.close(true)">
    {{ data?.confirmBtn || 'RemoveData.confirm' | translate }}
  </button>
</mat-dialog-actions>
<!-- #endregion -->
