<modal-title [title]="'RemoveData.headerTitle' | translate"></modal-title>
<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1" [innerHTML]="'RemoveData.title' | translate"></h4>
  <p>{{ 'RemoveData.description' | translate }}</p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    [disabled]="loading"
    (onClick)="dialogRef.close()"
    [config]="{
      type: ButtonTypes.DISCARD_BUTTON,
      text: 'RemoveData.cancel' | translate,
      size: ButtonSize.LARGE
    }"
  >
  </app-button>
  <app-button
    [class]="'ml-3'"
    [loading]="loading"
    [disabled]="loading"
    (onClick)="submit()"
    [config]="{
      type: ButtonTypes.DANGER_BUTTON,
      text: 'RemoveData.confirm' | translate,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-trash-outline.svg'
    }"
  >
  </app-button>
</mat-dialog-actions>
<!-- #endregion -->
