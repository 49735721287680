import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { AcceptanceComponent } from '@modules/client-panel/pages/acceptance/acceptance.component';
import { ClientMainPanelComponent } from '@modules/client-panel/pages/client-main-panel/client-main-panel.component';
import { ProtocolAcceptancePreviewComponent } from '@modules/client-panel/pages/protocol-acceptance-preview/protocol-acceptance-preview.component';
import { ClientQuestionDirectorySecondComponent } from '@modules/client-panel/pages/client-question-directory-second/client-question-directory-second.component';
import { ClientQuestionDirectorySecondResolver } from '@modules/client-panel/pages/client-question-directory-second/client-question-directory-second.resolver';
import { TaskChangeAcceptanceComponent } from '@modules/client-panel/pages/task-change-acceptance/task-change-acceptance.component';
import { TaskChangeAcceptanceResolver } from '@modules/client-panel/pages/task-change-acceptance/task-change-acceptance.resolver';
import { EventAcceptationComponent } from '@modules/client-panel/pages/event-acceptation/event-acceptation.component';

export const ClientPanelRoutes: CustomRoutes = [
  {
    path: '',
    component: ClientMainPanelComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
      },
      {
        path: 'protocol-acceptance/:id',
        component: AcceptanceComponent,
        data: {
          hideHeader: true,
        },
      },
      {
        path: 'project-second-box/:id',
        name: 'project-second-box-edit',
        component: ClientQuestionDirectorySecondComponent,
        data: {
          isSignUpHeader: true,
        },
        resolve: [ClientQuestionDirectorySecondResolver],
      },
      {
        path: 'changes-acceptance/:token',
        name: 'changes-acceptance',
        component: TaskChangeAcceptanceComponent,
        data: {
          hideHeader: false,
          stickyFooter: true,
        },
        resolve: { acceptance: TaskChangeAcceptanceResolver },
      },
    ],
  },
  {
    path: 'protocol-acceptance/preview/:id',
    component: ProtocolAcceptancePreviewComponent,
    name: 'protocol-acceptance-preview',
    data: {
      client: true,
      metaTitle: 'Protocols.previewProtocol',
      hideHeader: true,
    },
  },
  {
    path: 'event/:token',
    component: EventAcceptationComponent,
    name: 'event-acceptation',
    data: {
      client: true,
      hideHeader: true,
    },
  },
];
