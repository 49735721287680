import { StorageService } from '@core/services/storage.service';
import { Employee } from '@shared/models/employee.model';
import { permissionsConfigs } from './configs/permissions.configs';
import { PermissionType } from './enums/permission-type.enum';
import { PermissionDecorator } from './interfaces/permission-decorator.interface';
import { PermissionsAction } from './interfaces/permissions-action.interface';
import { PermissionsGroups } from './permissions.group';
import { PermissionsDecoratorService } from './services/permissions-decorator.service';

export class CheckPermission {
  storageService: StorageService;
  employee: Employee;

  constructor(private permissionDecorator: PermissionDecorator) {
    this.init();
  }

  init() {
    this.storageService = PermissionsDecoratorService.getStorageService();
    this.employee = this.storageService.Employee;
  }

  setPermissionDecorator(permissionDecorator: PermissionDecorator) {
    this.permissionDecorator = permissionDecorator;
  }

  checkCorrectInitAndSettings() {
    if (!this.storageService) {
      console.error(`ERROR Error: StorageService was not initialize in PermissionsDecoratorService`);
      return false;
    }

    if (!this.employee) {
      console.error(`ERROR Error: Employee does not exists in storage! Can't check permissions`);
      return false;
    }

    if (!this.permissionDecorator) {
      return true;
    }

    if (
      this.permissionDecorator.objectCreatorId &&
      !Array.isArray(this.permissionDecorator.objectCreatorId) &&
      typeof this.permissionDecorator.objectCreatorId !== 'number'
    ) {
      console.error(
        `ERROR Error: objectCreatorId expected number got: ${typeof this.permissionDecorator
          .objectCreatorId}`,
      );
      return false;
    }

    if (this.permissionDecorator.group !== 0 && !this.permissionDecorator.group) {
      console.error(`ERROR Error: There is no group field in your decorator / directive!`);
      return false;
    }

    if (!this.permissionDecorator.action) {
      console.error(`ERROR Error: There is no action field in your decorator / directive!`);
      return false;
    }

    return true;
  }

  check() {
    if (!this.checkCorrectInitAndSettings()) {
      return false;
    }

    if (!this.permissionDecorator) {
      return true;
    }

    let permissionExists = false;
    let isCreator = false;

    const action = this.permissionDecorator.action.toUpperCase();

    configIterate: for (let i = 0; i <= permissionsConfigs.length - 1; i++) {
      const config = permissionsConfigs[i];
      if (config.type !== this.permissionDecorator.group) {
        continue;
      }

      let permissionsArr = [];

      if (config[action]) {
        // if action exists in config

        for (let j = 0; j <= config[action].length - 1; j++) {
          // iterate through action roles and options

          const option: string | PermissionsAction = config[action][j];

          if (typeof option !== 'object') {
            // checking if it's role or PermissionAction

            permissionsArr = config[action];
          } else {
            isCreator = this.isCreator(option);
          }
        }
      } else {
        console.error(
          `ERROR Error: Action ${action} does not exists in ${
            PermissionsGroups[this.permissionDecorator.group]
          } group`,
        );
        permissionExists = false;
        break;
      }

      if (permissionsArr.indexOf(this.employee.role) !== -1) {
        permissionExists = true;
        return permissionExists;
      }

      if (isCreator) {
        permissionExists = true;
        return permissionExists;
      }
    }
    return permissionExists;
  }

  isCreator(option: PermissionsAction) {
    if (this.permissionDecorator.objectCreatorId && option.type === PermissionType.OBJECT_CREATOR_ID) {
      if (Array.isArray(this.permissionDecorator.objectCreatorId)) {
        for (let i = 0; i <= this.permissionDecorator.objectCreatorId.length - 1; i++) {
          const creatorId = this.permissionDecorator.objectCreatorId[i];

          if (creatorId === this.employee.id) {
            return true;
          }
        }

        return false;
      }

      if (!Array.isArray(this.permissionDecorator.objectCreatorId)) {
        if (this.permissionDecorator.objectCreatorId !== this.employee.id) {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  }
}
