import { FaqComponent } from '@modules/help/pages/faq/faq.component';
import { HelpPanelComponent } from '@modules/help/pages/help-panel/help-panel.component';
import { FaqContentType, HelpFaqContentComponent } from '@modules/help/shared/components/help-faq-content/help-faq-content.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const HelpRoutes: CustomRoutes = [
  {
    path: '',
    component: HelpPanelComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/help/faq/global',
      },
      {
        path: 'faq',
        name: 'faq',
        component: FaqComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/help/faq/global',
          },
          {
            path: 'global',
            name: 'faq-global',
            component: HelpFaqContentComponent,
            data: {
              metaTitle: 'Help.faqGlobal',
              faqContentType: FaqContentType.GLOBAL,
            },
          },
          {
            path: 'account',
            name: 'faq-account',
            component: HelpFaqContentComponent,
            data: {
              metaTitle: 'Help.faqAccount',
              faqContentType: FaqContentType.ACCOUNT,
            },
          },
          // Removing: IS-359
          // {
          //   path: 'protocols',
          //   name: 'faq-protocols',
          //   component: HelpFaqContentComponent,
          //   data: {
          //     metaTitle: 'Help.faqProtocols',
          //     faqContentType: FaqContentType.PROTOCOLS
          //   },
          // },
        ],
        data: {
          metaTitle: 'Help.faq',
        },
      },
    ],
  },
];
