import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'trustUrl',
})
export class TrustUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  async transform(url: string): Promise<string> {
    return new Promise((resolve) => {
      return resolve(this.domSanitizer.bypassSecurityTrustUrl(url as string) as string);
    });
  }
}
