import { TaskInfluence } from '../enums/task-influence.enum';

export const FinancialList = [
  {
    id: TaskInfluence.NO_ESTIMATION,
    label: 'Financial.withoutEstimation',
    image: 'ic-question-mark.svg',
  },
  {
    id: TaskInfluence.PLUS,
    label: 'Financial.additionalCost',
    image: 'ic-plus-add-blue.svg',
  },
  {
    id: TaskInfluence.MINUS,
    label: 'Financial.savedCost',
    image: 'ic-minus.svg',
  },
  {
    id: TaskInfluence.NO_CHANGES,
    label: 'Financial.withoutInfluence',
    image: 'ic-zero.svg',
  },
];
