import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent {
  discount: FormControl = new FormControl(0, [Validators.min(0), Validators.max(Config.MAX_NUMBER)]);
  @Input() interactive: boolean = false;
  @Input() disabled: boolean = false;
  @Output() discountChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatMenuTrigger) discountMenu: MatMenuTrigger;

  get currencySymbol() {
    return this.store.Employee?.company?.currency?.symbol;
  }

  constructor(private t: TranslateService, private store: StorageService) {}

  submitDiscount() {
    if (this.discount.valid) {
      this.discountChanged.emit(parseFloat(this.discount.value.replace(',', '.')));
      this.discount.setValue(0);
      this.discountMenu.closeMenu();
    }
  }

  errorMessages() {
    const messages = Config.validationMessages;
    const control = this.discount;
    if (control?.errors?.min?.min !== undefined) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.discount', {
        number: control.errors?.min?.min,
      });
    }

    if (control?.errors?.max?.max !== undefined) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max?.max,
      });
    }

    return messages;
  }
}
