export enum ProjectStage {
  STAGE_CREATOR = 'project-creator',
  STAGE_CREATED = 'created',
  STAGE_MEETING = 'meeting',
  STAGE_ESTIMATE = 'estimate',
  STAGE_NEGOTIATIONS = 'negotiations',
  STAGE_WON = 'won',
  STAGE_BEFORE_REALIZATION = 'before-realization',
  STAGE_REALIZATION = 'realization',
  STAGE_APPROVED = 'approved',
  STAGE_PAYMENT = 'payment',
  STAGE_FINISHED = 'finished',
  STAGE_COMPLAINT = 'complaint',
  STAGE_LOST = 'lost',
}
