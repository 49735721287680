<div class="d-flex align-items-center py-1 participant w-100">
  <div class="d-flex align-items-center participant__container">
    <avatar [imageUrl]="imageUrl(participant?.contact?.employee)" [width]="36"></avatar>
    <div class="ml-2 participant__info">
      <b class="participant__info-name" [title]="participant.contact?.fullName || participant.email">
        {{ participant.contact?.fullName || participant.email }}
      </b>
      <div
        *ngIf="participant.contact?.employee?.role"
        class="participant__info-role"
        [title]="'ROLES.' + participant.contact?.employee?.role | translate"
      >
        {{ 'ROLES.' + participant.contact?.employee?.role | translate }}
      </div>
    </div>
  </div>

  <status-cell
    [status]="'CalendarEvents.AcceptanceStatus.' + participant.status | translate"
    [color]="getColor(participant)"
  ></status-cell>
</div>
