import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';

@Pipe({ name: 'rangeFormat', pure: true })
export class RangeFormatPipe implements PipeTransform {
  transform(start: string, end: string, isFullDay: boolean = false, onlyTime: boolean = false): string {
    const _start = start ? moment(start, Config.DATE_SERVER) : '';
    const _end = end ? moment(end, Config.DATE_SERVER) : '';

    const startDate = _start ? _start.format(Config.PREVIEW_DAY_MONTH) : '';
    const endDate = _end ? _end.format(Config.PREVIEW_DAY_MONTH) : '';

    const startTime = _start ? _start.format(Config.TIME) : '';
    const endTime = _end ? _end.format(Config.TIME) : '';

    const startYear = _start ? _start.format('YYYY') : '';
    const endYear = _end ? _end.format('YYYY') : '';

    const isSameYear = startYear === endYear;
    const sameDates = startDate === endDate;

    if (onlyTime) {
      return `${startTime} - ${endTime}`;
    }

    if (isFullDay) {
      if (isSameYear) {
        return sameDates ? `${startDate} ${startYear}` : `${startDate} - ${endDate} ${startYear}`;
      }
      return sameDates ? `${startDate} ${startYear}` : `${startDate} ${startYear} - ${endDate} ${endYear}`;
    }

    if (isSameYear) {
      return sameDates
        ? `${startDate} ${startYear} ${startTime} - ${endTime}`
        : `${startDate} ${startTime} - ${endDate} ${endTime} ${startYear}`;
    }
    return sameDates
      ? `${startDate} ${startYear} ${startTime} - ${endTime}`
      : `${startDate} ${startTime} ${startYear} - ${endDate} ${endTime} ${endYear}`;
  }
}
