import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';

@Component({
  selector: 'sidenav-title',
  templateUrl: './sidenav-title.component.html',
  styleUrls: ['./sidenav-title.component.scss'],
})
export class SidenavTitleComponent {
  @Input() title: string = 'Lets begin!';
  @Input() columnOnMobile: boolean = true;
  @Input() template: TemplateRef<any>;
  @Input() closeFunc: () => void;
  @Input() isEditEnabled = false;
  @Input() wrap = false;

  @Output() editClick = new EventEmitter<void>();

  constructor(public sidenav: SidenavService) {}
}
