import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input, NgZone,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { LanguageService } from '@core/services/language.service';
import { BaseComponent } from '@shared/components/base.component';
import { Subscription } from 'rxjs';
import { Notification } from '../../models/notification.model';
import {
  NotificationChangeEventType,
  NotificationsApiService,
} from '../../services/notifications-api.service';
import { NotificationProjectGlobalManager } from '@modules/notification/shared/managers/notification-project-global.manager';
import { INotificationAvatarConfig } from '@modules/notification/shared/interfaces/notification-avatar-config.interface';
import { NotificationProtocolManager } from '@modules/notification/shared/managers/notification-protocol.manager';
import { NotificationTaskManager } from '@modules/notification/shared/managers/notification-task.manager';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { NotificationCalendarManager } from '@modules/notification/shared/managers/notification-calendar.manager';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent extends BaseComponent implements AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  private _notification: Notification<any>;


  @Input()
  set notification(notification: Notification<any>) {
    this._notification = notification;
    this.changes.detectChanges();
  }

  get notification() {
    return this._notification;
  }

  @ViewChild('notificationContent', {read: ViewContainerRef}) notificationContent: ViewContainerRef;

  sub: Subscription;
  avatarUrl: string = '';
  avatarConfig: INotificationAvatarConfig;
  hideAvatar: boolean = false;
  notificationClickFunction?: Function;

  constructor(
    private resolver: ComponentFactoryResolver,
    private changes: ChangeDetectorRef,
    public langService: LanguageService,
    private service: NotificationsApiService,
    private zone: NgZone
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.createNotificationTypeComponent();
  }

  createNotificationTypeComponent() {
    this.notificationContent.clear();
    let manager: NotificationProjectGlobalManager | NotificationProtocolManager | NotificationTaskManager | NotificationCalendarManager = null;

    if (this.notification.type.toString().substring(0, 8) === 'project_') {
      manager = new NotificationProjectGlobalManager(this.notification, this.markAsShown.bind(this));
    } else if (this.notification.type.toString().substring(0, 9) === 'protocol_') {
      manager = new NotificationProtocolManager(this.notification, this.markAsShown.bind(this));
    } else if (this.notification.type.toString().substring(0, 5) === 'task_') {
      manager = new NotificationTaskManager(this.notification, this.markAsShown.bind(this));
    } else if (this.notification.type.toString().substring(0, 15) === 'calendar_event_') {
      manager = new NotificationCalendarManager(this.notification, this.markAsShown.bind(this));
    }

    this.notificationClickFunction = manager?.createComponent(this.notificationContent);
    this.avatarConfig = manager?.avatarConfig;
    this.changes.detectChanges();
  }

  show() {
    this.notificationClickFunction ? this.notificationClickFunction() : null;
    this.markAsShown();
  }

  markAsShown() {
    if (!this.notification.shown) {
      this.notification.shown = true;
      this.service.show(this.notification.id).toPromise();
      this.service.changeEmitter.emit({type: NotificationChangeEventType.DECREASE_COUNTER});
    }
  }
}
