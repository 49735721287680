import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { ProtocolsManageService } from './protocols-manage.service';
import { catchError, map } from 'rxjs/operators';
import { Config } from '@shared/configs/config';
import { Entry } from '../interfaces/entry.interface';
import { AcceptanceData } from '../interfaces/acceptance-data.interface';
import { ProjectData } from '../interfaces/project-data.interface';
import { MeetingData } from '../interfaces/meeting-data.interface';
import { Invoice } from '@shared/interfaces/invoice.interface';
import { Observable } from 'rxjs';
import { Task } from '@shared/models/task.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { Protocol } from '../models/protocol';
import { IProtocolsDraft } from '../interfaces/protocols-draft.interface';
import { UsedEmail } from '@shared/interfaces/used-email.interface';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Project } from '@modules/projects/shared/models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProtocolApiService extends BaseHttpService {
  loadingProtocol: boolean = false;

  constructor(public manager: ProtocolsManageService) {
    super();
  }

  getSimpleProtocol(id: number, notReturnValue?: boolean): Observable<Protocol> {
    const url = `${Config.API}/protocol/${id}/simple`;
    return this.get(url, false, {}).pipe(
      map((p) => {
        if (!notReturnValue) {
          this.manager.successGetProtocolDraft(p, true);
        } else {
          return p;
        }
      }),
    );
  }

  getProtocolDraft(id: number): Observable<Protocol> {
    const url = `${Config.API}/protocol/${id}`;
    let params = {};
    if (Number(id) === 0) {
      // it means that this is tutorial
      params = {
        tutorial: 1,
      };
    }
    return this.get(url, false, params).pipe(map((p) => this.manager.successGetProtocolDraft(p)));
  }

  getEmployees(query?: string): Observable<Array<any>> {
    const url = `${Config.API}/employee/search`;
    return this.get(url, false, query?.length ? {query} : {}).pipe(
      map((list) => this.manager.successGetEmployees(list)),
    );
  }

  getUsedEmails(query?: string): Observable<Array<UsedEmail>> {
    const url = `${Config.API}/used-email/search`;
    return this.get(url, false, query?.length ? {query} : {});
  }

  //#region Client Interaction with Protocol
  sendProtocolPlain(email: string, id: number) {
    const url = `${Config.API}/protocol/${id}/send-plain`;
    return this.postWithSaving(url, {email, client: false});
  }

  sendProtocolAcceptance(email: string, id: number) {
    const url = `${Config.API}/protocol/${id}/acceptance`;
    return this.postWithSaving(url, {email});
  }

  getProtocolPDF(id: number, clientPreview?: boolean) {
    const url = `${Config.API}/protocol/${id}/pdf`;
    const params = {};
    clientPreview ? (params['client'] = clientPreview) : null;
    return this.getFileBlob(url, false, params);
  }

  getAcceptanceProtocolPDF(id: number, skipAuth?: boolean) {
    const url = `${Config.API}/protocol-acceptance/${id}/pdf`;
    return this.getFileBlob(url, skipAuth);
  }

  //#endregion

  // #region Protcol Data

  getProject(query?: string): Observable<Array<Project>> {
    const url = `${Config.API}/project/search`;
    return this.get(url, false, query?.length ? {query} : {});
  }

  createProject(data): Observable<Project> {
    const url = `${Config.API}/project`;
    return this.postWithSaving(url, data);
  }

  editProject(data): Observable<Project> {
    const url = `${Config.API}/project/${data.id}`;
    return this.postWithSaving(url, data);
  }

  createContact(data): Observable<Contact> {
    const url = `${Config.API}/contact`;
    return this.postWithSaving(url, data);
  }

  createExternalCompany(data): Observable<Contact> {
    const url = `${Config.API}/external-company`;
    return this.postWithSaving(url, data);
  }

  //#endregion

  //#region Protocol creating
  createProtocolDraft(draft: IProtocolsDraft): Observable<Protocol> {
    const url = `${Config.API}/protocol`;
    return this.postWithSaving(url, draft).pipe(map((p) => this.manager.setProtocol(p)));
  }

  editProtocolDraft(draft: IProtocolsDraft): Observable<Protocol> {
    const url = `${Config.API}/protocol/${this.manager.protocol.id}`;
    return this.postWithSaving(url, draft).pipe(map((p) => this.manager.setProtocol(p)));
  }

  activateProtocol(id: number) {
    const url = `${Config.API}/protocol/${id}/activate`;
    return this.postWithSaving(url, {}).pipe(map((p) => this.manager.setProtocol(p)));
  }

  //#endregion

  //#region  Task
  addTask(data): Observable<Task> {
    const url = `${Config.API}/protocol/${this.manager.protocol.id}/task`;
    this.startSavingTasks();
    return this.postWithSaving(url, data, false, true);
  }

  getTask(id: number) {
    const url = `${Config.API}/task/${id}`;
    return this.get(url);
  }

  editTask(data, id: number): Observable<Task> {
    const url = `${Config.API}/task/${id}`;
    this.startSavingTasks();
    return this.postWithSaving(url, data, false, true);
  }

  patchTask(data, id: number) {
    const url = `${Config.API}/task/${id}`;
    this.startSavingTasks();
    return this.patchWithSaving(url, data, false, true);
  }

  reorderTask(newPosition: number, task: Task): Observable<Task> {
    const url = `${Config.API}/task/${task.id}/reorder`;
    this.startSavingTasks();
    const body = {
      newPosition,
      newType: task.type
    };
    return this.postWithSaving(url, body);
  }

  groupEditStatus(tasksIds: Array<number>, completed: boolean) {
    const params = {
      tasksIds,
      status: completed ? 'completed' : 'created',
    };
    const url = `${Config.API}/task/bulk/status`;
    this.startSavingTasks();
    return this.postWithSaving(url, params);
  }

  groupAssign(tasksIds: Array<number>, assignedToIds: Array<number>) {
    const params = {
      tasksIds,
      assignedToIds,
    };
    const url = `${Config.API}/task/bulk/assigned-to`;
    this.startSavingTasks();
    return this.postWithSaving(url, params);
  }

  groupDiscount(tasksIds: Array<number>, discount: number) {
    const params = {
      tasksIds,
      discount,
    };
    const url = `${Config.API}/task/bulk/discount`;
    this.startSavingTasks();
    return this.postWithSaving(url, params);
  }

  groupTerm(tasksIds: Array<number>, termStart: string, termEnd: string) {
    const params = {
      tasksIds,
      termStart,
      termEnd,
    };
    const url = `${Config.API}/task/bulk/term`;
    this.startSavingTasks();
    return this.postWithSaving(url, params);
  }

  deleteTask(id: number) {
    const url = `${Config.API}/task/${id}`;
    this.startSavingTasks();
    return this.deleteWithSaving(url, false);
  }

  //#endregion

  //#region Invoice
  createInvoice(data: Invoice) {
    const url = `${Config.API}/project/${this.manager.ProjectID}/invoice`;
    return this.postWithSaving(url, this.manager.amountsToPenny(data), false, true);
  }

  setInvoice(data: Invoice) {
    const url = `${Config.API}/invoice/${data.id}`;
    return this.postWithSaving(url, this.manager.amountsToPenny(data), false, true);
  }

  deleteInvoice(id: number) {
    const url = `${Config.API}/invoice/${id}`;
    return this.deleteWithSaving(url, false);
  }

  //#endregion

  // #region Protocol Types Saving
  setAcceptanceProtocolData(data: AcceptanceData) {
    const url = `${Config.API}/acceptance-protocol-data/${this.manager.protocol.id}`;
    return this.postWithSaving(url, data, false, true).pipe(
      map((i) => this.manager.successAcceptanceDataUpdate(i)),
    );
  }

  setProjectProtocolData(data: ProjectData) {
    const url = `${Config.API}/project-protocol-data/${this.manager.protocol.id}`;
    return this.postWithSaving(url, data, false, true);
  }

  setProjectMeetingData(data: MeetingData) {
    const url = `${Config.API}/meeting-protocol-data/${this.manager.protocol.id}`;
    return this.postWithSaving(url, data, false, true);
  }

  //#endregion

  // #region signature
  setSignatures(data) {
    const url = `${Config.API}/protocol/${this.manager.protocol.id}/signature`;
    return this.postWithSaving(url, data, false, true);
  }

  // #endregion

  // #region Entry
  addEntryToProtocol(data: any) {
    const url = `${Config.API}/${this.manager.ProtocolTexttype}/${this.manager.protocol.id}/entry`;
    return this.postWithSaving(url, data, false, true).pipe(
      map((entry) => this.manager.successAddEntry(entry)),
    );
  }

  editEntryToProtocol(data: any, id: number) {
    const url = `${Config.API}/entry/${id}`;
    return this.postWithSaving(url, data, false, true).pipe(
      map((entry) => this.manager.successEditEntry(entry)),
    );
  }

  deleteProtocolEntry(entry: Entry) {
    const url = `${Config.API}/entry/${entry.id}`;
    return this.deleteWithSaving(url, false).pipe(map(() => this.manager.successDeleteEntry(entry)));
  }

  //#endregion

  // #region Protocol
  deleteProtocol(id: number) {
    const url = `${Config.API}/protocol/${id}`;
    return this.deleteWithSaving(url, false);
  }

  // #endregion

  postWithSaving(
    url: string,
    body: any,
    skipAuth: boolean = false,
    multipart: boolean = false,
    options?: any,
  ) {
    this.manager.startSave();
    return this.post(url, body, skipAuth, multipart, options).pipe(
      map((res) => this.successSaving(res)),
      catchError((err) => this.manager.errorSaving(err)),
    );
  }

  patchWithSaving(url: string, body: any, skipAuth: boolean = false, options?: any) {
    this.manager.startSave();
    return this.patch(url, body, options, skipAuth).pipe(
      map((res) => this.successSaving(res)),
      catchError((err) => this.manager.errorSaving(err)),
    );
  }

  deleteWithSaving(url: string, skipAuth: boolean = false, options?: any) {
    this.manager.startSave();
    return this.delete(url, skipAuth, options).pipe(
      map((res) => this.successSaving(res)),
      catchError((err) => this.manager.errorSaving(err)),
    );
  }

  startSavingTasks() {
    if (!!this.deepestSnapshot?.data?.edit) {
      this.manager.TaskContainer = document.querySelector('.cupertino-container');
      this.manager.Offset = this.manager.TaskContainer?.scrollTop;
    }
  }

  successSaving(res) {
    !!this.deepestSnapshot?.data?.edit && this.manager?.protocol?.id ? this.updateSimpleProtocol() : null;
    return this.manager.successSaving(res);
  }

  updateSimpleProtocol() {
    this.getSimpleProtocol(this.manager?.protocol?.id).subscribe();
  }
}
