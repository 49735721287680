<button class="btn task-item_task-change-btn" mat-icon-button [matMenuTriggerFor]="menu">
  <span [innerHTML]="'Protocols.changeFor' | translate"></span>
  <svg-icon class="ml-2" src="./assets/img/ic-arrow-down-blue.svg"></svg-icon>
</button>

<mat-menu #menu="matMenu" xPosition="after">
  <ul class="m-0">
    <li
      class="d-flex align-items-center"
      mat-menu-item
      (click)="changeType(task, TaskTypes.TYPE_TASK)"
      [innerHTML]="'Protocols.taskItem' | translate"
    ></li>
    <li
      mat-menu-item
      class="d-flex align-items-center"
      (click)="changeType(task, TaskTypes.TYPE_CORRECTION)"
      [innerHTML]="'Protocols.correctionItem' | translate"
    ></li>
    <li
      mat-menu-item
      class="d-flex align-items-center"
      (click)="changeType(task, TaskTypes.TYPE_CHANGE)"
      [innerHTML]="'Protocols.changeItem' | translate"
    ></li>
    <li
      mat-menu-item
      class="d-flex align-items-center"
      (click)="changeType(task, TaskTypes.TYPE_ORDER)"
      [innerHTML]="'Protocols.orderItem' | translate"
    ></li></ul
></mat-menu>
