<section class="project-question-directory">
  <div class="project-question-directory__title">
    <content-title
      [title]="('Projects.Menu.questions' | translate) + ' 1'"
      [subtitle]="'Projects.mainContactDescription' | translate"
    >
    </content-title>
  </div>
  <!-- #region FIRST STAGE -->
  <div>
    <div class="project-question-directory__region-title">1. {{ 'Projects.defaultData' | translate }}</div>
    <div class="project-question-directory__region-subtitle">{{ 'Projects.projectInfo' | translate }}</div>
    <directory-first-step-one [form]="projectStore.mainDirectoryFirstForm"></directory-first-step-one>
  </div>

  <div>
    <div class="project-question-directory__region-title">
      2. {{ 'Projects.realizationTerm' | translate }}
    </div>
    <div class="project-question-directory__region-subtitle">{{ 'Projects.estimateTime' | translate }}</div>
    <directory-first-step-two [form]="projectStore.mainDirectoryFirstForm"></directory-first-step-two>
  </div>

  <hr />

  <!-- <directory-first-tasks></directory-first-tasks> -->
  <div class="mt-5">
    <commentary-manage
      #comments
      [attachmentsConfig]="attachmentsConfig"
      [id]="projectStore.projectCtrl?.project?.id"
      [type]="CommentaryType.BASIC_DATA_BOX"
      [commentaries]="projectStore.projectCtrl.project?.basicDataBox?.comments"
      (commentaryEvent)="commentaryEvent($event)"
    ></commentary-manage>
  </div>

  <hr />

  <form [formGroup]="projectStore.mainDirectoryFirstForm">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div class="bold mb-2">{{ 'Projects.projectNameSystem' | translate }}</div>
        <gbxsoft-input
          class="required"
          [errMessages]="errorMessages()"
          [formControlName]="PROJECT_DATA_FORM_BOX.name"
          [config]="{ name: 'Projects.projectName' | translate }"
        ></gbxsoft-input>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div class="bold mb-2">{{ 'Projects.employeeResponsible' | translate }}</div>

        <ng-select
          [clearable]="false"
          ngSelectExtension
          [ignoreLocalSearch]="true"
          [initialValue]="initialPerson"
          [searchGetParam]="'search'"
          [formControlName]="PROJECT_DATA_FORM_BOX.responsibleEmployeeId"
          class="select select-default select-with-title mb-3 w-100"
          [url]="CONFIG.API + '/employee/search?' + searchRoles"
          [bindValue]="'helperID'"
          [bindLabel]="'previewName'"
          [tabIndex]="-1"
          [responseTransform]="transformEmployees.bind(this)"
          [placeholder]="'Projects.selectEmployee' | translate"
          [addTagText]="'Projects.addTagText' | translate"
          [loadingText]="'Projects.loading' | translate"
          [notFoundText]="'Projects.notFoundText' | translate"
        ></ng-select>
      </div>
    </div>
  </form>
</section>
