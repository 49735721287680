import { CalendarApi, EventApi } from '@fullcalendar/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { TranslateService } from '@ngx-translate/core';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ResourceApi } from '@fullcalendar/resource-common';

export class ProjectsTimelineEventController {
  t: TranslateService;

  constructor(private calendar: CalendarApi) {
    this.t = AppInjector.getInjector().get(TranslateService);
  }

  addProjectsEvents(projects: Project[]) {
    for (let i = 0; i < projects.length; i++) {
      const project = new Project(projects[i]);
      this.addProjectEvent(project);
    }
  }

  addStagesEvents(stages: IProjectStage[]) {
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      if (stage.termEnd && stage.termStart) {
        this.addStageEvent(stage);
      }
    }
  }

  updateClosedStageEvent(stage: ProjectStage, startDate: moment.Moment, endDate: moment.Moment) {
    let stageEvent: EventApi = this.calendar.getEventById(stage);
    let stageResource: ResourceApi = this.calendar.getResourceById(stage);

    if (!startDate && !endDate && stageEvent) {
      stageEvent.remove();
      return;
    }

    if (!stageEvent && startDate && endDate) {
      this.addStageEvent({
        stage,
        termStart: startDate.format(Config.DATE_SERVER),
        termEnd: endDate.format(Config.DATE_SERVER),
        projectsCount: stageResource.extendedProps.projectsCount,
      });
      stageResource.setExtendedProp('termStart', startDate ? startDate.format(Config.DATE_SERVER) : null);
      stageResource.setExtendedProp('termEnd', endDate ? endDate.format(Config.DATE_SERVER) : null);
      return;
    }

    if (startDate && startDate.isBefore(moment(stageEvent.start))) {
      stageEvent.setStart(startDate.toDate());
      stageResource.setExtendedProp('termStart', startDate ? startDate.format(Config.DATE_SERVER) : null);
    }

    if (endDate && endDate.isAfter(moment(stageEvent.end))) {
      stageEvent.setEnd(endDate.toDate());
      stageResource.setExtendedProp('termEnd', endDate ? endDate.format(Config.DATE_SERVER) : null);
    }

    stageEvent ? stageEvent.setProp('title', this.t.instant('ProjectsTimeline.projectsInStage', {count: stageResource.extendedProps.projectsCount})) : '';
  }

  getProjectsResourcesCount(stage: ProjectStage): number {
    let count = 0;
    const resources = this.calendar.getResources();
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      if (resource._resource.parentId === stage) {
        count++;
      }
    }
    return count;
  }

  updateOpenedStageEvent(stage: ProjectStage) {
    let stageEvent: EventApi = this.calendar.getEventById(stage);
    let stageResource: ResourceApi = this.calendar.getResourceById(stage);
    let startDate: moment.Moment = null;
    let endDate: moment.Moment = null;
    const projectsResourcesCount = this.getProjectsResourcesCount(stage);
    const events = this.calendar.getEvents();

    for (let i = 0; i < events.length; i++) {
      const e = events[i];
      if (e._def.extendedProps.stage === stage) {
        if (!startDate || moment(e.start).isBefore(startDate)) {
          startDate = moment(e.start);
        }
        if (!endDate || moment(e.end).isAfter(endDate)) {
          endDate = moment(e.end);
        }
      }
    }

    if (!stageEvent) {
      this.addStageEvent({
        stage: stage,
        termStart: startDate ? startDate.format(Config.DATE_SERVER) : null,
        termEnd: endDate ? endDate.format(Config.DATE_SERVER) : null,
        projectsCount: projectsResourcesCount,
      });
    } else if (!startDate && !endDate) {
      stageEvent.remove();
    } else {
      stageEvent.setStart(startDate ? startDate.toDate() : null);
      stageEvent.setEnd(endDate ? endDate.toDate() : null);//update count in stage event
      stageEvent.setProp('title', this.t.instant('ProjectsTimeline.projectsInStage', {count: projectsResourcesCount}));
    }


    stageResource.setExtendedProp('termStart', startDate ? startDate.format(Config.DATE_SERVER) : null);
    stageResource.setExtendedProp('termEnd', endDate ? endDate.format(Config.DATE_SERVER) : null);
  }

  addStageEvent(stage: IProjectStage) {
    return this.calendar.addEvent({
      id: stage.stage,
      title: this.t.instant('ProjectsTimeline.projectsInStage', {count: stage.projectsCount}),
      classNames: ['fc-event--' + stage.stage, 'fc-event--resource-event'],
      resourceId: stage.stage,
      start: moment(stage.termStart, Config.DATE_SERVER).toDate(),
      end: moment(stage.termEnd, Config.DATE_SERVER).toDate(),
      eventOverlap: true,
      extendedProps: {
        isStageEvent: true,
      },
    });
  }

  addProjectEvent(project: Project) {
    if(!project?.basicDataBox?.termStart || !project?.basicDataBox?.termEnd) return; // not add event if it's hasn't terms
    return this.calendar.addEvent({
      id: project.id.toString(),
      title: project.fullName,
      extendedProps: project,
      editable: true,
      classNames: ['fc-event--' + project.stage, 'fc-event--project-event'],
      resourceEditable: true,

      constraint: {
        resourceIds: [project.id.toString()],
      },
      resourceId: project.id.toString(),
      start: moment(project.basicDataBox.termStart, Config.DATE_SERVER).toDate(),
      end: moment(project.basicDataBox.termEnd, Config.DATE_SERVER).toDate(),
    });
  }

  updateProjectEvent(project: Project) {
    const event = this.calendar.getEventById(project.id.toString());
    if (event) {

      if (!project.basicDataBox.termStart || !project.basicDataBox.termEnd) {
        event.remove();
        this.updateOpenedStageEvent(project.stage);
        return;
      }

      event.setStart(moment(project.basicDataBox.termStart).toDate());
      event.setEnd(moment(project.basicDataBox.termEnd).toDate());
      event.setProp('classNames', 'fc-event--' + project.stage + ' fc-event--project-event');
      event.setExtendedProp('basicDataBox', project.basicDataBox);
      event.setExtendedProp('stage', project.stage);
    } else {
      this.addProjectEvent(project);
    }
    this.updateOpenedStageEvent(project.stage);
  }

  removeEventsWithoutResources() {
    const events = this.calendar.getEvents();
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (!event.getResources()[0]) {
        event.remove();
      }
    }
  }
}
