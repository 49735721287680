import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { Task } from '@shared/models/task.model';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';

export class ProjectDataBox {
  additionalComment: string;
  created: string;
  deleted: string;
  id: number;
  modified: string;
  note: string;
  type: string;
  project2Box?: any;

  tasks: Task[] = [];
  entries: Entry[] = [];
  comments?: Commentary[] = [];
  entriesTypeList: { [key: string]: Entry[] } = {};

  constructor(box?: ProjectDataBox) {
    Object.keys(EntryType).forEach((key) => {
      this.entriesTypeList[EntryType[key]] = [];
    });

    box ? this.deserialize(box) : null;
  }

  deserialize(box: ProjectDataBox) {
    Object.assign(this, box);
    this.comments = box.comments?.map((c) => new Commentary(c)) || [];
    this.entries = box.entries?.map((e) => new Entry(e)) || [];
    this.tasks = box.tasks?.map((t) => new Task(t)) || [];
    Object.keys(this.entriesTypeList).forEach((key) => {
      this.entriesTypeList[key] = this.entries.filter((i) => i.type === key) || [];
    });
  }
}
