import { AppTourConfig } from '@shared/modules/app-tour/shared/interfaces/app-tour-config.interface';
import { AppTourTypes } from '../enums/app-tour-types.enum';
import { CustomIStepOptionInterface } from '../interfaces/custom-i-step-option.interface';

export class AppTourSecondBoxConfig implements AppTourConfig {
  static readonly type: AppTourTypes = AppTourTypes.QUESTION_DIRECTORY_SECOND;
  static readonly steps: CustomIStepOptionInterface[] = [
    {
      anchorId: 'questionDirectory.s1.entryButton',
    },
  ];

  static readonly startComponentName = 'project-second-box-edit';
  static readonly startComponentParams = { id: 0 };
  static readonly ignoreMarking = true;
}
