import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { SettingsPanelComponent } from '@modules/settings/pages/settings-panel/settings-panel.component';
import { CompanySettingsComponent } from '@modules/settings/pages/company-settings/company-settings.component';
import { UserSettingsComponent } from '@modules/settings/pages/user-settings/user-settings.component';
import { TeamSettingsComponent } from '@modules/settings/pages/team-settings/team-settings.component';
import { CompanySettingsGuard } from '@core/guards/settings/company-settings.quard';
import { CompanyTeamGuard } from '@core/guards/settings/company-team.guard';
import { EmailSettingsComponent } from '@modules/settings/pages/email-settings/email-settings.component';
import { EmailGoogleAuthResolver } from '@modules/settings/shared/resolvers/email-google-auth.resolver';

export const SettingsRoutes: CustomRoutes = [
  {
    path: '',
    component: SettingsPanelComponent,
    data: {
      hideProgressBar: true,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/panel/settings/user',
      },
      {
        path: 'company',
        name: 'company-settings',
        canActivate: [CompanySettingsGuard],
        data: {
          metaTitle: 'Settings.company',
          bottomBar: true,
        },
        children: [
          {
            path: '',
            component: CompanySettingsComponent,
          },
        ],
      },
      {
        path: 'user',
        name: 'user-settings',
        data: {
          metaTitle: 'Settings.user',
          bottomBar: true,
        },
        children: [
          {
            path: '',
            component: UserSettingsComponent,
          },
        ],
      },
      {
        path: 'email',
        name: 'email-settings',
        resolve: [EmailGoogleAuthResolver],
        data: {
          metaTitle: 'Settings.email',
          bottomBar: true,
        },
        children: [
          {
            path: '',
            component: EmailSettingsComponent,
          },
        ],
      },
      {
        path: 'team',
        name: 'team-settings',
        canActivate: [CompanyTeamGuard],
        children: [
          {
            path: '',
            component: TeamSettingsComponent,
          },
        ],
        data: {
          hideProgressBar: true,
          metaTitle: 'Settings.team',
        },
      },
    ],
  },
];
