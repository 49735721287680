import { Component, OnInit } from '@angular/core';
import { ListService } from '@shared/modules/list/services/list.service';

@Component({
  selector: 'list-loader',
  templateUrl: './list-loader.component.html',
  styleUrls: ['./list-loader.component.scss']
})
export class ListLoaderComponent implements OnInit {

  constructor(
    public service: ListService
  ) {
  }

  ngOnInit(): void {
  }

}
