<modal-title [title]="'Settings.Team.editEmployeeTitle' | translate"></modal-title>
<!-- #region Content -->
<form [formGroup]="form">
  <gbxsoft-select class="select"
                  [errMessages]="errorMessages('role')"
                  formControlName="role"
                  [config]="positionSelectConfig"
                  [options]="positionSelectOptions"></gbxsoft-select>
  <gbxsoft-input
    [errMessages]="errorMessages('hourlyRate')"
    [formControlName]="'hourlyRate'"
    [config]="{ name: 'Settings.Team.hourlyRate' | translate, type: 'number', appendContent: currencySymbol, placeholder: '' }"
  ></gbxsoft-input>
</form>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <button class="btn btn-cancel mr-md-2 mb-2 mb-md-0 btn-sm-block"
          (click)="closeModal()">
    {{ 'Settings.Team.cancel' | translate }}
  </button>
  <button class="btn btn-primary ml-md-1 btn-sm-block"
          (click)="submit()"
          [disabled]="loading">
    <span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"></span>
    <img draggable="false" *ngIf="!loading"
         src="./assets/img/ic-arrow-right.svg"
         [alt]="'Settings.Team.save' | translate"/>
    {{ 'Settings.Team.save' | translate }}
  </button>
</mat-dialog-actions>
<!-- #endregion -->
