<div class="selection-change-title d-flex align-items-center justify-content-between">
  <span [innerHTML]="title"></span>
  <help-icon [message]="message"></help-icon>
</div>
<form [formGroup]="form" class="selection-change d-flex align-items-center">
  <ng-select
    formControlName="select"
    [items]="items"
    [placeholder]="selectConfig?.placeholder"
    [searchable]="false"
    [clearable]="false"
    [tabIndex]="-1"
    (change)="changeValue($event)"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <span class="ng-arrow-option d-flex align-items-center" [title]="item.label">
        <img draggable="false" *ngIf="item.image" class="mr-2" height="16" width="16" [src]="'/assets/img/' + item.image" />
        <span [innerHTML]="item.label"> </span>
      </span>
    </ng-template>
  </ng-select>
  <gbxsoft-input
    [errMessages]="errorMessages('input')"
    #input
    [disabled]="disabledInput"
    formControlName="input"
    [config]="config"
  ></gbxsoft-input>
</form>
