import { HttpError } from '@shared/interfaces/error.interface';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AttachmentController } from '../../shared/controllers/attachment.controller';

@Component({
  selector: 'attachment-file',
  templateUrl: './attachment-file.component.html',
  styleUrls: ['./attachment-file.component.scss'],
})
export class AttachmentFileComponent implements OnInit {
  downloading: boolean = false;
  restoring: boolean = false;

  @Input() file: Attachment;
  @Input() edit: boolean = false;
  @Input() downloadable: boolean = true;

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}

  restoreAttachment() {
    if (this.restoring || !this.edit) return;
    this.restoring = true;
    const ctrl = new AttachmentController();
    ctrl.restoreAttachment(this.file).subscribe({
      next: (attachment: Attachment) => this.successRestore(attachment),
      error: (err: HttpError) => this.errorRestore(),
    });
  }

  successRestore(attachment: Attachment) {
    this.restoring = false;
    this.file.deleted = null;
    this.file = new Attachment(this.file);
    this.changes.detectChanges();
  }

  errorRestore() {
    this.restoring = false;
    this.changes.detectChanges();
  }
}
