import { Component, Input, OnInit } from '@angular/core';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'list-empty-states',
  templateUrl: './list-empty-states.component.html',
  styles: [],
})
export class ListEmptyStatesComponent implements OnInit {
  @Input() emptyTemplate;

  constructor(public service: ListService) { }

  ngOnInit(): void { }

  get isActiveFilter() {
    let isActiveFilter = false;
    this.service?.filtersForm ? Object.keys(this.service?.filtersForm?.value).forEach((key) => {
      if (this.service?.filtersForm?.value[key]) {
        isActiveFilter = true;
      }
    }) : null;
    return isActiveFilter;
  }

}
