export const addStyleToParagraphs = (htmlStr: string, cssStyles: any = '') => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlStr, 'text/html');
  const tags = doc.querySelectorAll('p,ol,ul,blockquote');
  for (let i = 0; i < tags.length; i++) {
    if (typeof cssStyles === 'object' && cssStyles !== null) {
      const tagName = tags[i].tagName?.toLowerCase();
      // { 'p': '', 'ul':'padding:0;margin:0;'}
      tags[i].setAttribute('style', cssStyles[tagName]);
    } else {
      tags[i].setAttribute('style', cssStyles);
    }
  }
  return doc.documentElement.outerHTML;
};
