import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { ProtocolAcceptanceType } from '@modules/protocols/shared/components/protocol-acceptance/protocol-acceptance.component';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { BaseComponent } from '@shared/components/base.component';
import { ProtocolAcceptance } from '@modules/protocols/shared/interfaces/protocol-acceptance.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'notification-protocol-acceptance',
  templateUrl: './notification-protocol-acceptance.component.html',
  styleUrls: ['./notification-protocol-acceptance.component.scss'],
})
export class NotificationProtocolAcceptanceComponent extends BaseComponent implements AfterViewInit {
  PermissionsGroups = PermissionsGroups;

  text: string;
  protocol: Protocol;
  id: number;

  markAsReadEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private pService: ProtocolApiService, private changes: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.changes.detectChanges();
  }

  accept() {
    const ctrl = new ProtocolController(this.pService, this.protocol);
    ctrl.acceptance(ProtocolAcceptanceType.ACCEPT, this.id);
    this.markAsReadEmitter.emit(true);
  }

  decline() {
    const ctrl = new ProtocolController(this.pService, this.protocol);
    ctrl.acceptance(ProtocolAcceptanceType.DECLINE, this.id);
    this.markAsReadEmitter.emit(true);
  }

  preview() {
    const ctrl = new ProtocolController(this.pService, this.protocol);
    ctrl.previewAcceptance(null, this.id);
  }
}
