import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { map } from 'rxjs/operators';
import { Employee } from '@shared/models/employee.model';

@Injectable()
export class EmployeeService extends BaseHttpService {

  constructor() {
    super();
  }

  getEmployees(query: string) {
    const url = `${Config.API}/employee/search`;
    return this.get(url, false, {query});
  }
}
