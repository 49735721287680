import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectChangeStageEventType } from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';

@Component({
  selector: 'project-star-end-term-modal',
  templateUrl: './project-star-end-term-modal.component.html',
  styleUrls: ['./project-star-end-term-modal.component.scss'],
})
export class ProjectStarEndTermModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  project: Project;
  emitter: EventEmitter<{ termStart, termEnd }> = new EventEmitter<{ termStart; termEnd }>();
  form: FormGroup = new FormGroup({
    termStart: new FormControl(null, [Validators.required]),
    termEnd: new FormControl(null, [Validators.required]),
  });
  newStage: ProjectStage;

  constructor(
    public dialogRef: MatDialogRef<ProjectStarEndTermModalComponent>,
    private s: SnackBarService,
    private t: TranslateService,
    private projectStageApiService: ProjectStageAPIService,
    private changes: ChangeDetectorRef,
    private projectPreviewService: ProjectPreviewService,
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    if (!this.form.value['termStart'] || !this.form.value['termEnd']) {
      this.s.error(this.t.instant('Projects.PreviewHeader.termsError'));
      return;
    }
    this.loading = true;
    this.projectStageApiService.setStage(this.project.id, this.newStage, null, this.form.get('termStart').value, this.form.get('termEnd').value).subscribe((res: Project) => {
      this.dialogRef.close();
      this.s.success(this.t.instant('Projects.PreviewHeader.termsSaved'));
      this.loading = false;
      this.changes.detectChanges();
      if (this.projectPreviewService.project) {
        this.projectPreviewService.project = res;
      }
    });
  }

  rangeChanged($event) {
    const start = $event.start ? moment($event.start, Config.DATE_SERVER).format(Config.DATE_SERVER) : null;
    const end = $event.end ? moment($event.end, Config.DATE_SERVER).format(Config.DATE_SERVER) : null;
    this.form.get('termStart').setValue(start);
    this.form.get('termEnd').setValue(end);
  }

  cancel() {
    this.dialogRef.close();
  }
}
