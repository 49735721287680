
export interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: GoogleAdressComponentType[];
}

export enum GoogleAdressComponentType {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  TOWN = 'locality',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code'
}
