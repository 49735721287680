<div class="settings settings-content">
  <content-title
    [title]="'Settings.Email.title' | translate"
    [subtitle]="'Settings.Email.subtitle' | translate"
  >
  </content-title>
  <div [formGroup]="form">
    <div class="mb-2">
      <div class="content-with-tabs-content-title mb-1 mb-sm-2 h1 grey-1">
        {{ 'Settings.Email.selectType' | translate }}
      </div>
      <div class="form-control-checkbox form-control-checkbox--rounded mr-4">
        <label class="m-0 d-flex align-items-center">
          <input
            [formControlName]="EmailSettingsForm.mailMethod"
            type="radio"
            [value]="UserMailMethod.default"
          />
          <span></span>
          <span [innerHTML]="'Settings.Email.systemType' | translate"></span>
        </label>
      </div>

      <div class="form-control-checkbox form-control-checkbox--rounded">
        <label class="m-0 d-flex align-items-center">
          <input
            [formControlName]="EmailSettingsForm.mailMethod"
            type="radio"
            [value]="UserMailMethod.google"
          />
          <span></span>
          <span [innerHTML]="'Settings.Email.googleType' | translate"></span>
        </label>
      </div>
    </div>
    <div class="mt-3" *ngIf="form.get(EmailSettingsForm.mailMethod).value === UserMailMethod.google">
      <button
        (click)="getAccessGoogleAccount()"
        class="btn btn-empty_grey d-flex align-items-center p-1 px-2"
      >
        <img class="mr-2" src="./assets/img/ic-google.svg" alt="google" />
        {{ 'Settings.Email.googleAuth' | translate }}
      </button>
    </div>
    <hr />

    <div>
      <div class="content-with-tabs-content-title mb-1 mb-sm-2 h1 grey-1">
        {{ 'Settings.Email.descriptionTitle' | translate }}
      </div>
      <div class="form-control-checkbox form-control-checkbox--rounded mb-3">
        <label class="m-0 d-flex align-items-center">
          <input [formControlName]="EmailSettingsForm.useSystemMailFooter" type="checkbox" />
          <span></span>
          <span [innerHTML]="'Settings.Email.useSystemDescritpion' | translate"></span>
        </label>
      </div>

      <div class="relative mb-3">
        <quill-editor
          [disabled]="!!form.get(EmailSettingsForm.useSystemMailFooter).value"
          [readOnly]="!!form.get(EmailSettingsForm.useSystemMailFooter).value"
          [formControlName]="EmailSettingsForm.mailFooter"
          [modules]="modules"
          [formats]="formats"
          [placeholder]="'Settings.Email.descriptionPlaceholder' | translate"
        ></quill-editor>
      </div>
    </div>
  </div>
</div>
