import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
