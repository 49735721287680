<form [formGroup]="form">
  <gbxsoft-input
    #autocomplete
    class="text-control"
    [formControlName]="config.adrressControlName"
    [errMessages]="errorMessages(config.adrressControlName)"
    [config]="{ name: 'Address.address' | translate, type: 'text', placeholder: '' }"
  >
  </gbxsoft-input>
  <div class="row">
    <div class="col-md-6 col-lg-12 col-xl-6">
      <gbxsoft-input
        class="text-control"
        [errMessages]="errorMessages(config.postalCodeControlName)"
        [formControlName]="config.postalCodeControlName"
        [config]="{ name: 'Address.postalCode' | translate, type: 'text', placeholder: '' }"
      >
      </gbxsoft-input>
    </div>
    <div class="col-md-6 col-lg-12 col-xl-6">
      <gbxsoft-input
        class="text-control"
        [formControlName]="config.townControlName"
        [errMessages]="errorMessages(config.townControlName)"
        [config]="{ name: 'Address.town' | translate, type: 'text', placeholder: '' }"
      >
      </gbxsoft-input>
    </div>
  </div>
  <gbxsoft-select
    class="select"
    [formControlName]="config.countryControlName"
    [config]="countrySelectConfig"
    [options]="countrySelectOptions"
  ></gbxsoft-select>
</form>
