<div class="header-navigation" #el>
  <ng-container *ngFor="let tabLink of tabLinks; let index = index">
    <a
      draggable="false"
      [routerLink]="n.getPath(tabLink.link)"
      [routerLinkActive]="['header-navigation-tab--active']"
      [ngClass]="{ 'header-navigation-tab--active2': isTimelineRoute(tabLink) }"
      class="header-navigation-tab no-draggable"
      *ngIf="!tabLink.hidden"
      data-type="tab"
      id="tab{{ index }}"
    >
      <span [innerHTML]="tabLink.name | translate" class="header-navigation-tab-text"></span>
    </a>
  </ng-container>

  <app-button
    class="header-navigation-create"
    id="createMenu"
    tourAnchor="protocolsList.s10.add"
    *ngIf="!employee?.isClient"
    [matMenuTriggerFor]="createMenu"
    [config]="{
      type: ButtonTypes.PRIMARY_WHITE,
      size: ButtonSize.SMALL,
      text: 'Header.create' | translate,
      appendSrc: '/assets/img/arrow-down-solid.svg'
    }"
  ></app-button>
  <mat-menu #createMenu="matMenu" yPosition="above" class="mt-1">
    <button
      [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
      mat-menu-item
      (click)="createContact()"
    >
      <span>{{ 'Header.CreateOptions.contact' | translate }}</span>
    </button>

    <button
      [permission]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
      mat-menu-item
      (click)="createProjectDraft()"
    >
      <span>{{ 'Header.CreateOptions.project' | translate }}</span>
    </button>
    <!-- Removing: IS-359 -->
    <!-- <button
      mat-menu-item
      [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
      (click)="createProtocol()"
    >
      <span>{{ 'Header.CreateOptions.protocol' | translate }}</span>
    </button> -->

    <button
      mat-menu-item
      [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
      (click)="createTask()"
    >
      <span>{{ 'Header.CreateOptions.task' | translate }}</span>
    </button>

    <button
      mat-menu-item
      [permission]="{ group: PermissionsGroups.TASKS, action: 'CHANGE_ADD' }"
      (click)="createChange()"
    >
      <span>{{ 'Header.CreateOptions.change' | translate }}</span>
    </button>

    <button
      mat-menu-item
      [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
      (click)="createEvent()"
    >
      <span>{{ 'CalendarEvents.event' | translate }}</span>
    </button>
    <button
      mat-menu-item
      [permission]="{ group: PermissionsGroups.MAILINGS, action: 'MAILING_FUNCTION' }"
      (click)="createEmail()"
    >
      <span>{{ 'E-mail' | translate }}</span>
    </button>
  </mat-menu>
</div>
