import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Notification } from '@modules/notification/shared/models/notification.model';
import { TranslateService } from '@ngx-translate/core';
import { INotificationManager } from '@modules/notification/shared/interfaces/notification-manager.interface';
import { NotificationBaseComponent } from '@modules/notification/shared/components/notification/types/notification-base/notification-base.component';
import { AppInjector } from '@shared/services/app-injector.service';
import { NotificationType } from '@modules/notification/shared/models/notification-type';
import { Employee } from '@shared/models/employee.model';
import { Project, projectNotificationTitle } from '@modules/projects/shared/models/project.model';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { DateTimeFormatPipe } from '@shared/pipes/dateTimeFormat.pipe';
import { INotificationAvatarConfig } from '@modules/notification/shared/interfaces/notification-avatar-config.interface';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { StorageService } from '@core/services/storage.service';
import {
  BasePanelService,
  BasePanelChangeEventType,
} from '@shared/modules/base-panel/services/base-panel.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { UserPerson } from '@shared/interfaces/user.interface';
import { ProjectQuestionDirectorySecondSidenavComponent } from '@modules/projects/shared/components/project-question-directory-second-sidenav/project-question-directory-second-sidenav.component';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { NavigateService } from '@shared/services/navigate.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '@core/services/snackbar.service';

export class NotificationProjectGlobalManager implements INotificationManager {
  resolver: ComponentFactoryResolver;
  t: TranslateService;
  n: NavigateService;
  s: SnackBarService;
  sidenav: SidenavService;
  store: StorageService;
  basePanelService: BasePanelService;
  settingsService: SettingsService;
  projectApiService: ProjectAPIService;
  projectStore: ProjectStoreService;

  avatarConfig: INotificationAvatarConfig = {
    src: '/assets/img/ic-projects-filled.svg',
    type: 'svg',
    class: 'blue-300',
    hideAvatar: false,
  };

  constructor(private notification: Notification<any>, markAsShown?: Function) {
    this.resolver = AppInjector.getInjector().get(ComponentFactoryResolver);
    this.t = AppInjector.getInjector().get(TranslateService);
    this.n = AppInjector.getInjector().get(NavigateService);
    this.s = AppInjector.getInjector().get(SnackBarService);
    this.sidenav = AppInjector.getInjector().get(SidenavService);
    this.store = AppInjector.getInjector().get(StorageService);
    this.basePanelService = AppInjector.getInjector().get(BasePanelService);
    this.settingsService = AppInjector.getInjector().get(SettingsService);
    this.projectApiService = AppInjector.getInjector().get(ProjectAPIService);
    this.projectStore = AppInjector.getInjector().get(ProjectStoreService);
  }

  getFactory(): ComponentFactory<any> {
    return this.resolver.resolveComponentFactory(NotificationBaseComponent);
  }

  createComponent(content: ViewContainerRef): Function | null {
    let factory = this.getFactory();
    const component = content.createComponent<NotificationBaseComponent>(factory);
    component.instance.text = this.t.instant(
      `Notifications.Texts.Project.Global.${this.notification.type}`,
      this.getNotificationTextData(),
    );

    switch (this.notification.type) {
      // (N.2.0.1) // (N.2.0.2) // (N.2.0.3) // (N.2.0.4) // (N.2.0.5)
      case NotificationType.PROJECT.PROJECT_CREATED:
      case NotificationType.PROJECT.PROJECT_RESPONSIBLE_ASSIGNED:
      case NotificationType.PROJECT.PROJECT_STAGE_CHANGED:
      case NotificationType.PROJECT.PROJECT_STAGE_LOST:
      case NotificationType.PROJECT.PROJECT_REMOVED:
        if (this.notification.type === NotificationType.PROJECT.PROJECT_RESPONSIBLE_ASSIGNED) {
          this.avatarConfig.src = '/assets/img/ic-user-check.svg';
        }

        if (this.notification.type === NotificationType.PROJECT.PROJECT_STAGE_LOST) {
          this.avatarConfig.src = '/assets/img/ic-alert-circle.svg';
          this.avatarConfig.class = 'red-500';
        }

        if (this.notification.type === NotificationType.PROJECT.PROJECT_REMOVED) {
          return null;
        }

        return () => {
          if (this.notification.type === NotificationType.PROJECT.PROJECT_RESPONSIBLE_ASSIGNED) {
            this.updateProjectResponsibleHeader();
          }
          return this.previewProject(this.notification.data.project.id);
        };

      // (N.2.0.7)
      case NotificationType.PROJECT.PROJECT_ACCOUNT_CREATED_INFO:
        this.avatarConfig.src = '/assets/img/ic-user-plus.svg';
        return () => {
          const ctrl = new ContactController();
          ctrl.preview(this.notification.data.contact.id);
          this.sidenav.close();
        };

      // (N.2.0.10)
      case NotificationType.PROJECT.PROJECT_RESPONSIBLE_CHANGED:
        this.avatarConfig.src = '/assets/img/ic-user.svg';
        return null;

      // (N.2.0.12)
      case NotificationType.PROJECT.PROJECT_BOX_TWO_FILLED:
        this.avatarConfig.src = '/assets/img/ic-alert-circle-orange.svg';
        this.avatarConfig.class = 'blue-500';
        return () => {
          const ctrl = new ProjectController(this.notification.data.project);
          ctrl.preview(this.notification.data.project).then((navigated: boolean) => {
            if (navigated) {
              this.sidenav.open(ProjectQuestionDirectorySecondSidenavComponent, { class: 'scroll' });
              this.projectApiService.getProject(this.notification?.data?.project.id).subscribe((project) => {
                this.projectStore.projectCtrl.setProject(project);
              });
            }
          });
        };
    }
  }

  previewProject(projectId: number) {
    return this.projectApiService.getProject(projectId).subscribe({
      next: (res) => {
        this.sidenav.close();
        this.n.go('project-preview', { projectId: this.notification.data.project.id });
      },
      error: (httpResponse: HttpErrorResponse) => {
        switch (httpResponse.status) {
          case 400:
            this.s.error(this.t.instant('Projects.projectDoesntExist'));
            break;
          case 401:
            this.s.error(this.t.instant('Projects.noPermissionForProject'));
            break;
          default:
            break;
        }
      },
    });
  }

  getNotificationTextData() {
    const editor: Employee = new Employee(this.notification.data.editor);
    const project: Project = new Project(this.notification.data.project);
    const contact = new Contact(this.notification.data.contact);
    const dateTimeFormatPipe = new DateTimeFormatPipe();
    const responsiblePersonCompany: UserPerson = project.basicDataBox?.responsibleEmployee?.userPerson;

    return {
      editor: editor?.userPerson?.previewName ? editor?.userPerson?.previewName : '',
      project_fullName: projectNotificationTitle(project),
      stage: project?.stage ? this.t.instant('Projects.Stage.' + project.stage) : '',
      lostReason: project.failureReason
        ? this.t.instant('Projects.FailureReason.' + project.failureReason)
        : '',
      modificationDate: dateTimeFormatPipe.transform(project.modified),
      contact: contact?.fullName ? contact.fullName : '',
      responsibleName: responsiblePersonCompany?.fullName ? responsiblePersonCompany?.fullName : '',
      responsiblePhone: responsiblePersonCompany?.phone ? 'tel. ' + responsiblePersonCompany?.phone : '',
      responsibleEmail: responsiblePersonCompany?.email ? 'email: ' + responsiblePersonCompany?.email : '',
    };
  }

  private updateProjectResponsibleHeader() {
    this.settingsService.getUserData().subscribe((user: UserPerson) => {
      this.store.UserPerson = user;
      user.employees.map((e: Employee) => {
        if (e.id === this.store.Employee.id) {
          this.store.Employee = e;
          this.basePanelService.panelChangeEmitter.emit({
            type: BasePanelChangeEventType.RECONFIGURE_NAVIGATION,
          });
        }
      });
    });
  }
}
