import { AppTourConfig } from '@shared/modules/app-tour/shared/interfaces/app-tour-config.interface';
import { AppTourTypes } from '../enums/app-tour-types.enum';
import { CustomIStepOptionInterface } from '../interfaces/custom-i-step-option.interface';

export class AppTourSecondBoxModalConfig implements AppTourConfig {
  static readonly type: AppTourTypes = AppTourTypes.QUESTION_DIRECTORY_SECOND_MODAL;
  static readonly steps: CustomIStepOptionInterface[] = [
    {
      anchorId: 'questionDirectory.s2.modalInput',
    },
    {
      anchorId: 'questionDirectory.s3.modalButton',
    },
  ];
  static readonly ignoreMarking = true;
}
