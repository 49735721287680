import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {ProtocolApiService} from '@modules/protocols/shared/services/protocol-api.service';
import {BaseComponent} from '@shared/components/base.component';

@Component({
  selector: 'protocol-preview',
  templateUrl: './protocol-preview.component.html',
  styleUrls: ['./protocol-preview.component.scss'],
})
export class ProtocolPreviewComponent extends BaseComponent implements OnInit {
  content;

  constructor(
    public pService: ProtocolApiService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      return this.n.navigate('protocols-list-all');
    } else {
      this.employee?.isClient ? this.getClientPreview(id) : this.getPreview(id);
    }
  }

  getPreview(id) {
    this.pService.getProtocolPDF(id).subscribe({
      next: (res) => {
        this.content = URL.createObjectURL(res);
      },
      error: (e) => {
        this.s.error(this.t.instant('Protocols.errorDownloadPDF'));
        this.n.navigate('protocols-list-all');
      },
    });
  }

  getClientPreview(id) {
    this.pService.getAcceptanceProtocolPDF(id, false).subscribe({
      next: (res) => {
        this.content = URL.createObjectURL(res);
      },
      error: (e) => {
        this.s.error(this.t.instant('Protocols.errorDownloadPDF'));
      },
    });
  }
}
