<div class="mailing-selection-modal">
  <modal-title [title]="'Mailings.sendMailing' | translate"></modal-title>
  <mat-dialog-content>
    <div class="py-3">
      <div class="bold grey-1 mb-1">{{ 'Mailings.Templates.selectProject' | translate }}</div>
      <project-selection
        [requiredid]="projectId"
        [form]="form"
        [placeholder]="'Mailings.Templates.selectProjectTitle' | translate"
      ></project-selection>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="w-100">
      <div class="m-n1 d-flex justify-content-between flex-wrap">
        <app-button
          [class]="'m-1 bold'"
          (onClick)="discard()"
          [config]="{
            text: 'Mailings.cancel' | translate,
            type: ButtonTypes.DISCARD_BUTTON,
            size: ButtonSize.SMALL
          }"
        ></app-button>
        <div class="d-flex align-items-center flex-wrap">
          <app-button
            [class]="'m-1 bold'"
            (onClick)="withoutProject()"
            [config]="{
              text: 'Mailings.Templates.withoutProject' | translate,
              type: ButtonTypes.LIGHT_BLUE,
              size: ButtonSize.SMALL
            }"
          ></app-button>
          <app-button
            [class]="'m-1 bold'"
            (onClick)="submit()"
            [config]="{
              text: 'Mailings.Templates.withProject' | translate,
              type: ButtonTypes.PRIMARY_BLUE,
              size: ButtonSize.SMALL
            }"
          ></app-button>
        </div>
      </div>
    </div>
  </mat-dialog-actions>
</div>
