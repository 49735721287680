import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { BaseNavigationComponent } from '@shared/modules/base-panel/components/base-navigation.component';
import { NavigateService } from '@shared/services/navigate.service';
import { publicFile } from '@shared/helpers/public-file.helper';
import { StorageService } from '@core/services/storage.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { debounce } from '@shared/decorators/debounce.decorator';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';

@Component({
  selector: 'mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent extends BaseNavigationComponent implements OnInit, AfterViewInit {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  imageUrl: string;

  static readonly componentName: string = 'MobileNavigationComponent';

  @ViewChild('mobileNavigation') mobileNavigation: ElementRef;

  constructor(
    public sidenavService: SidenavService,
    public n: NavigateService,
    public store: StorageService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.setImageUrl();
  }

  ngAfterViewInit() {
    this.setHeightToNavigation();
  }

  setHeightToNavigation() {
    let clientHeight = document.documentElement.clientHeight;
    this.mobileNavigation.nativeElement.style.height = clientHeight + 'px';
  }

  closeMenu() {
    this.sidenavService.close();
  }

  setImageUrl() {
    const userPerson = this.store.UserPerson;
    userPerson.photo ? (this.imageUrl = publicFile(userPerson.photo, 100)) : null;
  }

  createProtocol() {
    const ctrl = new ProtocolController(null, null);
    ctrl.add();
    this.sidenavService.close();
  }

  createContact() {
    const ctrl = new ContactController();
    ctrl.add();
    this.sidenavService.close();
  }

  createProjectDraft() {
    const ctrl = new ProjectController();
    ctrl.add().add(() => {
      this.sidenavService.close();
    });
  }

  createTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(null);
    this.sidenavService.close();
  }

  createChange() {
    const ctrl = new ChangesSidenavController();
    ctrl.addChange(null);
    this.sidenavService.close();
  }

  createEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent();
  }

  createEmail() {
    const ctrl = new MailingController();
    ctrl.openMailingSelection('');
  }

  @HostListener('window:resize', ['$event'])
  @debounce(50)
  windowResize(e: KeyboardEvent) {
    this.setHeightToNavigation();
    this.closeMenu();
  }
}
