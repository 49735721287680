<form
  [formGroup]="form"
  class="task-item mb-2"
  [ngClass]="{ 'cdk-entered-list': enteredList }"
  [tourAnchor]="tourAnchorName"
>
  <div class="task-item__header d-flex align-items-center justify-content-between">
    <div class="task-item__selection d-flex align-items-center">
      <div class="d-flex align-items-center">
        <!--#region Checbox-->
        <div class="form-control-checkbox" *ngIf="!preview">
          <ng-container *ngIf="isNote && previewItems?.length && !isAllSelected">
            <label
              (click)="$event.stopPropagation()"
              class="m-0 d-flex align-items-center justify-content-start"
            >
              <input formControlName="all" type="checkbox" disabled />
              <span
                mat-menu-item
                confirmationPopover
                [tourAnchor]="'protocolManage.s14.bulkEdit' + index"
                [popoverClass]="'task-popover'"
                [confirmButtonType]="'default'"
                (confirm)="confirmSelection($event)"
                (cancel)="cancelSelection($event)"
                [placement]="'right'"
                [cancelText]="'Protocols.discard' | translate"
                [confirmText]="'Protocols.yes' | translate"
                [popoverTitle]="'Protocols.unselectNotes' | translate"
                [popoverMessage]="'Protocols.selectAllNotes' | translate"
              ></span>
            </label>
          </ng-container>
          <ng-container *ngIf="!isNote || !previewItems?.length || isAllSelected">
            <label
              (click)="$event.stopPropagation()"
              class="m-0 d-flex align-items-center justify-content-start"
            >
              <input
                formControlName="all"
                type="checkbox"
                [attr.disabled]="!previewItems?.length ? '' : null"
              />
              <span [tourAnchor]="'protocolManage.s14.bulkEdit' + index"></span>
            </label>
          </ng-container>
        </div>
        <!--#endregion-->

        <!--#region Title-->
        <b [innerHTML]="title"></b>
        <!--#endregion-->
      </div>

      <div class="grey-4 completed">
        ・{{ completedItemCount }} {{ 'Protocols.from' | translate }} {{ previewItems?.length }}
      </div>
    </div>

    <!--#region Actions-->
    <div class="task-item__actions d-flex align-items-center cursor-pointer">
      <div
        *ngIf="isStatus && !preview"
        (click)="checkDisabled(); $event.stopPropagation()"
        class="task-item__assignment mr-2"
      >
        <task-status
          [type]="type"
          [ngClass]="{ 'pointer-none': isDisabled }"
          (statusChanged)="statusChanged($event)"
        ></task-status>
      </div>

      <!--#region Assignment-->
      <div
        (click)="checkDisabled(); $event.stopPropagation()"
        *ngIf="isAssignment && !preview"
        class="task-item__assignment mr-2"
      >
        <assignment
          [disabled]="!previewItems?.length || !anyItemChecked"
          (userAssign)="changeEmployee($event)"
        >
        </assignment>
      </div>
      <!--#endregion-->

      <!--#region Discount-->
      <!-- <div (click)="checkDisabled();$event.stopPropagation()"
        *ngIf="isDiscount"
        class="task-item__discount mr-2  cursor-pointer">
        <discount (discountChanged)="discountChanged($event)"
          [disabled]="!items?.length || !anyItemChecked">
        </discount>
      </div> -->
      <!--#endregion-->

      <!--#region Realization Date-->
      <div
        (click)="checkDisabled(); $event.stopPropagation()"
        *ngIf="isRealization && !preview"
        class="task-item__realization-date mr-2 cursor-pointer"
      >
        <daterangepicker
          [disabled]="!previewItems?.length || !anyItemChecked"
          [isCalendarTime]="true"
          (rangeChanged)="rangeChanged($event)"
        >
        </daterangepicker>
      </div>
      <!--#endregion-->

      <!--#region Plus Icon-->
      <plus-icon-button
        *ngIf="!hideAddAction && !preview"
        (click)="openTaskAdd(); $event.stopPropagation()"
      ></plus-icon-button>
      <!--#endregion-->
    </div>
    <!--#endregion-->
  </div>
  <div
    formGroupName="items"
    #dropzone
    dndDropzone
    (dndDrop)="dropEvent($event, this.taskList)"
    [dndDropzone]="dropzoneType"
    [dndEffectAllowed]="'copyMove'"
    [dndDisableIf]="disabledDnd"
    class="task-item__content"
    [ngClass]="{ 'items-exists': previewItems.length, closed: !opened }"
  >
    <!--    <div [hidden]="!(opened && previewItems.length)">-->
    <!--#region Task-->
    <div dndPlaceholderRef class="dndPlaceholder"></div>
    <task-entity
      *ngFor="let task of previewItems; let j = index; trackBy: trackTask"
      [snackBottom]="snackBottom"
      [preview]="preview"
      [editPermission]="task.editPermission"
      [deletePermission]="task.deletePermission"
      [hiddenForClientShow]="hiddenForClientShow"
      (taskEmitter)="taskEmitterChange($event)"
      [form]="form"
      [taskList]="taskList"
      [isTypeSelection]="isTypeSelection"
      [isRealization]="isRealization"
      [isAssignment]="isAssignment"
      [isDiscount]="isDiscount"
      [type]="type"
      [selected]="isItemTrue(task.id.toString())"
      [task]="task"
      [index]="j"
      [disabledDnd]="disabledDnd"
    ></task-entity>

    <!--#endregion-->
    <!--    </div>-->
  </div>
</form>
