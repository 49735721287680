<modal-title [title]="'LoseData.headerTitle' | translate"></modal-title>

<div mat-dialog-content>
  <h4 class="grey-1"
      [innerHTML]="'LoseData.title' | translate"></h4>
  <p>{{ 'LoseData.description' | translate }}</p>
</div>

<mat-dialog-actions align="end" [class]="'d-block d-md-flex'">
  <app-button
    [class]="'mr-md-2 mb-2 mb-md-0 btn-sm-block'"
    (onClick)="onCancel()"
    [config]="{
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'LoseData.cancel' | translate,
        size: ButtonSize.LARGE
      }"
  ></app-button>
    <app-button
      [class]="'ml-md-1 btn-sm-block'"
      (onClick)="leaveEmitter.emit(true); dialogRef.close()"
      [config]="{
        type: ButtonTypes.DANGER_BUTTON,
        text: 'LoseData.confirm'  | translate,
        size: ButtonSize.LARGE,
        prependSrc: '/assets/img/ic-trash-outline.svg'
      }"
    ></app-button>
</mat-dialog-actions>

