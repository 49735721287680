<div
  class="status-reason d-flex align-items-center"
  [ngClass]="statusClass"
  *ngIf="!showTooltipWithComment"
>
  <div class="status-reason-circle" [ngClass]="{ 'status-reason-circle--small': showOnlySmallDot }"></div>
  <span class="status-reason-text {{textClass}}" *ngIf="!showOnlySmallDot">
    {{ label }}
  </span>
</div>
<div
  class="status-reason d-flex align-items-center"
  *ngIf="showTooltipWithComment"
  [ngClass]="statusClass"
  matTooltipClass="status-reason-comment"
  [matTooltip]="comment"
  matTooltipPosition="above">
  <div class="status-reason-circle" [ngClass]="{ 'status-reason-circle--small': showOnlySmallDot }"></div>

  <span class="status-reason-text {{textClass}}" *ngIf="!showOnlySmallDot">
    {{ label }}
  </span>
</div>
