import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventViewState } from '@shared/modules/event-sidenav/enums/event-view-state.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  templateUrl: './event-discard-dialog.component.html',
  styleUrls: ['./event-discard-dialog.component.scss'],
})
export class EventDiscardDialogComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  get isCreation() {
    return this.data.viewState === EventViewState.CREATE;
  }

  constructor(
    public dialogRef: MatDialogRef<EventDiscardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { viewState: EventViewState; confirm: () => void },
  ) {}

  confirm() {
    this.dialogRef.close();
    this.data?.confirm();
  }
}
