<main>
  <form [formGroup]="form" class="auth-form" (keyup.enter)="submitSetPassword()">
    <h1 class="mb-2 h1 grey-1" [innerHTML]="'Auth.Recovery.setPassword' | translate"></h1>
    <div class="mb-2 blue-text blue-300" [innerHTML]="'Auth.Recovery.security' | translate"></div>
    <div class="grey-4 grey-text" [innerHTML]="'Auth.Recovery.securePassword' | translate"></div>

    <gbxsoft-input
      [errMessages]="errorMessages('password')"
      class="password-control special-control required"
      formControlName="password"
      [config]="{
        fieldName: 'password-reset',
        name: 'Auth.Recovery.newPassword' | translate,
        type: 'password',
        placeholder: '',
        autocomplete: false,
        spellcheck: false,
        autocapitalize: false
      }"
    >
    </gbxsoft-input>
    <gbxsoft-input
      [errMessages]="errorMessages('passwordRepeat')"
      class="password-control special-control required"
      formControlName="passwordRepeat"
      [config]="{
        fieldName: 'passwordRepeat-reset',
        name: 'Auth.Recovery.reepateNewPassword' | translate,
        type: 'password',
        placeholder: '',
        autocomplete: false,
        spellcheck: false,
        autocapitalize: false
      }"
    >
    </gbxsoft-input>
    <button type="submit" hidden></button>
    <div class="d-flex align-items-center justify-content-end mt-4">
      <app-button
        [class]="'bold'"
        (onClick)="discard()"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Auth.Reset.discard' | translate
        }"
      >
      </app-button>

      <app-button
        [class]="'ml-2'"
        [type]="'submit'"
        (onClick)="submitSetPassword()"
        [disabled]="loading"
        [loading]="loading"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Auth.Reset.send' | translate,
          prependSrc: './assets/img/ic-arrow-right.svg'
        }"
      >
      </app-button>
    </div>
  </form>
</main>
