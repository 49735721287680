<button
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'PREVIEW'
  }"
  mat-menu-item
  (click)="preview()"
>
  <span>{{ 'Contacts.List.preview' | translate }}</span>
</button>

<button
  mat-menu-item
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT'
  }"
  (click)="edit()"
>
  <span>{{ 'Contacts.List.edit' | translate }}</span>
</button>

<button
  mat-menu-item
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT'
  }"
  (click)="addSubcontact()"
>
  <span>{{ 'Contacts.List.addSubcontact' | translate }}</span>
</button>

<button
  mat-menu-item
  [permission]="{
    group: PermissionsGroups.CONTACTS,
    action: 'REMOVE'
  }"
  *ngIf="!_row?.deleted"
  mat-menu-item
  class="hover_red red-500"
  (click)="remove()"
>
  <span>{{ 'Contacts.List.remove' | translate }}</span>
</button>
