import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Clipboard } from '@shared/helpers/clipboard.helper';

@Directive({
  selector: '[copyDir]',
  exportAs: 'copyDir',
})
export class CopyDirective implements OnInit {
  @Input() textCopy: string = '';
  @Input() callback: () => void;

  @Input() withoutHtml: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.initListeners();
  }

  initListeners() {
    this.renderer.listen(this.el.nativeElement, 'click', () =>
      this.withoutHtml ? this.copyToClip(this.textCopy) : this.copyMessage(this.textCopy),
    );
  }

  copyToClip(text: string) {
    text = text || '';
    this.copyFormatted(text);

    //   function listener(e) {
    //     e.clipboardData.setData('text/html', text);
    //     e.clipboardData.setData('text/plain', text);
    //     e.preventDefault();
    //   }
    //   document.addEventListener('copy', listener);
    //   document.execCommand('copy');
    //   document.removeEventListener('copy', listener);

    this.callback ? this.callback() : null;
  }

  copyFormatted(html) {
    // Create container for the HTML
    // [1]
    var container: any = document.createElement('div');
    container.innerHTML = html;

    // Hide element
    // [2]
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = 0;

    // Detect all style sheets of the page
    container.querySelectorAll('style,link[rel="stylesheet"]').forEach((element) => element.remove());
    // Mount the container to the DOM to make `contentWindow` available
    // [3]
    document.body.appendChild(container);

    // Copy to clipboard
    // [4]
    window.getSelection().removeAllRanges();

    var range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);
    // [5.3]
    document.execCommand('copy');
    // Remove the container
    // [6]
    document.body.removeChild(container);
  }

  copyMessage(text: string) {
    Clipboard.copyText(text).then(() => {
      this.callback ? this.callback() : null;
    });
  }
}
