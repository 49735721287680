import { ENTRY_MODAL_FORM } from './../entry-modal/entry-modal.component';
import { finalize } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ComponentRef,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { TranslateService } from '@ngx-translate/core';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { EntryEmitter } from '@modules/protocols/shared/interfaces/entry-emitter.interface';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EntryModalComponent } from '../entry-modal/entry-modal.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { IEntryModalConfig } from '../../interfaces/entry-modal-config.interface';
import { Config } from '@shared/configs/config';
import { EntryType } from '../../enums/entry-type.enum';
import { EntryObjectType } from '../../enums/entry-object-type.enum';
import { EntryService } from '../../services/entry.service';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';

@Component({
  selector: 'entry-list',
  styleUrls: ['./entry-list.component.scss'],
  templateUrl: './entry-list.component.html',
  providers: [EntryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryListComponent implements OnInit, AfterViewInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  _placeholder: string = '';
  _loading: boolean = false;
  entryToEdit: Entry = null;
  attachments: Array<TextAttachment> = [];
  attachmentsToDelete: Array<number> = [];
  refDialog: MatDialogRef<EntryModalComponent>;
  entryModal: EntryModalComponent;

  @Input() entryModalConfig: IEntryModalConfig;
  @Input()
  set loading(_loading: boolean) {
    this._loading = _loading;
    this.changes.detectChanges();
    if (this.entryModal) {
      this.entryModal.loading = _loading;
      this.entryModal?.changes?.detectChanges();
    }
  }
  get loading(): boolean {
    return this._loading;
  }
  @Input() edit: boolean = true;

  @Input() id: number;
  @Input() objectType: EntryObjectType;

  @Input() btnTourAnchor: string;
  @Input() attachmentTour: any;
  @Input() attachmentTourType: AppTourTypes;

  @Input() snackBottom: boolean = true;

  @Input() type: EntryType;
  @Input() title: string;
  @Input() buttonTitle: string;
  @Input() objectToken: string;
  @Input() limitEditAction: boolean = false;
  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.config.placeholder = placeholder;
  }
  get placeholder(): string {
    return this._placeholder;
  }

  @Input() attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachmentSize: 100,
  };

  $showDeleted: boolean = false;
  $previewItems: Array<Entry> = [];

  @Input()
  set showDeleted($showDeleted: boolean) {
    this.$showDeleted = $showDeleted;
    this.setPreviewItems();
  }

  get showDeleted() {
    return this.$showDeleted;
  }

  $items: Array<Entry> = [];
  @Input()
  set items($items: Array<Entry>) {
    this.$items = $items;
    this.setPreviewItems();
  }
  get items() {
    return this.$items;
  }

  @Input() preview: boolean = false;
  @Input() manageType: EntryManageType = EntryManageType.STATIC;
  @Input() componentRef: ComponentRef<any> = null;
  @Input() showAllImages: boolean = false;

  @Output() onEntry: EventEmitter<IEntryEmitter> = new EventEmitter();

  @ViewChild('textAttach') textAttach: TextAttachmentsInputComponent;

  form: FormGroup;

  config: GbxsoftInputConfig = {
    name: ' ',
    type: GbxsoftInputTypes.TEXT,
    placeholder: this.placeholder,
    showPlaceholderOnFocus: true,
  };

  get isTypeStatic() {
    return this.manageType === EntryManageType.STATIC;
  }

  get isTypeButton() {
    return this.manageType === EntryManageType.BUTTON;
  }

  get isTypePreview() {
    return this.manageType === EntryManageType.PREVIEW_ENTRY;
  }

  get formData() {
    const data = !this.entryToEdit
      ? this.form.get('files')?.value
      : this.form.get('files')?.value?.filter((i) => !i.created);
    return {
      type: this.type,
      description: this.form.get('note').value || '',
      attachments: data ? data : [],
      attachmentsToDelete: this.attachmentsToDelete,
    };
  }

  get isEmployee() {
    return this.type === EntryType.TYPE_WORKER_ENTRY;
  }

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private t: TranslateService,
    private s: SnackBarService,
    public changes: ChangeDetectorRef,
    public service: EntryService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit(): void {
    const input: HTMLInputElement = this.textAttach?.input.inputElement;
    if (!input) return;
    input.addEventListener('keyup', (e) =>
      e.keyCode === 13 ? (this.entryToEdit ? this.editEntry() : this.addEntry()) : null,
    );
    this.changes.detectChanges();
  }

  setPreviewItems() {
    this.$previewItems = this.showDeleted
      ? this.items.filter((i) => !!i.deleted)
      : this.items.filter((i) => !i.deleted);
  }

  openEntryModal() {
    const entry = this.entryToEdit || {};
    this.refDialog = this.dialog.open(EntryModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        onlyTextInteraction: this.entryToEdit ? !!this.limitEditAction : false,
        entry: Object.assign(entry, { type: this.type }),
        entryModalConfig: this.entryModalConfig,
        config: this.config,
        attachmentsConfig: this.attachmentsConfig,
        componentInstance: this.componentRef,
        attachmentTour: this.attachmentTour,
        attachmentTourType: this.attachmentTourType,
      },
    });

    this.entryModal = this.refDialog.componentInstance;
    const EntrySub = this.entryModal.onEntry.subscribe((value: IEntryEmitter) => {
      this.entryToEdit ? this.editEntry() : this.addEntry();
    });

    this.refDialog.afterClosed().subscribe(() => {
      EntrySub ? EntrySub.unsubscribe() : null;
      this.entryToEdit = null;
      this.entryModal = null;
      this.changes.detectChanges();
    });
  }

  //#region ADD event
  addEntry() {
    const formData = this.manageType === EntryManageType.BUTTON ? this.entryModal.formData : this.formData;
    const valid = formData?.attachments?.length || formData?.description?.length;
    if (valid) {
      formData['objectType'] = this.objectType;
      formData['id'] = this.id;

      this.loading = true;
      this.changes.detectChanges();
      this.service
        .addEntry(formData, this.objectToken)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.changes.detectChanges();
          }),
        )
        .subscribe({
          next: (entry: Entry) => this.successAddEntry(entry),
        });
    }
  }

  successAddEntry(item: Entry) {
    item = new Entry(item);
    item.opened = true;
    this.items = [...this.items, item];
    this.onEntry.emit({ type: EntryAction.ADD, data: { list: this.items, item } });
    this.s.success(
      this.t.instant(`Entries.entry_${item.type}_Created`),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.resetEntryForm();
    this.changes.detectChanges();
  }
  //#endregion

  //#region EDIT event
  editEntry() {
    const formData = this.isTypeButton || this.isTypePreview ? this.entryModal.formData : this.formData;
    const isImages = !!this.entryModal?.form?.value[ENTRY_MODAL_FORM.files]?.length;
    const valid = isImages || !!formData?.description?.length;
    if (valid) {
      formData['objectType'] = this.objectType;

      this.loading = true;
      this.changes.detectChanges();
      this.service
        .editEntry(formData, this.entryToEdit.id, this.objectToken)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.changes.detectChanges();
          }),
        )
        .subscribe({
          next: (entry: Entry) => this.successEditEntry(entry),
        });
    }
  }

  successEditEntry(item: Entry) {
    const index = this.items.findIndex((i) => i.id === item.id);
    item = new Entry(item);
    item.opened = true;
    this.items[index] = item;
    this.onEntry.emit({ type: EntryAction.EDIT, data: { list: this.items, item } });
    this.s.success(
      this.t.instant(`Entries.entry_${item.type}_Edited`),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.resetEntryForm();
    this.setPreviewItems();
    this.changes.detectChanges();
  }
  //#endregion

  removeFileEvent(attachment: TextAttachment) {
    attachment.id ? this.attachmentsToDelete.push(parseInt(attachment.id)) : null;
    this.changes.detectChanges();
  }

  successFileRemove() {
    this.s.success(
      this.t.instant('fileRemoved'),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
  }

  errorFileRemove() {}

  createForm(): void {
    this.form = this.fb.group({ note: null, files: null });
    this.changes.detectChanges();
  }

  entryAction($event: EntryEmitter) {
    switch ($event.type) {
      case EntryAction.DELETE:
        this.deleteAction($event.data);
        break;
      case EntryAction.RESTORE:
        this.restoreAction($event.data);
        break;
      case EntryAction.EDIT:
        this.editAction($event.data);
        break;
      default:
        break;
    }
  }

  // #region DELETE event
  deleteAction(entry: Entry) {
    this.service.removeEntry(entry.id).subscribe({
      next: () => this.successDeleteAction(entry),
      error: () => {},
    });
  }

  successDeleteAction(item: Entry) {
    //Hard Delete
    // const index = this.items.findIndex((i) => i.id === item.id);
    // index > -1 ? this.items.splice(index, 1) : null;

    // Soft delete
    const index = this.items.findIndex((i) => i.id === item.id);
    item = new Entry(item);
    item.deleted = Date.now().toString();
    this.items[index] = item;

    this.onEntry.emit({ type: EntryAction.DELETE, data: { list: this.items, item } });
    this.s.success(
      this.t.instant(`Entries.entry_${item.type}_Deleted`),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.setPreviewItems();
    this.changes.detectChanges();
  }

  errorDeleteAction() {}
  //#endregion

  // #region RESTORE event
  restoreAction(entry: Entry) {
    this.service.restoreEntry(entry.id).subscribe({
      next: () => this.successRestoreEntry(entry),
      error: () => this.errorRestoreEntry(),
    });
  }

  successRestoreEntry(item: Entry) {
    const index = this.items.findIndex((i) => i.id === item.id);
    item = new Entry(item);
    item.opened = true;
    item.deleted = null;
    this.items[index] = item;
    this.onEntry.emit({ type: EntryAction.EDIT, data: { list: this.items, item } });
    this.s.success(
      this.t.instant(`Entries.entry_Restored`),
      this.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG,
    );
    this.resetEntryForm();
    this.setPreviewItems();
    this.changes.detectChanges();
  }

  errorRestoreEntry() {
    this.s.error(this.t.instant('Entries.errorRestoration'));
  }
  //#endregion

  editAction(entry: Entry) {
    entry.attachments = [...(entry?.attachments?.filter((a) => !a.deleted) || [])];
    this.setEntryToEdit(entry);
    if (this.isTypeButton || this.isTypePreview) {
      this.openEntryModal();
      return;
    }
    this.form.get('note').setValue(entry.description);
    this.attachments = this.entryToEdit.attachments.map((attachment: Attachment) => {
      return this.textAttach.getAttachment(attachment as any, null, attachment);
    });
    setTimeout(() => {
      this.textAttach.setFilesToFormControl();
      this.changes.detectChanges();
    }, 0);
  }

  setEntryToEdit(entry: Entry) {
    this.attachmentsToDelete = [];
    this.entryToEdit = entry;
    this.changes.detectChanges();
  }

  resetEntryForm() {
    this.entryToEdit = null;
    this.attachments = [];
    this.attachmentsToDelete = [];
    this.form.reset();
    this.textAttach?.removeAllAttachments();
    this.entryModal?.resetForm();
  }
}

export interface IEntryEmitter {
  data: any;
  type: EntryAction;
}

export enum EntryManageType {
  BUTTON,
  STATIC,
  PREVIEW_ENTRY,
}
