import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { BaseComponent } from '@shared/components/base.component';
import * as moment from 'moment';

@Component({
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationComponent extends BaseComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  disabled: boolean = false;
  timeLeft: string;
  constructor(
    public dialogRef: MatDialogRef<EmailVerificationComponent>,
    private authService: AuthorizationService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  resendConfirmation() {
    if (this.loading || !!this.timeLeft) return;
    this.loading = true;
    this.disabled = true;
    this.changes.detectChanges();

    this.authService
      .resendConfirmation()
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Auth.Confirmation.successResend'));
          this.minuteCounter();
          this.changes.detectChanges();
        },
        error: () => {
          this.disabled = false;
          this.s.error(this.t.instant('Auth.Confirmation.errorResend'));
          this.changes.detectChanges();
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  minuteCounter() {
    let i = 60;
    const counter = () => {
      this.timeLeft = moment().startOf('day').set('second', i).format('mm:ss');
      i--;
      i >= 0
        ? setTimeout(counter, 1000)
        : setTimeout(() => {
            this.timeLeft = null;
            this.changes.detectChanges();
          }, 1000);
      this.changes.detectChanges();
    };
    counter();
  }

  discard() {
    this.dialogRef.close();
  }
}
