import { WindowHelper } from '@shared/helpers/window.helper';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialogRef } from '@angular/material/dialog';
import { MailingSidenavComponent } from '@modules/mailing-templates/shared/modules/mailing-sidenav/components/mailing-sidenav/mailing-sidenav.component';
import { Project } from '@modules/projects/shared/models/project.model';
import { Config } from '@shared/configs/config';
import { TaskType } from '@shared/enums/task-type.enum';
import { TaskViewState } from '@shared/enums/task-view-state.enum';
import { Task } from '@shared/models/task.model';
import { TaskChangeShareDialogComponent } from '../components/task-change-share-dialog/task-change-share-dialog.component';
import { TaskSidenavComponent } from '../components/task-sidenav/task-sidenav.component';
import { BaseTaskSidenavController } from './base-task-sidenav.controller';
import { TaskSidenavController } from './task-sidenav.controller';

export class ChangesSidenavController extends BaseTaskSidenavController {
  static readonly component: ComponentType<TaskSidenavComponent> = TaskSidenavComponent;
  readonly changesMailingIdNumber = 25;

  constructor() {
    super();
  }

  addChange(projectID?: number) {
    this.sidenav.open(
      ChangesSidenavController.component,
      this.getSidenavConfig({ type: TaskType.TYPE_CHANGE }),
      {
        viewState: TaskViewState.CREATE,
        replaceUrl: true,
        projectID,
      },
    );
  }

  editChange(projectID?: number, taskId?: string) {
    this.sidenav.open(
      ChangesSidenavController.component,
      this.getSidenavConfig({ type: TaskType.TYPE_CHANGE }),
      {
        viewState: TaskViewState.EDIT,
        replaceUrl: true,
        projectID,
        taskId,
      },
    );
  }

  previewChange(taskId: string) {
    this.sidenav.open(
      ChangesSidenavController.component,
      this.getSidenavConfig({ type: TaskType.TYPE_CHANGE }),
      {
        viewState: TaskViewState.PREVIEW,
        replaceUrl: true,
        taskId,
      },
    );
  }

  taskCreate(connectedTaskId: number, projectId?: number) {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(projectId, null, connectedTaskId);
  }

  // #region Sharing Change
  openShareDialog(data: any) {
    const ref: MatDialogRef<TaskChangeShareDialogComponent> = this.dialog.open(
      TaskChangeShareDialogComponent,
      {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false,
        data,
      },
    );
    return ref.componentInstance.onShared;
  }

  createMailing(id: number, _params: any) {
    const config: any = {
      maxWidth: '709px',
      class: 'no-padding',
      hideBackdrop: WindowHelper.isMoreLessMD,
      hardReload: true,
      closeOnBackdrop: true,
    };

    const params = Object.assign({ templateId: id }, _params);
    this.sidenav.open(MailingSidenavComponent, config, params);
  }

  shareSingleChange(task: Task, projectId: number, project?: Project) {
    // return this.openShareDialog({ task, projectId, project });
    this.createMailing(this.changesMailingIdNumber, { projectId: projectId, email: null, taskIds: [task?.id] });
  }

  shareMultipleChanges(taskIds: number[], projectId: number, project?: Project) {
    // return this.openShareDialog({ taskIds, projectId, project });
    this.createMailing(this.changesMailingIdNumber, { projectId: projectId, email: null, taskIds });
  }

  //#endregion
}
