export enum TaskAction {
  TYPE_CHANGE_ACTION = 1,
  DELETE_POST_ACTION,
  OPEN_POST_ACTION,
  STATUS_POST_ACTION,
  DROP_ACTION,
  EMPLOYEE_ASSINGED,
  TERM_CHANGED,
  DISCOUNT_CHANGED,
  EDIT_TASK,
  STARTED_SAVING,
  COMPLETED_SAVING,
}
