import { Injectable, EventEmitter } from '@angular/core';
export enum ProjectEntryActionType {
  DELETE,
  ADD,
  UPDATE,
}

@Injectable({ providedIn: 'root' })
export class ProjectCreatorEntryService {
  actionEmitter: EventEmitter<{ action: ProjectEntryActionType; payload: any }> = new EventEmitter();
  constructor() {}
}
