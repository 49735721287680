<div class="doc-previewer">
  <modal-title [title]="'Protocols.view' | translate"></modal-title>
  <mat-dialog-content>
    <div class="iframe">
      <custom-pdf-viewer
        *ngIf="data.isPDF"
        [content]="src"
        [renderText]="false"
        [fitToPage]="false"
      ></custom-pdf-viewer>
      <iframe *ngIf="!data.isPDF" width="100%" (load)="load()" [src]="src"></iframe>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-button
      [class]="'bold py-2 px-3 mr-2'"
      (onClick)="dialogRef.close()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Protocols.discard' | translate
      }"
    ></app-button>
    <app-button
      [class]="'bold py-2 px-3 '"
      [loading]="downloading"
      [disabled]="downloading"
      (onClick)="data?.downloadFunc()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'Protocols.download' | translate,
        prependSrc: '/assets/img/ic-download-outline.svg'
      }"
    ></app-button>
  </mat-dialog-actions>
</div>
