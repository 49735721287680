import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { IProjectsTimelineResourceEvent } from '@modules/projects/modules/projects-timeline/shared/interfaces/projects-timeline-resource-event.interface';
import isMobile from 'ismobilejs';
import { ResourceApi } from '@fullcalendar/resource-common';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'projects-timeline-resource-label',
  templateUrl: './projects-timeline-resource-label.component.html',
  styleUrls: ['./projects-timeline-resource-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsTimelineResourceLabelComponent implements OnInit {
  ProjectStage = ProjectStage;
  WindowHelper = WindowHelper;

  resource: ResourceApi;
  opened: boolean = false;
  eventEmitter: EventEmitter<IProjectsTimelineResourceEvent> =
    new EventEmitter<IProjectsTimelineResourceEvent>();
  project: Project;
  resourceStage: IProjectStage;
  loading: boolean = false;
  openedPopover: { project: Project; parentElement: HTMLBaseElement };
  onProjectChange: EventEmitter<{ project: Project; oldStage?: ProjectStage }> = new EventEmitter<{
    project: Project;
    oldStage?: ProjectStage;
  }>();

  @ViewChild('resourceLabel') resourceLabel: ElementRef;

  constructor(private projectApiService: ProjectAPIService, private changes: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setProjectAndStage();
  }

  setProjectAndStage() {
    if (this.resource._resource.parentId) {
      this.project = new Project(this.resource._resource.extendedProps);
    } else {
      this.resourceStage = this.resource._resource.extendedProps as IProjectStage;
    }
  }

  isIpad() {
    return isMobile()?.apple?.tablet;
  }

  toggle() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.eventEmitter.emit({
      open: !this.resourceStage.opened,
      stage: this.resource.extendedProps.stage,
      resource: this.resource,
    });
    setTimeout(() => {
      this.loading = false;
      this.changes.detectChanges();
    }, 300);
  }

  setFavourite() {
    this.resource.setExtendedProp('isFavourite', !this.project.isFavourite);
    this.project.isFavourite
      ? this.projectApiService.removeFavourite(this.resource.extendedProps.id).toPromise()
      : this.projectApiService.setFavourite(this.resource.extendedProps.id).toPromise();
  }

  openProjectPopover() {
    this.openedPopover = {
      project: this.project,
      parentElement: this.resourceLabel.nativeElement,
    };
    this.changes.detectChanges();
  }
}
