import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ListSingleFilter } from '@shared/modules/list/interfaces/list-single-filter.interface';

@Component({
  selector: 'list-single-filter-toggle',
  templateUrl: './list-single-filter-toggle.component.html',
  styleUrls: ['./list-single-filter-toggle.component.scss'],
})
export class ListSingleFilterToggleComponent implements ListSingleFilter {
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  get isValue() {
    return this.form?.value[this.controlName] && this.form?.value[this.controlName].toString().length;
  }
}
