<div
  (dndStart)="dragstart()"
  (dndEnd)="dragend()"
  [dndDraggable]="listGroupTask"
  [dndEffectAllowed]="'copyMove'"
  (dndDrag)="drag($event)"
  class="list-tasks-group"
  [dndDisableIf]="listTaskService?.isMyWorkTaskLists"
>
  <!-- [ngClass]="{ 'list-tasks-group--hidden': isEmpty && isEmptyCompleted && listGroupTask?.projectGroup }" -->
  <div
    [permission]="dragPermissions"
    dndHandle
    *ngIf="listGroupTask?.projectGroup"
    class="list-tasks-group__draggable"
  >
    <img width="15" src="./assets/img/ic-sortdragchange.svg" alt="drag-drop" />
  </div>

  <div class="list-tasks-group__wrapper">
    <div class="list-tasks-group__header">
      <div class="list-tasks-group__header__title">
        <h4 class="d-flex align-items-center">
          <div class="mr-2 list-tasks-group__header__title-edit" *ngIf="listGroupTask?.projectGroup">
            <img
              [ngClass]="{
                pointer: !listGroupTask?.taskGroup?.immutable,
                'not-allowed': listGroupTask?.taskGroup?.immutable
              }"
              [src]="
                './assets/img/ic-edit-' + (listGroupTask?.taskGroup?.immutable ? '3-grey' : '3') + '.svg'
              "
              (click)="!listGroupTask?.taskGroup?.immutable ? editGroupName(listGroupTask) : null"
              alt="edit"
            />
          </div>
          {{ listGroupTask.taskGroup.name | listGroupTranslate }}
        </h4>
        <span>{{ listGroupTask.completedCount + '/' + listGroupTask.allCount }}</span>
      </div>
      <div
        [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
        class="list-tasks-group__header__actions"
      >
        <link-button
          [name]="'ProjectTasks.TaskGroup.addTask' | translate"
          [class]="'green-500'"
          [smallRightMargin]="true"
          [iconUrl]="'/assets/img/ic-plus-add-green.svg'"
          (onClick)="addTask(listGroupTask?.taskGroup)"
        ></link-button>
      </div>
    </div>
    <div class="list-tasks-group__content">
      <!-- <div class="list-tasks-group__empty-state" *ngIf="isEmpty && isEmptyCompleted">
        {{ 'ProjectTasks.noTasks' | translate }}
      </div> -->

      <!--------------------NOT COMPLETED TASKS START------------------------>
      <div class="d-flex flex-column list-tasks-group__tasks">
        <ng-container #tasksContainer></ng-container>
      </div>
      <!--------------------NOT COMPLETED TASKS END------------------------>

      <!--------------------COMPLETED TASKS START------------------------>
      <div
        class="d-flex flex-column list-tasks-group__completed"
        [ngClass]="{ hidden: isEmptyCompleted || !listService?.filtersForm?.get('[show_completed]')?.value }"
      >
        <h5 class="list-tasks-group__content__completed-title">
          {{ 'ProjectTasks.TaskGroup.completedTasks' | translate }}
        </h5>
        <ng-container #completedContainer></ng-container>
      </div>
      <!--------------------COMPLETED TASKS END------------------------>
    </div>
  </div>
</div>
