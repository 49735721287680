import { GlobalDataResolve } from '@shared/resolvers/global-data.resolve';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { ProtocolFillDataComponent } from '@modules/protocols/shared/components/protocol-fill-data/protocol-fill-data.component';
import { CompanyPanelGuard } from '@core/guards/company-panel.guard';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ContactsListGuard } from '@core/guards/contacts/contacts-list.guard';

export const GlobalRoutes: CustomRoutes = [
  {
    path: '',
    loadChildren: () =>
      import('@modules/authorization/authorization.module').then((m) => m.AuthorizationModule),
    resolve: { globalData: GlobalDataResolve },
  },
  {
    path: 'client-panel',
    loadChildren: () => import('@modules/client-panel/client-panel.module').then((m) => m.ClientPanelModule),
  },
  {
    path: 'project-creator',
    loadChildren: () =>
      import('@modules/project-creator/project-creator.module').then((m) => m.ProjectCreatorModule),
  },
  {
    path: 'help',
    loadChildren: () => import('@modules/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'panel',
    resolve: { globalData: GlobalDataResolve },
    children: [
      {
        path: 'settings',
        loadChildren: () => import('@modules/settings/settings.module').then((m) => m.SettingsModule),
        resolve: { globalData: GlobalDataResolve },
        canActivate: [CompanyPanelGuard],
      },
      {
        path: 'my-work',
        loadChildren: () => import('@modules/my-work/my-work.module').then((m) => m.MyWorkModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('@modules/calendar/calendar.module').then((m) => m.CalendarModule),
      },
      // Removing: IS-359
      // {
      //   path: 'protocols',
      //   loadChildren: () => import('@modules/protocols/protocols.module').then((m) => m.ProtocolsModule),
      //   resolve: { globalData: GlobalDataResolve },
      //   canActivate: [CompanyPanelGuard],
      // },
      {
        path: 'projects/list',
        name: 'projects-list',
        loadChildren: () =>
          import('@modules/projects/modules/projects-list/projects-list.module').then(
            (m) => m.ProjectsListModule,
          ),
      },
      {
        path: 'projects/timeline',
        name: 'projects-timeline',
        loadChildren: () =>
          import('@modules/projects/modules/projects-timeline/projects-timeline.module').then(
            (m) => m.ProjectsTimelineModule,
          ),
      },
      {
        path: 'projects/preview',
        loadChildren: () =>
          import('@modules/projects/modules/project-preview/project-preview.module').then(
            (m) => m.ProjectPreviewModule,
          ),
      },
      {
        path: 'projects/create',
        name: 'project-create',
        loadChildren: () =>
          import('@modules/projects/modules/projects-create/projects-create.module').then(
            (m) => m.ProjectsCreateModule,
          ),
      },
      {
        path: 'projects/edit',
        name: 'project-edit',
        loadChildren: () =>
          import('@modules/projects/modules/projects-edit/projects-edit.module').then(
            (m) => m.ProjectsEditModule,
          ),
      },
      {
        path: 'contacts',
        loadChildren: () => import('@modules/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'protocols-no-data',
        component: ProtocolFillDataComponent,
        name: 'protocols-no-data',
      },
      {
        path: 'mailings',
        loadChildren: () =>
          import('@modules/mailing-templates/mailing-templates.module').then((m) => m.MailingTemplatesModule),
      },
    ],
  },
  { path: '404', name: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
