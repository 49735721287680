<!-- #region box -->
<div class="summary-item" [ngClass]="{ 'summary-item--blue': isBoxBlue }">
  <div class="summary-item__description">
    <div class="summary-item__title">
      <span>{{ summary.title | translate }}</span>
      <help-icon *ngIf="!!summary?.tip" [message]="summary?.tip | translate"></help-icon>
    </div>

    <ul class="summary-item__budgets">
      <li *ngFor="let cost of summary.items">
        <div
          (click)="cost?.apiConfig ? openChangeDialog(cost) : null"
          [title]="(cost.title | translate) + ': ' + cost.value"
          class="summary-item__inline"
        >
          <span class="summary-item__inline-title text-left">
            {{ cost.title | translate }}
            <img draggable="false" *ngIf="!!cost?.apiConfig" class="ml-2" src="./assets/img/ic-edit-3-white.svg" alt="edit" />
          </span>
          <span
            [class]="
              'summary-item__inline-count ' + 'summary-item__inline-count-' + cost?.color + ' text-right'
            "
          >
            {{ cost.value }}
          </span>
        </div>
      </li>
    </ul>
  </div>

  <div class="summary-item__finalize">
    <div class="summary-item__inline">
      <span class="summary-item__inline-title">{{ summary?.total?.title | translate }}</span>
      <span [class]="'summary-item__inline-count ' + 'summary-item__inline-count-' + summary?.total?.color">
        {{ summary?.total?.value }}
      </span>
    </div>
  </div>
</div>
<!-- #endregion -->
