import { ListModule } from '@shared/modules/list/list.module';
import { SubtasksService } from './subtasks.service';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { UIModule } from '@shared/modules/ui/ui.module';
import { SubtasksListComponent } from './components/subtasks-manager/components/subtasks-list/subtasks-list.component';
import { SubtaskCreateComponent } from './components/subtasks-manager/components/subtask-create/subtask-create.component';
import { SubtasksManagerComponent } from './components/subtasks-manager/subtasks-manager.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [UIModule, CoreModule, SharedModule, ListModule],
  declarations: [SubtasksManagerComponent, SubtaskCreateComponent, SubtasksListComponent],
  exports: [SubtasksManagerComponent],
  providers: [SubtasksService],
})
export class SubtasksModule {}
