<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1 pt-3" [innerHTML]="'Projects.shareAgainTitle' | translate"></h4>
  <p>{{'Projects.shareAgainText' | translate}}</p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    [class]="'mr-2 bold'"
    (onClick)="dialogRef.close()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.DISCARD_BUTTON,
      text: 'Tasks.discard' | translate
    }"
  ></app-button>

  <app-button
    [class]="'bold'"
    (onClick)="confirm()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.PRIMARY_BLUE,
      text: 'Projects.shareButton' | translate,
      prependSrc: './assets/img/ic-share.svg'
    }"
  ></app-button>
</mat-dialog-actions>
<!-- #endregion -->
