import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { Subscription } from 'rxjs';
import { Config } from '@shared/configs/config';
import { CONTACT_FORM } from '@modules/contacts/shared/consts/contact-form.const';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgOption } from '@ng-select/ng-select';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { Regex } from '@shared/configs/regex';
import {
  ChangesUnsavedModalComponent,
  IUnsavedStates,
} from '../changes-unsaved-modal/changes-unsaved-modal.component';

@Component({
  selector: 'contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit, OnDestroy {
  CONFIG = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  NEW_CONTACT_FORM = CONTACT_FORM;
  GbxsoftInputTypes = GbxsoftInputTypes;

  form: FormGroup;

  sub: Subscription = new Subscription();

  loading: boolean = false;
  contact: Contact = null;

  subTypeChange: Subscription;

  contactTypeList: NgOption = [
    {
      id: ContactType.PERSONAL,
      label: this.t.instant(`Contacts.Type.${ContactType.PERSONAL}`),
    },
    {
      id: ContactType.COMPANY,
      label: this.t.instant(`Contacts.Type.${ContactType.COMPANY}`),
    },
  ];

  onAddContact: EventEmitter<Contact> = new EventEmitter<Contact>();

  get modalTitle() {
    if (this.data.parentContact) {
      return 'Contacts.Preview.newSubcontact';
    }

    return this.data?.contact?.id ? 'Contacts.Manage.Menu.edit' : 'Contacts.Manage.Menu.create';
  }

  get formValue() {
    const value = Object.assign({}, this.form.value);
    value.links = [];
    value.isNetPrices = [];
    value.isContactPerson = false;
    this.data?.isDraft ? (value.isDraft = this.data.isDraft) : null;
    delete value.parentContact;
    delete value.parentContactId;
    return value;
  }

  get isCompany() {
    return this.form.get(CONTACT_FORM.type).value === ContactType.COMPANY;
  }

  get isPersonal() {
    return this.form.get(CONTACT_FORM.type).value === ContactType.PERSONAL;
  }

  get formData() {
    this.form
      .get(CONTACT_FORM.parentsIds)
      .setValue(this.data?.parentContact?.id ? [this.data?.parentContact?.id] : null);

    const prevValue = !!this.data?.contact ? this.data?.contact : {};
    const obj = Object.assign(prevValue, this.formValue);

    !!this.data.projectId ? (obj.projectId = this.data.projectId) : null;

    if (this.isCompany) {
      obj.firstName = null;
      obj.lastName = null;
    } else {
      obj.companyName = null;
    }

    return obj;
  }

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactModalComponent>,
    private service: ContactService,
    private changes: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contact: Contact;
      hideResponsible: boolean;
      projectId: number;
      parentContact?: Contact;
      firstName?: string;
      isDraft?: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.setFormData();
    this.subscribeTypeChange();
    this.dialogCloseDetection();
  }

  dialogCloseDetection() {
    const sub = this.dialogRef.backdropClick().subscribe(() => {
      if (this.form.pristine) {
        this.dialogRef.close();
      } else {
        let dialogRef = this.dialog.open(ChangesUnsavedModalComponent, {
          width: '550px',
          autoFocus: false,
          data: {
            title: 'Contacts.unsavedTitle',
            text: 'Contacts.unsavedDescription',
          },
        });

        dialogRef.afterClosed().subscribe((confirmation: IUnsavedStates) => {
          switch (confirmation) {
            case IUnsavedStates.SAVE_UNLOAD:
              this.submitContact();
              break;
            case IUnsavedStates.DISCARD:
              break;
            default:
              this.dialogRef.close();
              break;
          }
        });
      }
    });
    this.sub.add(sub);
  }

  createForm() {
    const group = {};
    Object.keys(CONTACT_FORM).forEach((key) => (group[key] = null));
    this.form = this.fb.group(group);
    this.form.get(CONTACT_FORM.type).setValue(ContactType.PERSONAL);

    this.setValidatorsByType(ContactType.PERSONAL);
  }

  subscribeTypeChange() {
    this.subTypeChange = this.form
      .get(CONTACT_FORM.type)
      .valueChanges.subscribe((type: ContactType) => this.setValidatorsByType(type));
  }

  setFormData() {
    if (this.data.firstName) {
      this.form.get(CONTACT_FORM.firstName).setValue(this.data.firstName);
      this.form.get(CONTACT_FORM.firstName).markAsDirty();
    }
    if (!this.data?.contact) {
      return;
    }
    this.contact = new Contact(this.data?.contact);
    Object.keys(this.data?.contact).forEach((key) => {
      const control = this.form.get(key);
      if (control) {
        control.setValue(this.data?.contact[key]);
      }
    });

    this.setContactFunctionality();
    this.setValidatorsByType(this.contact.type);
  }

  setContactFunctionality() {
    this.form.get(CONTACT_FORM.function).setValue(this.contact?.function?.map((i) => i.name) || []);
  }

  setValidatorsByType(type: ContactType) {
    switch (type) {
      case ContactType.COMPANY:
        this.setCompanyValidators();
        break;
      default:
        this.setPersonalValidators();
        break;
    }

    this.changes.detectChanges();
  }

  setPersonalValidators() {
    this.setSingleValidator(CONTACT_FORM.firstName, [Validators.required]);
    this.setSingleValidator(CONTACT_FORM.lastName, [Validators.required]);
    this.setSingleValidator(CONTACT_FORM.email, [Validators.pattern(Regex.email)]);

    this.setSingleValidator(CONTACT_FORM.companyName, []);
  }

  setCompanyValidators() {
    this.setSingleValidator(CONTACT_FORM.companyName, [Validators.required]);
    this.setSingleValidator(CONTACT_FORM.email, [Validators.pattern(Regex.email)]);

    this.setSingleValidator(CONTACT_FORM.firstName, []);
    this.setSingleValidator(CONTACT_FORM.lastName, []);
  }

  setSingleValidator(name: string, validators: ValidatorFn[]) {
    const control = this.form.get(name);
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  submitContact() {
    Object.keys(CONTACT_FORM).forEach((key) => {
      const control = this.form.get(key);
      control.markAsTouched();
      control.updateValueAndValidity();
    });

    if (this.loading || this.form.invalid) {
      return;
    }
    this.loading = true;
    const obj = this.formData;
    obj.id ? this.updateContact(obj) : this.createContact(obj);
  }

  createContact(value) {
    this.service
      .setContact(value)
      .subscribe({
        next: (contact: Contact) => this.onSuccessSaveContact(contact),
        error: (e) => {
          this.s.error(this.t.instant('Contacts.Manage.errorCreatingContact'));
        },
      })
      .add(() => (this.loading = false));
  }

  onSuccessSaveContact(contact: Contact) {
    this.contact = contact;
    this.contact.isResponsible = this.form.get(CONTACT_FORM.isResponsible).value;
    this.s.success(this.t.instant('Contacts.Manage.successCreatingContact'));
    this.onAddContact.emit(contact);
    this.closeDialog();
  }

  updateContact(value) {
    this.service
      .updateContact(value)
      .subscribe({
        next: (contact: Contact) => this.onSuccessUpdateContact(contact),
        error: (e) => {
          this.s.error(this.t.instant('Contacts.Manage.errorEditingContact'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  onSuccessUpdateContact(contact: Contact) {
    this.contact = contact;
    this.contact.isResponsible = this.form.get(CONTACT_FORM.isResponsible).value;
    this.s.success(this.t.instant('Contacts.Manage.successEditingContact'));
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    return messages;
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.subTypeChange?.unsubscribe();
  }
}
