import { Component, ChangeDetectorRef } from '@angular/core';
import { TopProgressBarService } from '../../services/top-progress-bar.service';

@Component({
  selector: 'top-bar-progress-bar',
  templateUrl: './top-bar-progress-bar.component.html',
  styleUrls: ['./top-bar-progress-bar.component.scss'],
})
export class TopBarProgressBarComponent {
  constructor(public service: TopProgressBarService, private cdRef: ChangeDetectorRef) {}
}
