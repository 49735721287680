import { Config } from '@shared/configs/config';

export const publicFile = (url: string, width?: number, height?: number): string => {

  if(!url) return '';

  if (!width) {
    return Config.PUBLIC_PHOTO + url;
  } else {
    !height ? height = width : null;
    return Config.API + '/public/thumbnail/' + url + `?width=${width}&height=${height}`;
  }

};
