<main class="projects-timeline" #timeline>
  <projects-timeline-header
    [fullCalendar]="fullCalendarComponent"
    #timelineHeaderComponent
  ></projects-timeline-header>
  <div class="projects-timeline__calendar" #calendarContainer>
    <full-calendar [options]="calendarOptions" #fullCalendarComponent></full-calendar>
  </div>
  <projects-timeline-popover
    #popoverComponent
    (close)="openedPopover = null"
    (onProjectChange)="onProjectChange($event)"
    [project]="openedPopover?.project"
    [parentElement]="openedPopover?.parentElement"
    *ngIf="openedPopover?.project && openedPopover?.parentElement"
  ></projects-timeline-popover>
</main>
