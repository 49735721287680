import { map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import { Config } from '@shared/configs/config';

@Injectable()
export class EntryService extends BaseHttpService {
  entryStatusEvent: EventEmitter<
    EntryAction.STARTED_SAVING | EntryAction.COMPLETED_SAVING
  > = new EventEmitter();

  constructor() {
    super();
  }

  addEntry(data: any, objectToken?: string) {
    const url = `${Config.API}/entry`;

    if (!!objectToken) {
      data['objectToken'] = objectToken;
      delete data['id'];
    }

    return this.postWithSaving(url, data, false, true);
  }

  editEntry(data: any, id: number, objectToken?: string) {
    if (!!objectToken) {
      data['objectToken'] = objectToken;
      delete data['id'];
    }

    const url = `${Config.API}/entry/${id}`;
    return this.postWithSaving(url, data, false, true);
  }

  removeEntry(id: number) {
    const url = `${Config.API}/entry/${id}`;
    return this.delete(url, false);
  }

  // TODO ADD API
  restoreEntry(id: number) {
    const url = `${Config.API}/entry/${id}/restore`;
    return this.postWithSaving(url, {}, false, true);
  }

  postWithSaving(
    url: string,
    body: any,
    skipAuth: boolean = false,
    multipart: boolean = false,
    options?: any,
  ) {
    this.entryStatusEvent.emit(EntryAction.STARTED_SAVING);
    return this.post(url, body, skipAuth, multipart, options).pipe(
      map((res) => {
        this.entryStatusEvent.emit(EntryAction.COMPLETED_SAVING);
        return res;
      }),
    );
  }

  deleteWithSaving(url: string, skipAuth: boolean = false, options?: any) {
    this.entryStatusEvent.emit(EntryAction.STARTED_SAVING);
    return this.delete(url, skipAuth, options).pipe(
      map((res) => {
        this.entryStatusEvent.emit(EntryAction.COMPLETED_SAVING);
        return res;
      }),
    );
  }
}
