import { WindowHelper } from '@shared/helpers/window.helper';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeSearchComponent } from '@shared/components/employee-search/employee-search.component';
import {
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { Observable, of, Subscription } from 'rxjs';
import { TaskTypeList } from '@modules/protocols/shared/consts/task-type.list';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { Task } from '@shared/models/task.model';
import { AttachmentManagerListComponent } from '@shared/modules/attachment-manage/components/attachment-manager-list/attachment-manager-list.component';
import { MatDialog } from '@angular/material/dialog';
import { ObjectTaskType, TaskType } from '@shared/enums/task-type.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EventEmitter, Injectable } from '@angular/core';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { Config } from '@shared/configs/config';
import { TaskDiscardComponent } from '@shared/modules/task-sidenav/components/task-discard/task-discard.component';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { TaskViewState } from '@shared/enums/task-view-state.enum';
import { concatMap, distinctUntilChanged, finalize, tap, filter } from 'rxjs/operators';
import { Project } from '@modules/projects/shared/models/project.model';
import { DaysList } from '@modules/protocols/shared/consts/days.list';
import { FinancialList } from '@modules/protocols/shared/consts/financial.list';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { ChangeSource } from '@modules/projects/shared/enums/change-source.enum';
import { ETaskAction } from '../enums/task-action.enum';
import { ITaskActionEvent } from '../interfaces/task-action-event.interface';
import { StorageService } from '@core/services/storage.service';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { AssignedContactList } from '@shared/models/assigned-contact.model';
import { ProjectChangesSettlementStatus } from '@modules/projects/shared/enums/project-change-settlement-status.enum';
import { TaskSelectionV2ChangeComponent } from '@shared/modules/tasks/components/task-selection-change-v2/task-selection-change-v2.component';

@Injectable({ providedIn: 'root' })
export class TaskSidenavService {
  TASK_SIDENAV_FORM = TASK_SIDENAV_FORM;
  TaskConfigType: TaskType = TaskType.TYPE_TASK;
  form: FormGroup;
  loading: boolean = false;
  projectID: number = null;
  defaultGroupID: number = null;
  showInCalendar: boolean = false;
  attachmentsListRef: AttachmentManagerListComponent;
  searchEmployeeRef: EmployeeSearchComponent;
  groupRef: any;
  financeTemplate: TaskSelectionV2ChangeComponent;
  daysTemplate: TaskSelectionV2ChangeComponent;

  isOpened: boolean = false;
  taskId: number = null;
  task: Task = null;
  connectedTask: Task = null;
  viewState: TaskViewState = null;
  loadingTask: boolean = false;
  safeContent: any = null;
  safeContentInternalNote: any = null;

  mockList: AssignedContactList = new AssignedContactList();

  subUpdateTask: Subscription = new Subscription();

  assignedUsers: any = [];
  permissionCtrl: TaskPermissionController = new TaskPermissionController();

  financial: Array<any> = FinancialList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  days: Array<any> = DaysList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  selectedType: { type: string; name: string } = TaskTypeList[0];
  selectedGroup: { id: number; text: string } = null;
  selectedDate: { start: string; end: string } = null;
  selectedEmployees: { assignedToIds?: number[]; assignedEmails?: string[] } = null;

  previewFormSub: Subscription;
  statusSubscription: Subscription;
  taskSubscription: Subscription;
  sub: Subscription = new Subscription();

  savedFormValues: Record<TASK_SIDENAV_FORM, string>;
  taskActionEmitter: EventEmitter<ITaskActionEvent> = new EventEmitter();

  changeStatusHelper: FormControl = new FormControl(ProjectChangesStatus.CHANGE_STATUS_CREATED);

  get canEditTask() {
    return this.permissionCtrl.edit && (this.isPreview || this.isEdit);
  }

  get canAddTask() {
    return this.permissionCtrl.add && this.isCreation;
  }

  get isTaskSelection() {
    return this.isTask || this.isCorrection || this.isOrder || this.isNote || this.isClientTask;
  }

  get isTaskCompanyClient() {
    return this.isCompanyTask || this.isClientTask;
  }

  get isCompanyTask() {
    return this.TaskConfigType === TaskType.TYPE_COMPANY_TASK || this.TaskConfigType === TaskType.TYPE_TASK;
  }

  get FormType() {
    return this.form.get(TASK_SIDENAV_FORM.type).value;
  }

  get isChange() {
    return this.FormType === TaskType.TYPE_CHANGE;
  }

  get isConfigTask() {
    return this.FormType !== TaskType.TYPE_CHANGE;
  }

  get isClientTask() {
    return this.FormType === TaskType.TYPE_CLIENT_TASK;
  }

  get isNote() {
    return this.FormType === TaskType.TYPE_NOTE;
  }

  get isOrder() {
    return this.FormType === TaskType.TYPE_ORDER;
  }

  get isConfigChange() {
    return this.FormType === TaskType.TYPE_CHANGE;
  }

  get isCorrection() {
    return this.FormType === TaskType.TYPE_CORRECTION;
  }

  get isTask() {
    return this.FormType === TaskType.TYPE_TASK;
  }

  get isProtocol() {
    return this.form.get(TASK_SIDENAV_FORM.source).value === ChangeSource.SOURCE_PROTOCOL;
  }

  get formProjectId() {
    return this.form.get(TASK_SIDENAV_FORM.projectId).value;
  }

  get TagUrl() {
    const id = !isNaN(Number(this.formProjectId)) ? Number(this.formProjectId) : null;
    return `${Config.API}/tag/type?type=task-tag${id ? '&projectId=' + id : ''}`;
  }

  get objectType() {
    return this.form.get(TASK_SIDENAV_FORM.objectType).value;
  }

  get initialFormValues() {
    return {
      [TASK_SIDENAV_FORM.status]: TaskStatus.CREATED,
      [TASK_SIDENAV_FORM.changeStatus]: ProjectChangesStatus.CHANGE_STATUS_CREATED,
      [TASK_SIDENAV_FORM.objectType]: ObjectTaskType.PLAIN,
      [TASK_SIDENAV_FORM.type]: this.TaskConfigType,
      [TASK_SIDENAV_FORM.id]: null, // Helper ID is used to fill project/protocol id before send
      [TASK_SIDENAV_FORM.groupId]: this.defaultGroupID,
      [TASK_SIDENAV_FORM.isHighPriority]: false,
      [TASK_SIDENAV_FORM.hiddenForClient]: true,
      [TASK_SIDENAV_FORM.discount]: 0,
      [TASK_SIDENAV_FORM.description]: '',
      [TASK_SIDENAV_FORM.content]: null,
      [TASK_SIDENAV_FORM.termStart]: null,
      [TASK_SIDENAV_FORM.termEnd]: null,
      [TASK_SIDENAV_FORM.assignedTo]: null,
      [TASK_SIDENAV_FORM.tags]: null,
      [TASK_SIDENAV_FORM.attachments]: [],
      [TASK_SIDENAV_FORM.attachmentPacketId]: null,
      [TASK_SIDENAV_FORM.price]: null,
      [TASK_SIDENAV_FORM.isAdditionalTimeEstimated]: 0,
      [TASK_SIDENAV_FORM.isPriceEstimated]: 0,
      [TASK_SIDENAV_FORM.additionalTime]: null,
      [TASK_SIDENAV_FORM.attachmentPacketId]: null,
      [TASK_SIDENAV_FORM.source]: null,
      [TASK_SIDENAV_FORM.linkedChangeId]: null,
      [TASK_SIDENAV_FORM.projectId]: this.projectID,
      [TASK_SIDENAV_FORM.protocolId]: null,
      [TASK_SIDENAV_FORM.internalNote]: '',
      [TASK_SIDENAV_FORM.settlementStatus]: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NONE,
      [TASK_SIDENAV_FORM.showInCalendar]: this.showInCalendar,
    };
  }

  get isPreview() {
    return this.viewState === TaskViewState.PREVIEW;
  }

  get isEdit() {
    return this.viewState === TaskViewState.EDIT;
  }

  get isCreation() {
    return this.viewState === TaskViewState.CREATE;
  }

  get currentFormValues() {
    return Object.assign(this.form.value, {});
  }

  get isStatusChanged() {
    return this.savedFormValues?.changeStatus !== this.currentFormValues?.changeStatus;
  }

  get isFormValid() {
    if (this.isConfigChange) {
      return this.form.valid && this.financeTemplate.form.valid && this.daysTemplate.form.valid;
    }
    return this.form.valid;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public sideService: SidenavService,
    private taskService: TaskService,
    public listTaskService: ListTaskService,
    public t: TranslateService,
    private sanitizer: DomSanitizer,
    public storage: StorageService,
  ) {
    this.createForm();
  }

  setInitialData(options: any) {
    this.changeStatusHelper.setValue(ProjectChangesStatus.CHANGE_STATUS_CREATED);
    this.isOpened = true;
    //#region set form data from options
    this.viewState = options?.viewState || null;

    this.setProjectId(Number(options?.projectID));
    this.setObjectType(options?.extra?.objectType);
    this.setTaskTypeOnInit(options?.extra?.type);
    this.setGroupID(options.taskGroupId);
    this.setAssigneeEmails(options?.assignToEmails);
    this.setAssigneeIds(options?.assignToIds);
    this.setVisibilityInCalendar(options?.showInCalendar || false);

    //#endregion

    //#region set form data from server
    this.isCreation ? this.requestConnectedTask(options?.connectedTaskId) : null;
    this.isPreview || this.isEdit ? this.setPreviewState(options) : null;
    //#endregion

    //#region set validators
    this.setRequiredValidators(TASK_SIDENAV_FORM.projectId, !!this.isConfigTask);
    //#endregion

    //#region set subscribers
    this.sourceSubscriber();
    setTimeout(() => {
      this.typeSubscriber();
    }, 200);

    //#endregion

    //#region set permission
    this.permissionCtrl.initPermissions(this.task);
    //#endregion

    //#region set historical data
    this.setFromHistory();
    //#endregion
  }

  setVisibilityInCalendar(visible: boolean) {
    this.showInCalendar = visible;
    this.form.get(TASK_SIDENAV_FORM.showInCalendar).setValue(this.showInCalendar);
  }

  getTaskRequest(id: number): Observable<Task> {
    this.loadingTask = true;
    return this.taskService.getTask(id);
  }

  setProjectId(id: number) {
    if (!id) {
      return;
    }
    this.projectID = id || null;
    this.form.get(TASK_SIDENAV_FORM.projectId).setValue(id);
  }

  setObjectType(type: TaskType) {
    if (!type) {
      return;
    }
    this.form.get(TASK_SIDENAV_FORM.objectType).setValue(type);
  }

  getConnectedTask(connectedTaskId: number) {
    return this.getTaskRequest(connectedTaskId).pipe(
      tap((task: Task) => this.successSettingConnectedTask(task)),
    );
  }

  requestConnectedTask(connectedTaskId: number) {
    if (!connectedTaskId) {
      return;
    }
    this.getConnectedTask(connectedTaskId)
      .subscribe()
      .add(() => {
        this.loadingTask = false;
      });
  }

  successSettingConnectedTask(task: Task) {
    this.setConnectedTask(task);
    this.form.get(TASK_SIDENAV_FORM.description).setValue(task.description);
    this.setFromHistory();
  }

  setConnectedTask(task: Task) {
    this.connectedTask = task ? this.createTask(task) : null;
    this.form.get(TASK_SIDENAV_FORM.linkedChangeId).setValue(task?.id || null);
  }

  setFromHistory() {
    this.savedFormValues = this.currentFormValues;
  }

  sourceSubscriber() {
    const sourceSub = this.form
      .get(TASK_SIDENAV_FORM.source)
      .valueChanges.subscribe(() =>
        this.setRequiredValidators(TASK_SIDENAV_FORM.protocolId, !this.isProtocol),
      );

    this.sub.add(sourceSub);
  }

  typeSubscriber() {
    const typeSub = this.form.get(TASK_SIDENAV_FORM.type).valueChanges.subscribe((type: string) => {
      this.updateTaskType(type);
    });
    this.sub.add(typeSub);
  }

  updateTaskType(type: string) {
    const index = this.groupRef?._items?.findIndex((i) => i.name === type);
    this.setRequiredValidators(TASK_SIDENAV_FORM.projectId, !!this.isConfigTask);

    if (index > -1) {
      this.defaultGroupID = this.groupRef._items[index].id;
      this.form.get(TASK_SIDENAV_FORM.groupId).patchValue(this.groupRef._items[index].id, { onlySelf: true });
    }

    if (!this.isConfigChange) {
      //Reset source when change CHANGE -> ANY OTHER STATE
      this.form.get(TASK_SIDENAV_FORM.source).setValue(null);
    }
    this.taskActionEmitter.emit({ action: ETaskAction.ACTION_UPDATE_TEMPLATE });
  }

  compareFormValues(
    stateOld: Record<TASK_SIDENAV_FORM, string>,
    stateNew: Record<TASK_SIDENAV_FORM, string>,
  ) {
    return JSON.stringify(stateOld) === JSON.stringify(stateNew);
  }

  getSidenavTask(options) {
    const source = options.task ? of(options.task) : this.getTaskRequest(options?.taskId);
    return source.pipe(tap((task) => this.initPreviewState(task)));
  }

  setPreviewState(options: any) {
    this.loadingTask = true;
    this.getSidenavTask(options)
      .subscribe()
      .add(() => {
        this.loadingTask = false;
      });
  }

  setViewTerm() {
    this.rangeChanged({ start: this.task?.termStart, end: this.task?.termEnd });
  }

  setTaskTypeFromSave() {
    const control = this.form.get(TASK_SIDENAV_FORM.type);
    this.selectedType = TaskTypeList.filter((i) => i.type === control.value)[0] || null;
  }

  setViewTags() {
    this.form.get(TASK_SIDENAV_FORM.tags).setValue(this.task.tags.map((i) => i.name) || null);
  }

  setViewAssignedEmployees() {
    const ids = this.task?.assignedTo
      .map((i) => i.id)
      .concat((this.task?.assignedEmails?.map((i: string) => i) as any) || []);
    this.form.get(TASK_SIDENAV_FORM.assignedTo).setValue(ids);
  }

  setStatusCompleted(task: Task) {
    this.task.status = task.status;
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_TASK, data: this.task });
    this.form.get(TASK_SIDENAV_FORM.status).setValue(this.task.status);
  }

  initPreviewState(task: Task) {
    this.taskActionEmitter.emit({ action: ETaskAction.ACTION_TASK_LOADED });
    this.sub?.unsubscribe();
    this.taskSubscription?.unsubscribe();
    this.subUpdateTask?.unsubscribe();
    this.previewFormSub?.unsubscribe();

    this.sub = new Subscription();
    this.setTaskTypeOnInit(task.type);
    // 1. Fill the form from backend
    this.fillPreviewState(task);
    // 2.  Set connected Task
    this.setConnectedTask(task?.linkedChange);
    // 3. Subscribe for form changes without first change
    // 4. Save Form on any submit
    this.subscribePreviewChange();
    this.subscribeUpdateTask();

    this.setFromHistory();
  }

  setTaskTypeOnInit(type: TaskType) {
    if (!type) {
      return;
    }
    this.TaskConfigType = type;
    this.form.get(TASK_SIDENAV_FORM.type).setValue(this.TaskConfigType);
  }

  fillPreviewState(task: Task) {
    this.setNewTask(task);
    this.setTaskGroup({
      id: task?.taskGroup?.id,
      text: this.translate('Projects.TaskGroups.', task?.taskGroup?.name),
    });
    this.defaultFormFill();
    this.setViewTerm();
    this.setTaskTypeFromSave();
    this.setViewTags();
    this.setViewAssignedEmployees();
    this.setSourceProtocol();
    this.setChangeStatus();
    this.safeContent = this.task?.content ? this.sanitizer.bypassSecurityTrustHtml(this.task?.content) : null;
    this.safeContentInternalNote = this.task?.internalNote
      ? this.sanitizer.bypassSecurityTrustHtml(this.task?.internalNote)
      : null;

    this.form
      .get(TASK_SIDENAV_FORM.settlementStatus)
      .setValue(task?.settlementStatus?.toString() || ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NONE, {
        onlySelf: true,
      });
  }

  setChangeStatus() {
    let status = this.task?.changeStatus;
    if (this.task?.changeStatus === ProjectChangesStatus.CHANGE_STATUS_EDITED) {
      status = ProjectChangesStatus.CHANGE_STATUS_CREATED;
    }
    this.form.get(TASK_SIDENAV_FORM.changeStatus).setValue(status, { onlySelf: true });
    this.changeStatusHelper.setValue(status, { onlySelf: true });
  }

  setSourceProtocol() {
    this.form.get(TASK_SIDENAV_FORM.protocolId).setValue(this.task?.protocol?.id || null);
  }

  translate(prefix, value: string) {
    const translation = this.t.instant(`${prefix}${value}`);
    if (translation !== `${prefix}${value}`) {
      return translation;
    }
    return value;
  }

  setGroupID(id: number, onlySelf: boolean = true) {
    if (!id) {
      return;
    }
    this.defaultGroupID = id || null;
    this.form
      .get(TASK_SIDENAV_FORM.groupId)
      .patchValue(this.defaultGroupID, onlySelf ? { onlySelf: true } : {});
  }

  setAssigneeEmails(emails: string[]) {
    if (emails?.length) {
      this.assignedUsers = [...emails.map((e) => this.mockList.createCustomTag(e))];
      this.form.get(TASK_SIDENAV_FORM.assignedTo).setValue(this.assignedUsers.map((i) => i.id));
    }
  }

  setAssigneeIds(ids: number[]) {
    if (ids?.length) {
      this.assignedUsers = [...ids];
      this.form.get(TASK_SIDENAV_FORM.assignedTo).setValue(ids);
    }
  }

  defaultFormFill() {
    const ignoreStates = [
      TASK_SIDENAV_FORM.id,
      TASK_SIDENAV_FORM.protocolId,
      TASK_SIDENAV_FORM.projectId,
    ].map((i) => i.toString());
    // Don`t put id to Form, because that is for project
    Object.keys(this.task).forEach((key: string) => {
      if (!(ignoreStates.indexOf(key) > -1)) {
        const control = this.form.get(key);
        !!control ? control.setValue(this.task[key]) : null;
      }
    });
  }

  subscribePreviewChange() {
    if (this.task?.isDeleted || !this.isPreview || !this.permissionCtrl.edit) {
      return;
    }
    const $source = this.form.valueChanges
      .pipe(distinctUntilChanged((a, b) => this.compareFormValues(a, b)))
      .pipe(concatMap(() => this.saveForm()));
    this.previewFormSub = $source.subscribe((res) => null);
  }

  subscribeUpdateTask() {
    // || !this.isPreview
    if (this.task?.isDeleted) {
      return;
    }

    const actions = [ETaskAction.ACTION_SHARE];
    const actionsSub = this.taskActionEmitter
      .pipe(filter((i) => actions.indexOf(i.action) > -1))
      ?.subscribe((i: ITaskActionEvent) => this.reloadTask());

    const protocolsSub = this.taskService.updateTaskList.subscribe(() => this.reloadTask());

    this.sub.add(actionsSub);
    this.sub.add(protocolsSub);
  }

  reloadTask() {
    if (!this.isOpened) return;
    this.getTaskRequest(this.task?.id)
      .subscribe((task: Task) => this.initPreviewState(task))
      .add(() => (this.loadingTask = false));
  }

  saveForm(obj?: any) {
    this.loading = true;
    // console.log(obj);
    return this.taskService.editTask(this.getFormData(), this.task.id).pipe(
      tap((task: Task) => {
        this.initPreviewState(task);
        this.taskActionEmitter.emit({ action: ETaskAction.ACTION_UPDATE, data: { task } });
        this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_TASK, data: new Task(task) });
        this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
      }),
      finalize(() => (this.loading = false)),
    );
  }

  setSelectedItems() {
    this.selectedEmployees = this.searchEmployeeRef?.formData || null;
  }

  createForm() {
    this.form = this.fb.group(this.initialFormValues);
    this.setRequiredValidators(TASK_SIDENAV_FORM.description);
  }

  setRequiredValidators(name: string, clear: boolean = false) {
    this.form.get(name).setValidators(clear ? [] : [Validators.required]);
    this.form.get(name).updateValueAndValidity();
  }

  //#region
  registerAttachmentList(attachmentsListRef: AttachmentManagerListComponent) {
    this.attachmentsListRef = attachmentsListRef;
  }

  unregisterAttachmentList() {
    this.attachmentsListRef = null;
  }

  //#endregion

  //#region
  registerGroupRef(ref: any) {
    this.groupRef = ref;
  }

  unregisterGroupRef() {
    this.groupRef = null;
  }

  //#endregion

  //#region
  registerSearchEmployee(searchEmployeeRef: EmployeeSearchComponent) {
    this.searchEmployeeRef = searchEmployeeRef;
  }

  unregisterSearchEmployee() {
    this.searchEmployeeRef = null;
  }

  //#endregion

  //#region
  registerChangeTemplates(options) {
    this.financeTemplate = options.financeTemplate;
    this.daysTemplate = options.daysTemplate;
  }

  unregisterChangeTemplates() {
    this.financeTemplate = null;
    this.daysTemplate = null;
  }

  //#endregion

  rangeChanged($event) {
    const startControl = this.form.get(TASK_SIDENAV_FORM.termStart);
    const endControl = this.form.get(TASK_SIDENAV_FORM.termEnd);

    startControl.setValue(
      !!$event.start ? moment($event.start, Config.DATE_SERVER).format(Config.DATE_SERVER) : null,
      { onlySelf: true },
    );

    endControl.setValue(
      !!$event.end ? moment($event.end, Config.DATE_SERVER).format(Config.DATE_SERVER) : null,
      { onlySelf: true },
    );

    this.selectedDate = {
      start: startControl.value
        ? moment(startControl.value, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS)
        : null,
      end: endControl.value
        ? moment(endControl.value, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS)
        : null,
    };
    startControl.setValue(startControl.value);
  }

  closeTaskSidenav() {
    this.isOpened = false;
    this.sideService.close();
    this.hardResetSidenav();
  }

  resetTaskSidenav() {
    this.taskSubscription?.unsubscribe();
    this.subUpdateTask?.unsubscribe();
    this.previewFormSub?.unsubscribe();
    this.form.setValue(this.initialFormValues);
    Object.keys(this.TASK_SIDENAV_FORM).forEach((key: string) => {
      this.form.get(key)?.markAsUntouched();
      this.form.get(key)?.updateValueAndValidity({ onlySelf: true });
    });
  }

  hardResetSidenav() {
    this.showInCalendar = false;
    this.resetTaskSidenav();
    this.resetDefaultValuesWithinSidenav();
    this.taskActionEmitter.emit({ action: ETaskAction.ACTION_DISCARD, data: {} });
  }

  resetDefaultValuesWithinSidenav() {
    this.unregisterChangeTemplates();
    this.unregisterAttachmentList();
    this.unregisterSearchEmployee();
    this.unregisterGroupRef();

    this.defaultGroupID = null;
    this.projectID = null;
    this.connectedTask = null;
    this.task = null;
    this.taskId = null;
    this.selectedDate = null;
    this.selectedEmployees = null;
    this.assignedUsers = [];
    this.permissionCtrl.initPermissions(this.task);

    this.subUpdateTask?.unsubscribe();
    this.statusSubscription?.unsubscribe();
    this.previewFormSub?.unsubscribe();
  }

  setTaskGroup(i: { id: number; text: string }) {
    this.selectedGroup = i;
    this.setGroupID(i?.id, false);
  }

  setTaskType(i: { type: string; name: string }) {
    this.selectedType = i;
    this.form.get(TASK_SIDENAV_FORM.type).setValue(i?.type);
  }

  setNewTask(task: Task) {
    this.task = this.createTask(task);
    this.projectID = task?.project?.id || null;
    this.assignUsers();
    this.permissionCtrl.initPermissions(this.task);
    this.form.get(TASK_SIDENAV_FORM.projectId).patchValue(this.projectID, { onlySelf: true });
  }

  createTask(_task: Task): Task {
    const task = new Task(_task);
    task.project ? (task.project = new Project(task.project)) : null;
    task.setChildrenFirstLevel();
    return task;
  }

  assignUsers() {
    const emailCustomTags = this.task?.assignedEmails?.map((i) => this.mockList?.createCustomTag(i)) || [];
    const userCustomTags =
      this.task?.assignedUsers?.map((i) => this.mockList?.createTagFromUserPerson(i)) || [];

    this.assignedUsers = [...userCustomTags.concat(emailCustomTags)];
  }

  createCustomTag(name: string): any {
    return { email: name, previewName: name, helperID: name, newItem: true, label: name };
  }

  goToProjectSummary() {
    const ctrl = new ProjectController(this.task.project);
    const prom = (
      this.isConfigChange ? ctrl.prewiewChanges(this.task.project) : ctrl.previewTasks(this.task.project)
    ) as Promise<boolean>;
    prom?.then(() => {
      if (WindowHelper.isMoreLessMD) {
        this.sideService.close();
      }
    });
  }

  discardSaving() {
    const nothingChanged =
      (this.isCreation || this.isEdit) &&
      this.compareFormValues(this.savedFormValues, this.currentFormValues) &&
      !this.attachmentsListRef?.service?.attachmentPacket?.id;

    if (this.isPreview || nothingChanged) {
      this.closeTaskSidenav();
      return;
    }
    const ref = this.dialog.open(TaskDiscardComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        type: this.TaskConfigType,
        viewState: this.viewState,
        confirm: this.closeTaskSidenav.bind(this),
      },
    });
    return ref;
  }

  getFormData(obj?: any) {
    const projectIDCtrl = this.form.get(TASK_SIDENAV_FORM.projectId);
    const model = obj ? Object.assign(obj, this.form.value) : Object.assign({}, this.form.value);
    model.groupId = projectIDCtrl?.value ? model.groupId : null;
    model.attachmentPacketId = this?.attachmentsListRef?.service?.attachmentPacket?.id || null;
    model.attachments = [];

    model.objectType = ObjectTaskType.PROJECT;

    if (this.isTaskCompanyClient) {
      model.objectType = ObjectTaskType.PROJECT_BOX_1;
    }

    if (this.isConfigChange && this.isProtocol) {
      model.id = model.protocolId;
      model.objectType = ObjectTaskType.PROTOCOL;
    } else {
      model.id = model.projectId;
    }

    model.objectType = projectIDCtrl?.value ? model.objectType : ObjectTaskType.PLAIN;
    delete model.assignedToIds;
    delete model.protocol;

    this.setSelectedItems();
    const data = Object.assign(model, this.selectedEmployees);

    const withoutResend = () => {
      const o = Object.assign({}, this.savedFormValues);
      const n = Object.assign({}, this.currentFormValues);

      const priorityChanged = o?.isHighPriority !== n?.isHighPriority;
      delete o?.isHighPriority;
      delete n?.isHighPriority;

      const hiddenForClientChanged = o?.hiddenForClient !== n?.hiddenForClient;
      delete o?.hiddenForClient;
      delete n?.hiddenForClient;

      const internalNoteChanged = o?.internalNote !== n?.internalNote;
      delete o?.internalNote;
      delete n?.internalNote;

      const settlementStatusChanged = o?.settlementStatus !== n?.settlementStatus;
      delete o?.settlementStatus;
      delete n?.settlementStatus;

      // console.log('here', o, n, this.compareFormValues(o, n));
      // console.log('priority changed:', priorityChanged);
      // console.log('hiddenForClient changed:', hiddenForClientChanged);
      return (
        (priorityChanged || hiddenForClientChanged || internalNoteChanged || settlementStatusChanged) &&
        this.compareFormValues(o, n)
      );
    };
    // console.log(model, 'should resend');
    const _withoutResend = withoutResend();
    //Helper var for Backend
    data.dataChanges = this.isStatusChanged || _withoutResend ? 0 : 1;
    return data;
  }

  addNewTask() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    return this.taskService.createTask(this.getFormData()).pipe(
      tap((task: Task) => {
        this.setConnectedTask(task?.linkedChange);
        this.successAddingTask(task);
      }),
      finalize(() => (this.loading = false)),
    );
  }

  successAddingTask(task: Task) {
    this.resetTaskSidenav();
    this.taskActionEmitter.emit({ action: ETaskAction.ACTION_CREATE, data: { task } });
    this.listTaskService.emitter.emit({ type: ListTaskEventType.ADD_TASK, data: task });
  }

  deleteTask() {
    return this.taskService.deleteTask(this.task.id).pipe(
      tap((i) => {
        this.updateTaskOnRemove();
        this.taskActionEmitter.emit({ action: ETaskAction.ACTION_DELETE, data: { task: this.task } });
      }),
    );
  }

  updateTaskOnRemove() {
    this.task.deleted = moment().format(Config.DATE_SERVER);
  }
}
