import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification-base',
  templateUrl: './notification-base.component.html',
  styleUrls: ['./notification-base.component.scss']
})
export class NotificationBaseComponent {

  @Input() text: string;

  constructor() { }

}
