<div class="project-right-section" [ngClass]="{'project-right-section--visible':isVisibleOnMobile}" #projectRightSection>
  <div class="project-right-section__back" (click)="onCloseRightSection.emit(true)">
    <svg-icon [src]="'/assets/img/ic-arrow-left.svg'" [svgStyle]="{width: '24px', height: '24px'}"></svg-icon>
    {{'Projects.back' | translate}}
  </div>
  <div class="project-right-section__content">
    <ng-content></ng-content>
  </div>
</div>

