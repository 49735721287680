import { ChangeDetectorRef, Input, Directive } from '@angular/core';
import { WindowHelper } from '@shared/helpers/window.helper';

@Directive()
export class BaseAvatarComponent {

  private _imageUrl: string = '';

  get imageUrl() {
    return this._imageUrl;
  }

  @Input() set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
    this.changes.detectChanges();
  }

  @Input() width: number = 44;
  @Input() mobileConfig?: AvatarMobileConfig;

  constructor(public changes: ChangeDetectorRef) {
  }

  get avatarWidth() {
    if (!this.mobileConfig) {
      return this.width;
    }
    if (WindowHelper.isLessMobileWidth && this.mobileConfig.sm) {
      return this.mobileConfig.sm;
    }
    if (WindowHelper.isLessMD && this.mobileConfig.md) {
      return this.mobileConfig.md;
    }
    if (WindowHelper.isLessXL && this.mobileConfig.xl) {
      return this.mobileConfig.xl;
    }
    return this.width;
  }
}

export interface AvatarMobileConfig {
  sm?: number;
  md?: number;
  xl?: number;
}
