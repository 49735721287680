<modal-title [title]="'Projects.newTaskGroupTitle' | translate"></modal-title>
<div mat-dialog-content>
  <gbxsoft-input
    [class]="'required'"
    [formControl]="group"
    (keydown.enter)="submit()"
    [errMessages]="errorMessages"
    [config]="{ name: 'Projects.newTaskGroupName' | translate }"
  ></gbxsoft-input>
</div>
<mat-dialog-actions>
  <div class="d-flex aligh-items-center justify-content-between w-100 flex-wrap">
    <div>
      <app-button
        *ngIf="data.edit"
        (onClick)="onRemoveGroup()"
        [class]="'bold mr-2'"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.DANGER_BUTTON,
          text: 'Projects.removeGroup' | translate
        }"
      ></app-button>
    </div>

    <div>
      <app-button
        (onClick)="close()"
        [class]="'bold mr-2'"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Projects.discard' | translate
        }"
      ></app-button>

      <app-button
        [loading]="loading"
        (onClick)="submit()"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Projects.saveAndEnd' | translate,
          prependSrc: './assets/img/ic-save.svg'
        }"
      ></app-button>
    </div>
  </div>
</mat-dialog-actions>
