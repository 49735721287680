<modal-title [title]="'ProjectTeam.Manage.inviteSent' | translate"></modal-title>
<!-- #region Content -->

<div class="project-team-manage-success-modal">
  <h4 *ngIf="data.emails.length === 1">{{'ProjectTeam.Manage.youInvitedUsersTitleSingle' | translate:{count: data.emails.length} }}</h4>
  <h4 *ngIf="data.emails.length > 1">{{'ProjectTeam.Manage.youInvitedUsersTitleMultiple' | translate:{count: data.emails.length} }}</h4>
  <p>{{'ProjectTeam.Manage.youInvitedUsersDescription' | translate}}</p>
  <mat-dialog-content class="project-team-manage-success-modal__content">
    <span class="project-team-manage-success-modal__tag"
          matTooltip="{{ email}}"
          [matTooltipPosition]="'above'"
          *ngFor="let email of data.emails">
      {{email}}
    </span>
  </mat-dialog-content>
</div>

<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    (onClick)="dialogRef.close()"
    [config]="{
      text: 'ProjectTeam.Manage.close' | translate,
      type: ButtonTypes.PRIMARY_BLUE,
      size: ButtonSize.LARGE
    }"
  ></app-button>

</mat-dialog-actions>
<!-- #endregion -->
