<notification-base [text]="text"></notification-base>

<div class="d-flex mt-1 mb-1" *ngIf="status === EParticipantStatus.STATUS_UNKNOWN">
  <a
  draggable="false"
    [attr.disabled]="loading"
    class="mr-2 green-500 event"
    (click)="accept()">
    <svg-icon src="/assets/img/ic-check-green.svg" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"></svg-icon>
    {{'Notifications.Texts.Protocol.accept' | translate}}</a>
  <a
  draggable="false"
    [attr.disabled]="loading"
    class="red-500 event"
    (click)="decline()">
    <svg-icon src="/assets/img/ic-close-red.svg" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"></svg-icon>
    {{'Notifications.Texts.Protocol.decline' | translate}}</a>
</div>

<div class="d-flex mt-1 mb-1" *ngIf="status !== EParticipantStatus.STATUS_UNKNOWN">
  <span class="green-500" *ngIf="status === EParticipantStatus.STATUS_ATTENDANCE_CONFIRMED">{{'Calendar.takeParticipated' | translate}}</span>
  <span class="red-500" *ngIf="status === EParticipantStatus.STATUS_ATTENDANCE_REJECTED">{{'Calendar.notTakeParticipated' | translate}}</span>
</div>

<app-button
  (onClick)="preview()"
  [config]="{
        type: ButtonTypes.SECONDARY_GREY,
        text: 'Calendar.showEvent' | translate,
        size: ButtonSize.SMALL
      }">
</app-button>
