import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ListService } from '@shared/modules/list/services/list.service';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@shared/configs/config';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Employee } from '@shared/models/employee.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ProjectTeamListConfig } from '@project-modules/project-team/shared/configs/project-team-list.config';
import { ProjectTeamFiltersComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-filters/project-team-filters.component';
import { ProjectTeamEmployeeCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-employee-cell/project-team-employee-cell.component';
import { ProjectTeamFunctionCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-function-cell/project-team-function-cell.component';
import { ProjectTeamPhoneCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-phone-cell/project-team-phone-cell.component';
import { ProjectTeamHourlyRateCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-hourly-rate-cell/project-team-hourly-rate-cell.component';
import { ProjectTeamRoleCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-role-cell/project-team-role-cell.component';
import { ProjectTeamRoleProjectCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-role-project-cell/project-team-role-project-cell.component';
import { ProjectTeamToggleMenuCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-toggle-menu-cell/project-team-toggle-menu-cell.component';
import { ProjectTeamEmailCellComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-email-cell/project-team-email-cell.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectTeamManageModalComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-manage-modal/project-team-manage-modal.component';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'project-team-list',
  templateUrl: './project-team-list.component.html',
  styleUrls: ['./project-team-list.component.scss'],
})
export class ProjectTeamListComponent extends BaseListComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;
  ProjectTeamFiltersComponent = ProjectTeamFiltersComponent;
  WindowHelper = WindowHelper;
  projectId: number;
  checkedTaskIds: number[] = [];

  @ViewChild('projectTeamEmployeeCellComponent')
  public projectTeamEmployeeCellComponent: ProjectTeamEmployeeCellComponent;
  @ViewChild('projectTeamEmailCellComponent')
  public projectTeamEmailCellComponent: ProjectTeamEmailCellComponent;
  @ViewChild('projectTeamFunctionCellComponent')
  public projectTeamFunctionCellComponent: ProjectTeamFunctionCellComponent;
  @ViewChild('projectTeamPhoneCellComponent')
  public projectTeamPhoneCellComponent: ProjectTeamPhoneCellComponent;
  @ViewChild('projectTeamHourlyRateCellComponent')
  public projectTeamHourlyRateCellComponent: ProjectTeamHourlyRateCellComponent;
  @ViewChild('projectTeamRoleCellComponent')
  public projectTeamRoleCellComponent: ProjectTeamRoleCellComponent;
  @ViewChild('projectTeamRoleProjectCellComponent')
  public projectTeamRoleProjectCellComponent: ProjectTeamRoleProjectCellComponent;

  constructor(
    public changes: ChangeDetectorRef,
    public listService: ListService,
    private contactService: ContactService,
    private basePanelService: BasePanelService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public projectPreviewService: ProjectPreviewService,
  ) {
    super(changes, listService);
    this.checkProjectId();
    this.configureHourlyRateColumn();
    this.initListConfig(ProjectTeamListConfig);
  }

  checkProjectId() {
    if (this.route?.parent?.parent?.snapshot?.params?.projectId) {
      this.projectId = this.route?.parent?.parent?.snapshot?.params?.projectId;
      ProjectTeamListConfig.url = `${Config.API}/project/${this.projectId}/employee/search-advanced`;
    } else {
      ProjectTeamListConfig.url = `${Config.API}/project/[{projectId}]/employee/search-advanced`;
    }
  }

  ngOnInit(): void {}

  configureHourlyRateColumn() {
    let isAddedHourlyRate = false;
    ProjectTeamListConfig.columns.map((c) => {
      if (c.prop === 'hourlyRate') {
        isAddedHourlyRate = true;
      }
    });

    const ctrl = new CheckPermission({ group: PermissionsGroups.PROJECT_TEAM, action: 'HOURLY' });
    if (ctrl.check()) {
      if (!isAddedHourlyRate) {
        ProjectTeamListConfig.columns.splice(2, 0, {
          prop: 'hourlyRate',
          name: 'ProjectTeam.List.hourlyRate',
          componentRef: 'projectTeamHourlyRateCellComponent',
          sortField: 'a.hourlyRate',
          resizeable: false,
          hideOnMobile: true,
          width: 100,
          order: 3,
        });
      }
    } else {
      ProjectTeamListConfig.columns = ProjectTeamListConfig.columns.filter((c) => c.prop !== 'hourlyRate');
    }
  }

  @Permission({
    group: PermissionsGroups.PROJECT_TEAM,
    action: 'ADD',
    objectCreatorIdMethod: (self) =>
      self?.projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  addNewTeamMember() {
    const dialog = this.dialog.open(ProjectTeamManageModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
    });
    dialog.componentInstance.projectId = this.projectPreviewService.project.id;
    this.sub.add(
      dialog.componentInstance.change.subscribe(() => {
        this.listService.getRows();
      }),
    );
  }

  showToggleMenu(row: Employee) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECT_TEAM,
      action: 'EDIT',
      objectCreatorId: this.projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id,
    });
    return !isNotNullOrUndefined(row.deleted) && ctrl.check();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sub.unsubscribe();
  }
}
