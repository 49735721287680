import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { CHIPS_VIEW, ListService } from '@shared/modules/list/services/list.service';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { Config } from '@shared/configs/config';
import { FormControl, FormGroup } from '@angular/forms';
import { Employee } from '@shared/models/employee.model';
import { ChangeSource } from '@modules/projects/shared/enums/change-source.enum';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { ProjectChangesSettlementStatus } from '@modules/projects/shared/enums/project-change-settlement-status.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ListSingleFilterRangeDateV2Component } from '@shared/modules/list/components/list-filter/components/list-single-filter-range-date-v2/list-single-filter-range-date-v2.component';

enum EProjectChangeAmountType {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  NO_PRICE = 'no-price',
  NOT_ESTIMATED = 'not-estimated',
}

@Component({
  selector: 'project-changes-filters',
  templateUrl: './project-changes-filters.component.html',
})
export class ProjectChangesFiltersComponent
  extends BaseListFiltersComponentComponent
  implements OnInit, OnDestroy
{
  Config = Config;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  statuses = [];
  sources = [];
  types = [];
  settlements = [];

  typeControl: FormControl = new FormControl(null);

  constructor(
    @Optional() @Inject(CHIPS_VIEW) public chipsView: boolean,
    public service: ListService,
    @Optional() private dialogRef: MatDialogRef<ProjectChangesFiltersComponent>,
  ) {
    super(service);
  }

  @ViewChild('dateRanePickerFilter') dateRanePickerFilter: ListSingleFilterRangeDateV2Component;

  ngOnInit(): void {
    this.createForm();
    this.setStatuses();
    this.setSources();
    this.setTypes();
    this.setSettlements();

    if (!this.chipsView) {
      this.service.readUrlParams(false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  listenQueryParams() {
    this.sub = this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      if (e.type === ListEventType.QUERY_PARAMS) {
        if (isNotNullOrUndefined(e.data['filters[a.price][eq]'])) {
          this.typeControl.setValue(EProjectChangeAmountType.NO_PRICE);
        }
        if (isNotNullOrUndefined(e.data['filters[a.price][gt]'])) {
          this.typeControl.setValue(EProjectChangeAmountType.INCREASE);
        }
        if (isNotNullOrUndefined(e.data['filters[a.price][lt]'])) {
          this.typeControl.setValue(EProjectChangeAmountType.DECREASE);
        }

        if (isNotNullOrUndefined(e.data['filters[a.isPriceEstimated][eq]'])) {
          this.typeControl.setValue(EProjectChangeAmountType.NOT_ESTIMATED);
        }
        this.setFiltersToForm(e.data);
      }
    });
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[a-creator][eq]': new FormControl(null),
      '[a-changeStatus][eq]': new FormControl(null),
      '[a-settlementStatus][eq]': new FormControl(null),
      '[a-price][gt]': new FormControl(null),
      '[a-price][lt]': new FormControl(null),
      '[a-price][eq]': new FormControl(null),
      '[a-isPriceEstimated][eq]': new FormControl(null),
      '[a-source][eq]': new FormControl(null),
      '[a-termStart][gte]': new FormControl(null),
      '[a-termEnd][lte]': new FormControl(null),
    });
  }

  setSources() {
    this.sources = [];
    Object.values(ChangeSource)
      // Removing protocols: IS-359
      .filter((key) => key !== ChangeSource.SOURCE_PROTOCOL)
      .map((key) => {
        this.sources.push({
          id: key,
          name: this.t.instant('Projects.ProjectChangeSource.' + key),
        });
      });
  }

  setTypes() {
    this.types = [
      {
        id: EProjectChangeAmountType.INCREASE,
        name: this.t.instant('ProjectChanges.List.increaseChange'),
      },
      {
        id: EProjectChangeAmountType.DECREASE,
        name: this.t.instant('ProjectChanges.List.decreaseChange'),
      },
      {
        id: EProjectChangeAmountType.NO_PRICE,
        name: this.t.instant('ProjectChanges.List.noPriceChange'),
      },
      {
        id: EProjectChangeAmountType.NOT_ESTIMATED,
        name: this.t.instant('ProjectChanges.List.withoutEstimation'),
      },
    ];
  }

  setSettlements() {
    this.settlements = [];

    Object.values(ProjectChangesSettlementStatus).map((key) => {
      this.settlements.push({
        id: key,
        name: this.t.instant('Projects.ProjectSettlement.' + key),
      });
    });
  }

  setStatuses() {
    this.statuses = [];

    Object.values(ProjectChangesStatus).map((key) => {
      if (key === ProjectChangesStatus.CHANGE_STATUS_SETTLED && !!this.store.Employee.isClient) {
        return;
      }
      if (
        key !== ProjectChangesStatus.CHANGE_STATUS_EDITED &&
        key !== ProjectChangesStatus.CHANGE_STATUS_SETTLED
      ) {
        this.statuses.push({
          id: key,
          name: this.t.instant('Projects.ProjectChangeStatuses.' + key),
        });
      }
    });
  }

  onTypeChange() {
    this.service.filtersForm.get('[a-price][gt]').setValue(null);
    this.service.filtersForm.get('[a-price][lt]').setValue(null);
    this.service.filtersForm.get('[a-price][eq]').setValue(null);
    this.service.filtersForm.get('[a-isPriceEstimated][eq]').setValue(null);

    switch (this.typeControl.value) {
      case EProjectChangeAmountType.INCREASE:
        this.service.filtersForm.get('[a-price][gt]').setValue(0);
        break;
      case EProjectChangeAmountType.DECREASE:
        this.service.filtersForm.get('[a-price][lt]').setValue(0);
        break;
      case EProjectChangeAmountType.NO_PRICE:
        this.service.filtersForm.get('[a-price][eq]').setValue(0);
        this.service.filtersForm.get('[a-isPriceEstimated][eq]').setValue(1);
        break;
      case EProjectChangeAmountType.NOT_ESTIMATED:
        this.service.filtersForm.get('[a-isPriceEstimated][eq]').setValue(0);
        break;
    }

    this.onChangeFilter();
  }

  creatorName(employee: Employee) {
    const e = new Employee(employee);
    return e.userPerson.previewName;
  }

  clearFilters() {
    Object.keys(this.service?.filtersForm.value).forEach((key) => {
      this.service.filtersForm.get(key).setValue(null);
    });
    this.typeControl.setValue(null);
    this.dateRanePickerFilter.clearDateRangePicker();
  }

  cancel() {
    this.dialogRef.close();
    this.refreshChips();
  }

  submit() {
    this.service.getRows();
    this.dialogRef.close();
    this.refreshChips();
  }
}
