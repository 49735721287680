<div class="mailing-panel" [ngClass]="{ 'mailing-panel--inner': !!active?.snapshot?.data?.innerEmails }">
  <div *ngIf="!active?.snapshot?.data?.innerEmails" class="bg-white py-3 project-wrapper">
    <div class="container">
      <div
        class="blue-500 project-address mb-1 elipsis"
        [innerHTML]="this.mailingService.project?.fullNameAndAddress || '&nbsp;'"
      ></div>
      <div
        class="grey-1 bold project-title elipsis"
        [innerHTML]="this.mailingService.project?.nameAndProjectID || '&nbsp;'"
      ></div>
    </div>
  </div>
  <main>
    <content-with-tabs
      [selectTabsOnMobile]="!active.snapshot?.data?.innerEmails"
      [tabLinks]="tabLinks"
    ></content-with-tabs>
  </main>
</div>
