import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppTourService} from '@shared/modules/app-tour/shared/services/app-tour.service';
import {CustomTourStepComponent} from './shared/components/custom-tour-step/custom-tour-step.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {TourMatMenuModule} from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/md-menu.module';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [CustomTourStepComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TourMatMenuModule
  ],
  exports: [CustomTourStepComponent]
})
export class AppTourModule {
  public static forRoot(): ModuleWithProviders<AppTourModule> {
    return {
      ngModule: AppTourModule,
      providers: [AppTourService]
    };
  }
}
