<label class="d-inline-flex align-items-center">
  <input
    type="radio"
    (change)="change($event)"
    [value]="valueName"
    [name]="name"
    [checked]="valueName === value"
  />
  <span></span>
  <span class="ml-2">{{ text }}</span>
</label>
