<div
  class="list-filter d-flex align-content-start align-md-content-center align-items-baseline justify-content-between flex-column flex-xl-row"
  [tourAnchor]="filtersTourAnchor"
>
  <div class="list-filter-label">
    <label
      class="mr-md-3 d-flex collapsed"
      data-toggle="collapse"
      data-target="#filters"
      aria-expanded="false"
      aria-controls="filters"
      *ngIf="WindowHelper.isMobileWidth"
    >{{ 'List.Filters.filters' | translate }}
      <img draggable="false" class="cursor-pointer expand-arrow" src="./assets/img/ic-circle-toggle.svg" alt="Toggle"/>
    </label>

    <label *ngIf="!WindowHelper.isMobileWidth" class="mr-md-3">{{ 'List.Filters.filters' | translate }} </label>
  </div>
  <div id="filters" class="collapse list-filter-wrapper" [ngClass]="{ show: !WindowHelper.isMobileWidth }">
    <div class="d-flex justify-content-between flex-column flex-xl-row">
      <div class="list-filter-content">
        <div>
          <ng-container *ngComponentOutlet="component"></ng-container>
        </div>
      </div>
      <div class="list-filter-search"
           (click)="searchInput.focus()"
           [ngClass]="{'list-filter-search--more-width': languageService.getCurrentLanguage() === Languages.DE}"
           *ngIf="isSearch">
        <input
          #searchInput
          type="text"
          [placeholder]="searchName ? searchName : ('List.Filters.search' | translate)"
          [formControl]="form.get('search')"
          (keydown)="onChange()"
        />
        <span class="list-filter-search__close" *ngIf="form.get('search')?.value" (click)="form.get('search').setValue(''); onChange()">
          <svg-icon [src]="'/assets/img/ic-close.svg'" [svgStyle]="{width: '16px', height: '16px'}"></svg-icon>
        </span>
      </div>
    </div>
  </div>
</div>
