<div class="task-client-change">
  <div class="change-acceptance">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4 change-acceptance__description">
          <h1>{{ 'ProjectChanges.changesAcceptanceTitle' | translate }}</h1>
          <div class="d-flex justify-content-between flex-wrap">
            <p>
              {{ 'ProjectChanges.changesAcceptanceDescription' | translate }}
            </p>
            <app-button
              (onClick)="checkProject()"
              [config]="{
                type: ButtonTypes.PRIMARY_BLUE,
                size: ButtonSize.LARGE,
                text: 'ProjectChanges.goToProject' | translate,
                prependSrc: './assets/img/ic-right-arrow-blue.svg'
              }"
            ></app-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let task of acceptance.tasks; let i = index" class="col-12 col-md-6 pb-4">
          <acceptance-item
            (update)="getSummary()"
            [index]="i"
            [task]="task"
            [token]="token"
          ></acceptance-item>
        </div>
      </div>
    </div>
  </div>

  <div class="sticky-footer">
    <app-summary-toggle [summary]="summary" [summaryConfig]="summaryConfig"></app-summary-toggle>
    <intilio-footer></intilio-footer>
  </div>
</div>
