<white-board [template]="template"></white-board>
<ng-template #template>
  <div class="container">
    <h2 class="mt-4 white-board-content-title">{{ 'Protocols.FillData.title' | translate }}</h2>
    <h4 class="white-board-content-subtitle">{{ 'Protocols.FillData.subtitle' | translate }}</h4>
    <p class="white-board-content-description">{{ 'Protocols.FillData.description' | translate }}</p>
    <form [formGroup]="form" (submit)="submit()">
      <div class="row">
        <div class="col-12 col-md-6">
          <gbxsoft-input
            item-loading
            [errMessages]="errorMessages('firstName')"
            class="text-control required"
            formControlName="firstName"
            [config]="{ name: 'Protocols.FillData.firstName' | translate, type: 'text', placeholder: '' }"
          >
          </gbxsoft-input>
        </div>
        <div class="col-12 col-md-6">
          <gbxsoft-input
            item-loading
            [errMessages]="errorMessages('lastName')"
            class="text-control required"
            formControlName="lastName"
            [config]="{ name: 'Protocols.FillData.lastName' | translate, type: 'text', placeholder: '' }"
          >
          </gbxsoft-input>
        </div>
      </div>
      <gbxsoft-input
        item-loading
        *ngIf="employee?.isEmployeeOwner"
        [errMessages]="errorMessages('companyName')"
        class="text-control required"
        formControlName="companyName"
        [config]="{ name: 'Protocols.FillData.companyName' | translate, type: 'text', placeholder: '' }"
      >
      </gbxsoft-input>
      <div class="text-right">
        <button
          type="reset"
          class="btn btn-empty_grey mr-sm-3 mb-3 mb-md-0 btn-sm-block"
          onclick="window.history.go(-1); return false;"
        >
          {{ 'Protocols.FillData.cancel' | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-primary ml-md-1 btn-sm-block"
          (click)="submit()"
          [disabled]="loading"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
          <img draggable="false"
            *ngIf="!loading"
            src="./assets/img/ic-arrow-right.svg"
            [alt]="'Protocols.FillData.continue' | translate"
          />
          {{ 'Protocols.FillData.continue' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
