import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Config } from '@shared/configs/config';
import { NavigateService } from '@shared/services/navigate.service';
import { BaseNavigationComponent } from '@shared/modules/base-panel/components/base-navigation.component';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { StorageService } from '@core/services/storage.service';
import { Employee } from '@shared/models/employee.model';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MailingController } from '@shared/modules/mailings/shared/controllers/mailing.controller';

@Component({
  selector: 'header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationComponent extends BaseNavigationComponent implements OnInit {
  @ViewChild('el') el: ElementRef;

  Config = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;

  employee: Employee;

  constructor(
    public n: NavigateService,
    public store: StorageService,
    private router: Router,
    private changes: ChangeDetectorRef,
  ) {
    super(store);
    this.employee = this.store.Employee;
  }

  ngOnInit() {
    this.detectChangeOnRouteChanges();
  }

  createTask() {
    const ctrl = new TaskSidenavController();
    ctrl.addTask(null);
  }

  createChange() {
    const ctrl = new ChangesSidenavController();
    ctrl.addChange(null);
  }

  createEvent() {
    const ctrl = new EventSidenavController();
    ctrl.createEvent();
  }

  createProtocol() {
    const ctrl = new ProtocolController(null, null);
    ctrl.add();
  }

  createContact() {
    const ctrl = new ContactController();
    ctrl.add();
  }

  createProjectDraft() {
    const ctrl = new ProjectController();
    ctrl.add();
  }

  createEmail() {
    const ctrl = new MailingController();
    ctrl.openMailingSelection('');
  }

  detectChangeOnRouteChanges() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeData) => {
      this.changes.detectChanges();
    });
  }

  isTimelineRoute(tabLink: TabLink) {
    const isProjects = window.location.href.indexOf('projects') !== -1;
    if (tabLink.link === 'projects-list' && isProjects) {
      return true;
    }
    return false;
  }
}
