import { CalendarEntity } from './calendar-entity.model';

export class CalendarEntityPreview extends CalendarEntity {
  name: string = '';
  employeeId: number = null;
  canAddEvent: boolean = false;
  photoUrl: string = '';
  url?: string;

  constructor(i: CalendarEntityPreview) {
    super(i);
    i ? Object.assign(this, i) : null;
    this.setColor();
  }

  addEvent(canAddEvent: boolean) {
    this.canAddEvent = canAddEvent;
  }

  setPreviewName(name: string) {
    this.name = name;
  }

  setEmployeeId(employeeId: number) {
    this.employeeId = employeeId;
  }

  setPhotoURL(photoUrl: string) {
    this.photoUrl = photoUrl;
  }
}
