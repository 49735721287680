import { NgOption } from '@ng-select/ng-select';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { FormGroup } from '@angular/forms';
import { Project, projectSelectOption } from '@modules/projects/shared/models/project.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Config } from '@shared/configs/config';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';

@Component({
  selector: 'project-selection',
  templateUrl: './project-selection.component.html',
})
export class ProjectSelectionComponent {
  _initialValue: any[];

  @Input() isFieldRequired: boolean = false;
  @Input() requiredid: string = null;
  @Input() addTag: boolean = true;
  @Input() loading: boolean = false;
  @Input() placeholder: string = '';
  @Input() form: FormGroup;
  @Input() controlName: string = 'project';
  @Input() class: string = '';
  @Input() disabled: boolean = false;
  @Input() clearable: boolean = true;
  @Input() changePromise?: (item?: NgOption) => Promise<boolean>;

  @Input()
  get initialValue() {
    return this._initialValue;
  }

  set initialValue(_initialValue: any[]) {
    _initialValue.forEach((i: Project, index: number) => {
      const j = new Project(i);
      _initialValue[index] = Object.assign(j, this.getProjectOption(j));
    });
    this._initialValue = _initialValue;
  }

  @Output() putProject: EventEmitter<{
    item: Project;
    items?: Array<Project>;
    update: boolean;
  }> = new EventEmitter();

  @ViewChild('projectSelect') projectSelect: NgSelectExtensionDirective;

  selectedProject: Project = null;
  addProject: EventEmitter<any[]> = new EventEmitter();

  get URL() {
    return `${Config.API}/project/search${!!this.requiredid ? '?requiredId=' + this.requiredid : ''}`;
  }

  constructor(private s: SnackBarService, private t: TranslateService, private service: ProtocolApiService) {}

  changePromiseFunc($event) {
    const defaultPromise = new Promise((resolve, reject) => {
      resolve(true);
    });

    return this.changePromise ? this.changePromise($event) : defaultPromise;
  }

  validateProjectTag($event) {
    if ($event?.length > 200) {
      this.s.error(this.t.instant('Protocols.projectMaxLenght'), Config.BOTTOM_TOASTER_CONFIG);
      return null;
    }
    return this.createProject($event);
  }

  successOnLoadProjects(projectList: Array<Project>) {
    projectList.forEach((i: Project, index: number) => {
      const j = new Project(i);
      projectList[index] = Object.assign(j, this.getProjectOption(j));
    });

    const id = this.form.get(this.controlName)?.value;
    if (id) {
      this.selectedProject = projectList.concat(this.initialValue).filter((i) => i?.id === id)[0];
      this.putProject.emit({ item: this.selectedProject, update: false });
    }
    return projectList;
  }

  createProject(name: string, items?: Array<any>): any {
    const e = {
      name,
      address: '',
      country: '',
      postalCode: '',
      town: '',
    };
    return new Promise((resolve, reject) => {
      this.service.createProject(e).subscribe(
        (newProject: Project) => this.successCreatingProject(newProject, items, resolve),
        () => reject(e),
      );
    });
  }

  successCreatingProject(newProject: Project, items: any[] = [], resolve): void {
    const j = new Project(newProject);
    newProject = Object.assign(j, this.getProjectOption(j));
    this.selectedProject = newProject;
    this.addProject.emit([this.selectedProject]);
    resolve(newProject);
    this.putProject.emit({ item: this.selectedProject, update: true });
  }

  setProject(item, items: Array<any>) {
    this.selectedProject = item;
    this.putProject.emit({ item, items, update: true });
  }

  isControlInvalid(name: string) {
    return this.form?.get(name)?.touched && this.form?.get(name)?.invalid;
  }

  getProjectOption(project: Project): object {
    const title = `${!!project?.name ? project?.name : ''}`;
    const name = !!project.MainContact?.fullName ? project.MainContact?.fullName : '';
    const addres = !!project?.fullAddress ? project?.fullAddress : '';
    const subtitle = `${name}${!!name && !!addres ? ', ' : ''} ${addres}`;
    const display = `
    ${projectSelectOption(project)}
    `;

    return {
      title,
      subtitle,
      display,
    };
  }
}
