import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { HttpError } from '@shared/interfaces/error.interface';

@Component({
  selector: 'base-remove-modal',
  templateUrl: './base-remove-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseRemoveModalComponent extends BaseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BaseRemoveModalComponent>,
    private service: SettingsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      subtitle?: string;
      description?: string;
      cancelBtn?: string;
      confirmBtn?: string;
    },
  ) {
    super();
  }

  ngOnInit(): void {}
}
