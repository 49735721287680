import { NgModule } from '@angular/core';
import { UIComponents } from '@shared/modules/ui/index';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TourMatMenuModule } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/md-menu.module';
import { MatTabsModule } from '@angular/material/tabs';
import { PermissionDirectiveModule } from '../permission-directive/permission-directive.module';
import { GbxsoftFormModule } from '@form/src/lib/gbxsoft-form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PriceCellComponent } from './components/price-cell/price-cell.component';
import { DayCellComponent } from './components/day-cell/day-cell.component';
import { StatusCellComponent } from './components/status-cell/status-cell.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';

@NgModule({
  declarations: [
    UIComponents,
    SafeHtmlPipe,
    PriceCellComponent,
    DayCellComponent,
    StatusCellComponent,
    ColorPickerComponent,
    CheckboxComponent,
  ],
  imports: [
    CommonModule,
    PermissionDirectiveModule,
    TranslateModule,
    RouterModule,
    AngularSvgIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    TourMatMenuModule,
    GbxsoftFormModule,
    ReactiveFormsModule,
  ],
  exports: [
    UIComponents,
    PriceCellComponent,
    DayCellComponent,
    StatusCellComponent,
    ColorPickerComponent,
    CheckboxComponent,
  ],
})
export class UIModule {}
